import media from 'lib/styles/media'
import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { ReactComponent as Arrow } from '../../../assets/icons/mobile_header_arrow.svg'
import Search from '../Search'

interface Props {
  title?: string
  type?: string
}
function MobileTitleHeader({ title, type }: Props) {
  const history = useHistory()
  const params = useParams<{ search: string }>()
  const value = params.search

  return (
    <Wrapper
      className={
        (type !== 'search' ? 'mobile' : '') +
        (window.location.href.includes('/tutor/detail/') ? ' fixed' : '')
      }
    >
      {type === 'arrow' ? (
        <div className="arrow_content">
          <Arrow
            onClick={() => {
              history.goBack()
            }}
          />
          <p className="arrow_title">{title}</p>
        </div>
      ) : type === 'search' ? (
        <div className="search_content">
          <Arrow
            onClick={() => {
              history.goBack()
            }}
          />
          <Search
            preValue={value}
            placeholder="강의 제목, 튜터 이름으로 검색하세요!"
            type="mobile"
          />
        </div>
      ) : (
        <p className="mobile_header">{title}</p>
      )}
    </Wrapper>
  )
}

export default MobileTitleHeader

const Wrapper = styled.div`
  display: none;
  width: 100%;
  height: 58px;
  background: #fff;
  &.mobile {
    padding: 15px 20px;
  }
  ${media.medium} {
    display: flex;
  }
  & > p.mobile_header {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: #3b3b40;
  }
  & div.arrow_content {
    display: flex;
    align-items: center;
  }
  & p.arrow_title {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #3b3b40;
    margin-left: 14px;
  }
  & .search_content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px 20px;
    & > svg {
      margin-right: 10px;
    }
  }

  &.fixed {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
  }
`
