import Footer from 'components/common/Footer'
import MobileTitleHeader from 'components/common/header/MobileTitleHeader'
import TutorHeader from 'components/common/header/TutorHeader'
import TutorBasicComponent from 'components/common/joinInfo/TutorBasicComponent'
import media from 'lib/styles/media'
import React from 'react'
import styled from 'styled-components'

function TutorJoininfo() {
  return (
    <Wrapper>
      <TutorHeader />
      <MobileTitleHeader type="arrow" title="기본 정보 설정" />
      <Contents>
        <TutorBasicComponent />
      </Contents>
      <Footer />
    </Wrapper>
  )
}

export default TutorJoininfo
const Wrapper = styled.div``
const Contents = styled.div`
  margin-top: 100px;
  ${media.medium} {
    margin-top: 0;
  }
`
