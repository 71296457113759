import React from 'react'
import styled from 'styled-components'
import { ReactComponent as Icon } from '../../assets/icons/image_file_upload_icon.svg'
import { readFile } from 'lib/utils'
import PreviewImage from './PreviewImage'
import type { FileType } from 'lib/types/account'
import media from 'lib/styles/media'

interface Props {
  onChange: (files: FileType[]) => void
  length: number
  imageSize: 'sm' | 'md' | 'lg'
  files: FileType[]
  onCloseClick: (id: number | string) => void
}

function InputFile({
  onChange,
  length,
  imageSize,
  files,
  onCloseClick,
}: Props) {
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = async (
    e,
  ) => {
    if (!e.target.files) return
    if (e.target.files && e.target.files?.length + length > 10) return

    const files: any = await Promise.all(
      Array.from(e.target.files).map((file: File) => readFile(file)),
    )
    onChange && onChange(files)
  }
  return (
    <Wrapper>
      <Content>
        {files.length < 10 && (
          <div className="file_input_content">
            <label htmlFor="image_file">
              <Icon />
            </label>
            <input
              type="file"
              multiple
              accept="image/8*"
              id="image_file"
              onChange={handleChange}
            />
          </div>
        )}
        {files.map((item, i) => (
          <div
            key={`register_class_file_files_list_${i}`}
            className="preview_image"
          >
            <PreviewImage
              file={item}
              onClick={() => onCloseClick(item.id)}
              frist={i === 0}
            />
          </div>
        ))}
      </Content>
    </Wrapper>
  )
}

export default InputFile
const Wrapper = styled.div`
  display: flex;
  width: 100%;
`
const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  ${media.medium} {
    flex-wrap: nowrap;
    overflow-x: scroll;
  }
  & div.file_input_content {
    width: 144px;
    height: 80px;
    margin-right: 12px;
    ${media.medium} {
      margin-right: 10px;
    }
    & input {
      display: none;
    }
  }
  & div.preview_image {
    margin-right: 12px;
    margin-bottom: 12px;
    ${media.medium} {
      margin-right: 10px;
      margin-bottom: 0;
    }
  }
  & div.preview_image:nth-of-type(5n) {
    margin-right: 0;
  }
  & div.preview_image:nth-of-type(n + 5) {
    margin-bottom: 0;
  }
`
