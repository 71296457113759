import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import TabButton from '../register/TabButton'
import DatePickerContent from '../DatePickerContent'
import media from 'lib/styles/media'
import Button from '../Button'
import { TutorRegisterClassPayloadState2 } from 'atoms/join'
import type { StepClassType } from 'atoms/join'
import { useRecoilState } from 'recoil'
import { ReactComponent as Close } from '../../../assets/icons/header_menu_close_hover_icon.svg'

interface DateProps {
  day: string
  time: string
  estimated: number
  title: string
  step: number
  modify: string
}
interface Props {
  data: number
  handleClick: (id: number) => void
  stepData: StepClassType[]
  handleStepChange: (data: DateProps, step: any) => void
  mode: 'add' | 'edit'
  handledateDisabled: (data: boolean) => void
  handleTitleDisabled: (data: boolean) => void
}
function TutorMultiComponent({
  data,
  handleClick,
  stepData,
  handleStepChange,
  mode,
  handledateDisabled,
  handleTitleDisabled,
}: Props) {
  const [multi, setMulti] = useState(false)
  const [payload, setPayload] = useRecoilState(
    TutorRegisterClassPayloadState2(mode),
  )

  useEffect(() => {
    if (data === 1) {
      setMulti(true)
    } else {
      setMulti(false)
    }
  }, [data])

  const onAdd = () => {
    const index =
      !payload?.stepClass || (payload?.stepClass ?? [])?.length === 0
        ? 1
        : Number(payload?.stepClass?.slice(-1)[0]?.step) + 1

    setPayload({
      ...payload,
      stepClass: payload?.stepClass?.concat({
        step: index,
        date: '',
        time: '',
        estimated: '',
        title: '',
        modify: 'Y',
      }),
    })
  }
  const onRemove = (number: number | undefined) => {
    setPayload({
      ...payload,
      stepClass: payload?.stepClass?.filter((item) => item.step !== number),
    })
  }
  return (
    <Wrapper>
      <TabButton
        type="multi"
        title="강의 방식"
        data={data}
        handleClick={handleClick}
      />
      {stepData.map((item, i) => {
        return (
          <Content key={`tutor_multi_step_data_${i}`}>
            {multi && (
              <StepTitle>
                <p className="step_title">{i + 1}회차</p>
                {i >= 1 && (
                  <Close
                    onClick={() => {
                      if (item.can_modify === 'Y') {
                        onRemove(item.step)
                      }
                    }}
                  />
                )}
              </StepTitle>
            )}
            <Title>강의일시 및 예상시간</Title>
            <DatePickerContent
              multi={multi}
              handleStepChange={(e) => {
                handleStepChange(e, item.step)
              }}
              step={item.step}
              mode={mode}
              handledateDisabled={handledateDisabled}
              handleTitleDisabled={handleTitleDisabled}
              day={
                (item.step_begin_date && item.step_begin_date.slice(0, 10)) ??
                ''
              }
              time={
                (item.step_begin_date && item.step_begin_date.slice(11, -3)) ??
                ''
              }
              estimated={
                (item.step_estimated_time && item.step_estimated_time) ?? ''
              }
              title={item.step_title ?? ''}
              modify={item.can_modify ?? 'Y'}
            />
          </Content>
        )
      })}

      {multi && (
        <ButtonBox>
          <Button title="추가" className="border" onClick={onAdd} />
        </ButtonBox>
      )}
    </Wrapper>
  )
}

export default TutorMultiComponent

const Wrapper = styled.div``
const Content = styled.div`
  margin-top: 40px;
`
const StepTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  & p.step_title {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #0d0d0e;
    ${media.medium} {
      font-size: 16px;
      line-height: 26px;
    }
  }
`
const Title = styled.p`
  font-weight: 500;
  font-size: 17px;
  line-height: 26px;
  color: #0d0d0e;
  margin-top: 40px;
  ${media.medium} {
    font-size: 16px;
    margin-top: 20px;
  }
`
const ButtonBox = styled.div`
  margin-top: 30px;
`
