import { AppRootWrapper } from 'components/layout/CommonStyle'
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Button from '../Button'
import api from 'lib/api'
import SmsInput from '../SmsInput'
import CheckPopup from '../CheckPopup'
import {
  Error0001,
  Error0002,
  Error0003,
  Error0004,
  ErrorFindId,
} from 'lib/utils/CommonFuncttion'
import media from 'lib/styles/media'
import { useHistory } from 'react-router-dom'
import { useRecoilState, useResetRecoilState } from 'recoil'
import { JoinStepPayloadState, TutorJoinStepPayloadState } from 'atoms/join'

interface Props {
  type: string
  pageType: string
}
function SmsComponent({ type, pageType }: Props) {
  const history = useHistory()
  const [payload, setPayload] = useRecoilState(
    pageType === 'tutor' ? TutorJoinStepPayloadState : JoinStepPayloadState,
  )

  const [btnText, setBtnText] = useState('인증')
  const [isSendCode, setIsSendCode] = useState(false)
  const [isCodeCheck, setIsCodeCheck] = useState(false)
  const [popup, setPopup] = useState(false)
  const [title, setTitle] = useState('')
  const [contents, setContents] = useState('')
  const local = localStorage.getItem('social')

  useEffect(() => {
    if (popup) {
      document.documentElement.style.overflow = 'hidden'
    } else {
      document.documentElement.style.overflow = 'auto'
    }

    return () => {
      document.documentElement.style.overflow = 'auto'
    }
  }, [popup])

  const handleCodeError = (code?: string) => {
    if (code) {
      if (code === 'DB0001') {
        setPopup(true)
        setTitle('이미 가입된 휴대폰 번호')
        setContents(Error0001)
      } else if (code === 'DB0002') {
        setPopup(true)
        setTitle('탈퇴 회원')
        setContents(Error0002)
      } else if (code === 'DB0002') {
        setPopup(true)
        setTitle('강제 탈퇴 회원')
        setContents(Error0003)
      } else {
        setPopup(true)
        setTitle('인증번호 발송 횟수 초과')
        setContents(Error0004)
      }
    } else {
      setIsCodeCheck(true)
    }
  }
  // 인증번호 발송
  const handleSendSms = async () => {
    if (payload?.phone && String(payload?.phone).length >= 10) {
      const formData = new FormData()
      formData.append('type', pageType === 'tutor' ? 'tutor' : 'user')
      formData.append('phone', String(payload?.phone))
      // formData.append('is_join', type === 'find' ? '0' : '1')

      const {
        data: { success, alert, code },
      } = await api.post('/sms/certification', formData)

      if (success) {
        setBtnText('재발송')
        setIsSendCode(true)
      } else {
        handleCodeError(code)
      }
    }
  }
  // 인증번호 확인
  const handleNextClick = async () => {
    const fd = new FormData()
    fd.append('type', pageType === 'tutor' ? 'tutor' : 'user')
    fd.append('phone', String(payload?.phone))
    fd.append('code', String(payload?.code))
    fd.append('is_join', '1')

    const {
      data: { success, alert, code },
    } = await api.post('/sms/certification/check', fd)

    if (success) {
      if (pageType === 'tutor') {
        history.push('/join/info?role=tutor')
      } else {
        if (local) {
          history.push('/join/info/basic')
        } else {
          history.push('/join/info')
        }
      }
    } else {
      handleCodeError(code)
      // if (code === 'WO0001') {
      //   setIsCodeCheck(true)
      // } else {
      //   handleCodeError(code)
      // }
    }
  }
  // 아이디 찾기
  const handleCheck = () => {
    setPopup(false)
  }

  const handleSuccess = async () => {
    const fd = new FormData()
    fd.append('type', pageType === 'tutor' ? 'tutor' : 'user')
    fd.append('phone', String(payload?.phone))
    fd.append('code', String(payload?.code))

    const {
      data: { success, alert, data },
    } = await api.post('/find/email', fd)

    if (success) {
      if (data.provider === null) {
        history.push(
          pageType === 'tutor'
            ? `/find/id/${data.email}?role=tutor`
            : `/find/id/${data.email}`,
        )
      } else {
        history.push(
          pageType === 'tutor'
            ? `/find/id/${data.email}?social=kakao&role=tutor`
            : `/find/id/${data.email}?social=kakao`,
        )
      }
    } else {
      if (alert === '해당 연락처로 가입된 계정이 없습니다.') {
        setPopup(true)
        setTitle('가입 계정 조회 불가')
        setContents(ErrorFindId)
      } else {
        setIsCodeCheck(true)
      }
    }
  }
  // 번호만 등록
  const handleChange = (type: string) => (e: any) => {
    const data = e.target.value.replace(/[^0-9]/g, '')
    setPayload({
      ...payload,
      [type]: data,
    })
  }

  return (
    <Wrapper>
      <Content>
        {type === 'find' ? (
          <div className="text_box">
            <p className="sms_title">아이디 찾기</p>
            <p className="sms_text">
              아이디를 찾기 위해 휴대폰 인증이 필요해요.
            </p>
          </div>
        ) : (
          <div className="text_box">
            <p className="sms_title">휴대폰 인증</p>
            <p className="sms_text">
              가입 여부 확인을 위해 휴대폰 인증이 필요해요.
            </p>
          </div>
        )}

        <div className="input_box">
          <SmsInput
            title="휴대폰 번호"
            placeholder="‘-’ 제외 휴대폰 번호 입력"
            phone={payload?.phone}
            onPhoneChange={handleChange('phone')}
            btnText={btnText}
            handleSendSms={handleSendSms}
            codeInput={isSendCode}
            code={payload?.code}
            onCodeChange={handleChange('code')}
            isCodeCheck={isCodeCheck}
            type={type}
          />
        </div>
        {type === 'find' ? (
          <div className="button_box">
            <Button
              title="완료"
              onClick={handleSuccess}
              disabled={String(payload?.code).length < 6}
            />
          </div>
        ) : (
          <div className="button_box">
            <Button
              title="다음"
              onClick={handleNextClick}
              disabled={String(payload?.code).length < 6}
            />
          </div>
        )}
      </Content>
      {popup && (
        <CheckPopup onClick={handleCheck} title={title} content={contents} />
      )}
    </Wrapper>
  )
}

export default SmsComponent

const Wrapper = styled(AppRootWrapper)``
const Content = styled.div`
  width: 440px;
  height: 680px;
  margin: 0 auto;
  padding: 80px 0 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${media.medium} {
    padding: 20px 0 80px;
    height: 100%;
    width: 100%;
  }
  & p.sms_title {
    font-weight: 700;
    font-size: 22px;
    line-height: 34px;
    text-align: center;
    color: #0d0d0e;
    ${media.medium} {
      font-size: 18px;
      line-height: 28px;
    }
  }
  & p.sms_text {
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #3b3b40;
    margin-top: 6px;
  }
  & div.input_box {
    margin-top: 50px;
    width: 380px;
    ${media.medium} {
      width: 100%;
    }
  }
  & div.button_box {
    margin-top: 100px;
    width: 380px;
    ${media.medium} {
      width: 100%;
    }
  }
`
