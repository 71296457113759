import api from 'lib/api'
import React, { useCallback, useState } from 'react'
import useSWR from 'swr'
import useScroll from './useScroll'
import { getPaymentListRequest } from 'lib/api/list'

interface Props {
  status: string
}
function usePayment({ status }: Props) {
  const [loading, setLoding] = useState(false)

  const { data, mutate } = useSWR(
    status && `/payment?page=1&payment_status=${status}`,
    async (url: any) => {
      setLoding(true)
      const responseData = await api.get(url).then((res) => res.data)
      setLoding(false)
      if (!responseData.success) return undefined
      return responseData
    },
  )
  const [fetchMoreLoading, setFetchMoreLoading] = useState(false)

  const fetchMore = useCallback(
    async (page: number) => {
      if (!data || !data.data || fetchMoreLoading || loading) return
      setFetchMoreLoading(true)

      const response = await getPaymentListRequest(page, status)

      if (!response.success || !response.data) return

      mutate(
        {
          ...data,
          data: {
            payment_histories: {
              last_page: response.data?.payment_histories.last_page,
              current_page: response.data?.payment_histories.current_page,
              data: data.data.payment_histories.data.concat(
                response.data.payment_histories.data,
              ),
            },
          },
        },
        false,
      )
      setFetchMoreLoading(false)
    },
    [data, mutate, fetchMoreLoading, loading],
  )
  const { target } = useScroll({
    fetchMore,
    page: data?.data?.payment_histories.current_page,
    lastPage: data?.data?.payment_histories.last_page,
  })
  return {
    data,
    target,
    mutate,
    fetchMoreLoading,
  }
}

export default usePayment
