import React from 'react'
import styled from 'styled-components'

interface Props {
  title: string
  value: string | undefined
  handleClick: () => void
}
function AddressInput({ title, value, handleClick }: Props) {
  return (
    <Wrapper>
      <InputContent>
        <p className="input_title">{title}</p>
        <input type="text" readOnly value={value} />
        <button className="address_button_content" onClick={handleClick}>
          주소 검색
        </button>
      </InputContent>
    </Wrapper>
  )
}

export default AddressInput
const Wrapper = styled.div``
const InputContent = styled.div`
  position: relative;
  border-bottom: 1px solid #e1e1e2;
  & p.input_title {
    font-weight: 700;
    font-size: 13px;
    line-height: 22px;
    color: #0d0d0e;
    margin-bottom: 4px;
  }
  & input {
    width: 85%;
    border: none;
    outline: none;
    padding: 7px 30px 7px 0;
  }
  & input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px #fff inset;
  }
  & button.address_button_content {
    width: 70px;
    height: 30px;
    background: #ffffff;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    bottom: 4px;
    font-weight: 400;
    font-size: 13px;
    line-height: 22px;
    border: 1px solid #0d0d0e;
    color: #0d0d0e;
  }
`
