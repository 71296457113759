import React from 'react'
import useSWR from 'swr'

interface Props {
  tutorId: number | string
}
function useTutorClass({ tutorId }: Props) {
  return useSWR(tutorId ? `main/tutor/class?id=${tutorId}` : '')
}

export default useTutorClass
