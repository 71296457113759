import {
  type GetClassDetailRequest,
  type GetClassStepDetailRequest,
} from 'lib/types/class'
import { type PaymentMethodType } from 'lib/types/common'
import auth from 'lib/utils/auth'
import api, { API_END_POINT } from '.'

export function paymentClass({
  classId,
  paymentMethod,
}: {
  classId: number | string
  paymentMethod: PaymentMethodType
}) {
  const token = auth.getToken()

  window.location.href = `${API_END_POINT}/class/purchase?class_id=${classId}&access_token=${token}&payment_method=${paymentMethod}`
}

export async function getClassDetailRequest(classId: string) {
  const { data } = await api.get<GetClassDetailRequest>('/class/detail', {
    params: {
      class_id: classId,
    },
  })

  return data
}

export async function getClassStepDetailRequest({
  classId,
  step,
}: {
  classId: number
  step: number
}) {
  const response = await api.get<GetClassStepDetailRequest>(
    '/class/step/detail',
    {
      params: {
        class_id: classId,
        step,
      },
    },
  )

  return response.data
}
