import { timeSettingModalState } from 'atoms/modal'
import { add } from 'date-fns'
import useRoom from 'lib/hooks/room/useRoom'
import media from 'lib/styles/media'
import { addDate } from 'lib/utils'
import { DEBATE_STATUS_TEXT } from 'lib/utils/variables'
import React from 'react'
import { useParams } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import styled from 'styled-components'
import SettingDebateStatus from './SettingDebateStatus'
import RoomTimer from '../content/RoomTimer'
import { useRoomInfoValue } from '../provider/RoomInfoProvider'

function RoomTitle() {
  const { roomId }: { roomId: string } = useParams()
  const {
    debateStatus,
    setDebatingTimerTrigger,
    clearDebatingTimerTrigger,
    clearVoteTimerTrigger,
  } = useRoom(roomId)
  const { role, endDate } = useRoomInfoValue()

  const setTimeSettingModal = useSetRecoilState(timeSettingModalState)

  const timerSetting = (time: number) => {
    const date = addDate(time)

    setDebatingTimerTrigger(date)
  }

  const handleSettingTimer = () => {
    setTimeSettingModal({
      title: '타이머 설정',
      text: '설정한 시간이 디베이팅룸에 카운트됩니다.',
      width: 'md',
      isSelectButton: true,
      onSetting: timerSetting,
    })
  }

  if (debateStatus === 8) return <Wrapper>추가 강의</Wrapper>
  if (debateStatus === undefined || debateStatus === 6)
    return <Wrapper>온라인 교실</Wrapper>

  return (
    <Wrapper>
      <div className="first">
        {endDate?.vote ? (
          <div>투표 진행중</div>
        ) : (
          <div>{DEBATE_STATUS_TEXT[debateStatus]}</div>
        )}
        {role === 'tutor' && <SettingDebateStatus />}
      </div>
      <div className="second">
        {endDate?.vote ? (
          <RoomTimer
            role={role}
            date={endDate?.vote}
            onCancel={clearVoteTimerTrigger}
          />
        ) : (
          <RoomTimer
            role={role}
            onSetting={handleSettingTimer}
            date={endDate?.room}
            onCancel={clearDebatingTimerTrigger}
          />
        )}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  color: #ffffff;
  display: flex;

  & .first {
    display: flex;
    align-items: center;
    z-index: 20;
    ${media.pc} {
      gap: 10px;
    }
    ${media.medium} {
      gap: 6px;
    }
  }

  & .second {
    z-index: 10;
  }

  ${media.pc} {
    padding: 40px 0 56px 0;
    font-weight: 700;
    font-size: 30px;
    line-height: 28px;
    justify-content: center;
    gap: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  ${media.medium} {
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    padding: 12px 20px;

    background: rgba(59, 59, 64, 0.4);

    order: 1;

    justify-content: space-between;
    align-items: center;
  }
`

export default RoomTitle
