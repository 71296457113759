import Footer from 'components/common/Footer'
import MobileTitleHeader from 'components/common/header/MobileTitleHeader'
import StudentHeader from 'components/common/header/StudentHeader'
import TutorHeader from 'components/common/header/TutorHeader'
import ReferenceDetailComponent from 'components/common/reference/ReferenceDetailComponent'
import FileDownloadModal from 'components/modal/FileDownloadModal'
import useUser from 'lib/hooks/account/useUser'
import media from 'lib/styles/media'
import React from 'react'
import styled from 'styled-components'

const ReferenceDetailPage = () => {
  const { user } = useUser()
  const typeUser = user && user.type

  return (
    <Wrapper className={typeUser}>
      {typeUser === 'user' ? <StudentHeader /> : <TutorHeader />}
      <MobileTitleHeader type="arrow" title="레퍼런스룸" />
      <Content>
        <ReferenceDetailComponent type={typeUser} />
      </Content>
      <Footer />
      <FileDownloadModal />
    </Wrapper>
  )
}

export default ReferenceDetailPage

const Wrapper = styled.div``
const Content = styled.div`
  margin: 100px auto 120px;
  ${media.medium} {
    width: 100%;
    margin: 0 auto 80px;
  }
`
