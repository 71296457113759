import React, { useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { Document, Page, pdfjs } from 'react-pdf'
import qs from 'query-string'

function PdfPage() {
  const { search } = useLocation()

  const { url }: any = qs.parse(search)

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
  }, [])

  return (
    <Document file={url}>
      <Page pageNumber={1} />
    </Document>
  )
}

export default PdfPage
