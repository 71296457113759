import React from 'react'
import styled, { css } from 'styled-components'
import { ReactComponent as BasicIcon } from 'assets/icons/basic_icon.svg'
import media from 'lib/styles/media'

interface ProfileProps {
  profileUrl: string | null
  name: string
  size?: 'xs' | 'sm' | 'md' | 'lg'
}

function Profile({ profileUrl, name, size = 'md' }: ProfileProps) {
  return (
    <Wrapper size={size}>
      <div className="image">
        {profileUrl ? (
          <img src={profileUrl} alt="profile" className="profile-image" />
        ) : (
          <BasicIcon className="basic-icon" />
        )}
      </div>
      <div className="name">{name}</div>
    </Wrapper>
  )
}

const Wrapper = styled.div<{ size: 'xs' | 'sm' | 'md' | 'lg' }>`
  display: flex;
  align-items: center;
  gap: 8px;

  & .image {
    ${(props) =>
      props.size === 'xs' &&
      css`
        width: 26px;
        height: 26px;

        ${media.medium} {
          width: 26px;
          height: 26px;
        }
      `}

    ${(props) =>
      props.size === 'sm' &&
      css`
        width: 28px;
        height: 28px;

        ${media.medium} {
          width: 24px;
          height: 24px;
        }
      `}

    ${(props) =>
      props.size === 'md' &&
      css`
        width: 42px;
        height: 42px;
      `}

    ${(props) =>
      props.size === 'lg' &&
      css`
        width: 42px;
        height: 42px;
      `}

    & img,.basic-icon {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }

  & .name {
    font-weight: 500;
    font-size: 16px;
    color: #f9f9fb;

    ${media.medium} {
      font-weight: 500;
      font-size: 14px;
    }

    ${(props) =>
      props.size === 'xs' &&
      css`
        font-weight: 500;
        font-size: 14px;
      `}
  }
`

export default Profile
