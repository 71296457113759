import media from 'lib/styles/media'
import React from 'react'
import styled from 'styled-components'
import { ReactComponent as ArrowIcon } from '../../assets/icons/notice_arrow_icon.svg'

interface Props {
  category: string
  onClick: () => void
}
function CategoryTab({ category, onClick }: Props) {
  return (
    <Wrapper onClick={onClick}>
      <p>{category}</p>
      <ArrowIcon />
    </Wrapper>
  )
}

export default CategoryTab

const Wrapper = styled.div`
  width: 100%;
  padding: 20px 18px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #eeeff1;
  ${media.medium} {
    padding: 15px 20px;
  }
  & p {
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: #0d0d0e;
    flex: 1;
    cursor: pointer;
    ${media.medium} {
      font-size: 14px;
      line-height: 24px;
    }
  }
  & svg {
    width: 8px;
    height: 14px;
  }
  &:hover {
    background: #f9f9fb;
  }
`
