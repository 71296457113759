import useUser from 'lib/hooks/account/useUser'
import useRoom from 'lib/hooks/room/useRoom'
import media from 'lib/styles/media'
import React from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import Reference from '../common/Reference'
import { ReactComponent as NoticeDarkIcon } from 'assets/icons/notice_dark_icon.svg'
import { useRoomInfoValue } from '../provider/RoomInfoProvider'

function ReferenceList() {
  const { roomId }: { roomId: string } = useParams()
  const { data } = useRoom(roomId)
  const { user } = useUser()
  const { role } = useRoomInfoValue()

  if (!data?.room.files.length) {
    return (
      <Wrapper>
        <div className="empty">
          <NoticeDarkIcon />
          <p className="notice-text">업도드된 자료가 없습니다.</p>
        </div>
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      {data?.room.files.map((file) => (
        <Reference
          file={file}
          key={file.id}
          isMe={role === 'tutor' || user.id === file.user_id}
        />
      ))}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  height: 100%;

  overflow-y: scroll;

  & > .empty {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    justify-content: flex-end;
    height: 25%;

    ${media.medium} {
      height: 50%;
    }

    & > .notice-text {
      color: #3b3b40;
      font-weight: 700;
      font-size: 16px;
      line-height: 26px;
    }
  }

  ${media.pc} {
    padding-top: 10px;
  }

  padding-left: 20px;
  padding-right: 20px;
`

export default ReferenceList
