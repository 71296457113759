import { fileDownloadModalState } from 'atoms/modal'
import React from 'react'
import { useRecoilState } from 'recoil'
import styled from 'styled-components'
import ModalWrapper from './ModalWrapper'

import { ReactComponent as CloseIcon } from 'assets/icons/close_icon.svg'
import { ReactComponent as Down } from 'assets/icons/file_down_icon.svg'

function FileDownloadModal() {
  const [modal, setModal] = useRecoilState(fileDownloadModalState)

  const handleClose = () => {
    setModal(null)
  }

  if (!modal) return null

  return (
    <ModalWrapper onClose={handleClose}>
      <Wrapper>
        <Header>
          <h4>{modal.title}</h4>
          <CloseIcon onClick={handleClose} />
        </Header>
        <Content>
          {modal?.files?.map((file: any, i: number) => (
            <List
              key={'reference_file_down_' + i}
              href={file.file_url}
              download={true}
            >
              <span className="total_span">
                <span>
                  {
                    file.file_url
                      .split('/')
                      [file.file_url.split('/').length - 1].split('.')[0]
                  }
                </span>
                <span>
                  .
                  {
                    file.file_url
                      .split('/')
                      [file.file_url.split('/').length - 1].split('.')[1]
                  }
                </span>
              </span>

              <Down />
            </List>
          ))}
        </Content>
      </Wrapper>
    </ModalWrapper>
  )
}

export default FileDownloadModal

const List = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  text-decoration: none;

  &:nth-of-type(n + 2) {
    margin-top: 22px;
  }

  & span.total_span {
    overflow: hidden;
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    max-width: 300px;
    & > span {
      font-weight: 500;
      font-size: 14px;
      line-height: 1.71;
      color: #3b3b40;
      border-bottom: solid 1px #3b3b40;
      max-width: calc(100% - 60px);
    }
  }

  & > svg {
    margin-left: auto;
  }
`

const Content = styled.div`
  width: 100%;
  height: 316px;
  overflow: auto;
  padding: 18px 26px;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 26px 26px 10px;

  & > h4 {
    font-weight: 700;
    font-size: 18px;
    line-height: 1.56;
    color: #0d0d0e;
  }

  & > svg {
    width: 17px;
    min-width: 17px;
    height: 17px;
    cursor: pointer;
  }
`

const Wrapper = styled.div`
  background: #fff;
  max-width: 380px;
  width: 100%;
  margin: 0 20px;
  border-radius: 14px;
`
