import { type PaymentMethodType } from 'lib/types/common'
import React from 'react'
import styled, { css } from 'styled-components'
import { ReactComponent as PaycoIcon } from 'assets/icons/payco_icon.svg'
import { ReactComponent as NaverIcon } from 'assets/icons/naver_icon.svg'
import { ReactComponent as KakaoIcon } from 'assets/icons/kakaopay_icon.svg'

export type ChangeMethodType = (value: PaymentMethodType) => void

interface PaymentMethodProps {
  value: PaymentMethodType
  label: string
  isSelected?: boolean
  onChangeMethod: ChangeMethodType
}

function PaymentMethod({
  value,
  label,
  onChangeMethod,
  isSelected,
}: PaymentMethodProps) {
  const handleSelect = () => {
    onChangeMethod(value)
  }
  return (
    <Wrapper onClick={handleSelect} isSelected={isSelected}>
      {value === 'kakao' && <KakaoIcon />}
      {value === 'naver' && <NaverIcon />}
      {value === 'payco' && <PaycoIcon />}
      {label}
    </Wrapper>
  )
}

const Wrapper = styled.div<{ isSelected?: boolean }>`
  width: 100%;
  border-radius: 50px;
  border: 1px solid #e1e1e2;

  height: 50px;

  padding: 13px 0;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;

  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #0d0d0e;

  cursor: pointer;

  ${(props) =>
    props.isSelected &&
    css`
      border: 1px solid #0d0d0e;
    `}
`

export default PaymentMethod
