import ReferenceUpload from 'components/room/common/ReferenceUpload'
import ReferenceList from 'components/room/side/ReferenceList'
import React from 'react'
import styled from 'styled-components'

function ReferenceContainer() {
  return (
    <Wrapper>
      <ReferenceList />
      <ReferenceUpload />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  flex: 1;

  display: flex;
  flex-direction: column;
  overflow-y: hidden;

  position: relative;
`

export default ReferenceContainer
