import React from 'react'
import styled from 'styled-components'
import { ReactComponent as GrayCheck } from '../../assets/icons/gray_check_box.svg'
import { ReactComponent as PurpleCheck } from '../../assets/icons/purple_check_box.svg'
import { ReactComponent as AllGrayCheck } from '../../assets/icons/all_gray_check_box.svg'
import { ReactComponent as AllPurpleCheck } from '../../assets/icons/all_purple_check_box.svg'
import { ReactComponent as EditPurpleCheck } from '../../assets/icons/withdrawal_check_box_checked.svg'
import { ReactComponent as EditGrayCheck } from '../../assets/icons/withdrawal_check_box_default.svg'

interface Props {
  checked: boolean
  onChange: React.ChangeEventHandler<HTMLInputElement>
  AllCheck?: boolean
  id: string
  type?: string
}
function CheckBox({ checked, onChange, AllCheck, id, type }: Props) {
  return (
    <Wrapper>
      {AllCheck ? (
        <label htmlFor={id}>
          {checked ? <AllPurpleCheck /> : <AllGrayCheck />}
          <input type="checkbox" id={id} onChange={onChange} />
        </label>
      ) : (
        <label htmlFor={id}>
          {type === 'edit' ? (
            checked ? (
              <EditPurpleCheck />
            ) : (
              <EditGrayCheck />
            )
          ) : checked ? (
            <PurpleCheck />
          ) : (
            <GrayCheck />
          )}
          <input type="checkbox" id={id} onChange={onChange} />
        </label>
      )}
    </Wrapper>
  )
}

export default CheckBox

const Wrapper = styled.div`
  & input {
    display: none;
  }
`
