import TeamDebateButton from 'components/room/content/TeamDebateButton'
import React from 'react'
import ModalWrapper from './ModalWrapper'
import { ViewModalWrapper } from './RoomSettingViewModal'
import { ReactComponent as CloseIcon } from 'assets/icons/close_white_icon.svg'
import styled from 'styled-components'

interface TeamMeetingViewModalProps {
  visible?: boolean
  onClose: () => void
}

function TeamMeetingViewModal({ visible, onClose }: TeamMeetingViewModalProps) {
  if (!visible) return null
  return (
    <ModalWrapper>
      <Wrapper>
        <TeamDebateButton />

        <div className="cancel">
          <div className="close-button-wrap" onClick={onClose}>
            <CloseIcon className="close-icon" />
          </div>
          <p className="cancel-label">취소</p>
        </div>
      </Wrapper>
    </ModalWrapper>
  )
}

const Wrapper = styled(ViewModalWrapper)``

export default TeamMeetingViewModal
