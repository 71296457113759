import React from 'react'
import useSWR from 'swr'

interface Props {
  noticeId: string
}
function useNoticeDetail({ noticeId }: Props) {
  return useSWR(`/notice/detail?notice_id=${noticeId}`)
}

export default useNoticeDetail
