import { createGlobalStyle } from 'styled-components'
import reset from 'styled-reset'
import { generateMedia } from 'styled-media-query'
import media from './media'

export const customMedia = generateMedia({
  desktop: '1200px',
  tablet: '768px',
  mobile: '360px',
})

export const GlobalStyles = createGlobalStyle`
  ${reset}
  * {
    box-sizing: border-box;
    font-family: "Noto Sans KR", sans-serif;
    -ms-overflow-style: none; 
    scrollbar-width: none;
  }

  *::-webkit-scrollbar {
    display : none;
  }
  body {
    -ms-overflow-style: none;
    font-family: "Noto Sans KR", sans-serif;
  }
  #root {
    ${media.medium} {
      overflow-x: hidden;
    }
  }
  html {
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    overflow-x: hidden !important;
    margin: 0;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox  */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  .pc-hidden {
    ${media.pc}{
      display : none !important;
    }
  }

  .mobile-hidden {
    ${media.medium}{
      display: none !important;
    }
  }

  .bold {
    font-weight: 700;
  }

  .toast{
    width: 420px;
    height: 52px;

    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 100px;

    padding: 14px 20px;

    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #0D0D0E;

    bottom: 50px;
  }

  .center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  #none {
    display: none;
  }
`
