import React from 'react'
import styled from 'styled-components'
import { ReactComponent as StarOff } from 'assets/icons/reiews_star_off.svg'
import { ReactComponent as StarOn } from 'assets/icons/reiews_star_on.svg'

interface GradeComponentProps {
  grade: number
}

function GradeComponent({ grade }: GradeComponentProps) {
  const offCount = 5 - grade

  return (
    <Wrapper>
      {Array.from({ length: grade }).map((_, index) => (
        <StarOn key={index} />
      ))}
      {Array.from({ length: offCount }).map((_, index) => (
        <StarOff key={index} />
      ))}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  gap: 5px;
`

export default GradeComponent
