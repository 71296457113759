import React from 'react'
import styled from 'styled-components'
import { ReactComponent as NoticeGrayIcon } from 'assets/icons/notice_gray_icon.svg'

function EmptyAudience() {
  return (
    <Wrapper>
      <NoticeGrayIcon />
      <p className="notice-text">참여한 방청객이 없습니다.</p>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  gap: 16px;

  & .notice-text {
    font-weight: 700;
    font-size: 16px;
    color: #b5b5b9;
    line-height: 26px;
  }
`

export default EmptyAudience
