import React from 'react'
// import ReactDOM from 'react-dom/client'
import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { RecoilRoot } from 'recoil'
import { BrowserRouter } from 'react-router-dom'
import { SWRConfig } from 'swr'
import { swrConfig } from 'lib/config'
import { GlobalStyles } from 'lib/styles/global-styles'
import 'swiper/css'
import 'swiper/css/navigation'

// const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

ReactDOM.render(
  <BrowserRouter>
    <GlobalStyles />
    <SWRConfig value={swrConfig}>
      <RecoilRoot>
        <App />
      </RecoilRoot>
    </SWRConfig>
  </BrowserRouter>,
  document.getElementById('root'),
)

reportWebVitals()
