import { confirmModalState } from 'atoms/modal'
import ConfirmModal from 'components/modal/ConfirmModal'
import React from 'react'
import { useRecoilValue } from 'recoil'
import { Toaster } from 'react-hot-toast'

function Core() {
  const confirmModal = useRecoilValue(confirmModalState)
  return (
    <>
      {confirmModal !== null && <ConfirmModal />}
      <Toaster />
    </>
  )
}

export default Core
