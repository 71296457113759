import React, { useEffect, useState } from 'react'
import Footer from 'components/common/Footer'
import Header from 'components/common/header/Header'
import MobileTitleHeader from 'components/common/header/MobileTitleHeader'
import ManageDetailComponent from 'components/common/manage/ManageDetailComponent'
import media from 'lib/styles/media'
import styled from 'styled-components'
import InviteModal from 'components/modal/InviteModal'
import { useRecoilValue, useRecoilState } from 'recoil'
import { cancleApplyModalState, inviteModalState } from 'atoms/modal'
import CancleApplyModal from 'components/modal/CancleApplyModal'
import StudentHeader from 'components/common/header/StudentHeader'
import TutorHeader from 'components/common/header/TutorHeader'
import useIndex from 'lib/hooks/useIndex'
import { PageTypeState } from 'atoms/common'

const ManageDetailPage = () => {
  const inviteModal = useRecoilValue(inviteModalState)
  const cancleApplyModal = useRecoilValue(cancleApplyModalState)

  const role = window.location.search
  const [pageType, setPageType] = useRecoilState(PageTypeState)

  useEffect(() => {
    if (role.includes('tutor')) {
      return setPageType('tutor')
    }
  }, [])

  const { data, mutate } = useIndex()

  return (
    <Wrapper>
      <Header />
      {pageType === 'user' ? <StudentHeader /> : <TutorHeader />}
      <MobileTitleHeader type="arrow" />
      <Content>
        <ManageDetailComponent
          type={pageType === 'user' ? 'student' : 'tutor'}
          userId={data?.data?.user?.id}
        />
      </Content>
      {pageType === 'user' && <Footer />}
      {inviteModal && <InviteModal />}
      {cancleApplyModal && <CancleApplyModal />}
    </Wrapper>
  )
}

export default ManageDetailPage

const Wrapper = styled.div``
const Content = styled.div`
  margin: 100px auto 120px;
  ${media.medium} {
    width: 100%;
    margin: 0 auto 80px;
  }
`
