import { AppRootWrapper } from 'components/layout/CommonStyle'
import api from 'lib/api'
import { resNicknamge, resSpace } from 'lib/utils/CommonFuncttion'
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Input from '../Input'
import EditProfileInput from './EditProfileInput'
import TutorTextAreaContent from '../joinInfo/TutorTextAreaContent'
import TutorDetailInfoContent from '../joinInfo/TutorDetailInfoContent'
import Button from '../Button'
import CheckPopup from '../CheckPopup'
import media from 'lib/styles/media'
import { useRecoilState } from 'recoil'
import { TutorEditInfoPayloadState } from 'atoms/join'
import TutorInputContent from '../joinInfo/TutorInputContent'

interface Props {
  name: string
}
function EditTutorInfoComponent({ name }: Props) {
  const [popup, setPopup] = useState(false)
  const [payload, setPayload] = useRecoilState(
    TutorEditInfoPayloadState('edit'),
  )
  const [preview, setPreview] = useState<File | undefined>()
  const [successNick, setSuccessNick] = useState(false)
  const [messageNick, setMessageNick] = useState('')
  const [errorNick, setErrorNick] = useState(false)
  const [errorNickMessage, setErrorNickMessage] = useState('')
  const [menu, setMenu] = useState('single')
  const [editTitle, setEditTitle] = useState('수정')
  const [isEdit, setIsEdit] = useState(true)
  const [isCancel, setIsCancel] = useState(false)
  const [chekNickname, setChekNickname] = useState(false)
  const [title, setTitle] = useState('')
  const [contents, setContents] = useState('')
  const TabBox = [
    { text: 'single', title: '단일 항목' },
    { text: 'multi', title: '여러 항목' },
  ]
  const detailMode = payload?.detailIntroduce ? 'edit' : 'add'
  const [detailDisabled, setDetailDisable] = useState(true)
  const [detailtIitleDisabled, setDetailtIitleDisable] = useState(true)

  useEffect(() => {
    if (!payload?.introduceType) return
    setMenu(payload?.introduceType)
  }, [payload?.introduceType])

  useEffect(() => {
    if (popup) {
      document.documentElement.style.overflow = 'hidden'
    } else {
      document.documentElement.style.overflow = 'auto'
    }

    return () => {
      document.documentElement.style.overflow = 'auto'
    }
  }, [popup])

  const handleEditProfile = (file: any, newFile: File) => {
    setPayload({
      ...payload,
      profile: file,
    })
    setPreview(newFile)
  }
  const handleDelImage = () => {
    setPayload({
      ...payload,
      profile: undefined,
    })
    setPreview(undefined)
  }
  const handleChange = (type: string) => (e: any) => {
    setPayload({
      ...payload,
      [type]: e.target.value,
    })
  }
  const handlenickNameCheck = async () => {
    if (!payload?.nickname) return
    if (String(payload?.nickname).length >= 2) {
      if (
        !resNicknamge.test(String(payload?.nickname)) &&
        !resSpace.test(String(payload?.nickname))
      ) {
        const formData = new FormData()
        formData.append('type', 'tutor')
        formData.append('nickname', String(payload?.nickname))
        const {
          data: { success, alert },
        } = await api.post('/member/nickname/check', formData)
        if (success) {
          setErrorNick(false)
          setSuccessNick(true)
          setMessageNick('사용 가능한 닉네임입니다.')
        } else {
          setSuccessNick(false)
          if (alert === '이미 사용중인 닉네임입니다.') {
            setErrorNick(true)
            setErrorNickMessage('중복 닉네임입니다.')
          } else {
            window.alert(alert)
          }
        }
      } else {
        setSuccessNick(false)
        setErrorNick(true)
        return setErrorNickMessage('띄어쓰기 및 특수문자는 사용할 수 없습니다.')
      }
    }
  }
  const handleTabClick = (text: string) => {
    if (text === '단일 항목') {
      setMenu('single')
    } else {
      setMenu('multi')
    }
  }

  const handleSubmit = async () => {
    if (!isEdit && !successNick) {
      setPopup(true)
      setTitle('닉네임 확인')
      setContents('닉네임 중복을 확인해 주세요.')
    } else {
      const formData = new FormData()
      formData.append('profile', payload?.profile)

      formData.append('introduce', String(payload?.introduce) ?? '')
      formData.append('career_url', String(payload?.careerUrl) ?? '')
      formData.append('name', String(payload?.name))
      formData.append('nickname', String(payload?.nickname))
      formData.append('detail_introduce_type', menu)
      payload?.detailIntroduce.map((item, i) => {
        formData.append(
          `detail_introduces[${item.title === null ? '' : item.title}]`,
          String(item.contents),
        )
        return item
      })
      payload?.profile === undefined &&
        formData.append('is_remove_profile', '1')

      const {
        data: { success, alert },
      } = await api.post('/mypage/modify', formData)

      if (success) {
        setPopup(true)
        setTitle('튜터 정보 변경 완료')
        setContents('튜터 정보 변경이 완료되었습니다.')
      } else {
        window.alert(alert)
      }
    }
  }
  const handleClick = () => {
    setPopup(false)
    if (title === '튜터 정보 변경 완료') return window.location.reload()
  }
  const onhandleChange = (value: string, index: number) => {
    const fineded = payload?.detailIntroduce?.find(
      (detail) => detail.index === index,
    )

    let modified = []

    if (fineded) {
      modified = (payload?.detailIntroduce ?? []).map((detail) => {
        if (detail.index === index) {
          return {
            ...detail,
            contents: value,
          }
        }
        return detail
      })
    } else {
      modified = [
        ...(payload?.detailIntroduce ?? []),
        {
          index,
          id: '',
          title: '',
          contents: value,
        },
      ]
    }

    setDetailDisable(value === '' || value.includes('<p><br></p>'))
    setPayload({
      ...payload,
      detailIntroduce: modified,
    })
  }
  const onTitlehandleChange = (value: string, index: number) => {
    const fineded = payload?.detailIntroduce?.find(
      (detail) => detail.index === index,
    )
    let modify = []

    if (fineded) {
      modify = (payload?.detailIntroduce ?? []).map((detail) => {
        if (detail.index === index) {
          return {
            ...detail,
            title: value,
          }
        }
        return detail
      })
    } else {
      modify = [
        ...(payload?.detailIntroduce ?? []),
        {
          index,
          id: '',
          title: value,
          contents: '',
        },
      ]
    }

    setDetailtIitleDisable(value === '')
    setPayload({
      ...payload,
      detailIntroduce: modify,
    })
  }
  const handleCancelClick = () => {
    setIsEdit(true)
    setEditTitle('수정')
    setIsCancel(false)
    setChekNickname(false)
    setErrorNick(false)
    setPayload({
      ...payload,
      nickname: name,
    })
  }

  const handleEditClick = () => {
    setIsEdit(false)
    setEditTitle('중복 확인')
    setIsCancel(true)
    setChekNickname(true)
  }
  return (
    <Wrapper>
      <Content>
        <div className="profile_box">
          <EditProfileInput
            data={payload?.profile}
            onChange={handleEditProfile}
            preview={preview}
          />
          {payload?.profile && (
            <p className="remove_image" onClick={handleDelImage}>
              사진 삭제
            </p>
          )}
        </div>
        <div className="notice_box">
          <p>가입 승인을 위해 반드시 튜터님 본인의 사진을 등록해주세요.</p>
        </div>
        <div className="name_info_box">
          <Input
            type="text"
            title="이름"
            placeholder="이름 입력"
            value={payload?.name}
            onChange={handleChange('name')}
            maxLength={10}
          />
          <Input
            type="text"
            title="닉네임"
            placeholder="2-10자 공백 및 특수문자 사용 불가"
            value={payload?.nickname}
            onChange={handleChange('nickname')}
            chekNickname={chekNickname}
            handlenickNameCheck={handlenickNameCheck}
            maxLength={10}
            error={errorNick}
            errorMessage={errorNickMessage}
            success={successNick}
            successMessage={messageNick}
            editTitle={editTitle}
            handleEditClick={handleEditClick}
            handleCancelClick={handleCancelClick}
            edit={isEdit}
            cancel={isCancel}
          />
        </div>
        <TutorTextAreaContent
          value={payload?.introduce}
          onChange={handleChange('introduce')}
        />
        <TutorDetailInfoContent
          Tab={TabBox}
          menu={menu}
          handleTabClick={handleTabClick}
          onhandleChange={onhandleChange}
          onTitlehandleChange={onTitlehandleChange}
          mode={detailMode}
          type="info"
        />
        <TutorInputContent
          title={'나의 경력을 확인할 수 있는 URL \n(SNS, 블로그 등)'}
          value={payload?.careerUrl}
          onChange={handleChange('careerUrl')}
          placeholder="https://example.com"
          maxLength={255}
        />
        <ButtonBox>
          <Button
            title="저장"
            onClick={handleSubmit}
            disabled={
              (menu === 'single'
                ? detailDisabled
                : detailDisabled && detailtIitleDisabled) ||
              (!payload?.profile && !payload?.introduce && !payload?.careerUrl)
            }
          />
        </ButtonBox>
      </Content>
      {popup && (
        <CheckPopup onClick={handleClick} title={title} content={contents} />
      )}
    </Wrapper>
  )
}

export default EditTutorInfoComponent
const Wrapper = styled.div`
  width: 100%;
`
const Content = styled.div`
  width: 830px;
  padding: 0 30px;
  margin: 0 auto;
  ${media.medium} {
    width: 100%;
    margin: 50px auto 0;
    padding: 0;
  }
  & div.profile_box {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    & p.remove_image {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      color: #ff2c2c;
      cursor: pointer;
      position: absolute;
      right: 0;
      top: -35px;
    }
  }
  & div.notice_box {
    width: 100%;
    background: #f9f9fb;
    border-radius: 8px;
    margin-top: 30px;
    padding: 12px 20px;
    ${media.medium} {
      margin-top: 25px;
      padding: 12px 40px;
    }
    & > p {
      font-weight: 400;
      font-size: 13px;
      line-height: 22px;
      text-align: center;
      color: #7c7c80;
    }
  }
  & div.name_info_box {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin-top: 40px;
    ${media.medium} {
      flex-direction: column;
    }
    & > div:first-of-type {
      ${media.medium} {
        margin-bottom: 20px;
      }
    }
    & > div:last-of-type {
      ${media.medium} {
        margin-bottom: 10px;
      }
    }
    & > div {
      width: calc(50% - 10px);
      ${media.medium} {
        width: 100%;
      }
    }
  }
`
const ButtonBox = styled.div`
  width: 380px;
  margin: 40px auto 0;
  ${media.medium} {
    width: 100%;
  }
`
