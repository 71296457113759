import React from 'react'
import styled from 'styled-components'

function PageLoadingComponent() {
  return <Wrapper>PageLoadingComponent</Wrapper>
}

const Wrapper = styled.div`
  opacity: 0;
`

export default PageLoadingComponent
