import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { ReactComponent as SearchIcon } from '../../assets/icons/input_search_icon.svg'
import { ReactComponent as SearchCloseIcon } from '../../assets/icons/search_close_icon.svg'
import useSWR from 'swr'
import api from 'lib/api'
import media from 'lib/styles/media'
import useIndex from 'lib/hooks/useIndex'
import useUser from 'lib/hooks/account/useUser'

interface SearchProps {
  placeholder: string
  onClick?: () => void
  type?: string
  preValue?: string
}
interface HistoryProps {
  id: number
  keyword: string
}
function Search({ placeholder, onClick, type, preValue }: SearchProps) {
  const history = useHistory()
  const { data } = useIndex()
  const { user } = useUser()
  const studentUser = user && user.type === 'user'
  const [value, setValue] = useState('')
  const [focus, setFocus] = useState(false)
  const { data: search, mutate } = useSWR(
    focus && studentUser && `/search/history`,
  )
  const searchData = search && search.data

  const onCloseClick = async (id: number) => {
    const {
      data: { success, alert },
    } = await api.delete(`/search/delete?id=${id}`)

    if (success) {
      mutate()
    } else {
      window.alert(alert)
    }
  }
  useEffect(() => {
    if (preValue) {
      setValue(preValue)
    }
  }, [preValue])

  return (
    <Wrapper className={focus ? 'active' : ''} onClick={onClick}>
      <input
        placeholder={placeholder}
        type="text"
        name="search"
        autoComplete="off"
        value={value ?? ''}
        onChange={(e) => {
          setValue(e.target.value)
        }}
        onKeyPress={(e) => {
          if (e.key === 'Enter' || e.keyCode === 13) {
            if (!value) return
            setValue(value)
            history.push(`/search/${value}`)
          }
        }}
        onFocus={() => {
          if (!type) {
            setFocus(true)
          }
        }}
        onBlur={() => {
          if (!type && focus) {
            setTimeout(() => {
              setFocus(false)
            }, 300)
          }
        }}
      />
      <SearchIcon
        onClick={() => {
          if (value === '') return
          history.push(`/search/${value}`)
        }}
      />
      {!type && focus && searchData && (
        <FocusSearch>
          <p className="search_title">최근 검색어</p>
          <div className="search_content">
            {searchData.search_histories.length > 0 ? (
              <>
                {searchData.search_histories.map(
                  (item: HistoryProps, i: number) => (
                    <div
                      className="search_history_content"
                      key={`search_history_list_${i}`}
                    >
                      <SearchIcon className="small_icon" />
                      <p
                        className="search_history_text"
                        onClick={() => {
                          history.push(`/search/${item.keyword}`)
                        }}
                      >
                        {item.keyword}
                      </p>
                      <SearchCloseIcon
                        onClick={() => {
                          onCloseClick(item.id)
                        }}
                      />
                    </div>
                  ),
                )}
              </>
            ) : (
              <div className="none_history">
                <p>최근 검색어 내역이 없습니다.</p>
              </div>
            )}
          </div>
        </FocusSearch>
      )}
    </Wrapper>
  )
}
export default Search

const Wrapper = styled.div`
  width: 400px;
  height: 46px;
  background: #ffffff;
  border: 1px solid #e1e1e2;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 11px 14px;
  position: relative;
  &.active {
    border: 1px solid #0d0d0e;
  }
  ${media.medium} {
    width: 100%;
  }
  & input {
    width: 90%;
    min-height: 24px;
    outline: none;
    border: 0;
    background: #fff;
    & input::placeholder {
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      color: #b5b5b9;
    }
  }
  & input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px #fff inset;
  }
`
const FocusSearch = styled.div`
  width: 400px;
  min-height: 160px;
  max-height: 240px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #e1e1e2;
  z-index: 2;
  position: absolute;
  left: 0;
  top: 50px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  & .search_title {
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    color: #7c7c80;
    margin-bottom: 6px;
    padding: 10px 14px;
  }
  & .search_history_content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 14px;
  }
  & .search_history_content:hover {
    background: #f9f9fb;
  }
  & .small_icon {
    width: 14px;
    height: 14px;
  }
  & .search_history_text {
    flex: 1;
    margin: 0 8px;
    font-weight: 400;
    font-size: 13px;
    line-height: 22px;
    color: #3b3b40;
    cursor: pointer;
  }
  & .none_history {
    padding: 49px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    & > p {
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      color: #b5b5b9;
    }
  }
`
