import {
  audiencesModalState,
  voteModalState,
  enterTeamRoomModalState,
  roomSettingState,
  roomSettingViewModalState,
  timeSettingModalState,
} from 'atoms/modal'
import AudiencesModal from 'components/modal/AudiencesModal'
import VoteModal from 'components/modal/VoteModal'
import EnterTeamRoomModal from 'components/modal/EnterTeamRoomModal'
import RoomSettingModal from 'components/modal/RoomSettingModal'
import RoomSettingViewModal from 'components/modal/RoomSettingViewModal'
import TimeSettingModal from 'components/modal/TimeSettingModal'
import React from 'react'
import { useRecoilValue } from 'recoil'

function RoomCore() {
  const audiencesModal = useRecoilValue(audiencesModalState)
  const voteModal = useRecoilValue(voteModalState)
  const roomSettingModal = useRecoilValue(roomSettingState)
  const roomSettingViewModal = useRecoilValue(roomSettingViewModalState)
  const timeSettingModal = useRecoilValue(timeSettingModalState)
  const enterTeamRoomModal = useRecoilValue(enterTeamRoomModalState)
  return (
    <>
      {roomSettingModal !== null && <RoomSettingModal />}
      {roomSettingViewModal !== null && <RoomSettingViewModal />}
      {timeSettingModal !== null && <TimeSettingModal />}
      {enterTeamRoomModal !== null && <EnterTeamRoomModal />}
      {audiencesModal && <AudiencesModal />}
      {voteModal && <VoteModal />}
    </>
  )
}

export default RoomCore
