import { getTutorReviewListRequest } from 'lib/api/tutor'
import useSWR from 'swr'

function useTutorReviewList({
  tutorId,
  page,
}: {
  tutorId: string
  page: number
}) {
  return useSWR(['tutorReviewList', page], async () => {
    const response = await getTutorReviewListRequest({
      tutorId,
      page,
    })

    if (!response.success) return
    return response.data.reviews
  })
}

export default useTutorReviewList
