import { AppRootWrapper } from 'components/layout/CommonStyle'
import React, { useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'
import media from 'lib/styles/media'
import useSWR from 'swr'
import {
  PriceNum,
  dateHyphenFormet,
  dateDiffHour,
} from 'lib/utils/CommonFuncttion'
import Button from '../Button'
import TwoCheckPopup from '../TwoCheckPopup'
import api from 'lib/api'
import StudentList from './StudentList'
import moment from 'moment'
import { useSetRecoilState } from 'recoil'
import { inviteModalState } from 'atoms/modal'
import CheckPopup from '../CheckPopup'
import useUser from 'lib/hooks/account/useUser'
import ClassStepSelect from './ClassStepSelect'

import { Swiper, Pagination, Autoplay } from 'swiper'

import { ReactComponent as Pause } from '../../../assets/icons/pause_icon.svg'
import { ReactComponent as Like } from '../../../assets/icons/like_gray_icon.svg'
import { ReactComponent as Like1 } from '../../../assets/icons/like_gray_line_icon.svg'
import { ReactComponent as Like2 } from '../../../assets/icons/like_pull_icon.svg'
import { ReactComponent as EmptyIcon } from '../../../assets/icons/no_data_icon.svg'
import { ReactComponent as Arrow } from '../../../assets/icons/detail_arrow_icon.svg'
import { ReactComponent as Close } from '../../../assets/icons/close_icon.svg'
import { ReactComponent as Top } from '../../../assets/icons/top_icon.svg'
import ToastPopup from '../ToastPopup'

Swiper.use([Pagination, Autoplay])

interface ImageProps {
  id: number
  image_url: string
}

interface Props {
  cancel_reason?: string
  class_user_cancel_request_id?: number
  grade: string
  id: number
  name: string
  phone: string
  profile_url: string
  school_type: string
  step: number
  user_id: number
}

const ManageDetailComponent = ({
  type,
  userId,
}: {
  type: 'student' | 'tutor'
  userId?: string | number
}) => {
  const history = useHistory()
  const params = useParams<{ id: string }>()
  const searchData = params.id

  const { user } = useUser()

  const setInviteModal = useSetRecoilState(inviteModalState)

  const [userType, setUserType] = useState<'student' | 'tutor'>('student')
  const [step, setStep] = useState('')
  const [stepInfo, setStepInfo] = useState<any>('')
  const [massage, setMassage] = useState(false)

  const { data, mutate } = useSWR(`/class/detail?class_id=${searchData}`)
  const originData = data?.data?.class

  const { data: feed, mutate: mutate2 } = useSWR(
    userType !== 'student' && `/class/feedback?class_id=${searchData}`,
  )

  const [twoPopup, setTwoPopup] = useState(false)
  const [popup, setPopup] = useState(false)

  const [disable, setDisable] = useState(false)
  const [max, setMax] = useState('')
  const [setting, setSetting] = useState([
    { id: '', name: '' },
    { id: '', name: '' },
    { id: '', name: '' },
  ])
  const [deleteSet, setDeleteSet] = useState<string[]>([])

  const [popupData, setPopupData] = useState({
    title: '',
    content: '',
    btn: '',
    id: '',
  })

  const popupSlide = useRef(null)

  useEffect(() => {
    if (originData && userId) {
      if (Number(userId) === originData.user_id && type === 'tutor') {
        setUserType('tutor')
      } else {
        setUserType('student')
      }
    } else {
      setUserType(type)
    }
  }, [userId, type, originData])

  useEffect(() => {
    if (feed?.data?.class_feedbacks && feed?.data?.class_feedbacks.length > 0) {
      const arr = []

      for (const f of feed?.data?.class_feedbacks) {
        arr.push({ id: f.id, name: f.name })
      }

      if (arr.length === 0) {
        arr.push({ id: '', name: '' })
        arr.push({ id: '', name: '' })
        arr.push({ id: '', name: '' })
      } else if (arr.length === 1) {
        arr.push({ id: '', name: '' })
        arr.push({ id: '', name: '' })
      } else if (arr.length === 2) {
        arr.push({ id: '', name: '' })
      }

      setSetting(arr)
    }

    if (feed?.data?.max_feedback_score) {
      setMax(feed?.data?.max_feedback_score)
    }
  }, [feed])

  useEffect(() => {
    let check = false

    for (const set of setting) {
      if (set.name === '') check = true
    }

    if (max === '' || (max !== '' && Number(max) <= 0)) check = true

    setDisable(check)
  }, [setting, max])

  useEffect(() => {
    if (originData) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      popupSlide.current = new Swiper('.detailSlide', {
        spaceBetween: 10,
        mousewheel: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        breakpoints: {
          0: {
            slidesPerView: 1,
          },
        },
      })

      if (originData.steps.length > 0 && originData.steps[0].id) {
        setStepInfo(originData.steps[0])
        setStep('1')
      }
    }
  }, [originData])

  const handleDelete = async () => {
    try {
      const {
        data: { success, alert },
      } = await api.delete('/class/delete?class_id=' + String(originData.id))
      if (success) {
        history.replace('/manage/list')
      } else {
        window.alert(alert)
      }
    } catch (err) {}
  }

  const handleClose = async () => {
    try {
      const formData = new FormData()
      formData.append('class_id', String(originData.id))

      const {
        data: { success, alert },
      } = await api.post('/class/close', formData)
      if (success) {
        mutate()
        setTwoPopup(false)
        setPopupData({
          title: '',
          content: '',
          btn: '',
          id: '',
        })
      } else {
        window.alert(alert)
      }
    } catch (err) {}
  }

  const handleCancle = async () => {
    try {
      const formData = new FormData()
      formData.append('class_user_id', popupData.id)

      const {
        data: { success, alert },
      } = await api.post('/class/cancel', formData)
      if (success) {
        mutate()
        setTwoPopup(false)
        setPopupData({
          title: '',
          content: '',
          btn: '',
          id: '',
        })
      } else {
        window.alert(alert)
      }
    } catch (err) {}
  }

  const handleButton = () => {
    if (userType === 'student') {
      if (!user) {
        setPopupData({
          title: '로그인 필요',
          content: `수강 신청을 위해 로그인이 필요합니다.\n로그인하시겠습니까?`,
          btn: '로그인',
          id: '',
        })
        setTwoPopup(true)
      } else {
        history.push('/class/request/' + searchData)
      }
    } else if (
      moment().isAfter(moment(stepInfo.step_begin_date).subtract(30, 'minutes'))
    ) {
      if (stepInfo.status === 1) {
        history.push('/room/' + originData.id + `?step=${stepInfo.step}`)
      } else {
        handleOpen()
      }
    } else {
      setPopupData({
        title: originData.is_close === 0 ? '모집 마감' : '마감 해제',
        content:
          originData.is_close === 0
            ? '모집 마감시 더 이상 수강신청할 수 없습니다.\n모집 마감하시겠습니까?'
            : '강의 마감을 해제하시겠습니까?',
        btn: originData.is_close === 0 ? '마감' : '해제',
        id: '',
      })
      setTwoPopup(true)
    }
  }

  const handleOpen = async () => {
    try {
      const formData = new FormData()
      formData.append('class_id', String(originData.id))
      formData.append('step', String(stepInfo.step))

      const {
        data: { success, alert },
      } = await api.post('/class/room/create', formData)
      if (success) {
        mutate()
      } else {
        window.alert(alert)
      }
    } catch (err) {}
  }

  const handleLike = async (like: boolean) => {
    if (!user) {
      history.push('/login')
    } else
      try {
        const formData = new FormData()
        formData.append('class_id', String(originData.id))

        const {
          data: { success, alert },
        } = await api.post('/class/like', formData)
        if (success) {
          mutate()

          if (!like) {
            setMassage(true)
            setTimeout(() => {
              setMassage(false)
            }, 1500)
          }
        } else {
          window.alert(alert)
        }
      } catch (err) {}
  }

  const handleSave = async () => {
    try {
      const formData = new FormData()
      formData.append('class_id', String(originData.id))
      formData.append('max_feedback_score', max)

      for (const feed of setting) {
        if (feed.id !== '')
          formData.append(`modify_feedbacks[${feed.id}]`, feed.name)
        else formData.append(`feedbacks[]`, feed.name)
      }
      for (const feed of deleteSet) {
        formData.append(`delete_feedbacks[]`, feed)
      }

      const {
        data: { success, alert },
      } = await api.post('/class/feedback/modify', formData)
      if (success) {
        mutate2()
      } else {
        window.alert(alert)
      }
    } catch (err) {}
  }

  if (!data) return null
  if (!data.data) return null

  const isBegin = moment().isAfter(
    moment(stepInfo.step_begin_date).subtract(30, 'minutes'),
  )
  const isJoin = originData.max_qty - originData.join_qty > 0

  return (
    <Wrapper
      className={
        userType !== 'student' &&
        originData?.join_qty > 0 &&
        originData.class_users &&
        originData.class_users.length > 0
          ? ''
          : 'empty'
      }
    >
      <ClassInfoArea>
        <BannerArea>
          {originData && (
            <div className="swiper-container detailSlide">
              <div className="swiper-wrapper">
                {originData?.images?.map((item: ImageProps, i: number) => (
                  <div
                    key={`root_main_banner_list_${i}`}
                    className="swiper-slide"
                  >
                    <BannerImage src={item.image_url} alt="banner" />
                  </div>
                ))}
              </div>
            </div>
          )}
          <BannerPagination>
            <div className="swiper-pagination"></div>
            <Pause
              onClick={() => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                if (popupSlide.current.autoplay.paused) {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-expect-error
                  popupSlide.current.autoplay.start()
                } else {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-expect-error
                  popupSlide.current.autoplay.pause()
                }
              }}
            />
          </BannerPagination>
        </BannerArea>
        <InfoBox>
          {originData?.is_public !== 1 && <Opened>비공개</Opened>}
          {originData.categories?.length > 0 && (
            <Category>
              {originData.categories.map(
                (cate: { category: string }, i: number) => {
                  if (i === 0) return cate?.category
                  else
                    return (
                      <>
                        <span />
                        {cate?.category}
                      </>
                    )
                },
              )}
            </Category>
          )}
          <Title>{originData.title}</Title>
          {originData?.steps?.length > 1 ? (
            <>
              <MultiTotal>
                <p>총 {originData?.steps?.length}회차</p>
                {Number(originData.price) > 0 ? (
                  <>
                    <h4>
                      회차당 수강료{' '}
                      {PriceNum(originData?.price / originData?.steps?.length) +
                        '원'}
                    </h4>
                    <h1>총 수강료 {PriceNum(originData.price) + '원'}</h1>
                  </>
                ) : (
                  <h1>무료강의</h1>
                )}
              </MultiTotal>
              <ClassStepSelect
                step={originData?.steps}
                select={stepInfo}
                type={userType}
                onChange={(info: any, i: number) => {
                  setStepInfo(info)
                  setStep(String(i))
                }}
              />
            </>
          ) : (
            <>
              <Label>강의일시</Label>
              {originData.status === 2 ? (
                <Value>
                  {dateHyphenFormet(originData.real_begin_date)} ~{' '}
                  {moment(originData.real_begin_date).format('YYYY.MM.DD') ===
                  moment(originData.real_end_date).format('YYYY.MM.DD')
                    ? moment(originData.real_end_date).format('HH:mm')
                    : dateHyphenFormet(originData.real_end_date)}{' '}
                  {dateDiffHour(
                    originData.real_begin_date,
                    originData.real_end_date,
                  )}
                </Value>
              ) : (
                <Value>
                  {dateHyphenFormet(originData.begin_date)} ~ (약{' '}
                  {originData.estimated_time}분)
                </Value>
              )}
              <Label>수강료</Label>
              <Value>
                <b>
                  {Number(originData.price) > 0
                    ? PriceNum(originData.price) + '원'
                    : '무료 강의'}
                </b>
              </Value>
            </>
          )}
          <ButtonArea
            className={
              (stepInfo.status === 2 || userType === 'student' ? 'max ' : '') +
              (userType === 'student'
                ? 'student'
                : stepInfo.status !== 2 &&
                  moment().isAfter(moment(originData.steps[0].step_begin_date))
                ? 'step'
                : '')
            }
          >
            {userType === 'student' ? (
              <>
                <Button
                  title={
                    stepInfo.status === 2
                      ? '종료된 강의입니다'
                      : !isBegin
                      ? !isJoin || originData.is_close === 1
                        ? '수강 인원 마감'
                        : originData.is_my_join === 'Y'
                        ? '수강완료'
                        : `수강신청 (남은 자리 ${
                            originData.max_qty - originData.join_qty
                          }개)`
                      : isBegin
                      ? originData.is_my_join === 'Y'
                        ? '수업 참여'
                        : originData.available_attend === 1
                        ? '방청하기'
                        : '방청이 불가한 강의입니다'
                      : '종료된 강의입니다'
                  }
                  disabled={
                    (!isBegin &&
                      (!isJoin ||
                        originData.is_close === 1 ||
                        originData.is_my_join === 'Y')) ||
                    (isBegin &&
                      originData.is_my_join === 'N' &&
                      originData.available_attend === 0) ||
                    stepInfo.status === 2
                  }
                  onClick={() => {
                    if (!user) {
                      setPopupData({
                        title: '로그인 필요',
                        content: `수강 신청을 위해 로그인이 필요합니다.\n로그인하시겠습니까?`,
                        btn: '로그인',
                        id: '',
                      })
                      setTwoPopup(true)
                    } else {
                      if (
                        !isBegin &&
                        isJoin &&
                        originData.is_close === 0 &&
                        originData.is_my_join === 'N'
                      ) {
                        history.push('/class/request/' + searchData)
                      } else if (isBegin) {
                        if (originData.is_my_join === 'Y')
                          history.push(
                            '/room/' + originData.id + `?step=${stepInfo.step}`,
                          )
                        else if (originData.available_attend === 1)
                          history.push(
                            '/room/' + originData.id + `?step=${stepInfo.step}`,
                          )
                      }
                    }
                  }}
                />
              </>
            ) : (
              <>
                {stepInfo.status !== 2 && (
                  // !moment().isAfter(
                  //   moment(originData.steps[0].step_begin_date),
                  // ) &&
                  <Button
                    title="강의 수정"
                    variant="outlined"
                    onClick={() =>
                      history.push('/register/class/' + originData.id)
                    }
                  />
                )}
                <Button
                  title={
                    stepInfo.status === 2 ? '종료된 강의입니다.' : '강의 삭제'
                  }
                  disabled={stepInfo?.status === 2}
                  onClick={() => {
                    setPopupData({
                      title: '강의 삭제',
                      content:
                        '삭제시 수강 신청한 수강생의 결제 금액이 모두<br/>환불됩니다. 강의를 삭제하시겠습니까?',
                      btn: '삭제',
                      id: '',
                    })
                    setTwoPopup(true)
                  }}
                />
              </>
            )}
            <LikeButton
              className={type}
              style={
                stepInfo.status === 2 &&
                userType === 'student' &&
                originData?.steps?.length === 1
                  ? { display: 'none' }
                  : {}
              }
              onClick={() => {
                if (userType === 'student') handleLike(originData.my_like === 1)
              }}
            >
              {userType === 'student' ? (
                originData.my_like === 1 ? (
                  <Like2 />
                ) : (
                  <Like1 />
                )
              ) : (
                <Like />
              )}
              {PriceNum(originData.like_count)}
            </LikeButton>
          </ButtonArea>
        </InfoBox>
      </ClassInfoArea>
      {userType === 'student' ? (
        <>
          <TutorArea>
            <h1>
              튜터 소개{' '}
              <button
                onClick={() =>
                  history.push(`/tutor/detail/${originData.user_id}`)
                }
              >
                자세히 보기
                <Arrow />
              </button>
            </h1>
            <TutorBox>
              <TutorProfile>
                <img src={originData.profile_url} />
                <div>
                  <h1>{originData.nickname.slice(0, 10)}</h1>
                  <p>{originData.name.slice(0, 10)}</p>
                </div>
              </TutorProfile>
              <TutorContent>
                <h1
                  dangerouslySetInnerHTML={{
                    __html: originData.nickname + ' 님을 소개합니다.',
                  }}
                />
                <p dangerouslySetInnerHTML={{ __html: originData.introduce }} />
              </TutorContent>
            </TutorBox>
          </TutorArea>
          <DetailIntroduce>
            <h1>강의 소개</h1>
            <span />
            {originData.detail_introduces.map((intro: any, i: number) => {
              if (originData.detail_introduce_type === 'multi') {
                return (
                  <EditorIntro key={'class_detail_intro_' + i}>
                    <h1>{intro.title}</h1>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: intro.contents,
                      }}
                    />
                  </EditorIntro>
                )
              } else {
                if (i === 0) {
                  return (
                    <EditorIntro key={'class_detail_intro_' + i}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: intro.contents,
                        }}
                      />
                    </EditorIntro>
                  )
                } else return ''
              }
            })}
          </DetailIntroduce>
        </>
      ) : (
        <>
          <StudentArea
            className={
              originData.join_qty > 0 &&
              originData.class_users &&
              originData.class_users.length > 0
                ? ''
                : 'empty'
            }
          >
            <h1>
              수강생 목록 ({originData.join_qty}/{originData.max_qty})
            </h1>
            {originData.join_qty > 0 &&
            originData.class_users &&
            originData.class_users.length > 0 ? (
              originData.class_users.map((student: Props, i: number) => (
                <StudentList
                  key={'manage_detail_students_' + i}
                  data={student}
                  max={
                    originData.max_feedback_score *
                    feed?.data?.class_feedbacks.length
                  }
                  type={String(stepInfo.status)}
                  mutate={mutate}
                  isSetting={
                    feed?.data?.class_feedbacks &&
                    feed?.data?.class_feedbacks.length > 0
                  }
                  classId={originData.id}
                  handleCancle={(id: string) => {
                    setPopupData({
                      title: '수강 취소',
                      content:
                        '취소 신청한 수강생의 결제 금액이 환불됩니다.<br/>수강을 취소하시겠습니까?',
                      btn: '수강 취소',
                      id: id,
                    })
                    setTwoPopup(true)
                  }}
                />
              ))
            ) : (
              <Empty>
                <EmptyIcon />
                등록된 수강생이 없습니다.
                {originData.join_qty < originData.max_qty &&
                  stepInfo.status !== 2 && (
                    <Button
                      title="수강생 초대"
                      variant="outlined"
                      onClick={() =>
                        setInviteModal({
                          id: originData.id,
                          count: originData.max_qty - originData.join_qty,
                          onClose: () => setPopup(true),
                          mutate: () => mutate(),
                        })
                      }
                    />
                  )}
              </Empty>
            )}
          </StudentArea>
          {stepInfo.status === 2 ? (
            <FeedBackArea>
              <h1>수강생 피드백 항목 설정</h1>
              <InputBox>
                <b>평가 항목당 최대 점수</b>
                <div>
                  <input
                    type="text"
                    placeholder={
                      feed?.data?.max_feedback_score
                        ? feed?.data?.max_feedback_score
                        : '0'
                    }
                    value={max}
                    onChange={(e) => {
                      let value = e.target.value
                      value = value.replace(/[^0-9]/g, '')

                      if (Number(value) <= 100) setMax(value)
                      else setMax('100')
                    }}
                  />
                  <Unit>점</Unit>
                </div>
              </InputBox>
              <Line />
              <SettingArea>
                평가 항목 설정
                <Button
                  title="항목추가"
                  className="small"
                  onClick={() => {
                    if (setting.length < 10)
                      setSetting((prev) => [...prev, { id: '', name: '' }])
                  }}
                />
              </SettingArea>
              {setting.map((data, i) => (
                <InputBox key={'class_feedback_ques_' + i}>
                  <p>평가 항목명{i + 1}</p>
                  <div>
                    <input
                      type="text"
                      placeholder="평가 항목명을 입력해주세요"
                      value={setting[i].name}
                      onChange={(e) => {
                        if (e.target.value.length <= 10) {
                          const arr = [...setting]
                          arr[i].name = e.target.value
                          setSetting(arr)
                        }
                      }}
                    />
                    {i > 2 && (
                      <button
                        onClick={() => {
                          const arr = [...setting]
                          const arr2 = [...deleteSet]

                          if (setting[i].id !== '') {
                            arr2.push(setting[i].id)
                          }

                          arr.splice(i, 1)
                          setSetting(arr)
                          setDeleteSet(arr2)
                        }}
                      >
                        <Close />
                      </button>
                    )}
                  </div>
                </InputBox>
              ))}
              <FeedButton>
                <Button
                  title="설정 저장"
                  disabled={disable}
                  onClick={handleSave}
                />
              </FeedButton>
            </FeedBackArea>
          ) : (
            originData.join_qty > 0 &&
            originData.class_users &&
            originData.class_users.length > 0 &&
            originData.join_qty < originData.max_qty &&
            stepInfo.status !== 2 && (
              <Button
                title="수강생 초대"
                variant="outlined"
                onClick={() =>
                  setInviteModal({
                    id: originData.id,
                    count: originData.max_qty - originData.join_qty,
                    onClose: () => setPopup(true),
                    mutate: () => mutate(),
                  })
                }
              />
            )
          )}
        </>
      )}
      {twoPopup && (
        <TwoCheckPopup
          title={popupData.title}
          content={popupData.content}
          oncancelClick={() => {
            setTwoPopup(false)
          }}
          onClick={() => {
            if (popupData.title.includes('로그인')) history.push('/login')
            else if (popupData.title.includes('마감')) handleClose()
            else if (popupData.title === '수강 취소') handleCancle()
            else handleDelete()
          }}
          btn={popupData.btn}
        />
      )}
      {popup && (
        <CheckPopup
          onClick={() => setPopup(false)}
          title="강의 수강생 초대 실패"
          content="수강생 목록 변동으로 인해<br/>수강생 초대를 실패했습니다.<br/>다시 시도해주세요."
        />
      )}
      {stepInfo.status !== 2 &&
        !(
          userType !== 'student' &&
          originData.steps.length > 1 &&
          moment().isAfter(
            moment(originData.steps[0].step_begin_date).subtract(30, 'minutes'),
          ) &&
          !isBegin
        ) && (
          <FloatArea>
            <div>
              <FixedText>
                {originData?.is_public !== 1 && (
                  <Opened className="fixed">비공개</Opened>
                )}
                {originData.title}
              </FixedText>
              <FixedButton>
                {userType === 'student' ? (
                  <Button
                    title={
                      stepInfo.status === 2
                        ? '종료된 강의입니다'
                        : !isBegin
                        ? !isJoin || originData.is_close === 1
                          ? '수강 인원 마감'
                          : originData.is_my_join === 'Y'
                          ? '수강완료'
                          : `수강신청 (남은 자리 ${
                              originData.max_qty - originData.join_qty
                            }개)`
                        : isBegin
                        ? originData.is_my_join === 'Y'
                          ? '수업 참여'
                          : originData.available_attend === 1
                          ? '방청하기'
                          : '방청이 불가한 강의입니다'
                        : '종료된 강의입니다'
                    }
                    disabled={
                      (!isBegin &&
                        (!isJoin ||
                          originData.is_close === 1 ||
                          originData.is_my_join === 'Y')) ||
                      (isBegin &&
                        originData.is_my_join === 'N' &&
                        originData.available_attend === 0) ||
                      stepInfo.status === 2
                    }
                    onClick={() => {
                      if (!user) {
                        setPopupData({
                          title: '로그인 필요',
                          content: `수강 신청을 위해 로그인이 필요합니다.\n로그인하시겠습니까?`,
                          btn: '로그인',
                          id: '',
                        })
                        setTwoPopup(true)
                      } else {
                        if (
                          !isBegin &&
                          isJoin &&
                          originData.is_close === 0 &&
                          originData.is_my_join === 'N'
                        ) {
                          history.push('/class/request/' + searchData)
                        } else if (isBegin) {
                          if (originData.is_my_join === 'Y')
                            history.push('/room/' + originData.id)
                          else if (originData.available_attend === 1)
                            history.push('/room/' + originData.id)
                        }
                      }
                    }}
                  />
                ) : (
                  <Button
                    title={
                      isBegin
                        ? stepInfo.status === 1
                          ? '강의 입장'
                          : '온라인 교실 생성'
                        : originData.is_close === 0
                        ? '수강 신청 마감'
                        : '수강 신청 마감 해제'
                    }
                    onClick={handleButton}
                  />
                )}
                {userType === 'student' && (
                  <LikeButton
                    className={type}
                    onClick={() => {
                      handleLike(originData.my_like === 1)
                    }}
                    style={{ marginLeft: '10px' }}
                  >
                    {originData.my_like === 1 ? <Like2 /> : <Like1 />}
                    {PriceNum(originData.like_count)}
                  </LikeButton>
                )}
              </FixedButton>
            </div>
          </FloatArea>
        )}
      <TopIcon
        onClick={() => {
          window.scrollTo({ top: 0, behavior: 'smooth' })
        }}
      />
      {massage && <ToastPopup title="찜한 강의에 추가되었어요!" />}
    </Wrapper>
  )
}

export default ManageDetailComponent

const TopIcon = styled(Top)`
  position: fixed;
  bottom: 121px;
  right: 276px;
  width: 64px;
  height: 64px;
  cursor: pointer;

  ${media.medium} {
    bottom: 90px;
    right: 20px;
  }
`

const MultiTotal = styled.div`
  & > p {
    font-weight: 500;
    font-size: 14px;
    line-height: 1.71;
    color: #0d0d0e;
    margin-bottom: 10px;
  }

  & > h4 {
    font-weight: 500;
    font-size: 14px;
    line-height: 1.71;
    color: #7c7c80;
    margin-bottom: 4px;
  }

  & > h1 {
    font-weight: 900;
    font-size: 18px;
    line-height: 1.56;
    color: #0d0d0e;
    margin-bottom: 14px;
  }

  ${media.medium} {
    & > p {
      font-size: 13px;
      line-height: 1.69;
      margin-bottom: 6px;
    }

    & > h4 {
      font-size: 13px;
      line-height: 1.69;
      margin-bottom: 6px;
    }

    & > h1 {
      font-size: 16px;
      line-height: 1.62;
    }
  }
`

const Unit = styled.div`
  position: absolute;
  top: 50%;
  right: 14px;
  transform: translateY(-50%);
  font-weight: 700;
  font-size: 14px;
  line-height: 1.71;
  color: #b5b5b9;
`

const SettingArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  font-size: 20px;
  line-height: 1.3;
  color: #0d0d0e;
  margin-bottom: 20px;

  & > button {
    max-width: 200px;
  }

  ${media.medium} {
    font-size: 16px;
    line-height: 1.62;
    display: block;

    & > button {
      max-width: 100%;
      margin-top: 20px;
    }
  }
`

const InputBox = styled.div`
  display: flex;
  align-items: center;

  &:nth-of-type(n + 2) {
    margin-top: 10px;
  }

  & > p {
    width: 280px;
    min-width: 280px;
    font-weight: 400;
    font-size: 17px;
    line-height: 1.53;
    color: #3b3b40;
  }

  & > b {
    width: 280px;
    min-width: 280px;
    font-weight: 500;
    font-size: 20px;
    line-height: 1.5;
    color: #0d0d0e;
  }

  & > div {
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;

    & > input {
      width: 100%;
      border: 1px solid #e1e1e2;
      border-radius: 8px;
      padding: 11px 14px;
      font-weight: 700;
      font-size: 14px;
      line-height: 1.71;
      color: #3b3b40;

      &::placeholder {
        color: #b5b5b9;
      }
    }

    & > button {
      width: 46px;
      min-width: 46px;
      height: 46px;
      margin-left: 10px;
      border: 1px solid #eeeff1;
      border-radius: 8px;
      background: #f9f9fb;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      outline: none;

      & > svg {
        width: 20px;
        min-width: 20px;
        height: 20px;
      }
    }
  }

  ${media.medium} {
    display: block;

    &:nth-of-type(n + 2) {
      margin-top: 20px;
    }

    & > p {
      width: 100%;
      font-size: 13px;
      line-height: 1.69;
      color: #0d0d0e;
    }

    & > b {
      width: 100%;
      font-size: 16px;
      line-height: 1.62;
    }

    & > div {
      margin-top: 10px;

      & > input {
        color: #3b3b40;
      }
    }
  }
`

const Line = styled.div`
  width: 100%;
  height: 1px;
  background: #eeeff1;
  margin: 30px 0;
`

const FeedButton = styled.div`
  & > button {
    max-width: 380px;
    margin: 40px auto 0;
  }

  ${media.medium} {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #fff;
    padding: 20px;

    & > button {
      max-width: 100%;
      height: 50px;
      margin: 0;
    }
  }
`

const FeedBackArea = styled.div`
  margin-top: 70px;

  & > h1 {
    font-weight: 700;
    font-size: 22px;
    line-height: 1.55;
    color: #0d0d0e;
    margin-bottom: 40px;
  }

  ${media.medium} {
    margin-top: 65px;

    & > h1 {
      font-size: 18px;
      line-height: 1.56;
      margin-bottom: 30px;
    }
  }
`

const EditorIntro = styled.div`
  display: flex;
  align-items: start;

  & > h1 {
    width: 214px;
    min-width: 214px;
    font-weight: 500;
    font-size: 17px;
    line-height: 1.53;
    color: #3b3b40;
    margin-right: 20px;
    word-break: break-all;
  }

  & > div {
    width: 100%;
  }

  &:nth-of-type(n + 2) {
    margin-top: 40px;
  }

  ${media.medium} {
    display: block;

    & > h1 {
      width: 100%;
      margin-right: 0;
      margin-bottom: 20px;
    }
  }
`

const DetailIntroduce = styled.div`
  & > h1 {
    font-weight: 700;
    font-size: 22px;
    line-height: 1.55;
    color: #0d0d0e;
  }

  & > span {
    display: block;
    width: 100%;
    height: 1px;
    background: #e1e1e2;
    margin: 20px 0 50px;
  }

  ${media.medium} {
    & > h1 {
      font-size: 18px;
      line-height: 1.56;
    }

    & > span {
      margin: 20px 0 30px;
    }
  }
`

const TutorContent = styled.div`
  width: 100%;
  padding: 20px 24px;
  background: #f9f9fb;
  border-radius: 20px 20px 20px 0px;

  & > h1 {
    font-weight: 700;
    font-size: 18px;
    line-height: 1.56;
    color: #0d0d0e;
    margin-bottom: 8px;
  }

  & > p {
    font-weight: 400;
    font-size: 16px;
    line-height: 1.62;
    color: #707070;
  }

  ${media.medium} {
    padding: 20px;
    border-radius: 20px;

    & > h1 {
      font-size: 14px;
      line-height: 1.71;
      margin-bottom: 6px;
    }

    & > p {
      font-size: 13px;
      line-height: 1.85;
    }
  }
`

const TutorProfile = styled.div`
  width: 148px;

  & > img {
    width: 78px;
    min-width: 78px;
    height: 78px;
    border-radius: 100px;
    object-fit: cover;
    margin: auto;
    display: block;
  }

  & > div {
    margin-top: 8px;

    & > h1 {
      font-weight: 500;
      font-size: 16px;
      line-height: 1.62;
      text-align: center;
      color: #0d0d0e;
    }

    & > p {
      font-weight: 500;
      font-size: 14px;
      line-height: 1.71;
      text-align: center;
      color: #b5b5b9;
    }
  }

  ${media.medium} {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;

    & > img {
      width: 54px;
      min-width: 54px;
      height: 54px;
    }

    & > div {
      margin-top: 0;
      margin-left: 10px;
      width: 100%;

      & > h1 {
        text-align: left;
      }

      & > p {
        text-align: left;
      }
    }
  }
`

const TutorBox = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  padding: 20px;
  border: 1px solid #e1e1e2;
  border-radius: 14px;

  ${media.medium} {
    display: block;
  }
`

const TutorArea = styled.div`
  margin: 107px 0 70px;

  & > h1 {
    font-weight: 700;
    font-size: 22px;
    line-height: 1.55;
    color: #0d0d0e;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > button {
      margin: 0;
      padding: 0;
      border: 0;
      background: none;
      outline: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;

      & > svg {
        width: 8px;
        height: 14px;
        margin-left: 6px;
      }
    }
  }

  ${media.medium} {
    margin: 65px 0;

    & > h1 {
      font-size: 18px;
      line-height: 1.56;
      margin-bottom: 18px;
    }
  }
`

const FixedText = styled.div`
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 20px;
  line-height: 1.5;
  color: #3b3b40;

  ${media.medium} {
    display: none;
  }
`

const FixedButton = styled.div`
  display: flex;
  align-items: center;
  min-width: 321px;

  & > button {
    max-width: 320px;
  }

  ${media.medium} {
    width: 100%;

    & > button {
      max-width: 100%;
      height: 50px;
    }
  }
`

const FloatArea = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background: #ffffff;
  border-top: solid 1px #e1e1e2;
  padding: 0 20px;

  & > div {
    max-width: 1200px;
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: auto;
  }

  ${media.medium} {
    border: 0;
    padding: 20px;

    & > div {
      height: auto;
    }
  }
`

const Empty = styled.div`
  padding: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.62;
  color: #b5b5b9;

  & > svg {
    width: 38px;
    height: 38px;
    margin-bottom: 16px;
  }

  & > button {
    margin-top: 30px;
    max-width: 175px;
  }

  ${media.medium} {
    padding: 125px 0;

    & > button {
      margin-top: 20px;
      max-width: 200px;
      height: 50px;
    }
  }
`

const StudentArea = styled.div`
  margin: 107px 0 40px;

  & > h1 {
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 22px;
    line-height: 1.55;
    color: #0d0d0e;
  }

  &.empty {
    margin-bottom: 0;
  }

  ${media.medium} {
    margin: 65px 0 20px;

    & > h1 {
      font-size: 18px;
      line-height: 1.56;
    }
  }
`

const LikeButton = styled.button`
  width: 50px;
  min-width: 50px;
  height: 50px;
  background: #f9f9fb;
  border: 1px solid #e1e1e2;
  border-radius: 4px;
  padding: 10px 6px 8px;
  font-weight: 500;
  font-size: 10px;
  line-height: 1;
  text-align: center;
  color: #7c7c80;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > svg {
    width: 18px;
    height: 16px;
    margin-bottom: 6px;
  }

  &.student {
    cursor: pointer;
    background: #ffffff;
  }
`

const ButtonArea = styled.div`
  display: flex;
  align-items: center;

  & > button {
    max-width: 125px;

    &:nth-of-type(n + 2) {
      margin-left: 10px;
    }
  }

  &.student {
    & > button {
      max-width: calc(100% - 60px);
    }
  }

  &.step {
    & > button {
      max-width: calc(100% - 60px);
    }
  }

  &.student {
    & > button {
      max-width: calc(100% - 60px);

      &:last-of-type {
        cursor: pointer;
        background: #ffffff;
      }
    }
  }

  &.max {
    & > button:first-of-type {
      max-width: 100%;
    }
  }

  ${media.medium} {
    & > button {
      max-width: calc((100% - 62px) / 2);
      height: 50px;

      &:nth-of-type(n + 2) {
        margin-left: 6px;
      }
    }
  }
`

const Value = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 1.71;
  color: #0d0d0e;
  margin-bottom: 16px;

  & > b {
    font-weight: 900;
    font-size: 18px;
    line-height: 1.56;
    margin-bottom: 4px;
  }

  ${media.medium} {
    font-size: 13px;
    line-height: 1.69;

    & > b {
      font-size: 16px;
      line-height: 1.62;
      margin-bottom: 20px;
    }
  }
`

const Label = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 1.71;
  color: #7c7c80;
  margin-bottom: 4px;

  ${media.medium} {
    margin-top: 14px;
    margin-bottom: 0;
    font-size: 13px;
    line-height: 1.69;

    &:nth-child(n + 2) {
      margin-bottom: 4px;
      margin-top: 6px;
    }
  }
`

const Title = styled.h1`
  height: 100%;
  font-weight: 700;
  font-size: 20px;
  line-height: 1.5;
  color: #3b3b40;
  word-break: break-all;

  ${media.medium} {
    font-size: 16px;
    line-height: 1.62;
  }
`

const Category = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 1.71;
  color: #7c7c80;
  cursor: pointer;
  margin-bottom: 4px;

  & > span {
    display: inline-block;
    width: 2px;
    min-width: 2px;
    height: 2px;
    margin: 6px;
    background: #7c7c80;
  }

  ${media.medium} {
    font-size: 13px;
    line-height: 1.69;
  }
`

const Opened = styled.div`
  width: fit-content;
  font-weight: 700;
  font-size: 14px;
  line-height: 1.57;
  color: #fff;
  margin-bottom: 10px;
  background: #b5b5b9;
  border-radius: 3px;
  padding: 3px 8px;

  &.fixed {
    margin-right: 10px;
    margin-bottom: 0px;
  }

  ${media.medium} {
    &.fixed {
      display: none;
    }
  }
`

const InfoBox = styled.div`
  width: 380px;
  height: 450px;
  border: 1px solid #e1e1e2;
  border-radius: 14px;
  padding: 30px;
  display: flex;
  flex-direction: column;

  ${media.medium} {
    margin-top: 45px;
    width: 100%;
    height: auto;
    padding: 20px;
  }
`

const BannerPagination = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: calc(100% + 30px);
  left: 0;
  width: 100%;
  height: 7px;

  & > svg {
    width: 6px;
    height: 7px;
    margin-left: 9px;
    cursor: pointer;
  }

  & .swiper-pagination {
    position: static;
    display: flex;
    align-items: center;
  }

  & .swiper-pagination-bullet {
    width: 7px;
    min-width: 7px;
    height: 7px;
    border: 1px solid #0d0d0e;
    border-radius: 100px;

    &:nth-of-type(n + 2) {
      margin-left: 6px;
    }
  }

  & .swiper-pagination-bullet-active {
    width: 18px;
    min-width: 18px;
    background: #0d0d0e;
  }

  ${media.medium} {
    top: calc(100% + 20px);
    height: 5px;

    & > svg {
      width: 5px;
      height: 5px;
      margin-left: 5px;
    }

    & .swiper-pagination-bullet {
      width: 5px;
      min-width: 5px;
      height: 5px;

      &:nth-of-type(n + 2) {
        margin-left: 3px;
      }
    }

    & .swiper-pagination-bullet-active {
      width: 12px;
      min-width: 12px;
    }
  }
`

const BannerImage = styled.img`
  width: 100%;
  height: 450px;
  object-fit: cover;
  border-radius: 14px;

  ${media.medium} {
    height: 180px;
  }
`

const BannerArea = styled.div`
  width: 100%;
  max-width: calc(100% - 400px);
  height: 450px;
  margin-right: 20px;
  position: relative;

  & .detailSlide {
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    border-radius: 14px;
  }

  ${media.medium} {
    max-width: 100%;
    height: 180px;
    margin-right: 0;
  }
`

const ClassInfoArea = styled.div`
  display: flex;
  justify-content: space-between;

  ${media.medium} {
    display: block;
  }
`

const Wrapper = styled(AppRootWrapper)`
  padding: 40px 0 0;
  margin-bottom: 180px;

  &.empty {
    margin-bottom: 105px;
  }

  & > button {
    max-width: 380px;
    margin: auto;
  }

  ${media.medium} {
    padding: 0;
    margin-bottom: 194px;

    &.empty {
      margin-bottom: 154px;
    }

    & > button {
      height: 50px;
    }
  }
`
