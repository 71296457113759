import { AppRootWrapper } from 'components/layout/CommonStyle'
import React from 'react'
import styled from 'styled-components'
import Button from '../Button'
import SmsInput from '../SmsInput'
import { useHistory, useParams } from 'react-router-dom'
import { ReactComponent as KakaoIcon } from '../../../assets/icons/find_id_kakao_icon.svg'
import media from 'lib/styles/media'
import LineButton from '../LineButton'

interface Props {
  pageType: 'user' | 'tutor'
}
function FindIdComponent({ pageType }: Props) {
  const params = useParams<{ findId: string }>()
  const findId = params.findId
  const kakao = window.location.search
  const social = kakao.includes('social=kakao')
  const history = useHistory()

  return (
    <Wrapper>
      <Content>
        <div className="title_box">
          <p className="find_info_title">아이디 찾기 결과</p>
          <p className="find_info_text">
            입력하신 정보로 조회되는 아이디입니다.
          </p>
        </div>

        {social ? (
          <div className="find_content_box">
            <KakaoIcon className="kakao_icon" /> <p>{findId}</p>
          </div>
        ) : (
          <div className="find_content_box">
            <p>{findId}</p>
          </div>
        )}

        <div className="find_button_box">
          <LineButton
            type="gray"
            title="비밀번호 찾기"
            onClick={() => {
              pageType === 'tutor'
                ? history.push('/find/password?role=tutor')
                : history.push('/find/password')
            }}
          />
          <Button
            title="로그인하기"
            onClick={() => {
              pageType === 'tutor'
                ? history.push('/login?role=tutor')
                : history.push('/login')
            }}
          />
        </div>
      </Content>
    </Wrapper>
  )
}

export default FindIdComponent
const Wrapper = styled(AppRootWrapper)``
const Content = styled.div`
  width: 440px;
  height: 680px;
  margin: 0 auto;
  padding: 80px 0 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${media.medium} {
    padding: 20px 0 80px;
    height: 100%;
    width: 100%;
  }
  & p.find_info_title {
    font-weight: 700;
    font-size: 22px;
    line-height: 34px;
    text-align: center;
    color: #0d0d0e;
    ${media.medium} {
      font-size: 18px;
      line-height: 28px;
    }
  }
  & p.find_info_text {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #3b3b40;
    margin-top: 6px;
  }
  & div.find_content_box {
    margin: 80px 0 100px;
    width: 380px;
    display: flex;
    align-items: center;
    justify-content: center;
    & .kakao_icon {
      margin-right: 10px;
    }
    & > p {
      font-weight: 700;
      font-size: 22px;
      line-height: 34px;
      text-align: center;
      color: #3b3b40;
    }
  }
  & div.find_button_box {
    width: 380px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    ${media.medium} {
      width: 100%;
    }
    & > button {
      width: calc(50% - 5px);
      margin: 0;
      height: 50px;
    }
  }
`
