import React, { useState } from 'react'
import styled from 'styled-components'
import { Chart, Radar } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from 'chart.js'
import media from 'lib/styles/media'

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
)

interface Props {
  data: Array<{
    comment: string
    id: number
    name: string
    score: number
  }>
  maxScore: number
}

function FeedbackChart({ data, maxScore }: Props) {
  const scoreType = data.map((item, i) => item.name)
  const score = data.map((item, i) => item.score)

  const feedbackData = {
    labels: scoreType,
    datasets: [
      {
        label: ' score ',
        data: score,
        backgroundColor: 'rgba(117, 116, 216, 0.1)',
        borderColor: '#7574D8',
        borderWidth: 3,
        pointBorderWidth: 6,
        pointBackgroundColor: '#FFF',
      },
    ],
  }
  const feedbackOption = {
    scale: {
      ticks: {
        beginAtZero: true,
        min: 0,
        max: maxScore,
        stepSize: 5,
        font: {
          size: 0,
        },
      },
      pointLabels: {
        fontSize: 13,
        fontColor: '#7C7C80',
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  }

  return (
    <Wrapper>
      <div className="feedback_title_box">
        <p>피드백 총평</p>
      </div>
      <div className="feedback_chart_box">
        <Radar
          data={feedbackData}
          options={feedbackOption}
          width={600}
          height={550}
        />
      </div>
    </Wrapper>
  )
}

export default FeedbackChart
const Wrapper = styled.div`
  margin-bottom: 50px;
  ${media.medium} {
    margin-bottom: 60px;
  }
  & div.feedback_title_box {
    & > p {
      font-weight: 500;
      font-size: 17px;
      line-height: 26px;
      color: #0d0d0e;
      ${media.medium} {
        font-size: 16px;
      }
    }
  }
  & div.feedback_chart_box {
    min-width: 600px;
    min-height: 550px;
    display: flex;
    align-items: center;
    justify-content: center;
    ${media.medium} {
      min-width: 100%;
      min-height: 300px;
      margin-top: 40px;
    }
    & > canvas {
      max-width: 620px;
      max-height: 560px;
      ${media.medium} {
        max-width: 100%;
        max-height: 315px;
      }
    }
  }
`
