import { AppRootWrapper } from 'components/layout/CommonStyle'
import media from 'lib/styles/media'
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import EditMenuTabContent from './EditMenuTabContent'
import { useHistory } from 'react-router-dom'
import EditInfoPhoneComponent from './EditInfoPhoneComponent'
import EditInfoLoginComponent from './EditInfoLoginComponent'
import EditInfoBasicComponent from './EditInfoBasicComponent'
import useSWR from 'swr'
import { useRecoilState, useSetRecoilState } from 'recoil'
import EditInfoPaymentComponent from './EditInfoPaymentComponent'
import EditTutorInfoComponent from './EditTutorInfoComponent'
import { TutorEditInfoPayloadState } from 'atoms/join'
import { refatoringDetailInfo } from 'lib/utils'
import useUser from 'lib/hooks/account/useUser'

function EditMyInfoComponent() {
  const history = useHistory()
  const [location, setLocation] = useState('')
  const [type, setType] = useState('')
  const setPayload = useSetRecoilState(TutorEditInfoPayloadState('edit'))
  const phone = type.includes('phone')
  const login = type.includes('login')
  const basic = type.includes('basic')
  const info = type.includes('info')
  const price = type.includes('price')
  const { user } = useUser()
  const typeUser = user && user.type

  useEffect(() => {
    setType(window.location.search.slice(6))
    setLocation(window.location.search)
  }, [])

  const onHandleClick = (type: string) => {
    setType(type.slice(6))
    setLocation(type)
    history.replace(type)
  }

  const { data: userPhone } = useSWR(phone && '/mypage/phone')
  const { data: userLogin } = useSWR(login && '/mypage/email')
  const { data: userBasic } = useSWR((basic || info) && '/mypage/index')
  const { data: usetPayment } = useSWR(price && '/mypage/calculate')

  useEffect(() => {
    if (info) {
      if (!userBasic) return
      setPayload({
        ...userBasic.data.user,
        profile: userBasic.data.user.profile_url
          ? userBasic.data.user.profile_url
          : '',
        introduceType: userBasic.data.user.detail_introduce_type
          ? userBasic.data.user.detail_introduce_type
          : '',
        detailIntroduce: refatoringDetailInfo(
          userBasic.data.user?.detail_introduces ?? [],
        ),
        careerUrl: userBasic.data.user.career_url
          ? userBasic.data.user.career_url
          : '',
      })
    }
  }, [type, userBasic])

  if (phone && !userPhone) return null
  if (phone && !userPhone.data) return null
  if (login && !userLogin) return null
  if ((basic || info) && !userBasic) return null
  if ((basic || info) && !userBasic.data) return null
  if (price && !usetPayment) return null
  if (price && !usetPayment.data) return null

  return (
    <Wrapper>
      <Title>
        <p className="main_title">프로필 수정</p>
      </Title>
      <EditMenuTabContent
        onHandleClick={onHandleClick}
        location={location}
        pageType={typeUser}
      />
      <Content>
        {phone && <EditInfoPhoneComponent data={userPhone.data.phone} />}
        {login && <EditInfoLoginComponent data={userLogin.data} />}
        {basic && typeUser === 'user' && (
          <EditInfoBasicComponent data={userBasic.data.user} />
        )}
        {info && typeUser === 'tutor' && (
          <EditTutorInfoComponent name={userBasic.data.user.nickname} />
        )}
        {price && typeUser === 'tutor' && (
          <EditInfoPaymentComponent data={usetPayment.data.user_info} />
        )}
      </Content>
    </Wrapper>
  )
}

export default EditMyInfoComponent

const Wrapper = styled(AppRootWrapper)``
const Title = styled.div`
  ${media.medium} {
    display: none;
  }
  & p.main_title {
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: #0d0d0e;
    padding-top: 40px;
  }
`
const Content = styled.div`
  min-width: 440px;
  min-height: 680px;
  margin: 0 auto;
  padding: 100px 0 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${media.medium} {
    padding: 20px 0 80px;
    height: 100%;
    width: 100%;
    min-width: 100%;
    min-height: 100%;
  }
`
