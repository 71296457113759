import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ReactComponent as MainLogo } from '../../../assets/icons/main_logo.svg'
import { ReactComponent as MenuLogo } from '../../../assets/icons/header_menu_icon.svg'
import { ReactComponent as MenuCloseLogo } from '../../../assets/icons/header_menu_close_icon.svg'
import { ReactComponent as MenuHoverLogo } from '../../../assets/icons/header_menu_hover_icon.svg'
import { ReactComponent as PolygonLogo } from '../../../assets/icons/header_polygon_icon.svg'
import Search from '../Search'
import { useHistory, useParams } from 'react-router'
import { AppRootWrapper } from 'components/layout/CommonStyle'
import useSWR from 'swr'
import media from 'lib/styles/media'
import useIndex from 'lib/hooks/useIndex'
import { useRecoilState } from 'recoil'
import { PageTypeState } from 'atoms/common'
import useUser from 'lib/hooks/account/useUser'

function StudentHeader() {
  const params = useParams<{ search: string }>()
  const value = params.search
  const { data } = useIndex()
  const { user } = useUser()
  const history = useHistory()
  const [isMenu, SetIsMenu] = useState(false)
  const [position, SetPosition] = useState(window.pageYOffset)
  const [isVisiable, SetIsVisiable] = useState(true)
  const [pageType, setPageType] = useRecoilState(PageTypeState)
  const studentUser = user && user.type === 'user'

  const pageTypeChange = () => {
    setPageType('tutor')
    history.push('/?role=tutor')
  }

  useEffect(() => {
    const handleScroll = () => {
      const move = window.pageYOffset
      if (!window.location.href.includes('/tutor/detail/'))
        SetIsVisiable(position > move)
      SetPosition(move)
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [position])

  const handleMouseOver = () => {
    if (!isMenu) {
      SetIsMenu(true)
    }
  }
  const handleMouseOut = () => {
    if (isMenu) {
      SetIsMenu(false)
    }
  }
  return (
    <Wrapper className={isVisiable ? '' : 'active'}>
      <HeaderContent>
        <LeftContent>
          <MainLogo
            onClick={() => {
              history.push('/')
            }}
          />
          <div className="left_contents">
            <p
              className="left_title"
              onClick={() => {
                if (studentUser) {
                  history.push('/class')
                } else {
                  history.push('/login')
                }
              }}
            >
              예정된 강의
            </p>
            {studentUser && data?.data.user.is_starting_soon_class === 'Y' && (
              <div className="polygon_content">
                <PolygonLogo />
                <p className="show_content">곧 시작 되는 강의가 있어요!</p>
              </div>
            )}
          </div>
        </LeftContent>
        <CenterContent>
          <Search
            placeholder="강의 제목, 튜터 이름으로 검색하세요!"
            preValue={value}
          />
        </CenterContent>
        <RightContent>
          <div className="page_button" onClick={pageTypeChange}>
            <p>튜터 페이지로 이동</p>
          </div>
          {isMenu ? (
            <MenuHoverLogo
              onMouseOut={handleMouseOut}
              onClick={() => {
                history.push('/menu')
              }}
            />
          ) : (
            <MenuLogo
              onMouseOver={handleMouseOver}
              onClick={() => {
                history.push('/menu')
              }}
            />
          )}
        </RightContent>
      </HeaderContent>
    </Wrapper>
  )
}

export default StudentHeader

const Wrapper = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  position: fixed;
  left: 0;
  top: 0;
  background: #fff;
  border-bottom: 1px solid #eeeff1;
  z-index: 100;
  &.active {
    display: none;
  }
  ${media.medium} {
    display: none;
  }
`
const HeaderContent = styled(AppRootWrapper)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const LeftContent = styled.div`
  display: flex;
  align-items: center;
  & > svg {
    cursor: pointer;
  }
  & .left_contents {
    position: relative;
    cursor: pointer;
  }
  & .left_title {
    font-weight: 700;
    font-size: 17px;
    line-height: 26px;
    color: #0d0d0e;
    margin-left: 28px;
  }
  & .polygon_content {
    position: absolute;
    top: 40px;
    left: 28px;
    width: 177px;
    max-height: 30px;
    background: #3b3b40;
    border-radius: 20px;
    padding: 4px 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    & > p {
      font-weight: 700;
      font-size: 13px;
      line-height: 22px;
      color: #ffffff;
    }
    & > svg {
      position: absolute;
      left: 36px;
      top: -4px;
    }
  }
`
const CenterContent = styled.div``
const RightContent = styled.div`
  display: flex;
  align-items: center;

  & .page_button {
    padding: 8px 16px;
    background: rgba(117, 116, 216, 0.2);
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 18px;
    & > p {
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      color: #7574d8;
      cursor: pointer;
    }
  }
`
