import React from 'react'
import styled from 'styled-components'
import { ReactComponent as ScopeIcon } from '../../../assets/icons/class_info_scope_icon.svg'
import { ReactComponent as NoneImage } from '../../../assets/icons/none_profile_icon.svg'
import {
  dateDiff,
  dateHyphenFormet,
  timeFormet,
} from 'lib/utils/CommonFuncttion'
import media from 'lib/styles/media'

interface Props {
  image: string
  title: string
  begin: string
  end: string
  category: Array<{ category: string; id: number }>
  nickname: string
  profile: string
  score: string
  stepInfo: {
    step_title?: string
    step_begin_date?: string
    step_estimated_time?: string
    step_real_begin_date?: string
    step_real_end_date?: string
  }
}

function ClassInfoContent({
  image,
  title,
  begin,
  end,
  category,
  nickname,
  profile,
  score,
  stepInfo,
}: Props) {
  return (
    <Wrapper>
      {image ? (
        <img src={image} alt="class_image" className="class_image" />
      ) : (
        <Div />
      )}

      <div className="info_class">
        <p className="info_title">{title}</p>
        {stepInfo.step_title && (
          <p className="info_step_title">{stepInfo.step_title}</p>
        )}
        <p className="info_date">
          {dateHyphenFormet(stepInfo.step_real_begin_date)} ~{' '}
          {timeFormet(stepInfo.step_real_end_date)}{' '}
          {stepInfo.step_estimated_time
            ? `(${stepInfo.step_estimated_time}분)`
            : dateDiff(begin, end)}
        </p>
        <div className="category_content">
          <p className="category">
            {category.map((item, i) => (
              <p
                key={`myclass_list_data_category_${i}`}
                className={
                  category.length === 1 ? 'category_span none' : 'category_span'
                }
              >
                {item.category} <span className="dot" />
              </p>
            ))}
          </p>
        </div>
        <div className="tutor_box">
          {profile ? (
            <img src={profile} alt="profile_image" className="profile_image" />
          ) : (
            <NoneImage className="profile_image" />
          )}
          <p className="nick_name">{nickname}</p>
          <ScopeIcon className="scope_icon" />
          <p className="scope">{score}</p>
        </div>
      </div>
    </Wrapper>
  )
}

export default ClassInfoContent
const Wrapper = styled.div`
  width: 100%;
  min-height: 166px;
  background: #f9f9fb;
  display: flex;
  align-items: center;
  padding: 0 20px;
  ${media.medium} {
    flex-direction: column;
    align-items: flex-start;
    padding: 18px 20px;
    min-height: 200px;
  }

  & img.class_image {
    width: 210px;
    height: 118px;
    border-radius: 8px;
    ${media.medium} {
      width: 90px;
      height: 51px;
    }
  }
  & div.info_class {
    margin-left: 20px;
    ${media.medium} {
      margin-left: 0;
      margin-top: 10px;
    }
  }
  & p.info_title {
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: #3b3b40;
  }
  & p.info_step_title {
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    color: #3b3b40;
    margin-top: 4px;
  }
  & p.info_date {
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    color: #7c7c80;
    margin: 4px 0;
    ${media.medium} {
      font-size: 12px;
      margin: 2px 0;
    }
  }
  & .category_content {
    display: flex;
    align-items: center;
  }
  & .category {
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    color: #b5b5b9;
    display: flex;
    align-items: center;
    width: 100%;
    overflow: hidden;
    word-break: break-word;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    ${media.medium} {
      font-size: 12px;
      min-width: 100%;
    }
  }
  & .category_span {
    width: 100%;
    min-width: fit-content;
    position: relative;
    margin-right: 8px;
    &.none {
      & span.dot {
        display: none;
      }
    }
    & span.dot {
      position: absolute;
      right: -4px;
      top: 50%;
      transform: translateY(-50%);
      width: 2px;
      height: 2px;
      border-radius: 100%;
      background: #b5b5b9;
    }
  }
  & div.tutor_box {
    display: flex;
    align-items: center;
    margin-top: 12px;
    ${media.medium} {
      margin-top: 10px;
    }
  }
  & img.profile_image {
    width: 28px;
    height: 28px;
    border-radius: 100%;
    margin-right: 10px;
    ${media.medium} {
      width: 22px;
      height: 22px;
      margin-right: 6px;
    }
  }
  & svg.profile_image {
    width: 28px;
    height: 28px;
    border-radius: 100%;
    margin-right: 10px;
    ${media.medium} {
      width: 22px;
      height: 22px;
      margin-right: 6px;
    }
  }
  & .nick_name {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #3b3b40;
    ${media.medium} {
      font-size: 13px;
      line-height: 22px;
    }
  }
  & .scope_icon {
    margin: 1px 4px 0 10px;
  }
  & .scope {
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    color: #7c7c80;
  }
`
const Div = styled.div`
  width: 210px;
  height: 118px;
  border-radius: 8px;
  background: rgb(181, 181, 185);
  ${media.medium} {
    width: 90px;
    height: 51px;
  }
`
