import { Switch } from 'antd'
import { AppRootWrapper } from 'components/layout/CommonStyle'
import type { SearchDataProps } from 'lib/types/account'
import React, { useState } from 'react'
import styled from 'styled-components'
import NoneData from '../NoneData'
import media from 'lib/styles/media'
import SmallClassInfoComponent from '../SmallClassInfoComponent'

function SearchComponent({
  data,
  target,
  handleChange,
  checked,
  available,
}: SearchDataProps) {
  return (
    <Wrapper>
      <SearchTitle>
        <div>
          <p className="search_title">
            검색결과<span className="search_length">({data.length})</span>
          </p>
        </div>
        <div className="button_content">
          <p className="search_title">신청 가능한 강의</p>
          <Switch checked={checked} onChange={handleChange} />
        </div>
      </SearchTitle>
      <Contents>
        {data.length > 0 ? (
          <SearchContents>
            {data.map((item, i) => (
              <SmallClassInfoComponent
                key={`search_class_list_${i}`}
                Image={item.image_url}
                Profile={item.profile_url}
                Title={item.title}
                Nickname={item.nickname}
                Score={item.tutor_score}
                Category={item.categories}
                Price={item.price}
                like={item.is_my_like}
                date={item.begin_date}
                id={item.id}
                type="search"
                available={available}
                tutorId={item.tutor_id}
                status={item.status}
              />
            ))}
            <div className="target" ref={target} />
          </SearchContents>
        ) : (
          <NoneData title="검색 결과가" />
        )}
      </Contents>
    </Wrapper>
  )
}

export default SearchComponent

const Wrapper = styled(AppRootWrapper)`
  margin-top: 40px;
  ${media.medium} {
    margin-top: 16px;
  }
`
const SearchTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
  ${media.medium} {
    margin-bottom: 26px;
  }
  & .search_title {
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #3b3b40;
    margin-right: 8px;
    ${media.medium} {
      font-size: 13px;
      line-height: 22px;
    }
  }
  & .search_length {
    margin-left: 4px;
    font-weight: 700;
    font-size: 16px;
    line-height: 26px;
    color: #7574d8;
    ${media.medium} {
      font-size: 13px;
      line-height: 22px;
    }
  }
  & .button_content {
    display: flex;
    align-items: center;
  }
  & .ant-switch.ant-switch-checked {
    background: #7574d8;
  }
  & .ant-switch.ant-switch-checked:hover {
    background: #7574d8;
  }
  & .ant-switch {
    background: #e1e1e2;
  }
  & .ant-switch:hover {
    background: #e1e1e2;
  }
  & .ant-switch {
    ${media.medium} {
      min-width: 34px;
      min-height: 20px;
      height: 20px;
    }
  }
  & .ant-switch-handle {
    ${media.medium} {
      width: 14px;
      height: 14px;
      top: 3px;
    }
  }
`
const Contents = styled.div`
  min-height: 600px;
  ${media.medium} {
    min-height: 400px;
  }
`
const SearchContents = styled.div`
  display: flex;
  overflow: hidden;
  flex-wrap: wrap;
  margin-bottom: 120px;
  ${media.medium} {
    margin-bottom: 50px;
  }
  & > div {
    margin-bottom: 40px;
    ${media.medium} {
      margin-bottom: 30px;
    }
  }
  & > div:nth-of-type(4n) {
    margin-right: 0;
  }
  & > div:nth-of-type(2n) {
    ${media.medium} {
      margin-right: 0;
    }
  }
`
