import media from 'lib/styles/media'
import React from 'react'
import styled, { css } from 'styled-components'

interface Props {
  title: string
  onClick?: () => void
  className?: string
  disabled?: boolean
  variant?: 'contained' | 'outlined'
  score?: string
  max?: number
  color?: 'gray-3'
}
function Button({
  title,
  onClick,
  className,
  disabled,
  variant = 'contained',
  score,
  max,
  color,
}: Props) {
  return (
    <Wrapper
      onClick={onClick}
      className={className}
      disabled={disabled}
      variant={variant}
      color={color}
    >
      <p>
        {title}
        {score && max && (
          <span className="score_text">
            {score}/{max}
          </span>
        )}
      </p>
    </Wrapper>
  )
}
export default Button

const Wrapper = styled.button<{
  variant: 'contained' | 'outlined'
  color?: string
}>`
  width: 100%;
  height: 50px;
  border-radius: 4px;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  ${(props) =>
    props.variant === 'contained' &&
    css`
      background: #57569e;

      & > p {
        font-weight: 700;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        color: #ffffff;
      }

      &:hover {
        background: #363676;
      }

      &:active {
        opacity: 0.9;
      }
    `}

  ${(props) =>
    props.variant === 'outlined' &&
    css`
      border: 1px solid #e1e1e2;
      color: #0d0d0e;
      background: #fff;

      ${props.color === 'gray-3' &&
      css`
        border: 1px solid #7c7c80;
      `}

      & > p {
        color: #0d0d0e;
        font-weight: 700;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
      }

      &:hover {
        background: rgba(0, 0, 0, 0.04);
        border: 1px solid #e1e1e2;
        color: #000;
      }

      &:active {
        opacity: 0.9;
      }
    `}

  ${media.medium} {
    height: 42px;
    &.login {
      height: 50px;
    }
  }

  &.small {
    height: 42px;
  }
  &.border {
    border: 1px solid #e1e1e2;
    background: #fff;
    & > p {
      color: #0d0d0e;
    }
    &:hover {
      background: rgba(0, 0, 0, 0.04);
      border: 1px solid #e1e1e2;
    }
  }
  & span.score_text {
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: #7574d8;
    margin-left: 4px;
  }
  &:disabled {
    background: #e1e1e2;
    cursor: not-allowed;
    &:hover {
      opacity: 1;
    }
    &:active {
      opacity: 1;
    }
  }
`
