import { MyClassListDataPayloadState } from 'atoms/join'
import media from 'lib/styles/media'
import React from 'react'
import { useRecoilState } from 'recoil'
import styled from 'styled-components'
import { ReactComponent as NoneImage } from '../../../assets/icons/none_profile_icon.svg'

interface Props {
  content: string
}
function TutorFeedbackContent({ content }: Props) {
  const [payload, setPayload] = useRecoilState(MyClassListDataPayloadState)
  return (
    <Wrapper>
      <div className="tutor_info_box">
        {payload?.profile ? (
          <img src={payload?.profile} alt="image" className="profile_image" />
        ) : (
          <NoneImage />
        )}
        <p className="tutor_nickname">[{payload?.nickname}] 선생님 총평</p>
      </div>
      <div className="tutor_feedback_box">
        <p className="tutor_feedback_text">{content}</p>
      </div>
    </Wrapper>
  )
}

export default TutorFeedbackContent
const Wrapper = styled.div`
  & div.tutor_info_box {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    & img {
      width: 50px;
      height: 50px;
      border-radius: 100%;
      ${media.medium} {
        width: 40px;
        height: 40px;
      }
    }
    & svg {
      width: 50px;
      height: 50px;
      border-radius: 100%;
      ${media.medium} {
        width: 40px;
        height: 40px;
      }
    }
  }
  & p.tutor_nickname {
    font-weight: 700;
    font-size: 16px;
    line-height: 26px;
    color: #3b3b40;
    margin-left: 12px;
    ${media.medium} {
      margin-left: 8px;
      font-size: 14px;
      line-height: 24px;
    }
  }
  & div.tutor_feedback_box {
    width: 100%;
    min-height: 150px;
    background: #57569e;
    border-radius: 2px 14px 14px 14px;
    padding: 18px;
    margin-bottom: 20px;
    ${media.medium} {
      margin-bottom: 12px;
    }
  }
  & p.tutor_feedback_text {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #ffffff;
    white-space: pre-wrap;
  }
`
