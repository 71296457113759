import { enterTeamRoomModalState, timeSettingModalState } from 'atoms/modal'
import { format, add } from 'date-fns'
import ko from 'date-fns/locale/ko'
import useRoom from 'lib/hooks/room/useRoom'
import media from 'lib/styles/media'
import { type RoomStatusType } from 'lib/types/room'
import React, { useEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import { ReactComponent as CloseIcon } from 'assets/icons/close_white_icon.svg'
import styled from 'styled-components'
import {
  useRoomInfoAction,
  useRoomInfoValue,
} from '../provider/RoomInfoProvider'
import RoomButton from './RoomButton'
import Timer from 'components/common/Timer'

interface EnterTeamRoomButtonProps {
  team: 'A' | 'B'
  remainTime?: number | null
  date?: Date | null
}

const ENTER_ROOM_TEXT = {
  studentEnter: {
    A: '선발언팀 소회의방 입장',
    B: '후발언팀 소회의방 입장',
  },
  tutorEnder: {
    A: '선발언팀 소회의방 열기',
    B: '후발언팀 소회의방 열기',
  },
}

function EnterTeamRoomButton({
  team,
  remainTime,
  date,
}: EnterTeamRoomButtonProps) {
  const setTimeSettingModal = useSetRecoilState(timeSettingModalState)
  const { roomId }: { roomId: string } = useParams()
  const { modifyTeamRoomTrigger, closeTeamRoomTrigger } = useRoom(roomId)
  const { role, maxCount, teamCount, myRoomInfo } = useRoomInfoValue()
  const { enterTeamRoom, enterTeamRoomTutor } = useRoomInfoAction()

  const [timer, setTimer] = useState(0)
  const history = useHistory()
  const [end, setEnd] = useState(false)

  const roomStatus: RoomStatusType | undefined = useMemo(() => {
    if (role === 'student' && !date) return 'STUDENT_DISABLED_ENTER'
    if (role === 'student' && date && end) {
      return 'STUDENT_DISABLED_ENTER'
    }
    if (role === 'student' && date && !end) return 'STUDENT_ENABLED_ENTER'

    if (role === 'tutor' && !date) return 'TUTOR_ENABLED_OPEN'
    if (role === 'tutor' && date && end) {
      return 'TUTOR_ENABLED_OPEN'
    }
    if (role === 'tutor' && date) return 'TUTOR_ENABLED_ENTER'
    return 'TUTOR_ENABLED_ENTER'
  }, [role, end, date])

  const routeTeamRoom = () => {
    history.push(`/room/${roomId}/teamroom/${team}`)
  }

  const openTeamRoom = () => {
    const handleSettingTimer = async (time: number) => {
      const currentAt = new Date()

      const closedAt = add(currentAt, {
        seconds: time,
      })

      const formatDate = format(closedAt, 'yyyy/MM/dd HH:mm:ss', { locale: ko })

      await modifyTeamRoomTrigger({
        closedAt: formatDate,
        team,
      })
    }

    setTimeSettingModal({
      onSetting: handleSettingTimer,
      title: '회의 시간 설정',
      text: '설정한 회의시간이 소회의방에 카운트됩니다.',
      width: 'sm',
    })
  }

  const enterRoom = () => {
    if (!myRoomInfo) return
    enterTeamRoom &&
      enterTeamRoom({
        tableNum: myRoomInfo.table_num,
        team,
        userId: myRoomInfo.user_id,
      })
    routeTeamRoom()
  }

  const setEnterTeamRoomModal = useSetRecoilState(enterTeamRoomModalState)

  const enterRoomTutor = () => {
    setEnterTeamRoomModal({
      onEnter: () => {
        enterTeamRoomTutor && enterTeamRoomTutor({ team, isHide: false })
        routeTeamRoom()
      },
      onEnterSecret: () => {
        enterTeamRoomTutor && enterTeamRoomTutor({ team, isHide: true })
        routeTeamRoom()
      },
    })
  }

  const handleClickCloseIcon: React.MouseEventHandler<SVGSVGElement> = (e) => {
    e.stopPropagation()
    closeTeamRoomTrigger(team)
  }

  useEffect(() => {
    if (!remainTime) return
    if (remainTime - timer <= 0) return

    const interval = setInterval(() => {
      setTimer((timer) => timer + 1)
    }, 1000)

    return () => clearInterval(interval)
  }, [timer, remainTime])

  useEffect(() => {
    setEnd(false)
  }, [date])

  if (roomStatus === 'STUDENT_DISABLED_ENTER') {
    return (
      <Wrapper>
        <RoomButton disabled>{ENTER_ROOM_TEXT.studentEnter[team]}</RoomButton>
      </Wrapper>
    )
  }

  if (roomStatus === 'TUTOR_ENABLED_OPEN') {
    return (
      <Wrapper>
        <RoomButton size="lg" onClick={openTeamRoom}>
          {ENTER_ROOM_TEXT.tutorEnder[team]}
        </RoomButton>
      </Wrapper>
    )
  }

  if (remainTime && roomStatus === 'TUTOR_ENABLED_ENTER') {
    return (
      <Wrapper>
        <RoomButton onClick={enterRoomTutor}>
          <span>{team === 'A' ? '선' : '후'}발언팀</span>{' '}
          <span>
            {teamCount[team]} /{maxCount / 2}
          </span>
          <div className="divide" />
          <Timer date={date} onExit={() => setEnd(true)} />
          <CloseIcon className="close-icon" onClick={handleClickCloseIcon} />
        </RoomButton>
      </Wrapper>
    )
  }

  if (remainTime && roomStatus === 'STUDENT_ENABLED_ENTER') {
    return (
      <Wrapper>
        <RoomButton onClick={enterRoom}>
          <span>
            {team === 'A' ? '선' : '후'}발언팀 {teamCount[team]} /{maxCount / 2}
          </span>
          <div className="divide" />
          <Timer date={date} onExit={() => setEnd(true)} />
        </RoomButton>
      </Wrapper>
    )
  }

  return null
}

const Wrapper = styled.div`
  width: 320px;

  ${media.medium} {
    width: 100%;
  }

  & .divide {
    width: 1px;
    height: 16px;

    /* gray 3 */

    background: #7c7c80;
    opacity: 0.16;

    margin: 0 10px;
  }

  & .close-icon {
    margin-left: 10px;
    width: 14px;
    height: 14px;
  }

  & .emergency {
    color: #ff2c2c;
  }
`

export default EnterTeamRoomButton
