import media from 'lib/styles/media'
import { type StepType } from 'lib/types/class'
import { formatPaymentDate } from 'lib/utils'
import React from 'react'
import styled from 'styled-components'
import ClassPaymentSubmit from './ClassPaymentSubmit'

interface ClassPaymentInfoProps {
  info: {
    imageUrl: string
    title: string
    name: string
    steps: StepType[]
  }
  isValid?: boolean
  term?: boolean
  onChangeTerm: () => void
  onSubmit: () => void
}

function ClassPaymentInfo({ info, ...rest }: ClassPaymentInfoProps) {
  return (
    <Wrapper>
      <div className="label">강의 정보</div>
      <div className="content">
        <div
          className="class-image"
          style={{
            backgroundImage: `url("${info.imageUrl}")`,
            backgroundPosition: 'center',
            objectFit: 'cover',
            backgroundRepeat: 'no-repeat',
          }}
        />
        <div className="class-title">{info.title}</div>
        <div className="class-name">{info.name}</div>
        {info.steps.length <= 1 ? (
          <div className="class-date">
            {formatPaymentDate(
              info.steps[0].step_real_begin_date ??
                info.steps[0].step_begin_date,
            )}{' '}
            ~ (약 {info.steps[0].step_estimated_time}분)
          </div>
        ) : (
          <div className="class-date">총 {info.steps.length}회차</div>
        )}
        <div className="mobile-hidden">
          <ClassPaymentSubmit {...rest} />
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  & > .label {
    font-weight: 500;
    font-size: 17px;
    line-height: 26px;

    ${media.medium} {
      display: none;
    }
  }

  & > .content {
    margin-top: 10px;
    padding: 30px;
    border: 1px solid #e1e1e2;
    border-radius: 14px;

    ${media.medium} {
      padding: 20px;
    }

    & > .class-image {
      border-radius: 8px;
      padding-bottom: 56.25%;
    }

    & > .class-title {
      margin-top: 14px;
      font-weight: 700;
      font-size: 20px;
      line-height: 30px;
      color: #3b3b40;

      ${media.medium} {
        font-weight: 700;
        font-size: 16px;
        line-height: 26px;
        color: #3b3b40;
      }
    }

    & > .class-name {
      margin-top: 6px;
      font-weight: 500;
      font-size: 13px;
      line-height: 22px;
      color: #7c7c80;

      ${media.medium} {
        font-weight: 500;
        font-size: 13px;
        line-height: 22px;
      }
    }

    & .class-date {
      margin-top: 10px;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: #0d0d0e;
    }
  }
`

export default ClassPaymentInfo
