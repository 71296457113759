import media from 'lib/styles/media'
import { type RoomSeatInfoType, type TutorRoomInfoType } from 'lib/types/room'
import React from 'react'
import styled from 'styled-components'
import DebateTutor from './DebateTutor'

interface TeamRoomTutorProps {
  tutor?: TutorRoomInfoType
}

function TeamRoomTutor({ tutor }: TeamRoomTutorProps) {
  if (!tutor) return <Wrapper></Wrapper>
  return (
    <Wrapper>
      <DebateTutor tutor={tutor} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  ${media.pc} {
    height: 166px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

export default TeamRoomTutor
