import TeamRoomHeader from 'components/room/common/TeamRoomHeader'
import TeamRoomFooter from 'components/room/footer/TeamRoomFooter'
import {
  useRoomInfoAction,
  useRoomInfoValue,
} from 'components/room/provider/RoomInfoProvider'
import TeamRoomContainer from 'containers/room/TeamRoomContainer'
import TeamRoomSideContainer from 'containers/room/TeamRoomSideContainer'
import useRoom from 'lib/hooks/room/useRoom'
import React, { useEffect, useMemo } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'

function TeamRoomPage() {
  const { role, myRoomInfo } = useRoomInfoValue()
  const { exitTeamRoom, exitTeamRoomTutor } = useRoomInfoAction()
  const history = useHistory()

  const { team, roomId }: { team: 'A' | 'B'; roomId: string } = useParams()
  // const { data } = useRoom(roomId)

  const title = useMemo(() => {
    if (team === 'A') return '선발언팀 소회의방'
    return '후발언팀 소회의방'
  }, [team])

  const handleClickArrow = () => {
    if (role === 'tutor') {
      exitTeamRoomTutor && exitTeamRoomTutor()
      history.goBack()
      return
    }

    if (!myRoomInfo) return
    exitTeamRoom &&
      exitTeamRoom({
        tableNum: myRoomInfo?.table_num,
        userId: myRoomInfo?.user_id,
      })

    history.goBack()
  }

  // const key = useMemo(() => {
  //   if (team === 'A') return 'team_a_date'
  //   return 'team_b_date'
  // }, [team])

  return (
    <Wrapper>
      <div className="content">
        <TeamRoomHeader title={title} onClickArrow={handleClickArrow} />
        <TeamRoomContainer />
      </div>
      <TeamRoomSideContainer role={role} />
      <TeamRoomFooter roomId={roomId} team={team} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  display: flex;

  width: 100%;
  height: calc(var(--vh, 1vh) * 100);

  & .content {
    display: flex;
    flex-direction: column;

    position: relative;
    overflow-y: scroll;
    flex: 1;
  }

  background: rgba(13, 13, 14, 1);
`

export default TeamRoomPage
