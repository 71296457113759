export const mediaQuery = (maxWidth: number) => `
  @media (max-width: ${maxWidth}px)
`

const media = {
  xxlarge: mediaQuery(1920),
  xlarge: mediaQuery(1400),
  large: mediaQuery(1200),
  medium: mediaQuery(1024),
  small: mediaQuery(768),
  xsmall: mediaQuery(375),
  custom: mediaQuery,
  pc: '@media (min-width: 1025px)',
  max: '@media (min-width: 1720px)',
  1400: '@media (min-width: 1400px)',
}

export default media
