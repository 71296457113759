import { AppRootWrapper } from 'components/layout/CommonStyle'
import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import Button from '../Button'
import TermsCheckComponent from './TermsCheckComponent'
import TermsPopup from './TermsPopup'
import media from 'lib/styles/media'
import { useHistory } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { JoinStepPayloadState, TutorJoinStepPayloadState } from 'atoms/join'

interface Props {
  pageType: string
}
function TermsComponent({ pageType }: Props) {
  const [payload, setPayload] = useRecoilState(
    pageType === 'tutor' ? TutorJoinStepPayloadState : JoinStepPayloadState,
  )
  const [formData, setFormData] = useState({
    service: false,
    privacy: false,
    other: false,
    marketing: false,
  })
  const [popup, setPopup] = useState(false)
  const [type, setType] = useState('')
  const history = useHistory()
  const { service, privacy, other, marketing } = formData

  const formDataAllCheck = Object.values(formData).every((item) => item)

  useEffect(() => {
    const marketingNum = marketing ? 1 : 0
    setPayload({
      ...payload,
      is_marketing: marketingNum,
    })
  }, [marketing])

  useEffect(() => {
    if (popup) {
      document.documentElement.style.overflow = 'hidden'
    } else {
      document.documentElement.style.overflow = 'auto'
    }

    return () => {
      document.documentElement.style.overflow = 'auto'
    }
  }, [popup])

  const handleCheckAll = () => {
    if (formDataAllCheck) {
      setFormData({
        ...formData,
        service: false,
        privacy: false,
        other: false,
        marketing: false,
      })
    } else {
      setFormData({
        ...formData,
        service: true,
        privacy: true,
        other: true,
        marketing: true,
      })
    }
  }

  const handleChange =
    (key: 'service' | 'privacy' | 'other' | 'marketing') => () => {
      setFormData({
        ...formData,
        [key]: !formData[key],
      })
    }

  const handleTermsPopup = (type: string) => {
    setType(type)
    setPopup(true)
  }
  const handleClose = () => {
    setPopup(false)
  }
  const handleBackClick = (e: any) => {
    if (e.target.id === 'popup') {
      if (popup) setPopup(false)
    }
  }
  const onCheckClick = () => {
    const list =
      type === '서비스 이용 약관'
        ? 'service'
        : type === '개인정보 수집 및 처리방침'
        ? 'privacy'
        : 'other'

    setPopup(false)
    setFormData({
      ...formData,
      [list]: true,
    })
  }

  return (
    <Wrapper>
      <Content>
        <div className="title_box">
          <p className="terms_title">
            {pageType === 'tutor' && '튜터 '}회원가입 약관 동의
          </p>
          <p className="terms_text">
            회원가입을 진행하기 위해 약관에 동의해주세요.
          </p>
        </div>
        <div className="terms_box">
          <TermsCheckComponent
            text="전체 동의"
            required={false}
            show={false}
            isAll
            checked={service && privacy && other && marketing}
            onChange={handleCheckAll}
            id="all_check_id"
          />
          <div className="terms_gray_box">
            <TermsCheckComponent
              text="서비스 이용약관 동의"
              required={true}
              checked={formData.service}
              onChange={handleChange('service')}
              handleTermsPopup={() => handleTermsPopup('서비스 이용 약관')}
              show={true}
              id="service_check_id"
            />
            <TermsCheckComponent
              text="개인정보 처리방침 동의"
              required={true}
              checked={formData.privacy}
              onChange={handleChange('privacy')}
              handleTermsPopup={() =>
                handleTermsPopup('개인정보 수집 및 처리방침')
              }
              show={true}
              id="privacy_check_id"
            />
            <TermsCheckComponent
              text="개인정보 제3자 제공 동의"
              required={true}
              checked={formData.other}
              onChange={handleChange('other')}
              handleTermsPopup={() =>
                handleTermsPopup('개인정보 제3자 이용 동의')
              }
              show={true}
              id="other_check_id"
            />
            <TermsCheckComponent
              text="마케팅 정보 수신 동의"
              required={false}
              checked={formData.marketing}
              onChange={handleChange('marketing')}
              show={false}
              id="marketing_check_id"
            />
          </div>
        </div>
        <div className="button_box">
          <Button
            title="다음"
            disabled={!service || !privacy || !other}
            onClick={() => {
              history.push('/join/sms')
            }}
          />
        </div>
      </Content>
      {popup && (
        <TermsPopup
          title={type}
          handleClose={handleClose}
          handleBackClick={handleBackClick}
          onCheckClick={onCheckClick}
          type="terms"
          pageType={pageType === 'tutor' ? 'tutor' : 'user'}
        />
      )}
    </Wrapper>
  )
}

export default TermsComponent

const Wrapper = styled(AppRootWrapper)``
const Content = styled.div`
  width: 440px;
  height: 680px;
  margin: 0 auto;
  padding: 80px 0 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${media.medium} {
    padding: 20px 0 80px;
    height: 100%;
    width: 100%;
  }

  & p.terms_title {
    font-weight: 700;
    font-size: 22px;
    line-height: 34px;
    text-align: center;
    color: #0d0d0e;
    ${media.medium} {
      font-size: 18px;
      line-height: 28px;
    }
  }
  & p.terms_text {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #3b3b40;
    margin-top: 6px;
  }
  & div.terms_box {
    margin-top: 50px;
    width: 380px;
    ${media.medium} {
      width: 100%;
    }
  }
  & div.terms_gray_box {
    width: 380px;
    min-height: 162px;
    background: #f9f9fb;
    border-radius: 8px;
    padding: 15px;
    & > div {
      margin-bottom: 12px;
    }
    & > div:last-of-type {
      margin-bottom: 0;
    }
    ${media.medium} {
      width: 100%;
    }
  }
  & div.button_box {
    width: 380px;
    margin-top: 40px;
    ${media.medium} {
      width: 100%;
    }
  }
`
