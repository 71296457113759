import React from 'react'
import styled from 'styled-components'
import { ReactComponent as Icon } from '../../assets/icons/preview_image_close_icon.svg'
import type { FileType } from 'lib/types/account'

interface Props {
  file: FileType & { image_url?: string }
  onClick: (id: number | string) => void
  frist: boolean
}
function PreviewImage({ file, onClick, frist }: Props) {
  return (
    <Wrapper>
      <img src={String(file?.image_url ?? file.preview)} alt="preview" />
      <Icon onClick={() => onClick(file.id)} />
      {frist && (
        <div className="frist_content">
          <p>메인</p>
        </div>
      )}
    </Wrapper>
  )
}

export default PreviewImage
const Wrapper = styled.div`
  width: 144px;
  height: 80px;
  min-width: 144px;
  position: relative;

  & img {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    object-fit: cover;
  }
  & svg {
    position: absolute;
    top: 6px;
    right: 6px;
  }
  & div.frist_content {
    width: 35px;
    height: 23px;
    background: #7574d8;
    border-radius: 3px;
    position: absolute;
    left: 8px;
    bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    & > p {
      font-weight: 700;
      font-size: 12px;
      line-height: 22px;
      color: #ffffff;
    }
  }
`
