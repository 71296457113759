import CategoryComponent from 'components/common/category/CategoryComponent'
import Footer from 'components/common/Footer'
import Header from 'components/common/header/Header'
import MobileTitleHeader from 'components/common/header/MobileTitleHeader'
import useUser from 'lib/hooks/account/useUser'
import useCategory from 'lib/hooks/useCategory'
import media from 'lib/styles/media'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

function CategoryPage() {
  const { user } = useUser()
  const params = useParams<{ categoryId: string }>()
  const categoryId = params.categoryId
  const [checked, setChecked] = useState(false)
  const [available, setAvailable] = useState('0')
  const studentUser = user && user.type === 'user'

  const {
    data: originData,
    mutate,
    target,
    total,
  } = useCategory({ categoryId, available, studentUser })

  const handleChange = (checked: boolean) => {
    if (!checked) {
      setChecked(false)
      setAvailable('0')
    } else {
      setChecked(true)
      setAvailable('1')
    }
  }

  if (!originData) return null
  if (!originData.data) return null
  if (!originData.data.classes) return null

  return (
    <Wrapper>
      <Header />
      <MobileTitleHeader type="arrow" title="카테고리별 강의" />
      <ContentWra>
        <CategoryComponent
          data={originData.data.classes.data}
          target={target}
          handleChange={handleChange}
          checked={checked}
          available={available}
          total={total}
        />
      </ContentWra>
      <Footer />
    </Wrapper>
  )
}

export default CategoryPage
const Wrapper = styled.div``
const ContentWra = styled.div`
  margin-top: 140px;
  ${media.medium} {
    margin-top: 10px;
  }
`
