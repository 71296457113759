import media from 'lib/styles/media'
import React from 'react'
import styled from 'styled-components'
import { ReactComponent as Icon } from '../../assets/icons/menu_alarm_icon.svg'
import { ReactComponent as KakaoIcon } from '../../assets/icons/login_kakao_icon.svg'
import { ReactComponent as NoticeIcon } from '../../assets/icons/main_notice_icon2.svg'

interface Props {
  title: string
  onClick?: () => void
  type: string
  className?: string
  alarm?: string | undefined
}

function IconButton({ title, onClick, type, className, alarm }: Props) {
  return (
    <>
      {type === 'kakao' && (
        <Wrapper onClick={onClick} className="kakao_button">
          <KakaoIcon />
          <p className="kakao">{title}</p>
        </Wrapper>
      )}
      {type === 'notice' && (
        <Wrapper onClick={onClick} className={className}>
          <NoticeIcon />
          <p>{title}</p>
        </Wrapper>
      )}
      {type === 'alarm' && (
        <Wrapper onClick={onClick} className={className}>
          <Icon />
          {alarm === 'Y' && <Dot />}
          <p>{title}</p>
        </Wrapper>
      )}
      {type === 'none' && (
        <Wrapper onClick={onClick} className={className}>
          <span className="none" />
          <p>{title}</p>
        </Wrapper>
      )}
    </>
  )
}

export default IconButton
const Wrapper = styled.button`
  width: 100%;
  height: 62px;
  background: #ffffff;
  border: 1px solid #e1e1e2;
  border-radius: 8px;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
  position: relative;
  &.menu {
    justify-content: space-between;
    padding: 0 35px;
    & > p {
      flex: 1;
    }
  }
  & span.none {
    width: 22px;
  }
  &.kakao_button {
    height: 50px;
    background: #ffe812;
    border-radius: 4px;
    border: none;
    & > p {
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
    }
  }
  ${media.medium} {
    height: 42px;
    & > svg {
      width: 17px;
      height: 18px;
    }
  }
  & > p {
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    color: #0d0d0e;
    margin-left: 8px;
    ${media.medium} {
      font-size: 14px;
      line-height: 24px;
    }
  }
`
const Dot = styled.span`
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background: #ff2c2c;
  position: absolute;
  top: 16px;
  left: 57px;
`
