import Footer from 'components/common/Footer'
import EditInfoBasicComponent from 'components/common/editinfo/EditInfoBasicComponent'
import EditInfoLoginComponent from 'components/common/editinfo/EditInfoLoginComponent'
import EditInfoPhoneComponent from 'components/common/editinfo/EditInfoPhoneComponent'
import EditMyInfoComponent from 'components/common/editinfo/EditMyInfoComponent'
import Header from 'components/common/header/Header'
import MobileTitleHeader from 'components/common/header/MobileTitleHeader'
import media from 'lib/styles/media'
import React, { useEffect } from 'react'
import styled from 'styled-components'
import TutorHeader from 'components/common/header/TutorHeader'
import useUser from 'lib/hooks/account/useUser'

function EditMyinfoPage() {
  const { user } = useUser()
  const typeUser = user && user.type

  return (
    <Wrapper>
      {typeUser === 'tutor' ? <TutorHeader /> : <Header />}
      <MobileTitleHeader
        type="arrow"
        title={typeUser === 'tutor' ? '튜터 정보 수정' : '프로필 수정'}
      />
      <Content>
        <EditMyInfoComponent />
      </Content>
      <Footer />
    </Wrapper>
  )
}
export default EditMyinfoPage

const Wrapper = styled.div``
const Content = styled.div`
  margin-top: 100px;
  ${media.medium} {
    margin-top: 4px;
  }
`
