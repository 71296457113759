import { enterTeamRoomModalState } from 'atoms/modal'
import React from 'react'
import { useRecoilState } from 'recoil'
import styled from 'styled-components'
import ModalWrapper from './ModalWrapper'
import { ReactComponent as CloseIcon } from 'assets/icons/close_icon.svg'
import Button from 'components/common/Button'

function EnterTeamRoomModal() {
  const [modal, setModal] = useRecoilState(enterTeamRoomModalState)

  const handleClose = () => {
    setModal(null)
  }

  const handleClickSecretEnter = () => {
    modal?.onEnterSecret()
    handleClose()
  }

  const handleClickEnter = () => {
    modal?.onEnter()
    handleClose()
  }

  if (modal === null) return null

  return (
    <ModalWrapper>
      <Wrapper>
        <div className="header">
          <h3 className="title">소회의방 입장</h3>
          <CloseIcon className="close-icon" onClick={handleClose} />
        </div>
        <div className="content">
          <p>소회의방에 입장할 방식을 선택해주세요.</p>
          <p className="gray-4">
            몰래 입장시 수강생들이 나의 입장여부를 알 수 없습니다.
          </p>
        </div>
        <div className="footer">
          <Button
            title="몰래입장"
            variant="outlined"
            className="gray-3"
            onClick={handleClickSecretEnter}
          />
          <Button title="입장" onClick={handleClickEnter} />
        </div>
      </Wrapper>
    </ModalWrapper>
  )
}

const Wrapper = styled.div`
  width: 380px;

  border-radius: 14px;
  background: #fff;

  & .header {
    padding: 26px 26px 10px;

    display: flex;
    justify-content: space-between;

    & .title {
      font-weight: 700;
      font-size: 18px;
      color: #000000;
    }

    & .close-icon {
      cursor: pointer;
    }
  }

  & .content {
    padding: 0px 26px 20px;
    height: 68px;
    & p {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
    }

    & .gray-4 {
      color: #b5b5b9;
    }
  }
  & .footer {
    padding: 10px 26px 26px;

    display: flex;
    gap: 10px;

    height: 86px;
  }

  & .gray-3 {
    border: 1px solid #7c7c80;
  }
`

export default EnterTeamRoomModal
