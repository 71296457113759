import media from 'lib/styles/media'
import React from 'react'
import styled from 'styled-components'
import { ReactComponent as CloseIcon } from '../../../assets/icons/popup_cancel_icon.svg'
import useSWR from 'swr'
import LineButton from '../LineButton'

interface Props {
  title: string
  handleClose: () => void
  handleBackClick?: (e: any) => void
  onCheckClick?: () => void
  type?: string
  pageType: string
}
function TermsPopup({
  title,
  handleClose,
  handleBackClick,
  onCheckClick,
  type,
  pageType,
}: Props) {
  const data = useSWR(`/terms?type=${pageType}&title=${title}`)

  if (!data) return null
  if (!data.data) return null
  if (!data.data.data) return null

  return (
    <Wrapper id="popup" onClick={handleBackClick}>
      <Content>
        <TitleBox>
          <p className="terms_popup_title">{data.data.data.terms.title}</p>
          <CloseIcon onClick={handleClose} />
        </TitleBox>
        <TextBox>
          <p
            dangerouslySetInnerHTML={{ __html: data.data.data.terms.contents }}
          ></p>
        </TextBox>
        <ButtonBox>
          {type === 'terms' ? (
            <LineButton
              title="확인 및 동의합니다."
              type="gray"
              onClick={onCheckClick}
            />
          ) : (
            <LineButton title="확인" type="gray" onClick={handleClose} />
          )}
        </ButtonBox>
      </Content>
    </Wrapper>
  )
}

export default TermsPopup

const Wrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  z-index: 110;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
`
const Content = styled.div`
  width: 480px;
  min-height: 500px;
  background: #fff;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 14px;
  padding: 26px;
  ${media.medium} {
    width: 100%;
    height: 100%;
    border-radius: 0;
    position: relative;
  }
`
const TitleBox = styled.div`
  padding-bottom: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  & p.terms_popup_title {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: #0d0d0e;
    ${media.medium} {
      font-size: 14px;
      line-height: 24px;
    }
  }
`
const TextBox = styled.div`
  padding: 16px 0;
  min-height: 430px;
  max-height: 430px;
  overflow-y: auto;
  ${media.medium} {
    min-height: 500px;
    overflow-y: scroll;
    margin-bottom: 90px;
  }
  & > p {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #3b3b40;
  }
`
const ButtonBox = styled.div`
  padding-top: 10px;
  ${media.medium} {
    position: fixed;
    left: 0;
    bottom: -1px;
    width: 100%;
    background: #fff;
    padding: 20px;
    & > button {
      margin: 0;
    }
  }
`
