import React, { useState } from 'react'
import styled from 'styled-components'
import { ReactComponent as Arrow } from '../../assets/icons/join_select_button_arrow.svg'
import { ReactComponent as UpArrow } from '../../assets/icons/join_select_button_up_arrow.svg'
import { bankType } from 'lib/utils/CommonFuncttion'

interface Props {
  valueBank: string | undefined
  handleBankClick: (type: any) => void
  valueName: string | undefined
  onChange: (e: any) => void
  valueNum: number | string | undefined
  onChangeNumber: (e: any) => void
}
function BankInput({
  valueBank,
  handleBankClick,
  valueName,
  onChange,
  valueNum,
  onChangeNumber,
}: Props) {
  const [bank, setBank] = useState(false)

  const handleClick = () => {
    if (bank) {
      setBank(false)
    } else {
      setBank(true)
    }
  }
  const handleBlur = () => {
    if (bank) {
      setTimeout(() => {
        setBank(false)
      }, 150)
    }
  }

  return (
    <Wrapper>
      <Title>
        <p>정산 계좌</p>
      </Title>
      <Content>
        <BankContent>
          <BankBox1>
            <div className="bank_select_box" onClick={handleClick}>
              <input
                type="text"
                placeholder="은행 선택"
                readOnly
                className={bank ? 'focus' : ''}
                onBlur={handleBlur}
                value={valueBank ?? ''}
              />
              {bank ? (
                <UpArrow className="arrow" />
              ) : (
                <Arrow className="arrow" />
              )}
              {bank && (
                <SelectBox className="select_box_content">
                  {bankType.map((item, i) => (
                    <div
                      key={`bank_type_list_${i}`}
                      className="bank_list_content"
                      onClick={() => {
                        handleBankClick(item.type)
                      }}
                    >
                      <p className="bank_list_text">{item.type}</p>
                    </div>
                  ))}
                </SelectBox>
              )}
            </div>
          </BankBox1>
          <BankBox2>
            <input
              type="text"
              placeholder="예금주 입력"
              maxLength={10}
              value={valueName}
              onChange={onChange}
            />
          </BankBox2>
        </BankContent>
        <BankBox3>
          <input
            type="text"
            placeholder="‘-’ 제외 계좌번호 입력"
            maxLength={15}
            value={valueNum}
            onChange={onChangeNumber}
          />
        </BankBox3>
        <BankNotice>
          <p>정산계좌 정보는 수강료 정산 외 다른 목적으로 이용되지 않습니다.</p>
        </BankNotice>
      </Content>
    </Wrapper>
  )
}

export default BankInput
const Wrapper = styled.div`
  & div.select_box_content {
    ::-webkit-scrollbar {
      display: flex;
      width: 10px;
    }
    ::-webkit-scrollbar-thumb {
      height: 30%;
      background: rgb(87, 86, 158);
      border-radius: 10px;
    }
    ::-webkit-scrollbar-track {
      background: rgb(238, 239, 241);
    }
  }
`
const Title = styled.div`
  margin-bottom: 4px;
  & > p {
    font-weight: 700;
    font-size: 13px;
    line-height: 22px;
    color: #0d0d0e;
  }
`
const Content = styled.div`
  & input {
    width: 100%;
    border: none;
    border-bottom: 1px solid #e1e1e2;
    outline: none;
    padding: 7px 30px 7px 0;
    &::placeholder {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #b5b5b9;
    }
    &.focus {
      border-bottom: 1px solid #0d0d0e;
    }
  }
`
const SelectBox = styled.div`
  position: absolute;
  left: 0;
  top: 40px;
  width: 100%;
  height: 180px;
  border: 1px solid #0d0d0e;
  background: #fff;
  border-radius: 4px;
  overflow: hidden;
  overflow-y: scroll;
  z-index: 10;
  & div.bank_list_content {
    padding: 10px;
  }
  & div.bank_list_content:hover {
    background: #f9f9fb;
    & > p {
      font-weight: 500;
    }
  }
  & div.bank_list_content:first-of-type:hover {
    border-radius: 4px 4px 0 0;
  }
  & div.bank_list_content:last-of-type:hover {
    border-radius: 0 0 4px 4px;
  }
  & p.bank_list_text {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #0d0d0e;
    cursor: pointer;
  }
`
const BankContent = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`
const BankBox1 = styled.div`
  width: calc(50% - 10px);
  & div.bank_select_box {
    position: relative;
    & > input {
      cursor: pointer;
    }
    & svg.arrow {
      position: absolute;
      right: 0;
      bottom: 14px;
    }
  }
`
const BankBox2 = styled.div`
  width: calc(50% - 10px);
`
const BankBox3 = styled.div`
  margin-top: 18px;
`
const BankNotice = styled.div`
  margin-top: 4px;
  & > p {
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    color: #7c7c80;
  }
`
