import React from 'react'
import styled from 'styled-components'
import Button from './Button'
import media from 'lib/styles/media'
import { ReactComponent as CloseIcon } from '../../assets/icons/popup_cancel_icon.svg'
import { PriceNum, dateBasicFormet } from 'lib/utils/CommonFuncttion'

interface Props {
  onClick: () => void
  title: string
  data: {
    date: string
    price: number | string
    name: string
    bank: string
    account: string
  }
}
function BasicPopup({ title, onClick, data }: Props) {
  return (
    <Wrapper>
      <Content>
        <Title>
          <p>{title}</p>
          <CloseIcon onClick={onClick} />
        </Title>
        <div className="content">
          <div className="content_box">
            <p className="content_title">정산 일시</p>
            <p className="content_text">{dateBasicFormet(data.date)}</p>
          </div>
          <div className="content_box">
            <p className="content_title">정산 계좌 정보</p>
            <p className="content_text">
              {data.name} {data.account}({data.bank})
            </p>
          </div>
          <div className="content_box">
            <p className="content_title">정산 금액</p>
            <p className="content_text">
              {data.price === null ? '-' : PriceNum(data.price) + '원'}
            </p>
          </div>
        </div>
      </Content>
    </Wrapper>
  )
}

export default BasicPopup

const Wrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  z-index: 110;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
`
const Content = styled.div`
  min-width: 380px;
  min-height: 200px;
  background: #fff;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 14px;
  border: 1px solid #e1e1e2;
  ${media.medium} {
    min-width: 320px;
  }
  & div.content {
    padding: 18px 26px;
  }
  & div.content_box {
    margin-bottom: 18px;
  }
  & p.content_title {
    font-weight: 700;
    font-size: 16px;
    line-height: 26px;
    color: #3b3b40;
    margin-bottom: 8px;
  }
  & p.content_text {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #3b3b40;
  }
`
const Title = styled.div`
  padding: 18px 26px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > p {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: #0d0d0e;
    cursor: pointer;
  }
`
