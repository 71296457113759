import React from 'react'
import styled from 'styled-components'
import { ReactComponent as LoginIcon } from '../../../assets/icons/main_login_icon.svg'
import { ReactComponent as ClassIcon } from '../../../assets/icons/main_class_icon.svg'
import { ReactComponent as PaymentIcon } from '../../../assets/icons/main_payment_icon.svg'
import { ReactComponent as LikeIcon } from '../../../assets/icons/main_like_class_icon.svg'
import { ReactComponent as UserIcon } from '../../../assets/icons/myinfo_use_icon.svg'
import { ReactComponent as ReferenceIcon } from '../../../assets/icons/mypage_main_reference_icon.svg'
import Button from '../Button'
import useIndex from 'lib/hooks/useIndex'
import { useHistory } from 'react-router-dom'
import media from 'lib/styles/media'
import useUser from 'lib/hooks/account/useUser'

interface Props {
  type?: string
}
function MainMyInfo({ type }: Props) {
  const { data } = useIndex()
  const { user } = useUser()
  const history = useHistory()
  const studentUser = user && user.type === 'user'
  const Icon = [
    {
      key: 1,
      title: '강의 이력',
      icon: ClassIcon,
      history: '/myclass',
    },
    {
      key: 2,
      title: '결제 내역',
      icon: PaymentIcon,
      history: '/payment?type=payment',
    },
    {
      key: 3,
      title: '찜한 강의',
      icon: LikeIcon,
      history: '/like',
    },
    {
      key: 4,
      title: '레퍼런스룸',
      icon: ReferenceIcon,
      history: '/reference',
    },
  ]
  return (
    <Wrapper className={type ? 'none' : ''}>
      {studentUser ? (
        <InfoContent>
          {data?.data.user.profile_url ? (
            <img src={data?.data.user.profile_url} alt="profile" />
          ) : (
            <UserIcon className="user_icon" />
          )}

          <p className="title">
            <b>{data?.data.user.name}</b>님 안녕하세요!
          </p>
          <Button
            className="border"
            title="프로필 수정"
            onClick={() => {
              if (data?.data.user.provider === 'kakao') {
                history.push('/mypage/edit/myinfo?type=phone')
              } else {
                history.push('/mypage/edit/info')
              }
            }}
          />
        </InfoContent>
      ) : (
        <InfoContent>
          <LoginIcon />
          <p className="title user">
            지금 로그인 하고
            <br />
            디베이트온 이용하세요!
          </p>
          <Button
            title="로그인"
            onClick={() => {
              history.push('/login')
            }}
          />
        </InfoContent>
      )}

      <Span />
      <IconContent>
        {Icon.map((item, i) => (
          <div
            key={`main_myinfo_icon_list_${i}`}
            className="icon_box"
            onClick={() => {
              if (studentUser) {
                history.push(`${item.history}`)
              } else {
                history.push('/login')
              }
            }}
          >
            <item.icon />
            <p className="icon_text">{item.title}</p>
          </div>
        ))}
      </IconContent>
    </Wrapper>
  )
}
export default MainMyInfo

const Wrapper = styled.div`
  width: 380px;
  height: 440px;
  background: #ffffff;
  border: 1px solid #e1e1e2;
  border-radius: 14px;
  margin-left: 20px;
  padding: 56px 30px 36px;
  &.none {
    margin-left: 0;
    width: 440px;
    ${media.medium} {
      width: 100%;
      height: 315px;
      padding: 32px 25px 32px;
    }
  }
`
const InfoContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & .title {
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #3b3b40;
    margin: 16px 0 36px;
    & > b {
      font-weight: 700;
    }
    &.user {
      font-weight: 700;
    }
    ${media.medium} {
      font-size: 14px;
      line-height: 24px;
      margin: 12px 0 22px;
    }
  }
  & > img {
    width: 80px;
    height: 80px;
    border-radius: 100%;
    object-fit: cover;
    ${media.medium} {
      width: 60px;
      height: 60px;
    }
  }
  & .user_icon {
    ${media.medium} {
      width: 60px;
      height: 60px;
    }
  }
`
const Span = styled.div`
  width: 100%;
  height: 1px;
  border: 1px solid #e1e1e2;
  border-radius: 14px;
  margin: 36px 0;
  ${media.medium} {
    display: none;
  }
`
const IconContent = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  ${media.medium} {
    margin-top: 32px;
  }
  & div.icon_box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60px;
    height: 76px;
    & > svg {
      ${media.medium} {
        width: 34px;
        height: 34px;
      }
    }
  }
  & .icon_text {
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    text-align: center;
    color: #3b3b40;
    margin-top: 14px;
    cursor: pointer;
    ${media.medium} {
      font-size: 12px;
      margin-top: 3px;
    }
  }
`
