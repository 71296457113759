import api from '.'

export interface ChatType {
  id: number
  class_id: number
  step: number
  team: 'A' | 'B' | null
  message: string
  name: string
  nickname: string
  profile_url: string
  created_at: string
  user_id: number
}

interface GetChatListRequestType {
  success: boolean
  alert: string
  data: {
    is_unread_normal: boolean
    chats: ChatType[]
  }
}

export async function getChatListRequest({
  roomId,
  lastId,
}: {
  roomId: string
  lastId?: number
}) {
  const { data } = await api.post<GetChatListRequestType>('/rtc/chat', {
    class_id: roomId,
    step: 1,
    last_chat_id: lastId,
  })

  return data
}

export async function getTeamChatListRequest({
  roomId,
  team,
  lastId,
}: {
  roomId: string
  team: 'A' | 'B'
  lastId?: number
}) {
  const { data } = await api.post<GetChatListRequestType>('/rtc/chat/team', {
    class_id: roomId,
    step: 1,
    team,
    last_chat_id: lastId,
  })

  return data
}
