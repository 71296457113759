import { confirmModalState } from 'atoms/modal'
import React from 'react'
import { useSetRecoilState } from 'recoil'
import styled from 'styled-components'

interface RoomMemberPopoverProps {
  onExitUser: () => void
  onExchangeSeat: () => void
}

function RoomMemberPopover({
  onExitUser,
  onExchangeSeat,
}: RoomMemberPopoverProps) {
  const setConfirmModal = useSetRecoilState(confirmModalState)

  const handleExit = () => {
    setConfirmModal({
      title: '수강생 추방',
      description: '선택한 수강생을 추방하시겠습니까?',
      confirmText: '추방',
      onConfirm: onExitUser,
    })
  }

  return (
    <Wrapper>
      <div className="button" onClick={onExchangeSeat}>
        자리변경
      </div>
      <div className="button" onClick={handleExit}>
        추방
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: absolute;

  right: 0px;
  top: 41px;

  width: 120px;

  z-index: 10;

  background: #2b2b31;
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.4);
  border-radius: 4px;

  & .button {
    width: 100%;
    padding: 5px 14px;

    display: flex;
    justify-content: flex-start;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;

    color: #eeeff1;

    &:hover {
      background: #0d0d0e;
    }
  }
`

export default RoomMemberPopover
