export const resize = () => {
  const vh = window.innerHeight * 0.01
  document.documentElement.style.setProperty('--vh', `${vh}px`)
}

const initialResize = () => {
  resize()
  window.addEventListener('resize', resize)
}

export const initialize = () => {
  initialResize()
  resize()
}
