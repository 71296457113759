import Footer from 'components/common/Footer'
import Header from 'components/common/header/Header'
import MobileTitleHeader from 'components/common/header/MobileTitleHeader'
import PageLoadingComponent from 'components/common/PageLoadingComponent'
import { CommonContentWrapper } from 'components/layout/CommonStyle'
import useClassInfo from 'containers/class/hooks/useClassInfo'
import useClassStepInfo from 'containers/class/hooks/useClassStepInfo'
import MyClassEndStudentContainer from 'containers/class/MyClassEndStudentContainer'
import MyClassEndTutorContainer from 'containers/class/MyClassEndTutorContainer'
import { type UserInfo } from 'lib/types/account'
import React from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

interface MyClassEndPageProps {
  user?: UserInfo
}

function MyClassEndPage({ user }: MyClassEndPageProps) {
  const { classId, step }: { classId: string; step: string } = useParams()

  const { data, isLoading } = useClassInfo(classId)

  if (isLoading) return <PageLoadingComponent />
  if (!data) return <div>error page</div>
  if (!user) return <div>Not User Page</div>

  return (
    <Wrapper>
      <Header />
      <MobileTitleHeader type="arrow" />
      <CommonContentWrapper>
        {user.type === 'user' && (
          <MyClassEndStudentContainer
            data={data}
            step={step}
            classId={classId}
          />
        )}
        {user.type === 'tutor' && (
          <MyClassEndTutorContainer data={data} step={step} classId={classId} />
        )}
      </CommonContentWrapper>
      <Footer />
    </Wrapper>
  )
}

const Wrapper = styled.div``

export default MyClassEndPage
