import React from 'react'
import styled from 'styled-components'
import TutorFirstContent from './TutorFirstContent'
import TutorSecondContent from './TutorSecondContent'
import TutorThirdContent from './TutorThirdContent'
import TutorFourthContent from './TutorFourthContent'
import TutorFifthContent from './TutorFifthContent'
import media from 'lib/styles/media'

function TutorMainComponent() {
  return (
    <Wrapper>
      <TutorFirstContent />
      <TutorSecondContent />
      <TutorThirdContent />
      <TutorFourthContent />
      <TutorFifthContent />
    </Wrapper>
  )
}

export default TutorMainComponent
const Wrapper = styled.div``
