import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import MainImage from '../../../assets/icons/tutor_main_content_image.png'
import MainImageMobile from '../../../assets/icons/tutor_main_content_image_mobile.png'
import { ReactComponent as ArrowIcon } from '../../../assets/icons/tutor_main_content_arrow.svg'
import { ReactComponent as MainTutorLogo } from '../../../assets/icons/tutor_main_logo.svg'
import Button from '../Button'
import media from 'lib/styles/media'
import useUser from 'lib/hooks/account/useUser'
import { useHistory } from 'react-router-dom'
import useIndex from 'lib/hooks/useIndex'
import CheckPopup from '../CheckPopup'

function TutorFirstContent() {
  const { user } = useUser()
  const { data } = useIndex()
  const history = useHistory()
  const [popup, setPopup] = useState(false)

  useEffect(() => {
    if (popup) {
      document.documentElement.style.overflow = 'hidden'
    } else {
      document.documentElement.style.overflow = 'auto'
    }

    return () => {
      document.documentElement.style.overflow = 'auto'
    }
  }, [popup])

  const handleCheck = () => {
    setPopup(false)
  }

  return (
    <Wrapper>
      <div className="main_box">
        <MobileIcon>
          <MainTutorLogo
            onClick={() => {
              history.push('/?role=tutor')
            }}
          />
        </MobileIcon>
        <p className="main_text">온라인 디베이팅 수업을 위한 맞춤형 공간!</p>
        <p className="main_content">
          비대면 디베이팅에
          <br />
          최적화된 툴을 활용하여
          <br />
          디베이팅 수업을 진행해 보세요.
        </p>
        {user && user.type === 'tutor' ? (
          <div className="button_box">
            <Button
              title="강의 개설하기"
              onClick={() => {
                if (data && data.data.user.status === 1) {
                  // status(1:승인 0:미승인)
                  history.push('/register/class?role=tutor')
                } else {
                  setPopup(true)
                }
              }}
            />
            <Button
              title="활용 가이드 보기"
              className="border"
              onClick={() => {
                window.alert('활용 가이드 보기')
              }}
            />
          </div>
        ) : (
          <div className="button_box one">
            <Button
              title="튜터 회원가입하기"
              onClick={() => {
                history.push('/join?role=tutor')
              }}
            />
          </div>
        )}
      </div>
      <img src={MainImage} className="main_image" />
      <img src={MainImageMobile} className="main_image_mobile" />
      <ArrowIcon className="arrow_icon" />
      {popup && (
        <CheckPopup
          onClick={handleCheck}
          title="튜터 미승인"
          content={'튜터 승인이 완료되면 \n강의를 등록할 수 있습니다.'}
        />
      )}
    </Wrapper>
  )
}

export default TutorFirstContent

const Wrapper = styled.div`
  width: 100%;
  height: 920px;
  background: linear-gradient(
    181.45deg,
    rgba(255, 255, 255, 0) -70.48%,
    rgba(223, 223, 247, 0.5) 98.77%
  );
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  ${media.medium} {
    height: 100%;
    flex-direction: column;
    justify-content: flex-start;
  }
  & div.main_box {
    width: 450px;
    min-width: 450px;
    margin-right: 206px;
    ${media.medium} {
      width: 100%;
      min-width: 100%;
      margin-right: 0;
      padding: 0 20px;
    }
  }
  & p.main_text {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: #57569e;
    ${media.medium} {
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      padding-top: 30px;
    }
  }
  & p.main_content {
    font-weight: 700;
    font-size: 34px;
    line-height: 54px;
    color: #0d0d0e;
    margin-top: 16px;
    ${media.medium} {
      font-size: 18px;
      line-height: 28px;
      margin-top: 10px;
    }
  }
  & div.button_box {
    width: 450px;
    display: flex;
    align-items: center;
    margin-top: 50px;
    justify-content: space-between;
    ${media.medium} {
      width: 100%;
      margin-top: 30px;
    }
    & > button {
      width: calc(50% - 5px);
    }
    &.one {
      width: 220px;
      ${media.medium} {
        width: 100%;
      }
      & > button {
        width: 100%;
      }
    }
  }
  & img.main_image {
    margin-top: 150px;
    ${media.medium} {
      display: none;
    }
  }
  & img.main_image_mobile {
    display: none;
    ${media.medium} {
      display: flex;
      width: 100%;
      margin-top: 32px;
    }
  }
  & svg.arrow_icon {
    position: absolute;
    animation: arrow 1s ease-in-out infinite;
  }
  @keyframes arrow {
    0% {
      bottom: 50px;
    }
    to {
      bottom: 30px;
    }
  }
`
const MobileIcon = styled.div`
  display: none;
  ${media.medium} {
    display: flex;
    padding: 13px 0;
    & > svg {
      width: 87px;
      height: 30px;
    }
  }
`
