import { AppRootWrapper } from 'components/layout/CommonStyle'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import ManageListArea from './ManageListArea'
import media from 'lib/styles/media'
import { inviteModalState } from 'atoms/modal'
import { useRecoilState } from 'recoil'

const ManageListComponent = () => {
  const history = useHistory()

  const [modal, setModal] = useRecoilState(inviteModalState)
  const [type, setType] = useState<'0' | '1'>('0')

  useEffect(() => {
    setModal(null)
  }, [])

  useEffect(() => {
    if (window.location.href.split('?type=')[1] === '1') setType('1')
    else setType('0')
  }, [window.location.href])

  return (
    <Wrapper>
      <h1>강의 관리</h1>
      <TabArea>
        <button
          className={type === '0' ? 'active' : ''}
          onClick={() => history.replace('/manage/list?type=0')}
        >
          예정된 강의
        </button>
        <button
          className={type === '1' ? 'active' : ''}
          onClick={() => history.replace('/manage/list?type=1')}
        >
          종료된 강의
        </button>
      </TabArea>
      <ManageListArea type={type} />
    </Wrapper>
  )
}

export default ManageListComponent

const TabArea = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  & > button {
    border: 0;
    background: none;
    outline: none;
    cursor: pointer;

    width: 50%;
    border-bottom: solid 2px #e1e1e2;
    padding-bottom: 14px;
    font-weight: 500;
    font-size: 18px;
    line-height: 1.56;
    color: #b5b5b9;

    &.active {
      font-weight: 700;
      color: #0d0d0e;
      border-bottom: solid 2px #3b3b40;
    }
  }

  ${media.medium} {
    position: relative;
    left: -20px;
    width: calc(100% + 40px);

    & > button {
      border-bottom: solid 1px #e1e1e2;
      padding-bottom: 13px;
      font-size: 14px;
      line-height: 1.71;
      color: #b5b5b9;

      &.active {
        font-weight: 700;
        color: #0d0d0e;
        padding-bottom: 12px;
        border-bottom: solid 2px #3b3b40;
      }
    }
  }
`

const Wrapper = styled(AppRootWrapper)`
  & > h1 {
    font-weight: 700;
    font-size: 24px;
    line-height: 1.5;
    color: #0d0d0e;
    padding: 40px 0 32px;
  }

  ${media.medium} {
    & > h1 {
      display: none;
    }
  }
`
