import { AppRootWrapper } from 'components/layout/CommonStyle'
import type { PaymentDetailDataProps } from 'lib/types/account'
import React from 'react'
import styled from 'styled-components'
import PaymentInfoComponent from './PaymentInfoComponent'
import MyClassInfoContent from '../myclass/MyClassInfoContent'
import PaymenClasstInfoComponent from './PaymenClasstInfoComponent'
import Button from '../Button'
import PaymentCancelComponent from './PaymentCancelComponent'
import media from 'lib/styles/media'
import PaymentCalculateComponent from './PaymentCalculateComponent'
import PaymentMultiComponent from './PaymentMultiComponent'

interface Props {
  data: PaymentDetailDataProps
  handleCancelClick: () => void
  pageType: string
  handlePaymentClick: (data: any) => void
  handleCancelReject: () => void
  handleCancelAccept: () => void
}
function PaymentDetailComponent({
  data,
  handleCancelClick,
  pageType,
  handlePaymentClick,
  handleCancelReject,
  handleCancelAccept,
}: Props) {
  const status = data.payment_history.payment_status

  const handleStatus = () => {
    if (status === 0) {
      return <p className="status payment">결제완료</p>
    } else if (status === 1) {
      return <p className="status success">수강완료</p>
    } else if (status === 2) {
      return <p className="status success">정산완료</p>
    } else if (status === 9) {
      return <p className="status cancel">취소신청</p>
    } else {
      return <p className="status cancel">결제취소</p>
    }
  }
  return (
    <Wrapper>
      <Title>
        <p>결제 내역 상세</p>
      </Title>
      <Content>
        <div className="status_box">
          {handleStatus()}
          <p className="order_number">
            {data.payment_history.id}
            {/* {data.payment_history.order_number} */}
          </p>
        </div>
        <div className="class_info">
          {/* 강의 정보_학생/튜터 */}
          <PaymenClasstInfoComponent
            image={data.payment_history.class_image?.image_url}
            title={data.payment_history.title}
            nickname={data.payment_history.tutor_nickname}
            beginDate={
              data.payment_history.real_begin_date ??
              data.payment_history.begin_date
            }
            endDate={data.payment_history.real_end_date}
            studentName={data.payment_history.user_name}
            pageType={pageType}
            time={data.payment_history.estimated_time ?? ''}
          />
        </div>
        {/* 결제 정보_학생/튜터 */}
        <div className="payment_info">
          <PaymentInfoComponent
            payment={data.payment_history.payment_method}
            price={data.payment_history.price}
            date={data.payment_history.created_at}
          />
        </div>
        {/* 정산정보_튜터 */}
        {pageType === 'tutor' && status === 2 && (
          <PaymentCalculateComponent
            date={data.payment_history.calculated_at}
            name={data.payment_history.calculate_bank_holder}
            account={data.payment_history.calculate_bank_account}
            bank={data.payment_history.calculate_bank_name}
            price={data.payment_history.calculate_price}
          />
        )}
        {/* 회차별 결제 정보_학생/튜터 */}
        {data.is_multi === 1 && (
          <PaymentMultiComponent
            data={data.step_payments}
            code={data.payment_history.id}
            handleClick={handlePaymentClick}
          />
        )}
        {/* 취소신청 정보_학생/튜터 */}
        {(status === 9 || status === 10) && (
          <div className="cancel_info">
            <PaymentCancelComponent
              cancelDate={
                data.class_user_cancel_request !== null
                  ? data.class_user_cancel_request.created_at
                  : data.payment_history.cancel_date
              }
              content={
                data.class_user_cancel_request !== null
                  ? data.class_user_cancel_request.reason
                  : data.payment_history.cancel_reason
              }
              status={status}
              payment={data.payment_history.payment_method}
              price={
                data.class_user_cancel_request !== null
                  ? data.payment_history.price
                  : data.payment_history.cancel_price
              }
              pageType={pageType}
            />
          </div>
        )}
      </Content>
      {status === 0 && (
        <div className="button_box">
          <Button
            title={pageType === 'tutor' ? '결제 취소' : '취소 신청'}
            className="border"
            onClick={handleCancelClick}
          />
        </div>
      )}
      {pageType === 'tutor' && status === 9 && (
        <div className="button_box two">
          <Button
            title="취소 신청 반려"
            className="border"
            onClick={handleCancelReject}
          />
          <Button
            title="취소 신청 승인"
            className="border"
            onClick={handleCancelAccept}
          />
        </div>
      )}
    </Wrapper>
  )
}

export default PaymentDetailComponent
const Wrapper = styled(AppRootWrapper)`
  & div.button_box {
    margin: 0 auto;
    width: 380px;
    &.two {
      display: flex;
      align-items: center;
      width: 770px;
      margin-top: 60px;
      ${media.medium} {
        width: 100%;
      }
      & > button {
        width: 380px;
      }
      & > button:first-of-type {
        margin-right: 10px;
      }
    }
    ${media.medium} {
      width: 100%;
    }
  }
`
const Title = styled.div`
  padding-top: 40px;
  ${media.medium} {
    padding-top: 0;
  }
  & > p {
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: #0d0d0e;
    ${media.medium} {
      display: none;
    }
  }
`
const Content = styled.div`
  min-height: 600px;
  ${media.medium} {
    min-height: 400px;
  }
  & div.status_box {
    margin-top: 34px;
    display: flex;
    align-items: center;
    ${media.medium} {
      margin-top: 10px;
    }
  }
  & p.status {
    width: 68px;
    padding: 4px 8px;
    border-radius: 3px;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    ${media.medium} {
      width: 57px;
      padding: 2px 6px;
      font-size: 12px;
      line-height: 22px;
    }
    &.payment {
      color: #7574d8;
      background: rgba(117, 116, 216, 0.2);
    }
    &.success {
      background: rgba(143, 225, 205, 0.2);
      color: #48d6b4;
    }
    &.cancel {
      background: rgba(255, 44, 44, 0.1);
      color: #ff2c2c;
    }
  }
  & p.order_number {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: #3b3b40;
    margin-left: 14px;
    ${media.medium} {
      margin-left: 10px;
      font-size: 16px;
      line-height: 26px;
    }
  }
  & div.class_info {
    margin-top: 20px;
  }
  & div.payment_info {
    margin-top: 40px;
    ${media.medium} {
      margin-bottom: 60px;
    }
  }
  & div.cancel_info {
    margin-top: 40px;
  }
`
