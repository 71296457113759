import media from 'lib/styles/media'
import { type RoomSeatInfoType } from 'lib/types/room'
import React from 'react'
import styled from 'styled-components'
import CommonDebateComponent from './CommonDebateComponent'

interface FocusMemberProps {
  info: RoomSeatInfoType
  isMike?: boolean
  isCamera?: boolean
}

function FocusMember({ info, isCamera, isMike }: FocusMemberProps) {
  return (
    <Wrapper>
      <CommonDebateComponent info={info} isCamera={isCamera} isMike={isMike} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  ${media.pc} {
    width: 50.27%;
    margin: 0 auto;
    border-radius: 6px;
  }

  ${media.medium} {
    width: 100%;
  }
`

export default FocusMember
