import media from 'lib/styles/media'
import React from 'react'
import styled from 'styled-components'
import ClassInfoDate from './ClassInfoDate'

interface ClassInfoProps {
  tutorInfo?: {
    isPublic?: boolean
    maxCount: number
    joinCount: number
  }

  title: string
  image: string
  name?: string
  direction?: 'column' | 'row'
  imageSize?: 'sm' | 'md' | 'lg'
  date?: {
    start: string
    end?: string
    learningTime?: number
  }
  step?: {
    title?: string
    round?: number
    total?: number
  }
  isBorder?: boolean
}

function ClassInfo({
  tutorInfo,
  image,
  title,
  step,
  name,
  date,
}: ClassInfoProps) {
  return (
    <Wrapper>
      <h4>강의 정보</h4>

      <div className="content">
        <div
          className="image"
          style={{
            backgroundImage: `url("${image}")`,
            backgroundPosition: 'center',
            objectFit: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }}
        ></div>
        <div className="info">
          {tutorInfo && (
            <div className="tutor-info">
              <div className="public">
                {tutorInfo.isPublic ? '공개' : '비공개'}
              </div>
              <div className="member-count">
                수강인원{' '}
                <span className="bold">
                  {tutorInfo.joinCount}/{tutorInfo.maxCount}
                </span>
              </div>
            </div>
          )}
          <div className="title">{title}</div>
          <div className="name">{name}</div>
          {step && step?.title && (
            <div className="step-title">{step.title}</div>
          )}
          {step && step?.round && (
            <div className="step-round">{step.round}회차</div>
          )}
          {step && step?.total && (
            <div className="step-total">총 {step.total}회차</div>
          )}
          {date && <ClassInfoDate date={date} />}
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  & > h4 {
    font-weight: 500;
    font-size: 17px;
    line-height: 26px;
    color: #0d0d0e;
  }

  & > .content {
    margin-top: 10px;
    padding: 20px;
    border: 1px solid #e1e1e2;
    border-radius: 14px;

    display: flex;
    gap: 20px;

    ${media.medium} {
      gap: 12px;
      flex-direction: column;
    }

    & > .image {
      width: 210px;
      height: 118px;
      border-radius: 8px;

      ${media.medium} {
        width: 90px;
        height: 51px;
        border-radius: 8px;
      }
    }

    & > .info > .tutor-info {
      display: flex;
      gap: 6px;

      margin-bottom: 10px;

      & > .public {
        background: #b5b5b9;
        border-radius: 3px;
        width: 46px;
        height: 27px;
        color: #ffffff;
        font-weight: 700;
        font-size: 12px;
        line-height: 22px;

        display: flex;
        align-items: center;
        justify-content: center;
      }

      & > .member-count {
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;

        ${media.medium} {
          font-weight: 500;
          font-size: 13px;
          line-height: 22px;
          color: #0d0d0e;
        }
      }
    }

    & > .info > .title {
      font-weight: 700;
      font-size: 18px;
      line-height: 28px;
      color: #0d0d0e;

      ${media.medium} {
        font-weight: 700;
        font-size: 14px;
        line-height: 24px;
        color: #3b3b40;
      }
    }

    & > .info > .name {
      margin-top: 4px;
      font-weight: 500;
      font-size: 13px;
      line-height: 22px;
      color: #7c7c80;

      ${media.medium} {
        font-weight: 500;
        font-size: 13px;
        line-height: 22px;
      }
    }

    & > .info > .step-total {
      margin-top: 8px;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;

      ${media.medium} {
        font-weight: 500;
        font-size: 13px;
        line-height: 22px;
      }
    }
  }
`

export default ClassInfo
