import React from 'react'
import styled, { css } from 'styled-components'
import { ReactComponent as UserIcon } from 'assets/icons/user_icon.svg'
import { ReactComponent as CheckboxIcon } from 'assets/icons/checkbox_button_icon.svg'
import media from 'lib/styles/media'
import { type RoomRoleType } from 'lib/types/room'
import { type ExchangedSeatType } from '../provider/RoomInfoProvider'

interface EmptySeatProps {
  onChangeSeat: () => void
  role: RoomRoleType
  exchangedSeat?: ExchangedSeatType
  onExchangeSeatAfter?: () => void
}

function EmptySeat({
  onChangeSeat,
  role,
  exchangedSeat,
  onExchangeSeatAfter,
}: EmptySeatProps) {
  return (
    <Wrapper role={role}>
      <div
        className="profile"
        onClick={role === 'student' ? onChangeSeat : undefined}
      >
        <UserIcon className="user-icon" />
      </div>

      <p className="text">비어있는 자리입니다.</p>

      {exchangedSeat && (
        <div className="checkbox-wrapper" onClick={onExchangeSeatAfter}>
          <CheckboxIcon />
        </div>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div<{ role: RoomRoleType }>`
  position: relative;
  ${media.pc} {
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.4);
    border-radius: 6px;
    background: #84848b;

    width: 100%;
    aspect-ratio: 1000 / 594;

    display: flex;
    align-items: center;
    justify-content: center;

    ${(props) =>
      props.role === 'student' &&
      css`
        cursor: pointer;
      `}

    & .text {
      display: none;
    }

    & .profile {
      width: 100%;
      height: 100%;

      display: flex;
      align-items: center;
      justify-content: center;
    }

    & .user-icon {
      width: 50%;
      height: 50%;
    }
  }

  ${media.medium} {
    height: 60px;
    border-bottom: 1px solid #7c7c80;

    display: flex;

    & .profile {
      width: 100px;
      height: 100%;
      background: #84848b;
      display: flex;

      align-items: center;
      justify-content: center;

      ${(props) =>
        props.role === 'student' &&
        css`
          cursor: pointer;
        `}
    }

    & .user-icon {
      width: 28px;
      height: 30px;
    }

    & .text {
      font-weight: 500;
      font-size: 13px;
      color: #7c7c80;

      display: flex;
      align-items: center;
      margin-left: 10px;
    }
  }

  & .checkbox-wrapper {
    position: absolute;
    top: 10px;
    right: 10px;

    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export default EmptySeat
