import { getTutorDetailRequest } from 'lib/api/tutor'
import useSWR from 'swr'

function useTutorDetail(tutorId: string) {
  const { data, isLoading } = useSWR('tutorDetail', async () => {
    const response = await getTutorDetailRequest(tutorId)
    if (!response.success) return
    return response.data.tutor
  })

  return { data, isLoading }
}

export default useTutorDetail
