import type { StudentClassDetailDataProps } from 'lib/types/account'
import { dateFormet } from 'lib/utils/CommonFuncttion'
import React from 'react'
import styled from 'styled-components'
import ClassComponent from './ClassComponent'
import media from 'lib/styles/media'

interface Props {
  data: StudentClassDetailDataProps
  handleCancelClick: (id: number) => void
}
function ClssListComponent({ data, handleCancelClick }: Props) {
  return (
    <Wrapper>
      <p className="date">{dateFormet(data.begin_date)}</p>
      <ClassContent>
        {data.class.map((item, i) => (
          <Content key={`main_student_detail_class_list_${i}`}>
            <ClassComponent
              title={item.title}
              nickname={item.nickname}
              score={item.tutor_score}
              image={item.image_url}
              profile={item.profile_url}
              category={item.categories}
              status={item.status}
              date={item.begin_date}
              multi={item.is_multi}
              stepTitle={item.step_title}
              classCancel={item.class_user_cancel_request_id}
              handleCancel={() => handleCancelClick(item.class_user_id)}
              id={item.id}
              tutorId={item.tutor_id}
            />
          </Content>
        ))}
      </ClassContent>
    </Wrapper>
  )
}

export default ClssListComponent

const Wrapper = styled.div`
  & .date {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #b5b5b9;
    margin-bottom: 16px;
    ${media.medium} {
      font-size: 13px;
      line-height: 22px;
      margin-bottom: 12px;
    }
  }
`
const ClassContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;

  ${media.medium} {
    flex-wrap: nowrap;
    overflow-x: scroll;
  }

  & > div {
    margin-bottom: 30px;
    margin-right: 20px;

    ${media.medium} {
      margin-right: 12px;
    }
  }

  & > div:nth-of-type(3n) {
    margin-right: 0;

    ${media.medium} {
      margin-right: 12px;
    }
  }
`
const Content = styled.div``
