import api from 'lib/api'
import { isAndroid } from '.'

const auth = {
  getToken: () => {
    const localToken = localStorage.getItem('token')

    if (localToken) {
      return localToken
    }
  },

  getRefreshToken: () => {
    return localStorage.getItem('refresh_token')
  },

  getSaveRefreshToken: () => {
    return localStorage.getItem('save_refresh_token')
  },

  setToken({ token, refreshToken }: { token: string; refreshToken: string }) {
    if (isAndroid()) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      window.towner.postMessage(
        JSON.stringify({
          type: 'autoLogin',
          token,
        }),
      )
    }

    api.defaults.headers.Authorization = `Bearer ${token}`
    localStorage.setItem('token', token)
    localStorage.setItem('refresh_token', refreshToken)
  },

  clearToken() {
    api.defaults.headers.Authorization = ''
    localStorage.removeItem('token')
    localStorage.removeItem('refresh_token')
  },
  setRefreshToken(token: any) {
    return localStorage.setItem('refresh_token', token)
  },
  setSaveRefreshToken(token: any) {
    return localStorage.setItem('save_refresh_token', token)
  },
  clearAppStorage() {
    return localStorage.clear()
  },
}

export default auth
