import api from 'lib/api'
import useClass from 'lib/hooks/useClass'
import type { StudentClassDataProps } from 'lib/types/account'
import { CancelContents, dateFormet } from 'lib/utils/CommonFuncttion'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import CheckPopup from '../CheckPopup'
import ClassCancelPopup from '../ClassCancelPopup'
import NoneData from '../NoneData'
import ClassComponent from './ClassComponent'
import ClssListComponent from './ClssListComponent'
import media from 'lib/styles/media'

function StudentClassDateComponent({ data }: StudentClassDataProps) {
  const { data: originData, mutate } = useClass()
  const [popup, setPopup] = useState(false)
  const [checkPopup, setCheckPopup] = useState(false)
  const [content, setContent] = useState('')
  const [classId, setClassId] = useState('')

  useEffect(() => {
    if (popup || checkPopup) {
      document.documentElement.style.overflow = 'hidden'
    } else {
      document.documentElement.style.overflow = 'auto'
    }

    return () => {
      document.documentElement.style.overflow = 'auto'
    }
  }, [popup, checkPopup])

  const handleCancelClick = (id: number) => {
    setClassId(String(id))
    setPopup(true)
  }
  const handleClose = () => {
    if (popup) setPopup(false)
  }
  const onCancelClick = async () => {
    const formData = new FormData()
    formData.append('class_user_id', classId)
    formData.append('reason', content)

    const {
      data: { success, alert },
    } = await api.post('/class/cancel/request', formData)

    if (success) {
      setCheckPopup(true)
      setPopup(false)
    } else {
      window.alert(alert)
      window.location.reload()
    }
  }

  const handleCheckCancel = () => {
    setCheckPopup(false)
    mutate()
  }
  const handleBackClick = (e: any) => {
    if (e.target.id === 'popup') {
      if (popup) setPopup(false)
    }
  }
  useEffect(() => {
    if (popup || checkPopup) {
      document.documentElement.style.overflow = 'hidden'
    } else {
      document.documentElement.style.overflow = 'auto'
    }

    return () => {
      document.documentElement.style.overflow = 'auto'
    }
  }, [popup, checkPopup])

  return (
    <>
      {data.length > 0 ? (
        <>
          {data.map((item, i) => (
            <Wrapper key={`main_student_class_list_${i}`}>
              <ClssListComponent
                data={item}
                handleCancelClick={handleCancelClick}
              />
            </Wrapper>
          ))}
        </>
      ) : (
        <NoneData title="예정된 강의가" />
      )}
      {popup && (
        <ClassCancelPopup
          value={content}
          onChange={(e) => {
            setContent(e.target.value)
          }}
          handleClose={handleClose}
          onCancelClick={onCancelClick}
          handleBackClick={handleBackClick}
        />
      )}
      {checkPopup && (
        <CheckPopup
          onClick={handleCheckCancel}
          title="수강취소 신청 완료"
          content={CancelContents}
        />
      )}
    </>
  )
}

export default StudentClassDateComponent

const Wrapper = styled.div`
  margin-bottom: 30px;
`
