import React from 'react'
import styled from 'styled-components'
import { ReactComponent as CloseIcon } from '../../assets/icons/popup_cancel_icon.svg'
import media from 'lib/styles/media'

interface Props {
  handleClose?: () => void
  type: string
}
function Tooltip({ handleClose, type }: Props) {
  return (
    <Wrapper className={type}>
      <Content>
        <p>
          {type === 'public' &&
            '비공개 강의는 수강생이 조회하는 강의 리스트에서 노출되지 않으며,\n 튜터가 강의 상세 페이지에서 직접 수강생을 초대하거나\n[상단 메뉴 > 강의 관리 > 예정된 강의]에서 강의 링크 복사 후\n수강생이 복사된 링크를 통해 비밀번호를 입력하여 직접 수강신청할 수 있습니다.\n\n*강의 방청이 필요할 경우, 복사된 링크를 통해 비밀번호를 입력하여\n수업 진행중일때 입장할 수 있습니다.'}
          {type === 'price' && '1회 수강 기준 가격을 입력해주세요'}
        </p>
        {type === 'public' && (
          <CloseIcon onClick={handleClose} className="close" />
        )}
      </Content>
    </Wrapper>
  )
}

export default Tooltip
const Wrapper = styled.div`
  width: 100%;
  position: absolute;
  top: 25px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 11px 14px;
  z-index: 1;
  &.public {
    width: 480px;
    left: 100px;
    ${media.medium} {
      left: 0;
      top: 35px;
      width: 320px;
    }
  }
  &.price {
    width: 215px;
    left: 50px;
  }
`
const Content = styled.div`
  display: flex;
  & > p {
    font-weight: 400;
    font-size: 13px;
    line-height: 19px;
    color: #7c7c80;
    white-space: break-spaces;
  }
  & > svg.close {
    margin-left: 10px;
  }
`
