import React, { useState } from 'react'
import styled from 'styled-components'
import ContentImage from '../../../assets/icons/tutor_third_content_image.png'
import { ReactComponent as Icon1 } from '../../../assets/icons/third_content_icon1.svg'
import { ReactComponent as Icon2 } from '../../../assets/icons/third_content_icon2.svg'
import { ReactComponent as Icon3 } from '../../../assets/icons/third_content_icon3.svg'
import { ReactComponent as Icon4 } from '../../../assets/icons/third_content_icon4.svg'
import media from 'lib/styles/media'
import { AppRootWrapper } from 'components/layout/CommonStyle'
import useScrollFadeIn from 'lib/hooks/useScrollFadeIn '

function TutorThirdContent() {
  const animated1: any = useScrollFadeIn({
    direction: 'up',
    duration: 1,
    delay: 0,
  })
  const animated2: any = useScrollFadeIn({
    direction: 'up',
    duration: 1,
    delay: 0,
  })
  const animated3: any = useScrollFadeIn({
    direction: 'up',
    duration: 1,
    delay: 0,
  })
  const animated4: any = useScrollFadeIn({
    direction: 'up',
    duration: 1,
    delay: 0,
  })
  return (
    <Wrapper>
      <Box>
        <Content>
          <div className="third_text_box">
            <p className="third_title">
              디베이트온의
              <br />
              튜터가 되어보세요!
            </p>
            <p className="third_text">
              이 밖에도 아래 내용과 상응하는 경험을 가지신 분
            </p>
          </div>
          <div className="content_icon_box">
            <div className="icon_box" {...animated1}>
              <Icon1 className="content_icon" />
              <p className="third_content_text">
                디베이팅 전문가,
                <br />
                코치 자격이 있으신 분
              </p>
            </div>
            <div className="icon_box right" {...animated2}>
              <Icon2 className="content_icon" />
              <p className="third_content_text">
                토론 대회의
                <br />
                수상 경험이 있으신 분
              </p>
            </div>
            <div className="icon_box top" {...animated3}>
              <Icon3 className="content_icon" />
              <p className="third_content_text">
                디베이팅, 언어/논술
                <br />
                과외 경험이 있으신 분
              </p>
            </div>
            <div className="icon_box" {...animated4}>
              <Icon4 className="content_icon" />
              <p className="third_content_text">
                아이들을 가르쳐 본 경험이
                <br />
                풍부하신 분
              </p>
            </div>
          </div>
        </Content>
        <img src={ContentImage} alt="content_image" className="third_image" />
      </Box>
    </Wrapper>
  )
}

export default TutorThirdContent
const Wrapper = styled(AppRootWrapper)`
  background: #fff;
`
const Box = styled.div`
  width: 1520px;
  display: flex;
  margin-left: auto;
  ${media.medium} {
    width: 100%;
    margin-left: 0;
  }
  & img.third_image {
    width: 960px;
    object-fit: contain;
    ${media.medium} {
      display: none;
    }
  }
`
const Content = styled.div`
  min-width: 430px;
  padding: 130px 0 120px;
  ${media.medium} {
    min-width: 100%;
    padding: 52px 0;
  }
  & p.third_title {
    font-weight: 700;
    font-size: 28px;
    line-height: 45px;
    color: #0d0d0e;
    ${media.medium} {
      font-size: 18px;
      line-height: 28px;
    }
  }
  & p.third_text {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: #a1a1af;
    margin-top: 16px;
    ${media.medium} {
      font-size: 14px;
      line-height: 24px;
      margin-top: 8px;
    }
  }
  & div.content_icon_box {
    margin-top: 60px;
    display: flex;
    flex-wrap: wrap;
    opacity: 1;
    ${media.medium} {
      margin-top: 52px;
      justify-content: space-between;
    }
    & div:nth-of-type(2n) {
      margin-left: 30px;
      ${media.medium} {
        margin-left: 0;
      }
    }
    & div:nth-of-type(3) {
      ${media.medium} {
        margin-top: 0;
      }
    }
    & div:nth-of-type(2),
    div:nth-of-type(4) {
      margin-top: 70px;
      ${media.medium} {
        margin-top: 45px;
      }
    }
  }
  & div.icon_box {
    min-width: 200px;
    width: 200px;
    height: 190px;
    ${media.medium} {
      min-width: 160px;
      width: 160px;
      height: 136px;
    }
  }
  & p.third_content_text {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: #3b3b40;
    margin-top: 26px;
    ${media.medium} {
      margin-top: 18px;
      font-size: 14px;
      line-height: 24px;
    }
  }
  & svg.content_icon {
    ${media.medium} {
      width: 70px;
      height: 70px;
    }
  }
`
