import React, { useState } from 'react'
import styled from 'styled-components'
import useSWR from 'swr'
import ManageList from './ManageList'
import TwoCheckPopup from '../TwoCheckPopup'
import CheckPopup from '../CheckPopup'
import api from 'lib/api'
import media from 'lib/styles/media'
import { handleCopy } from 'lib/utils/CommonFuncttion'
import { Swiper, SwiperSlide } from 'swiper/react'

import { ReactComponent as EmptyIcon } from '../../../assets/icons/no_data_icon.svg'

interface Props2 {
  begin_date: string
  categories: any[]
  id: number
  image_url: string
  is_close: number
  is_multi: number
  is_public: number
  join_qty: number
  max_qty: number
  price: number
  status: number
  step: number
  step_title: string
  title: string
}

interface Props {
  begin_date: string
  class: Props2[]
}

const ManageListArea = ({ type }: { type: '0' | '1' }) => {
  const { data, mutate } = useSWR(
    type === '1' ? `/class/date?type=end` : `/class/date`,
  )

  const [popup, setPopup] = useState(false)
  const [twoPopup, setTwoPopup] = useState(false)

  const [popupData, setPopupData] = useState({
    title: '',
    content: '',
    btn: '',
    id: '',
  })

  if (!data) return null
  if (!data.data) return null

  const originData = data?.data?.classes

  const handleDelete = async () => {
    try {
      const {
        data: { success, alert },
      } = await api.delete('/class/delete?class_id=' + String(popupData.id))
      if (success) {
        mutate()
        setTwoPopup(false)
        setPopupData({
          title: '',
          content: '',
          btn: '',
          id: '',
        })
      } else {
        window.alert(alert)
      }
    } catch (err) {}
  }

  const handleClose = async () => {
    try {
      const formData = new FormData()
      formData.append('class_id', String(popupData.id))

      const {
        data: { success, alert },
      } = await api.post('/class/close', formData)
      if (success) {
        mutate()
        setTwoPopup(false)
        setPopupData({
          title: '',
          content: '',
          btn: '',
          id: '',
        })
      } else {
        window.alert(alert)
      }
    } catch (err) {}
  }

  return (
    <Wrapper>
      {originData?.length > 0 ? (
        originData?.map((item: Props, i: number) => (
          <Box
            key={'manage_list_box' + i}
            style={{ zIndex: originData.length - i }}
          >
            <h1>{item.begin_date}</h1>
            <ManageListBox>
              {item.class?.map((item: Props2, j) => (
                <ManageList
                  key={'pc_manage_list' + j}
                  data={item}
                  type={type}
                  handleDelete={(id: string) => {
                    setPopupData({
                      title: '강의 삭제',
                      content:
                        '삭제시 수강 신청한 수강생의 결제 금액이 모두\n환불됩니다. 강의를 삭제하시겠습니까?',
                      btn: '삭제',
                      id,
                    })
                    setTwoPopup(true)
                  }}
                  handleClose={(type: string, id: string) => {
                    setPopupData({
                      title: type === 'close' ? '모집 마감' : '마감 해제',
                      content:
                        type === 'close'
                          ? '모집 마감시 더 이상 수강신청할 수 없습니다.\n모집 마감하시겠습니까?'
                          : '강의 마감을 해제하시겠습니까?',
                      btn: type === 'close' ? '마감' : '해제',
                      id,
                    })
                    setTwoPopup(true)
                  }}
                  handleLink={() => {
                    handleCopy(
                      window.location.href.split('/manage/list')[0] +
                        '/class/' +
                        item.id,
                    )
                    setPopup(true)
                  }}
                />
              ))}
            </ManageListBox>
            <ManageListMobileBox
              spaceBetween={12}
              breakpoints={{
                0: { slidesPerView: 1.1 },
                769: { slidesPerView: 2 },
              }}
            >
              {item.class?.map((item, j) => (
                <SwiperSlide key={`mobile_manage_list${j}`}>
                  <ManageList
                    last={originData.length - 1 === i}
                    data={item}
                    type={type}
                    handleDelete={(id: string) => {
                      setPopupData({
                        title: '강의 삭제',
                        content:
                          '삭제시 수강 신청한 수강생의 결제 금액이 모두\n환불됩니다. 강의를 삭제하시겠습니까?',
                        btn: '삭제',
                        id,
                      })
                      setTwoPopup(true)
                    }}
                    handleClose={(type: string, id: string) => {
                      setPopupData({
                        title: type === 'close' ? '모집 마감' : '마감 해제',
                        content:
                          type === 'close'
                            ? '모집 마감시 더 이상 수강신청할 수 없습니다.\n모집 마감하시겠습니까?'
                            : '강의 마감을 해제하시겠습니까?',
                        btn: type === 'close' ? '마감' : '해제',
                        id,
                      })
                      setTwoPopup(true)
                    }}
                    handleLink={() => {
                      handleCopy(window.location.href)
                      setPopup(true)
                    }}
                  />
                </SwiperSlide>
              ))}
            </ManageListMobileBox>
          </Box>
        ))
      ) : (
        <EmptyBox>
          <EmptyIcon />
          {type === '0' ? '예정된 강의가 없습니다.' : '종료된 강의가 없습니다.'}
        </EmptyBox>
      )}
      {twoPopup && (
        <TwoCheckPopup
          title={popupData.title}
          content={popupData.content}
          oncancelClick={() => {
            setTwoPopup(false)
          }}
          onClick={() => {
            if (popupData.title.includes('마감')) handleClose()
            else handleDelete()
          }}
          btn={popupData.btn}
        />
      )}
      {popup && (
        <CheckPopup
          onClick={() => setPopup(false)}
          title="강의 링크 복사"
          content="강의 링크가 복사되었습니다."
        />
      )}
    </Wrapper>
  )
}

export default ManageListArea

const ManageListMobileBox = styled(Swiper)`
  width: 100%;
  padding: 0 20px;
  display: none;
  overflow: visible;

  ${media.medium} {
    display: block;
  }
`

const ManageListBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  ${media.medium} {
    display: none;
  }
`

const Box = styled.div`
  position: relative;

  & > h1 {
    font-weight: 500;
    font-size: 14px;
    line-height: 1.71;
    color: #b5b5b9;
    margin-bottom: 16px;
  }

  &:nth-of-type(n + 2) {
    margin-top: 46px;
  }

  ${media.medium} {
    & > h1 {
      font-size: 13px;
      line-height: 1.69;
      margin-bottom: 12px;
      padding: 0 20px;
    }

    &:nth-of-type(n + 2) {
      margin-top: 40px;
    }
  }
`

const EmptyBox = styled.div`
  padding: 160px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.62;
  color: #b5b5b9;

  & > svg {
    width: 38px;
    height: 38px;
    margin-bottom: 16px;
  }
`

const Wrapper = styled.div`
  padding: 40px 0 0;

  ${media.medium} {
    padding: 20px 0 0;
    position: relative;
    left: -20px;
    width: calc(100% + 40px);
  }
`
