import ClassPaymentInfo from 'components/common/class/ClassPaymentInfo'
import ClassPaymentMethod from 'components/common/class/ClassPaymentMethod'
import ClassPaymentPrice from 'components/common/class/ClassPaymentPrice'
import ClassPaymentSubmit from 'components/common/class/ClassPaymentSubmit'
import { type ChangeMethodType } from 'components/common/class/PaymentMethod'
import PageLoadingComponent from 'components/common/PageLoadingComponent'
import { AppRootWrapper } from 'components/layout/CommonStyle'
import { paymentClass } from 'lib/api/class'
import media from 'lib/styles/media'
import { type ClassDetailType } from 'lib/types/class'
import { type PaymentMethodType } from 'lib/types/common'
import React, { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import useClassInfo from './hooks/useClassInfo'

interface Props {
  data: ClassDetailType
}

function ClassRequestContainer({ data }: Props) {
  const { classId }: { classId: string } = useParams()

  const [formdata, setFormdata] = useState<
    | {
        method?: PaymentMethodType
        term?: boolean
      }
    | undefined
  >()

  const handleChangeMethod: ChangeMethodType = (value) => {
    setFormdata((prev) => {
      if (!prev)
        return {
          method: value,
        }
      return {
        ...prev,
        method: value,
      }
    })
  }

  const handleChangeTerm = () => {
    setFormdata((prev) => {
      if (!prev) {
        return {
          term: true,
        }
      }

      return {
        ...prev,
        term: !prev?.term,
      }
    })
  }

  const isValid = useMemo(() => {
    return formdata?.method && formdata?.term
  }, [formdata])

  const handleSubmit = () => {
    if (!formdata?.method || !formdata?.term) return
    paymentClass({ classId, paymentMethod: formdata.method })
  }

  return (
    <Wrapper>
      <h3 className="title">강의 결제</h3>
      <div className="content">
        <div className="left">
          <ClassPaymentPrice price={data.price} />
          <ClassPaymentMethod
            onChangeMethod={handleChangeMethod}
            method={formdata?.method}
          />
        </div>
        <div className="right">
          <ClassPaymentInfo
            info={{
              imageUrl: data.images[0].image_url,
              title: data.title,
              name: data.name,
              steps: data.steps,
            }}
            term={formdata?.term}
            onChangeTerm={handleChangeTerm}
            isValid={isValid}
            onSubmit={handleSubmit}
          />
        </div>

        <div className="pc-hidden submit">
          <ClassPaymentSubmit
            term={formdata?.term}
            onChangeTerm={handleChangeTerm}
            isValid={isValid}
            onSubmit={handleSubmit}
          />
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled(AppRootWrapper)`
  padding-top: 40px;

  ${media.medium} {
    padding-top: 20px;
  }

  & .title {
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: #0d0d0e;

    ${media.medium} {
      font-weight: 700;
      font-size: 18px;
      line-height: 28px;
      color: #0d0d0e;
    }
  }

  & > .content {
    ${media.pc} {
      display: flex;
      gap: 40px;
      margin-top: 40px;
    }

    ${media.medium} {
      display: flex;
      flex-direction: column;
      gap: 40px;
    }
  }

  & .left {
    ${media.pc} {
      flex: 2;
    }
    ${media.medium} {
      order: 1;
    }
  }

  & .right {
    ${media.pc} {
      flex: 1;
    }
    ${media.medium} {
      order: 0;
    }
  }

  & .submit {
    ${media.medium} {
      order: 3;
    }
  }
`

export default ClassRequestContainer
