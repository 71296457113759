import Footer from 'components/common/Footer'
import Header from 'components/common/header/Header'
import WelcomeComponent from 'components/common/login/WelcomeComponent'
import media from 'lib/styles/media'
import React from 'react'
import styled from 'styled-components'

function StudentLogin() {
  return (
    <Wrapper>
      <Header />
      <Content>
        <WelcomeComponent />
      </Content>
      <Footer type="none" />
    </Wrapper>
  )
}

export default StudentLogin
const Wrapper = styled.div``
const Content = styled.div`
  margin-top: 100px;
  ${media.medium} {
    margin-top: 0;
  }
`
