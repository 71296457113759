import { PageTypeState } from 'atoms/common'
import Footer from 'components/common/Footer'
import Gnb from 'components/common/Gnb'
import Header from 'components/common/header/Header'
import LogoMobileHeader from 'components/common/header/LogoMobileHeader'
import RootComponent from 'components/common/rootpage/RootComponent'
import useIngMain from 'lib/hooks/useIngMain'
import useMain from 'lib/hooks/useMain'
import media from 'lib/styles/media'
import React, { useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import styled from 'styled-components'
import useSWR from 'swr'

function StudentRootPage() {
  const { data: originData, mutate } = useMain()
  const { data: classIngData, mutate: ingmutate } = useIngMain()

  if (!originData || !classIngData) return null
  if (!originData.data || !classIngData.data) return null

  return (
    <Wrapper>
      <Header />
      <LogoMobileHeader />
      <ContentWra>
        <RootComponent data={originData.data} ingData={classIngData.data} />
      </ContentWra>
      <Footer type="bottom" />
      <Gnb />
    </Wrapper>
  )
}

export default StudentRootPage
const Wrapper = styled.div``
const ContentWra = styled.div`
  margin-top: 140px;
  ${media.medium} {
    margin-top: 73px;
  }
`
