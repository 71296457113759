import React, { useEffect } from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'
import Root from 'pages/Root'
import RoomPage from 'pages/room/RoomPage'
import AuthRoute from 'components/common/AuthRoute'
import SearchPage from 'pages/search/SearchPage'
import IntendedClass from 'pages/intended/IntendedClass'
import MenuPage from 'pages/menu/MenuPage'
import CategoryPage from 'pages/category/CategoryPage'
import LikeClassPage from 'pages/like/LikeClassPage'
import NoticePage from 'pages/notice/NoticePage'
import AlarmPage from 'pages/alarm/AlarmPage'
import MobileSearchPage from 'pages/search/MobileSearchPage'
import LoginPage from 'pages/login/LoginPage'
import JoinPage from 'pages/join/JoinPage'
import RegisterSmsPage from 'pages/sms/RegisterSmsPage'
import JoininfoPage from 'pages/joinInfo/JoininfoPage'
import JoininfoBasicPage from 'pages/joininfobasic/JoininfoBasicPage'
import FindIdPage from 'pages/findid/FindIdPage'
import FindIdSuccessPage from 'pages/findid/FindIdSuccessPage'
import FindPasswordPage from 'pages/findpw/FindPasswordPage'
import FindPasswordSuccessPage from 'pages/findpw/FindPasswordSuccessPage'
import EditInfoPage from 'pages/edit/EditInfoPage'
import EditMyinfoPage from 'pages/edit/EditMyinfoPage'
import MyClassPage from 'pages/myclass/MyClassPage'
import MyClassReviewPage from 'pages/myclass/MyClassReviewPage'
import Core from 'containers/base/Core'
import MyClassFeedbackPage from 'pages/myclass/MyClassFeedbackPage'
import MyPayMentPage from 'pages/payment/MyPayMentPage'
import MyPaymentDetailpage from 'pages/payment/MyPaymentDetailpage'
import NoticeDetailPage from 'pages/notice/StudentNoticeDetail'
import StudentIdLogin from 'pages/id/StudentIdLogin'
import KakaoLoginPage from 'pages/login/KakaoLoginPage'
import RegisterClass from 'pages/register/RegisterClass'
import PdfPage from 'pages/pdf/PdfPage'
import EditRegisterClass from 'pages/register/EditRegisterClass'
import ManageListPage from 'pages/manage/ManageListPage'
import { SWRConfig } from 'swr'
import api from 'lib/api'
import ManageDetailPage from 'pages/manage/ManageDetailPage'
import FeedBackRegisterPage from 'pages/manage/FeedBackRegisterPage'
import { useRecoilState } from 'recoil'
import { userState } from 'atoms/account'
import MyClassEndPage from 'pages/myclass/MyClassEndPage'
import ClassRequestPage from 'pages/class/ClassRequestPage'
import ClassCompletePage from 'pages/class/ClassCompletePage'
import ReferencePage from 'pages/reference/ReferencePage'
import ReferenceRegisterPage from 'pages/reference/ReferenceRegisterPage'
import ReferenceDetailPage from 'pages/reference/ReferenceDetailPage'
import TutorDetailPage from 'pages/tutor/TutorDetailPage'
import { initialize } from 'lib/utils/initialize'
import ManagePublicPage from 'pages/manage/ManagePublicPage'

function App() {
  const [user, setUser] = useRecoilState(userState)
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  useEffect(() => {
    initialize()
  }, [])

  // useEffect(() => {
  //   try {
  //     const w: any = window
  //     ;(function () {
  //       if (w.ChannelIO) {
  //         return (window.console.error || window.console.log || function () {})(
  //           'ChannelIO script included twice.',
  //         )
  //       }
  //       const ch: any = function () {
  //         ch.c(arguments)
  //       }
  //       ch.q = []
  //       ch.c = function (args: any) {
  //         ch.q.push(args)
  //       }
  //       w.ChannelIO = ch
  //       function l() {
  //         if (w.ChannelIOInitialized) {
  //           return
  //         }
  //         w.ChannelIOInitialized = true
  //         const s = document.createElement('script')
  //         s.type = 'text/javascript'
  //         s.async = true
  //         s.src = 'https://cdn.channel.io/plugin/ch-plugin-web.js'
  //         s.charset = 'UTF-8'
  //         const x: any = document.getElementsByTagName('script')[0]
  //         x.parentNode.insertBefore(s, x)
  //       }
  //       if (document.readyState === 'complete') {
  //         l()
  //       } else if (w.attachEvent) {
  //         w.attachEvent('onload', l)
  //       } else {
  //         w.addEventListener('DOMContentLoaded', l, false)
  //         w.addEventListener('load', l, false)
  //       }
  //     })()

  //     if (user) {
  //       if (user) {
  //         w.ChannelIO('boot', {
  //           pluginKey: 'a1ff850f-767e-4f72-b39a-7e2098463327',
  //           memberId: user?.email,
  //           profile: {
  //             name: user?.name,
  //             mobileNumber: '-',
  //           },
  //         })
  //         // w.ChannelIO('hideChannelButton')
  //       }
  //     } else {
  //       w.ChannelIO('boot', {
  //         pluginKey: 'a1ff850f-767e-4f72-b39a-7e2098463327',
  //       })
  //     }
  //   } catch (error) {
  //     console.log(error)
  //   }

  //   return () => {}
  // }, [user])

  return (
    <>
      <Switch>
        <Route path="/" exact>
          <AuthRoute component={Root} />
        </Route>
        <Route path="/room/:roomId">
          <AuthRoute component={RoomPage} />
        </Route>
        <Route path="/search/:search" exact component={SearchPage} />.
        <Route path="/class" exact component={IntendedClass} />
        <Route path="/menu" exact>
          <AuthRoute component={MenuPage} />
        </Route>
        <Route path="/category/:categoryId" exact component={CategoryPage} />
        <Route path="/like" exact component={LikeClassPage} />
        <Route path="/notice" exact>
          <AuthRoute component={NoticePage} />
        </Route>
        <Route path="/notice/:noticeId" exact component={NoticeDetailPage} />
        <Route path="/alarm" exact component={AlarmPage} />
        <Route path="/mobile/search" exact component={MobileSearchPage} />
        <Route path="/login" exact>
          <AuthRoute component={LoginPage} />
        </Route>
        <Route path="/login/id" exact>
          <AuthRoute component={StudentIdLogin} />
        </Route>
        <Route path="/join" exact>
          <AuthRoute component={JoinPage} />
        </Route>
        <Route path="/social/kakao" exact component={KakaoLoginPage} />
        <Route path="/join/sms" exact>
          <AuthRoute component={RegisterSmsPage} />
        </Route>
        <Route path="/join/info" exact>
          <AuthRoute component={JoininfoPage} />
        </Route>
        <Route path="/join/info/basic" exact>
          <AuthRoute component={JoininfoBasicPage} />
        </Route>
        <Route path="/find/id" exact component={FindIdPage} />
        <Route path="/find/id/:findId" exact component={FindIdSuccessPage} />
        <Route path="/find/password" exact component={FindPasswordPage} />
        <Route
          path="/find/password/edit"
          exact
          component={FindPasswordSuccessPage}
        />
        <Route path="/mypage/edit/info" exact component={EditInfoPage} />
        <Route path="/mypage/edit/myinfo" exact component={EditMyinfoPage} />
        <Route path="/myclass" exact component={MyClassPage} />
        <Route
          path="/myclass/review/:classId"
          exact
          component={MyClassReviewPage}
        />
        <Route path="/myclass/end/:classId/:step" exact>
          <AuthRoute component={MyClassEndPage} />
        </Route>
        <Route
          path="/myclass/feedback/:classId"
          exact
          component={MyClassFeedbackPage}
        />
        <Route path="/payment" exact component={MyPayMentPage} />
        <Route
          path="/payment/detail/:paymentId"
          exact
          component={MyPaymentDetailpage}
        />
        <Route path="/pdf" exact component={PdfPage} />
        <Route path="/register/class" exact component={RegisterClass} />
        <Route
          path="/register/class/:classId"
          exact
          component={EditRegisterClass}
        />
        <Route path="/manage/list" exact component={ManageListPage} />
        <Route path="/class/:id" exact component={ManagePublicPage} />
        <Route
          path="/feedback/:class/:id"
          exact
          component={FeedBackRegisterPage}
        />
        <Route
          path="/class/request/:classId"
          exact
          component={ClassRequestPage}
        />
        <Route
          path="/class/complete/:classId"
          exact
          component={ClassCompletePage}
        />
        <Route
          path="/class/complete/:classId/:orderId"
          exact
          component={ClassCompletePage}
        />
        <Route path="/reference" exact component={ReferencePage} />
        <Route
          path="/reference/detail/:id"
          exact
          component={ReferenceDetailPage}
        />
        <Route
          path="/reference/register"
          exact
          component={ReferenceRegisterPage}
        />
        <Route
          path="/tutor/detail/:tutorId"
          exact
          component={TutorDetailPage}
        />
      </Switch>
      <Core />
    </>
  )
}

export default App
