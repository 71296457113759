import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import { ReactComponent as ArrowIcon } from 'assets/icons/left_arrow.svg'
import { ReactComponent as MikeOnIcon } from 'assets/icons/mike_on_icon.svg'
import { ReactComponent as MikeOffIcon } from 'assets/icons/mike_off_icon.svg'
import { ReactComponent as CameraOnIcon } from 'assets/icons/camera_on_icon.svg'
import { ReactComponent as CameraOffIcon } from 'assets/icons/camera_off_icon.svg'
import { ReactComponent as DocumentIcon } from 'assets/icons/document_icon.svg'
import { ReactComponent as ExitIcon } from 'assets/icons/exit_icon.svg'
import { RoomFooterWrapper } from './TutorRoomFooter'
import {
  useRoomInfoAction,
  useRoomInfoValue,
} from '../provider/RoomInfoProvider'
import FooterIconWrap from 'components/common/FooterIconWrap'
import { type RoomSeatInfoType } from 'lib/types/room'
import useRoom from 'lib/hooks/room/useRoom'
import { useParams } from 'react-router-dom'

interface TeamRoomFooterProps {
  roomId: string
  team: 'A' | 'B'
}

function TeamRoomFooter({ roomId, team }: TeamRoomFooterProps) {
  const [open, setOpen] = useState(false)
  const { myRoomInfo, tutorRoomInfo, role } = useRoomInfoValue()
  const { modifyRoomInfo, modifyTutorInfo } = useRoomInfoAction()
  const { closeTeamRoomTrigger } = useRoom(roomId)

  const roomInfo = useMemo(() => {
    if (role === 'student') return myRoomInfo
    return tutorRoomInfo
  }, [myRoomInfo, tutorRoomInfo, role]) as RoomSeatInfoType

  const onToggle = () => {
    setOpen(!open)
  }

  const handleToggleCamera = () => {
    if (role === 'tutor') {
      if (tutorRoomInfo?.isSecretEnter) return
      modifyTutorInfo &&
        modifyTutorInfo({
          isCam: roomInfo.is_camera !== 1,
          isMike: roomInfo.is_mike === 1,
        })
    }

    if (role === 'student') {
      modifyRoomInfo &&
        modifyRoomInfo({
          isCam: roomInfo.is_camera !== 1,
          isMike: roomInfo.is_mike === 1,
          tableNum: roomInfo.table_num,
          userId: roomInfo.user_id,
        })
    }
  }
  const handleToggleMike = () => {
    if (role === 'tutor') {
      if (tutorRoomInfo?.isSecretEnter) return
      modifyTutorInfo &&
        modifyTutorInfo({
          isCam: roomInfo.is_camera === 1,
          isMike: roomInfo.is_mike !== 1,
        })
    }

    if (role === 'student') {
      modifyRoomInfo &&
        modifyRoomInfo({
          isCam: roomInfo.is_camera === 1,
          isMike: roomInfo.is_mike !== 1,
          tableNum: roomInfo.table_num,
          userId: roomInfo.user_id,
        })
    }
  }

  const handleExitTeamRoom = () => {
    closeTeamRoomTrigger(team)
  }

  return (
    <Wrapper>
      <div className="arrow-wrap" onClick={onToggle}>
        <ArrowIcon className={`arrow ${open ? 'arrow-down' : 'arrow-up'}`} />
      </div>
      {open && (
        <div className="buttons">
          <FooterIconWrap
            Icon={ExitIcon}
            label={'회의 종료'}
            onClick={handleExitTeamRoom}
          />

          <FooterIconWrap
            Icon={roomInfo?.is_camera === 1 ? CameraOffIcon : CameraOnIcon}
            label={roomInfo?.is_camera === 1 ? '화면 off' : '화면 on'}
            onClick={handleToggleCamera}
          />
          <FooterIconWrap
            Icon={roomInfo?.is_mike === 1 ? MikeOffIcon : MikeOnIcon}
            label={roomInfo?.is_mike === 1 ? '마이크 off' : '마이크 on'}
            onClick={handleToggleMike}
          />
          <div className="divide" />
          <FooterIconWrap Icon={DocumentIcon} label="자료" />
        </div>
      )}
    </Wrapper>
  )
}

const Wrapper = styled(RoomFooterWrapper)`
  & .divide {
  }
`

export default TeamRoomFooter
