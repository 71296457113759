import Footer from 'components/common/Footer'
import PagiNationComponent from 'components/common/PagiNationComponent'
import MobileTitleHeader from 'components/common/header/MobileTitleHeader'
import TutorHeader from 'components/common/header/TutorHeader'
import NoticeComponent from 'components/common/notice/NoticeComponent'
import useUser from 'lib/hooks/account/useUser'
import useNotice from 'lib/hooks/useNotice'
import media from 'lib/styles/media'
import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

function TutorNotice() {
  const type = 'tutor'
  const [page, setPage] = useState(1)
  const { user } = useUser()
  const tutorUser = user && user.type === 'tutor'
  const { data: originData } = useNotice({ page, type })
  const history = useHistory()

  useEffect(() => {
    if (!tutorUser) return history.push('/login?role=tutor')
  }, [])

  const handleChangePage = (page: number) => {
    setPage(page)
  }
  if (!originData) return null
  if (!originData.data) return null
  if (!originData.data.notices) return null

  return (
    <Wrapper>
      <TutorHeader />
      <MobileTitleHeader type="arrow" title="공지사항" />
      <ContentWra>
        <NoticeComponent
          data={originData.data.notices.data}
          total={originData.data.notices.total}
        />
        <PageBox>
          <PagiNationComponent
            page={page}
            total={originData.data.notices.total}
            onChange={handleChangePage}
          />
        </PageBox>
      </ContentWra>
      <Footer />
    </Wrapper>
  )
}

export default TutorNotice
const Wrapper = styled.div``
const ContentWra = styled.div`
  margin-top: 140px;
  ${media.medium} {
    margin-top: 10px;
  }
`
const PageBox = styled.div`
  margin: 80px 0 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${media.medium} {
    margin: 40px 0 80px;
  }
`
