import { AppRootWrapper } from 'components/layout/CommonStyle'
import React from 'react'
import styled from 'styled-components'
import MyClassInfoContent from './MyClassInfoContent'
import FeedbackChart from './FeedbackChart'
import FeedbackListContent from './FeedbackListContent'
import useSWR from 'swr'
import { useParams } from 'react-router-dom'
import TutorFeedbackContent from './TutorFeedbackContent'
import media from 'lib/styles/media'
import { useRecoilValue } from 'recoil'
import { MyClassListDataPayloadState } from 'atoms/join'

function FeedBackComponent() {
  const params = useParams<{ classId: string }>()
  const classId = params.classId
  const { data: originData } = useSWR(
    `/class/user/feedback?class_user_id=${classId}`,
  )
  const payload = useRecoilValue(MyClassListDataPayloadState)

  if (!originData) return null
  if (!originData.data) return null

  return (
    <Wrapper>
      <InfoContent>
        <p>튜터 피드백</p>
        <MyClassInfoContent info={payload} />
      </InfoContent>
      <Span />
      <FeedbackChart
        data={originData.data.class_user_feedbacks}
        maxScore={originData.data.max_feedback_score}
      />
      <TutorFeedbackContent content={originData.data.feedback_review} />
      <FeedbackListContent
        data={originData.data.class_user_feedbacks}
        maxScore={originData.data.max_feedback_score}
      />
    </Wrapper>
  )
}

export default FeedBackComponent
const Wrapper = styled(AppRootWrapper)``
const InfoContent = styled.div`
  padding-top: 40px;
  ${media.medium} {
    padding-top: 0;
  }
  & > p {
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: #0d0d0e;
    margin-bottom: 40px;
    ${media.medium} {
      display: none;
    }
  }
`
const Span = styled.div`
  height: 12px;
  background: #f9f9fb;
  margin: 50px 0;
  ${media.medium} {
    width: calc(100% + 40px);
    transform: translateX(-20px);
    margin: 25px 0;
  }
`
