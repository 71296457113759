import { AppRootWrapper } from 'components/layout/CommonStyle'
import media from 'lib/styles/media'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import useSWR from 'swr'
import PagiNationComponent from '../PagiNationComponent'
import Button from '../Button'
import { PriceNum } from 'lib/utils/CommonFuncttion'
import moment from 'moment'
import { Empty, Select } from 'antd'

import { ReactComponent as Pin } from 'assets/icons/pin_icon.svg'
import { ReactComponent as Search } from 'assets/icons/mobile_search_icon.svg'
import { ReactComponent as EmptyIcon } from 'assets/icons/no_data_icon.svg'
import { ReactComponent as AdminIcon } from '../../../assets/icons/reference_pin_icon.svg'
import { ReactComponent as BasicImage } from '../../../assets/icons/none_profile_icon.svg'

interface Props {
  created_at: string
  id: number
  is_notice: number
  nickname: string
  profile_url: string
  title: string
  view: number
  is_admin: number
}

const ReferenceComponent = ({ type }: { type: string }) => {
  const history = useHistory()

  const [tab, setTab] = useState<'0' | '1'>('0')
  const [page, setPage] = useState('1')
  const [searchtype, setSearchtype] = useState('all')
  const [search, setSearch] = useState('')
  const [totalSearch, setTotalSearch] = useState('')

  const { data, mutate } = useSWR(
    `/reference?type=${
      type === 'user' || tab === '0' ? 'user' : 'tutor'
    }&search=${totalSearch}&search_type=${searchtype}&page=${page}`,
  )

  const originData = data?.data?.reference_rooms

  useEffect(() => {
    if (window.location.href.split('?tab=')[1] === '1') setTab('1')
    else setTab('0')

    setPage('1')
    setSearchtype('all')
    setSearch('')
  }, [window.location.href])

  const handleSearch = () => {
    setTotalSearch(search)
  }
  const handleChangePage = (page: number) => {
    setPage(String(page))
  }

  const onChange = (value: string) => {
    setSearchtype(value)
  }

  return (
    <Wrapper>
      <h1>레퍼런스 룸</h1>
      {type !== 'user' && (
        <TabArea>
          <button
            className={tab === '0' ? 'active' : ''}
            onClick={() => history.replace('/reference?tab=0')}
          >
            수강생용
          </button>
          <button
            className={tab === '1' ? 'active' : ''}
            onClick={() => history.replace('/reference?tab=1')}
          >
            튜터용
          </button>
        </TabArea>
      )}
      <Content>
        {originData?.data.length > 0 ? (
          originData?.data?.map((item: Props, i: number) => (
            <List
              key={'reference_list_' + i}
              className={item.is_notice === 1 ? 'notice' : ''}
              onClick={() => history.push(`/reference/detail/${item.id}`)}
            >
              <ListText className={item.is_notice === 1 ? 'notice' : ''}>
                <h1>
                  {item.is_notice === 1 && <Pin />}
                  <span>{item.title}</span>
                </h1>
                <p>
                  {item.is_admin === 1 ? (
                    <AdminIcon className="admin_image" />
                  ) : !item.profile_url ? (
                    <BasicImage className="basic_image" />
                  ) : (
                    <img src={item.profile_url} />
                  )}

                  {item.nickname}
                  <span />
                  <b>조회수 {PriceNum(item.view)}</b>
                  <b>{moment(item.created_at).format('YYYY.MM.DD')}</b>
                </p>
              </ListText>
              <p>조회수 {PriceNum(item.view)}</p>
              <p>{moment(item.created_at).format('YYYY.MM.DD')}</p>
            </List>
          ))
        ) : (
          <EmptyBox>
            <EmptyIcon />
            {search === '' ? '게시글이 없습니다.' : '검색 결과가 없습니다.'}
          </EmptyBox>
        )}
      </Content>
      <PageBox>
        <PagiNationComponent
          page={Number(page)}
          total={originData?.data.length > 0 ? originData?.total : 1}
          onChange={handleChangePage}
        />
        {type !== 'user' && (
          <div className="buttonbox">
            <Button
              className="small"
              title="글쓰기"
              onClick={() => history.push(`/reference/register`)}
            />
          </div>
        )}
      </PageBox>
      <SearchBox>
        <Select
          placeholder="튜터명+제목"
          onChange={onChange}
          options={[
            {
              label: '튜터명+제목',
              value: 'all',
            },
            {
              label: '튜터명',
              value: 'nickname',
            },
            {
              label: '제목',
              value: 'title',
            },
          ]}
          defaultValue={'all'}
        />
        <SearchInput>
          <input
            type="text"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <Search onClick={handleSearch} />
        </SearchInput>
      </SearchBox>
    </Wrapper>
  )
}

export default ReferenceComponent

const EmptyBox = styled.div`
  padding: 160px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.62;
  color: #b5b5b9;

  & > svg {
    width: 38px;
    height: 38px;
    margin-bottom: 16px;
  }
`

const SearchInput = styled.div`
  position: relative;
  width: 400px;
  height: 46px;
  padding: 0 14px;
  border: 1px solid #e1e1e2;
  border-radius: 8px;
  margin-left: 10px;
  display: flex;
  align-items: center;

  & > input {
    width: 100%;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.71;
    color: #0d0d0e;
    border: 0;
    outline: none;
  }

  & > svg {
    width: 18px;
    height: 18px;
    position: absolute;
    right: 14px;
    top: 50%;
    transform: translateY(-50%);
  }
`

const SearchBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  & > div:first-of-type > div {
    width: 130px !important;
    height: 46px !important;

    & .ant-select-selection-item {
      font-family: 'Noto Sans KR', sans-serif;
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 14px;
      line-height: 1.71;
      color: #0d0d0e;
    }
  }
`

const PageBox = styled.div`
  margin: 80px 0 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  & > div.buttonbox {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: 143px;

    & > button {
      max-width: 143px;
    }
  }

  ${media.medium} {
    margin: 40px 0;

    & > div.buttonbox {
      position: fixed;
      top: auto;
      bottom: 0;
      right: 0;
      transform: none;
      width: 100%;
      padding: 20px;
      background: #fff;
      z-index: 1;

      & > button {
        max-width: 100%;
        height: 50px;
      }
    }
  }
`

const ListText = styled.div`
  width: calc(100% - 180px);

  & > h1 {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.62;
    color: #0d0d0e;
    margin-bottom: 6px;

    & > span {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    & > svg {
      width: 15px;
      min-width: 15px;
      height: 15px;
      margin-right: 10px;
    }
  }
  & svg.admin_image {
    width: 18px;
    min-width: 18px;
    height: 18px;
    margin-right: 6px;
    border-radius: 50px;
  }
  & svg.basic_image {
    width: 18px;
    min-width: 18px;
    height: 18px;
    margin-right: 6px;
    border-radius: 50px;
    object-fit: cover;
  }
  & > p {
    font-weight: 500;
    font-size: 13px;
    line-height: 1;
    color: #7c7c80;
    display: flex;
    align-items: center;

    & > img {
      width: 18px;
      min-width: 18px;
      height: 18px;
      margin-right: 6px;
      border-radius: 50px;
      object-fit: cover;
    }

    & > span {
      display: none;
      width: 2px;
      height: 2px;
      margin: 2px 8px;
      background: #7c7c80;
    }

    & > b {
      display: none;
      margin-right: 8px;
      font-weight: 400;
      font-size: 12px;
      line-height: 1.83;
      color: #7c7c80;
    }
  }

  &.notice {
    & > p {
      padding-left: 25px;
    }
  }

  ${media.medium} {
    width: 100%;

    & > h1 {
      font-size: 14px;
      line-height: 1.71;
      margin-bottom: 7px;

      & > svg {
        width: 12px;
        min-width: 12px;
        height: 12px;
        margin-right: 8px;
      }
    }

    & > p {
      font-size: 12px;

      & > img {
        width: 16px;
        min-width: 16px;
        height: 16px;
        margin-right: 5px;
      }

      & > span,
      & > b {
        display: block;
      }
    }

    &.notice {
      & > p {
        padding-left: 20px;
      }
    }
  }
`

const List = styled.div`
  padding: 14px 20px;
  display: flex;
  align-items: center;
  background: #fff;
  cursor: pointer;

  &:hover {
    background: #f9f9fb;
  }

  &.notice {
    background: rgba(117, 116, 216, 0.05);
  }

  & > p {
    font-weight: 400;
    font-size: 13px;
    line-height: 1.69;
    text-align: right;
    color: #7c7c80;
    margin-left: 10px;
    min-width: 80px;
  }

  ${media.medium} {
    & > p {
      display: none;
    }
  }
`

const Content = styled.div`
  min-height: 400px;
`

const TabArea = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 32px;

  & > button {
    border: 0;
    background: none;
    outline: none;
    cursor: pointer;

    width: 50%;
    border-bottom: solid 2px #e1e1e2;
    padding-bottom: 14px;
    font-weight: 500;
    font-size: 18px;
    line-height: 1.56;
    color: #b5b5b9;

    &.active {
      font-weight: 700;
      color: #0d0d0e;
      border-bottom: solid 2px #3b3b40;
    }
  }

  ${media.medium} {
    position: relative;
    left: -20px;
    width: calc(100% + 40px);

    & > button {
      border-bottom: solid 1px #e1e1e2;
      padding-bottom: 13px;
      font-size: 14px;
      line-height: 1.71;
      color: #b5b5b9;

      &.active {
        font-weight: 700;
        color: #0d0d0e;
        padding-bottom: 12px;
        border-bottom: solid 2px #3b3b40;
      }
    }
  }
`

const Wrapper = styled(AppRootWrapper)`
  & > h1 {
    font-weight: 700;
    font-size: 24px;
    line-height: 1.5;
    color: #0d0d0e;
    padding: 40px 0 32px;
  }

  ${media.medium} {
    & > h1 {
      display: none;
    }
  }
`
