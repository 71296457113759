import media from 'lib/styles/media'
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { ReactComponent as Icon } from '../../../assets/icons/tutor_register_show_icon.svg'
import Tooltip from '../Tooltip'

interface Props {
  type: string
  title: string
  data: number | undefined
  handleClick: (id: number) => void
  password?: string
  onChange?: (e: any) => void
}
function TabButton({
  type,
  title,
  data,
  handleClick,
  password,
  onChange,
}: Props) {
  const [popup, setPopup] = useState(false)
  const classType = [
    { id: 0, title: '단일 회차' },
    { id: 1, title: '다회차' },
  ]
  const publicType = [
    { id: 1, title: '공개' },
    { id: 0, title: '비공개' },
  ]
  const attendType = [
    { id: 1, title: '가능' },
    { id: 0, title: '불가능' },
  ]

  useEffect(() => {
    if (popup) {
      setTimeout(() => {
        setPopup(false)
      }, 3000)
    }
  }, [popup])

  const handleShowClick = () => {
    setPopup(true)
  }
  return (
    <Wrapper>
      <Title>
        <p>{title} </p>
        {type === 'public' && <Icon onClick={handleShowClick} />}
        {popup && (
          <Tooltip
            type="public"
            handleClose={() => {
              setPopup(false)
            }}
          />
        )}
      </Title>
      <Content>
        {type === 'multi' &&
          classType.map((item, i) => (
            <div
              key={`tutor_register_class_type_multi_${i}`}
              className={data === item.id ? 'active' : ''}
              onClick={() => handleClick(item.id)}
            >
              <p>{item.title}</p>
            </div>
          ))}
        {type === 'public' &&
          publicType.map((item, i) => (
            <div
              key={`tutor_register_class_type_public_${i}`}
              className={data === item.id ? 'active' : ''}
              onClick={() => handleClick(item.id)}
            >
              <p>{item.title}</p>
            </div>
          ))}
        {type === 'attend' &&
          attendType.map((item, i) => (
            <div
              key={`tutor_register_class_type_attend_${i}`}
              className={data === item.id ? 'active' : ''}
              onClick={() => handleClick(item.id)}
            >
              <p>{item.title}</p>
            </div>
          ))}
      </Content>
      {type === 'public' && data === 0 && (
        <input
          className="input_public"
          type="text"
          value={password ?? ''}
          onChange={onChange}
          maxLength={10}
          placeholder="강의 비밀번호를 입력해주세요 (최대 10자)"
        />
      )}
    </Wrapper>
  )
}

export default TabButton
const Wrapper = styled.div`
  width: 100%;
  margin-top: 40px;
  & > input.input_public {
    width: 100%;
    background: #ffffff;
    border: 1px solid #e1e1e2;
    border-radius: 8px;
    padding: 11px 14px;
    outline: none;
    margin-top: 20px;
    &::placeholder {
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      color: #b5b5b9;
    }
  }
`
const Title = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: fit-content;
  & > p {
    font-weight: 500;
    font-size: 17px;
    line-height: 26px;
    color: #0d0d0e;
    ${media.medium} {
      white-space: break-spaces;
      font-size: 16px;
    }
  }
  & svg {
    margin-left: 4px;
  }
`
const Content = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  & div {
    width: calc(50% - 5px);
    background: #ffffff;
    border: 1px solid #e1e1e2;
    border-radius: 50px;
    padding: 13px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    &.active {
      border: 1px solid #0d0d0e;
    }
  }
  & p {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #0d0d0e;
    cursor: pointer;
  }
`
