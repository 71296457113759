import { userState } from 'atoms/account'
import useIndex from 'lib/hooks/useIndex'
import { type UserInfo } from 'lib/types/account'
import React, { useEffect } from 'react'
import { useRecoilState } from 'recoil'
import PageLoadingComponent from './PageLoadingComponent'
import { PageTypeState } from 'atoms/common'

interface AuthRouteProps {
  component: ({ user }: { user?: UserInfo }) => JSX.Element
}

function AuthRoute({ component }: AuthRouteProps) {
  const { data, isLoading } = useIndex()
  const Component = component
  const role = window.location.search
  const [user, setUser] = useRecoilState(userState)
  const [pageType, setPageType] = useRecoilState(PageTypeState)

  useEffect(() => {
    if (role.includes('tutor')) {
      return setPageType('tutor')
    }
  }, [])

  useEffect(() => {
    if (!data) return
    setUser(data.data.user)
    // if (data.data.user.type === 'tutor') {
    //   setPageType('tutor')
    // } else {
    //   setPageType('user')
    // }
  }, [data])

  if (isLoading) return <PageLoadingComponent />
  return <Component user={data?.data.user} />
}

export default AuthRoute
