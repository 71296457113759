import api from 'lib/api'
import { getClassDetailRequest } from 'lib/api/class'
import useSWR from 'swr'

function useClassInfo(classId: string) {
  const { data, isLoading } = useSWR(
    [`/class/detail?class_id=${classId}`, classId],
    async () => {
      const response = await getClassDetailRequest(classId)
      if (!response.success) return

      return response.data.class
    },
  )

  return { data, isLoading }
}

export default useClassInfo
