import { AppRootWrapper } from 'components/layout/CommonStyle'
import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import LineButton from '../LineButton'
import media from 'lib/styles/media'

interface Props {
  data: {
    contents: string
    created_at: string
    id: number
    title: string
  }
}
function NoticeDetailComponent({ data }: Props) {
  const history = useHistory()
  return (
    <Wrapper>
      <DetailTitle>
        <p className="detail_title">공지사항</p>
        <div className="detail_content">
          <p className="detail_text">{data.title}</p>
          <p className="detail_date">{data.created_at}</p>
        </div>
      </DetailTitle>
      <Contents>
        <div className="contents">
          <p dangerouslySetInnerHTML={{ __html: data.contents }} />
        </div>
        <div className="button_box">
          <LineButton
            title="목록으로"
            onClick={() => {
              history.goBack()
            }}
            type="small"
          />
        </div>
      </Contents>
    </Wrapper>
  )
}

export default NoticeDetailComponent

const Wrapper = styled(AppRootWrapper)``
const DetailTitle = styled.div`
  & p.detail_title {
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: #0d0d0e;
    ${media.medium} {
      display: none;
    }
  }
  & div.detail_content {
    margin-top: 32px;
    padding-bottom: 32px;
    border-bottom: 1px solid #e1e1e2;
    ${media.medium} {
      margin-top: 0;
      padding-bottom: 22px;
    }
  }
  & p.detail_text {
    font-weight: 500;
    font-size: 22px;
    line-height: 34px;
    color: #0d0d0e;
    ${media.medium} {
      font-size: 16px;
      line-height: 26px;
    }
  }
  & p.detail_date {
    margin-top: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #7c7c80;
    ${media.medium} {
      margin-top: 6px;
      font-size: 13px;
      line-height: 22px;
    }
  }
`
const Contents = styled.div`
  margin-top: 32px;
  ${media.medium} {
    margin-top: 22px;
  }
  & div.contents {
    min-height: 600px;
    & > p {
      word-break: break-all;
      white-space: pre-line;
    }
    ${media.medium} {
      min-height: 400px;
    }
  }
  & div.button_box {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 40px 0 120px;
    ${media.medium} {
      margin: 30px 0 0;
    }
  }
`
