import { userState } from 'atoms/account'
import { type UserInfo } from 'lib/types/account'
import React from 'react'
import { useRecoilValue } from 'recoil'
import useIndex from '../useIndex'

function useUser() {
  const { data } = useIndex()

  return {
    user: data?.data.user as UserInfo,
  }
}

export default useUser
