import React from 'react'
import styled from 'styled-components'
import Content1 from '../../../assets/icons/tutor_content_image1.png'
import Content2 from '../../../assets/icons/tutor_content_image2.png'
import Content3 from '../../../assets/icons/tutor_content_image3.png'
import { ReactComponent as Icon1 } from '../../../assets/icons/tutor_content_icon1.svg'
import { ReactComponent as Icon2 } from '../../../assets/icons/tutor_content_icon2.svg'
import { ReactComponent as Icon3 } from '../../../assets/icons/tutor_content_icon3.svg'
import media from 'lib/styles/media'
import useScrollFadeIn from 'lib/hooks/useScrollFadeIn '

function TutorSecondContent() {
  const animated1: any = useScrollFadeIn({
    direction: 'up',
    duration: 1,
    delay: 0,
  })
  const animated2: any = useScrollFadeIn({
    direction: 'up',
    duration: 1,
    delay: 0,
  })
  const animated3: any = useScrollFadeIn({
    direction: 'up',
    duration: 1,
    delay: 0,
  })

  return (
    <Wrapper>
      <Content>
        <div className="second_text_box">
          <p className="second_title">
            실전 디베이팅과 흡사한 모습을
            <br />
            온라인 환경에서 구현했습니다!
          </p>
          <p className="second_content">
            강의 개설부터 진행, 학생 관리까지! 디베이트온을 통해 진행해 보세요!
          </p>
        </div>
        <div className="content_box" {...animated1}>
          <img src={Content1} alt="content_image" className="content_image1" />
          <div className="content_text_box">
            <Icon1 className="icon" />
            <p className="content_title">유연한 수업 진행</p>
            <p className="content_text">
              강의 개설이 자유롭고
              <br />
              수강생 지정, 비공개 강의도 가능하며
              <br />
              피드백 기능을 통해 학생 관리가 용이해요!
            </p>
          </div>
        </div>
        <div className="content_box2" {...animated2}>
          <div className="content_text_box2">
            <Icon2 className="icon" />
            <p className="content_title">디베이팅 맞춤형 라이브 수업 공간</p>
            <p className="content_text2">
              타이머, 작전회의, 찬반투표 등 <br />
              디베이팅에 최적화된 다양한 기능들을 제공해요!
            </p>
          </div>
          <img src={Content2} alt="content_image" className="content_image1" />
        </div>
        <div className="content_box" {...animated3}>
          <img src={Content3} alt="content_image" className="content_image1" />
          <div className="content_text_box">
            <Icon3 />
            <p className="content_title">검증된 수업 커리큘럼 활용까지</p>
            <p className="content_text">
              국내 디베이팅 전문 강사진이 제공하는
              <br />
              검증된 커리큘럼을 이용할 수 있어요!
            </p>
          </div>
        </div>
      </Content>
    </Wrapper>
  )
}

export default TutorSecondContent
const Wrapper = styled.div`
  width: 100%;
  height: 1888px;
  background: #faf9fb;
  ${media.medium} {
    height: 100%;
  }
`
const Content = styled.div`
  width: 1120px;
  margin: 0 auto;
  padding: 120px 0 130px;
  ${media.medium} {
    width: 100%;
    padding: 52px 0;
  }
  & div.second_text_box {
    ${media.medium} {
      display: flex;
      flex-direction: column-reverse;
      padding: 0 20px;
    }
  }
  & p.second_title {
    font-weight: 700;
    font-size: 28px;
    line-height: 45px;
    text-align: center;
    color: #0d0d0e;
    margin-bottom: 14px;
    ${media.medium} {
      font-size: 18px;
      line-height: 28px;
      margin-bottom: 0;
    }
  }
  & p.second_content {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #57569e;
    ${media.medium} {
      font-size: 13px;
      line-height: 22px;
      margin-bottom: 10px;
    }
  }
  & div.content_box {
    display: flex;
    margin-top: 120px;
    ${media.medium} {
      margin-top: 60px;
      flex-direction: column-reverse;
    }
  }
  & div.content_box2 {
    display: flex;
    margin-top: 120px;
    justify-content: end;
    ${media.medium} {
      margin-top: 60px;
      flex-direction: column;
    }
  }
  & img.content_image1 {
    width: 680px;
    ${media.medium} {
      width: 100%;
      margin-top: 32px;
    }
  }
  & div.content_text_box {
    min-width: 400px;
    margin-top: 50px;
    ${media.medium} {
      min-width: 100%;
      margin-top: 0;
      padding: 0 20px;
    }
  }
  & svg.icon {
    ${media.medium} {
      width: 26px;
      height: 26px;
    }
  }
  & div.content_text_box2 {
    min-width: 400px;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: end;
    ${media.medium} {
      min-width: 100%;
      margin-top: 0;
      align-items: flex-start;
      padding: 0 20px;
    }
  }
  & p.content_title {
    font-weight: 700;
    font-size: 22px;
    line-height: 34px;
    color: #3b3b40;
    margin: 16px 0;
    ${media.medium} {
      font-size: 16px;
      line-height: 26px;
      margin: 8px 0;
    }
  }
  & p.content_text {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: #a1a1af;
    ${media.medium} {
      font-size: 14px;
      line-height: 24px;
    }
  }
  & p.content_text2 {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: #a1a1af;
    text-align: end;
    ${media.medium} {
      font-size: 14px;
      line-height: 24px;
      text-align: start;
    }
  }
`
