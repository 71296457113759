import React from 'react'
import styled from 'styled-components'
import CheckBox from '../CheckBox'
import media from 'lib/styles/media'

interface Props {
  text?: string
  required?: boolean
  checked: boolean
  onChange: React.ChangeEventHandler<HTMLInputElement>
  handleTermsPopup?: () => void
  show?: boolean
  isAll?: boolean
  id: string
}
function TermsCheckComponent({
  text,
  required,
  checked,
  onChange,
  handleTermsPopup,
  show,
  isAll,
  id,
}: Props) {
  return (
    <Wrapper>
      {isAll ? (
        <div className="all_check_box">
          <CheckBox checked={checked} onChange={onChange} AllCheck id={id} />
          <p>{text}</p>
        </div>
      ) : (
        <div className="check_box">
          <CheckBox checked={checked} onChange={onChange} id={id} />
          <p className="check_terms_text">
            <span className="check_required_text">
              {required ? '(필수) ' : '(선택) '}
            </span>
            {text}
          </p>
          {show && (
            <p className="check_show_terms" onClick={handleTermsPopup}>
              보기
            </p>
          )}
        </div>
      )}
    </Wrapper>
  )
}

export default TermsCheckComponent

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  & div.all_check_box {
    display: flex;
    align-items: center;
    margin-bottom: 22px;
    & > p {
      font-weight: 700;
      font-size: 16px;
      line-height: 26px;
      color: #3b3b40;
      margin-left: 12px;
      ${media.medium} {
        font-size: 14px;
        line-height: 24px;
        margin-left: 10px;
      }
    }
  }
  & div.check_box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  & p.check_terms_text {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #3b3b40;
    flex: 1;
    margin-left: 12px;
  }
  & span.check_required_text {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #b5b5b9;
  }
  & p.check_show_terms {
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    text-align: right;
    text-decoration-line: underline;
    color: #7574d8;
    cursor: pointer;
  }
`
