import React from 'react'
import styled from 'styled-components'
import { ReactComponent as LeftArrow } from 'assets/icons/left_arrow.svg'
import { ReactComponent as LeftArrowMobile } from 'assets/icons/left_arrow_mobile.svg'
import media from 'lib/styles/media'

interface TeamRoomHeaderProps {
  title: string
  onClickArrow?: () => void
}

function TeamRoomHeader({ title, onClickArrow }: TeamRoomHeaderProps) {
  return (
    <RoomHeaderWrapper>
      <div className="left">
        <div className="left-arrow" onClick={onClickArrow}>
          <LeftArrow className="mobile-hidden" />
          <LeftArrowMobile className="pc-hidden" />
        </div>

        <div className="lecture-title">{title}</div>
      </div>
    </RoomHeaderWrapper>
  )
}

export const RoomHeaderWrapper = styled.div`
  padding: 14px 20px;
  background: rgba(13, 13, 14, 1);

  display: flex;
  justify-content: space-between;
  align-items: center;

  color: #fff;

  ${media.medium} {
    height: 58px;
  }

  & .left {
    display: flex;
    align-items: center;
  }

  & .left-arrow {
    cursor: pointer;
    ${media.pc} {
      width: 44px;
      height: 44px;

      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(59, 59, 64, 0.8);
      border-radius: 8px;
    }
  }

  & .lecture-title {
    height: 100%;

    color: #eeeff1;

    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: keep-all;

    ${media.pc} {
      font-weight: 500;
      font-size: 22px;
      line-height: 22px;

      margin-left: 14px;
    }

    ${media.medium} {
      font-weight: 500;
      font-size: 14px;
      margin-left: 4px;
    }
  }
`

export default TeamRoomHeader
