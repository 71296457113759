import media from 'lib/styles/media'
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import InputFile from '../InputFile'
import type { FileType } from 'lib/types/account'

interface Props {
  title: string
  onChange: (files: FileType[], id?: string | number) => void
  type: 'edit' | 'add'
  files: FileType[]
}

function ClassImageComponent({ title, onChange, type, files }: Props) {
  useEffect(() => {
    onChange(files)
  }, [files])

  const handleChangeFiles = (newFiles: FileType[]) => {
    if (!newFiles || newFiles.length === 0) return
    onChange(files.concat(newFiles))
  }

  const handleCloseClick = (id: number | string) => {
    const filter = files.filter((file) => file.id !== id)
    onChange(filter, id)
  }

  return (
    <Wrapper>
      <Title>
        <p>{title}</p>
      </Title>
      <Content>
        <InputFile
          onChange={handleChangeFiles}
          length={files.length}
          imageSize="lg"
          files={files}
          onCloseClick={handleCloseClick}
        />
      </Content>
    </Wrapper>
  )
}

export default ClassImageComponent
const Wrapper = styled.div`
  margin-top: 40px;
  ${media.medium} {
    margin-top: 20px;
  }
`
const Title = styled.div`
  & > p {
    font-weight: 500;
    font-size: 17px;
    line-height: 26px;
    color: #0d0d0e;
    ${media.medium} {
      font-size: 16px;
    }
  }
`
const Content = styled.div`
  margin-top: 10px;
`
