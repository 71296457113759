import { convertTimer, initialTimer } from 'lib/utils'
import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'

interface TimerProps {
  onExit?: (timer: number) => void
  date?: Date | string | null
}

function Timer({ onExit, date }: TimerProps) {
  const [timer, setTimer] = useState(initialTimer(date as any))

  useEffect(() => {
    if (timer <= 0) {
      onExit && onExit(timer)
      return
    }

    const interval = setInterval(() => {
      setTimer((timer) => timer - 1)
    }, 1000)

    return () => clearInterval(interval)
  }, [timer])

  return (
    <Wrapper className="timer" isEmergency={timer <= 30}>
      {convertTimer(timer)}
    </Wrapper>
  )
}

const Wrapper = styled.div<{ isEmergency: boolean }>`
  ${(props) =>
    props.isEmergency &&
    css`
      color: rgba(255, 44, 44, 1) !important;
    `}
`

export default Timer
