import useUser from 'lib/hooks/account/useUser'
import React, { useEffect, useRef } from 'react'

interface AudioProps extends React.HTMLProps<HTMLVideoElement> {
  stream?: MediaStream
  userId: number
}

function Audio(props: AudioProps) {
  const ref = useRef<any>(null)
  const { stream, muted, userId, ...rest } = props

  const { user } = useUser()

  useEffect(() => {
    if (!ref.current) return
    ref.current['srcObject'] = stream
  }, [stream])

  return (
    <audio
      ref={ref}
      muted={user.id === userId || muted}
      autoPlay
      {...rest}
    ></audio>
  )
}

export default Audio
