import Footer from 'components/common/Footer'
import MobileTitleHeader from 'components/common/header/MobileTitleHeader'
import TutorHeader from 'components/common/header/TutorHeader'
import TutorInfoComponent from 'components/common/joinInfo/TutorInfoComponent'
import media from 'lib/styles/media'
import React from 'react'
import styled from 'styled-components'

function TutorJoininfoBasic() {
  return (
    <Wrapper>
      <TutorHeader />
      <MobileTitleHeader type="arrow" title="튜터 정보 설정" />
      <Contents>
        <TutorInfoComponent />
      </Contents>
      <Footer />
    </Wrapper>
  )
}

export default TutorJoininfoBasic
const Wrapper = styled.div``
const Contents = styled.div`
  margin-top: 100px;
  ${media.medium} {
    margin-top: 0;
  }
`
