import React, { useMemo, useState } from 'react'
import styled, { css } from 'styled-components'
import ModalWrapper from './ModalWrapper'
import { ReactComponent as CloseIcon } from 'assets/icons/close_icon.svg'
import { useRecoilState } from 'recoil'
import { timeSettingModalState } from 'atoms/modal'
import Button from 'components/common/Button'
import { SELECT_TIME_VARIABLES } from 'lib/utils/variables'

function TimeSettingModal() {
  const [modal, setModal] = useRecoilState(timeSettingModalState)

  const [timer, setTimer] = useState({
    minutes: 0,
    seconds: 0,
  })

  const result = useMemo(() => {
    return +(timer.minutes * 60) + +timer.seconds
  }, [timer])

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    if (e.target.value.length > 2) return
    setTimer((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }))
  }

  const handleSelect = (value: number) => () => {
    const minutes = Math.floor(value / 60)
    const seconds = Math.floor(value % 60)
    setTimer({
      minutes,
      seconds,
    })
  }

  const handleClose = () => {
    setModal(null)
  }

  const handleSubmit = () => {
    modal?.onSetting(result)
    handleClose()
  }

  const isValid = useMemo(() => {
    return timer.minutes > 0 || timer.seconds > 0
  }, [timer])

  return (
    <ModalWrapper onClose={handleClose}>
      <Wrapper width={modal?.width ?? 'md'}>
        <div className="header">
          <h3 className="title">{modal?.title}</h3>
          <CloseIcon className="close-icon" onClick={handleClose} />
        </div>
        <p className="description">{modal?.text}</p>
        <div className="content">
          <div className="time-wrap">
            <input
              type="number"
              name="minutes"
              onChange={handleChange}
              value={timer.minutes}
            />
            <span className="time-label">분</span>
          </div>
          <div className="time-wrap">
            <input
              type="number"
              name="seconds"
              onChange={handleChange}
              value={timer.seconds}
            />
            <span className="time-label">초</span>
          </div>
        </div>

        {modal?.isSelectButton && (
          <div className="seconds-buttons-area">
            {Object.entries(SELECT_TIME_VARIABLES).map(([value, label]) => (
              <div
                className={`seconds-button ${
                  parseInt(value) === result ? 'active' : ''
                }`}
                key={value}
                onClick={handleSelect(parseInt(value))}
              >
                {label}
              </div>
            ))}
          </div>
        )}

        <div className="footer">
          <Button title="설정" disabled={!isValid} onClick={handleSubmit} />
        </div>
      </Wrapper>
    </ModalWrapper>
  )
}

const Wrapper = styled.div<{ width: 'sm' | 'md' | 'lg' }>`
  border-radius: 14px;
  background: #fff;

  ${(props) =>
    props.width === 'sm' &&
    css`
      width: 320px;
    `}

  ${(props) =>
    props.width === 'md' &&
    css`
      width: 380px;
    `}

  ${(props) =>
    props.width === 'lg' &&
    css`
      width: 380px;
    `}

  & .header {
    padding: 26px 26px 10px;

    display: flex;
    justify-content: space-between;

    & .title {
      font-weight: 700;
      font-size: 18px;
      color: #000000;
    }

    & .close-icon {
      cursor: pointer;
    }
  }

  & .description {
    padding: 0 20px;
    font-weight: 400;
    font-size: 14px;
    color: #3b3b40;
    height: 24px;
  }

  & .content {
    padding: 40px 0;

    display: flex;
    align-items: center;
    justify-content: center;

    gap: 30px;
  }

  & .time-wrap {
    display: flex;
    align-items: center;
    gap: 8px;

    & input {
      width: 60px;
      height: 40px;
      border: 1px solid #eeeff1;
      border-radius: 8px;

      font-weight: 700;
      font-size: 14px;
      color: #0d0d0e;

      outline: none;
      text-align: center;
    }

    & .time-label {
      font-weight: 400;
      font-size: 14px;
      color: #0d0d0e;
    }
  }

  & .seconds-buttons-area {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
    padding: 0 26px 20px 26px;
  }

  & .seconds-button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    border: 1px solid #e1e1e2;
    border-radius: 4px;
    background: #ffffff;
    cursor: pointer;

    color: #0d0d0e;
    font-weight: 500;
    font-size: 14px;
  }

  & .active {
    border: 1px solid #7c7c80;
  }

  & .footer {
    padding: 10px 26px 26px 26px;
  }
`

export default TimeSettingModal
