import media from 'lib/styles/media'
import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'
import DebateMembers from './DebateMembers'
import FocusMember from '../common/FocusMember'
import RoomTitle from '../common/RoomTitle'
import TeamDebateButton from './TeamDebateButton'
import TutorRoomInfo from './TutorRoomInfo'
import { useRoomInfoValue } from '../provider/RoomInfoProvider'
import { type RoomSeatInfoType } from 'lib/types/room'
import SubFocusMember from '../common/SubFocusMember'
import useUser from 'lib/hooks/account/useUser'

function RoomContent() {
  const { focusUserRoomInfo, tutorRoomInfo } = useRoomInfoValue()

  const { user } = useUser()

  const focusCamera = useMemo(() => {
    if (focusUserRoomInfo) {
      return focusUserRoomInfo.is_camera === 1
    }

    return tutorRoomInfo?.is_camera === 1
  }, [focusUserRoomInfo, tutorRoomInfo])

  const focusMike = useMemo(() => {
    if (focusUserRoomInfo) return false
    if (tutorRoomInfo?.id === user.id) return false
    if (tutorRoomInfo?.team) return false
    if (!tutorRoomInfo?.stream) return false
    return tutorRoomInfo?.is_mike === 1
  }, [focusUserRoomInfo, tutorRoomInfo, user])

  return (
    <Wrapper isFocusUser={!!focusUserRoomInfo}>
      <RoomTitle />
      <div className="focus-wrapper">
        <FocusMember
          info={focusUserRoomInfo ?? (tutorRoomInfo as RoomSeatInfoType)}
          isCamera={focusCamera}
          isMike={focusMike}
        />
        {focusUserRoomInfo && (
          <SubFocusMember
            info={tutorRoomInfo as RoomSeatInfoType}
            isMobile
            isCamera={tutorRoomInfo?.is_camera === 1}
            isMike={tutorRoomInfo?.is_mike === 1}
          />
        )}
      </div>
      <DebateMembers />
      <div className="mobile-hidden button-wrap">
        {focusUserRoomInfo && (
          <SubFocusMember
            info={tutorRoomInfo as RoomSeatInfoType}
            isCamera={tutorRoomInfo?.is_camera === 1}
            isMike={tutorRoomInfo?.is_mike === 1}
          />
        )}
        <TeamDebateButton />
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div<{ isFocusUser: boolean }>`
  position: relative;
  overflow-y: scroll;
  background: #242427;
  flex: 1;

  display: flex;
  flex-direction: column;

  ${media.pc} {
    padding: 0 30px;
  }

  & .button-wrap {
    position: absolute;
    bottom: 30px;
    left: 0;

    width: 50.27%;

    left: 50%;
    transform: translateX(-50%);

    display: flex;

    justify-content: center;

    ${(props) =>
      props.isFocusUser &&
      css`
        align-items: flex-end;
        justify-content: space-between;
      `}
  }
`

export default RoomContent
