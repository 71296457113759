import axios from 'axios'
import auth from 'lib/utils/auth'
import { useHistory } from 'react-router-dom'

export const API_END_POINT = process.env.REACT_APP_API_END_POINT

const api = axios.create({
  baseURL: API_END_POINT,
  headers: {
    Authorization: `Bearer ${
      localStorage.getItem('token') ? localStorage.getItem('token') : ''
    }`,
  },
})

api.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    if (error.response && error.response.status === 401) {
      try {
        if (
          (auth.getRefreshToken() && auth.getRefreshToken() !== 'undefined') ??
          (auth.getSaveRefreshToken() &&
            auth.getSaveRefreshToken() !== 'undefined')
        ) {
          const { data } = await api.post('/refresh/token', {
            refresh_token: auth.getRefreshToken()
              ? auth.getRefreshToken()
              : auth.getSaveRefreshToken(),
          })

          if (data.success) {
            auth.setToken({
              token: data?.data?.access_token,
              refreshToken: data?.data?.refresh_token,
            })

            if (auth.getSaveRefreshToken())
              auth.setSaveRefreshToken(data.data.refresh_token)

            if (auth.getToken() === data.data.access_token) {
              api.defaults.headers.common['Authorization'] =
                data.data.access_token
              return api.request(error.config)
            }
          } else {
            auth.clearAppStorage()
            window.location.href = '/'
          }
        } else {
          if (auth.getToken()) {
            auth.clearToken()
          }
        }
      } catch (err) {
        return null
      }
    }

    return Promise.reject(error)
  },
)
export default api
