import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { ReactComponent as PasswordIcon } from '../../assets/icons/password_icon.svg'
import { ReactComponent as PasswordShowIcon } from '../../assets/icons/password_show_icon.svg'

interface Props {
  title: string
  placeholder: string
  value: string | undefined
  onChange: (e: any) => void
  onBlur?: () => void
  maxLength?: number
  error?: boolean
  errorPwMessage?: string
  onKeyUp?: () => void
}
function PasswordInput({
  title,
  placeholder,
  value,
  onChange,
  onBlur,
  maxLength,
  error,
  errorPwMessage,
  onKeyUp,
}: Props) {
  const [showPw, setShowPw] = useState(false)

  const handleShowPwClick = () => {
    if (showPw) {
      setShowPw(false)
    } else {
      setShowPw(true)
    }
  }

  return (
    <Wrapper>
      <InputContent>
        <p className="input_title">{title}</p>
        <input
          className={error ? 'error' : ''}
          type={showPw ? 'text' : 'password'}
          placeholder={placeholder}
          autoComplete="off"
          onBlur={onBlur}
          value={value ?? ''}
          onChange={onChange}
          maxLength={maxLength}
          onKeyUp={onKeyUp}
        />
        {showPw ? (
          <PasswordShowIcon
            className="show_icon no"
            onClick={handleShowPwClick}
          />
        ) : (
          <PasswordIcon className="show_icon" onClick={handleShowPwClick} />
        )}
      </InputContent>
      {error && errorPwMessage && (
        <p className="error_message">{errorPwMessage}</p>
      )}
    </Wrapper>
  )
}

export default PasswordInput

const Wrapper = styled.div`
  & p.error_message {
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    color: #ff2c2c;
    margin-top: 4px;
    white-space: break-spaces;
  }
  & p.success_message {
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    color: #2c80ff;
    margin-top: 4px;
  }
`
const InputContent = styled.div`
  position: relative;
  & p.input_title {
    font-weight: 700;
    font-size: 13px;
    line-height: 22px;
    color: #0d0d0e;
    margin-bottom: 4px;
  }
  & input {
    width: 100%;
    border: none;
    border-bottom: 1px solid #e1e1e2;
    outline: none;
    padding: 7px 30px 7px 0;

    &::placeholder {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #b5b5b9;
    }

    &.focus {
      border-bottom: 1px solid #3b3b40;
    }
    &.error {
      border-bottom: 1px solid #ff2c2c;
    }
    &.success {
      border-bottom: 1px solid #2c80ff;
    }
  }
  & input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px #fff inset;
  }
  & .show_icon {
    position: absolute;
    right: 5px;
    bottom: 10px;
    &.no {
      bottom: 7px;
    }
  }
  & button.cross_check_content {
    width: 70px;
    height: 30px;
    background: #ffffff;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    bottom: 4px;
    font-weight: 400;
    font-size: 13px;
    line-height: 22px;
    border: 1px solid #0d0d0e;
    color: #0d0d0e;

    &:disabled {
      border: 1px solid #e1e1e2;
      color: #b5b5b9;
    }
  }
`
