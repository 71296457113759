import type { IngClassDataProps } from 'lib/types/account'
import React from 'react'
import styled from 'styled-components'
import IngClassInfoComponent from '../IngClassInfoComponent'
import { ReactComponent as PreIcon } from '../../../assets/icons/gray_pre_icon.svg'
import { ReactComponent as NextIcon } from '../../../assets/icons/gray_next_icon.svg'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Keyboard, Scrollbar, Navigation, Pagination } from 'swiper'
import { AppRootWrapper } from 'components/layout/CommonStyle'
import media from 'lib/styles/media'
SwiperCore.use([Scrollbar, Navigation])

function IngClassComponent({ data }: IngClassDataProps) {
  return (
    <Wrapper>
      {data.length === 1 ? (
        <p className="main_ing_title">곧 시작하는 디베이팅</p>
      ) : (
        <p className="main_ing_title">
          지금 진행 중인 디베이팅 <span className="point">{data.length}</span>건
        </p>
      )}

      <p className="notice_ing_title">방청 허용된 강의만 노출됩니다.</p>
      <Contents
        scrollbar={true}
        navigation={{
          prevEl: '#pre_button',
          nextEl: '#next_button',
        }}
        spaceBetween={20}
        pagination={true}
        slidesPerView={'auto'}
        modules={[Scrollbar, Navigation, Pagination]}
        className="mySwiper"
      >
        {data.map((item, i) => (
          <SwiperSlide key={`main_ing_class_list_${i}`}>
            <IngClassInfoComponent
              Image={item.image_url}
              Profile={item.profile_url}
              Title={item.title}
              Nickname={item.nickname}
              Score={item.tutor_score}
              Category={item.categories}
              Id={item.id}
              tutorId={item.tutor_id}
            />
          </SwiperSlide>
        ))}
      </Contents>
      <PreIcon id="pre_button" />
      <NextIcon id="next_button" />
    </Wrapper>
  )
}
export default IngClassComponent

const Wrapper = styled(AppRootWrapper)`
  margin-top: 100px;
  position: relative;
  ${media.medium} {
    margin-top: 50px;
  }
  & .main_ing_title {
    font-weight: 700;
    font-size: 22px;
    line-height: 34px;
    color: #0d0d0e;
    ${media.medium} {
      font-size: 16px;
      line-height: 26px;
    }
  }
  & .point {
    color: #7574d8;
  }
  & .notice_ing_title {
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: #a1a1af;
    margin-top: 6px;
    ${media.medium} {
      font-size: 13px;
      margin-top: 2px;
      line-height: 22px;
    }
  }
  & #pre_button {
    position: absolute;
    left: 0px;
    bottom: -7px;
    z-index: 5;
    ${media.medium} {
      display: none;
    }
  }
  & #next_button {
    position: absolute;
    right: 0px;
    bottom: -7px;
    z-index: 5;
    ${media.medium} {
      display: none;
    }
  }
`
const Contents = styled(Swiper)`
  margin-top: 32px;
  display: flex;
  align-items: flex-start;
  position: relative;
  ${media.medium} {
    margin-top: 18px;
  }
  & .swiper-wrapper {
    display: flex;
    align-items: flex-start;
    padding-bottom: 45px;
    ${media.medium} {
      padding-bottom: 28px;
    }
  }
  & .swiper-slide {
    max-width: 410px;
    width: 410px !important;
    height: 358px;
    ${media.medium} {
      max-width: 270px;
      width: 270px !important;
      height: 260px;
    }
  }
  & .swiper-scrollbar {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    width: 1100px;
    height: 6px;
    background: #eeeff1;
    border-radius: 100px;
    ${media.medium} {
      width: 100%;
    }
    & .swiper-scrollbar-drag {
      width: 420px;
      height: 6px;
      background: #7574d8;
      border-radius: 100px;
    }
  }
  & .swiper-scrollbar-lock {
    display: flex !important;
  }
`
