import api from '.'

export async function uploadFileMutation({
  file,
  fileName,
  roomId,
  step,
}: {
  roomId: string | number
  step: number
  file: File
  fileName: string
}) {
  const formData = new FormData()

  formData.append('class_id', String(roomId))
  formData.append('step', String(step))
  formData.append('file', file)
  formData.append('file_name', fileName)

  const { data } = await api.post('/class/debate/file/register', formData)

  return data
}

export async function deleteFileMutation({
  roomId,
  fileId,
}: {
  roomId: string
  fileId: string
}) {
  const { data } = await api.delete('/class/debate/file/delete', {
    params: {
      class_id: roomId,
      file_id: fileId,
    },
  })

  return data
}
