import type { UserInfo } from 'lib/types/account'
import React, { useEffect } from 'react'
import StudentNotice from './StudentNotice'
import TutorNotice from './TutorNotice'
import { useRecoilState } from 'recoil'
import { PageTypeState } from 'atoms/common'

interface NoticePageProps {
  user?: UserInfo
}

function NoticePage({ user }: NoticePageProps) {
  const [pageType, setPageType] = useRecoilState(PageTypeState)

  if (pageType === 'tutor') return <TutorNotice />
  return <StudentNotice />
}

export default NoticePage
