import media from 'lib/styles/media'
import React from 'react'
import styled from 'styled-components'

interface Props {
  title: string
  date: string
  onClick: () => void
}
function NoticeListComponent({ title, date, onClick }: Props) {
  return (
    <Wrapper onClick={onClick}>
      <p className="list_title">{title}</p>
      <p className="list_date">{date}</p>
    </Wrapper>
  )
}

export default NoticeListComponent

const Wrapper = styled.div`
  padding: 18px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  ${media.medium} {
    padding: 10px 20px;
  }
  & p.list_title {
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: #0d0d0e;
    ${media.medium} {
      font-size: 14px;
      line-height: 24px;
    }
  }
  & p.list_date {
    font-weight: 400;
    font-size: 13px;
    line-height: 22px;
    text-align: right;
    color: #7c7c80;
    ${media.medium} {
      font-size: 12px;
    }
  }
  &:hover {
    background: #f9f9fb;
    border-radius: 8px;
  }
`
