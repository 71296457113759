import { AppRootWrapper } from 'components/layout/CommonStyle'
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Button from '../Button'
import Input from '../Input'
import { Switch } from 'antd'
import { ReactComponent as MainLogo } from '../../../assets/icons/main_logo.svg'
import api from 'lib/api'
import { useHistory } from 'react-router-dom'
import media from 'lib/styles/media'
import auth from 'lib/utils/auth'
import PasswordInput from '../PasswordInput'
import LineButton from '../LineButton'
import { useRecoilState } from 'recoil'
import { PageTypeState } from 'atoms/common'
import CheckPopup from '../CheckPopup'

function TutorIdLoginComponent() {
  const [checked, setChecked] = useState(false)
  const [id, setId] = useState('')
  const [pw, setPw] = useState('')
  const [error, setError] = useState(false)
  const history = useHistory()
  const [pageType, setPageType] = useRecoilState(PageTypeState)
  const [popup, setPopup] = useState(false)
  const [content, setContent] = useState('')

  useEffect(() => {
    if (popup) {
      document.documentElement.style.overflow = 'hidden'
    } else {
      document.documentElement.style.overflow = 'auto'
    }

    return () => {
      document.documentElement.style.overflow = 'auto'
    }
  }, [popup])

  const handleChange = (checked: boolean) => {
    if (!checked) {
      setChecked(false)
    } else {
      setChecked(true)
    }
  }

  const handleSubmit = async () => {
    const formData = new FormData()
    formData.append('email', id)
    formData.append('password', pw)
    formData.append('type', 'tutor')

    const {
      data: { success, alert, data },
    } = await api.post('/member/login', formData)

    if (success) {
      auth.setToken({
        token: data?.token?.access_token,
        refreshToken: data?.token?.refresh_token,
      })
      if (checked) auth.setSaveRefreshToken(data?.token?.refresh_token)
      setPageType('tutor')
      window.location.href = '/?role=tutor'
    } else {
      if (alert.includes('강제 탈퇴')) {
        setPopup(true)
        setContent(alert)
      } else {
        setError(true)
      }
    }
  }

  return (
    <Wrapper>
      <Content>
        <MainLogo className="logo" />
        <div className="input_box">
          <Input
            type="text"
            title="튜터 아이디"
            placeholder="아이디를 입력하세요"
            value={id}
            onChange={(e: any) => {
              setId(e.target.value)
            }}
            maxLength={20}
          />
          <PasswordInput
            title="비밀번호"
            placeholder="비밀번호를 입력하세요"
            value={pw}
            onChange={(e: any) => {
              setPw(e.target.value)
            }}
            maxLength={20}
          />
          {error && (
            <div className="error_content">
              <p className="error_text">
                아이디 또는 비밀번호가 일치하지 않습니다.
                <br />
                입력하신 내용을 다시 확인해주세요.
              </p>
            </div>
          )}
        </div>

        <div className="button_box">
          <Button
            title="로그인"
            onClick={() => {
              handleSubmit()
            }}
            className="login"
            disabled={id.length < 8 || pw.length < 8}
          />
        </div>
        <div className="text_box">
          <div className="auto_button_box">
            <Switch
              checked={checked}
              onChange={handleChange}
              className="switch_button"
            />
            <p>자동 로그인</p>
          </div>
          <div className="find_text">
            <p onClick={() => history.push('/find/id?role=tutor')}>
              아이디 찾기
            </p>
            <span className="span_bar" />
            <p onClick={() => history.push('/find/password?role=tutor')}>
              비밀번호 찾기
            </p>
          </div>
        </div>
        <div className="title_box">
          <span className="bar" />
          <p>지금 바로 디베이트온의 튜터가 되어보세요!</p>
          <span className="bar" />
        </div>
        <div className="login_box">
          <LineButton
            title="튜터 회원가입"
            type="gray"
            onClick={() => {
              history.push('/join?role=tutor')
            }}
          />
        </div>
        <p
          className="look_text"
          onClick={() => {
            history.push('/?role=tutor')
          }}
        >
          둘러보기
        </p>
      </Content>
      {popup && (
        <CheckPopup
          onClick={() => setPopup(false)}
          title="강제 탈퇴 회원"
          content={content}
        />
      )}
    </Wrapper>
  )
}

export default TutorIdLoginComponent

const Wrapper = styled(AppRootWrapper)``
const Content = styled.div`
  width: 440px;
  height: 784px;
  margin: 0 auto;
  padding: 80px 0 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${media.medium} {
    width: 100%;
    height: 100%;
    padding: 40px 0 80px;
  }

  & svg.logo {
    min-width: 146px;
    min-height: 50px;
    ${media.medium} {
      min-width: 116px;
      min-height: 40px;
    }
  }
  & div.input_box {
    width: 380px;
    margin-top: 40px;
    ${media.medium} {
      width: 320px;
    }
    & > div:first-of-type {
      margin-bottom: 20px;
    }
    & div.error_content {
      margin-top: 30px;
      & p.error_text {
        font-weight: 400;
        font-size: 13px;
        line-height: 22px;
        color: #ff2c2c;
      }
    }
  }
  & div.button_box {
    width: 380px;
    margin-top: 40px;
    ${media.medium} {
      width: 320px;
    }
  }
  & div.text_box {
    width: 380px;
    margin-top: 22px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    ${media.medium} {
      width: 320px;
    }
    & div {
      display: flex;
      align-items: center;
    }
    & p {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: #3b3b40;
      cursor: pointer;
    }
    & .switch_button {
      margin-right: 10px;
    }
    & span.span_bar {
      width: 1px;
      height: 12px;
      display: block;
      background: #e1e1e2;
      margin: 0 20px;
      ${media.medium} {
        margin: 0 14px;
      }
    }
  }
  & .ant-switch.ant-switch-checked {
    background: #7574d8;
  }
  & .ant-switch.ant-switch-checked:hover {
    background: #7574d8;
  }
  & .ant-switch {
    background: #e1e1e2;
  }
  & .ant-switch:hover {
    background: #e1e1e2;
  }
  & .ant-switch {
    ${media.medium} {
      min-width: 34px;
      min-height: 20px;
      height: 20px;
    }
  }
  & .ant-switch-handle {
    ${media.medium} {
      width: 14px;
      height: 14px;
      top: 3px;
    }
  }
  & div.title_box {
    width: 380px;
    margin-top: 50px;
    display: flex;
    align-items: center;
    ${media.medium} {
      width: 100%;
    }
    & span.bar {
      width: 54px;
      height: 1px;
      background: #eeeff1;
      ${media.medium} {
        width: 42px;
      }
    }
    & > p {
      font-size: 13px;
      line-height: 22px;
      text-align: center;
      color: #7c7c80;
      margin: 0 20px;
      ${media.medium} {
        margin: 0 10px;
      }
    }
  }
  & div.login_box {
    width: 380px;
    margin-top: 22px;
    ${media.medium} {
      width: 100%;
      & > button {
        margin-top: 0;
      }
    }
  }
  & p.look_text {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    text-decoration-line: underline;
    color: #3b3b40;
    margin-top: 40px;
    cursor: pointer;
  }
`
