import React from 'react'
import styled from 'styled-components'
import Button from './Button'
import media from 'lib/styles/media'

interface Props {
  onClick: () => void
  title: string
  content: any
  oncancelClick: () => void
  btn: string
}
function TwoCheckPopup({ onClick, title, content, oncancelClick, btn }: Props) {
  return (
    <Wrapper>
      <Content>
        <p className="title">{title}</p>
        <p className="content" dangerouslySetInnerHTML={{ __html: content }} />
        <ButtonBox>
          <p onClick={oncancelClick}>아니오</p>
          <Button onClick={onClick} title={btn} />
        </ButtonBox>
      </Content>
    </Wrapper>
  )
}

export default TwoCheckPopup

const Wrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  z-index: 110;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
`
const Content = styled.div`
  min-width: 320px;
  min-height: 180px;
  background: #fff;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 14px;
  border: 1px solid #e1e1e2;
  padding: 22px;
  & .title {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: #0d0d0e;
  }
  & .content {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #3b3b40;
    margin-top: 14px;
    white-space: break-spaces;
  }
`
const ButtonBox = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: end;
  align-items: baseline;
  & > button {
    width: 82px;
    height: 34px;
    border-radius: 100px;
  }
  & > p {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-align: right;
    color: #7c7c80;
    margin-right: 16px;
    cursor: pointer;
  }
`
