import { AppRootWrapper } from 'components/layout/CommonStyle'
import type { AlarmDataProps } from 'lib/types/account'
import React from 'react'
import styled from 'styled-components'
import NoneData from '../NoneData'
import AlarmListComponent from './AlarmListComponent'
import media from 'lib/styles/media'

function AlarmComponent({ data, target }: AlarmDataProps) {
  return (
    <Wrapper>
      <AlarmTitle>
        <p className="alarm_title">알림</p>
      </AlarmTitle>
      <Content className={data.length > 0 ? 'border' : ''}>
        {data.length > 0 ? (
          <AlarmContent>
            {data.map((item, i) => (
              <AlarmListComponent
                key={`alarm_list_${i}`}
                date={item.created_at}
                title={item.message}
                status={item.status}
              />
            ))}
            <div className="target" ref={target} />
          </AlarmContent>
        ) : (
          <NoneData title="알림 내역이" />
        )}
      </Content>
    </Wrapper>
  )
}

export default AlarmComponent
const Wrapper = styled(AppRootWrapper)`
  min-height: 600px;
  ${media.medium} {
    min-height: 400px;
    width: 100%;
  }
`
const AlarmTitle = styled.div`
  ${media.medium} {
    display: none;
  }
  & p.alarm_title {
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: #0d0d0e;
  }
`
const Content = styled.div`
  margin-top: 32px;
  ${media.medium} {
    margin-top: 0;
  }
  &.border {
    border-top: 1px solid #eeeff1;
  }
`
const AlarmContent = styled.div`
  margin-bottom: 100px;
  ${media.medium} {
    margin-bottom: 80px;
  }
`
