import React from 'react'
import styled from 'styled-components'
import ModalWrapper from './ModalWrapper'
import { ReactComponent as CloseIcon } from 'assets/icons/close_white_icon.svg'
import { ReactComponent as ViewIcon } from 'assets/icons/view_icon.svg'
import { useRecoilState } from 'recoil'
import { roomSettingViewModalState } from 'atoms/modal'

function RoomSettingViewModal() {
  const [modal, setModal] = useRecoilState(roomSettingViewModalState)

  const handleClose = () => {
    setModal(null)
  }

  const viewSetting = () => {
    modal?.onViewSetting()
    handleClose()
  }

  return (
    <ModalWrapper>
      <ViewModalWrapper>
        <div className="buttons">
          <button onClick={modal?.onViewPaticipant}>
            <ViewIcon />
            <span className="count">{'123,123,123'}</span>
          </button>
          <button onClick={viewSetting}>설정</button>
        </div>
        <div className="cancel">
          <div className="close-button-wrap" onClick={handleClose}>
            <CloseIcon className="close-icon" />
          </div>
          <p className="cancel-label">취소</p>
        </div>
      </ViewModalWrapper>
    </ModalWrapper>
  )
}

export const ViewModalWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 80px 20px 40px;

  & .buttons {
    display: flex;
    flex-direction: column;

    gap: 10px;
    width: 100%;

    & .count {
      margin-left: 6px;
    }

    & button {
      color: #fff;
      width: 100%;
      height: 50px;
      background: #39393c;
      border: 1px solid #7c7c80;

      display: flex;
      justify-content: center;
      align-items: center;

      font-weight: 700;
      font-size: 16px;
      letter-spacing: -0.2px;

      cursor: pointer;
    }
  }

  & .cancel {
    position: absolute;
    bottom: 40px;

    left: 50%;
    transform: translateX(-50%);
  }

  & .close-button-wrap {
    width: 72px;
    height: 72px;
    background: #39393c;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 50%;

    & .close-icon {
      color: #fff;
    }

    cursor: pointer;
  }

  & .cancel-label {
    text-align: center;
    color: #fff;

    margin-top: 3px;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    height: 24px;
  }
`

export default RoomSettingViewModal
