import Button from 'components/common/Button'
import ClassInfo from 'components/common/class/ClassInfo'
import ClassPaymentPrice from 'components/common/class/ClassPaymentPrice'
import { AppRootWrapper } from 'components/layout/CommonStyle'
import api from 'lib/api'
import media from 'lib/styles/media'
import { type ClassDetailType } from 'lib/types/class'
import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

interface ClassRequestFreeContainerProps {
  data: ClassDetailType
  classId: string
}

function ClassRequestFreeContainer({
  data,
  classId,
}: ClassRequestFreeContainerProps) {
  const history = useHistory()
  const handleSubmit = async () => {
    const { data } = await api.post('/class/request', {
      class_id: classId,
    })

    if (!data.success) return

    history.push(`/class/complete/${classId}`)
  }
  return (
    <Wrapper>
      <h3 className="title">강의 결제</h3>
      <div className="info-content">
        <ClassInfo
          title={data.title}
          name={data?.nickname ?? data.name}
          image={data.images[0].image_url}
          step={{ total: data.steps.length }}
        />

        <ClassPaymentPrice price={0} />
        <div className="button-wrapper">
          <Button title="수강 신청하기" onClick={handleSubmit} />
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled(AppRootWrapper)`
  ${media.pc} {
    padding-top: 40px;
  }
  ${media.medium} {
    padding-top: 20px;
  }

  & .title {
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: #0d0d0e;

    ${media.medium} {
      font-weight: 700;
      font-size: 18px;
      line-height: 28px;
    }
  }

  & .info-content {
    & > div {
      margin-top: 40px;
    }
    & > .button-wrapper {
      ${media.pc} {
        margin-top: 60px;
        width: 380px;

        position: relative;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
`

export default ClassRequestFreeContainer
