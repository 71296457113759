import type { UserInfo } from 'lib/types/account'
import React, { useEffect } from 'react'
import StudentMenu from './StudentMenu'
import TutorMenu from './TutorMenu'
import { useRecoilState } from 'recoil'
import { PageTypeState } from 'atoms/common'

interface MenuPageProps {
  user?: UserInfo
}

function MenuPage({ user }: MenuPageProps) {
  const [pageType, setPageType] = useRecoilState(PageTypeState)

  if (pageType === 'tutor') return <TutorMenu />
  return <StudentMenu />
}

export default MenuPage
