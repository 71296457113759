import media from 'lib/styles/media'
import { type RoomSeatInfoType } from 'lib/types/room'
import React from 'react'
import styled, { css } from 'styled-components'
import CommonDebateComponent from './CommonDebateComponent'

interface SubFocusMemberProps {
  info: RoomSeatInfoType
  isMobile?: boolean
  isCamera?: boolean
  isMike?: boolean
}

function SubFocusMember({
  info,
  isCamera,
  isMike,
  isMobile,
}: SubFocusMemberProps) {
  return (
    <Wrapper isMobile={isMobile}>
      <CommonDebateComponent
        info={info}
        isCamera={isCamera}
        isMike={isMike}
        disableName
      />
    </Wrapper>
  )
}

const Wrapper = styled.div<{ isMobile?: boolean }>`
  ${media.pc} {
    width: calc(18.1% / 50.27 * 100);
  }

  ${media.medium} {
    position: absolute;
    width: 27.77%;

    top: 8px;
    left: 8px;
  }

  ${(props) =>
    props.isMobile
      ? css`
          ${media.pc} {
            display: none;
          }
        `
      : css`
          ${media.medium} {
            display: none;
          }
        `}
`

export default SubFocusMember
