import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ReactComponent as MainLogo } from '../../../assets/icons/main_logo.svg'
import { useHistory, useParams } from 'react-router'
import media from 'lib/styles/media'

function LogoMobileHeader() {
  const params = useParams<{ search: string }>()
  const value = params.search
  const history = useHistory()
  const [position, SetPosition] = useState(window.pageYOffset)
  const [isVisiable, SetIsVisiable] = useState(true)

  useEffect(() => {
    const handleScroll = () => {
      const move = window.pageYOffset
      SetIsVisiable(position > move)
      SetPosition(move)
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [position])

  return (
    <Wrapper className={isVisiable ? '' : 'active'}>
      <MainLogo
        onClick={() => {
          history.push('/')
        }}
      />
    </Wrapper>
  )
}

export default LogoMobileHeader

const Wrapper = styled.div`
  display: none;
  width: 100%;
  height: 58px;
  position: fixed;
  left: 0;
  top: 0;
  background: #fff;
  border-bottom: 1px solid #eeeff1;
  padding: 14px 20px;
  z-index: 5;
  &.active {
    display: none;
  }
  ${media.medium} {
    display: flex;
    & > svg {
      width: 87px;
      height: 30px;
    }
  }
`
