import { AppRootWrapper } from 'components/layout/CommonStyle'
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Input from '../Input'
import JoinSelectBox from '../infobasic/JoinSelectBox'
import AddressInput from '../infobasic/AddressInput'
import AddressPopup from '../infobasic/AddressPopup'
import Button from '../Button'
import { Switch } from 'antd'
import media from 'lib/styles/media'
import api from 'lib/api'
import EditProfileInput from './EditProfileInput'

interface Props {
  data: {
    email: string
    grade: string
    id: number
    name: string
    profile_url: any
    residence: string
    school: string
    school_type: string
    type: string
    use_marketing_alarm: number
  }
}
function EditInfoBasicComponent(data: Props) {
  const info = data.data
  const [checked, setChecked] = useState(false)
  const [formdata, setFormdata] = useState({
    profile: info.profile_url,
    name: info.name ? info.name : '',
    school_type: info.school_type ? info.school_type : '초등학교',
    grade: info.grade ? info.grade : '',
    school: info.school ? info.school : '',
    residence: info.residence ? info.residence : '',
  })
  const [addressPopup, setAddressPopup] = useState(false)
  const [preview, setPreview] = useState<File | undefined>()

  useEffect(() => {
    if (info.use_marketing_alarm === 1) {
      setChecked(true)
    } else {
      setChecked(false)
    }
  }, [info])

  useEffect(() => {
    if (addressPopup) {
      document.documentElement.style.overflow = 'hidden'
    } else {
      document.documentElement.style.overflow = 'auto'
    }

    return () => {
      document.documentElement.style.overflow = 'auto'
    }
  }, [addressPopup])

  const handleChange = (type: string) => (e: any) => {
    setFormdata({
      ...formdata,
      [type]: e.target.value,
    })
  }
  const handleChangeEtc = (type: string) => (e: any) => {
    setFormdata({
      ...formdata,
      [type]: e.target.value,
    })
  }
  const handleClick = (type: any) => {
    setFormdata({
      ...formdata,
      school_type: type,
      grade: '',
    })
  }
  const handleGradeClick = (type: any) => {
    setFormdata({
      ...formdata,
      grade: type,
    })
  }
  const handleComplete = (data: any) => {
    let fullAddress = data.address
    let extraAddress = ''

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname
      }
      if (data.buildingName !== '') {
        extraAddress +=
          extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : ''
    }
    const Address = fullAddress
    setAddressPopup(false)
    setFormdata({
      ...formdata,
      residence: Address,
    })
  }
  const handleCheckAlarm = async (checked: boolean) => {
    if (!checked) {
      setChecked(false)
    } else {
      setChecked(true)
    }

    const checkNum = checked ? 1 : 0
    const formData = new FormData()
    formData.append('is_marketing', String(checkNum))

    const {
      data: { success, alert },
    } = await api.post('/mypage/marketing', formData)
  }

  const handleEditProfile = (file: any, newFile: File) => {
    setFormdata({
      ...formdata,
      profile: file,
    })
    setPreview(newFile)
  }
  const handleDelImage = () => {
    setFormdata({
      ...formdata,
      profile: undefined,
    })
    setPreview(undefined)
  }
  const handleEditSubmit = async () => {
    const formData = new FormData()
    formData.append('name', formdata.name)
    formData.append('school_type', formdata.school_type)
    formData.append('school', formdata.school)
    formData.append('grade', formdata.grade)
    formData.append('residence', formdata.residence)
    formdata.profile === undefined && formData.append('is_remove_profile', '1')
    formdata.profile !== undefined &&
      formData.append('profile', formdata.profile)

    const {
      data: { success, alert },
    } = await api.post('/mypage/modify', formData)

    if (success) {
      setTimeout(() => {
        window.location.reload()
      }, 150)
    } else {
      window.alert(alert)
    }
  }
  return (
    <Wrapper>
      <Content>
        <div className="profile_box">
          <EditProfileInput
            data={formdata.profile}
            onChange={handleEditProfile}
            preview={preview}
          />
          {formdata.profile && (
            <p className="remove_image" onClick={handleDelImage}>
              사진 삭제
            </p>
          )}
        </div>

        <div className="info_box">
          <Input
            type="text"
            title="이름"
            placeholder="이름 입력"
            value={formdata.name}
            onChange={handleChange('name')}
            maxLength={10}
          />
          <JoinSelectBox
            title="학교/학년"
            value={formdata.school_type}
            handleClick={handleClick}
            handleGradeClick={handleGradeClick}
            gradeValue={formdata.grade}
            handleChange={handleChangeEtc('grade')}
          />
          <Input
            type="text"
            title="학교 명"
            placeholder="학교명 입력 (선택)"
            value={formdata.school}
            onChange={handleChange('school')}
          />
          <AddressInput
            title="거주지"
            value={formdata.residence}
            handleClick={() => setAddressPopup(true)}
          />
        </div>
        <Span />
        <div className="check_box">
          <p className="check_title">마케팅 정보 수신 동의</p>
          <Switch checked={checked} onChange={handleCheckAlarm} />
        </div>
        <div className="button_box">
          <Button title="저장" onClick={handleEditSubmit} />
        </div>
      </Content>
      {addressPopup && (
        <AddressPopup
          handleClose={() => {
            setAddressPopup(false)
          }}
          onComplete={handleComplete}
        />
      )}
    </Wrapper>
  )
}

export default EditInfoBasicComponent
const Wrapper = styled(AppRootWrapper)`
  ${media.medium} {
    width: 100%;
  }
`
const Content = styled.div`
  width: 380px;
  margin: 0 auto;
  ${media.medium} {
    width: 100%;
  }
  & div.profile_box {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    ${media.medium} {
      margin-top: 30px;
    }
    & p.remove_image {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      color: #ff2c2c;
      cursor: pointer;
      position: absolute;
      right: 0;
      top: -35px;
      ${media.medium} {
        top: -30px;
      }
    }
  }

  & div.info_box {
    margin-top: 35px;
    padding-bottom: 40px;
    & > div {
      margin-bottom: 20px;
    }
    & > div:last-of-type {
      margin-bottom: 0;
    }
  }
  & div.check_box {
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    & p.check_title {
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      color: #0d0d0e;
    }
    & .ant-switch {
      ${media.medium} {
        min-width: 34px;
        min-height: 20px;
        height: 20px;
      }
    }
    & .ant-switch-handle {
      ${media.medium} {
        width: 14px;
        height: 14px;
        top: 3px;
      }
    }
    & .ant-switch.ant-switch-checked {
      background: #7574d8;
    }
    & .ant-switch.ant-switch-checked:hover {
      background: #7574d8;
    }
    & .ant-switch {
      background: #e1e1e2;
    }
    & .ant-switch:hover {
      background: #e1e1e2;
    }
  }

  & div.button_box {
    margin-top: 40px;
  }
`
const Span = styled.div`
  width: 100%;
  height: 1px;
  background: #f9f9fb;
  ${media.medium} {
    width: calc(100% + 40px);
    transform: translateX(-20px);
    height: 8px;
    background: #f9f9fb;
  }
`
