import useRoom from 'lib/hooks/room/useRoom'
import media from 'lib/styles/media'
import { type RoomSeatInfoType } from 'lib/types/room'
import { getThousandCommaPrice } from 'lib/utils'
import React, { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import styled, { css } from 'styled-components'
import DebateMember from './DebateMember'
import { useRoomInfoValue } from '../provider/RoomInfoProvider'

function DebateMembers() {
  const {
    membersRoomInfo,
    maxCount,
    role,
    exchangedSeat,
    isRequestTeamDebating,
    voteCount,
    focusUserRoomInfo,
  } = useRoomInfoValue()
  const { roomId }: { roomId: string } = useParams()
  const { debateStatus } = useRoom(roomId)
  const [tab, setTab] = useState<'A' | 'B'>('A')

  const isDebating = useMemo(() => debateStatus !== 6, [debateStatus])

  const handleChangeTab = (team: 'A' | 'B') => () => {
    if (!isDebating) return
    setTab(team)
  }

  const { teamA, teamB } = useMemo(() => {
    const arr: RoomSeatInfoType[] = Array.from({ length: maxCount ?? 0 })

    membersRoomInfo?.forEach((info) => {
      const index = info.table_num - 1
      arr[index] = info
    })

    if (!membersRoomInfo || !maxCount) {
      return {
        teamA: [],
        teamB: [],
      }
    }

    const teamA = arr.slice(0, Math.ceil(maxCount / 2))
    const teamB = arr.slice(Math.ceil(maxCount / 2))

    return {
      teamA,
      teamB,
    }
  }, [membersRoomInfo, maxCount])

  return (
    <Wrapper team={tab} isDebating={isDebating}>
      <div className="tab">
        <div
          className={`team-box ${tab === 'A' ? 'active' : ''}`}
          onClick={handleChangeTab('A')}
        >
          선발언팀
          {voteCount && (
            <span className="mobile-vote-count">
              ({getThousandCommaPrice(voteCount.A)})
            </span>
          )}
        </div>
        <div
          className={`team-box ${tab === 'B' ? 'active' : ''}`}
          onClick={handleChangeTab('B')}
        >
          후발언팀
          {voteCount && (
            <span className="mobile-vote-count">
              ({getThousandCommaPrice(voteCount.B)})
            </span>
          )}
        </div>
      </div>
      <div className="left">
        <div className={`mileStone ${isRequestTeamDebating.A && 'mb-14'}`}>
          <div className="team-label">
            선발언팀
            {voteCount && (
              <div className="mobile-vote-count">
                ({getThousandCommaPrice(voteCount.A)})
              </div>
            )}
          </div>
          <div className="team-sign-wrapper">
            {isRequestTeamDebating.A && (
              <div className="request-debating-label">회의 요청 중</div>
            )}
            {voteCount !== undefined && (
              <div className="vote-count">
                총 {getThousandCommaPrice(voteCount.A)}득표
              </div>
            )}
          </div>
        </div>
        {teamA.map((seat, index) => (
          <div
            className="room-wrapper"
            key={seat ? `${seat.nickname}_${seat.user_id}` : index}
          >
            <DebateMember
              key={seat ? `${seat.nickname}_${seat.user_id}` : index}
              seat={seat}
              seatNumber={index + 1}
              role={role}
              exchangedSeat={exchangedSeat}
              isFocus={seat && seat.user_id === focusUserRoomInfo?.user_id}
            />
          </div>
        ))}
      </div>

      <div className="right">
        <div className={`mileStone ${isRequestTeamDebating.B && 'mb-14'}`}>
          <div className="team-label">
            후발언팀
            {voteCount && (
              <span className="mobile-vote-count">
                ({getThousandCommaPrice(voteCount.B)})
              </span>
            )}
          </div>
          <div className="team-sign-wrapper">
            {isRequestTeamDebating.B && (
              <div className="request-debating-label">회의 요청 중</div>
            )}
            {voteCount !== undefined && (
              <div className="vote-count">
                총 {getThousandCommaPrice(voteCount.B)}득표
              </div>
            )}
          </div>
        </div>
        {teamB.map((seat, index) => (
          <div
            className="room-wrapper"
            key={
              seat
                ? `${seat.nickname}_${seat.user_id}`
                : index + Math.ceil(maxCount / 2) + 1
            }
          >
            <DebateMember
              key={
                seat
                  ? `${seat.nickname}_${seat.user_id}`
                  : index + Math.ceil(maxCount / 2) + 1
              }
              seat={seat}
              seatNumber={index + Math.ceil(maxCount / 2) + 1}
              role={role}
              exchangedSeat={exchangedSeat}
            />
          </div>
        ))}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div<{ team: 'A' | 'B' | null; isDebating: boolean }>`
  flex: 1;

  ${media.pc} {
    padding-bottom: 30px;
    position: absolute;
    top: 30px;
    width: calc(100% - 60px);

    display: flex;
    justify-content: space-between;

    & .left,
    .right {
      width: 18.83%;
      display: flex;
      flex-direction: column;

      gap: 6px;
    }

    & .tab {
      display: none;
    }

    & .mileStone {
      padding: 14px 0;
      background: rgba(59, 59, 64, 1);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      gap: 10px;

      margin-bottom: 30px;

      ${(props) =>
        !props.isDebating &&
        css`
          opacity: 0;
        `}
    }

    & .mb-14 {
      margin-bottom: 14px;
    }

    & .team-label {
      color: #f9f9fb;
      font-weight: 500;
      font-size: 24px;
      line-height: 24px;
    }

    & .request-debating-label {
      color: rgba(255, 44, 44, 1);
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;

      order: 1;
    }

    & .team-sign-wrapper {
      display: flex;
      gap: 10px;
    }
  }

  ${media.medium} {
    order: 2;
    padding-bottom: 40px;

    & .tab {
      display: flex;
      height: 48px;

      ${(props) =>
        !props.isDebating &&
        css`
          display: none;
        `}
    }

    & .team-box {
      flex: 1;

      display: flex;
      align-items: center;
      justify-content: center;

      cursor: pointer;

      color: #7c7c80;
      border-bottom: 2px solid #3b3b40;

      &.active {
        color: #f9f9fb;
        border-bottom: 2px solid #f9f9fb;
      }
    }

    & .left {
      width: 100%;
      ${(props) =>
        props.isDebating &&
        props.team !== 'A' &&
        css`
          display: none;
        `}
    }
    & .right {
      width: 100%;
      ${(props) =>
        props.isDebating &&
        props.team !== 'B' &&
        css`
          display: none;
        `}
    }

    & .team-label {
      display: none;
    }

    & .mileStone {
      display: flex;
      justify-content: flex-end;
    }

    & .request-debating-label {
      color: rgba(255, 44, 44, 1);
      font-weight: 700;
      font-size: 12px;
      line-height: 22px;
      background: rgba(255, 44, 44, 0.1);
      border-radius: 3px;
      width: 84px;
      height: 26px;

      margin-top: 8px;
      margin-right: 20px;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  & .room-wrapper {
    ${media.pc} {
      width: 100%;
    }
  }

  & .vote-count {
    color: 0;

    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #e1e1e2;

    ${media.medium} {
      display: none;
    }
  }

  & .mobile-vote-count {
    ${media.pc} {
      display: none;
    }

    margin-left: 4px;
  }
`

export default DebateMembers
