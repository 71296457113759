import React from 'react'
import styled from 'styled-components'
import { ReactComponent as Icon } from '../../assets/icons/class_notice_button_icon.svg'

interface Props {
  onClick: () => void
  onBlur: () => void
}
function ClassNoticeButton({ onClick, onBlur }: Props) {
  return (
    <Wrapper onClick={onClick} onBlur={onBlur}>
      <Icon />
    </Wrapper>
  )
}
export default ClassNoticeButton

const Wrapper = styled.button`
  width: 36px;
  height: 42px;
  background: #ffffff;
  border: 1px solid #e1e1e2;
  border-radius: 4px;
  outline: none;
`
