import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'

interface VideoProps extends React.HTMLProps<HTMLVideoElement> {
  stream?: MediaStream
  test?: any
}

function Video(props: VideoProps) {
  const videoRef = useRef<any>(null)
  const { stream, test, muted, ...rest } = props

  useEffect(() => {
    if (videoRef.current === null) return
    if (!stream) {
      console.log(test)
      console.log('stream 실패', stream)
      return
    } else {
      console.log(test)
      console.log('stream 성공', stream)
    }

    videoRef.current.srcObject = stream
    videoRef.current.muted = false
    videoRef.current.onloadedmetadata = function (e: any) {
      videoRef?.current?.play()
    }
    videoRef.current.muted = true
  }, [stream])

  // useEffect(() => {
  //   if (!ref.current) return
  //   ref.current.muted = muted
  // }, [muted])

  return (
    <Wrapper>
      <video {...rest} ref={videoRef} autoPlay playsInline />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 6px;

  & video {
    width: 100%;
    height: 100%;
    border-radius: 6px;
  }
`

export default Video
