import React from 'react'
import styled from 'styled-components'
import { AppRootWrapper } from 'components/layout/CommonStyle'
import MyClassTitle from 'components/common/myclass/MyClassTitle'
import media from 'lib/styles/media'
import { type ClassDetailType } from 'lib/types/class'
import ClassInfo from 'components/common/class/ClassInfo'
import Button from 'components/common/Button'
import { useHistory } from 'react-router-dom'

interface ClassCompleteContainerProps {
  data: ClassDetailType
}

function ClassCompleteContainer({ data }: ClassCompleteContainerProps) {
  const history = useHistory()
  const handleSubmit = () => {
    history.push('/class')
  }
  return (
    <Wrapper>
      <MyClassTitle
        mainTitle="수강 신청 완료"
        subTitle="수강 신청이 완료되었습니다. 강의 시간에 맞춰 예정된 강의에 접속하여 강의에 꼭 참석해주세요."
      />
      <div className="info-content">
        <ClassInfo
          title={data.title}
          image={data.images[0].image_url}
          name={data?.nickname ?? data.name}
          step={{
            total: data.steps.length,
          }}
        />

        <div className="button-wrapper">
          <Button title="확인" onClick={handleSubmit} />
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled(AppRootWrapper)`
  ${media.pc} {
    padding-top: 40px;
  }
  ${media.medium} {
    padding-top: 20px;
  }

  & .info-content {
    & > div {
      margin-top: 40px;
    }
    & > .button-wrapper {
      ${media.pc} {
        margin-top: 60px;
        width: 380px;

        position: relative;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
`

export default ClassCompleteContainer
