import media from 'lib/styles/media'
import { getThousandCommaPrice } from 'lib/utils'
import React from 'react'
import styled from 'styled-components'
import PaymentSubTitle from './PaymentSubTitle'

interface ClassPaymentPriceProps {
  price: number
}

function ClassPaymentPrice({ price }: ClassPaymentPriceProps) {
  return (
    <Wrapper>
      <PaymentSubTitle title="결제 금액" />
      <div className="content">
        <div className="box">
          <div className="label">수강 신청금액</div>
          <div className="price">{getThousandCommaPrice(price)}원</div>
        </div>
        <div className="box">
          <div className="label">결제금액</div>
          <div className="price bold">{getThousandCommaPrice(price)}원</div>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  & .content {
    margin-top: 20px;

    display: flex;
    flex-direction: column;

    gap: 12px;

    ${media.medium} {
      margin-top: 14px;
      gap: 10px;
    }
  }

  & .box {
    display: flex;
    gap: 30px;

    ${media.medium} {
      gap: 10px;
    }
  }

  & .label {
    width: 160px;

    font-size: 14px;
    line-height: 24px;
    color: #3b3b40;

    ${media.medium} {
      width: 110px;
      font-weight: 500;
      font-size: 13px;
      line-height: 22px;
      color: #3b3b40;
    }
  }

  & .price {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #0d0d0e;

    ${media.medium} {
      font-weight: 500;
      font-size: 13px;
      line-height: 22px;
      color: #3b3b40;
    }
  }

  & .price.bold {
    font-weight: 700;
  }
`

export default ClassPaymentPrice
