import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import useSWR from 'swr'
import ManageDetailPage from './ManageDetailPage'
import SecretPopup from 'components/common/SecretPopup'

function ManagePublicPage() {
  const params = useParams<{ id: string }>()
  const searchData = params.id
  const [publicType, setPublicType] = useState('')
  const { data, mutate } = useSWR(`/class/detail?class_id=${searchData}`)

  useEffect(() => {
    if (data) {
      return setPublicType(String(data?.data?.class.is_public))
    }
  }, [data])

  if (!data) return null
  if (!data.data) return null
  if (!data.data.class) return null

  if (publicType === '0')
    return (
      <SecretPopup
        handleClose={() => {
          window.location.href = '/'
        }}
        id={searchData}
        onSuccess={() => setPublicType('1')}
      />
    )
  return <ManageDetailPage />
}

export default ManagePublicPage
