import Profile from 'components/common/Profile'
import { type ChatType } from 'lib/api/chat'
import { formatChatDate } from 'lib/utils'
import React from 'react'
import styled, { css } from 'styled-components'

interface ChatProps {
  chat: ChatType
  isMe: boolean
}

function Chat({ chat, isMe }: ChatProps) {
  return (
    <Wrapper isMe={isMe}>
      <div className="header">
        <Profile
          name={chat?.nickname ?? chat.name}
          profileUrl={chat.profile_url}
          size="xs"
        />
      </div>
      <div className="content">{chat.message}</div>
      <div className="footer">{formatChatDate(chat.created_at)}</div>
    </Wrapper>
  )
}

const Wrapper = styled.div<{ isMe: boolean }>`
  width: 100%;

  & .content {
    display: inline-block;

    background: #3b3b40;
    padding: 10px;

    margin-top: 8px;
    margin-bottom: 6px;

    border-radius: 2px 8px 8px 8px;

    font-weight: 400;
    font-size: 13px;
    line-height: 22px;
    color: #eeeff1;

    ${(props) =>
      props.isMe &&
      css`
        color: #3b3b40;
        background: #f9f9fb;
      `}
  }

  & .footer {
    font-weight: 400;
    font-size: 10px;
    line-height: 10px;
    color: #7c7c80;
  }
`

export default Chat
