import React, { useCallback, useEffect, useRef } from 'react'

interface Props {
  direction: string
  duration: string | number
  delay: string | number
}
function useScrollFadeIn({ direction, duration, delay }: Props) {
  const dom = useRef<any>()
  const handleDirection = (name: string) => {
    switch (name) {
      case 'up':
        return 'translate3d(0, 50px, 0)'
      case 'down':
        return 'translate3d(0, -50px, 0)'
      case 'left':
        return 'translate3d(50px, 0, 0)'
      case 'right':
        return 'translate3d(-50px, 0, 0)'
      default:
        return 'translate3d(0, 0, 0)'
    }
  }
  const handleScroll = useCallback(([entry]: any) => {
    const { current } = dom

    if (entry.isIntersecting && current) {
      current.style.transitionProperty = 'all'
      current.style.transitionDuration = `${duration}s`
      current.style.transitionTimingFunction = 'cubic-bezier(0, 0, 0.2, 1)'
      current.style.transitionDelay = `${delay}s`
      current.style.opacity = 1
      current.style.transform = 'translate3d(0, 0, 0)'
    }
  }, [])

  useEffect(() => {
    let observer: any
    const { current } = dom
    if (current) {
      observer = new IntersectionObserver(handleScroll, { threshold: 0.7 })
      observer.observe(current)
    }
    return () => observer && observer.disconnect()
  }, [handleScroll])

  return {
    ref: dom,
    style: {
      opacity: 0,
      transform: handleDirection(direction),
    },
  }
}

export default useScrollFadeIn
