import React from 'react'
import styled from 'styled-components'
import { ReactComponent as CameraOnIcon } from 'assets/icons/camera_on_icon.svg'
import { ReactComponent as CameraOffIcon } from 'assets/icons/camera_off_icon.svg'
import { ReactComponent as MikeOnIcon } from 'assets/icons/mike_on_icon.svg'
import { ReactComponent as MikeOffIcon } from 'assets/icons/mike_off_icon.svg'
import IconWrap from 'components/common/IconWrap'
import Profile from 'components/common/Profile'
import {
  useRoomInfoAction,
  useRoomInfoValue,
} from '../provider/RoomInfoProvider'
import { type RoomSeatInfoType } from 'lib/types/room'

function StudentRoomSideFooter() {
  const { myRoomInfo } = useRoomInfoValue()
  const { modifyRoomInfo } = useRoomInfoAction()

  const myRoomInfoData = myRoomInfo as RoomSeatInfoType

  const handleToggleMike = () => {
    if (myRoomInfoData.is_tutor_mike === 0) return
    modifyRoomInfo &&
      modifyRoomInfo({
        isCam: myRoomInfoData.is_camera === 1,
        isMike: myRoomInfoData.is_mike !== 1,
        tableNum: myRoomInfoData.table_num,
        userId: myRoomInfoData.user_id,
      })
  }

  const handleToggleCam = () => {
    if (myRoomInfoData.is_tutor_camera === 0) return
    modifyRoomInfo &&
      modifyRoomInfo({
        isCam: myRoomInfoData.is_camera !== 1,
        isMike: myRoomInfoData.is_mike === 1,
        tableNum: myRoomInfoData.table_num,
        userId: myRoomInfoData.user_id,
      })
  }

  return (
    <SideFooterWrapper>
      <div className="profile">
        <Profile
          name={myRoomInfoData?.nickname ?? myRoomInfoData?.name}
          profileUrl={myRoomInfoData?.profile_url}
        />
      </div>
      <IconWrap onClick={handleToggleCam}>
        {myRoomInfoData?.is_camera === 1 ? <CameraOffIcon /> : <CameraOnIcon />}
      </IconWrap>
      <IconWrap onClick={handleToggleMike}>
        {myRoomInfoData?.is_mike === 1 ? <MikeOffIcon /> : <MikeOnIcon />}
      </IconWrap>
    </SideFooterWrapper>
  )
}

export const SideFooterWrapper = styled.div`
  padding: 20px;

  display: flex;
  gap: 8px;

  border-top: 1px solid #7c7c80;

  & .profile {
    width: 100%;
  }
`

export default StudentRoomSideFooter
