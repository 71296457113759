import React from 'react'
import styled from 'styled-components'
import { ReactComponent as HomeIcon } from '../../assets/icons/mobile_home_icon.svg'
import { ReactComponent as HomeHoverIcon } from '../../assets/icons/mobile_home_hover_icon.svg'
import { ReactComponent as NavIcon2 } from '../../assets/icons/mobile_tutor_icon2.svg'
import { ReactComponent as MenuHoverIcon2 } from '../../assets/icons/mobile_tutor_icon2_hover.svg'
import { ReactComponent as NavIcon3 } from '../../assets/icons/mobile_tutor_icon3.svg'
import { ReactComponent as MenuHoverIcon3 } from '../../assets/icons/mobile_tutor_icon3_hover.svg'
import { ReactComponent as NavIcon4 } from '../../assets/icons/mobile_tutor_icon4.svg'
import { ReactComponent as MenuHoverIcon4 } from '../../assets/icons/mobile_tutor_icon4_hover.svg'
import { ReactComponent as NavIcon5 } from '../../assets/icons/mobile_tutor_icon5.svg'
import { ReactComponent as MenuHoverIcon5 } from '../../assets/icons/mobile_tutor_icon5_hover.svg'
import { useHistory } from 'react-router-dom'
import media from 'lib/styles/media'
import useUser from 'lib/hooks/account/useUser'

function TutorGnb() {
  const { user } = useUser()
  const history = useHistory()
  const location = window.location.pathname
  const tutorUser = user && user.type === 'tutor'
  const icon = [
    {
      id: 1,
      title: '홈',
      history: '/',
      icon: <HomeIcon />,
      hover: <HomeHoverIcon />,
    },
    {
      id: 2,
      title: '강의 관리',
      history: !tutorUser ? '/login' : '/manage/list',
      icon: <NavIcon2 />,
      hover: <MenuHoverIcon2 />,
    },
    {
      id: 3,
      title: '강의 개설',
      history: !tutorUser ? '/login' : '/register/class',
      icon: <NavIcon3 />,
      hover: <MenuHoverIcon3 />,
    },
    {
      id: 4,
      title: '튜터 정보',
      history: !tutorUser ? '/login' : '/mypage/edit/info',
      icon: <NavIcon4 />,
      hover: <MenuHoverIcon4 />,
    },
    {
      id: 5,
      title: '마이페이지',
      history: !tutorUser ? '/login' : '/menu',
      icon: <NavIcon5 />,
      hover: <MenuHoverIcon5 />,
    },
  ]
  return (
    <Wrapper>
      {icon.map((item, i) => (
        <div
          key={`mobile_gnb_list_${i}`}
          className={location === item.history ? 'content active' : 'content'}
          onClick={() => {
            if (item.id === 2) {
              history.push(item.history + '?type=0&role=tutor')
            } else {
              history.push(item.history)
            }
          }}
        >
          {location === item.history ? item.hover : item.icon}
          <p className="gnb_title">{item.title}</p>
        </div>
      ))}
    </Wrapper>
  )
}

export default TutorGnb

const Wrapper = styled.div`
  position: fixed;
  left: 0;
  bottom: -1px;
  z-index: 6;
  width: 100%;
  height: 60px;
  background: #fff;
  border-top: 1px solid #eeeff1;
  padding: 0 26px;
  align-items: center;
  justify-content: space-between;
  display: none;
  ${media.medium} {
    display: flex;
    padding: 0 16px;
  }
  & div.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 65px;
    height: 60px;
  }
  & p.gnb_title {
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    text-align: center;
    color: #7c7c80;
    margin-top: 3px;
  }
  & .active {
    & > p {
      color: #0d0d0e;
    }
  }
`
