import React from 'react'
import styled from 'styled-components'

interface FooterIconWrapProps {
  Icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined
    }
  >
  label: string
  onClick?: () => void
  disabled?: boolean
}

function FooterIconWrap({
  Icon,
  label,
  onClick,
  disabled,
}: FooterIconWrapProps) {
  return (
    <Wrapper onClick={disabled ? undefined : onClick} disabled={disabled}>
      <Icon className="box-icon" />
      <span className="box-label">{label}</span>
    </Wrapper>
  )
}

const Wrapper = styled.div<{ disabled?: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;

  align-items: center;

  width: 46px;
  min-width: 46px;
  height: 38px;

  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

  & .box-icon {
    width: 24px;
    height: 24px;
  }

  & .box-label {
    margin-top: 4px;
    font-weight: 500;
    font-size: 10px;
    text-align: center;

    color: ${(props) =>
      props.disabled ? 'rgba(124, 124, 128, 1)' : '#eeeff1'};
  }
`

export default FooterIconWrap
