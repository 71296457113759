import media from 'lib/styles/media'
import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

interface Props {
  onHandleClick: any
  location: string
  pageType: string
}
function EditMenuTabContent({ onHandleClick, location, pageType }: Props) {
  const studentTab = [
    {
      name: '휴대폰 번호',
      link: '?type=phone',
    },
    {
      name: '로그인 정보',
      link: '?type=login',
    },
    {
      name: '기본 정보',
      link: '?type=basic',
    },
  ]

  const tutorTab = [
    {
      name: '휴대폰 번호',
      link: '?type=phone&role=tutor',
    },
    {
      name: '로그인 정보',
      link: '?type=login&role=tutor',
    },
    {
      name: '튜터 정보',
      link: '?type=info&role=tutor',
    },
    {
      name: '정산 정보',
      link: '?type=price&role=tutor',
    },
  ]

  return (
    <Wrapper>
      {pageType === 'user' &&
        studentTab.map((item, i) => (
          <div
            key={`edit_menu_tab_${i}`}
            onClick={() => {
              onHandleClick(item.link)
            }}
            className={
              location === item.link
                ? 'menu_tab_content active'
                : 'menu_tab_content'
            }
          >
            <p className="menu_tab_text">{item.name}</p>
          </div>
        ))}
      {pageType === 'tutor' &&
        tutorTab.map((item, i) => (
          <div
            key={`edit_menu_tab_${i}`}
            onClick={() => {
              onHandleClick(item.link)
            }}
            className={
              location === item.link
                ? 'menu_tab_content tutor active'
                : 'menu_tab_content tutor'
            }
          >
            <p className="menu_tab_text">{item.name}</p>
          </div>
        ))}
    </Wrapper>
  )
}

export default EditMenuTabContent

const Wrapper = styled.div`
  width: 100%;
  margin-top: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e1e1e2;
  ${media.medium} {
    min-width: 100px;
    overflow-x: scroll;
  }
  & div.menu_tab_content {
    width: calc(100% / 3);
    &.tutor {
      width: calc(100% / 4);
      ${media.medium} {
        min-width: 100px;
      }
    }
    &.active {
      border-bottom: 2px solid #3b3b40;
      & > p {
        color: #0d0d0e;
        font-weight: 700;
      }
    }
  }
  & p.menu_tab_text {
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #b5b5b9;
    padding-bottom: 16px;
    cursor: pointer;
    ${media.medium} {
      font-size: 14px;
      line-height: 24px;
      padding-bottom: 12px;
    }
  }
  ${media.medium} {
    margin: 0;
    width: calc(100% + 40px);
    transform: translateX(-20px);
  }
`
