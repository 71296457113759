import { type ReviewType } from 'lib/api/tutor'
import { dateFormat } from 'lib/utils'
import React, { useState } from 'react'
import styled from 'styled-components'
import GradeComponent from './GradeComponent'
import media from 'lib/styles/media'

interface Props {
  review: ReviewType
}

function TutorReviewComponent({ review }: Props) {
  const [open, setOpen] = useState(false)

  return (
    <Wrapper onClick={() => setOpen(!open)} className={open ? 'open' : ''}>
      <div className="review-header">
        <div className="review-header-left">
          <div className="review-grade">
            <GradeComponent grade={review.grade} />
          </div>
          {review.name && <div className="student-name">{review.name}</div>}
        </div>
        <div className="review-date">
          {dateFormat(review.created_at, 'yyyy-MM-dd')}
        </div>
      </div>
      <div className="review-content">
        <div className="profile-image">
          <img src={review.image_url} />
        </div>
        <div className="review-info">
          <div className="class-title">{review.title}</div>
          {/* <div className="step-title">{}</div> */}
          <div className="review-content">{review.contents}</div>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  border-bottom: 1px solid #eeeff1;
  padding: 30px 10px;
  cursor: pointer;

  & .review-header {
    display: flex;
    justify-content: space-between;
  }

  & .review-header-left {
    display: flex;
    align-items: center;
  }

  & .student-name {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #7c7c80;
    width: 100%;
    padding-left: 13px;
  }

  & .review-date {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #3b3b40;
  }

  & .profile-image {
    border-radius: 8px;
    width: 140px;
    height: 78.75px;

    & img {
      width: 100%;
      height: 100%;
      border-radius: inherit;
      object-fit: cover;
    }
  }

  & .review-info {
    flex: 1;
  }

  & .class-title {
    font-weight: 700;
    font-size: 14px;
    line-height: 26px;
    color: #b5b5b9;
  }

  & .step-title {
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    color: #b5b5b9;
  }

  & .review-content {
    margin-top: 16px;

    display: flex;
    gap: 20px;

    & .review-content {
      margin-top: 6px;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #3b3b40;
      overflow: hidden;
      word-break: break-word;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
    }
  }

  &.open {
    background: #f9f9fb;

    & .review-content .review-content {
      -webkit-line-clamp: 100;
    }
  }

  ${media.medium} {
    padding: 20px 0;

    & .review-header {
      display: block;
    }

    & .student-name {
      width: 100%;
      padding-left: 13px;
    }

    & .review-date {
      margin-top: 6px;
    }

    & .profile-image {
      width: 90px;
      height: 51px;
    }

    & .review-info {
      margin-top: 14px;
    }

    & .step-title {
      line-height: 26px;
    }

    & .review-content {
      margin-top: 20px;
      display: block;
      gap: 0;
    }
  }
`

export default TutorReviewComponent
