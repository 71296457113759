import api from 'lib/api'
import media from 'lib/styles/media'
import { convertToMinute } from 'lib/utils/CommonFuncttion'
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

interface Props {
  title: string
  placeholder: string
  phone: string | number | undefined
  onPhoneChange: (e: any) => void
  btnText: string
  handleEditSendSms?: () => void
  handleSendSms: () => void
  codeInput?: boolean
  code: string | number | undefined
  onCodeChange: (e: any) => void
  isCodeCheck?: boolean
  type?: string
  disabled?: boolean
}
function SmsInput({
  title,
  placeholder,
  phone,
  onPhoneChange,
  btnText,
  handleEditSendSms,
  handleSendSms,
  codeInput,
  code,
  onCodeChange,
  isCodeCheck,
  type,
  disabled,
}: Props) {
  const [isTimer, setIsTimer] = useState(180)
  const [isTimerError, setIsTimerError] = useState(false)
  const [isTimerErrorMessage, setIsTimerErrorMessage] = useState('')
  const [reStart, setReStart] = useState(false)

  // 재인증 버튼 클릭
  useEffect(() => {
    if (reStart) {
      setIsTimer(180)
      setReStart(false)
    }
  }, [reStart])
  // 인증시간 03:00
  useEffect(() => {
    if (codeInput) {
      const isTime = setTimeout(() => {
        setIsTimer(isTimer - 1)
      }, 1000)

      if (isTimer === 0) {
        clearTimeout(isTime)
        setIsTimerError(true)
        setIsTimerErrorMessage('인증 시간이 초과되었습니다.')
        setIsTimer(0)
      }

      return () => {
        clearTimeout(isTime)
      }
    }
  }, [isTimer, codeInput])

  // 인증번호 체크
  useEffect(() => {
    if (isCodeCheck) {
      setIsTimerError(true)
      setIsTimerErrorMessage('인증번호가 올바르지 않습니다.')
    }
  }, [isCodeCheck])

  return (
    <Wrapper className={type === 'find' ? 'find' : ''}>
      <InputContent className={disabled ? 'disabled' : ''}>
        <p className="input_title">{title}</p>
        <input
          autoComplete="off"
          placeholder={placeholder}
          maxLength={11}
          value={phone ?? ''}
          onChange={onPhoneChange}
          disabled={disabled}
        />

        {btnText === '수정' ? (
          <div className="sms_button_box border" onClick={handleEditSendSms}>
            <p className="sms_button_text">{btnText}</p>
          </div>
        ) : (
          <div
            className={
              btnText === '수정' ||
              btnText === '재발송' ||
              String(phone).length >= 10
                ? 'sms_button_box border'
                : 'sms_button_box'
            }
            onClick={() => {
              handleSendSms()
              setIsTimerError(false)
              return setReStart(true)
            }}
          >
            <p className="sms_button_text">{btnText}</p>
          </div>
        )}
      </InputContent>
      {codeInput && (
        <RegisterBox className={isTimerError ? 'error' : ''}>
          <p className="input_title">인증번호</p>
          <input
            placeholder="인증번호 6자리 입력"
            autoComplete="off"
            value={code ?? ''}
            maxLength={6}
            onChange={onCodeChange}
          />
          <p className="register_timer">{convertToMinute(isTimer)}</p>
        </RegisterBox>
      )}
      {isTimerError && isTimerErrorMessage && (
        <p className="error_message">{isTimerErrorMessage}</p>
      )}
    </Wrapper>
  )
}

export default SmsInput
const Wrapper = styled.div`
  min-height: 150px;
  & p.error_message {
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    color: #ff2c2c;
    margin-top: 4px;
  }
  & p.input_title {
    font-weight: 700;
    font-size: 13px;
    line-height: 22px;
    color: #0d0d0e;
    margin-bottom: 4px;
  }
  & input {
    width: 100%;
    border: none;
    border-bottom: 1px solid #e1e1e2;
    outline: none;
    padding: 7px 30px 7px 0;
    &::placeholder {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #b5b5b9;
    }
    &:disabled {
      background: #fff;
      color: #b5b5b9;
    }
  }
  & input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px #fff inset;
  }
  &.find {
    ${media.medium} {
      min-height: 60px;
    }
  }
`
const InputContent = styled.div`
  position: relative;

  & div.sms_button_box {
    position: absolute;
    right: 0;
    bottom: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    max-width: 70px;
    min-height: 30px;
    background: #ffffff;
    border: 1px solid #e1e1e2;
    border-radius: 100px;
    &.border {
      border: 1px solid #0d0d0e;
      & > p {
        color: #0d0d0e;
      }
    }
  }
  & p.sms_button_text {
    font-weight: 400;
    font-size: 13px;
    line-height: 22px;
    text-align: center;
    color: #b5b5b9;
    margin-bottom: 2px;
    cursor: pointer;
  }
  &.disabled {
    & p.input_title {
      color: #b5b5b9;
    }
  }
`
const RegisterBox = styled.div`
  margin-top: 20px;
  position: relative;
  &.error {
    & > input {
      border-bottom: 1px solid #ff2c2c;
    }
  }

  & p.register_timer {
    position: absolute;
    right: 2px;
    bottom: 7px;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    text-align: right;
    color: #7574d8;
  }
`
