import React from 'react'
import styled from 'styled-components'
import MyClassListComponent from './MyClassListComponent'
import type { MyClassDetailDataProps } from 'lib/types/account'
import media from 'lib/styles/media'

interface Props {
  data: MyClassDetailDataProps
}

function MyClassList({ data }: Props) {
  return (
    <Wrapper>
      <p className="class_date">{data.begin_date}</p>
      <div className="class_box">
        {data.class.map((item, i) => (
          <MyClassListComponent data={item} key={`my_clss_reviews_list_${i}`} />
        ))}
      </div>
    </Wrapper>
  )
}

export default MyClassList

const Wrapper = styled.div`
  margin-bottom: 60px;
  ${media.medium} {
    margin-bottom: 0;
    border-bottom: 6px solid #eeeff1;
  }
  & p.class_date {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #b5b5b9;
    ${media.medium} {
      padding: 10px 20px;
      font-size: 13px;
      line-height: 22px;
    }
  }
  & div.class_box {
    margin-top: 16px;
    ${media.medium} {
      margin-top: 0;
    }
  }
`
