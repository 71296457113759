import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import Button from '../Button'
import { useHistory } from 'react-router-dom'
import { ReactComponent as StarOff } from '../../../assets/icons/reiews_star_off.svg'
import { ReactComponent as StarOn } from '../../../assets/icons/reiews_star_on.svg'
import media from 'lib/styles/media'

interface Props {
  review?: string
  grade?: number
  feedback?: string
  maxFeedback?: number
  handleReviewClick: () => void
  handleFeedbackClick: () => void
}
function ReviewInfoContent({
  grade,
  review,
  feedback,
  maxFeedback,
  handleReviewClick,
  handleFeedbackClick,
}: Props) {
  const star = [
    { id: 1, icon: <StarOff />, active: <StarOn /> },
    { id: 2, icon: <StarOff />, active: <StarOn /> },
    { id: 3, icon: <StarOff />, active: <StarOn /> },
    { id: 4, icon: <StarOff />, active: <StarOn /> },
    { id: 5, icon: <StarOff />, active: <StarOn /> },
  ]
  const contentRef = useRef(null)
  const [more, setMore] = useState(false)
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (contentRef?.current !== null) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      const scrollHeight = contentRef?.current?.scrollHeight
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      const height = contentRef?.current?.offsetHeight

      if (scrollHeight <= height) {
        setOpen(true)
      } else {
        setOpen(false)
      }
    }
  }, [contentRef])

  useEffect(() => {
    if (contentRef?.current !== null && open && !more) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      contentRef.current.style.height = contentRef.current.scrollHeight + 'px'
    }
  }, [open, more])

  return (
    <Wrapper>
      <Review>
        <p className="review_title">내가 쓴 리뷰</p>
        {review ? (
          <div className="review_content">
            <div className="star_content">
              {star.map((item, i) => (
                <div key={`review_cotent_score_${i}`}>
                  {grade && grade >= item.id ? item.active : item.icon}
                </div>
              ))}
            </div>
            <p
              className={open ? 'review_text open' : 'review_text'}
              ref={contentRef}
            >
              {review}
            </p>
            {!open && !more && (
              <span
                onClick={() => {
                  setOpen(true)
                  setMore(true)
                }}
              >
                더보기
              </span>
            )}
            {open && more && (
              <span
                onClick={() => {
                  setOpen(false)
                  setMore(false)
                }}
              >
                접기
              </span>
            )}
          </div>
        ) : (
          <div className="button_box">
            <Button
              title="리뷰 작성"
              className="border"
              onClick={handleReviewClick}
            />
          </div>
        )}
      </Review>
      <FeedBack>
        <p className="review_title">튜터 피드백</p>
        {feedback ? (
          <div className="button_box">
            <Button
              title="총점"
              className="border"
              score={feedback}
              max={maxFeedback}
              onClick={handleFeedbackClick}
            />
          </div>
        ) : (
          <p className="no_feedback">아직 등록된 피드백이 없습니다.</p>
        )}
      </FeedBack>
    </Wrapper>
  )
}

export default ReviewInfoContent
const Wrapper = styled.div`
  width: 100%;
  min-height: 215px;
  background: #fff;
  padding: 22px 20px;
  ${media.medium} {
    padding: 18px 20px;
  }
  & p.review_title {
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: #3b3b40;
  }
  & div.button_box {
    width: 200px;
    margin-top: 8px;
  }
`
const Review = styled.div`
  & div.star_content {
    display: flex;
    align-items: center;
    margin-top: 8px;
    & > div {
      margin-right: 5px;
    }
    & > div:last-of-type {
      margin-right: 0;
    }
  }
  & p.review_text {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #3b3b40;
    padding-top: 8px;
    overflow: hidden;
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    &.open {
      width: 100%;
      text-overflow: none;
      overflow: hidden;
      -webkit-line-clamp: 100;
    }
  }
  & span {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-decoration-line: underline;
    color: #3b3b40;
    cursor: pointer;
  }
`

const FeedBack = styled.div`
  margin-top: 22px;
  ${media.medium} {
    margin-top: 16px;
  }
  & p.no_feedback {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #b5b5b9;
    margin-top: 8px;
  }
`
