import useUser from 'lib/hooks/account/useUser'
import type { UserInfo } from 'lib/types/account'
import React, { useEffect } from 'react'
import StudentRootPage from './root/StudentRootPage'
import TutorRootPage from './root/TutorRootPage'
import { useRecoilState } from 'recoil'
import { PageTypeState } from 'atoms/common'

interface RootPageProps {
  user?: UserInfo | undefined
}

function Root({ user }: RootPageProps) {
  const [pageType, setPageType] = useRecoilState(PageTypeState)

  if (pageType === 'tutor') return <TutorRootPage />
  return <StudentRootPage />
}

export default Root
