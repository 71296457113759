import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import { ReactComponent as SendIcon } from 'assets/icons/send_icon.svg'
import { useChatActions } from '../provider/RoomChatProvider'

interface ChatInputProps {
  team?: 'A' | 'B'
}

function ChatInput({ team }: ChatInputProps) {
  const { sendMessageTrigger } = useChatActions()
  const [message, setMessage] = useState('')

  const handleChangeMessage: React.ChangeEventHandler<HTMLInputElement> = (
    e,
  ) => {
    setMessage(e.target.value)
  }

  const resetMessage = () => {
    setMessage('')
  }

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault()
    sendMessageTrigger({ team, message })
    resetMessage()
  }

  return (
    <Wrapper onSubmit={handleSubmit}>
      <input
        className="input"
        placeholder="채팅 내용을 입력해주세요."
        onChange={handleChangeMessage}
        value={message}
      />

      <button className="submit-button" type="submit">
        <SendIcon />
      </button>
    </Wrapper>
  )
}

const Wrapper = styled.form`
  position: relative;
  width: 100%;

  padding: 14px 20px;

  & .input {
    width: 100%;
    padding: 10px 14px;
    background: rgba(59, 59, 64, 0.8);
    border-radius: 8px;
    color: #fff;

    outline: none;
    border: 0;

    padding-right: 40px;

    &::placeholder {
      color: #7c7c80;
    }
  }

  & .submit-button {
    position: absolute;
    top: 20px;
    right: 28px;

    background: inherit;
    outline: none;
    box-shadow: none;
    border: 0;
    cursor: pointer;
  }
`

export default ChatInput
