import { atom } from 'recoil'
import { v1 } from 'uuid'

type RoomSettingType = {
  onConfirm?: (maxCount: number) => void
  onExit: () => void
  maxCount: number
} | null

export const roomSettingState = atom<RoomSettingType>({
  key: `roomSettingState/${v1()}`,
  default: null,
})

type RoomSettingViewModalType = {
  onViewPaticipant: () => void
  onViewSetting: () => void
} | null

export const roomSettingViewModalState = atom<RoomSettingViewModalType>({
  key: `roomSettingViewModalState/${v1()}`,
  default: null,
})

type TimeSettingModalType = {
  onSetting: (time: number) => void
  title?: string
  text?: string
  isSelectButton?: boolean
  width?: 'sm' | 'md' | 'lg'
} | null

export const timeSettingModalState = atom<TimeSettingModalType>({
  key: `timeSettingModalState/${v1()}`,
  default: null,
})

type EnterTeamRoomModalType = {
  onEnterSecret: () => void
  onEnter: () => void
} | null

export const enterTeamRoomModalState = atom<EnterTeamRoomModalType>({
  key: `enterTeamRoomModalState/${v1()}`,
  default: null,
})

type AudiencesModalType = {
  onSubmit: () => void
} | null

export const audiencesModalState = atom<AudiencesModalType>({
  key: `audiencesModalState/${v1()}`,
  default: null,
})

type VoteModalType = boolean

export const voteModalState = atom<VoteModalType>({
  key: `voteModalState/${v1()}`,
  default: false,
})

type ConfirmModalType = {
  onConfirm: () => void
  onCancel?: () => void
  title: string
  description?: string
  confirmText?: string
  canelText?: string
} | null

export const confirmModalState = atom<ConfirmModalType>({
  key: `confirmModalState/${v1()}`,
  default: null,
})

type InviteModalType = {
  id: number
  count: number
  onClose: () => void
  mutate: () => void
} | null

export const inviteModalState = atom<InviteModalType>({
  key: `inviteModalState/${v1()}`,
  default: null,
})

type CancleApplyModalType = {
  id?: number | string
  name: string
  profile: string
  reason?: string
  mutate: any
} | null

export const cancleApplyModalState = atom<CancleApplyModalType>({
  key: `cancleApplyModalState/${v1()}`,
  default: null,
})

type FileDownloadModalType = {
  title: string
  files: any[]
} | null

export const fileDownloadModalState = atom<FileDownloadModalType>({
  key: `fileDownloadModalState/${v1()}`,
  default: null,
})
