import { AppRootWrapper } from 'components/layout/CommonStyle'
import media from 'lib/styles/media'
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { ReactComponent as Icon } from '../../../assets/icons/mypage_edit_info_icon_password.svg'
import Button from '../Button'
import PasswordInput from '../PasswordInput'
import api from 'lib/api'
import CheckPopup from '../CheckPopup'
import { useHistory } from 'react-router-dom'

interface Props {
  pageType: 'user' | 'tutor'
}
function EditInfoComponent({ pageType }: Props) {
  const history = useHistory()
  const [password, setPassword] = useState('')
  const [popup, setPopup] = useState(false)
  const [title, setTitle] = useState('')
  const [contents, setContents] = useState('')

  useEffect(() => {
    if (popup) {
      document.documentElement.style.overflow = 'hidden'
    } else {
      document.documentElement.style.overflow = 'auto'
    }

    return () => {
      document.documentElement.style.overflow = 'auto'
    }
  }, [popup])

  const handleSubmit = async () => {
    const formData = new FormData()
    formData.append('password', password)

    const {
      data: { success, alert },
    } = await api.post('/password/check', formData)

    if (success) {
      pageType === 'tutor'
        ? history.push('/mypage/edit/myinfo?type=phone&role=tutor')
        : history.push('/mypage/edit/myinfo?type=phone')
    } else {
      if (alert === '비밀번호가 올바르지 않습니다.') {
        setPopup(true)
        setTitle('비밀번호 불일치')
        setContents(
          '비밀번호가 올바르지 않습니다.\n입력하신 내용을 다시 확인해주세요.',
        )
      } else {
        window.alert(alert)
      }
    }
  }

  const handleCheck = () => {
    setPopup(false)
  }
  return (
    <Wrapper>
      <Title>
        <p className="main_title">프로필 수정</p>
      </Title>
      <Content>
        <div className="title_box">
          <Icon />
          <p className="edit_info_text">비밀번호 확인 후 접근할 수 있습니다.</p>
        </div>
        <div className="content_box">
          <PasswordInput
            title="비밀번호"
            placeholder="비밀번호를 입력하세요"
            value={password}
            onChange={(e: any) => {
              setPassword(e.target.value)
            }}
            maxLength={20}
          />
        </div>
        <div className="button_box">
          <Button
            title="확인"
            disabled={password.length < 8}
            onClick={handleSubmit}
          />
        </div>
      </Content>
      {popup && (
        <CheckPopup onClick={handleCheck} title={title} content={contents} />
      )}
    </Wrapper>
  )
}

export default EditInfoComponent

const Wrapper = styled(AppRootWrapper)``
const Title = styled.div`
  ${media.medium} {
    display: none;
  }
  & p.main_title {
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: #0d0d0e;
    padding-top: 40px;
  }
`
const Content = styled.div`
  width: 440px;
  height: 680px;
  margin: 0 auto;
  padding: 50px 0 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${media.medium} {
    padding: 20px 0 80px;
    height: 100%;
    width: 100%;
  }
  & div.title_box {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  & p.edit_info_text {
    font-weight: 400;
    font-size: 13px;
    line-height: 22px;
    text-align: center;
    color: #7c7c80;
    margin-top: 16px;
  }
  & div.content_box {
    width: 380px;
    margin-top: 50px;
    ${media.medium} {
      width: 100%;
    }
  }
  & div.button_box {
    width: 380px;
    margin-top: 40px;
    ${media.medium} {
      width: 100%;
    }
  }
`
