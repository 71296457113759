import React from 'react'
import Profile from 'components/common/Profile'
import { type RoomSeatInfoType } from 'lib/types/room'
import styled from 'styled-components'
import { ReactComponent as MikeOnIcon } from 'assets/icons/mike_on_icon.svg'
import { ReactComponent as MikeOffIcon } from 'assets/icons/mike_off_icon.svg'
import { ReactComponent as CameraOnIcon } from 'assets/icons/camera_on_icon.svg'
import { ReactComponent as CameraOffIcon } from 'assets/icons/camera_off_icon.svg'
import { ReactComponent as SpeakerIcon } from 'assets/icons/speaker_icon.svg'
import { ReactComponent as SpeakerOffIcon } from 'assets/icons/speaker_off_icon.svg'

import IconWrap from 'components/common/IconWrap'
import {
  type BlurFocusUserType,
  type FocusUserType,
  type ModifyRoomInfoType,
} from '../provider/RoomInfoProvider'
import media from 'lib/styles/media'

interface ParticipantProps {
  info: RoomSeatInfoType
  modifyRoomInfo: ModifyRoomInfoType
  onFocusUser: FocusUserType
  onBlurFocusUser: BlurFocusUserType
  isFocus: boolean
  isDebating: boolean
}

function Participant({
  info,
  modifyRoomInfo,
  onFocusUser,
  isFocus,
  onBlurFocusUser,
  isDebating,
}: ParticipantProps) {
  const handleToggleCam = () => {
    modifyRoomInfo({
      isCam: info.is_camera !== 1,
      userId: info.user_id,
      isMike: info.is_mike === 1,
      tableNum: info.table_num,
    })
  }

  const handleToggleMike = () => {
    modifyRoomInfo({
      isCam: info.is_camera === 1,
      userId: info.user_id,
      isMike: info.is_mike !== 1,
      tableNum: info.table_num,
    })
  }

  const handleToggleFocusUser = () => {
    if (isFocus) {
      onBlurFocusUser()
    } else {
      onFocusUser(info.user_id)
    }
  }

  return (
    <Wrapper>
      <Profile
        name={info.nickname ?? info.name}
        profileUrl={info.profile_url}
        size="sm"
      />
      <div className="icons">
        {isDebating && (
          <IconWrap onClick={handleToggleFocusUser}>
            {isFocus ? <SpeakerIcon /> : <SpeakerOffIcon />}
          </IconWrap>
        )}
        <IconWrap onClick={handleToggleMike}>
          {info.is_mike === 1 ? <MikeOffIcon /> : <MikeOnIcon />}
        </IconWrap>
        <IconWrap onClick={handleToggleCam}>
          {info.is_camera === 1 ? <CameraOffIcon /> : <CameraOnIcon />}
        </IconWrap>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  height: 83px;

  ${media.medium} {
    height: 56px;
  }

  display: flex;
  justify-content: space-between;
  align-items: center;

  border-bottom: 1px solid rgba(59, 59, 64, 1);

  & .icons {
    display: flex;
    gap: 8px;

    ${media.medium} {
      gap: 10px;
    }

    align-items: center;
  }
`

export default Participant
