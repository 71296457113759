import { type MyClassListDataType } from 'atoms/join'
import media from 'lib/styles/media'
import {
  dateDiff,
  dateHyphenFormet,
  timeFormet,
} from 'lib/utils/CommonFuncttion'
import React from 'react'
import styled from 'styled-components'

interface MyClassInfoContentProps {
  info: MyClassListDataType | null
}

function MyClassInfoContent({ info }: MyClassInfoContentProps) {
  if (info === null) return null

  return (
    <Wrapper>
      <p className="content_title">강의 정보</p>
      <div className="info_content_box">
        {info?.image ? (
          <img src={info?.image} className="info_image" alt="image" />
        ) : (
          <Div />
        )}
        <div className="info_text_box">
          <p className="class_title">{info?.title}</p>
          {info?.stepInfo && (
            <p className="class_step_title">{info?.stepInfo.step_title}</p>
          )}
          <p className="class_nickname">{info?.nickname}</p>
          <p className="class_date">
            {dateHyphenFormet(info?.beginDate)} ~ {timeFormet(info?.endDate)}{' '}
            {info?.time
              ? `(${info?.time}분)`
              : dateDiff(info?.beginDate, info?.endDate)}
          </p>
        </div>
      </div>
    </Wrapper>
  )
}

export default MyClassInfoContent

const Wrapper = styled.div`
  margin-bottom: 40px;
  & p.content_title {
    font-weight: 500;
    font-size: 17px;
    line-height: 26px;
    color: #0d0d0e;
    ${media.medium} {
      font-size: 16px;
    }
  }
  & div.info_content_box {
    width: 100%;
    min-height: 158px;
    margin-top: 10px;
    background: #ffffff;
    border: 1px solid #e1e1e2;
    border-radius: 14px;
    padding: 20px;
    display: flex;
    align-items: center;
    ${media.medium} {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  & img.info_image {
    width: 210px;
    height: 118px;
    border-radius: 8px;
    ${media.medium} {
      width: 90px;
      height: 50px;
    }
  }
  & div.info_text_box {
    margin-left: 20px;
    ${media.medium} {
      margin-left: 0;
      margin-top: 12px;
    }
  }
  & p.class_title {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    color: #3b3b40;
    ${media.medium} {
      font-size: 14px;
      line-height: 24px;
    }
  }
  & p.class_step_title {
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    color: #3b3b40;
    margin-top: 4px;
  }
  & p.class_nickname {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #7c7c80;
    margin: 6px 0 10px;
    ${media.medium} {
      font-size: 13px;
      line-height: 22px;
      margin: 1px 0 4px;
    }
  }
  & p.class_date {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #0d0d0e;
    ${media.medium} {
      font-size: 13px;
      line-height: 22px;
    }
  }
`
const Div = styled.div`
  width: 210px;
  height: 118px;
  border-radius: 8px;
  background: rgb(249, 249, 251);
  ${media.medium} {
    width: 90px;
    height: 50px;
  }
`
