import media from 'lib/styles/media'
import React, { useState } from 'react'
import styled from 'styled-components'
import { ReactComponent as ArrowIcon } from 'assets/icons/left_arrow.svg'
import { ReactComponent as MikeOnIcon } from 'assets/icons/mike_on_icon.svg'
import { ReactComponent as MikeOffIcon } from 'assets/icons/mike_off_icon.svg'
import { ReactComponent as CameraOnIcon } from 'assets/icons/camera_on_icon.svg'
import { ReactComponent as CameraOffIcon } from 'assets/icons/camera_off_icon.svg'
import { ReactComponent as PeopleIcon } from 'assets/icons/people_icon.svg'
import { ReactComponent as ChatIcon } from 'assets/icons/chat_icon.svg'
import { ReactComponent as DocumentIcon } from 'assets/icons/document_icon.svg'
import { ReactComponent as PersonIcon } from 'assets/icons/person_icon.svg'
import { ReactComponent as PlayIcon } from 'assets/icons/play_icon.svg'
import { ReactComponent as ExitIcon } from 'assets/icons/exit_icon.svg'
import { ReactComponent as OnlineIcon } from 'assets/icons/online_icon.svg'

import FooterIconWrap from 'components/common/FooterIconWrap'
import {
  useRoomInfoAction,
  useRoomInfoValue,
} from '../provider/RoomInfoProvider'
import { type RoomSeatInfoType } from 'lib/types/room'
import { useHistory, useParams } from 'react-router-dom'
import TeamMeetingViewModal from 'components/modal/TeamMeetingViewModal'
import useRoom from 'lib/hooks/room/useRoom'

function TutorRoomFooter() {
  const { roomId }: { roomId: string } = useParams()
  const [open, setOpen] = useState(false)
  const { tutorRoomInfo } = useRoomInfoValue()
  const { modifyTutorInfo } = useRoomInfoAction()
  const history = useHistory()
  const [isTeamMettingViewModal, setIsTeamMettingViewModal] = useState(false)
  const { debateStatus, modifyDebateStatusTrigger, exitRoomTrigger } =
    useRoom(roomId)

  const onToggle = () => {
    setOpen(!open)
  }

  const handleToggleCamera = () => {
    const tutorRoomInfoData = tutorRoomInfo as RoomSeatInfoType

    modifyTutorInfo &&
      modifyTutorInfo({
        isCam: tutorRoomInfoData.is_camera !== 1,
        isMike: tutorRoomInfoData.is_mike === 1,
      })
  }

  const handleToggleMike = () => {
    const tutorRoomInfoData = tutorRoomInfo as RoomSeatInfoType

    modifyTutorInfo &&
      modifyTutorInfo({
        isCam: tutorRoomInfoData.is_camera === 1,
        isMike: tutorRoomInfoData.is_mike !== 1,
      })
  }

  const handleClickviewParticipantList = () => {
    history.push(`/room/${roomId}/participants`)
  }

  const handleClickChat = () => {
    history.push(`/room/${roomId}/chat`)
  }

  const handleClickReference = () => {
    history.push(`/room/${roomId}/reference`)
  }

  const handleClickTeamRoomButton = () => {
    setIsTeamMettingViewModal(true)
  }

  const handleClose = () => {
    setIsTeamMettingViewModal(false)
  }

  const modifyDebateStatus = () => {
    const status = debateStatus === 6 ? 0 : 6

    modifyDebateStatusTrigger(status)
  }

  return (
    <RoomFooterWrapper>
      <div className="arrow-wrap" onClick={onToggle}>
        <ArrowIcon className={`arrow ${open ? 'arrow-down' : 'arrow-up'}`} />
      </div>
      {open && (
        <div className="buttons">
          <FooterIconWrap
            Icon={debateStatus === 6 ? PlayIcon : OnlineIcon}
            label={debateStatus === 6 ? '디베이팅' : '온라인교실'}
            onClick={modifyDebateStatus}
          />
          {debateStatus !== 6 && (
            <FooterIconWrap
              Icon={ExitIcon}
              label="강의종료"
              onClick={exitRoomTrigger}
            />
          )}

          <FooterIconWrap
            Icon={tutorRoomInfo?.is_camera === 1 ? CameraOffIcon : CameraOnIcon}
            label={tutorRoomInfo?.is_camera === 1 ? '화면 off' : '화면 on'}
            onClick={handleToggleCamera}
          />
          <FooterIconWrap
            Icon={tutorRoomInfo?.is_mike === 1 ? MikeOffIcon : MikeOnIcon}
            label={tutorRoomInfo?.is_mike === 1 ? '마이크 on' : '마이크 off'}
            onClick={handleToggleMike}
          />
          <FooterIconWrap
            Icon={PeopleIcon}
            label="소회의방"
            onClick={handleClickTeamRoomButton}
          />
          <FooterIconWrap
            Icon={ChatIcon}
            label="채팅"
            onClick={handleClickChat}
          />
          <FooterIconWrap
            Icon={DocumentIcon}
            label="자료"
            onClick={handleClickReference}
          />
          <FooterIconWrap
            Icon={PersonIcon}
            label="참가자"
            onClick={handleClickviewParticipantList}
          />
        </div>
      )}

      {isTeamMettingViewModal && (
        <TeamMeetingViewModal
          visible={isTeamMettingViewModal}
          onClose={handleClose}
        />
      )}
    </RoomFooterWrapper>
  )
}

export const RoomFooterWrapper = styled.div`
  @media (min-width: 1400px) {
    display: none;
  }

  ${media.xlarge} {
    position: fixed;

    width: 100%;
    border-radius: 16px 16px 0px 0px;

    bottom: 0;

    & .arrow-wrap {
      height: 40px;
      display: flex;
      justify-content: center;

      border-radius: 16px 16px 0px 0px;
      background: rgba(59, 59, 64, 1);

      cursor: pointer;
    }

    & .arrow {
      margin-top: 10px;
    }

    & .arrow-down {
      transform: rotate(-90deg);
    }

    & .arrow-up {
      transform: rotate(90deg);
    }

    & .buttons {
      display: flex;
      gap: 16px;

      overflow-x: scroll;

      background: rgba(59, 59, 64, 1);
      height: 54px;
      padding: 0 10px;
      align-items: flex-start;
    }

    & .divide {
      width: 1px;
      height: 30px;

      background: #7c7c80;
    }
  }
`

export default TutorRoomFooter
