import { confirmModalState } from 'atoms/modal'
import React from 'react'
import { useRecoilState } from 'recoil'
import styled from 'styled-components'
import ModalWrapper from './ModalWrapper'
import { ReactComponent as CloseIcon } from 'assets/icons/close_icon.svg'

function ConfirmModal() {
  const [modal, setModal] = useRecoilState(confirmModalState)

  const handleClose = () => {
    setModal(null)
  }

  const handleCancel = () => {
    modal?.onCancel && modal?.onCancel()
    handleClose()
  }

  const handleConfirm = () => {
    modal?.onConfirm && modal?.onConfirm()
    handleClose()
  }

  if (!modal) return null

  return (
    <ModalWrapper onClose={handleClose}>
      <Wrapper>
        <div className="header">
          <h4 className="title">{modal.title}</h4>
          <CloseIcon className="close-icon" onClick={handleClose} />
        </div>
        <div className="content">
          <p className="description">{modal.description}</p>
        </div>
        <div className="footer">
          <button className="button text" onClick={handleCancel}>
            {modal?.canelText ?? '아니오'}
          </button>
          <button className="button contained" onClick={handleConfirm}>
            {modal?.confirmText ?? '예'}
          </button>
        </div>
      </Wrapper>
    </ModalWrapper>
  )
}

const Wrapper = styled.div`
  background: #fff;

  padding: 22px;
  border-radius: 14px;

  min-width: 320px;

  & .header {
    display: flex;
    justify-content: space-between;

    & .title {
      font-weight: 700;
      font-size: 18px;
      color: #000000;
    }

    & .close-icon {
      cursor: pointer;
    }
  }

  & .content {
    margin-top: 14px;
  }

  & .description {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #3b3b40;
  }
  & .footer {
    margin-top: 32px;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }

  & .button {
    width: 82px;
    height: 34px;
    outline: none;
    border-radius: 100px;
    padding: 5px 10px;
    border: 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    cursor: pointer;
  }
  & .button.text {
    background: inherit;
    color: #7c7c80;

    &:hover {
      background: rgba(0, 0, 0, 0.04);
    }
  }
  & .button.contained {
    background: #57569e;
    color: #fff;

    &:hover {
      background: rgba(54, 54, 118, 1);
    }
  }
`

export default ConfirmModal
