import { type TutorRoomInfoType } from 'lib/types/room'
import React from 'react'
import styled from 'styled-components'
import { ReactComponent as BasicIcon } from 'assets/icons/basic_icon.svg'
import Video from '../common/Video'

interface DebateTutorProps {
  tutor: TutorRoomInfoType
}

function DebateTutor({ tutor }: DebateTutorProps) {
  console.log('여기서 호출?', tutor)
  if (!tutor) {
    return (
      <Wrapper>
        <div className="content">
          <div className="wrapper">
            <div className="profile">
              <BasicIcon />
            </div>
          </div>
        </div>
      </Wrapper>
    )
  }

  if (!tutor.stream || tutor.is_camera !== 1) {
    return (
      <Wrapper>
        <div className="profile">
          {tutor?.profile_url ? (
            <img
              src={tutor?.profile_url}
              alt="profile"
              className="profile-image"
            />
          ) : (
            <BasicIcon className="basic-icon" />
          )}
        </div>
        <div className="nickname">{tutor.nickname}</div>
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <div className="profile">
        <Video stream={tutor.stream} test={tutor} />
      </div>

      <div className="nickname">{tutor.nickname}</div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.4);
  border-radius: 6px;
  width: 100%;
  aspect-ratio: 1000 / 594;
  background: #3b3b40;

  & .profile {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & .profile-image {
    width: calc(100% / 3.02);
    aspect-ratio: 1 /1;
    border-radius: 50%;
    object-fit: cover;
  }

  & .nickname {
    position: absolute;
    right: 10px;
    bottom: 10px;
    border-radius: 6px;
    padding: 10px 12px;
    background: rgba(13, 13, 14, 0.6);
    color: #fff;
  }
`

export default DebateTutor
