import { useCallback, useEffect, useRef, useState } from 'react'

interface UseScrollProps {
  fetchMore?: (page: number) => Promise<void>
  page?: number
  lastPage?: number
}

function useScroll({ fetchMore, page, lastPage }: UseScrollProps) {
  const [target, setTarget] = useState<HTMLElement | null>(null)
  const last = useRef(0)

  const onFetchMore = useCallback(async () => {
    if (!page || !lastPage || !fetchMore) return
    if (page === lastPage) return
    if (last.current === page) return

    last.current = page
    await fetchMore(page + 1)

    if (page === 1) last.current = 0
  }, [page, lastPage, fetchMore])

  const callback: IntersectionObserverCallback = useCallback(
    ([entry]: IntersectionObserverEntry[]) => {
      if (!entry.isIntersecting) return
      onFetchMore()
    },
    [onFetchMore],
  )

  useEffect(() => {
    let observer: any
    if (target) {
      observer = new IntersectionObserver(callback)
      observer.observe(target)
    }
    return () => observer && observer.unobserve(target)
  }, [target, callback])

  return { target: setTarget }
}

export default useScroll
