import Footer from 'components/common/Footer'
import EditInfoComponent from 'components/common/editinfo/EditInfoComponent'
import Header from 'components/common/header/Header'
import MobileTitleHeader from 'components/common/header/MobileTitleHeader'
import media from 'lib/styles/media'
import React, { useEffect } from 'react'
import styled from 'styled-components'
import TutorHeader from 'components/common/header/TutorHeader'
import useUser from 'lib/hooks/account/useUser'

function EditInfoPage() {
  const { user } = useUser()
  const typeUser = user && user.type

  return (
    <Wrapper>
      {typeUser === 'tutor' ? <TutorHeader /> : <Header />}
      <MobileTitleHeader
        type="arrow"
        title={typeUser === 'tutor' ? '튜터 정보 수정' : '프로필 수정'}
      />
      <Content>
        <EditInfoComponent pageType={typeUser} />
      </Content>
      <Footer />
    </Wrapper>
  )
}
export default EditInfoPage

const Wrapper = styled.div``
const Content = styled.div`
  margin-top: 100px;
  ${media.medium} {
    margin-top: 0;
  }
`
