import { type DetailIntroduceType } from 'lib/api/tutor'
import media from 'lib/styles/media'
import React from 'react'
import styled from 'styled-components'

interface TutorIntroDetailProps {
  detailIntro: DetailIntroduceType
  type: 'multi' | ''
}

function TutorIntroDetail({ detailIntro, type }: TutorIntroDetailProps) {
  return (
    <Wrapper>
      {type === 'multi' && <div className="title">{detailIntro.title}</div>}
      <div
        dangerouslySetInnerHTML={{ __html: detailIntro.contents }}
        className="content"
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: start;

  & > .title {
    width: 214px;
    min-width: 214px;
    font-weight: 500;
    font-size: 17px;
    line-height: 1.53;
    color: #3b3b40;
    margin-right: 20px;
    word-break: break-all;
  }

  & > .content {
    width: 100%;
  }

  ${media.medium} {
    display: block;

    & > .title {
      width: 100%;
      margin-right: 0;
      margin-bottom: 20px;
    }
  }
`

export default TutorIntroDetail
