import Footer from 'components/common/Footer'
import Header from 'components/common/header/Header'
import MobileTitleHeader from 'components/common/header/MobileTitleHeader'
import MyClassReviewComponent from 'components/common/myclass/MyClassReviewComponent'
import { CommonContentWrapper } from 'components/layout/CommonStyle'
import React from 'react'
import styled from 'styled-components'

function MyClassReviewPage() {
  return (
    <Wrapper>
      <Header />
      <MobileTitleHeader type="arrow" />
      <CommonContentWrapper>
        <MyClassReviewComponent />
      </CommonContentWrapper>
      <Footer type="none" />
    </Wrapper>
  )
}

export default MyClassReviewPage
const Wrapper = styled.div``
