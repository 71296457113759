import React, { useRef, useState, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import Button from '../Button'
import EditorContent from '../EditorContent'
import media from 'lib/styles/media'
import { useRecoilState } from 'recoil'
import {
  TutorEditInfoPayloadState,
  TutorRegisterClassPayloadState2,
} from 'atoms/join'

interface Props {
  Tab: Array<{ title: string; text: string }>
  menu: string
  handleTabClick: (text: string) => void
  onhandleChange: (value: string, index: number) => void
  onDelete?: (number: number) => void
  onTitlehandleChange: (value: string, index: number) => void
  mode: 'add' | 'edit'
  type: 'class' | 'info'
}
function TutorDetailInfoContent({
  Tab,
  menu,
  handleTabClick,
  onhandleChange,
  onTitlehandleChange,
  mode,
  type,
}: Props) {
  // type -> info:회원가입 및 프로필 수정 / class:강의등록 및 수정
  // ispayload : 강의등록 및 수정 / payload : 회원가입 및 프로필 수정
  const [isPayload, setIsPayload] = useRecoilState(
    TutorRegisterClassPayloadState2(mode),
  )
  const [payload, setPayload] = useRecoilState(TutorEditInfoPayloadState(mode))
  const [blur, setBlur] = useState(false)

  const count = useMemo(() => {
    if (type === 'info') {
      return payload?.detailIntroduce.length ?? 1
    } else {
      return isPayload?.detailIntroduce.length ?? 1
    }
  }, [type, payload?.detailIntroduce, isPayload?.detailIntroduce])

  const onAdd = () => {
    if (type === 'info') {
      if (payload?.detailIntroduce && payload?.detailIntroduce.length > 4)
        return
    } else {
      if (isPayload?.detailIntroduce && isPayload?.detailIntroduce.length > 4)
        return
    }

    if (type === 'info') {
      const index =
        !payload?.detailIntroduce ||
        (payload?.detailIntroduce ?? [])?.length === 0
          ? 1
          : payload?.detailIntroduce?.slice(-1)[0]?.index + 1
      setPayload({
        ...payload,
        detailIntroduce: payload?.detailIntroduce?.concat({
          index: index,
          title: '',
          contents: '',
          id: '',
        }),
      })
    } else {
      const isIndex =
        !isPayload?.detailIntroduce ||
        (isPayload?.detailIntroduce ?? [])?.length === 0
          ? 1
          : isPayload?.detailIntroduce?.slice(-1)[0]?.index + 1

      setIsPayload({
        ...isPayload,
        detailIntroduce: isPayload?.detailIntroduce?.concat({
          index: isIndex,
          title: '',
          contents: '',
          id: '',
        }),
      })
    }
  }

  const onRemove = (number: number, id: any) => {
    if (type === 'info') {
      setPayload({
        ...payload,
        detailIntroduce: payload?.detailIntroduce?.filter(
          (item) => item.index !== number,
        ),
      })
    } else {
      setIsPayload({
        ...isPayload,
        detailIntroduce: isPayload?.detailIntroduce?.filter(
          (item) => item.index !== number,
        ),
      })
    }
  }

  const handleChange = (index: number) => (value: string) => {
    onhandleChange(value, index)
  }
  const handleTitleChange = (index: number) => (value: string) => {
    onTitlehandleChange(value, index)
  }

  return (
    <Wrapper>
      <Title>상세 소개</Title>
      <TabBox>
        {Tab.map((item, i) => (
          <div
            key={`tutor_detail_info_tab_${i}`}
            className={
              item.text === menu ? 'tab_menu_box active' : 'tab_menu_box'
            }
            onClick={() => {
              if (type === 'info') {
                setPayload({
                  ...payload,
                  detailIntroduce: [
                    { index: 1, title: '', contents: '', id: '' },
                  ],
                })
              } else {
                setIsPayload({
                  ...isPayload,
                  detailIntroduce: [
                    { index: 1, title: '', contents: '', id: '' },
                  ],
                })
              }
              setBlur(true)
              handleTabClick(item.title)
            }}
          >
            <p>{item.title}</p>
          </div>
        ))}
      </TabBox>
      <Content>
        {type === 'info' ? (
          <>
            {payload?.detailIntroduce?.map((info) => (
              <EditorContent
                key={info.index}
                close={info.index !== 1}
                handleClose={() => onRemove(info.index, info.id)}
                handleChange={handleChange(info.index)}
                handleTitleChange={handleTitleChange(info.index)}
                defaultValue={info}
                menu={menu}
              />
            ))}
          </>
        ) : (
          <>
            {isPayload?.detailIntroduce?.map((info) => (
              <EditorContent
                key={info.index}
                close={info.index !== 1}
                handleClose={() => onRemove(info.index, info.id)}
                handleChange={handleChange(info.index)}
                handleTitleChange={handleTitleChange(info.index)}
                defaultValue={info}
                menu={menu}
                blur={blur}
              />
            ))}
          </>
        )}
      </Content>
      {menu === 'multi' && count < 5 && (
        <ButtonBox>
          <Button title="추가" className="border" onClick={onAdd} />
        </ButtonBox>
      )}
    </Wrapper>
  )
}

export default TutorDetailInfoContent
const Wrapper = styled.div`
  margin-top: 40px;
`
const Title = styled.p`
  font-weight: 500;
  font-size: 17px;
  line-height: 26px;
  color: #0d0d0e;
  ${media.medium} {
    font-size: 16px;
  }
`
const TabBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  & div.tab_menu_box {
    width: calc(50% - 5px);
    background: #ffffff;
    border: 1px solid #e1e1e2;
    border-radius: 50px;
    padding: 13px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    & > p {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: #0d0d0e;
      cursor: pointer;
    }
    &.active {
      border: 1px solid #0d0d0e;
    }
  }
`
const Content = styled.div`
  margin-top: 30px;
  ${media.medium} {
    margin-top: 20px;
  }
`
const ButtonBox = styled.div`
  margin-top: 30px;
`
