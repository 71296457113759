import { AppRootWrapper } from 'components/layout/CommonStyle'
import media from 'lib/styles/media'
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Button from '../Button'
import Input from '../Input'
import SmsInput from '../SmsInput'
import api from 'lib/api'
import CheckPopup from '../CheckPopup'
import {
  Error0001,
  Error0002,
  Error0003,
  Error0004,
  ErrorFindId,
} from 'lib/utils/CommonFuncttion'
import { useHistory } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { FindPasswordPayloadState } from 'atoms/join'

interface Props {
  pageType: string
}
function FindPwComponent({ pageType }: Props) {
  const [payload, setPayload] = useRecoilState(FindPasswordPayloadState)
  const [btnText, setBtnText] = useState('인증')
  const [isSendCode, setIsSendCode] = useState(false)
  const [isCodeCheck, setIsCodeCheck] = useState(false)
  const [popup, setPopup] = useState(false)
  const [title, setTitle] = useState('')
  const [contents, setContents] = useState('')
  const history = useHistory()

  useEffect(() => {
    if (popup) {
      document.documentElement.style.overflow = 'hidden'
    } else {
      document.documentElement.style.overflow = 'auto'
    }

    return () => {
      document.documentElement.style.overflow = 'auto'
    }
  }, [popup])

  const handleCodeError = (code?: string) => {
    if (code) {
      if (code === 'DB0001') {
        setPopup(true)
        setTitle('이미 가입된 휴대폰 번호')
        setContents(Error0001)
      } else if (code === 'DB0002') {
        setPopup(true)
        setTitle('탈퇴 회원')
        setContents(Error0002)
      } else if (code === 'DB0002') {
        setPopup(true)
        setTitle('강제 탈퇴 회원')
        setContents(Error0003)
      } else {
        setPopup(true)
        setTitle('인증번호 발송 횟수 초과')
        setContents(Error0004)
      }
    } else {
      setIsCodeCheck(true)
    }
  }

  const handleChange = (type: string) => (e: any) => {
    setPayload({
      ...payload,
      [type]: e.target.value,
    })
  }

  const handlePhoneChange = (type: string) => (e: any) => {
    setPayload({
      ...payload,
      [type]: e.target.value.replace(/[^0-9]/g, ''),
    })
  }

  const handleCheckInfo = async () => {
    const formData = new FormData()
    formData.append('type', pageType === 'tutor' ? 'tutor' : 'user')
    formData.append('phone', String(payload?.phone))
    formData.append('email', String(payload?.email))

    const {
      data: { success, alert },
    } = await api.post('/find/password', formData)

    if (success) {
      handleSubmit()
    } else {
      setPopup(true)
      setTitle('가입 계정 조회 불가')
      setContents(ErrorFindId)
    }
  }
  const handleSendSms = async () => {
    if (payload?.phone && String(payload?.phone).length >= 10) {
      const formData = new FormData()
      formData.append('type', pageType === 'tutor' ? 'tutor' : 'user')
      formData.append('phone', String(payload?.phone))
      const {
        data: { success, alert, code },
      } = await api.post('/sms/certification', formData)
      if (success) {
        setBtnText('재발송')
        setIsSendCode(true)
      } else {
        handleCodeError(code)
      }
    }
  }

  const handleSubmit = async () => {
    const fd = new FormData()
    fd.append('type', pageType === 'tutor' ? 'tutor' : 'user')
    fd.append('phone', String(payload?.phone))
    fd.append('code', String(payload?.code))

    const {
      data: { success, alert, code },
    } = await api.post('/sms/certification/check', fd)

    if (success) {
      pageType === 'tutor'
        ? history.push('/find/password/edit?role=tutor')
        : history.push('/find/password/edit')
    } else {
      handleCodeError(code)
    }
  }
  const handleCheck = () => {
    setPopup(false)
  }
  return (
    <Wrapper>
      <Content>
        <div className="title_box">
          <p className="find_info_title">비밀번호 찾기</p>
          <p className="find_info_text">
            비밀번호를 찾기 위해 아이디와 휴대폰 번호를 확인해주세요.
          </p>
        </div>
        <div className="find_content_box">
          <Input
            type="text"
            title="아이디"
            placeholder="영문, 숫자 포함 8-20자"
            value={payload?.email}
            onChange={handleChange('email')}
            maxLength={20}
          />
          <SmsInput
            title="휴대폰 번호"
            placeholder="‘-’ 제외 휴대폰 번호 입력"
            phone={payload?.phone}
            onPhoneChange={handlePhoneChange('phone')}
            btnText={btnText}
            handleSendSms={handleSendSms}
            codeInput={isSendCode}
            code={payload?.code}
            onCodeChange={handlePhoneChange('code')}
            isCodeCheck={isCodeCheck}
            type="find"
          />
        </div>
        <div className="find_button_box">
          <Button
            title="완료"
            onClick={handleCheckInfo}
            disabled={
              String(payload?.email).length < 8 ||
              String(payload?.code).length !== 6
            }
          />
        </div>
      </Content>
      {popup && (
        <CheckPopup onClick={handleCheck} title={title} content={contents} />
      )}
    </Wrapper>
  )
}

export default FindPwComponent
const Wrapper = styled(AppRootWrapper)``
const Content = styled.div`
  width: 440px;
  height: 680px;
  margin: 0 auto;
  padding: 80px 0 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${media.medium} {
    padding: 20px 0 80px;
    height: 100%;
    width: 100%;
  }

  & p.find_info_title {
    font-weight: 700;
    font-size: 22px;
    line-height: 34px;
    text-align: center;
    color: #0d0d0e;
    ${media.medium} {
      font-size: 18px;
      line-height: 28px;
    }
  }
  & p.find_info_text {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #3b3b40;
    margin-top: 6px;
  }
  & div.find_content_box {
    width: 380px;
    margin-top: 50px;
    ${media.medium} {
      width: 100%;
    }
    & > div:first-of-type {
      margin-bottom: 20px;
    }
  }
  & div.find_button_box {
    width: 380px;
    margin-top: 40px;
    ${media.medium} {
      width: 100%;
    }
  }
`
