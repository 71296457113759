import React, { createContext, useContext, useEffect, useState } from 'react'
import * as io from 'socket.io-client'
import Echo from 'laravel-echo/dist/echo'
import PageLoadingComponent from 'components/common/PageLoadingComponent'

const SocketContext = createContext<any>(null)

export const useRoomSocket = () => {
  const socket = useContext(SocketContext)
  return socket
}

const RoomSocketProvider = ({
  children,
  roomId,
}: {
  children: React.ReactNode
  roomId: string
}) => {
  const [socket, setSocket] = useState<any>(null)

  useEffect(() => {
    const echo = new Echo({
      host: 'https://api.debateon.fifteenh.io:6003',
      broadcaster: 'socket.io',
      client: io,
    })

    setSocket(echo.channel(`laravel_database_chat${roomId}`))

    return () => {
      echo.leaveChannel(`laravel_database_chat${roomId}`)
      echo.disconnect()
    }
  }, [])

  if (socket === null) return <PageLoadingComponent />

  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  )
}

export default RoomSocketProvider
