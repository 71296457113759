import React from 'react'
import useSWR from 'swr'

interface Props {
  page: number
  type: string
}
function useNotice({ page, type }: Props) {
  return useSWR(`/notice?page=${page}&type=${type}`)
}

export default useNotice
