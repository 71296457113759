import Footer from 'components/common/Footer'
import Header from 'components/common/header/Header'
import MobileTitleHeader from 'components/common/header/MobileTitleHeader'
import PageLoadingComponent from 'components/common/PageLoadingComponent'
import { CommonContentWrapper } from 'components/layout/CommonStyle'
import ClassCompleteContainer from 'containers/class/ClassCompleteContainer'
import useClassInfo from 'containers/class/hooks/useClassInfo'
import React from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

function ClassCompletePage() {
  const { classId }: { classId: string } = useParams()

  const { data, isLoading } = useClassInfo(classId)
  if (isLoading) return <PageLoadingComponent />
  if (!data) return <div></div>
  return (
    <Wrapper>
      <Header />
      <MobileTitleHeader type="arrow" title="강의 결제" />
      <CommonContentWrapper>
        <ClassCompleteContainer data={data} />
      </CommonContentWrapper>
      <Footer />
    </Wrapper>
  )
}

const Wrapper = styled.div``

export default ClassCompletePage
