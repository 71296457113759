import React, { useRef } from 'react'
import styled from 'styled-components'
import { ReactComponent as UserIcon } from '../../../assets/icons/myinfo_use_icon.svg'
import { ReactComponent as ProfileIcon } from '../../../assets/icons/edit_myinfo_profile_icon.svg'
import media from 'lib/styles/media'

interface Props {
  data: string | undefined
  onChange?: (file: any, result: File) => void
  preview?: File | undefined
}
function EditProfileInput({ data, onChange, preview }: Props) {
  const ref = useRef<HTMLInputElement>(null)
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = async (
    e,
  ) => {
    const files = e.target.files
    const file: any = files && files[0]
    const result: any = URL.createObjectURL(file)
    onChange && onChange(file, result)
  }
  const handleClick = () => {
    if (!ref.current) return
    ref.current.click()
  }
  return (
    <Wrapper onClick={handleClick}>
      {preview ?? data ? (
        <img src={preview ? String(preview) : data} className="profile_icon" />
      ) : (
        <UserIcon className="profile_icon" />
      )}

      <ProfileIcon className="profile_edit_icon" />
      <input ref={ref} type="file" accept="image/*" onChange={handleChange} />
    </Wrapper>
  )
}

export default EditProfileInput

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  & .profile_icon {
    width: 85px;
    height: 85px;
    max-width: 85px;
    max-height: 85px;
    border-radius: 100%;
    object-fit: cover;
    ${media.medium} {
      width: 80px;
      height: 80px;
    }
  }
  & svg.profile_edit_icon {
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(50%);
  }
  & input {
    display: none;
  }
`
