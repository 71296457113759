import media from 'lib/styles/media'
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { ReactComponent as Icon } from '../../../assets/icons/tutor_register_show_icon.svg'
import Tooltip from '../Tooltip'
import { Switch } from 'antd'
import { PriceNum } from 'lib/utils/CommonFuncttion'

interface Props {
  value: string | undefined
  onChange: (value: any) => void
  title: string
  placeholder: string
  maxLength?: number
  type?: string
  content?: string
  free?: number
  checked?: boolean
  handleChange?: (checked: boolean) => void
  disabled?: any
  error?: boolean
  step?: number
  multi?: number
}
function TutorInputContent({
  value,
  onChange,
  title,
  placeholder,
  maxLength,
  type,
  content,
  free,
  checked,
  handleChange,
  disabled,
  error,
  step,
  multi,
}: Props) {
  // multi 1:다회차 0:단일회차
  const [popup, setPopup] = useState(false)
  const [prePrice, setPrePrice] = useState('')

  const price = value
    ? String(value).includes(',')
      ? value.replaceAll(',', '')
      : value
    : 0
  const stepNum = step ?? 1
  const totalPrice = Number(price) * stepNum

  useEffect(() => {
    if (content !== 'price') return
    if (!value) return
    setPrePrice(PriceNum(value))
  }, [content, value])

  useEffect(() => {
    if (popup) {
      setTimeout(() => {
        setPopup(false)
      }, 3000)
    }
  }, [popup])

  const handleShowClick = () => {
    setPopup(true)
  }
  const handleTypeChange = (e: any) => {
    if (title === '수강료') {
      changeEnteredNum(e)
    } else {
      onChange(e)
    }
  }
  const changeEnteredNum = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value: string = e.target.value
    const removedCommaValue: number = Number(value.replaceAll(',', ''))
    if (Number.isNaN(removedCommaValue)) return
    onChange(removedCommaValue.toLocaleString())
  }

  return (
    <Wrapper>
      <Title>
        <p>{title}</p>
        {content === 'price' && <Icon onClick={handleShowClick} />}
        {popup && <Tooltip type="price" />}
      </Title>
      <Content>
        <input
          type={type ? type : 'text'}
          placeholder={placeholder}
          value={prePrice ? prePrice : value ?? ''}
          // onChange={onChange}
          onChange={handleTypeChange}
          maxLength={maxLength}
          disabled={disabled}
        />
        {content === 'price' && <p className="price_content">원</p>}
      </Content>
      {error && (
        <p className="price_error_content">
          수강료는 1,000원 이상으로 설정해주세요
        </p>
      )}
      {content === 'price' && (
        <BottomBox>
          <ButtonBox>
            <Switch checked={checked} onChange={handleChange} />
            <p className="price_text">무료 강의로 설정</p>
          </ButtonBox>
          {multi === 1 && (
            <PriceBox>
              <p>총 {PriceNum(totalPrice)}원</p>
            </PriceBox>
          )}
        </BottomBox>
      )}
    </Wrapper>
  )
}

export default TutorInputContent
const Wrapper = styled.div`
  margin-top: 40px;
  & p.price_error_content {
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    color: #ff2c2c;
    margin-top: 4px;
  }
`
const Title = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  & > p {
    font-weight: 500;
    font-size: 17px;
    line-height: 26px;
    color: #0d0d0e;
    ${media.medium} {
      font-size: 16px;
    }
  }
  & > svg {
    margin-left: 4px;
  }
`
const Content = styled.div`
  margin-top: 10px;
  position: relative;
  & input {
    width: 100%;
    background: #ffffff;
    border: 1px solid #e1e1e2;
    border-radius: 8px;
    padding: 11px 14px;
    outline: none;
    &::placeholder {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: #b5b5b9;
    }
    &:disabled {
      background: #f9f9fb;
    }
  }
  & p.price_content {
    position: absolute;
    right: 14px;
    top: 50%;
    z-index: 2;
    transform: translateY(-50%);
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-align: right;
    color: #b5b5b9;
  }
`
const ButtonBox = styled.div`
  display: flex;
  align-items: center;
  & p.price_text {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #3b3b40;
    margin-left: 10px;
  }
  & .ant-switch.ant-switch-checked {
    background: #7574d8;
  }
  & .ant-switch.ant-switch-checked:hover {
    background: #7574d8;
  }
  & .ant-switch {
    background: #e1e1e2;
  }
  & .ant-switch:hover {
    background: #e1e1e2;
  }
  & .ant-switch {
    ${media.medium} {
      min-width: 34px;
      min-height: 20px;
      height: 20px;
    }
  }
  & .ant-switch-handle {
    ${media.medium} {
      width: 14px;
      height: 14px;
      top: 3px;
    }
  }
`
const BottomBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
`
const PriceBox = styled.div`
  & > p {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    text-align: right;
    color: #3b3b40;
  }
`
