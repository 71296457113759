import Footer from 'components/common/Footer'
import Header from 'components/common/header/Header'
import MobileTitleHeader from 'components/common/header/MobileTitleHeader'
import InfoBasicComponent from 'components/common/infobasic/InfoBasicComponent'
import media from 'lib/styles/media'
import React from 'react'
import styled from 'styled-components'

function StudentJoininfoBasic() {
  return (
    <Wrapper>
      <Header />
      <MobileTitleHeader type="arrow" title="기본 정보 설정" />
      <Contents>
        <InfoBasicComponent />
      </Contents>
      <Footer />
    </Wrapper>
  )
}

export default StudentJoininfoBasic
const Wrapper = styled.div``
const Contents = styled.div`
  margin-top: 100px;
  ${media.medium} {
    margin-top: 0;
  }
`
