import media from 'lib/styles/media'
import React from 'react'
import styled from 'styled-components'

interface IconWrapProps {
  children: React.ReactNode
  onClick?: () => void
}

function IconWrap({ children, onClick }: IconWrapProps) {
  return <Wrapper onClick={onClick}>{children}</Wrapper>
}

const Wrapper = styled.div`
  ${media.medium} {
    width: 36px;
    height: 36px;
    border-radius: 6px;

    & > svg {
      width: 20px;
      height: 20px;
    }
  }

  ${media.pc} {
    padding: 10px;
    max-height: 42px;
    border-radius: 8px;
  }

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  background: rgba(59, 59, 64, 0.8);

  &:hover {
    opacity: 0.9;
  }

  &:active {
    opacity: 0.8;
  }
`

export default IconWrap
