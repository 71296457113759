import React, { useState } from 'react'
import styled from 'styled-components'

interface Props {
  placeholder: string
  value: string | undefined
  onChange: (e: any) => void
  maxLength?: number
  type?: string
}
function Textarea({ placeholder, value, maxLength, onChange, type }: Props) {
  return (
    <Wrapper className={type === 'review' ? 'review' : ''}>
      <textarea
        className="textarea"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        maxLength={maxLength ? maxLength : 100}
      />
      <div className="value_content">
        <p className="value_text">
          {value ? value.length : value === '' && 0}/
          {maxLength ? maxLength : '100'} 자
        </p>
      </div>
    </Wrapper>
  )
}

export default Textarea

const Wrapper = styled.div`
  width: 328px;
  height: 200px;
  background: #fff;
  border: 1px solid #e1e1e2;
  border-radius: 14px;
  position: relative;
  &.review {
    width: 100%;
  }
  & .textarea {
    width: 100%;
    height: calc(100% - 40px);
    padding: 14px 14px 0;
    border-radius: 14px;
    outline: none;
    resize: none;
    padding: 14px;
    border: none;

    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #0d0d0e;
    &::placeholder {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #b5b5b9;
    }
  }
  & .value_content {
    position: absolute;
    right: 14px;
    bottom: 14px;
  }
  & .value_text {
    font-weight: 400;
    font-size: 14px;
    text-align: right;
    line-height: 24px;
    color: #b5b5b9;
  }
`
