import useRoom from 'lib/hooks/room/useRoom'
import media from 'lib/styles/media'
import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import EnterTeamRoomButton from '../common/EnterTeamRoomButton'
import RoomButton from '../common/RoomButton'
import {
  useRoomInfoAction,
  useRoomInfoValue,
} from '../provider/RoomInfoProvider'

function TeamDebateButton() {
  const { timer, endDate } = useRoomInfoValue()
  const { roomId }: { roomId: string } = useParams()
  const { debateStatus } = useRoom(roomId)
  const { role, myRoomInfo, isRequestTeamDebating } = useRoomInfoValue()
  const { requestTeamDebating } = useRoomInfoAction()
  const isDebating = useMemo(() => debateStatus !== 6, [debateStatus])

  const handleClickrequestTeamDetaing = () => {
    if (!myRoomInfo?.currentTeam) return
    requestTeamDebating && requestTeamDebating(myRoomInfo?.currentTeam)
  }

  const disabledRequestButton = useMemo(() => {
    return (
      !!myRoomInfo?.currentTeam &&
      isRequestTeamDebating[myRoomInfo?.currentTeam]
    )
  }, [isRequestTeamDebating, myRoomInfo?.currentTeam])

  return (
    <Wrapper>
      {role === 'student' && isDebating ? (
        myRoomInfo?.currentTeam === 'A' && (
          <EnterTeamRoomButton
            team="A"
            remainTime={timer.A}
            date={endDate?.A}
          />
        )
      ) : (
        <EnterTeamRoomButton team="A" remainTime={timer.A} date={endDate?.A} />
      )}
      {role === 'student' && isDebating ? (
        myRoomInfo?.currentTeam === 'B' && (
          <EnterTeamRoomButton
            team="B"
            remainTime={timer.B}
            date={endDate?.B}
          />
        )
      ) : (
        <EnterTeamRoomButton team="B" remainTime={timer.B} date={endDate?.B} />
      )}

      {role === 'student' && isDebating && (
        <RoomButton
          onClick={handleClickrequestTeamDetaing}
          disabled={disabledRequestButton}
        >
          {disabledRequestButton ? '회의 요청 중' : '작전 회의 요청'}
        </RoomButton>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  & button {
    width: 100%;

    ${media.pc} {
      width: 320px;
    }

    height: 50px;
    background: #39393c;
    border: 1px solid #7c7c80;
    border-radius: 4px;

    letter-spacing: -0.2px;

    color: #ffffff;
    font-weight: 700;
    font-size: 16px;

    outline: none;
    cursor: pointer;

    &:hover {
      opacity: 0.9;
    }

    &:active {
      opacity: 0.8;
    }
  }
`

export default TeamDebateButton
