import React from 'react'
import styled from 'styled-components'
import { ReactComponent as LeftArrow } from 'assets/icons/left_arrow_mobile.svg'
import { useHistory } from 'react-router-dom'

interface RoomMobileHeaderProps {
  title: string
  onClickArrow?: () => void
}

function RoomMobileHeader({ title, onClickArrow }: RoomMobileHeaderProps) {
  const history = useHistory()
  const handleClickArrow = () => {
    if (onClickArrow) return onClickArrow()
    history.goBack()
  }

  return (
    <Wrapper>
      <LeftArrow className="left-arrow" onClick={handleClickArrow} />
      <div className="title">{title}</div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: 58px;
  padding: 0 20px;

  display: flex;
  align-items: center;

  & .left-arrow {
    cursor: pointer;
  }

  & .title {
    font-weight: 500;
    font-size: 14px;
    color: #f9f9fb;

    margin-left: 18px;
  }
`

export default RoomMobileHeader
