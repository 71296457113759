import React from 'react'
import styled from 'styled-components'
import EditProfileInput from '../editinfo/EditProfileInput'
import media from 'lib/styles/media'

interface Props {
  profile: string | undefined
  onChange?: (file: any, result: File) => void
  preview?: File | undefined
}
function TutorProfileContent({ profile, onChange, preview }: Props) {
  return (
    <Wrapper>
      <div className="profile_image">
        <EditProfileInput
          data={profile}
          onChange={onChange}
          preview={preview}
        />
      </div>
      <div className="profile_notice">
        <p>{'가입 승인을 위해 반드시 튜터님 본인의 사진을\n 등록해주세요.'}</p>
      </div>
    </Wrapper>
  )
}

export default TutorProfileContent
const Wrapper = styled.div`
  width: 100%;
  & div.profile_image {
    margin-top: 50px;
    position: relative;
  }
  & div.profile_notice {
    width: 100%;
    background: #f9f9fb;
    border-radius: 8px;
    padding: 12px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    ${media.medium} {
      padding: 12px 10px;
    }
    & > p {
      font-weight: 400;
      font-size: 13px;
      line-height: 22px;
      color: #7c7c80;
      ${media.medium} {
        white-space: break-spaces;
        text-align: center;
      }
    }
  }
`
