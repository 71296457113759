import { AppRootWrapper } from 'components/layout/CommonStyle'
import api from 'lib/api'
import media from 'lib/styles/media'
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import BankInput from '../BankInput'
import Button from '../Button'
import CheckPopup from '../CheckPopup'

function EditInfoPaymentComponent({ data }: any) {
  const [popup, setPopup] = useState(false)
  const [formdata, setFormdata] = useState({
    bankName: data.bank_name ? data.bank_name : '',
    bankHolder: data.bank_holder ? data.bank_holder : '',
    bankAccount: data.bank_account ? data.bank_account : '',
  })
  useEffect(() => {
    if (popup) {
      document.documentElement.style.overflow = 'hidden'
    } else {
      document.documentElement.style.overflow = 'auto'
    }

    return () => {
      document.documentElement.style.overflow = 'auto'
    }
  }, [popup])

  const handleChange = (type: string) => (e: any) => {
    setFormdata({
      ...formdata,
      [type]: e.target.value,
    })
  }

  const handleBankClick = (type: string) => {
    setFormdata({
      ...formdata,
      bankName: type,
    })
  }

  const handleSubmitClick = async () => {
    const formData = new FormData()
    formData.append('bank_name', formdata.bankName)
    formData.append('bank_account', formdata.bankAccount)
    formData.append('bank_holder', formdata.bankHolder)

    const {
      data: { success, alert },
    } = await api.post('/mypage/calculate/modify', formData)

    if (success) {
      setPopup(true)
    } else {
      window.alert(alert)
    }
  }

  const handleClick = () => {
    setPopup(false)
    window.location.reload()
  }

  return (
    <Wrapper>
      <Content>
        <BankInput
          valueBank={formdata?.bankName}
          handleBankClick={handleBankClick}
          valueName={formdata?.bankHolder}
          onChange={handleChange('bankHolder')}
          valueNum={formdata?.bankAccount}
          onChangeNumber={(e: any) => {
            setFormdata({
              ...formdata,
              bankAccount: e.target.value.replace(/[^0-9]/g, ''),
            })
          }}
        />
        <ButtonBox>
          <Button
            title="저장"
            disabled={
              !formdata?.bankName ||
              !formdata?.bankHolder ||
              !formdata?.bankAccount
            }
            onClick={handleSubmitClick}
          />
        </ButtonBox>
      </Content>
      {popup && (
        <CheckPopup
          onClick={handleClick}
          title="정산 정보 변경 완료"
          content="정산 정보 변경이 완료되었습니다."
        />
      )}
    </Wrapper>
  )
}

export default EditInfoPaymentComponent
const Wrapper = styled.div`
  width: 100%;
`
const Content = styled.div`
  & > div:first-of-type {
    width: 380px;
    margin: 0 auto;
    ${media.medium} {
      width: 100%;
    }
  }
`
const ButtonBox = styled.div`
  width: 380px;
  margin: 40px auto 0;
  ${media.medium} {
    width: 100%;
  }
`
