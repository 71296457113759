import React from 'react'
import styled from 'styled-components'
import Textarea from '../Textarea'
import media from 'lib/styles/media'

interface Porps {
  value: string
  onChange: (e: any) => void
}
function MyClassReviewContent({ value, onChange }: Porps) {
  return (
    <Wrapper>
      <p className="content_title">강의 후기</p>
      <div className="review_content_box">
        <Textarea
          placeholder="강의에 대한 리뷰를 알려주세요!"
          value={value}
          onChange={onChange}
          maxLength={500}
          type="review"
        />
      </div>
    </Wrapper>
  )
}

export default MyClassReviewContent

const Wrapper = styled.div`
  margin-bottom: 40px;
  ${media.medium} {
    padding-bottom: 40px;
  }
  & p.content_title {
    font-weight: 500;
    font-size: 17px;
    line-height: 26px;
    color: #0d0d0e;
    ${media.medium} {
      font-size: 16px;
      line-height: 26px;
    }
  }
  & div.review_content_box {
    width: 100%;
    min-height: 200px;
    margin-top: 10px;
  }
`
