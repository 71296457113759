import { useRoomInfoValue } from 'components/room/provider/RoomInfoProvider'
import RoomSideTab from 'components/room/side/RoomSideTab'
import StudentRoomSideFooter from 'components/room/side/StudentRoomSideFooter'
import React, { useState } from 'react'
import styled from 'styled-components'
import {
  RoomSideContainerWrapper,
  type RoomSideTabType,
} from './RoomTutorSideContainer'
import ChatContainer from './side/ChatContainer'
import ReferenceContainer from './side/ReferenceContainer'
import TeamChatContainer from './side/TeamChatContainer'

interface RoomStudentSideContainerProps {}

function RoomStudentSideContainer({}: RoomStudentSideContainerProps) {
  const [tab, setTab] = useState<RoomSideTabType>('chat')
  const { role } = useRoomInfoValue()

  const handleClickTab = (tab: RoomSideTabType) => {
    setTab(tab)
  }

  return (
    <Wrapper>
      <RoomSideTab selectedTab={tab} role={role} onClick={handleClickTab} />
      {tab === 'chat' && <ChatContainer />}
      {tab === 'reference' && <ReferenceContainer />}
      {tab === 'teamChat' && <TeamChatContainer />}
      <StudentRoomSideFooter />
    </Wrapper>
  )
}

const Wrapper = styled(RoomSideContainerWrapper)``

export default RoomStudentSideContainer
