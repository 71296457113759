import media from 'lib/styles/media'
import {
  type TutorPageTabType,
  TUTOR_PAGE_TAB_VARIABLES,
} from 'lib/utils/variables'
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

interface TutorTabProps {
  selected?: TutorPageTabType
  onChangeTab?: (tab: TutorPageTabType) => void
}

function TutorTab({ selected, onChangeTab }: TutorTabProps) {
  const scrollRef = useRef(null)

  const [on, setOn] = useState(false)
  const [offset, setOffset] = useState(0)

  const handleClick = (tab: TutorPageTabType) => () => {
    onChangeTab && onChangeTab(tab)
  }

  useEffect(() => {
    if (scrollRef.current !== null) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      const tabTop = scrollRef.current.offsetTop

      setOffset(tabTop)
    }
  }, [scrollRef.current])

  const onScroll = (e: any) => {
    const scrollTop = e.target.scrollingElement.scrollTop
    const settingnum = window.outerWidth > 1024 ? offset - 143 : offset - 101

    if (scrollRef.current !== null) {
      if (scrollTop > settingnum) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        scrollRef.current.classList.add('active')
        setOn(true)
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        const classList = scrollRef.current.classList
        if (classList.value.includes('active') && scrollTop < settingnum) {
          classList.remove('active')
          setOn(false)
        }
      }
    }
  }

  useEffect(() => {
    try {
      if (offset > 0) window.addEventListener('scroll', onScroll)
    } catch (error) {}
  }, [scrollRef.current, offset])

  return (
    <>
      <Wrapper ref={scrollRef}>
        {Object.entries(TUTOR_PAGE_TAB_VARIABLES).map(([value, label]) => (
          <div
            className={`tab ${selected === value ? 'selected' : ''}`}
            key={value}
            onClick={handleClick(value as TutorPageTabType)}
          >
            {label}
          </div>
        ))}
      </Wrapper>
      {on && (
        <Wrapper style={{ opacity: '0' }}>
          {Object.entries(TUTOR_PAGE_TAB_VARIABLES).map(([value, label]) => (
            <div
              className={`tab ${selected === value ? 'selected' : ''}`}
              key={value}
              onClick={handleClick(value as TutorPageTabType)}
            >
              {label}
            </div>
          ))}
        </Wrapper>
      )}
    </>
  )
}

const Wrapper = styled.div`
  margin-top: 61px;

  display: flex;
  background: #fff;

  & > .tab {
    flex: 1;

    text-align: center;
    padding-bottom: 14px;
    color: #b5b5b9;
    border-bottom: 1px solid #b5b5b9;

    font-weight: 500;
    font-size: 18px;
    line-height: 28px;

    cursor: pointer;
  }

  & > .tab.selected {
    color: #0d0d0e;
    border-bottom: 1px solid #0d0d0e;
  }

  &.active {
    position: fixed;
    top: 100px;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 0;
    width: 100%;
    max-width: 1200px;
    z-index: 1;
    padding-top: 16px;
  }

  ${media.medium} {
    position: relative;
    left: -20px;
    width: calc(100% + 40px);
    margin-top: 65px;

    & > .tab {
      border-bottom: 1px solid #e1e1e2;
    }

    & > .tab.selected {
      padding-bottom: 13px;
      border-bottom: 2px solid #0d0d0e;
    }

    &.active {
      top: 58px;
      margin-top: 0;
      padding-top: 14px;
    }
  }
`

export default TutorTab
