import { AppRootWrapper } from 'components/layout/CommonStyle'
import React, { useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'
import media from 'lib/styles/media'
import useSWR from 'swr'
import Button from '../Button'
import api from 'lib/api'

import { Swiper, Pagination, Autoplay } from 'swiper'
import { phoneFormet } from 'lib/utils/CommonFuncttion'
import ToogleButton from '../ToogleButton'
import Textarea from '../Textarea'
import CheckPopup from '../CheckPopup'

import { ReactComponent as BasicIcon } from 'assets/icons/basic_icon.svg'

Swiper.use([Pagination, Autoplay])

const FeedBackComponent = () => {
  const history = useHistory()
  const params = useParams<{ class: string; id: string }>()
  const searchData = params.id
  const classData = params.class

  const { data, mutate } = useSWR(
    `/class/user/feedback?class_user_id=${searchData}`,
  )
  const originData = data?.data

  const { data: feed, mutate: mutate2 } = useSWR(
    `/class/feedback?class_id=${classData}`,
  )

  const [feedback, setFeedback] = useState([
    { id: '', title: '', score: '', comment: '', is_comment: false },
  ])
  const [comment, setComment] = useState('')
  const [total, setTotal] = useState(0)
  const [disable, setDisable] = useState(true)
  const [popup, setPopup] = useState(false)

  useEffect(() => {
    if (feed?.data?.class_feedbacks) {
      const arr = []

      for (const f of feed?.data?.class_feedbacks) {
        arr.push({
          id: f.id,
          title: f.name,
          score: '',
          comment: '',
          is_comment: false,
        })
      }

      setFeedback(arr)
    }
  }, [feed])

  useEffect(() => {
    if (
      originData &&
      originData.class_user_feedbacks &&
      feedback.length === feed?.data?.class_feedbacks.length
    ) {
      const arr = [...feedback]

      for (let i = 0; i < arr.length; i++) {
        for (const backs of originData.class_user_feedbacks) {
          if (backs.id === arr[i].id) {
            arr[i].score = backs.score
            arr[i].comment = backs.comment
            arr[i].is_comment = backs.comment !== null && backs.comment !== ''
          }
        }
      }

      setFeedback(arr)
    }

    if (originData?.feedback_review) setComment(originData?.feedback_review)
  }, [feed, originData])

  useEffect(() => {
    let max = 0
    let check = false

    for (const feed of feedback) {
      max = max + Number(feed.score)

      if (feed.score === '') check = true
      if (feed.is_comment && feed.comment === '') check = true
    }

    if (comment === '') check = true

    setTotal(max)
    setDisable(check)
  }, [comment, feedback])

  const handleSave = async () => {
    try {
      const formData = new FormData()
      formData.append('class_user_id', searchData)

      for (const feed of feedback) {
        formData.append(`feedbacks[${feed.id}][score]`, feed.score)
        formData.append(
          `feedbacks[${feed.id}][comment]`,
          feed.is_comment ? feed.comment : '',
        )
      }

      formData.append('review', comment)

      const {
        data: { success, alert },
      } = await api.post('/class/user/feedback/register', formData)

      if (success) {
        setPopup(true)
      } else {
        window.alert(alert)
      }
    } catch (err) {}
  }

  if (!data) return null
  if (!data.data) return null

  return (
    <Wrapper>
      <h1>수강생 피드백 등록</h1>
      <ProfileBox>
        {originData.user_info.profile_url ? (
          <img src={originData.user_info.profile_url} />
        ) : (
          <BasicIcon />
        )}
        <div>
          <h1>{originData.user_info.name}</h1>
          <p>
            초등학교 {originData.class.grade ? originData.class.grade : '1'}학년
            <span />
            {phoneFormet(originData.user_info.phone)}
          </p>
        </div>
      </ProfileBox>
      <Line />
      <Total>
        피드백 항목 평가
        <p>
          총점{' '}
          <span>
            {total}/{feed?.data?.max_feedback_score * feedback.length}
          </span>
        </p>
      </Total>
      {feedback.map((item, i) => (
        <FeedBackArea key={'feedback_area_' + i}>
          <ScoreBox>
            {item.title}
            <div>
              <Input>
                <input
                  type="text"
                  value={item.score}
                  onChange={(e) => {
                    const arr = [...feedback]

                    let value = e.target.value
                    value = value.replace(/[^0-9]/g, '')

                    if (value > feed?.data?.max_feedback_score)
                      arr[i].score = feed?.data?.max_feedback_score
                    else arr[i].score = value

                    setFeedback(arr)
                  }}
                />
                <p>점</p>
              </Input>{' '}
              <p>/{feed?.data?.max_feedback_score}점</p>
            </div>
          </ScoreBox>
          <ToogleButton
            title="코멘트 입력"
            value={item.is_comment}
            onChange={() => {
              const arr = [...feedback]
              arr[i].is_comment = !arr[i].is_comment
              setFeedback(arr)
            }}
          />
          {item.is_comment && (
            <Textarea
              placeholder="평가 항목에 대한 코멘트를 적어주세요"
              value={item.comment}
              maxLength={200}
              type="review"
              onChange={(e) => {
                const arr = [...feedback]
                arr[i].comment = e.target.value
                setFeedback(arr)
              }}
            />
          )}
        </FeedBackArea>
      ))}
      <Line />
      <TotalComment>
        <h1>피드백 총평</h1>
        <Textarea
          placeholder="수강생에 대한 총평을 남겨주세요"
          value={comment}
          maxLength={500}
          type="review"
          onChange={(e) => setComment(e.target.value)}
        />
      </TotalComment>
      <Button
        title={
          originData.class_user_feedbacks &&
          originData.class_user_feedbacks.length > 0
            ? '수정'
            : '등록'
        }
        disabled={disable}
        onClick={handleSave}
      />
      {popup && (
        <CheckPopup
          onClick={() => history.goBack()}
          title="피드백 등록 완료"
          content="수강생 피드백이 등록되었습니다."
        />
      )}
    </Wrapper>
  )
}

export default FeedBackComponent

const TotalComment = styled.div`
  & > h1 {
    font-weight: 500;
    font-size: 17px;
    line-height: 1.53;
    color: #0d0d0e;
    margin-bottom: 10px;
  }

  ${media.medium} {
    font-size: 16px;
    line-height: 1.62;
  }
`

const Input = styled.div`
  position: relative;

  & > input {
    padding: 11px 14px;
    width: 300px;
    border: 1px solid #e1e1e2;
    border-radius: 8px;
    font-weight: 700;
    font-size: 14px;
    line-height: 1.71;
    color: #0d0d0e;
    outline: none;

    &::placeholder {
      color: #b5b5b9;
    }
  }

  & > p {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 14px;
    font-weight: 700;
    font-size: 14px;
    line-height: 1.71;
    color: #b5b5b9;
  }

  ${media.medium} {
    width: 100%;

    & > input {
      width: 100%;
    }
  }
`

const ScoreBox = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 20px;
  line-height: 1.5;
  color: #0d0d0e;
  margin-bottom: 16px;

  & > div {
    display: flex;
    align-items: center;
    margin-left: auto;

    & > p {
      font-weight: 500;
      font-size: 17px;
      line-height: 1.53;
      color: #3b3b40;
      margin-left: 20px;
    }
  }

  ${media.medium} {
    display: block;
    font-size: 18px;
    line-height: 1.56;
    margin-bottom: 10px;

    & > div {
      margin-top: 10px;

      & > p {
        font-weight: 400;
        font-size: 13px;
        line-height: 1.69;
        color: #0d0d0e;
        margin-left: 10px;
        white-space: nowrap;
      }
    }
  }
`

const FeedBackArea = styled.div`
  margin-top: 20px;

  & > div.review {
    margin-top: 12px;
  }

  ${media.medium} {
    margin-top: 30px;

    & > div.review {
      margin-top: 10px;
    }
  }
`

const Total = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  font-size: 17px;
  line-height: 1.53;
  color: #0d0d0e;

  & > p {
    font-weight: 500;
    font-size: 17px;
    line-height: 1.53;
    color: #0d0d0e;

    & > span {
      font-weight: 700;
      color: #7574d8;
    }
  }

  ${media.medium} {
    font-size: 16px;
    line-height: 1.62;

    & > p {
      font-size: 16px;
      line-height: 1.62;
    }
  }
`

const Line = styled.div`
  margin: 30px 0;
  width: 100%;
  height: 1px;
  background: #eeeff1;
`

const ProfileBox = styled.div`
  border: 1px solid #e1e1e2;
  border-radius: 14px;
  padding: 20px;
  display: flex;
  align-items: center;

  & > img,
  & > svg {
    width: 64px;
    min-width: 64px;
    height: 64px;
    margin-right: 16px;
    object-fit: cover;
    border-radius: 50px;
  }

  & > div {
    & > h1 {
      font-weight: 700;
      font-size: 20px;
      line-height: 1.5;
      color: #3b3b40;
      margin-bottom: 2px;
    }

    & > p {
      font-weight: 500;
      font-size: 14px;
      line-height: 1.71;
      color: #7c7c80;

      & > span {
        display: inline-block;
        width: 2px;
        min-width: 2px;
        height: 2px;
        margin: 6px;
        background: #7c7c80;
      }
    }
  }

  ${media.medium} {
    padding: 16px 20px;

    & > img {
      width: 40px;
      min-width: 40px;
      height: 40px;
      margin-right: 10px;
    }

    & > div {
      & > h1 {
        font-size: 16px;
        margin-bottom: 0;
      }

      & > p {
        font-size: 13px;
        line-height: 1.69;

        & > span {
          margin: 3px 6px;
        }
      }
    }
  }
`

const Wrapper = styled(AppRootWrapper)`
  padding: 40px 0 190px;
  max-width: 770px;

  & > h1 {
    font-weight: 700;
    font-size: 24px;
    line-height: 1.5;
    color: #0d0d0e;
    margin-bottom: 20px;
    text-align: center;
  }

  & > button {
    margin: 40px auto 0;
    max-width: 380px;
  }

  ${media.medium} {
    padding: 20px 0 0;

    & > h1 {
      font-size: 18px;
      line-height: 1.56;
      margin-bottom: 40px;
    }

    & > button {
      height: 50px;
    }
  }
`
