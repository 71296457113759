import type { FileType } from 'lib/types/account'
import { atom, atomFamily } from 'recoil'
import { v1 } from 'uuid'

type JoinStepDataType = {
  is_marketing?: number
  phone?: number | string
  code?: number
  id?: string
  checkPassword?: string
  password?: string
  name?: string
  school_type?: string
  school?: string
  grade?: string
  schoolName?: string
  residence?: string
} | null

export const JoinStepPayloadState = atom<JoinStepDataType>({
  key: `joinStepDataState/${v1()}`,
  default: {
    school_type: '초등학교',
  },
})

type TutorJoinStepDataType = {
  is_marketing?: number
  phone?: number | string
  code?: number
  id?: string
  checkPassword?: string
  password?: string
  name?: string
  nickname?: string
  bankName?: string
  bankHolder?: string
  bankAccount?: number
  profile?: string
  introduce?: string
  detailIntroduce?: Array<{ index: number; title: string; content: string }>
  careerUrl?: string
} | null

export const TutorJoinStepPayloadState = atom<TutorJoinStepDataType>({
  key: `joinStepDataState/${v1()}`,
  default: null,
})

type FindPasswordDataType = {
  phone?: number | string
  password?: string
  checkPassword?: string
  email?: string
  code?: number
} | null

export const FindPasswordPayloadState = atom<FindPasswordDataType>({
  key: `findPasswordDataState/${v1()}`,
  default: null,
})

export type MyClassListDataType = {
  image: string
  title: string
  nickname: string
  beginDate: string
  endDate: string
  profile: string
  time: string
  stepInfo?: {
    step_title?: string
    step_begin_date?: string
    step_estimated_time?: string
    step_real_begin_date?: string
    step_real_end_date?: string
  }
}

export const MyClassListDataPayloadState = atom<MyClassListDataType | null>({
  key: `myClassListDataPayloadState/${v1()}`,
  default: null,
})

export type DetailInfoType = {
  id: number | string
  index: number
  title: string
  contents: string
}

export type DetailStepInfoType = {
  step: number
  date: string
  time: string
  estimated: string
  title: string
}
type TutorEditinfoDataType = {
  profile?: any
  name?: string
  nickname?: string
  introduce?: string
  detailIntroduce: DetailInfoType[]
  introduceType?: 'single' | 'multi'
  careerUrl?: string
}

function defaultInfoValue(mode: 'add' | 'edit') {
  if (mode === 'add') {
    return {
      detailIntroduce: [
        {
          index: 1,
          id: '',
          title: '',
          contents: '',
        },
      ],
    }
  }
  return {
    detailIntroduce: [],
  }
}

export const TutorEditInfoPayloadState = atomFamily<
  TutorEditinfoDataType,
  'edit' | 'add'
>({
  key: `TutorEditInfoPayloadState/${v1()}`,
  default: (params: 'edit' | 'add') => defaultInfoValue(params),
})

export type StepClassType = {
  step: number
  date: string
  time: string
  estimated: string
  title: string
  step_begin_date?: string
  step_estimated_time?: string | number
  step_title?: string
  can_modify?: 'Y' | 'N'
  modify?: string
}

export type ImageType = {
  id: string
  image_url: string | number
}

export type TutorRegisterClassDataType = {
  profile?: any
  classTitle?: string
  person?: number
  detailIntroduce: DetailInfoType[]
  multi?: number
  attend?: number
  public?: number
  password?: string
  price?: string
  free?: number
  category?: string[]
  files: FileType[]
  stepClass: StepClassType[]
  deleteImageIds?: string[]
  images?: ImageType[]
  detailType?: string
  room?: number
}

export const TutorRegisterClassPayloadState = atom<TutorRegisterClassDataType>({
  key: `joinStepDataState/${v1()}`,
  default: {
    detailIntroduce: [],
    files: [],
    stepClass: [
      { step: 1, date: '', time: '', estimated: '', title: '', modify: 'Y' },
    ],
    person: 2,
    multi: 0,
    attend: 1,
    public: 1,
    free: 0,
    detailType: 'single',
  },
})

function defaultValue(mode: 'add' | 'edit') {
  if (mode === 'add') {
    return {
      detailIntroduce: [
        {
          index: 1,
          id: '',
          title: '',
          contents: '',
        },
      ],
      files: [],
      stepClass: [
        { step: 1, date: '', time: '', estimated: '', title: '', modify: 'Y' },
      ],
      person: 2,
      multi: 0,
      attend: 1,
      public: 1,
      free: 0,
      detailType: 'single',
    }
  } else {
    return {
      detailIntroduce: [],
      detailType: 'single',
      files: [],
      stepClass: [
        { step: 1, date: '', time: '', estimated: '', title: '', modify: 'Y' },
      ],
    }
  }
}

export const TutorRegisterClassPayloadState2 = atomFamily<
  TutorRegisterClassDataType,
  'edit' | 'add'
>({
  key: `TutorRegisterClassPayloadState2/${v1()}`,
  default: (params: 'edit' | 'add') => defaultValue(params),
})
