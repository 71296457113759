import { type BasicUserInfo } from 'lib/types/room'
import React from 'react'
import styled from 'styled-components'
import { ReactComponent as BasicIcon } from 'assets/icons/basic_icon.svg'

interface AudienceComponentProps {
  audience: BasicUserInfo
}

function AudienceComponent({ audience }: AudienceComponentProps) {
  return (
    <Wrapper>
      <div className="profile">
        {audience.profile_url ? (
          <img
            src={audience.profile_url}
            alt="audience profile"
            className="profile-image"
          />
        ) : (
          <BasicIcon className="basic-icon" />
        )}
      </div>
      <div className="nickname">{audience.name}</div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: 24px;
  display: flex;

  align-items: center;

  gap: 8px;

  & .profile-image,
  .basic-icon {
    width: 24px;
    height: 24px;

    border-radius: 50%;
  }

  & .nickname {
    font-weight: 500;
    font-size: 14px;
    color: #3b3b40;
    height: 18px;
  }
`

export default AudienceComponent
