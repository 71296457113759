import React from 'react'
import styled from 'styled-components'
import { ReactComponent as CloseIcon } from '../../../assets/icons/popup_cancel_icon.svg'
import DaumPostcodeEmbed from 'react-daum-postcode'
import media from 'lib/styles/media'

interface Props {
  handleClose: () => void
  onComplete: (data: any) => void
}
function AddressPopup({ handleClose, onComplete }: Props) {
  return (
    <Wrapper>
      <PopupContent>
        <Title>
          <p className="address_title">주소 검색</p>
          <CloseIcon onClick={handleClose} />
        </Title>
        <Contents>
          <DaumPostcodeEmbed
            className="address_contents"
            onComplete={onComplete}
          />
        </Contents>
      </PopupContent>
    </Wrapper>
  )
}

export default AddressPopup

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  background: rgba(0, 0, 0, 0.6);
  z-index: 110;
  ${media.medium} {
    width: 100%;
    height: 100%;
  }
`
const PopupContent = styled.div`
  position: absolute;
  width: 480px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  background: #ffffff;
  border-radius: 26px;
  ${media.medium} {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
`
const Title = styled.div`
  padding: 26px 26px 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  & p.address_title {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: #0d0d0e;
  }
`
const Contents = styled.div`
  width: 100%;
  padding: 0 26px;
  height: 480px;
  ${media.medium} {
    height: 100%;
  }
  & div.address_contents {
    height: 480px !important;
    ${media.medium} {
      height: 100% !important;
    }
  }
`
