import { voteModalState } from 'atoms/modal'
import { ModalContentWrapper } from 'components/layout/CommonStyle'
import React from 'react'
import { useRecoilState } from 'recoil'
import styled from 'styled-components'
import { ReactComponent as CloseIcon } from 'assets/icons/close_icon.svg'
import ModalWrapper from './ModalWrapper'
import Button from 'components/common/Button'
import Timer from 'components/common/Timer'
import {
  useRoomInfoAction,
  useRoomInfoValue,
} from 'components/room/provider/RoomInfoProvider'

function VoteModal() {
  const [modal, setModal] = useRecoilState(voteModalState)

  const { excuteVote } = useRoomInfoAction()

  const handleClose = () => {
    setModal(false)
  }

  const handleVote = (team: 'A' | 'B') => () => {
    excuteVote && excuteVote(team)
  }

  const { endDate } = useRoomInfoValue()

  if (!modal) return null

  return (
    <ModalWrapper>
      <Wrapper>
        <div className="header">
          <h3 className="title">찬반 투표</h3>
          <CloseIcon className="close-icon" onClick={handleClose} />
        </div>
        <div className="content">
          <p className="description">찬반 투표를 진행합니다.</p>
          <p className="description">의견에 동의하시는 팀에 투표해주세요.</p>

          <div className="timer-wrap">
            <Timer date={endDate?.vote} onExit={handleClose} />
          </div>
        </div>
        <div className="footer">
          <Button
            title="찬성팀 투표"
            variant="outlined"
            color="gray-3"
            onClick={handleVote('A')}
          />
          <Button
            title="반대팀 투표"
            variant="outlined"
            color="gray-3"
            onClick={handleVote('B')}
          />
        </div>
      </Wrapper>
    </ModalWrapper>
  )
}

const Wrapper = styled(ModalContentWrapper)`
  & .description {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
  }

  & .timer-wrap {
    padding: 40px 0;

    display: flex;
    justify-content: center;

    & .timer {
      font-weight: 700;
      font-size: 24px;
      line-height: 24px;
    }
  }
`

export default VoteModal
