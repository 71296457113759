import React from 'react'
import styled from 'styled-components'
import { ReactComponent as PlusIcon } from '../../../assets/icons/feedback_review_plus_icon.svg'
import { ReactComponent as MinusIcon } from '../../../assets/icons/feedback_review_minus_icon.svg'
import { Collapse } from 'antd'
import media from 'lib/styles/media'

interface Props {
  data: Array<{
    comment: string
    id: number
    name: string
    score: number
  }>
  maxScore: number
}
function FeedbackListContent({ data, maxScore }: Props) {
  const { Panel } = Collapse

  const handleHeader = (item: any) => {
    return (
      <div className="text_box">
        <p className="title">{item.name}</p>
        <p className="score">
          {item.score}/{maxScore}점
        </p>
      </div>
    )
  }

  return (
    <Wrapper>
      <Collapse
        accordion
        expandIcon={({ isActive }) => (isActive ? <MinusIcon /> : <PlusIcon />)}
        className="feedback_contents"
      >
        {data.map((item, i) => (
          <Panel
            header={handleHeader(item)}
            key={String(i) + 1}
            className="feedback_box"
          >
            <div className="text_box">
              <p className="comment">{item.comment}</p>
            </div>
          </Panel>
        ))}
      </Collapse>
    </Wrapper>
  )
}

export default FeedbackListContent

const Wrapper = styled.div`
  & div.text_box {
    display: flex;
    align-items: center;
  }
  & p.title {
    font-weight: 700;
    font-size: 16px;
    line-height: 26px;
    color: #0d0d0e;
    ${media.medium} {
      font-size: 14px;
      line-height: 24px;
    }
  }
  & p.score {
    font-weight: 700;
    font-size: 16px;
    line-height: 26px;
    color: #7574d8;
    margin-left: 16px;
    ${media.medium} {
      font-size: 14px;
      line-height: 24px;
      margin-left: 12px;
    }
  }
  & div.feedback_contents {
    width: 100%;
    min-height: 74px;
    background: #ffffff;
    border: none;
    ${media.medium} {
      min-height: 60px;
    }
    & > div {
      margin-bottom: 20px;
      ${media.medium} {
        margin-bottom: 12px;
      }
    }
    & > div:last-of-type {
      border-radius: 15px;
    }
  }
  & div.feedback_box {
    width: 100%;
    min-height: 74px;
    border: none;
    padding: 24px 20px 0;
    border: 1px solid #e1e1e2;
    border-radius: 15px;
    ${media.medium} {
      min-height: 60px;
      padding: 18px 18px 0;
    }
  }
  & p.comment {
    padding: 24px 0;
    ${media.medium} {
      padding: 18px 0;
    }
  }
  & .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
  }
  & .ant-collapse-item > .ant-collapse-header {
    align-items: center;
    flex-direction: row-reverse;
    padding: 0;
    padding-bottom: 24px;
    ${media.medium} {
      padding-bottom: 18px;
    }
  }
`
