import { confirmModalState } from 'atoms/modal'
import axios, { type AxiosError } from 'axios'
import api from 'lib/api'
import { deleteFileMutation, uploadFileMutation } from 'lib/api/common'
import {
  type RoomInfoType,
  type RoomSeatInfoType,
  type GetRoomInfoType,
  type GetRoomSeatInfoType,
  type ModifyRoomProps,
  type DebateStatusType,
  type RoomRoleType,
} from 'lib/types/room'
import { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import useSWR from 'swr'

function useRoom(roomId: string | number) {
  const setConfirmModal = useSetRecoilState(confirmModalState)
  const { isLoading, data, mutate } = useSWR<
    | {
        room: RoomInfoType
        seat: [RoomSeatInfoType]
      }
    | undefined,
    AxiosError,
    any
  >([`/room`, roomId], async ([key, roomId]: any) => {
    const roomInfoResponse = await api.get<GetRoomInfoType>('/class/debate', {
      params: {
        class_id: roomId,
      },
    })

    if (!roomInfoResponse.data.success) {
      return
    }

    const roomSeatInfoResponse = await api.get<GetRoomSeatInfoType>(
      '/rtc/info',
      {
        params: {
          class_id: roomId,
        },
      },
    )

    if (!roomSeatInfoResponse.data.success) {
      return
    }

    return {
      room: {
        ...roomInfoResponse.data.data.room_info,
        files: roomInfoResponse.data.data.room_info.files.reverse(),
      },
      seat: roomSeatInfoResponse.data.data.chat_room_infos,
    }
  })

  const step = useMemo(() => {
    return data?.room.progress_step
  }, [data?.room.progress_step])

  const debateStatus = useMemo(
    () => data?.room.debate_status,
    [data?.room.debate_status],
  )

  const changeMemberCount = async (count: number) => {
    const { data: responseData } = await api.post('/class/debate/qty', {
      class_id: roomId,
      room_qty: count,
    })

    if (!responseData.success) return
    if (!data) return

    mutate(
      {
        ...data,
        room: {
          ...data.room,
          room_qty: count,
        },
      },
      false,
    )
  }

  const modifyTeamRoomTrigger = async ({ closedAt, team }: ModifyRoomProps) => {
    const key = team === 'A' ? 'team_a_date' : 'team_b_date'

    await api.post('/class/debate/team', {
      class_id: roomId,
      [key]: closedAt,
    })

    await axios.post('https://api.debateon.fifteenh.io/api/rtc/handler', {
      class_id: roomId,
      type: 'teamRoom',
      team,
      closed_at: closedAt,
    })
  }

  const modifyTeamRoom = ({ team, closedAt }: ModifyRoomProps) => {
    if (!data) return

    const key = team === 'A' ? 'team_a_date' : 'team_b_date'

    mutate((prev) => {
      if (!prev) return prev
      return {
        ...prev,
        room: {
          ...prev?.room,
          [key]: closedAt,
        },
      }
    }, false)
  }

  const closeTeamRoomTrigger = async (
    team: 'A' | 'B',
    disableConfirm?: boolean,
  ) => {
    const trigger = async () => {
      const key = team === 'A' ? 'team_a_date' : 'team_b_date'

      await api.post('/class/debate/team', {
        class_id: roomId,
        [key]: 'empty',
      })

      await axios.post('https://api.debateon.fifteenh.io/api/rtc/handler', {
        class_id: roomId,
        type: 'closeTeamRoom',
        team,
      })
    }

    if (disableConfirm) {
      trigger()
    } else {
      setConfirmModal({
        title: '소회의방 종료',
        description: '소회의방을 종료하시겠습니까?',
        confirmText: '종료',
        onConfirm: trigger,
      })
    }
  }

  const closeTeamRoom = async (team: 'A' | 'B') => {
    const key = team === 'A' ? 'team_a_date' : 'team_b_date'
    mutate((prev) => {
      if (!prev) return prev
      return {
        ...prev,
        room: {
          ...prev.room,
          [key]: null,
        },
      }
    })
  }

  /** 
    [필수] 변경할 세수 스탭 (0 : 입안 / 1 : 교차질의 / 2 : 반박 / 3 : 요약 / 4 : 전체 교차질의 / 5 : 마지막 발언 / 6 : 온라인 강의 / 7 : 투표진행중 )
  */
  const modifyDebateStatusTrigger = async (status: DebateStatusType) => {
    const trigger = async () => {
      await api.post('/class/debate/status', {
        class_id: roomId,
        debate_status: status,
        step: 1,
      })

      await axios.post('https://api.debateon.fifteenh.io/api/rtc/handler', {
        class_id: roomId,
        type: 'modifyDebateStatus',
        debate_status: status,
      })
    }

    const title = status === 0 ? '디베이팅 시작' : '온라인 교실 돌아가기'
    const description =
      status === 0
        ? '디베이팅을 시작하시겠습니까?'
        : '디베이팅을 종료하고 온라인 교실로 돌아가시겠습니까?'
    const confirmText = status === 0 ? '시작' : '예'

    if (status === 0 || status === 6 || status === 8) {
      setConfirmModal({
        title,
        description,
        confirmText,
        onConfirm: trigger,
      })
      return
    }

    trigger()
  }

  const modifyDebateStatus = (status: DebateStatusType) => {
    mutate((prev) => {
      if (!prev) return
      return {
        ...prev,
        room: {
          ...prev.room,
          debate_status: status,
        },
      }
    }, false)
  }

  const setDebatingTimerTrigger = async (date: Date | string) => {
    await api.post('/class/debate/time', {
      class_id: roomId,
      dabate_date: date,
    })

    await api.post('https://api.debateon.fifteenh.io/api/rtc/handler', {
      class_id: roomId,
      type: 'settingTimer',
      date,
    })
  }

  const setDebatingTimer = (date: Date) => {
    mutate((prev) => {
      if (!prev) return

      return {
        ...prev,
        room: {
          ...prev.room,
          debate_date: date,
        },
      }
    }, false)
  }

  const clearDebatingTimerTrigger = async () => {
    await api.post('/class/debate/time', {
      class_id: roomId,
      dabate_date: 'empty',
    })
    await api.post('https://api.debateon.fifteenh.io/api/rtc/handler', {
      class_id: roomId,
      type: 'clearTimer',
    })
  }

  const clearDebatingTimer = () => {
    mutate((prev) => {
      if (!prev) return

      return {
        ...prev,
        room: {
          ...prev.room,
          debate_date: null,
        },
      }
    }, false)
  }

  const setVoteTimerTrigger = async (date: string) => {
    await api.post('https://api.debateon.fifteenh.io/api/rtc/handler', {
      class_id: roomId,
      type: 'setVoteTimer',
      date,
    })

    await api.post('/class/debate/vote', {
      class_id: roomId,
      vote_date: date,
    })

    await api.post('class/vote/start', {
      class_id: roomId,
    })
  }

  const setVoteTimer = (date: Date) => {
    mutate((prev) => {
      if (!prev) return

      return {
        ...prev,
        room: {
          ...prev.room,
          vote_date: date,
        },
      }
    }, false)
  }

  const clearVoteTimerTrigger = async () => {
    await api.post('/class/debate/vote', {
      class_id: roomId,
      vote_date: 'empty',
    })

    const { data } = await api.post('/class/vote/end', {
      class_id: roomId,
    })

    await api.post('https://api.debateon.fifteenh.io/api/rtc/handler', {
      class_id: roomId,
      type: 'clearVoteTimer',
      date: 'empty',
      vote: {
        A: data.data.vote.agree,
        B: data.data.vote.disagree,
      },
    })
  }

  const clearVoteTimer = () => {
    mutate((prev) => {
      if (!prev) return

      return {
        ...prev,
        room: {
          ...prev.room,
          vote_date: null,
        },
      }
    }, false)
  }

  const uploadReference = async (file: File) => {
    if (!data?.room) return
    const responseData = await uploadFileMutation({
      file,
      fileName: file.name,
      roomId,
      step: data?.room.progress_step,
    })

    if (!responseData.success) return

    await api.post('https://api.debateon.fifteenh.io/api/rtc/handler', {
      class_id: roomId,
      type: 'uploadReference',
    })
  }

  const uploadReferenceHandler = () => {
    mutate()
  }

  const deleteReference = async (fileId: string) => {
    const resopnseData = await deleteFileMutation({
      fileId,
      roomId: String(roomId),
    })

    if (!resopnseData) return

    await api.post('https://api.debateon.fifteenh.io/api/rtc/handler', {
      class_id: roomId,
      type: 'deleteReference',
    })
  }

  const deleteReferenceHandler = () => {
    mutate()
  }

  const exitRoomTrigger = () => {
    const trigger = async () => {
      await api.post('/class/debate/end', {
        class_id: roomId,
        step: 1,
      })

      await api.post('https://api.debateon.fifteenh.io/api/rtc/handler', {
        class_id: roomId,
        type: 'exitRoom',
      })
    }

    setConfirmModal({
      title: '강의 종료',
      description: '강의를 종료하시겠습니까?',
      confirmText: '종료',
      onConfirm: trigger,
    })
  }

  const history = useHistory()

  const exitRoom = (role: RoomRoleType) => {
    // 강의종료페이지로 이동
    if (role === 'audience') return history.push('/')
    history.push(`/myclass/end/${roomId}/1`)
  }

  return {
    isLoading,
    data,
    mutate,
    changeMemberCount,
    modifyTeamRoomTrigger,
    modifyTeamRoom,
    closeTeamRoomTrigger,
    closeTeamRoom,
    modifyDebateStatusTrigger,
    debateStatus,
    modifyDebateStatus,
    setDebatingTimerTrigger,
    setDebatingTimer,
    clearDebatingTimerTrigger,
    clearDebatingTimer,
    setVoteTimerTrigger,
    setVoteTimer,
    clearVoteTimerTrigger,
    clearVoteTimer,
    uploadReference,
    deleteReference,
    exitRoomTrigger,
    exitRoom,
    uploadReferenceHandler,
    deleteReferenceHandler,
  }
}

export default useRoom
