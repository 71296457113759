import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ReactComponent as Arrow } from '../../../assets/icons/join_select_button_arrow.svg'
import { ReactComponent as UpArrow } from '../../../assets/icons/join_select_button_up_arrow.svg'
import Input from '../Input'

interface Props {
  title: string
  value: string | undefined
  handleClick: (type: any) => void
  handleGradeClick: (type: any) => void
  gradeValue: string | undefined
  handleChange: (e: any) => void
}
function JoinSelectBox({
  title,
  value,
  handleClick,
  handleGradeClick,
  gradeValue,
  handleChange,
}: Props) {
  const [school, setSchool] = useState(false)
  const [grade, setGrade] = useState(false)
  const [etc, setEtc] = useState(false)

  const schoolType = [
    { id: '1', type: '초등학교' },
    { id: '2', type: '중학교' },
    { id: '3', type: '고등학교' },
    { id: '4', type: '기타' },
  ]
  const schoolGrade = [
    {
      type: '초등학교',
      grade: [
        { key: 1 },
        { key: 2 },
        { key: 3 },
        { key: 4 },
        { key: 5 },
        { key: 6 },
      ],
    },
    { type: '중학교', grade: [{ key: 1 }, { key: 2 }, { key: 3 }] },
    { type: '고등학교', grade: [{ key: 1 }, { key: 2 }, { key: 3 }] },
  ]

  useEffect(() => {
    if (value === '기타') {
      setEtc(true)
    } else {
      setEtc(false)
    }
  }, [value])

  const handleBlur = () => {
    if (school) {
      setTimeout(() => {
        setSchool(false)
      }, 150)
    }
    if (grade) {
      setTimeout(() => {
        setGrade(false)
      }, 150)
    }
  }

  return (
    <Wrapper>
      <Title>
        <p className="selet_title">{title}</p>
      </Title>
      <Content>
        <div
          className="input_box"
          onClick={() => {
            setSchool(true)
          }}
        >
          <input
            type="text"
            readOnly
            placeholder="초등학교"
            className={school ? 'focus' : ''}
            value={value ?? ''}
            onBlur={handleBlur}
          />
          {school ? <UpArrow className="arrow" /> : <Arrow className="arrow" />}
          {school && (
            <SelectBox>
              {schoolType.map((item, i) => (
                <div
                  key={`school_type_list_${i}`}
                  className="school_list_content"
                  onClick={() => {
                    if (item.type === '기타') {
                      setEtc(true)
                      handleClick(item.type)
                    } else {
                      setEtc(false)
                      handleClick(item.type)
                    }
                  }}
                >
                  <p className="school_list_text">{item.type}</p>
                </div>
              ))}
            </SelectBox>
          )}
        </div>
        <div
          className="input_box"
          onClick={() => {
            setGrade(true)
          }}
        >
          {etc ? (
            <Input
              type="text"
              placeholder="학년 입력"
              value={gradeValue}
              onChange={handleChange}
              maxLength={20}
            />
          ) : (
            <>
              <input
                type="text"
                readOnly
                placeholder="학년 선택"
                className={grade ? 'focus' : ''}
                value={gradeValue ?? ''}
                onBlur={handleBlur}
              />
              {grade ? (
                <UpArrow className="arrow" />
              ) : (
                <Arrow className="arrow" />
              )}
              {grade && (
                <SelectBox>
                  {schoolGrade.map((item, i) =>
                    item.grade.map(
                      (list, index) =>
                        item.type === value && (
                          <div
                            key={`school_grade_list_${index}`}
                            className="school_list_content"
                            onClick={() => {
                              handleGradeClick(list.key)
                            }}
                          >
                            <p className="school_list_text">{list.key}</p>
                          </div>
                        ),
                    ),
                  )}
                </SelectBox>
              )}
            </>
          )}
        </div>
      </Content>
    </Wrapper>
  )
}

export default JoinSelectBox

const Wrapper = styled.div``
const Title = styled.div`
  & p.selet_title {
    font-weight: 700;
    font-size: 13px;
    line-height: 22px;
    color: #0d0d0e;
    margin-bottom: 4px;
  }
`
const Content = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  & div.input_box {
    position: relative;
    width: calc(50% - 10px);
  }
  & input {
    width: 100%;
    border: none;
    border-bottom: 1px solid #e1e1e2;
    outline: none;
    padding: 7px 30px 7px 0;
    cursor: pointer;
    &::placeholder {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #b5b5b9;
    }
    &.focus {
      border-bottom: 1px solid #0d0d0e;
    }
  }
  & input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px #fff inset;
  }
  & svg.arrow {
    position: absolute;
    right: 0;
    bottom: 14px;
  }
`
const SelectBox = styled.div`
  position: absolute;
  left: 0;
  top: 40px;
  width: 100%;
  border: 1px solid #0d0d0e;
  background: #fff;
  border-radius: 4px;
  z-index: 10;
  & div.school_list_content {
    padding: 10px;
  }
  & div.school_list_content:hover {
    background: #f9f9fb;
    & > p {
      font-weight: 500;
    }
  }
  & div.school_list_content:first-of-type:hover {
    border-radius: 4px 4px 0 0;
  }
  & div.school_list_content:last-of-type:hover {
    border-radius: 0 0 4px 4px;
  }
  & p.school_list_text {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #0d0d0e;
    cursor: pointer;
  }
`
