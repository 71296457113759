import RoomMobileHeader from 'components/room/common/RoomMobileHeader'
import ChatContainer from 'containers/room/side/ChatContainer'
import React from 'react'
import styled from 'styled-components'
import { MobileRoomWrapper } from './RoomParticipantList'

function RoomChatList() {
  return (
    <MobileRoomWrapper>
      <RoomMobileHeader title="채팅" />
      <ChatContainer />
    </MobileRoomWrapper>
  )
}

export default RoomChatList
