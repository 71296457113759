import useUser from 'lib/hooks/account/useUser'
import React from 'react'
import styled from 'styled-components'
import { useChatValues } from '../provider/RoomChatProvider'
import Chat from './Chat'

function ChatList() {
  const { data } = useChatValues()
  const { user } = useUser()
  return (
    <ChatListWrapper>
      <div className="list-wrapper">
        {data.chats.map((chat) => (
          <Chat chat={chat} isMe={chat.user_id === user.id} key={chat.id} />
        ))}
      </div>
    </ChatListWrapper>
  )
}

export const ChatListWrapper = styled.div`
  flex: 1;
  overflow-y: hidden;
  & .list-wrapper {
    height: 100%;
    overflow-y: scroll;
    flex: 1;
    display: flex;
    flex-direction: column-reverse;

    gap: 20px;
    padding: 20px 20px 0 20px;
  }
`

export default ChatList
