import React, { useRef } from 'react'
import styled from 'styled-components'
import { ReactComponent as UploadIcon } from 'assets/icons/upload_icon.svg'
import { useParams } from 'react-router-dom'
import useRoom from 'lib/hooks/room/useRoom'

function ReferenceUpload() {
  const ref = useRef<HTMLInputElement>(null)
  const { roomId }: { roomId: string } = useParams()
  const { data, uploadReference } = useRoom(roomId)

  const handleClick = () => {
    ref.current?.click()
  }

  const uploadFile: React.ChangeEventHandler<HTMLInputElement> = async (e) => {
    const files = e.target.files

    if (!files || !data?.room) return

    const file = files[0]
    uploadReference(file)
  }

  return (
    <Wrapper onClick={handleClick}>
      <UploadIcon />
      <input
        type="file"
        className="input"
        ref={ref}
        onChange={uploadFile}
        multiple={false}
        accept="*"
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: absolute;
  width: 64px;
  height: 64px;
  right: 20px;
  bottom: 16px;
  background: #7574d8;

  color: #fff;

  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  & .input {
    display: none;
  }
`

export default ReferenceUpload
