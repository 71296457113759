import IconWrap from 'components/common/IconWrap'
import React from 'react'
import styled from 'styled-components'
import RoomButton from '../common/RoomButton'
import { ReactComponent as CameraOnIcon } from 'assets/icons/camera_on_icon.svg'
import { ReactComponent as CameraOffIcon } from 'assets/icons/camera_off_icon.svg'
import { ReactComponent as MikeOnIcon } from 'assets/icons/mike_on_icon.svg'
import { ReactComponent as MikeOffIcon } from 'assets/icons/mike_off_icon.svg'
import {
  useRoomInfoAction,
  useRoomInfoValue,
} from '../provider/RoomInfoProvider'
import { SideFooterWrapper } from './StudentRoomSideFooter'
import useRoom from 'lib/hooks/room/useRoom'
import { useParams } from 'react-router-dom'

interface Props {
  isTeamRoom?: boolean
}

function TutorRoomSideFooter({ isTeamRoom }: Props) {
  const { roomId, team }: { roomId: string; team: 'A' | 'B' } = useParams()
  const { tutorRoomInfo } = useRoomInfoValue()
  const { modifyTutorInfo } = useRoomInfoAction()
  const {
    modifyDebateStatusTrigger,
    debateStatus,
    exitRoomTrigger,
    closeTeamRoomTrigger,
  } = useRoom(roomId)

  const handleToggleCam = () => {
    if (tutorRoomInfo?.isSecretEnter) return
    modifyTutorInfo &&
      modifyTutorInfo({
        isCam: tutorRoomInfo?.is_camera !== 1,
        isMike: tutorRoomInfo?.is_mike === 1,
      })
  }

  const handleToggleMike = () => {
    if (tutorRoomInfo?.isSecretEnter) return
    modifyTutorInfo &&
      modifyTutorInfo({
        isCam: tutorRoomInfo?.is_camera === 1,
        isMike: tutorRoomInfo?.is_mike !== 1,
      })
  }

  const modifyDebateStatus = () => {
    const status = debateStatus === 6 ? 0 : 8

    modifyDebateStatusTrigger(status)
  }

  const handleExitTeamRoom = () => {
    closeTeamRoomTrigger(team)
  }

  return (
    <Wrapper>
      <div className="button-area">
        {isTeamRoom && (
          <RoomButton
            background="rgba(249, 249, 251, 0.1)"
            onClick={handleExitTeamRoom}
          >
            소회의방 종료
          </RoomButton>
        )}
        {!isTeamRoom && debateStatus !== 8 && (
          <RoomButton
            background="rgba(249, 249, 251, 0.1)"
            onClick={modifyDebateStatus}
          >
            {debateStatus === 6 ? '디베이팅 시작' : '온라인교실'}
          </RoomButton>
        )}
        {!isTeamRoom && debateStatus !== 6 && (
          <RoomButton
            background="rgba(249, 249, 251, 0.1)"
            onClick={exitRoomTrigger}
          >
            강의종료
          </RoomButton>
        )}
      </div>

      <IconWrap onClick={handleToggleCam}>
        {tutorRoomInfo?.is_camera === 1 ? <CameraOffIcon /> : <CameraOnIcon />}
      </IconWrap>
      <IconWrap onClick={handleToggleMike}>
        {tutorRoomInfo?.is_mike === 1 ? <MikeOffIcon /> : <MikeOnIcon />}
      </IconWrap>
    </Wrapper>
  )
}

const Wrapper = styled(SideFooterWrapper)`
  & .button-area {
    flex: 1;

    display: flex;
    gap: 8px;
  }
`

export default TutorRoomSideFooter
