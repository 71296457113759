import Footer from 'components/common/Footer'
import Header from 'components/common/header/Header'
import MobileTitleHeader from 'components/common/header/MobileTitleHeader'
import LikeClassComponent from 'components/common/like/LikeClassComponent'
import useUser from 'lib/hooks/account/useUser'
import useLike from 'lib/hooks/useLike'
import media from 'lib/styles/media'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

function LikeClassPage() {
  const { user } = useUser()
  const studentUser = user && user.type === 'user'
  const type = 'like'
  const { data: likeData, target } = useLike({ type })
  const history = useHistory()

  useEffect(() => {
    if (!studentUser) return history.push('/login')
  }, [])

  if (!likeData) return null
  if (!likeData.data) return null
  if (!likeData.data.classes) return null

  return (
    <Wrapper>
      <Header />
      <MobileTitleHeader type="arrow" title="찜한 강의" />
      <ContentWra>
        <LikeClassComponent data={likeData.data.classes.data} target={target} />
      </ContentWra>
      <Footer />
    </Wrapper>
  )
}

export default LikeClassPage

const Wrapper = styled.div``
const ContentWra = styled.div`
  margin-top: 140px;
  min-height: 600px;
  ${media.medium} {
    margin-top: 10px;
    min-height: 400px;
  }
`
