import TutorRoomFooter from 'components/room/footer/TutorRoomFooter'
import RoomContainer from 'containers/room/RoomContainer'
import RoomTutorSideContainer from 'containers/room/RoomTutorSideContainer'
import React from 'react'
import styled from 'styled-components'

function TutorRoomPage() {
  return (
    <Wrapper>
      <RoomContainer />
      <RoomTutorSideContainer />
      <TutorRoomFooter />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
`

export default TutorRoomPage
