import { AppRootWrapper } from 'components/layout/CommonStyle'
import media from 'lib/styles/media'
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Button from '../Button'
import CheckPopup from '../CheckPopup'
import { useHistory } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { FindPasswordPayloadState } from 'atoms/join'
import Input from '../Input'
import api from 'lib/api'
import PasswordInput from '../PasswordInput'
import {
  ErrorPwCheckMessage,
  ErrorPwMessage,
  resPw,
} from 'lib/utils/CommonFuncttion'
import { PageTypeState } from 'atoms/common'

function FindPwSuccessComponent() {
  const [payload, setPayload] = useRecoilState(FindPasswordPayloadState)
  const [popup, setPopup] = useState(false)
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const history = useHistory()
  const [pageType, setPageType] = useRecoilState(PageTypeState)

  useEffect(() => {
    setPayload({
      ...payload,
      password: '',
      checkPassword: '',
    })
  }, [])

  useEffect(() => {
    if (popup) {
      document.documentElement.style.overflow = 'hidden'
    } else {
      document.documentElement.style.overflow = 'auto'
    }

    return () => {
      document.documentElement.style.overflow = 'auto'
    }
  }, [popup])

  const handleCheckPassword = () => {
    if (!payload?.password) {
      return setError(false)
    }

    if (!payload?.checkPassword) {
      if (!resPw.test(String(payload?.password))) {
        setError(true)
        setErrorMessage(ErrorPwMessage)
      } else {
        setError(false)
      }
    } else {
      if (payload?.password === payload?.checkPassword) {
        if (!resPw.test(String(payload?.password))) {
          setError(true)
          setErrorMessage(ErrorPwMessage)
        } else {
          setError(false)
        }
      } else {
        if (!resPw.test(String(payload?.password))) {
          setError(true)
          setErrorMessage(ErrorPwCheckMessage)
        } else {
          setError(true)
          setErrorMessage('비밀번호가 일치하지 않습니다.')
        }
      }
    }
  }

  const handleChange = (type: string) => (e: any) => {
    setPayload({
      ...payload,
      [type]: e.target.value,
    })
    handleCheckPassword()
  }
  const handleSubmit = async () => {
    const formData = new FormData()
    formData.append('type', pageType === 'tutor' ? 'tutor' : 'user')
    formData.append('phone', String(payload?.phone))
    formData.append('password', String(payload?.password))
    formData.append('email', String(payload?.email))
    formData.append('code', String(payload?.code))

    const {
      data: { success, alert },
    } = await api.post('/find/password/reset', formData)

    if (success) {
      setPopup(true)
    } else {
      window.alert(alert)
    }
  }
  const handleCheck = () => {
    setPopup(false)
    pageType === 'tutor'
      ? history.push('/login?role=tutor')
      : history.push('/login')
  }

  return (
    <Wrapper>
      <Content>
        <div className="title_box">
          <p className="find_info_title">비밀번호 재설정</p>
          <p className="find_info_text">변경하실 비밀번호를 입력해주세요.</p>
        </div>
        <div className="find_content_box">
          <PasswordInput
            title="비밀번호"
            placeholder="영문, 숫자, 특수문자 포함 8~20자"
            value={payload?.password}
            onChange={handleChange('password')}
            error={error}
            maxLength={20}
            onKeyUp={handleCheckPassword}
          />
          <PasswordInput
            title="새 비밀번호 확인"
            placeholder="비밀번호 재입력"
            value={payload?.checkPassword}
            onChange={handleChange('checkPassword')}
            error={error}
            errorPwMessage={errorMessage}
            maxLength={20}
            onKeyUp={handleCheckPassword}
          />
        </div>
        <div className="find_button_box">
          <Button
            title="변경"
            onClick={handleSubmit}
            disabled={
              error ||
              String(payload?.password).length < 8 ||
              String(payload?.checkPassword).length < 8
            }
          />
        </div>
      </Content>
      {popup && (
        <CheckPopup
          onClick={handleCheck}
          title="비밀번호 변경 완료"
          content="새로운 비밀번호로 로그인해 주세요."
        />
      )}
    </Wrapper>
  )
}

export default FindPwSuccessComponent
const Wrapper = styled(AppRootWrapper)``
const Content = styled.div`
  width: 440px;
  height: 680px;
  margin: 0 auto;
  padding: 80px 0 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${media.medium} {
    padding: 20px 0 80px;
    height: 100%;
    width: 100%;
  }

  & p.find_info_title {
    font-weight: 700;
    font-size: 22px;
    line-height: 34px;
    text-align: center;
    color: #0d0d0e;
    ${media.medium} {
      font-size: 18px;
      line-height: 28px;
    }
  }
  & p.find_info_text {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #3b3b40;
    margin-top: 6px;
  }
  & div.find_content_box {
    width: 380px;
    margin-top: 50px;
    & > div:first-of-type {
      margin-bottom: 20px;
    }
    ${media.medium} {
      width: 100%;
    }
  }
  & div.find_button_box {
    width: 380px;
    margin-top: 40px;
    ${media.medium} {
      width: 100%;
    }
  }
`
