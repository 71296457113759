import { audiencesModalState } from 'atoms/modal'
import { useRoomInfoValue } from 'components/room/provider/RoomInfoProvider'
import React, { useMemo } from 'react'
import { useRecoilState } from 'recoil'
import styled from 'styled-components'
import ModalWrapper from './ModalWrapper'
import { ReactComponent as CloseIcon } from 'assets/icons/close_icon.svg'
import Button from 'components/common/Button'
import AudienceComponent from 'components/room/common/AudienceComponent'

import useSWR from 'swr'
import { useParams } from 'react-router-dom'
import useAuduence from 'lib/hooks/room/useAuduence'
import EmptyAudience from 'components/room/common/EmptyAudience'

function AudiencesModal() {
  const [modal, setModal] = useRecoilState(audiencesModalState)

  const { isDebating, endDate } = useRoomInfoValue()
  const { roomId }: { roomId: string } = useParams()
  const { data } = useAuduence(roomId)

  const handleClose = () => {
    setModal(null)
  }

  const buttonText = useMemo(() => {
    if (endDate.vote) return '투표진행중'
    if (isDebating) return '투표시작'
    return '토론 진행시 투표를 진행할 수 있습니다'
  }, [isDebating, endDate])

  const handleSubmit = () => {
    handleClose()
    modal?.onSubmit()
  }

  const isDisabled = useMemo(() => {
    return !!endDate?.vote || !isDebating || (data?.data || []).length === 0
  }, [isDebating, data, endDate])

  if (!data) return null

  return (
    <ModalWrapper onClose={handleClose}>
      <Wrapper>
        <div className="header">
          <h3 className="title">방청객 ({data?.total ?? 0})</h3>
          <CloseIcon className="close-icon" onClick={handleClose} />
        </div>
        <div className="content">
          {(data?.data ?? []).length === 0 && <EmptyAudience />}
          {data?.data.reverse().map((audience: any) => (
            <AudienceComponent audience={audience} key={audience.id} />
          ))}
        </div>
        <div className="footer">
          {(data?.data || []).length !== 0 && (
            <Button
              title={buttonText}
              disabled={isDisabled}
              onClick={handleSubmit}
            />
          )}
        </div>
      </Wrapper>
    </ModalWrapper>
  )
}

const Wrapper = styled.div`
  width: 380px;
  padding: 26px;

  border-radius: 14px;
  background: #fff;

  & .header {
    display: flex;
    justify-content: space-between;

    padding-bottom: 10px;

    & .title {
      font-weight: 700;
      font-size: 18px;
      color: #000000;
    }

    & .close-icon {
      cursor: pointer;
    }
  }

  & .content {
    height: 290px;
    padding-top: 10px;

    overflow: scroll;

    display: flex;
    flex-direction: column;
    gap: 14px;
  }

  & .footer {
    padding-top: 10px;
  }
`

export default AudiencesModal
