import Gnb from 'components/common/Gnb'
import MobileTitleHeader from 'components/common/header/MobileTitleHeader'
import StudentMenuHeader from 'components/common/header/StudentMenuHeader'
import StudentMenuComponent from 'components/common/menu/StudentMenuComponent'
import media from 'lib/styles/media'
import React from 'react'
import styled from 'styled-components'

function StudentMenu() {
  return (
    <Wrapper>
      <StudentMenuHeader />
      <MobileTitleHeader title="더보기" />
      <ContentWra>
        <StudentMenuComponent />
      </ContentWra>
      <Gnb />
    </Wrapper>
  )
}

export default StudentMenu

const Wrapper = styled.div``
const ContentWra = styled.div`
  margin-top: 140px;
  ${media.medium} {
    margin-top: 5px;
    margin-bottom: 60px;
  }
`
