import type { MyClassPaymentDetailDataProps } from 'lib/types/account'
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import PaymentClassComponent from './PaymentClassComponent'
import ClassCancelPopup from '../ClassCancelPopup'
import api from 'lib/api'
import media from 'lib/styles/media'
import TwoCheckPopup from '../TwoCheckPopup'
import { CancelContents, tutorPaymentCancel } from 'lib/utils/CommonFuncttion'
import CheckPopup from '../CheckPopup'

interface Props {
  data: MyClassPaymentDetailDataProps
  type: string
  pageType: string
}
function PaymentClssList({ data, type, pageType }: Props) {
  const [popup, setPopup] = useState(false)
  const [cancelPopup, setCancelPopup] = useState(false)
  const [checkPopup, setCheckPopup] = useState(false)
  const [reason, setReason] = useState('')
  const [userId, setUserId] = useState('')
  const [id, setId] = useState('')
  const [title, setTitle] = useState('')
  const [content, setContent] = useState('')
  const [btn, setBtn] = useState('')
  const [popupType, setPopupType] = useState('')

  useEffect(() => {
    if (popup || cancelPopup || checkPopup) {
      document.documentElement.style.overflow = 'hidden'
    } else {
      document.documentElement.style.overflow = 'auto'
    }

    return () => {
      document.documentElement.style.overflow = 'auto'
    }
  }, [popup, cancelPopup, checkPopup])

  const handleCancelClick = (id: number) => {
    setUserId(String(id))
    setPopup(true)
  }

  const handleClose = () => {
    setPopup(false)
  }
  const handleBackClick = (e: any) => {
    if (e.target.id === 'popup') {
      if (popup) setPopup(false)
    }
  }
  const onCancelClick = async () => {
    setCheckPopup(false)
    const formdata = new FormData()
    formdata.append('class_user_id', userId)
    formdata.append('reason', reason)
    const {
      data: { success, alert },
    } = await api.post('/class/cancel/request', formdata)

    if (success) {
      setTimeout(() => {
        window.location.reload()
      }, 130)
    } else {
      window.alert(alert)
    }
  }
  // 결제완료 > 결제취소
  const handleTutorCancel = (id: number, multi: number | undefined) => {
    setId(String(id))
    setPopupType('cancel')
    if (multi === 0) {
      setTitle('결제 취소')
      setContent(tutorPaymentCancel)
      setBtn('결제 취소')
    } else {
      setTitle('결제 취소')
      setContent('남은 회차의 결제를 모두 취소하시겠습니까?')
      setBtn('결제 취소')
    }
    setCancelPopup(true)
  }
  const handleCancelSubmit = async () => {
    const formdata = new FormData()
    formdata.append('payment_history_id', id)
    const {
      data: { success, alert },
    } = await api.post('/class/cancel/payment', formdata)

    if (success) {
      setTimeout(() => {
        window.location.reload()
      }, 130)
    } else {
      window.alert(alert)
    }
    setCancelPopup(false)
  }
  // 취소신청 > 취소신청 반려
  const handleCancelReject = (id: number) => {
    setId(String(id))
    setPopupType('reject')
    setTitle('취소 신청 반려')
    setContent('수강생의 취소 신청을 반려하시겠습니까?')
    setBtn('반려')
    setCancelPopup(true)
  }
  const handleCancelRejectSubmit = async () => {
    const formdata = new FormData()
    formdata.append('class_user_cancel_request_id', id)
    const {
      data: { success, alert },
    } = await api.post('/class/cancel/request/reject', formdata)

    if (success) {
      setTimeout(() => {
        window.location.href = '/payment?type=tutor_price_cancel&role=tutor'
      }, 130)
    } else {
      window.alert(alert)
    }
    setCancelPopup(false)
  }
  // 취소신청 > 취소신청 승인
  const handleCancelAccept = (id: number) => {
    setId(String(id))
    setPopupType('accept')
    setTitle('취소 신청 승인')
    setContent(
      '승인 시 수강 취소 및 결제 금액이 환불됩니다.\n선택한 취소 신청을 승인하시겠습니까?',
    )
    setBtn('승인')
    setCancelPopup(true)
  }
  const handleCancelAcceptSubmit = async () => {
    const formdata = new FormData()
    formdata.append('class_user_cancel_request_id', id)
    const {
      data: { success, alert },
    } = await api.post('/class/cancel/request/accept', formdata)

    if (success) {
      setTimeout(() => {
        window.location.reload()
      }, 130)
    } else {
      window.alert(alert)
    }
    setCancelPopup(false)
  }

  return (
    <Wrapper>
      <p className="class_date">{data.created_at}</p>
      <div className="class_info_box">
        {data.payment_histories.map((item, i) => (
          <PaymentClassComponent
            key={`my_class_payment_list_${i}`}
            image={item.image_url}
            title={item.title}
            nickname={item?.tutor_nickname}
            price={item.price}
            orderNum={item.order_number}
            handleCancelClick={handleCancelClick}
            id={item.id}
            type={type}
            popup={popup}
            userId={item.class_user_id}
            userProfile={item?.user_profile_url}
            userNickname={item?.user_nickname}
            pageType={pageType}
            multi={item.is_multi}
            step={item.step}
            stepTitle={item.step_title}
            handleTutorCancel={handleTutorCancel}
            handleCancelReject={handleCancelReject}
            handleCancelAccept={handleCancelAccept}
            status={item.payment_status}
            cancelId={item.class_user_cancel_request_id}
          />
        ))}
      </div>
      {popup && (
        <ClassCancelPopup
          value={reason}
          onChange={(e) => {
            setReason(e.target.value)
          }}
          handleClose={handleClose}
          handleBackClick={handleBackClick}
          onCancelClick={() => {
            setPopup(false)
            setCheckPopup(true)
          }}
        />
      )}
      {cancelPopup && (
        <TwoCheckPopup
          title={title}
          content={content}
          onClick={() => {
            if (popupType === 'reject') {
              handleCancelRejectSubmit()
            } else if (popupType === 'accept') {
              handleCancelAcceptSubmit()
            } else {
              handleCancelSubmit()
            }
          }}
          oncancelClick={() => {
            setCancelPopup(false)
          }}
          btn={btn}
        />
      )}
      {checkPopup && (
        <CheckPopup
          onClick={onCancelClick}
          title="수강취소 신청 완료"
          content={CancelContents}
        />
      )}
    </Wrapper>
  )
}

export default PaymentClssList
const Wrapper = styled.div`
  margin-bottom: 60px;
  ${media.medium} {
    margin-bottom: 50px;
  }
  & p.class_date {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #b5b5b9;
    margin-bottom: 16px;
    ${media.medium} {
      font-size: 13px;
      line-height: 22px;
      margin-bottom: 12px;
    }
  }
  & div.class_info_box {
    display: flex;
    flex-wrap: wrap;
    & > div {
      margin-right: 20px;
      margin-bottom: 20px;
      ${media.medium} {
        margin-right: 0;
        margin-bottom: 12px;
      }
    }
    & > div:nth-of-type(2n) {
      margin-right: 0;
    }
  }
`
