import moment from 'moment'

export const PriceNum = (price) => {
  return String(price).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const dateFormet = (date) => {
  const time = moment(date)
  const day = time.day()
  const text =
    day === 0
      ? '일'
      : day === 1
      ? '월'
      : day === 2
      ? '화'
      : day === 3
      ? '수'
      : day === 4
      ? '목'
      : day === 5
      ? '금'
      : '토'

  return `${time.format('YYYY.MM.DD')} (${text})`
}

export const dateHyphenFormet = (date) => {
  const time = moment(date)
  const day = time.day()
  const text =
    day === 0
      ? '일'
      : day === 1
      ? '월'
      : day === 2
      ? '화'
      : day === 3
      ? '수'
      : day === 4
      ? '목'
      : day === 5
      ? '금'
      : '토'

  return `${time.format('YYYY-MM-DD')}(${text}) ${time.format('HH:mm')}`
}

export const dateBasicFormet = (date) => {
  const time = moment(date)
  return `${time.format('YYYY.MM.DD')} ${time.format('HH:mm')}`
}
export const dateBasicHyphenFormet = (date) => {
  const time = moment(date)
  return `${time.format('YYYY-MM-DD')} ${time.format('HH:mm')}`
}

export const timeFormet = (date) => {
  const time = moment(date)

  return `${time.format('HH:mm')}`
}

export const dateDiff = (date, date2) => {
  const time1 = moment(date)
  const time2 = moment(date2)
  const duration = moment.duration(time2.diff(time1))
  const hours = duration.asMinutes()
  return `(${Math.floor(hours)}분)`
}

export const dateDiffHour = (date, date2) => {
  const time1 = moment(date)
  const time2 = moment(date2)
  const duration = moment.duration(time2.diff(time1))
  const hours = duration.asMinutes()
  const hour = Math.floor(hours / 60)
  return `(${hour > 0 ? hour + '시간 ' : ''}${Math.floor(hours % 60)}분)`
}

export const dateHour = (time) => {
  const hour = Math.floor(time / 60)
  const minute = Math.floor(time % 60)

  return `(${hour > 0 ? hour + '시간' : ''}${
    hour > 0 && minute > 0 ? ' ' : ''
  }${minute > 0 ? minute + '분' : hour > 0 ? '' : '0분'})`
}

export function convertToMinute(second) {
  const minute = Math.floor(second / 60)
  const remainSecond = second % 60
  const resultSecond = remainSecond < 10 ? `0${remainSecond}` : remainSecond
  return `${minute} : ${resultSecond}`
}

export const Error0001 = `입력하신 휴대폰 번호로\n이미 가입된 계정이 존재합니다.\n'아이디 찾기' 를 통해 회원 정보를 찾거나,\n고객센터로 문의해주세요.`
export const Error0002 = `탈퇴 24시간 이후에 재가입이 가능합니다.\n내일 다시 시도해주세요.`
export const Error0003 = `강제 탈퇴된 회원입니다.\n자세한 내용은 고객센터로 문의해주세요.`
export const Error0004 = `일일 인증번호 발송 횟수를 초과했습니다.\n내일 다시 시도해주세요.`
export const ErrorFindId = `입력한 휴대폰 번호로\n가입된 계정이 존재하지 않습니다.`
export const regId = /^(?=.*[a-zA-Z])(?=.*[0-9]).{8,20}$/
export const resId = /^[a-zA-Z]{1,}[0-9]{1,}$/
export const resPw = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,20}$/
export const resNicknamge = /[^\w\sㄱ-힣()0-9 ]/g
export const resSpace = /\s/g
export const withdrawalSuccess = `회원 탈퇴가 완료되었습니다.\n디베이트온을 이용해주셔서 감사합니다.`
export const tutorWithdrawalSuccess = `튜터 탈퇴가 완료되었습니다.\n디베이트온을 이용해주셔서 감사합니다.`
export const withdrawalAlert = `예정된 강의가 존재하여 회원탈퇴가 불가능합니다.\n수강 완료 또는 튜터를 통해 취소 후 다시 시도해주세요.`
export const tutorWithdrawalAlert = `정산되지 않은 결제 건이 존재하여,\n회원 탈퇴가 불가능합니다.`
export const tutorPaymentCancel = `결제 취소 시,\n수강 취소 및 결제 금액이 환불됩니다.\n선택한 결제를 취소하시겠습니까?`
export const ErrorPwMessage = `영문, 숫자, 특수문자 조합 8-20자로 설정하실 수 있습니다.`
export const ErrorPwCheckMessage = `영문, 숫자, 특수문자 조합 8-20자로 설정하실 수 있습니다.\n비밀번호가 일치하지 않습니다.`
export const CancelContents = `수강취소 신청이 완료되었습니다.\n튜터의 취소 신청 승인 후 결제한 금액의 환불이\n진행되며, 강의가 취소 처리됩니다.`

export const paymentMethod = (type) => {
  // card : 카드결제 / bank : 실시간 계좌이체 / kakao : 카카오페이 / naver : 네이버 페이
  if (type === 'NAVER') {
    return '네이버 페이'
  } else if (type === 'BANK') {
    return '실시간 계좌이체'
  } else if (type === 'KAKAO') {
    return '카카오페이'
  } else {
    return '카드결제'
  }
}
export const bankType = [
  { id: '1', type: '산업은행' },
  { id: '2', type: '기업은행' },
  { id: '3', type: '국민은행' },
  { id: '4', type: '수협' },
  { id: '5', type: '농협' },
  { id: '6', type: '지역농축협' },
  { id: '7', type: '우리은행' },
  { id: '8', type: 'SC 제일은행' },
  { id: '9', type: '한국씨티은행' },
  { id: '10', type: '대구은행' },
  { id: '11', type: '부산은행' },
  { id: '12', type: '광주은행' },
  { id: '13', type: '제주은행' },
  { id: '14', type: '전북은행' },
  { id: '15', type: '경남은행' },
  { id: '16', type: '새마을금고' },
  { id: '17', type: '신협' },
  { id: '18', type: '저축은행' },
  { id: '19', type: '우체국' },
  { id: '20', type: '하나은행' },
  { id: '21', type: '신한은행' },
  { id: '22', type: '케이뱅크' },
  { id: '23', type: '카카오뱅크' },
]

export const handleCopy = (text) => {
  const textarea = document.createElement('textarea')
  document.body.appendChild(textarea)
  textarea.value = text
  textarea.select()
  document.execCommand('copy')
  document.body.removeChild(textarea)
}

export const phoneFormet = (phone) => {
  return phone.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`)
}
// 0-결제 완료, 1-수강 완료, 2-정산 완료, 9-취소 신청, 10-결제 취소
export const paymentStaus = (type) => {
  if (type === 0) {
    return '결제 완료'
  } else if (type === 1) {
    return '수강 완료'
  } else if (type === 2) {
    return '정산 완료'
  } else if (type === 9) {
    return '취소 신청'
  } else {
    return '결제 취소'
  }
}
