import { type RoomSeatInfoType } from 'lib/types/room'
import React, { useState } from 'react'
import OutsideClickHandler from 'react-outside-click-handler'
import styled from 'styled-components'
import { type ExchangedSeatType } from '../provider/RoomInfoProvider'
import { ReactComponent as GoodIcon } from 'assets/icons/good_icon.svg'
import { ReactComponent as OptionIcon } from 'assets/icons/option_icon.svg'
import { ReactComponent as CheckboxIcon } from 'assets/icons/checkbox_button_icon.svg'

import RoomMemberPopover from '../common/RoomMemberPopover'
import media from 'lib/styles/media'

interface RoomMemberOptionProps {
  exchangedSeat?: ExchangedSeatType
  seat: RoomSeatInfoType
  onExitUser: () => void
  onExchangeSeatBefore: () => void
  onExchangeSeatAfter: () => void
  onResetExchangeSeat: () => void
  onComplement: () => void
}

function RoomMemberOption({
  exchangedSeat,
  seat,
  onExitUser,
  onExchangeSeatBefore,
  onExchangeSeatAfter,
  onResetExchangeSeat,
  onComplement,
}: RoomMemberOptionProps) {
  const [popover, setPopover] = useState(false)
  const togglePopover = () => {
    setPopover(!popover)
  }

  const closePopover = () => {
    setPopover(false)
  }

  const handleClickExchangeSeat = () => {
    if (seat.user_id === exchangedSeat?.userId) {
      onResetExchangeSeat()
    } else {
      onExchangeSeatAfter()
    }
  }

  if (exchangedSeat)
    return (
      <Wrapper>
        <div className="check-button" onClick={handleClickExchangeSeat}>
          {exchangedSeat.userId === seat?.user_id ? '취소' : <CheckboxIcon />}
        </div>
      </Wrapper>
    )

  return (
    <Wrapper>
      <div className="icon-wrap" onClick={onComplement}>
        <GoodIcon />
      </div>

      <OutsideClickHandler onOutsideClick={closePopover}>
        <div className="icon-wrap" onClick={togglePopover}>
          <OptionIcon />
          {popover && (
            <RoomMemberPopover
              onExitUser={onExitUser}
              onExchangeSeat={onExchangeSeatBefore}
            />
          )}
        </div>
      </OutsideClickHandler>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;

  display: flex;
  gap: 5px;

  & .icon-wrap,
  .check-button {
    position: relative;
    width: 36px;
    height: 36px;
    background: rgba(13, 13, 14, 0.6);
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
  }

  & .check-button {
    background: inherit;
    color: #ffffff;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    font-weight: 500;
    font-size: 12px;
  }

  & .nickname {
    ${media.pc} {
      position: absolute;
      right: 10px;
      bottom: 10px;
      border-radius: 6px;
      padding: 10px 12px;
      background: rgba(13, 13, 14, 0.6);
    }

    ${media.medium} {
      font-weight: 500;
      font-size: 13px;
      line-height: 22px;

      display: flex;
      align-items: center;
      margin-left: 10px;
    }

    color: #fff;
  }
`

export default RoomMemberOption
