import {
  dateBasicHyphenFormet,
  dateDiffHour,
  dateHour,
  dateHyphenFormet,
} from 'lib/utils/CommonFuncttion'
import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'

import { ReactComponent as Arrow } from '../../../assets/icons/join_select_button_up_arrow.svg'
import moment from 'moment'

interface Props {
  id: number
  is_not_progress: number
  status: number
  step: number
  step_begin_date: string
  step_estimated_time: number
  step_real_begin_date: string
  step_real_end_date: string
  step_title: string
}

const ClassStepSelect = ({
  step,
  select,
  onChange,
  type,
}: {
  step: Props[]
  select: Props
  type: string
  onChange: (info: Props, i: number) => void
}) => {
  const contentRef = useRef(null)
  const arrowRef = useRef(null)

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    arrowRef.current.style.transform = `translateY(-50%) rotate(0deg)`
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    contentRef.current.style.height = '0px'

    document.body.addEventListener('click', (e) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      const value = e.target.classList.value

      if (!value.includes('button')) {
        try {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          contentRef.current.style.height = '0px'
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          arrowRef.current.style.transform = `translateY(-50%) rotate(0deg)`
        } catch (error) {}
      }
    })
  }, [])

  const handleClick = () => {
    if (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      arrowRef.current.style.transform === `translateY(-50%) rotate(0deg)`
    ) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      contentRef.current.style.height = contentRef.current.scrollHeight + 'px'
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      arrowRef.current.style.transform = `translateY(-50%) rotate(180deg)`
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      contentRef.current.style.height = '0px'
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      arrowRef.current.style.transform = `translateY(-50%) rotate(0deg)`
    }
  }

  return (
    <>
      <Wrapper className="button" onClick={handleClick}>
        <List className="top button">
          <StepInfo className="button">
            <b className="button">{select.step}회차</b>
            <p className="button">{select.step_title}</p>
          </StepInfo>
          <p className="button">
            {select.status === 2 ? (
              <>
                {dateHyphenFormet(select.step_real_begin_date)} ~{' '}
                {moment(select.step_real_begin_date).format('YYYY.MM.DD') ===
                moment(select.step_real_end_date).format('YYYY.MM.DD')
                  ? moment(select.step_real_end_date).format('HH:mm')
                  : dateHyphenFormet(select.step_real_end_date)}{' '}
                {select.is_not_progress === 1
                  ? ' 미진행'
                  : ' ' +
                    dateDiffHour(
                      select.step_real_begin_date,
                      select.step_real_end_date,
                    )}
              </>
            ) : (
              <>
                {dateBasicHyphenFormet(select.step_begin_date)}
                {dateHour(select.step_estimated_time)}
              </>
            )}
          </p>
        </List>
        <Arrow ref={arrowRef} className="button" />
        <ListArea ref={contentRef} className="button">
          <div className="button">
            {step.map((item, i) => {
              let disabled = false

              if (step.length > 1) {
                if (type === 'student') {
                  if (i > 0) {
                    disabled = !moment().isAfter(
                      moment(item.step_begin_date).subtract(30, 'minutes'),
                    )
                  }
                } else {
                  if (i > 0) {
                    disabled =
                      step[i - 1].status < 2 &&
                      !moment().isAfter(
                        moment(item.step_begin_date).subtract(30, 'minutes'),
                      )
                  }
                }
              }

              return (
                <List
                  key={'step_list_' + i}
                  className={
                    'list button' +
                    (select.id === item.id ? ' select' : '') +
                    (disabled ? ' disable' : '')
                  }
                  onClick={() => {
                    if (!disabled) onChange(item, item.step)
                  }}
                >
                  <StepInfo className="button">
                    <b className="button">{item.step}회차</b>
                    <p className="button">{item.step_title}</p>
                  </StepInfo>
                  <p className="button">
                    {item.status === 2 ? (
                      <>
                        {dateHyphenFormet(item.step_real_begin_date)} ~{' '}
                        {moment(item.step_real_begin_date).format(
                          'YYYY.MM.DD',
                        ) ===
                        moment(item.step_real_end_date).format('YYYY.MM.DD')
                          ? moment(item.step_real_end_date).format('HH:mm')
                          : dateHyphenFormet(item.step_real_end_date)}{' '}
                        {item.is_not_progress === 1
                          ? ' 미진행'
                          : ' ' +
                            dateDiffHour(
                              item.step_real_begin_date,
                              item.step_real_end_date,
                            )}
                      </>
                    ) : (
                      <>
                        {dateBasicHyphenFormet(item.step_begin_date)}
                        {dateHour(item.step_estimated_time)}
                      </>
                    )}
                  </p>
                </List>
              )
            })}
          </div>
        </ListArea>
      </Wrapper>
    </>
  )
}

export default ClassStepSelect

const ListArea = styled.div`
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  transition: all 0.3s;
  width: 100%;
  height: 0;
  overflow: hidden;
  background: #ffffff;
  z-index: 1;

  & > div {
    width: 100%;
    max-height: 300px;
    overflow: auto;
    border: 1px solid #0d0d0e;
    border-radius: 8px;
  }
`

const StepInfo = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  white-space: nowrap;

  & > b {
    font-weight: 700;
    font-size: 14px;
    line-height: 1.71;
    color: #0d0d0e;
    margin-right: 6px;
  }

  & > p {
    font-weight: 500;
    font-size: 14px;
    line-height: 1.71;
    color: #0d0d0e;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

const List = styled.div`
  &.top {
    width: calc(100% - 54px);
  }

  &.disable * {
    color: #b5b5b9;
  }

  &.select {
    background: #f9f9fb;
  }

  &.list {
    padding: 11px 14px;
  }

  & > p {
    font-weight: 500;
    font-size: 12px;
    line-height: 1.67;
    color: #7c7c80;
  }
`

const Wrapper = styled.div`
  margin-bottom: 20px;
  position: relative;
  width: 100%;
  padding: 11px 14px;
  border: 1px solid #0d0d0e;
  border-radius: 8px;
  cursor: pointer;

  & > svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 16px;
    height: 8px;
    width: 14px;
    transition: all 0.3s;
  }
`
