import media from 'lib/styles/media'
import React from 'react'
import styled from 'styled-components'
import { ReactComponent as BasicIcon } from 'assets/icons/basic_icon.svg'

interface RoomProfileImageProps {
  profileUrl: string | null
}

function RoomProfileImage({ profileUrl }: RoomProfileImageProps) {
  return (
    <Wrapper>
      {profileUrl ? (
        <img src={profileUrl} alt="profile" className="profile-image" />
      ) : (
        <BasicIcon className="basic-icon" />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  ${media.pc} {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ${media.medium} {
    width: 100px;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    background: #3b3b40;
  }

  & .profile-image {
    ${media.pc} {
      width: calc(100% / 3.02);
      aspect-ratio: 1 /1;
      border-radius: 50%;
    }

    ${media.medium} {
      width: 36px;
      height: 36px;
      border-radius: 50%;
    }
  }

  & .basic-icon {
    ${media.medium} {
      width: 36px;
      height: 36px;
    }
  }
`

export default RoomProfileImage
