import React, { useMemo, useState } from 'react'
import styled, { css } from 'styled-components'
import { ReactComponent as LeftArrow } from 'assets/icons/left_arrow.svg'
import { ReactComponent as LeftArrowMobile } from 'assets/icons/left_arrow_mobile.svg'
import { ReactComponent as OptionIcon } from 'assets/icons/option_icon.svg'
import { ReactComponent as GearIcon } from 'assets/icons/gear_icon.svg'
import { ReactComponent as ViewIcon } from 'assets/icons/view_icon.svg'

import { useSetRecoilState } from 'recoil'
import {
  audiencesModalState,
  roomSettingState,
  roomSettingViewModalState,
  timeSettingModalState,
} from 'atoms/modal'
import media from 'lib/styles/media'
import { useRoomInfoValue } from '../provider/RoomInfoProvider'
import { RoomHeaderWrapper } from './TeamRoomHeader'
import { type RoomRoleType } from 'lib/types/room'
import { addDate } from 'lib/utils'
import useRoom from 'lib/hooks/room/useRoom'
import { useParams } from 'react-router-dom'
import AudienceCount from './AudienceCount'

interface RoomHeaderProps {
  title: string
  maxCount: number
  changeMemberCount?: (maxCount: number) => void
  onClickArrow?: () => void
}

function RoomHeader({
  title,
  maxCount,
  changeMemberCount,
  onClickArrow,
}: RoomHeaderProps) {
  const setRoomSettingModal = useSetRecoilState(roomSettingState)
  const setRoomSettingViewModal = useSetRecoilState(roomSettingViewModalState)
  const setTimeSettingModal = useSetRecoilState(timeSettingModalState)
  const setAudiencesModal = useSetRecoilState(audiencesModalState)
  const { role } = useRoomInfoValue()

  const { roomId }: { roomId: string } = useParams()
  const { setVoteTimerTrigger, exitRoomTrigger, data } = useRoom(roomId)

  const isAttend = useMemo(() => {
    return data?.room.available_attend === 1
  }, [data?.room.available_attend])

  const exitDebate = () => {
    exitRoomTrigger()
  }

  const handleVote = (timer: number) => {
    setVoteTimerTrigger && setVoteTimerTrigger(addDate(timer))
  }

  const settingTimer = () => {
    role === 'tutor' &&
      setTimeSettingModal({
        title: '타이머 설정',
        text: '설정한 시간이 디베이팅룸에 카운트됩니다.',
        onSetting: handleVote,
      })
  }

  const handleViewAudienceList = () => {
    if (role !== 'tutor') return
    setRoomSettingViewModal(null)
    setAudiencesModal({
      onSubmit: settingTimer,
    })
  }

  const handleClickSetting = () => {
    setRoomSettingModal({
      onConfirm: changeMemberCount,
      onExit: exitDebate,
      maxCount,
    })
  }

  const handleClickOption = () => {
    setRoomSettingViewModal({
      onViewPaticipant: handleViewAudienceList,
      onViewSetting: handleClickSetting,
    })
  }

  return (
    <Wrapper role={role}>
      <div className="left">
        <div className="left-arrow" onClick={onClickArrow}>
          <LeftArrow className="mobile-hidden" />
          <LeftArrowMobile className="pc-hidden" />
        </div>

        <div className="lecture-title">{title}</div>
      </div>
      <div className="right">
        {isAttend && <AudienceCount roomId={roomId} role={role} />}
        {role === 'tutor' && (
          <div
            className="icon-wrapper mobile-hidden"
            onClick={handleClickSetting}
          >
            <GearIcon />
          </div>
        )}

        <div className="icon-wrapper pc-hidden" onClick={handleClickOption}>
          <OptionIcon />
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled(RoomHeaderWrapper)<{ role: RoomRoleType }>`
  & > .right {
    height: 100%;
    display: flex;
    gap: 10px;
  }

  & .icon-wrapper {
    cursor: pointer;
    &:hover {
      opacity: 0.9;
    }

    &:active {
      opacity: 0.8;
    }
    ${media.pc} {
      background: rgba(59, 59, 64, 0.8);
      border-radius: 8px;
      height: 44px;
      padding: 0 10px;

      display: flex;
      align-items: center;
      cursor: pointer;
    }

    ${media.medium} {
      display: flex;
      align-items: center;
    }
  }

  & .view-wrapper {
    display: flex;
    align-items: center;

    ${(props) =>
      props.role === 'tutor'
        ? css`
            background: rgba(59, 59, 64, 0.8);
            border-radius: 8px;
            height: 44px;
            padding: 0 10px;
            cursor: pointer;
          `
        : css`
            padding: 0 10px;
          `}
  }

  & .view-count {
    margin-left: 6px;
  }
`

export default RoomHeader
