import React from 'react'
import styled from 'styled-components'
import { ReactComponent as Icon } from '../../../assets/icons/fourth_content_icon1.svg'
import media from 'lib/styles/media'

function TutorFourthContent() {
  return (
    <Wrapper>
      <Content>
        <Icon className="fourth_icon" />
        <p className="fourth_title">
          디베이트온은 인증된 교육 기관과 함께합니다.
        </p>
        <div className="fourth_text_box">
          <span className="fourth_span">‘생각의 탄생’</span>
          <p className="fourth_content">
            과 함께 인증된 교육 커리큘럼을 제공하고 있어요.
          </p>
        </div>
      </Content>
      <MobileContent>
        <p className="fourth_title">
          디베이트온은
          <br />
          인증된 교육 기관과 함께합니다.
        </p>
        <Icon className="fourth_icon" />
        <div className="fourth_text_box">
          <span className="fourth_span">‘생각의 탄생’</span>
          <p className="fourth_content">
            과 함께 인증된 교육 커리큘럼을 제공하고 있어요.
          </p>
        </div>
      </MobileContent>
    </Wrapper>
  )
}

export default TutorFourthContent
const Wrapper = styled.div`
  width: 100%;
  height: 423px;
  background: #faf9fb;
  padding: 90px 0;
  ${media.medium} {
    padding: 52px 0;
    height: 397px;
  }
  & p.fourth_title {
    font-weight: 700;
    font-size: 30px;
    line-height: 50px;
    text-align: center;
    color: #0d0d0e;
    margin-top: 40px;
    ${media.medium} {
      font-size: 18px;
      line-height: 28px;
      margin-top: 0;
    }
  }
  & div.fourth_text_box {
    margin-top: 14px;
    display: flex;
    align-items: center;
    ${media.medium} {
      flex-direction: column;
    }
  }
  & span.fourth_span {
    padding: 2px 10px;
    background: #0f0963;
    border-radius: 18px;
    font-weight: 700;
    font-size: 16px;
    line-height: 26px;
    color: #ffffff;
    margin-right: 5px;
    ${media.medium} {
      margin-right: 0;
    }
  }
  & p.fourth_content {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #3b3b40;
    ${media.medium} {
      font-size: 13px;
      line-height: 22px;
      margin-top: 12px;
    }
  }
  & svg.fourth_icon {
    ${media.medium} {
      width: 100px;
      height: 100px;
      margin: 36px 0;
    }
  }
`
const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${media.medium} {
    display: none;
  }
`
const MobileContent = styled.div`
  display: none;
  ${media.medium} {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 261px;
    height: 293px;
    margin: 0 auto;
  }
`
