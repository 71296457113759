import media from 'lib/styles/media'
import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { ReactComponent as ArrowIcon } from 'assets/icons/left_arrow.svg'
import { DEBATE_STATUS_TEXT } from 'lib/utils/variables'
import { type DebateStatusType } from 'lib/types/room'
import useRoom from 'lib/hooks/room/useRoom'
import { useParams } from 'react-router-dom'

function SettingDebateStatus() {
  const [open, setOpen] = useState(false)
  const { roomId }: { roomId: string } = useParams()
  const { modifyDebateStatusTrigger } = useRoom(roomId)

  const handleMouseEnter = () => {
    if (open) return
    setOpen(true)
  }

  const handleMouseLeave = () => {
    if (!open) return
    setOpen(false)
  }

  const handleToggle = () => {
    setOpen(!open)
  }

  const handleClickStatus = (status: DebateStatusType) => () => {
    modifyDebateStatusTrigger(status)
    setOpen(false)
  }

  return (
    <Wrapper open={open} onMouseEnter={handleMouseEnter} onClick={handleToggle}>
      <ArrowIcon className="arrow-icon" />

      {open && (
        <div className="select-wrapper" onMouseLeave={handleMouseLeave}>
          {Object.entries(DEBATE_STATUS_TEXT)
            .filter(([status]) => {
              return status !== '6' && status !== '7' && status !== '8'
            })
            .map(([status, label]: any) => (
              <div
                className="select-box"
                key={status}
                onClick={handleClickStatus(status)}
              >
                {label}
              </div>
            ))}
        </div>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div<{ open: boolean }>`
  position: relative;

  & .arrow-icon {
    ${(props) =>
      props.open
        ? css`
            transform: rotate(90deg);
          `
        : css`
            transform: rotate(-90deg);
          `}
  }

  & .select-wrapper {
    position: absolute;
    left: 0px;
    top: 60px;
    background: #2b2b31;
    box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.4);
    border-radius: 4px;

    ${media.medium} {
      top: 24px;
    }
  }

  & .select-box {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #eeeff1;

    padding: 11px 14px;

    width: 244px;
    height: 46px;

    display: flex;
    align-items: center;

    z-index: 10;

    &:hover {
      background: rgba(13, 13, 14, 1);
    }
  }

  ${media.pc} {
    width: 44px;
    height: 44px;

    background: rgba(59, 59, 64, 0.8);

    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;

    cursor: pointer;
  }

  ${media.medium} {
    width: 18px;
    height: 18px;

    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export default SettingDebateStatus
