import media from 'lib/styles/media'
import React from 'react'
import styled from 'styled-components'

interface Props {
  title: string
  onClick?: () => void
  type?: string
  disabled?: boolean
}

function LineButton({ title, onClick, type, disabled }: Props) {
  return (
    <Wrapper
      onClick={onClick}
      className={!type ? '' : type}
      disabled={disabled}
    >
      <p>{title}</p>
    </Wrapper>
  )
}

export default LineButton
const Wrapper = styled.button`
  width: 100%;
  height: 62px;
  background: #ffffff;
  border: 1px solid #e1e1e2;
  border-radius: 8px;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
  ${media.medium} {
    height: 42px;
  }
  & > p {
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    color: #0d0d0e;
    margin-left: 8px;
    ${media.medium} {
      font-size: 14px;
      line-height: 24px;
    }
  }
  &.small {
    width: 320px;
    height: 50px;
    border-radius: 4px;
    & > p {
      font-size: 14px;
      line-height: 24px;
    }
  }
  &.gray {
    border: 1px solid #7c7c80;
    height: 50px;
    border-radius: 4px;
    & > p {
      font-size: 14px;
      line-height: 24px;
    }
    &:hover {
      background: rgba(0, 0, 0, 0.04);
      border: 1px solid #7c7c80;
    }
  }

  &:hover {
    background: rgba(0, 0, 0, 0.04);
    border: 1px solid #e1e1e2;
  }
  &:disabled {
    border: 1px solid #e1e1e2;
    background: #fff;
    & > p {
      color: #b5b5b9;
    }
  }
  &:disabled:hover {
    border: 1px solid #e1e1e2;
    background: #fff;
  }
`
