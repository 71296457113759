import { phoneFormet } from 'lib/utils/CommonFuncttion'
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import media from 'lib/styles/media'
import Button from '../Button'
import { cancleApplyModalState } from 'atoms/modal'
import { useSetRecoilState } from 'recoil'
import { useHistory } from 'react-router-dom'
import CheckPopup from '../CheckPopup'

import { ReactComponent as Menu } from 'assets/icons/class_notice_button_icon.svg'
import { ReactComponent as Staroff } from 'assets/icons/class_info_scope_icon.svg'
import { ReactComponent as StarOn } from 'assets/icons/class_review_icon_on.svg'
import { ReactComponent as BasicIcon } from 'assets/icons/basic_icon.svg'

interface Props {
  cancel_reason?: string
  class_user_cancel_request_id?: number
  grade: string
  id: number
  name: string
  phone: string
  profile_url: string
  feedback_score?: any[]
  review_grade?: number
  review_contents?: string
  school_type: string
  step: number
  user_id: number
}

const StudentList = ({
  data,
  max,
  type,
  mutate,
  isSetting,
  classId,
  handleCancle,
}: {
  data: Props
  max: number
  type: string
  mutate: any
  isSetting: boolean
  classId: number
  handleCancle: (id: string) => void
}) => {
  const history = useHistory()
  const menuRef = useRef(null)

  const setCancleApplyModal = useSetRecoilState(cancleApplyModalState)

  const [review, setReview] = useState(false)
  const [popup, setPopup] = useState(false)

  useEffect(() => {
    document.body.addEventListener('click', (e: any) => {
      const parentId = e.target.parentNode.id
      const thisId = e.target.id
      if (
        (parentId === undefined && thisId === undefined) ||
        (parentId === '' && thisId === '') ||
        (parentId !== undefined &&
          parentId !== '' &&
          parentId !== 'manage_menu_btn_' + data.id &&
          parentId !== 'manage_menu_' + data.id &&
          parentId !== 'manage_menu_btn_svg_' + data.id) ||
        (thisId !== undefined &&
          thisId !== '' &&
          thisId !== 'manage_menu_btn_' + data.id &&
          thisId !== 'manage_menu_' + data.id &&
          thisId !== 'manage_menu_btn_svg_' + data.id)
      ) {
        try {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          menuRef.current.style.opacity = '0'
          setTimeout(() => {
            try {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-expect-error{
              menuRef.current.style.display = 'none'
            } catch (error) {}
          }, 300)
        } catch (error) {}
      }
    })
  }, [])

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    if (menuRef.current !== null && !menuRef.current.style.display) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      menuRef.current.style.display = 'none'
    }
  }, [menuRef])

  const handleMenu = () => {
    if (menuRef.current !== null) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      if (menuRef.current.style.display === 'none') {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        menuRef.current.style.display = 'block'
        setTimeout(() => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          menuRef.current.style.opacity = '1'
        }, 100)
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        menuRef.current.style.opacity = '0'
        setTimeout(() => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error{
          menuRef.current.style.display = 'none'
        }, 300)
      }
    }
  }

  return (
    <>
      <Wrapper className={type === '2' ? 'end' : ''}>
        <div>
          <div>
            <ProfileBox>
              {data.profile_url ? (
                <img src={data.profile_url} alt="" />
              ) : (
                <BasicIcon />
              )}
            </ProfileBox>
            <Text>
              <h1>{data.name}</h1>
              <p>
                초등학교 {data.grade}학년
                <span />
                {phoneFormet(data.phone)}
              </p>
              {type === '2' && (
                <ReviewArea>
                  {data.review_grade ? (
                    <>
                      {Array(Number(data.review_grade))
                        .fill(0)
                        .map((data, i) => (
                          <StarOn key={'star_on_' + data.id + i} />
                        ))}
                      {Array(5 - Number(data.review_grade))
                        .fill(0)
                        .map((data, i) => (
                          <Staroff key={'star_off_' + data.id + i} />
                        ))}
                      <div onClick={() => setReview(!review)}>
                        {review ? '닫기' : '강의 후기'}
                      </div>
                    </>
                  ) : (
                    '등록된 리뷰가 없습니다'
                  )}
                </ReviewArea>
              )}
            </Text>
          </div>
          {type === '2' ? (
            <Total
              className={data.feedback_score ? '' : 'submit'}
              onClick={() => {
                if (isSetting) history.push(`/feedback/${classId}/${data.id}`)
                else setPopup(true)
              }}
            >
              {data.feedback_score ? (
                <>
                  총점{' '}
                  <span>
                    {data.feedback_score}/{max}
                  </span>
                </>
              ) : (
                '피드백 등록'
              )}
            </Total>
          ) : (
            data.class_user_cancel_request_id && (
              <Button
                title="수강 취소 신청"
                onClick={() =>
                  setCancleApplyModal({
                    id: data.class_user_cancel_request_id,
                    name: data.name,
                    profile: data.profile_url,
                    reason: data.cancel_reason,
                    mutate: mutate,
                  })
                }
              />
            )
          )}
          {type !== '2' && (
            <MenuButton onClick={handleMenu} id={'manage_menu_btn_' + data.id}>
              <Menu id={'manage_menu_btn_svg_' + data.id} />
            </MenuButton>
          )}
          <MenuBox ref={menuRef} id={'manage_menu_' + data.id}>
            <button
              onClick={() => {
                handleMenu()
                handleCancle(data.id.toString())
              }}
            >
              수강 취소
            </button>
          </MenuBox>
        </div>
        {review && <ReviewContent>{data?.review_contents}</ReviewContent>}
        {type === '2' ? (
          <Total
            className={'mobile' + (data.feedback_score ? '' : ' submit')}
            onClick={() => {
              if (isSetting) history.push(`/feedback/${classId}/${data.id}`)
              else setPopup(true)
            }}
          >
            {data.feedback_score ? (
              <>
                총점{' '}
                <span>
                  {data.feedback_score}/{max}
                </span>
              </>
            ) : (
              '피드백 등록'
            )}
          </Total>
        ) : (
          data.class_user_cancel_request_id && (
            <MobileButton
              title="수강 취소 신청"
              onClick={() =>
                setCancleApplyModal({
                  id: data.class_user_cancel_request_id,
                  name: data.name,
                  profile: data.profile_url,
                  reason: data.cancel_reason,
                  mutate: mutate,
                })
              }
            />
          )
        )}
      </Wrapper>
      {popup && (
        <CheckPopup
          onClick={() => setPopup(false)}
          title="평가 항목 미설정"
          content="수강생 피드백 평가항목 설정 후 피드백을 등록하실 수 있습니다."
        />
      )}
    </>
  )
}

export default StudentList

const ReviewContent = styled.div`
  margin-top: 19px;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.71;
  color: #3b3b40;
  padding-left: 80px;

  ${media.medium} {
    margin-top: 8px;
    padding-left: 0;
  }
`

const ReviewArea = styled.div`
  margin-top: 12px;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.71;
  color: #7c7c80;

  & > svg {
    width: 19px;
    height: 18px;

    &:nth-of-type(n + 2) {
      margin-left: 5px;
    }
  }

  & > div {
    margin-left: 13px;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.71;
    text-decoration-line: underline;
    color: #7c7c80;
    cursor: pointer;
  }

  ${media.medium} {
    margin-top: 8px;
    font-size: 13px;
    line-height: 1.69;

    & > svg {
      width: 14px;
      height: 13px;

      &:nth-of-type(n + 2) {
        margin-left: 4px;
      }
    }

    & > div {
      margin-left: 8px;
      font-size: 13px;
      line-height: 1.69;
    }
  }
`

const Total = styled.button`
  width: 200px;
  min-width: 200px;
  height: 42px;
  background: #ffffff;
  border: 1px solid #e1e1e2;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.71;
  color: #0d0d0e;

  & > span {
    font-weight: 700;
    color: #7574d8;
  }

  &.submit {
    background: #57569e;
    font-weight: 700;
    color: #ffffff;
    cursor: pointer;
  }

  &.mobile {
    display: none;
  }

  ${media.medium} {
    width: 100%;
    margin-top: 20px;
    display: none;

    &.mobile {
      display: block;
    }
  }
`

const MobileButton = styled(Button)`
  margin-top: 20px;
  display: none;

  ${media.medium} {
    display: block;
  }
`

const MenuBox = styled.span`
  position: absolute;
  top: 72px;
  right: 20px;
  padding: 11px 14px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  width: 200px;
  transition: all 0.3s;
  z-index: 1;
  opacity: 0;

  & > button {
    width: 172px;
    padding: 0;
    margin: 0;
    border: 0;
    outline: none;
    background: none;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.71;
    color: #3b3b40;
    text-align: left;
  }
`

const MenuButton = styled.button`
  width: 26px;
  min-width: 26px;
  height: 26px;
  margin: 0;
  padding: 0;
  outline: none;
  background: #ffffff;
  border: 1px solid #eeeff1;
  border-radius: 100px;
  cursor: pointer;

  & > svg {
    width: 2px;
    height: 12px;
  }
`

const Text = styled.div`
  width: 100%;

  & > h1 {
    font-weight: 700;
    font-size: 20px;
    line-height: 1.5;
    color: #3b3b40;
    margin-bottom: 2px;
  }

  & > p {
    font-weight: 500;
    font-size: 14px;
    line-height: 1.71;
    color: #7c7c80;

    & > span {
      display: inline-block;
      width: 2px;
      min-width: 2px;
      height: 2px;
      margin: 6px;
      background: #7c7c80;
    }
  }

  ${media.medium} {
    & > h1 {
      font-size: 16px;
      margin-bottom: 0;
    }

    & > p {
      font-size: 13px;
      line-height: 1.69;

      & > span {
        margin: 3px 6px;
      }
    }
  }
`

const ProfileBox = styled.div`
  & > img,
  & > svg {
    width: 64px;
    min-width: 64px;
    height: 64px;
    margin-right: 16px;
    border-radius: 100px;
    object-fit: cover;

    ${media.medium} {
      width: 40px;
      min-width: 40px;
      height: 40px;
      margin-right: 10px;
    }
  }
`

const Wrapper = styled.div`
  border: 1px solid #e1e1e2;
  border-radius: 14px;
  background: #ffffff;
  padding: 20px;
  position: relative;

  & > div {
    display: flex;
    align-items: center;

    & > div {
      display: flex;
      align-items: center;
      width: 100%;
    }

    & > button {
      max-width: 140px;
      margin-right: 34px;
    }
  }

  &:nth-of-type(n + 2) {
    margin-top: 20px;
  }

  &.end {
    & > div {
      & > div {
        align-items: flex-start;
      }

      & > button {
        margin-right: 0;
      }
    }
  }

  ${media.medium} {
    padding: 16px 20px;

    & > div {
      & > button {
        display: none;
      }
    }

    &:nth-of-type(n + 2) {
      margin-top: 10px;
    }
  }
`
