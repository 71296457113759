import Footer from 'components/common/Footer'
import Header from 'components/common/header/Header'
import PageLoadingComponent from 'components/common/PageLoadingComponent'
import { CommonContentWrapper } from 'components/layout/CommonStyle'
import useTutorDetail from 'containers/tutor/hooks/useTutorDetail'
import TutorClassListContainer from 'containers/tutor/TutorClassListContainer'
import TutorDetailContainer from 'containers/tutor/TutorDetailContainer'
import TutorReviewListContainer from 'containers/tutor/TutorReviewListContainer'
import React from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import { ReactComponent as Top } from 'assets/icons/top_icon.svg'
import media from 'lib/styles/media'
import MobileTitleHeader from 'components/common/header/MobileTitleHeader'

interface TutorDetailPageProps {}

function TutorDetailPage({}: TutorDetailPageProps) {
  const { tutorId }: { tutorId: string } = useParams()
  const { data, isLoading } = useTutorDetail(tutorId)
  if (isLoading) return <PageLoadingComponent />
  if (!data) return <div></div>

  return (
    <Wrapper>
      <Header />
      <MobileTitleHeader type="arrow" title="강의 상세" />
      <Content>
        <CommonContentWrapper>
          <TutorDetailContainer data={data} />
          <TutorClassListContainer info={data} tutorId={tutorId} />
          <TutorReviewListContainer name={data.nickname} tutorId={tutorId} />
        </CommonContentWrapper>
        <Footer />
        <TopIcon
          onClick={() => {
            window.scrollTo({ top: 0, behavior: 'smooth' })
          }}
        />
      </Content>
    </Wrapper>
  )
}

const Wrapper = styled.div``

const TopIcon = styled(Top)`
  position: fixed;
  bottom: 70px;
  right: 276px;
  width: 64px;
  height: 64px;
  cursor: pointer;

  ${media.medium} {
    bottom: 20px;
    right: 20px;
  }
`

const Content = styled.div`
  ${media.medium} {
    padding-top: 58px;
  }
`

export default TutorDetailPage
