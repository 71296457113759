import { type TutorClassType } from 'lib/api/tutor'
import { getDday, getThousandCommaPrice } from 'lib/utils'
import React, { useState } from 'react'
import styled from 'styled-components'
import media from 'lib/styles/media'
import useUser from 'lib/hooks/account/useUser'
import { useHistory } from 'react-router-dom'
import api from 'lib/api'
import ToastPopup from 'components/common/ToastPopup'

import { ReactComponent as NewClassIcon } from 'assets/icons/new_class_icon.svg'
import { ReactComponent as LikeIcon } from 'assets/icons/like_icon.svg'
import { ReactComponent as LikePullIcon } from 'assets/icons/like_pull_icon.svg'
import { ReactComponent as ScopeIcon } from 'assets/icons/class_info_scope_icon.svg'
import { ReactComponent as BasicIcon } from 'assets/icons/basic_icon.svg'

interface TutorClassComponentProps {
  info: TutorClassType
  profile: string
  nickname: string
}

function TutorClassComponent({
  info,
  profile,
  nickname,
}: TutorClassComponentProps) {
  const history = useHistory()
  const { user } = useUser()

  const dday = getDday(info.begin_date)

  const [like, setLike] = useState(info.is_my_like)
  const [massage, setMassage] = useState(false)

  const handleLikeClick = async (like: boolean) => {
    if (!user) return history.push('/login')
    if (user.type === 'tutor') return
    try {
      const formData = new FormData()
      formData.append('class_id', String(info.id))

      const {
        data: { success, alert },
      } = await api.post('/class/like', formData)
      if (success) {
        setLike(like ? 'N' : 'Y')
        if (!like) {
          setMassage(true)
          setTimeout(() => {
            setMassage(false)
          }, 1500)
        }
      } else {
        window.alert(alert)
      }
    } catch (err) {}
  }

  return (
    <Wrapper>
      <div className="class-image">
        <img
          src={info.image_url}
          alt="class-image"
          onClick={() => history.push(`/class/${info.id}`)}
        />
        {info.status === 2 ? (
          <EndClass>종료된 강의입니다</EndClass>
        ) : (
          dday >= 0 && (
            <div className="d-day">
              <NewClassIcon />
              <span className="d-day-text">D-{dday}</span>
            </div>
          )
        )}
        {like === 'N' ? (
          <LikeIcon
            className="like_icon"
            onClick={(e) => {
              handleLikeClick(false)
            }}
          />
        ) : (
          <LikePullIcon
            className="like_icon"
            onClick={(e) => {
              handleLikeClick(true)
            }}
          />
        )}
      </div>
      <div
        className="class-title"
        onClick={() => history.push(`/class/${info.id}`)}
      >
        {info.title}
      </div>
      <div
        className="class-price"
        onClick={() => history.push(`/class/${info.id}`)}
      >
        {info.price > 0 ? getThousandCommaPrice(info.price) + '원' : '무료강의'}
      </div>
      <div className="mobile" onClick={() => history.push(`/class/${info.id}`)}>
        {profile ? <img src={profile} /> : <BasicIcon />}
        <span>{nickname}</span>
        <p>
          <ScopeIcon />
          {info.tutor_score}
        </p>
      </div>
      <div
        className="class-categories"
        onClick={() => history.push(`/class/${info.id}`)}
      >
        {info.categories.map((cate, i) => {
          if (i === 0) return cate?.category
          else
            return (
              <>
                <span />
                {cate?.category}
              </>
            )
        })}
      </div>
      {massage && <ToastPopup title="찜한 강의에 추가되었어요!" />}
    </Wrapper>
  )
}

const EndClass = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(5px);
  font-weight: 500;
  font-size: 16px;
  line-height: 1.62;
  color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;

  ${media.medium} {
    font-size: 13px;
    line-height: 1.85;
  }
`

const Wrapper = styled.div`
  width: 100%;
  max-width: calc(25% - 15px);
  margin-right: 20px;

  &:nth-of-type(4n) {
    margin-right: 0;
  }

  &:nth-of-type(n + 5) {
    margin-top: 38px;
  }

  & > .class-image {
    position: relative;
    width: 100%;
    padding-bottom: 56.14%;
    border-radius: 8px;

    & img {
      position: absolute;
      border-radius: inherit;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      cursor: pointer;
    }
  }

  & .like_icon {
    position: absolute;
    right: 15px;
    bottom: 15px;
    cursor: pointer;
    z-index: 1;
  }

  & .d-day {
    position: absolute;
    left: 16px;
  }

  & .d-day-text {
    position: absolute;
    left: 10px;
    top: 13px;

    font-weight: 700;
    font-size: 13px;
    color: #ffffff;
  }

  & > .class-title {
    margin-top: 12px;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: #3b3b40;
    cursor: pointer;
  }
  & > .class-price {
    margin-top: 3px;
    font-weight: 900;
    font-size: 16px;
    line-height: 26px;
    color: #0d0d0e;
    cursor: pointer;
  }
  & > .mobile {
    display: none;
    align-items: center;
    margin-top: 8px;
    font-weight: 500;
    font-size: 12px;
    line-height: 1.83;
    color: #3b3b40;
    cursor: pointer;

    & > img,
    & > svg {
      width: 18px;
      height: 18px;
      border-radius: 20px;
      margin-right: 6px;
    }

    & > span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    & > p {
      margin-left: 6px;
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 12px;
      line-height: 1.83;
      color: #7c7c80;

      & > svg {
        margin-right: 4px;
      }
    }
  }
  & > .class-categories {
    margin-top: 3px;
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    color: #b5b5b9;
    height: 22px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;

    & > span {
      display: inline-block;
      width: 2px;
      min-width: 2px;
      height: 2px;
      margin: 5px;
      background: #b5b5b9;
    }
  }

  ${media.medium} {
    max-width: calc(50% - 6px);
    margin-right: 12px;

    &:nth-of-type(2n) {
      margin-right: 0;
    }

    &:nth-of-type(n + 3) {
      margin-top: 32px;
    }

    & > .class-image {
      padding-bottom: 54.54%;
      border-radius: 6px;
    }

    & .like_icon {
      right: 11px;
      bottom: 11px;
      width: 18px;
      height: 16px;
    }

    & .d-day {
      position: absolute;
      left: 8px;
      width: 32px;
      height: 35px;

      & > svg {
        width: 32px;
        height: 35px;
      }
    }

    & .d-day-text {
      left: 7px;
      top: 5px;
      font-size: 11px;
      line-height: 18px;
    }

    & > .class-title {
      margin-top: 6px;
      font-size: 13px;
      line-height: 1.69;
    }
    & > .class-price {
      margin-top: 2px;
      font-size: 14px;
      line-height: 24px;
    }
    & > .mobile {
      display: flex;
    }
    & > .class-categories {
      margin-top: 2px;
    }
  }
`

export default TutorClassComponent
