import ChatInput from 'components/room/chat/ChatInput'
import ChatList from 'components/room/chat/ChatList'
import RoomChatProvider from 'components/room/provider/RoomChatProvider'
import React from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

function ChatContainer() {
  const { roomId }: { roomId: string } = useParams()
  return (
    <RoomChatProvider roomId={roomId}>
      <ChatContainerWrapper>
        <ChatList />
        <ChatInput />
      </ChatContainerWrapper>
    </RoomChatProvider>
  )
}

export const ChatContainerWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
`

export default ChatContainer
