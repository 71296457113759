import React, { useState } from 'react'
import styled from 'styled-components'
import { ReactComponent as CloseIcon } from '../../assets/icons/popup_cancel_icon.svg'
import Button from './Button'
import Textarea from './Textarea'
import media from 'lib/styles/media'

interface Props {
  handleClose: () => void
  onCancelClick: () => void
  value: string
  onChange: (e: any) => void
  handleBackClick?: (e: any) => void
}
function ClassCancelPopup({
  handleClose,
  onCancelClick,
  value,
  onChange,
  handleBackClick,
}: Props) {
  return (
    <Wrapper id="popup" onClick={handleBackClick}>
      <Content>
        <Title>
          <p className="title">수강 취소 신청</p>
          <CloseIcon onClick={handleClose} />
        </Title>
        <TextBox>
          <p className="content_title">수강 취소 사유</p>
          <Textarea
            placeholder="수강 취소 사유를 상세히 입력해 주세요"
            value={value}
            onChange={onChange}
          />
        </TextBox>
        <ButtonBox>
          <Button
            title="수강 취소 신청"
            onClick={onCancelClick}
            disabled={!value}
          />
        </ButtonBox>
      </Content>
    </Wrapper>
  )
}

export default ClassCancelPopup

const Wrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  z-index: 110;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
`
const Content = styled.div`
  width: 380px;
  height: 428px;
  background: #fff;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 14px;
  border: 1px solid #e1e1e2;
  ${media.medium} {
    width: 100%;
    height: 100%;
    border-radius: 0;
    position: relative;
  }
`
const Title = styled.div`
  padding: 26px 26px 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${media.medium} {
    padding: 17px 20px;
  }
  & p.title {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: #0d0d0e;
    ${media.medium} {
      font-size: 14px;
      line-height: 24px;
    }
  }
`
const TextBox = styled.div`
  padding: 16px 26px 26px;
  ${media.medium} {
    padding: 16px 20px;
  }
  & p.content_title {
    font-weight: 700;
    font-size: 13px;
    line-height: 22px;
    color: #0d0d0e;
    margin-bottom: 10px;
    ${media.medium} {
      font-size: 13px;
      line-height: 22px;
    }
  }
`
const ButtonBox = styled.div`
  padding: 10px 26px 26px;
  ${media.medium} {
    margin-top: 200px;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
  }
`
