import { PageTypeState } from 'atoms/common'
import Footer from 'components/common/Footer'
import PageLoadingComponent from 'components/common/PageLoadingComponent'
import Header from 'components/common/header/Header'
import MobileTitleHeader from 'components/common/header/MobileTitleHeader'
import TutorHeader from 'components/common/header/TutorHeader'
import MypaymentComponent from 'components/common/payment/MypaymentComponent'
import useUser from 'lib/hooks/account/useUser'
import usePayment from 'lib/hooks/usePayment'
import media from 'lib/styles/media'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import styled from 'styled-components'

function MyPayMentPage() {
  const [pageType, setPageType] = useRecoilState(PageTypeState)
  const history = useHistory()
  const search = window.location.search
  const [location, setLocation] = useState('')
  const [type, setType] = useState('')
  const [status, setStatus] = useState('')
  const { data: paymentData } = usePayment({ status })
  const { user } = useUser()
  const typeUser = user && user.type

  useEffect(() => {
    setType(search.slice(6))
    setLocation(search)
  }, [])

  useEffect(() => {
    if (type) {
      // 0-결제 완료, 1-수강 완료, 2-정산 완료, 9-취소 신청, 10-결제 취소
      if (pageType === 'tutor') {
        if (type.includes('tutor_payment')) {
          setStatus('0')
        } else if (type.includes('tutor_success')) {
          setStatus('1')
        } else if (type.includes('tutor_price_success')) {
          setStatus('2')
        } else if (type.includes('tutor_cancel')) {
          setStatus('9')
        } else {
          setStatus('10')
        }
      } else {
        if (type === 'payment') {
          setStatus('0')
        } else if (type === 'success') {
          setStatus('1')
        } else {
          setStatus('10')
        }
      }
    }
  }, [type, pageType])

  const handleClick = (type: string) => {
    setType(type.slice(6))
    setLocation(type)
    history.replace(type)
  }

  if (!paymentData) return null
  if (!paymentData.data) return null

  return (
    <Wrapper>
      {typeUser === 'tutor' ? <TutorHeader /> : <Header />}
      <MobileTitleHeader
        type="arrow"
        title={typeUser === 'tutor' ? '결제 관리' : '결제 내역'}
      />
      <Content>
        <MypaymentComponent
          pageType={typeUser}
          location={location}
          data={paymentData.data}
          type={type}
          handleClick={handleClick}
        />
      </Content>
      <Footer />
    </Wrapper>
  )
}

export default MyPayMentPage
const Wrapper = styled.div``
const Content = styled.div`
  margin-top: 100px;
  ${media.medium} {
    margin-top: 0;
  }
`
