import type { UserInfo } from 'lib/types/account'
import React, { useEffect } from 'react'
import StudentJoininfoBasic from './StudentJoininfoBasic'
import TutorJoininfoBasic from './TutorJoininfoBasic'
import { useRecoilState } from 'recoil'
import { PageTypeState } from 'atoms/common'

interface JoinPageProps {
  user?: UserInfo
}

function JoininfoBasicPage({ user }: JoinPageProps) {
  const [pageType, setPageType] = useRecoilState(PageTypeState)

  if (pageType === 'tutor') return <TutorJoininfoBasic />
  return <StudentJoininfoBasic />
}

export default JoininfoBasicPage
