import { cancleApplyModalState } from 'atoms/modal'
import React, { useState } from 'react'
import { useRecoilState } from 'recoil'
import styled from 'styled-components'
import ModalWrapper from './ModalWrapper'
import { ReactComponent as CloseIcon } from 'assets/icons/close_icon.svg'
import Button from 'components/common/Button'
import api from 'lib/api'
import media from 'lib/styles/media'

import { ReactComponent as BasicIcon } from 'assets/icons/basic_icon.svg'

function CancleApplyModal() {
  const [modal, setModal] = useRecoilState(cancleApplyModalState)

  const handleClose = () => {
    setModal(null)
  }

  const handleAccept = async () => {
    try {
      const formData = new FormData()
      formData.append('class_user_cancel_request_id', String(modal?.id))

      const {
        data: { success, alert },
      } = await api.post('/class/cancel/request/accept', formData)
      if (success) {
        modal?.mutate()
        handleClose()
      } else {
        window.alert(alert)
      }
    } catch (err) {}
  }

  const handleReject = async () => {
    try {
      const formData = new FormData()
      formData.append('class_user_cancel_request_id', String(modal?.id))

      const {
        data: { success, alert },
      } = await api.post('/class/cancel/request/reject', formData)
      if (success) {
        modal?.mutate()
        handleClose()
      } else {
        window.alert(alert)
      }
    } catch (err) {}
  }

  if (!modal) return null

  return (
    <ModalWrapper onClose={handleClose}>
      <Wrapper>
        <Header>
          <h4 className="title">수강 취소 신청</h4>
          <CloseIcon className="close-icon" onClick={handleClose} />
        </Header>
        <Content>
          <Profile>
            {modal?.profile ? <img src={modal?.profile} /> : <BasicIcon />}
            {modal?.name}
          </Profile>
          {modal?.reason}
        </Content>
        <ButtonArea>
          <Button
            title="수강 취소 반려"
            onClick={handleReject}
            variant="outlined"
          />
          <Button title="수강 취소 승인" onClick={handleAccept} />
        </ButtonArea>
      </Wrapper>
    </ModalWrapper>
  )
}

export default CancleApplyModal

const Profile = styled.div`
  background: #f9f9fb;
  border-radius: 8px;
  padding: 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.71;
  color: #3b3b40;
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  & > img,
  & > svg {
    width: 34px;
    min-width: 34px;
    height: 34px;
    border-radius: 100px;
    margin-right: 8px;
  }
`

const ButtonArea = styled.div`
  padding: 10px 26px 26px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > button {
    width: calc(50% - 5px);
  }

  ${media.medium} {
    padding: 20px;
    display: block;

    & > button {
      width: 100%;
      height: 50px;

      &:nth-of-type(n + 2) {
        margin-top: 10px;
      }
    }
  }
`

const Content = styled.div`
  height: 290px;
  overflow: auto;
  padding: 20px 26px;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.71;
  color: #0d0d0e;

  ${media.medium} {
    height: calc(100% - 208px);
    padding: 20px;
  }
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 26px 26px 10px;

  & > h4 {
    font-weight: 700;
    font-size: 18px;
    line-height: 1.56;
    color: #0d0d0e;
  }

  & > svg {
    width: 17px;
    height: 17px;
  }

  ${media.medium} {
    padding: 17px 20px;

    & > h4 {
      font-size: 14px;
      line-height: 1.71;
      color: #3b3b40;
    }

    & > svg {
      width: 20px;
      height: 20px;
    }
  }
`

const Wrapper = styled.div`
  background: #fff;
  border-radius: 14px;
  width: 100%;
  max-width: 380px;
  margin: 0 30px;

  ${media.medium} {
    max-width: 100%;
    height: 100%;
    margin: 0;
    border-radius: 0;
  }
`
