import { type PaymentMethodType } from 'lib/types/common'
import { type DebateStatusType } from 'lib/types/room'

export const MEMBER_COUNT = [
  {
    value: 2,
    label: '1 : 1',
  },
  {
    value: 4,
    label: '2 : 2',
  },
  {
    value: 6,
    label: '3 : 3',
  },
  {
    value: 8,
    label: '4 : 4',
  },
  {
    value: 10,
    label: '5 : 5',
  },
]

export const TUTOR_ROOM_TAB_VALIABLES = [
  {
    value: 'participant',
    label: '참가자',
  },
  {
    value: 'chat',
    label: '채팅',
  },
  {
    value: 'reference',
    label: '자료',
  },
]

export const STUDENT_ROOM_TAB_VALIABLES = [
  {
    value: 'chat',
    label: '채팅',
  },
  {
    value: 'reference',
    label: '자료',
  },
  {
    value: 'teamChat',
    label: '팀채팅',
  },
]

export const TEAM_ROOM_TAB_VARIABLES = [
  {
    value: 'reference',
    label: '자료',
  },
]

export const ROOM_STATUS_TEXT = {
  TUTOR_ENABLED_OPEN: 'TUTOR_ENABLED_OPEN',
  TUTOR_ENABLED_ENTER: 'TUTOR_ENABLED_ENTER',
  STUDENT_DISABLED_ENTER: 'STUDENT_DISABLED_ENTER',
  STUDENT_ENABLED_ENTER: 'STUDENT_ENABLED_ENTER',
}

export const DEBATE_STATUS_TEXT: { [key in DebateStatusType]: string } = {
  0: '입안',
  1: '교차질의',
  2: '반박',
  3: '요약',
  4: '전체 교차질의',
  5: '마지막 발언',
  6: '온라인교육',
  7: '투표진행중',
  8: '추가강의',
}

export const SELECT_TIME_VARIABLES = {
  30: '30초',
  60: '1분',
  90: '1분30초',
  120: '2분',
  150: '2분30초',
  180: '3분',
  210: '3분30초',
  240: '4분',
  270: '4분30초',
  300: '5분',
}

export const PAYMENT_METHOD_VARIABLES: { [key in PaymentMethodType]: string } =
  {
    card: '카드결제',
    bank: '실시간 계좌이체',
    kakao: '카카오페이',
    naver: '네이버페이',
    payco: '페이코',
  }

export type TutorPageTabType = 'intro' | 'classList' | 'reviewList'

export const TUTOR_PAGE_TAB_VARIABLES: { [key in TutorPageTabType]: string } = {
  intro: '튜터 소개',
  classList: '강의 목록',
  reviewList: '리뷰 목록',
}
