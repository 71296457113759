import { Select } from 'antd'
import media from 'lib/styles/media'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ReactComponent as Icon } from '../../../assets/icons/class_category_close_icon.svg'
import { refactoringSelectData } from 'lib/utils'
import useSWR from 'swr'

interface Props {
  title: string
  handleChange: (value: string) => void
  defaultValue: any
  handleClose: (value: string) => void
}
function ClassSearchComponent({
  title,
  handleChange,
  defaultValue,
  handleClose,
}: Props) {
  const { data } = useSWR('/class/category')
  const [disabled, setDisabled] = useState(false)
  const [open, setOpen] = useState(false)
  const [value, setValue] = useState('')

  useEffect(() => {
    if (defaultValue.length > 3) {
      setDisabled(true)
    } else {
      setDisabled(false)
    }
  }, [defaultValue])

  const onChange = (value: string) => {
    setValue('')
    setOpen(false)
    handleChange(value)
  }

  const handleClick = () => {
    setOpen(true)
  }
  const newOptions = refactoringSelectData(data?.data?.categories)

  if (!data) return null
  if (!data.data) return null

  return (
    <Wrapper className={defaultValue.length > 0 ? 'caterory' : ''}>
      <Title>
        <p>{title}</p>
      </Title>
      <Content>
        <Select
          showSearch
          disabled={disabled}
          mode="multiple"
          placeholder={
            value !== '' ? '' : '강의의 주제를 설정해주세요 (최대 4건)'
          }
          onClick={() => {
            if (open) {
              setOpen(false)
            } else {
              setOpen(true)
            }
          }}
          open={open}
          onChange={onChange}
          searchValue={value}
          options={data.data.categories.map((item: any) => ({
            label: item.category,
            value: item.id,
          }))}
          defaultValue={defaultValue}
          filterOption={(input, option) =>
            String(option?.label)?.includes(value)
          }
          notFoundContent={
            <None>
              <p>일치하는 카테고리가 없습니다</p>
            </None>
          }
        />
        <input
          type="text"
          className="search_input"
          onChange={(e) => setValue(e.target.value)}
          value={value}
          onClick={handleClick}
          onBlur={() => {
            setTimeout(() => {
              setOpen(false)
            }, 130)
          }}
        />
        <ValueContent>
          {defaultValue.map((item: any, i: number) => (
            <div key={`value_list_${i}`}>
              <p>{newOptions[item]}</p>
              <Icon
                onClick={() => {
                  handleClose(item)
                }}
              />
            </div>
          ))}
        </ValueContent>
      </Content>
    </Wrapper>
  )
}

export default ClassSearchComponent
const Wrapper = styled.div`
  margin-top: 40px;
  &.caterory {
    padding-bottom: 40px;
  }
`
const Title = styled.div`
  & > p {
    font-weight: 500;
    font-size: 17px;
    line-height: 26px;
    color: #0d0d0e;
    ${media.medium} {
      font-size: 16px;
    }
  }
`
const Content = styled.div`
  margin-top: 10px;
  position: relative;
  & div.ant-select-multiple {
    width: 100%;
    & div.ant-select-selector {
      height: 44px;
      padding: 11px 14px;
      position: relative;
      & .ant-select-selection-overflow {
        display: flex;
        opacity: 0;
      }
      & .ant-select-selection-item {
        line-height: normal;
      }
      & .ant-select-selection-placeholder {
        line-height: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 24px;
        color: #b5b5b9;
      }
      & .ant-select-selection-selected-value {
        visibility: hidden;
      }

      & .ant-select-selection-selected-value::before {
        visibility: visible;
        content: '강의의 주제를 설정해주세요 (최대 4건)';
      }
      & .ant-select-selection-overflow-item-suffix {
        position: absolute;
        left: 0;
        bottom: 45px;
        z-index: 2;
        min-width: 700px !important;
        ${media.medium} {
          min-width: 280px !important;
        }
      }
      & .ant-select-selection-search {
        min-width: 100% !important;
        ${media.medium} {
          min-width: 280px !important;
        }
      }
    }
  }
  & > input.search_input {
    position: absolute;
    left: 0;
    width: 90%;
    height: 44px;
    z-index: 0;
    padding: 11px 14px;
    outline: none;
    background: transparent;
    border: none;
  }
`
const None = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 75px 0;
  & > p {
    font-weight: 700;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    color: #b5b5b9;
  }
`
const ValueContent = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  & > div {
    background: rgba(181, 181, 185, 0.4);
    border-radius: 3px;
    padding: 4px 8px;
    display: flex;
    align-items: baseline;
    margin-right: 10px;
    min-width: fit-content;
    & > p {
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      color: #7c7c80;
      cursor: pointer;
    }
    & > svg {
      margin-left: 10px;
    }
  }
`
