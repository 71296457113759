import { AppRootWrapper } from 'components/layout/CommonStyle'
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import TutorInputContent from '../joinInfo/TutorInputContent'
import { useRecoilState } from 'recoil'
import { TutorRegisterClassPayloadState2 } from 'atoms/join'
import MaxPerson from './MaxPerson'
import TutorDetailInfoContent from '../joinInfo/TutorDetailInfoContent'
import TabButton from './TabButton'
import Button from '../Button'
import ClassSearchComponent from './ClassSearchComponent'
import ClassImageComponent from './ClassImageComponent'
import api from 'lib/api'
import CheckPopup from '../CheckPopup'
import type { FileType } from 'lib/types/account'
import TutorMultiComponent from '../joinInfo/TutorMultiComponent'
import { useHistory } from 'react-router-dom'
import media from 'lib/styles/media'

interface DateProps {
  day: string
  time: string
  estimated: string | number
  title: string
  step: number
}

interface Props {
  type: 'edit' | 'add'
  classId?: number | string | undefined
}
function RegisterComponent({ type, classId }: Props) {
  const history = useHistory()
  const [payload, setPayload] = useRecoilState(
    TutorRegisterClassPayloadState2(type),
  )
  const [menu, setMenu] = useState('single')
  const [checked, setChecked] = useState(false)
  const [error, setError] = useState(false)
  const [popup, setPopup] = useState(false)
  const [dateDisabled, setDateDisable] = useState(true)
  const [titleDisabled, setTitleDisable] = useState(true)
  const [detailDisabled, setDetailDisable] = useState(true)
  const [detailtIitleDisabled, setDetailtIitleDisable] = useState(true)
  const [title, setTitle] = useState('')
  const [content, setContent] = useState('')

  useEffect(() => {
    if (payload.free && payload.free === 1) {
      setChecked(true)
    } else {
      setChecked(false)
    }
  }, [payload.free])

  useEffect(() => {
    if (payload.detailType) {
      setMenu(payload.detailType)
    } else {
      setMenu('single')
    }
    const title = payload?.detailIntroduce.map((item) => item.title)
    setDetailtIitleDisable(!title)
  }, [payload.detailType])

  const TabBox = [
    { text: 'single', title: '단일 항목' },
    { text: 'multi', title: '여러 항목' },
  ]

  const handleTabClick = (text: string) => {
    if (text === '단일 항목') {
      setMenu('single')
    } else {
      setMenu('multi')
    }
  }
  useEffect(() => {
    if (popup) {
      document.documentElement.style.overflow = 'hidden'
    } else {
      document.documentElement.style.overflow = 'auto'
    }

    return () => {
      document.documentElement.style.overflow = 'auto'
    }
  }, [popup])

  // 단일 회차 버튼 비활성화 유무(날짜/시간/간격)
  const handledateDisabled = (date: boolean) => {
    setDateDisable(date)
  }
  //  다회차 버튼 비활성화 유무(날짜/시간/간격/제목)
  const handleTitleDisabled = (date: boolean) => {
    setTitleDisable(date)
  }
  const handleChange = (type: string) => (e: any) => {
    if (type === 'price') {
      if (e < 1000) {
        setError(true)
      } else {
        setError(false)
      }
      setPayload({
        ...payload,
        [type]: e,
      })
    } else {
      setPayload({
        ...payload,
        [type]: e.target.value,
      })
    }
  }
  const handleValueChange = (type: string) => (value: string) => {
    setPayload({
      ...payload,
      [type]: value,
    })
  }
  const handleValueCloseChange = (type: string) => (value: string) => {
    if (!payload.category) return
    const result = payload.category.filter((item) => item !== value)

    setPayload({
      ...payload,
      [type]: result,
    })
  }
  const handleNumberClick = (type: string) => (id: number) => {
    if (type === 'public') {
      setPayload({
        ...payload,
        password: '',
        public: id,
      })
    } else if (type === 'multi') {
      setPayload({
        ...payload,
        [type]: id,
        stepClass: [
          {
            step: 1,
            date: '',
            time: '',
            estimated: '',
            title: '',
            modify: 'Y',
          },
        ],
      })
    } else {
      setPayload({
        ...payload,
        [type]: id,
      })
    }
  }
  const onhandleChange = (value: string, index: number) => {
    const fineded = payload?.detailIntroduce?.find(
      (detail) => detail.index === index,
    )

    let modified = []

    if (fineded) {
      modified = (payload?.detailIntroduce ?? []).map((detail) => {
        if (detail.index === index) {
          return {
            ...detail,
            contents: value,
          }
        }
        return detail
      })
    } else {
      modified = [
        ...(payload?.detailIntroduce ?? []),
        {
          index,
          id: '',
          title: '',
          contents: value,
        },
      ]
    }
    setDetailDisable(value === '' || value.includes('<p><br></p>'))
    setPayload({
      ...payload,
      detailIntroduce: modified,
    })
  }
  const onTitlehandleChange = (value: string, index: number) => {
    const fineded = payload?.detailIntroduce?.find(
      (detail) => detail.index === index,
    )
    let modify = []

    if (fineded) {
      modify = (payload?.detailIntroduce ?? []).map((detail) => {
        if (detail.index === index) {
          return {
            ...detail,
            title: value,
          }
        }
        return detail
      })
    } else {
      modify = [
        ...(payload?.detailIntroduce ?? []),
        {
          index,
          id: '',
          title: value,
          contents: '',
        },
      ]
    }
    setDetailtIitleDisable(value === '')
    setPayload({
      ...payload,
      detailIntroduce: modify,
    })
  }
  const handleDelete = (index: number) => {
    const filtered = payload?.detailIntroduce?.filter(
      (detail) => detail.index !== index,
    )

    setPayload({
      ...payload,
      detailIntroduce: filtered,
    })
  }
  const handleCheckChange = (checked: boolean) => {
    if (!checked) {
      setChecked(false)
      setPayload({
        ...payload,
        free: 0,
      })
    } else {
      setChecked(true)
      setPayload({
        ...payload,
        free: 1,
      })
    }
  }
  const handleChangeFile = (files: FileType[], deleteId?: string | number) => {
    // 새로 추가한 이미지 삭제
    if (
      type === 'edit' &&
      deleteId &&
      !(payload?.images ?? []).find((image) => image.id === deleteId)
    ) {
      setPayload({
        ...payload,
        files,
      })
      return
    }

    // 저장되어 있는 이미지 삭제
    if (type === 'edit' && deleteId) {
      setPayload((payload) => {
        return {
          ...payload,
          files,
          deleteImageIds: (payload?.deleteImageIds ?? []).concat(
            String(deleteId),
          ),
        }
      })
      return
    }

    setPayload({
      ...payload,
      files,
    })
  }
  const handleStepChange = (date: DateProps, step: any) => {
    const fined = payload?.stepClass?.find((detail) => detail.step === step)
    const day = `${date.day} ${date.time}:00`
    let modify: any[] = []

    if (fined) {
      modify = (payload?.stepClass ?? []).map((detail) => {
        if (detail.step === step) {
          return {
            ...detail,
            step: date.step,
            date: day,
            estimated: date.estimated,
            title: date.title,
          }
        }
        return detail
      })
    } else {
      modify = [
        ...(payload?.stepClass ?? []),
        {
          step,
          date: day,
          estimated: date.estimated,
          title: date.title,
        },
      ]
    }

    setPayload({
      ...payload,
      stepClass: modify,
    })
  }
  // 강의등록
  const handleAddSubmit = async () => {
    const formData = new FormData()
    payload.files.map((item, i) => {
      item.file && formData.append(`images[${i}]`, item.file)
      return item.file
    })
    formData.append('title', String(payload?.classTitle))
    payload?.category &&
      payload?.category.map((item, i) => {
        formData.append(`class_categories[${i}]`, item)
        return item
      })
    formData.append('max_qty', String(payload?.person))
    formData.append('detail_introduce_type', menu)
    payload?.detailIntroduce.map((item, i) => {
      formData.append(
        `detail_introduces[${item.title ? item.title : ''}]`,
        String(item.contents),
      )
      return item
    })
    formData.append(
      'price',
      payload?.price
        ? String(payload?.price).includes(',')
          ? String(payload?.price).replaceAll(',', '')
          : payload?.price
        : '',
    )

    formData.append('is_free', String(payload?.free))

    payload?.password && formData.append('password', String(payload?.password))

    formData.append('is_public', String(payload?.public))
    formData.append('available_attend', String(payload?.attend))
    formData.append('is_multi', String(payload.multi))

    if (payload?.deleteImageIds) {
      payload?.deleteImageIds.forEach((id, index) => {
        formData.append(`delete_images[${index}]`, id)
      })
    }

    if (payload.multi === 0) {
      // 단일회차 is_multi:0
      payload.stepClass.map((item, i) => {
        formData.append('begin_date', String(item.date))
        formData.append('estimated_time', String(item.estimated))
        return item
      })
    } else {
      // 다회차 is_multi:1
      payload.stepClass.map((item, i) => {
        const index = i + 1
        formData.append(`step_title[${index}]`, String(item?.title))
        formData.append(`step_begin_date[${index}]`, String(item?.date))
        formData.append(
          `step_estimated_time[${index}]`,
          String(item?.estimated),
        )
        return item
      })
    }
    const {
      data: { success, alert },
    } = await api.post('/class/register', formData)

    if (success) {
      setTitle('강의 개설 완료')
      setContent('새로운 강의가 등록되었습니다.')
      setPopup(true)
    } else {
      if (alert.includes('회차')) {
        setTitle('회차 일시 확인')
        setContent(alert)
        setPopup(true)
      } else {
        window.alert(alert)
      }
    }
  }
  // 강의수정
  const handleEditSubmit = async () => {
    if (!classId) return

    const formData = new FormData()
    formData.append('class_id', String(classId))
    payload.files.map((item, i) => {
      !item.file
        ? formData.append(`images[${i}]`, item.image_url ? item.image_url : '')
        : formData.append(`images[${i}]`, item.file)

      return item
    })
    formData.append('title', String(payload?.classTitle))
    payload?.category &&
      payload?.category.map((item, i) => {
        formData.append(`class_categories[${i}]`, item)
        return item
      })
    formData.append('max_qty', String(payload?.person))
    formData.append('detail_introduce_type', menu)
    payload?.detailIntroduce.map((item, i) => {
      formData.append(
        `detail_introduces[${item.title ? item.title : ''}]`,
        String(item.contents),
      )
      return item
    })
    formData.append('price', String(payload?.price).replaceAll(',', ''))
    formData.append('is_free', String(payload?.free))

    payload?.password && formData.append('password', String(payload?.password))

    formData.append('is_public', String(payload?.public))
    formData.append('available_attend', String(payload?.attend))
    formData.append('is_multi', String(payload.multi))

    if (payload?.deleteImageIds) {
      payload?.deleteImageIds.forEach((id, index) => {
        formData.append(`delete_images[${index}]`, id)
      })
    }

    if (payload.multi === 0) {
      // 단일회차 is_multi:0
      payload.stepClass.map((item, i) => {
        formData.append('begin_date', String(item.date))
        formData.append('estimated_time', String(item.estimated))
        return item
      })
    } else {
      // 다회차 is_multi:1
      payload.stepClass.map((item, i) => {
        const index = i + 1
        formData.append(`step_title[${index}]`, String(item?.title))
        formData.append(`step_begin_date[${index}]`, String(item?.date))
        formData.append(
          `step_estimated_time[${index}]`,
          String(item?.estimated),
        )
        return item
      })
    }
    const {
      data: { success, alert },
    } = await api.post('/class/modify', formData)

    if (success) {
      setTitle('강의 수정 완료')
      setContent('강의 수정이 완료되었습니다.')
      setPopup(true)
    } else {
      if (alert.includes('회차')) {
        setTitle('회차 일시 확인')
        setContent(alert)
        setPopup(true)
      } else {
        window.alert(alert)
      }
    }
  }
  const handleClick = () => {
    if (title === '강의 개설 완료' || title === '강의 수정 완료') {
      setPopup(false)
      window.location.href = '/?role=tutor'
    } else {
      setPopup(false)
    }
  }

  return (
    <Wrapper>
      <Title>
        <p>{type === 'add' ? '강의 개설' : '강의 수정'}</p>
      </Title>
      <ClassImageComponent
        title="강의 소개 이미지"
        onChange={handleChangeFile}
        type={type}
        files={payload?.files ?? []}
      />
      <TutorInputContent
        title="강의 제목"
        placeholder="강의제목은 여기에 입력합니다 (최대 25자)"
        maxLength={25}
        value={payload?.classTitle}
        onChange={handleChange('classTitle')}
      />
      <ClassSearchComponent
        title="강의 카테고리 설정"
        handleChange={handleValueChange('category')}
        defaultValue={payload?.category ?? []}
        handleClose={handleValueCloseChange('category')}
      />
      <MaxPerson
        title="기준 인원"
        handlePersonClick={handleNumberClick('person')}
        data={payload?.person ?? 2}
        room={payload?.room}
        handlePopup={() => {
          setPopup(true)
          setTitle('수강 인원 초과')
          setContent('설정한 기준인원이 수강인원보다 적어 수정할 수 없습니다.')
        }}
      />
      <TutorDetailInfoContent
        Tab={TabBox}
        menu={menu}
        handleTabClick={handleTabClick}
        onhandleChange={onhandleChange}
        onDelete={handleDelete}
        onTitlehandleChange={onTitlehandleChange}
        mode={type}
        type="class"
      />
      <TutorMultiComponent
        data={payload?.multi ?? 0}
        handleClick={handleNumberClick('multi')}
        stepData={payload.stepClass}
        handleStepChange={handleStepChange}
        mode={type}
        handledateDisabled={handledateDisabled}
        handleTitleDisabled={handleTitleDisabled}
      />
      <TutorInputContent
        title="수강료"
        type="text"
        placeholder=""
        value={payload?.price}
        onChange={handleChange('price')}
        content="price"
        free={payload?.free ?? 0}
        checked={checked}
        handleChange={handleCheckChange}
        disabled={payload?.free === 1}
        error={error}
        maxLength={11}
        step={payload.stepClass.length}
        multi={payload?.multi}
      />
      <TabButton
        type="public"
        title="강의 공개여부"
        data={payload?.public ?? 1}
        handleClick={handleNumberClick('public')}
        password={payload?.password}
        onChange={handleChange('password')}
      />
      <TabButton
        type="attend"
        title="방청객 참관 가능 여부"
        data={payload?.attend ?? 1}
        handleClick={handleNumberClick('attend')}
      />
      <ButtonBox>
        <Button
          title={type === 'add' ? '등록' : '수정'}
          onClick={type === 'add' ? handleAddSubmit : handleEditSubmit}
          disabled={
            payload?.files.length === 0 ||
            !payload?.classTitle ||
            payload?.category?.length === 0 ||
            (payload.public === 0 && payload.password === '') ||
            (payload?.free === 0 &&
              (!payload?.price || Number(payload.price) < 1000)) ||
            (payload?.multi === 0 ? dateDisabled : titleDisabled) ||
            (menu === 'single'
              ? detailDisabled
              : detailDisabled || detailtIitleDisabled)
          }
        />
      </ButtonBox>
      {popup && (
        <CheckPopup onClick={handleClick} title={title} content={content} />
      )}
    </Wrapper>
  )
}

export default RegisterComponent
const Wrapper = styled(AppRootWrapper)``
const Title = styled.div`
  padding-top: 40px;
  text-align: center;
  ${media.medium} {
    padding-top: 0;
  }
  & > p {
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: #0d0d0e;
    text-align: center;
    ${media.medium} {
      display: none;
    }
  }
`
const ButtonBox = styled.div`
  margin: 40px auto 0;
  width: 380px;
  ${media.medium} {
    width: 100%;
  }
`
