import ParticipantList from 'components/room/side/ParticipantList'
import { useRoomInfoValue } from 'components/room/provider/RoomInfoProvider'
import RoomSideTab from 'components/room/side/RoomSideTab'
import ReferenceList from 'components/room/side/ReferenceList'
import TutorRoomSideFooter from 'components/room/side/TutorRoomSideFooter'
import media from 'lib/styles/media'
import React, { useState } from 'react'
import { Route, Switch } from 'react-router-dom'
import styled from 'styled-components'
import ChatContainer from './side/ChatContainer'
import ParticipantContainer from './side/ParticipantContainer'
import ReferenceContainer from './side/ReferenceContainer'

export type RoomSideTabType = 'participant' | 'chat' | 'reference' | 'teamChat'

function RoomTutorSideContainer() {
  const [tab, setTab] = useState<RoomSideTabType>('participant')

  const { role } = useRoomInfoValue()

  const handleClickTab = (tab: RoomSideTabType) => {
    setTab(tab)
  }

  return (
    <RoomSideContainerWrapper>
      <RoomSideTab selectedTab={tab} role={role} onClick={handleClickTab} />
      {tab === 'participant' && <ParticipantContainer />}
      {tab === 'reference' && <ReferenceContainer />}
      {tab === 'chat' && <ChatContainer />}
      <TutorRoomSideFooter />
    </RoomSideContainerWrapper>
  )
}

export const RoomSideContainerWrapper = styled.div`
  width: 384px;
  height: 100%;
  background: #0d0d0e;

  ${media.xlarge} {
    display: none;
  }

  display: flex;
  flex-direction: column;

  & .tab {
    flex: 1;
  }
`

export default RoomTutorSideContainer
