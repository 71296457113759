import React, { useState } from 'react'
import styled from 'styled-components'
import Button from '../Button'
import CheckBox from '../CheckBox'
import TermsPopup from '../join/TermsPopup'

interface ClassPaymentSubmitProps {
  isValid?: boolean
  term?: boolean
  onChangeTerm: () => void
  onSubmit: () => void
}

function ClassPaymentSubmit({
  onChangeTerm,
  onSubmit,
  isValid,
  term,
}: ClassPaymentSubmitProps) {
  const [popup, setPopup] = useState<{
    title: string
  } | null>(null)

  const handleClickTerms = () => {
    setPopup({
      title: '결제 약관 동의',
    })
  }

  const handleClose = () => {
    setPopup(null)
  }

  return (
    <Wrapper>
      <div className="class-term">
        <CheckBox
          checked={!!term}
          onChange={onChangeTerm}
          id="class_payment_term"
          type="edit"
        />
        <p className="term-text">결제 이용약관에 동의합니다.</p>
        <span className="term-view" onClick={handleClickTerms}>
          보기
        </span>
      </div>

      <div className="button-wrapper">
        <Button title="결제하기" disabled={!isValid} onClick={onSubmit} />
      </div>

      {popup && (
        <TermsPopup
          title={popup.title}
          handleClose={handleClose}
          pageType="user"
        />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  & .class-term {
    margin-top: 32px;

    display: flex;
    align-items: center;

    & .term-text {
      flex: 1;

      margin-left: 12px;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: #3b3b40;
    }

    & .term-view {
      font-weight: 500;
      font-size: 12px;
      line-height: 22px;
      color: #7574d8;
      text-decoration-line: underline;

      cursor: pointer;
    }
  }

  & .button-wrapper {
    margin-top: 24px;
  }
`

export default ClassPaymentSubmit
