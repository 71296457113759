import api from 'lib/api'
import { getAlarmListRequest } from 'lib/api/list'
import React, { useCallback, useState } from 'react'
import useSWR from 'swr'
import useScroll from './useScroll'

function useAlarm() {
  const [loading, setLoding] = useState(false)
  const { data, mutate } = useSWR(`/message?page=1`, async (url) => {
    setLoding(true)
    const responseData = await api.get(url).then((res) => res.data)
    setLoding(false)
    if (!responseData.success) return undefined
    return responseData
  })

  const [fetchMoreLoading, setFetchMoreLoading] = useState(false)

  const fetchMore = useCallback(
    async (page: number) => {
      if (!data || !data.data || fetchMoreLoading || loading) return
      setFetchMoreLoading(true)

      const response = await getAlarmListRequest(page)

      if (!response.success || !response.data) return

      mutate(
        {
          ...data,
          data: {
            user_messages: {
              last_page: response.data?.user_messages.last_page,
              current_page: response.data?.user_messages.current_page,
              data: data.data.user_messages.data.concat(
                response.data.user_messages.data,
              ),
            },
          },
        },
        false,
      )
      setFetchMoreLoading(false)
    },
    [data, mutate, fetchMoreLoading, loading],
  )
  const { target } = useScroll({
    fetchMore,
    page: data?.data?.user_messages.current_page,
    lastPage: data?.data?.user_messages.last_page,
  })
  return {
    data,
    target,
    mutate,
    fetchMoreLoading,
  }
}

export default useAlarm
