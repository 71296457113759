import { format } from 'date-fns'
import media from 'lib/styles/media'
import { formatPaymentDate, formatTime, renderTime } from 'lib/utils'
import React from 'react'
import styled from 'styled-components'

interface ClassInfoDateProps {
  date: {
    start: string
    end?: string
    learningTime?: number
  }
}

function ClassInfoDate({ date }: ClassInfoDateProps) {
  return (
    <Wrapper>
      {formatPaymentDate(date.start)} ~{date.end && formatTime(date.end)}
      {date?.learningTime && `(${renderTime(date?.learningTime)})`}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-top: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;

  ${media.medium} {
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    color: #0d0d0e;
  }
`

export default ClassInfoDate
