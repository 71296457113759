import { AppRootWrapper } from 'components/layout/CommonStyle'
import TutorIntro from 'components/tutor/TutorIntro'
import TutorIntroDetail from 'components/tutor/TutorIntroDetail'
import TutorTab from 'components/tutor/TutorTab'
import { type DetailIntroduceType, type TutorDetailType } from 'lib/api/tutor'
import media from 'lib/styles/media'
import { type TutorPageTabType } from 'lib/utils/variables'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

interface Props {
  data: TutorDetailType
}

function TutorDetailContainer({ data }: Props) {
  const { detail_introduces: detailIntroduces, ...rest } = data
  const [tab, setTab] = useState<TutorPageTabType>('intro')
  const handleChangeTab = (tab: TutorPageTabType) => {
    const classList = document.getElementById(
      'tutor_class_list_area',
    )?.offsetTop
    const reviewList = document.getElementById(
      'tutor_review_list_area',
    )?.offsetTop

    setTab(tab)

    if (tab === 'intro') {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    } else if (tab === 'classList') {
      window.scrollTo({
        top:
          (classList ? classList : 0) - (window.outerWidth > 1024 ? 250 : 140),
        behavior: 'smooth',
      })
    } else {
      window.scrollTo({
        top:
          (reviewList ? reviewList : 0) -
          (window.outerWidth > 1024 ? 250 : 140),
        behavior: 'smooth',
      })
    }
  }

  const onScroll = (e: any) => {
    const scrollTop = e.target.scrollingElement.scrollTop
    const classList = document.getElementById(
      'tutor_class_list_area',
    )?.offsetTop
    const reviewList = document.getElementById(
      'tutor_review_list_area',
    )?.offsetTop

    if (
      scrollTop >
      (reviewList ? reviewList : 0) - (window.outerWidth > 1024 ? 300 : 160)
    ) {
      setTab('reviewList')
    } else if (
      scrollTop >
      (classList ? classList : 0) - (window.outerWidth > 1024 ? 300 : 160)
    ) {
      setTab('classList')
    } else {
      setTab('intro')
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', onScroll)
  }, [])

  return (
    <Wrapper>
      <TutorIntro info={{ ...rest }} />
      <TutorTab selected={tab} onChangeTab={handleChangeTab} />
      <div
        id="tutor-intro"
        className={detailIntroduces.length > 0 ? 'active' : ''}
      >
        {detailIntroduces.map(
          (detailIntro: DetailIntroduceType, index: number) => (
            <TutorIntroDetail
              detailIntro={detailIntro}
              type={
                detailIntroduces.length <= 1 && !detailIntroduces[0]?.title
                  ? ''
                  : 'multi'
              }
              key={index}
            />
          ),
        )}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled(AppRootWrapper)`
  #tutor-intro {
    margin-top: 50px;

    display: flex;
    flex-direction: column;
    gap: 40px;

    &.active {
      margin-bottom: 100px;
    }
  }

  ${media.medium} {
    #tutor-intro {
      margin-top: 30px;

      display: block;
      gap: 0;

      &.active {
        margin-bottom: 65px;
      }
    }
  }
`
export default TutorDetailContainer
