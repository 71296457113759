import { type RoomSideTabType } from 'containers/room/RoomTutorSideContainer'
import { type RoomRoleType } from 'lib/types/room'
import {
  STUDENT_ROOM_TAB_VALIABLES,
  TEAM_ROOM_TAB_VARIABLES,
  TUTOR_ROOM_TAB_VALIABLES,
} from 'lib/utils/variables'
import React, { useMemo } from 'react'
import styled from 'styled-components'

interface RoomSideTabProps {
  role?: RoomRoleType
  selectedTab: RoomSideTabType
  onClick?: (tab: RoomSideTabType) => void
  isTeamRoom?: boolean
}

function RoomSideTab({
  role,
  selectedTab,
  onClick,
  isTeamRoom,
}: RoomSideTabProps) {
  const tablist = useMemo(() => {
    if (isTeamRoom) return TEAM_ROOM_TAB_VARIABLES
    if (role === 'tutor') return TUTOR_ROOM_TAB_VALIABLES
    if (role === 'student') return STUDENT_ROOM_TAB_VALIABLES
    return STUDENT_ROOM_TAB_VALIABLES
  }, [role, isTeamRoom])

  const handleClick = (value: RoomSideTabType) => () => {
    onClick && onClick(value)
  }

  return (
    <Wrapper>
      {tablist.map(({ label, value }, index) => (
        <div
          className={`${value === selectedTab ? 'selected tab' : 'tab'}`}
          onClick={handleClick(value as RoomSideTabType)}
          key={label}
        >
          {label}
        </div>
      ))}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  color: #fff;
  width: 100%;
  display: flex;
  align-items: center;

  padding-top: 28px;

  & .tab {
    width: 100%;

    font-weight: 500;
    font-size: 18px;
    color: rgba(124, 124, 128, 1);

    display: flex;
    justify-content: center;

    border-bottom: 2px solid rgba(59, 59, 64, 1);

    padding-bottom: 16px;

    cursor: pointer;
  }

  & .selected {
    color: rgba(249, 249, 251, 1);
    border-bottom: 2px solid rgba(249, 249, 251, 1);
  }
`

export default RoomSideTab
