import Footer from 'components/common/Footer'
import TutorGnb from 'components/common/TutorGnb'
import TutorHeader from 'components/common/header/TutorHeader'
import TutorMainComponent from 'components/common/rootpage/TutorMainComponent'
import React from 'react'
import styled from 'styled-components'

function TutorRootPage() {
  return (
    <Wrapper>
      <TutorHeader />
      <TutorMainComponent />
      <Footer type="none" />
      <TutorGnb />
    </Wrapper>
  )
}

export default TutorRootPage
const Wrapper = styled.div``
