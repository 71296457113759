import { AppRootWrapper } from 'components/layout/CommonStyle'
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Button from '../Button'
import Input from '../Input'
import media from 'lib/styles/media'
import { useRecoilState } from 'recoil'
import { JoinStepPayloadState } from 'atoms/join'
import api from 'lib/api'
import CheckPopup from '../CheckPopup'
import { useHistory } from 'react-router-dom'
import AddressInput from './AddressInput'
import AddressPopup from './AddressPopup'
import JoinSelectBox from './JoinSelectBox'

function InfoBasicComponent() {
  const [payload, setPayload] = useRecoilState(JoinStepPayloadState)
  const [popup, setPopup] = useState(false)
  const [addressPopup, setAddressPopup] = useState(false)
  const history = useHistory()
  const local = localStorage.getItem('social')

  useEffect(() => {
    if (popup || addressPopup) {
      document.documentElement.style.overflow = 'hidden'
    } else {
      document.documentElement.style.overflow = 'auto'
    }

    return () => {
      document.documentElement.style.overflow = 'auto'
    }
  }, [popup, addressPopup])

  const handleChange = (type: string) => (e: any) => {
    setPayload({
      ...payload,
      [type]: e.target.value,
    })
  }
  const handleChangeEtc = (type: string) => (e: any) => {
    setPayload({
      ...payload,
      [type]: e.target.value,
    })
  }
  const handleComplete = (data: any) => {
    let fullAddress = data.address
    let extraAddress = ''

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname
      }
      if (data.buildingName !== '') {
        extraAddress +=
          extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : ''
    }
    const Address = fullAddress
    setAddressPopup(false)
    setPayload({
      ...payload,
      residence: Address,
    })
  }
  const handleClick = (type: any) => {
    setPayload({
      ...payload,
      school_type: type,
      grade: '',
    })
  }
  const handleGradeClick = (type: any) => {
    setPayload({
      ...payload,
      grade: type,
    })
  }

  const handleSubmit = async () => {
    // 소셜로그인 추가정보 입력
    if (local) {
      const formData = new FormData()
      formData.append('is_marketing', String(payload?.is_marketing))
      formData.append('phone', String(payload?.phone))
      formData.append('name', String(payload?.name))
      formData.append(
        'school_type',
        payload?.school_type ? String(payload?.school_type) : '',
      )
      formData.append('school', String(payload?.school))
      formData.append('grade', String(payload?.grade))
      formData.append('residence', String(payload?.residence))

      const {
        data: { success, alert },
      } = await api.post('/member/sns/join', formData)

      if (success) {
        setPopup(true)
      } else {
        window.alert(alert)
      }
    } else {
      // 일반 로그인 추가정보 입력
      const formData = new FormData()
      formData.append('is_marketing', String(payload?.is_marketing))
      formData.append('phone', String(payload?.phone))
      formData.append('email', String(payload?.id))
      formData.append('password', String(payload?.password))
      formData.append('name', String(payload?.name))
      formData.append(
        'school_type',
        payload?.school_type ? String(payload?.school_type) : '',
      )
      formData.append('school', String(payload?.school))
      formData.append('grade', String(payload?.grade))
      formData.append('residence', String(payload?.residence))

      const {
        data: { success, alert },
      } = await api.post('/member/join', formData)

      if (success) {
        setPopup(true)
      } else {
        window.alert(alert)
      }
    }
  }

  return (
    <Wrapper>
      <Content>
        <div className="title_box">
          <p className="join_info_title">기본 정보 설정</p>
          <p className="join_info_text">
            디베이트온에서 이용할 기본정보를 설정해주세요.
          </p>
        </div>
        <div className="info_content_box">
          <Input
            type="text"
            title="이름"
            placeholder="이름 입력"
            value={payload?.name}
            onChange={handleChange('name')}
            maxLength={10}
          />
          <JoinSelectBox
            title="학교/학년"
            value={payload?.school_type}
            handleClick={handleClick}
            handleGradeClick={handleGradeClick}
            gradeValue={payload?.grade}
            handleChange={handleChangeEtc('grade')}
          />
          <Input
            type="text"
            title="학교 명"
            placeholder="학교명 입력 (선택)"
            value={payload?.school}
            onChange={handleChange('school')}
          />
          <AddressInput
            title="거주지"
            value={payload?.residence ?? ''}
            handleClick={() => setAddressPopup(true)}
          />
        </div>
        <div className="info_button_box">
          <Button
            title="회원가입 완료"
            onClick={handleSubmit}
            disabled={
              !payload?.name ||
              !payload.school_type ||
              !payload?.grade ||
              !payload?.residence
            }
          />
        </div>
      </Content>
      {addressPopup && (
        <AddressPopup
          handleClose={() => {
            setAddressPopup(false)
          }}
          onComplete={handleComplete}
        />
      )}
      {popup && (
        <CheckPopup
          onClick={() => {
            if (local) {
              window.location.href = '/'
              localStorage.removeItem('social')
            } else {
              window.location.href = '/login'
            }
          }}
          title="회원가입 완료"
          content="회원가입이 완료되었습니다."
        />
      )}
    </Wrapper>
  )
}

export default InfoBasicComponent
const Wrapper = styled(AppRootWrapper)``
const Content = styled.div`
  width: 440px;
  height: 680px;
  margin: 0 auto;
  padding: 80px 0 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${media.medium} {
    padding: 20px 0 80px;
    height: 100%;
    width: 100%;
  }
  & p.join_info_title {
    font-weight: 700;
    font-size: 22px;
    line-height: 34px;
    text-align: center;
    color: #0d0d0e;
    ${media.medium} {
      font-size: 18px;
      line-height: 28px;
    }
  }
  & p.join_info_text {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #3b3b40;
    margin-top: 6px;
  }
  & div.info_content_box {
    margin-top: 50px;
    width: 380px;
    min-height: 320px;
    ${media.medium} {
      width: 100%;
    }
    & > div {
      margin-bottom: 20px;
    }
    & > div:last-of-type {
      margin-bottom: 0;
    }
  }
  & div.info_button_box {
    margin-top: 40px;
    width: 380px;
    ${media.medium} {
      width: 100%;
    }
  }
`
