import { type FileType } from 'lib/types/room'
import React from 'react'
import styled from 'styled-components'
import { ReactComponent as TrashCanIcon } from 'assets/icons/trash_can_icon.svg'
import { ReactComponent as DownloadIcon } from 'assets/icons/download_icon.svg'
import axios from 'axios'
import { API_END_POINT } from 'lib/api'
import useRoom from 'lib/hooks/room/useRoom'
import { useParams } from 'react-router-dom'
import media from 'lib/styles/media'

interface ReferenceProps {
  file: FileType
  isMe: boolean
}

function Reference({ file, isMe }: ReferenceProps) {
  const { roomId }: { roomId: string } = useParams()
  const { deleteReference } = useRoom(roomId)

  const handleDelete = () => {
    deleteReference(String(file.id))
  }

  return (
    <Wrapper>
      <a className="info" href={file.file_url} target="_blank" rel="noreferrer">
        <div className="file-name">{file.file_name}</div>
        <div className="name">{file?.nickname ?? file.name}</div>
      </a>
      <div className="buttons">
        {isMe && (
          <div className="icon-wrapper" onClick={handleDelete}>
            <TrashCanIcon />
          </div>
        )}
        <a
          href={`${API_END_POINT}/download?src=${file.file_url}`}
          download={true}
        >
          <div className="icon-wrapper">
            <DownloadIcon />
          </div>
        </a>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  color: #fff;

  justify-content: space-between;

  padding: 20px 0;

  border-bottom: 1px solid rgba(59, 59, 64, 0.7);

  ${media.medium} {
    padding: 10px 0;
  }

  & .info {
    cursor: pointer;
    text-decoration: none;
  }

  & .buttons {
    display: flex;
    gap: 8px;
  }

  & .icon-wrapper {
    background: rgba(59, 59, 64, 0.8);
    border-radius: 8px;
    width: 42px;
    height: 42px;

    ${media.medium} {
      width: 36px;
      height: 36px;
    }

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;

    &:hover {
      opacity: 0.9;
    }

    &:active {
      opacity: 0.8;
    }
  }

  & .file-name {
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: #fff;

    overflow-wrap: anywhere;

    ${media.medium} {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
    }
  }

  & .name {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #7c7c80;

    ${media.medium} {
      font-weight: 400;
      font-size: 13px;
      line-height: 22px;
    }
  }
`

export default Reference
