import Footer from 'components/common/Footer'
import FindPwSuccessComponent from 'components/common/find/FindPwSuccessComponent'
import Header from 'components/common/header/Header'
import MobileTitleHeader from 'components/common/header/MobileTitleHeader'
import TutorHeader from 'components/common/header/TutorHeader'
import useUser from 'lib/hooks/account/useUser'
import media from 'lib/styles/media'
import React from 'react'
import styled from 'styled-components'

function FindPasswordSuccessPage() {
  const { user } = useUser()
  const typeUser = user && user.type

  return (
    <Wrapper>
      {typeUser === 'tutor' ? <TutorHeader /> : <Header />}
      <MobileTitleHeader type="arrow" title="비밀번호 재설정" />
      <Contents>
        <FindPwSuccessComponent />
      </Contents>
      <Footer />
    </Wrapper>
  )
}

export default FindPasswordSuccessPage
const Wrapper = styled.div``
const Contents = styled.div`
  margin-top: 100px;
  ${media.medium} {
    margin-top: 0;
  }
`
