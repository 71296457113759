import { convertTimer } from 'lib/utils'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ReactComponent as CloseIcon } from 'assets/icons/close_white_icon.svg'
import { ReactComponent as ClockIcon } from 'assets/icons/clock_icon_outlined.svg'
import media from 'lib/styles/media'
import Timer from 'components/common/Timer'
import { type RoomRoleType } from 'lib/types/room'

interface RoomTimerProps {
  timer?: number | null
  onCancel?: () => void
  onSetting?: () => void
  role: RoomRoleType
  date?: Date | null
}

function RoomTimer({ role, onCancel, date, onSetting }: RoomTimerProps) {
  const [isExit, setIsExit] = useState(false)

  const handleExit = (timer: number) => {
    if (timer <= 0) return setIsExit(true)
    onCancel && onCancel()
    setIsExit(false)
  }

  const handleSettingTimer = () => {
    if (role !== 'tutor') return
    return onSetting
  }

  useEffect(() => {
    if (isExit) {
      onCancel && onCancel()
    }
  }, [isExit])

  if ((!date || isExit) && role !== 'tutor') return null

  if (!date || isExit)
    return (
      <Wrapper onClick={handleSettingTimer()} isActive={role === 'tutor'}>
        <ClockIcon className="clock-icon" />
        <span className="timer-label">타이머</span>
      </Wrapper>
    )

  return (
    <Wrapper>
      <Timer onExit={handleExit} date={date} />
      {role === 'tutor' && (
        <CloseIcon className="close-icon" onClick={onCancel} />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div<{ isActive?: boolean }>`
  width: 132px;
  height: 53px;

  cursor: ${(props) => props.isActive && 'pointer'};

  ${media.medium} {
    width: 80px;
    height: 28px;
  }

  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px solid #7c7c80;
  border-radius: 8px;
  background: #3b3b40;

  gap: 6px;

  & .timer {
    font-weight: 500;
    font-size: 25px;
    line-height: 25px;
    color: #ffffff;

    ${media.medium} {
      font-weight: 700;
      font-size: 13px;
      line-height: 13px;
    }
  }

  & .close-icon {
    width: 17px;
    height: 17px;

    ${media.medium} {
      width: 12px;
      height: 12px;
    }
    cursor: pointer;
  }

  & .clock-icon {
    width: 24px;
    height: 24px;

    ${media.medium} {
      width: 14px;
      height: 14px;
    }
  }

  & .timer-label {
    font-weight: 500;
    font-size: 23px;
    line-height: 23px;
    color: #fdfffe;

    ${media.medium} {
      font-weight: 700;
      font-size: 13px;
      line-height: 13px;
    }
  }
`

export default RoomTimer
