import { AppRootWrapper } from 'components/layout/CommonStyle'
import media from 'lib/styles/media'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'
import useSWR from 'swr'
import PagiNationComponent from '../PagiNationComponent'
import Button from '../Button'
import { PriceNum } from 'lib/utils/CommonFuncttion'
import moment from 'moment'
import { Empty, Select } from 'antd'

import { ReactComponent as Pin } from 'assets/icons/pin_icon.svg'
import { ReactComponent as Search } from 'assets/icons/mobile_search_icon.svg'
import { ReactComponent as EmptyIcon } from 'assets/icons/no_data_icon.svg'
import { useSetRecoilState } from 'recoil'
import { fileDownloadModalState } from 'atoms/modal'
import TwoCheckPopup from '../TwoCheckPopup'
import api from 'lib/api'
import { ReactComponent as BasicImage } from '../../../assets/icons/none_profile_icon.svg'

interface Props {
  created_at: string
  id: number
  is_notice: number
  nickname: string
  profile_url: string
  title: string
  view: number
}

const ReferenceDetailComponent = ({ type }: { type: string }) => {
  const history = useHistory()
  const { id }: { id: string } = useParams()

  const setFileDownloadModal = useSetRecoilState(fileDownloadModalState)

  const { data, mutate } = useSWR(`/reference/detail?id=${id}`)
  const originData = data?.data?.reference_room

  const [twoPopup, setTwoPopup] = useState(false)

  const handleDelete = async () => {
    try {
      const {
        data: { success, alert },
      } = await api.delete('/reference/delete?id=' + id)
      if (success) {
        history.replace('/reference')
      } else {
        window.alert(alert)
      }
    } catch (err) {}
  }

  return (
    <Wrapper>
      <h1>레퍼런스 룸</h1>
      <Title>{originData?.title}</Title>
      <TutorArea>
        <Tutor>
          {!originData?.profile_url ? (
            <BasicImage className="basic_image" />
          ) : (
            <img src={originData?.profile_url} />
          )}

          {originData?.nickname}
        </Tutor>
        <span />
        <p>{moment(originData?.created_at).format('YYYY.MM.DD')}</p>
        <p>조회수 {PriceNum(originData?.view)}</p>
      </TutorArea>
      <Line />
      <FileButton
        onClick={() =>
          setFileDownloadModal({
            title: `첨부파일(${originData?.files.length})`,
            files: originData?.files,
          })
        }
      >
        첨부파일({originData?.files.length})
      </FileButton>
      <Content dangerouslySetInnerHTML={{ __html: originData?.contents }} />
      {type !== 'user' && originData?.is_my_reference === 'Y' && (
        <ButtonArea>
          <Button
            variant="outlined"
            className="small"
            title="수정"
            onClick={() => history.push(`/reference/register?id=${id}`)}
          />
          <Button
            variant="outlined"
            className="small"
            title="삭제"
            onClick={() => setTwoPopup(true)}
          />
        </ButtonArea>
      )}
      {twoPopup && (
        <TwoCheckPopup
          title="게시글 삭제"
          content="레퍼런스룸의 게시글을 삭제하시겠습니까?"
          oncancelClick={() => {
            setTwoPopup(false)
          }}
          onClick={handleDelete}
          btn="삭제"
        />
      )}
    </Wrapper>
  )
}

export default ReferenceDetailComponent

const ButtonArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 40px;

  & > button {
    margin-left: 10px;
    height: 42px;
    max-width: 160px;
  }

  ${media.medium} {
    margin-top: 30px;

    & > button {
      max-width: 100px;
    }
  }
`

const Content = styled.div`
  min-height: 400px;
`

const FileButton = styled.div`
  margin-bottom: 32px;
  background: rgba(181, 181, 185, 0.2);
  border-radius: 3px;
  padding: 5px 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.71;
  color: #7c7c80;
  width: fit-content;
  cursor: pointer;

  ${media.medium} {
    margin-bottom: 22px;
  }
`

const Line = styled.div`
  margin: 32px 0;
  width: 100%;
  height: 1px;
  background: #e1e1e2;

  ${media.medium} {
    margin: 22px 0;
  }
`

const Tutor = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.71;
  color: #7c7c80;

  & > img {
    width: 18px;
    height: 18px;
    border-radius: 20px;
    margin-right: 6px;
    object-fit: cover;
  }
  & > svg.basic_image {
    width: 18px;
    height: 18px;
    border-radius: 20px;
    margin-right: 6px;
  }

  ${media.medium} {
    font-size: 13px;
    line-height: 1.69;

    & > img {
      margin-right: 5px;
    }
  }
`

const TutorArea = styled.div`
  display: flex;
  align-items: center;

  & > span {
    width: 2px;
    height: 2px;
    margin: 2px 0 2px 8px;
    background: #7c7c80;
  }

  & > p {
    font-weight: 400;
    font-size: 14px;
    line-height: 1.71;
    color: #7c7c80;
    margin-left: 8px;
  }

  ${media.medium} {
    line-height: 1.69;

    & > p {
      position: relative;
      font-size: 13px;

      &:first-of-type {
        order: 2;
      }
      &:last-of-type {
        order: 1;
      }
    }
  }
`

const Title = styled.p`
  font-weight: 500;
  font-size: 22px;
  line-height: 1.55;
  color: #0d0d0e;
  margin-bottom: 10px;

  ${media.medium} {
    padding: 10px 0 6px;
    margin-bottom: 0;
    font-size: 16px;
    line-height: 1.62;
  }
`

const Wrapper = styled(AppRootWrapper)`
  & > h1 {
    font-weight: 700;
    font-size: 24px;
    line-height: 1.5;
    color: #0d0d0e;
    padding: 40px 0 32px;
  }

  ${media.medium} {
    & > h1 {
      display: none;
    }
  }
`
