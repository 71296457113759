import React from 'react'
import RoomContainer from 'containers/room/RoomContainer'
import RoomStudentSideContainer from 'containers/room/RoomStudentSideContainer'
import styled from 'styled-components'
import StudentRoomFooter from 'components/room/footer/StudentRoomFooter'

function StudentRoomPage() {
  return (
    <Wrapper>
      <RoomContainer />
      <RoomStudentSideContainer />
      <StudentRoomFooter />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
`

export default StudentRoomPage
