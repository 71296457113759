import media from 'lib/styles/media'
import {
  dateDiff,
  dateHyphenFormet,
  timeFormet,
} from 'lib/utils/CommonFuncttion'
import React from 'react'
import styled from 'styled-components'

interface Props {
  handleClick: (data: any) => void
  data: any
  code: any
}
function PaymentMultiComponent({ data, code, handleClick }: Props) {
  const handleStatus = (status: number, item: any) => {
    if (status === 0) {
      return <p className="status">결제완료</p>
    } else if (status === 1) {
      return <p className="status">수강완료</p>
    } else if (status === 2) {
      return (
        <p className="status pay_success" onClick={() => handleClick(item)}>
          정산완료
        </p>
      )
    } else if (status === 9) {
      return <p className="status">취소신청</p>
    } else {
      return <p className="status">결제취소</p>
    }
  }

  return (
    <Wrapper>
      <p className="info_title">회차별 결제 정보</p>
      <div className="content_box">
        {data.map((item: any, i: number) => (
          <div className="content" key={`tutor_mypage_payment_multi_${i}`}>
            <p className="index">{i + 1}.</p>
            {handleStatus(item.payment_status, item)}
            <p className="number_web">
              {code}-{item.class_step}
            </p>
            <div className="step_content">
              <p className="number_mobile">
                {code}-{item.class_step}
              </p>
              <p className="step">
                {item.step}회차 - {item.step_title}
              </p>
              <p className="step_date">
                {!item.step_real_end_date ? (
                  <>
                    {dateHyphenFormet(item.step_begin_date)}
                    {item.step_estimated_time
                      ? `~ (약 ${item.step_estimated_time}분)`
                      : ''}
                  </>
                ) : (
                  <>
                    {dateHyphenFormet(item.step_real_begin_date)} ~{' '}
                    {timeFormet(item.step_real_end_date)}{' '}
                    {dateDiff(
                      item.step_real_begin_date,
                      item.step_real_end_date,
                    )}
                  </>
                )}
              </p>
            </div>
          </div>
        ))}
      </div>
    </Wrapper>
  )
}

export default PaymentMultiComponent
const Wrapper = styled.div`
  margin-top: 40px;
  margin-bottom: 60px;

  & p.info_title {
    font-weight: 500;
    font-size: 17px;
    line-height: 26px;
    color: #0d0d0e;
    padding-bottom: 10px;
    border-bottom: 1px solid #0d0d0e;
    ${media.medium} {
      font-size: 16px;
    }
  }
  & div.content_box {
    margin-top: 20px;
    ${media.medium} {
      margin-top: 14px;
    }
  }
  & div.content {
    display: flex;
    align-items: baseline;
    margin-bottom: 12px;
    ${media.medium} {
      margin-bottom: 10px;
    }
    & > p {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: #3b3b40;
      ${media.medium} {
        font-size: 13px;
        line-height: 22px;
        margin-bottom: 10px;
      }
      &.index {
        min-width: 20px;
        margin-right: 20px;
        ${media.medium} {
          margin-right: 6px;
        }
      }
      &.status {
        min-width: 60px;
        margin-right: 20px;
        ${media.medium} {
          margin-right: 6px;
          padding: 0;
        }
      }
      &.pay_success {
        text-decoration-line: underline;
        color: #7574d8;
        cursor: pointer;
      }
      &.number_web {
        min-width: 160px;
        margin-right: 20px;
        ${media.medium} {
          display: none;
        }
      }
    }
  }
  & div.step_content {
    & p.number_mobile {
      display: none;
      ${media.medium} {
        display: block;
        margin-right: 6px;
      }
    }
    & p.step {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: #0d0d0e;
    }
    & p.step_date {
      font-weight: 500;
      font-size: 12px;
      line-height: 22px;
      color: #b5b5b9;
    }
  }
`
