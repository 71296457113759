import media from 'lib/styles/media'
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { ReactComponent as CloseIcon } from '../../../assets/icons/popup_cancel_icon.svg'
import LineButton from '../LineButton'
import CheckBox from '../CheckBox'
import { useRecoilState } from 'recoil'
import { PageTypeState } from 'atoms/common'

interface Props {
  handleClose: () => void
  onClick: () => void
}
function WithdrawalPopup({ handleClose, onClick }: Props) {
  const [check, setCheck] = useState(false)
  const [pageType, setPageType] = useRecoilState(PageTypeState)

  const handleCheck = () => {
    if (check) {
      setCheck(false)
    } else {
      setCheck(true)
    }
  }
  return (
    <Wrapper>
      <Content>
        <div className="title_box">
          <p>회원 탈퇴</p>
          <CloseIcon onClick={handleClose} />
        </div>
        <div className="content_box">
          <p className="blue_text">회원 탈퇴 전 확인해주세요.</p>
          {pageType === 'user' ? (
            <p className="content_text">
              저장된 데이터 및 계정 정보가 영구적으로 삭제 되며,
              <br />
              회원 탈퇴 후 24시간동안 동일한 휴대폰번호로
              <br />
              가입이 불가합니다. <br />
              <br />
              이에 동의하시겠습니까?
            </p>
          ) : (
            <p className="content_text">
              등록한 강의 및 계정 정보가 영구적으로 삭제 되며,
              <br />
              수강색의 결제 금액이 모두 환불됩니다.
              <br />
              또한 회원 탈퇴 후 24시간동안 동일한 휴대폰번호로
              <br />
              가입이 불가합니다. <br />
              <br />
              이에 동의하시겠습니까?
            </p>
          )}
        </div>
        <div className="bottom_box">
          <div className="check_box">
            <CheckBox
              checked={check}
              onChange={handleCheck}
              id="withdrawal_check_box"
              type="edit"
            />
            <p>회원 탈퇴에 동의합니다.</p>
          </div>
          <div className="button_box">
            <LineButton
              title="회원 탈퇴"
              type="gray"
              disabled={!check}
              onClick={onClick}
            />
          </div>
        </div>
      </Content>
    </Wrapper>
  )
}

export default WithdrawalPopup

const Wrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  z-index: 110;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
`
const Content = styled.div`
  width: 380px;
  min-height: 436px;
  background: #fff;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 14px;
  border: 1px solid #e1e1e2;
  padding: 26px;
  ${media.medium} {
    width: 100%;
    height: 100%;
    border-radius: 0;
    position: relative;
  }

  & div.title_box {
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    & > p {
      font-weight: 700;
      font-size: 18px;
      line-height: 28px;
      color: #0d0d0e;
    }
  }
  & div.content_box {
    padding: 16px 0 56px;
  }
  & p.blue_text {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #57569e;
  }
  & p.content_text {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #3b3b40;
    margin-top: 16px;
  }
  & div.check_box {
    margin-bottom: 36px;
    display: flex;
    align-items: center;
    ${media.medium} {
      margin-bottom: 32px;
    }
    & > p {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: #3b3b40;
      margin-left: 12px;
    }
  }
  & div.button_box {
    & > button {
      margin-top: 0;
    }
  }
  & div.bottom_box {
    display: flex;
    flex-direction: column;
    ${media.medium} {
      position: fixed;
      bottom: 20px;
      left: 0;
      width: 100%;
      padding: 0 26px;
    }
  }
`
