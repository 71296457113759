import { AppRootWrapper } from 'components/layout/CommonStyle'
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { ReactComponent as MainLogo } from '../../../assets/icons/tutor_main_logo.svg'
import { ReactComponent as MenuHoverLogo } from '../../../assets/icons/header_menu_hover_icon.svg'
import { ReactComponent as MenuLogo } from '../../../assets/icons/header_menu_icon.svg'
import { useHistory } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { PageTypeState } from 'atoms/common'
import media from 'lib/styles/media'
import useIndex from 'lib/hooks/useIndex'
import CheckPopup from '../CheckPopup'
import useUser from 'lib/hooks/account/useUser'

function TutorHeader() {
  const history = useHistory()
  const { data } = useIndex()
  const { user } = useUser()
  const tutorUser = user && user.type === 'tutor'
  const [isMenu, SetIsMenu] = useState(false)
  const [pageType, setPageType] = useRecoilState(PageTypeState)
  const [popup, setPopup] = useState(false)

  useEffect(() => {
    if (popup) {
      document.documentElement.style.overflow = 'hidden'
    } else {
      document.documentElement.style.overflow = 'auto'
    }

    return () => {
      document.documentElement.style.overflow = 'auto'
    }
  }, [popup])

  const pageTypeChange = () => {
    setPageType('user')
    history.push('/')
  }

  const handleMouseOver = () => {
    if (!isMenu) {
      SetIsMenu(true)
    }
  }
  const handleMouseOut = () => {
    if (isMenu) {
      SetIsMenu(false)
    }
  }

  return (
    <Wrapper>
      <Content>
        <MainLogo
          onClick={() => {
            history.push('/?role=tutor')
          }}
        />
        <CenterContent>
          <p
            className="center_text"
            onClick={() => {
              if (!data) {
                history.push('/login?role=tutor')
              } else {
                history.push('/manage/list?type=0&role=tutor')
              }
            }}
          >
            강의 관리
            {tutorUser && data?.data.user.is_manager_class === 'Y' && (
              <span>{data?.data.user.manager_class_count}</span>
            )}
          </p>
          <p
            className="center_text"
            onClick={() => {
              if (!tutorUser) return history.push('/login?role=tutor')
              if (tutorUser && data && data.data.user.status === 1) {
                // status(1:승인 0:미승인)
                history.push('/register/class?role=tutor')
              } else {
                setPopup(true)
              }
            }}
          >
            강의 개설
          </p>
          <p
            className="center_text"
            onClick={() => {
              if (!tutorUser) {
                history.push('/login?role=tutor')
              } else {
                history.push('/mypage/edit/info?role=tutor')
              }
            }}
          >
            튜터 정보 설정
          </p>
        </CenterContent>
        <RightContent>
          <div className="page_button" onClick={pageTypeChange}>
            <p>학생 페이지로 이동</p>
          </div>
          {isMenu ? (
            <MenuHoverLogo
              onMouseOut={handleMouseOut}
              onClick={() => {
                history.push('/menu?role=tutor')
              }}
            />
          ) : (
            <MenuLogo
              onMouseOver={handleMouseOver}
              onClick={() => {
                history.push('/menu?role=tutor')
              }}
            />
          )}
        </RightContent>
      </Content>
      {popup && (
        <CheckPopup
          onClick={() => {
            setPopup(false)
          }}
          title="튜터 미승인"
          content={'튜터 승인이 완료되면 \n강의를 등록할 수 있습니다.'}
        />
      )}
    </Wrapper>
  )
}

export default TutorHeader
const Wrapper = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  position: fixed;
  left: 0;
  top: 0;
  background: #fff;
  border-bottom: 1px solid #eeeff1;
  z-index: 100;
  ${media.medium} {
    display: none;
  }
`
const Content = styled(AppRootWrapper)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > svg {
    cursor: pointer;
  }
`
const CenterContent = styled.div`
  display: flex;
  align-items: center;
  & p.center_text {
    font-weight: 700;
    font-size: 17px;
    line-height: 26px;
    color: #0d0d0e;
    margin-right: 24px;
    cursor: pointer;
    position: relative;
    & > span {
      position: absolute;
      right: -20px;
      top: -13px;
      background: #7574d8;
      border-radius: 14px;
      padding: 0 9px;
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  & p.center_text:last-of-type {
    margin-right: 0;
  }
`
const RightContent = styled.div`
  display: flex;
  align-items: center;
  & div.page_button {
    width: 142px;
    padding: 8px 16px;
    background: rgba(117, 116, 216, 0.2);
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 18px;
    & > p {
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      color: #7574d8;
      cursor: pointer;
    }
  }
`
