import React, { useState, useMemo } from 'react'
import styled from 'styled-components'
import { RoomFooterWrapper } from './TutorRoomFooter'
import { ReactComponent as ArrowIcon } from 'assets/icons/left_arrow.svg'
import { ReactComponent as MikeOnIcon } from 'assets/icons/mike_on_icon.svg'
import { ReactComponent as MikeOffIcon } from 'assets/icons/mike_off_icon.svg'
import { ReactComponent as CameraOnIcon } from 'assets/icons/camera_on_icon.svg'
import { ReactComponent as CameraOffIcon } from 'assets/icons/camera_off_icon.svg'
import { ReactComponent as PeopleIcon } from 'assets/icons/people_icon.svg'
import { ReactComponent as PeopleGrayIcon } from 'assets/icons/people_gray_icon.svg'
import { ReactComponent as HandIcon } from 'assets/icons/hand_icon.svg'
import { ReactComponent as HandGrayIcon } from 'assets/icons/hand_gray_icon.svg'
import { ReactComponent as ChatIcon } from 'assets/icons/chat_icon.svg'
import { ReactComponent as DocumentIcon } from 'assets/icons/document_icon.svg'
import { ReactComponent as PersonIcon } from 'assets/icons/person_icon.svg'
import FooterIconWrap from 'components/common/FooterIconWrap'
import {
  useRoomInfoAction,
  useRoomInfoValue,
} from '../provider/RoomInfoProvider'
import { type RoomSeatInfoType } from 'lib/types/room'
import { useHistory, useParams } from 'react-router-dom'

function StudentRoomFooter() {
  const [open, setOpen] = useState(false)
  const { myRoomInfo, endDate, isDebating, isRequestTeamDebating } =
    useRoomInfoValue()
  const { modifyRoomInfo, requestTeamDebating, enterTeamRoom } =
    useRoomInfoAction()

  const history = useHistory()
  const { roomId }: { roomId: string } = useParams()

  const disabledTeamRoom = useMemo(() => {
    if (!myRoomInfo?.currentTeam) return true
    return !endDate[myRoomInfo?.currentTeam]
  }, [endDate, myRoomInfo?.currentTeam])

  const disabledRequestDebating = useMemo(() => {
    if (!myRoomInfo?.currentTeam) return true
    return isRequestTeamDebating[myRoomInfo?.currentTeam]
  }, [myRoomInfo?.currentTeam, isRequestTeamDebating])

  const handleClickrequestTeamDetaing = () => {
    if (!myRoomInfo?.currentTeam) return
    requestTeamDebating && requestTeamDebating(myRoomInfo?.currentTeam)
  }

  const onToggle = () => {
    setOpen(!open)
  }

  const handleToggleCamera = () => {
    const myRoomInfoData = myRoomInfo as RoomSeatInfoType
    if (myRoomInfoData.is_tutor_camera === 0) return

    modifyRoomInfo &&
      modifyRoomInfo({
        isCam: myRoomInfoData.is_camera !== 1,
        isMike: myRoomInfoData.is_mike === 1,
        tableNum: myRoomInfoData.table_num,
        userId: myRoomInfoData.user_id,
      })
  }

  const handleToggleMike = () => {
    const myRoomInfoData = myRoomInfo as RoomSeatInfoType
    if (myRoomInfoData.is_tutor_mike === 0) return

    modifyRoomInfo &&
      modifyRoomInfo({
        isCam: myRoomInfoData.is_camera === 1,
        isMike: myRoomInfoData.is_mike !== 1,
        tableNum: myRoomInfoData.table_num,
        userId: myRoomInfoData.user_id,
      })
  }

  const enterRoom = () => {
    if (!myRoomInfo || !myRoomInfo.currentTeam) return

    const team = myRoomInfo.currentTeam

    enterTeamRoom &&
      enterTeamRoom({
        tableNum: myRoomInfo.table_num,
        team,
        userId: myRoomInfo.user_id,
      })
    history.push(`/room/${roomId}/teamroom/${team}`)
  }

  const handleClickviewParticipantList = () => {
    history.push(`/room/${roomId}/participants`)
  }

  const handleClickChat = () => {
    history.push(`/room/${roomId}/chat`)
  }

  const handleClickReference = () => {
    history.push(`/room/${roomId}/reference`)
  }

  return (
    <Wrapper>
      <div className="arrow-wrap" onClick={onToggle}>
        <ArrowIcon className={`arrow ${open ? 'arrow-down' : 'arrow-up'}`} />
      </div>
      {open && (
        <div className="buttons">
          {isDebating && (
            <FooterIconWrap
              Icon={disabledRequestDebating ? HandGrayIcon : HandIcon}
              label={disabledRequestDebating ? '회의요청중' : '작전 회의'}
              disabled={disabledRequestDebating}
              onClick={handleClickrequestTeamDetaing}
            />
          )}
          <FooterIconWrap
            Icon={myRoomInfo?.is_camera === 1 ? CameraOffIcon : CameraOnIcon}
            label={myRoomInfo?.is_camera === 1 ? '화면 off' : '화면 on'}
            onClick={handleToggleCamera}
          />
          <FooterIconWrap
            Icon={myRoomInfo?.is_mike === 1 ? MikeOffIcon : MikeOnIcon}
            label={myRoomInfo?.is_mike === 1 ? '마이크 off' : '마이크 on'}
            onClick={handleToggleMike}
          />
          <FooterIconWrap
            Icon={disabledTeamRoom ? PeopleGrayIcon : PeopleIcon}
            label="소회의방"
            disabled={disabledTeamRoom}
            onClick={enterRoom}
          />
          <FooterIconWrap
            Icon={ChatIcon}
            label="채팅"
            onClick={handleClickChat}
          />
          <FooterIconWrap
            Icon={DocumentIcon}
            label="자료"
            onClick={handleClickReference}
          />
          <FooterIconWrap
            Icon={PersonIcon}
            label="참가자"
            onClick={handleClickviewParticipantList}
          />
        </div>
      )}
    </Wrapper>
  )
}

const Wrapper = styled(RoomFooterWrapper)``

export default StudentRoomFooter
