import axios from 'axios'
import api from 'lib/api'
import { pcConfig } from 'lib/config'
import useRoom from 'lib/hooks/room/useRoom'
import { type UserInfo } from 'lib/types/account'
import {
  type TutorRoomInfoType,
  type RoomRoleType,
  type RoomSeatInfoType,
  type TeamType,
  type BasicUserInfo,
} from 'lib/types/room'
import { initialSeatInfo } from 'lib/utils'
import React, {
  createContext,
  useEffect,
  useMemo,
  useRef,
  useState,
  useContext,
} from 'react'
import * as fns from 'date-fns'
import { useSetRecoilState } from 'recoil'
import { voteModalState } from 'atoms/modal'
import { useHistory } from 'react-router-dom'
import * as io from 'socket.io-client'
import Echo from 'laravel-echo/dist/echo'

export type ChangeSeatType = (props: {
  roomId: number
  userId: number
  tableNum: number
}) => void

export type ExitUserType = (props: { roomId: number; userId: number }) => void

export interface ExchangedSeatType {
  userId: number
  tableNum: number
}

export type ExchageSeatBefore = (props: {
  userId: number
  tableNum: number
}) => void
export type ExchageSeatAfter = (props: { tableNum: number }) => void
export type ModifyRoomInfoType = (props: {
  userId: number
  isMike: boolean
  isCam: boolean
  tableNum: number | null
  kind?: 'camera' | 'mike' | 'focus'
}) => void
export type ModifyTutorInfoType = (props: {
  isMike: boolean
  isCam: boolean
}) => void
export type EnterTeamRoom = (props: {
  userId: number
  team: 'A' | 'B'
  tableNum: number | null
}) => void
export type ExitTeamRoom = (props: {
  userId: number
  tableNum: number | null
}) => void

export type EnterTeamRoomTutor = (props: {
  team: 'A' | 'B'
  isHide: boolean
}) => void
export type ExitTeamRoomTutor = () => void
export type RequestTeamDebatingType = (tea: 'A' | 'B') => void

interface RoomTimerType {
  A?: number | null
  B?: number | null
}

interface TeamCountType {
  A: number
  B: number
}

interface EndDateType {
  A?: Date | null
  B?: Date | null
  room?: Date | null
  vote?: Date | null
}

interface IsRequestTeamDebatingType {
  A: boolean
  B: boolean
}

interface VoteCountType {
  A: number
  B: number
}

export const RoomInfoValueContext = createContext<{
  membersRoomInfo?: RoomSeatInfoType[]
  myRoomInfo?: RoomSeatInfoType
  tutorRoomInfo?: TutorRoomInfoType
  focusUserRoomInfo?: RoomSeatInfoType
  role: RoomRoleType
  maxCount: number
  exchangedSeat?: ExchangedSeatType
  timer: RoomTimerType
  teamCount: TeamCountType
  endDate: EndDateType
  isRequestTeamDebating: IsRequestTeamDebatingType
  isDebating: boolean
  audiences: BasicUserInfo[]
  voteCount?: VoteCountType
}>({
  audiences: [],
  maxCount: 0,
  role: 'audience',
  timer: {
    A: null,
    B: null,
  },
  teamCount: {
    A: 0,
    B: 0,
  },
  endDate: {
    A: null,
    B: null,
  },
  isRequestTeamDebating: { A: false, B: false },
  isDebating: false,
})

type ExcuteVoteType = (team: 'A' | 'B') => void
export type FocusUserType = (userId: number) => void
export type BlurFocusUserType = () => void

export const RoomInfoActionContext = createContext<
  | {
      changeSeat: ChangeSeatType
      exitUser: ExitUserType
      exchangeSeatBefore: ExchageSeatBefore
      exchangeSeatAfter: ExchageSeatAfter
      resetExchangeSeat: () => void
      modifyRoomInfo: ModifyRoomInfoType
      modifyTutorInfo: ModifyTutorInfoType
      enterTeamRoom: EnterTeamRoom
      exitTeamRoom: ExitTeamRoom
      enterTeamRoomTutor: EnterTeamRoomTutor
      exitTeamRoomTutor: ExitTeamRoomTutor
      requestTeamDebating: RequestTeamDebatingType
      excuteVote: ExcuteVoteType
      focusUserTrigger: FocusUserType
      blurFocusUserTrigger: BlurFocusUserType
    }
  | undefined
>(undefined)

export function useRoomInfoValue() {
  const value = useContext(RoomInfoValueContext)
  if (!value) throw new Error('not wrapped with RoomInfoProvider')
  return value
}

export function useRoomInfoAction() {
  const action = useContext(RoomInfoActionContext)
  if (!action) throw new Error('not wrapped with RoomInfoProvider')
  return action
}

interface RoomInfoProviderProps {
  children: React.ReactNode
  role: RoomRoleType
  user: UserInfo
  roomId: string
}

function RoomInfoProvider({
  children,
  role,
  user,
  roomId,
}: RoomInfoProviderProps) {
  const {
    data,
    modifyTeamRoom,
    closeTeamRoom,
    modifyDebateStatus,
    setDebatingTimer,
    clearDebatingTimer,
    setVoteTimer,
    clearVoteTimer,
    uploadReferenceHandler,
    deleteReferenceHandler,
    exitRoom,
  } = useRoom(roomId)

  const { seats, tableNum } = initialSeatInfo(
    data?.seat ?? [],
    data?.room.room_qty ?? 0,
  )
  const history = useHistory()
  const memberPcsRef = useRef<{
    [key in string]: RTCPeerConnection & { streamId?: string }
  }>({})
  const tutorPcRef = useRef<
    (RTCPeerConnection & { streamId?: string }) | undefined
  >()
  const setVoteModal = useSetRecoilState(voteModalState)

  const [membersRoomInfo, setMembersRoomInfo] =
    useState<RoomSeatInfoType[]>(seats)
  const [tutorRoomInfo, setTutorRoomInfo] = useState<
    TutorRoomInfoType | undefined
  >()
  const [audiencesInfo, setAudiencesInfo] = useState<BasicUserInfo[]>([])
  const [focusUserId, setFocusUserId] = useState<number>()

  const [localStream, setLocalStream] = useState<
    MediaStream | undefined | 'empty'
  >()

  const [exchangedSeat, setExchagedSeat] = useState<
    ExchangedSeatType | undefined
  >()
  const [isRequestTeamDebating, setIsRequestTeamDebating] =
    useState<IsRequestTeamDebatingType>({ A: false, B: false })

  const [voteCount, setVoteCount] = useState<VoteCountType | undefined>()

  const initialize = async () => {
    await api.post(`/rtc/joinRoom`, {
      class_id: roomId,
      user_id: user.id,
    })
  }

  const sendAudienceInfo = async () => {
    await api.post('https://api.debateon.fifteenh.io/api/rtc/handler', {
      type: 'audience',
      id: user.id,
      nickname: user.nickname,
      profile_url: user.profile_url,
      name: user.name,
      class_id: roomId,
    })
  }

  const sendTutorInfo = async () => {
    await axios.post('https://api.debateon.fifteenh.io/api/rtc/handler', {
      type: 'tutor',
      id: user.id,
      is_camera: data?.room.tutor_camera,
      is_mike: data?.room.tutor_mike,
      nickname: user.nickname,
      profile_url: user.profile_url,
      class_id: roomId,
    })
  }

  const getMyStream = async () => {
    let stream: MediaStream | undefined

    try {
      if (role === 'audience') throw new Error('no media stream')

      stream = await navigator.mediaDevices.getUserMedia({
        audio: {
          echoCancellation: false,
        },
        video: {
          width: { min: 360, ideal: 742 },
          height: { min: 214, ideal: 444 },
          aspectRatio: { ideal: 1.67 },
        },
      })

      setLocalStream(stream)

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      window.localStream = stream
    } catch (e) {
      setLocalStream('empty')
    }

    if (role === 'tutor') {
      await sendTutorInfo()
      return setTutorRoomInfo({
        id: user.id,
        is_camera: data?.room.tutor_camera ?? 0,
        is_mike: data?.room.tutor_mike ?? 0,
        nickname: user.nickname,
        profile_url: user.profile_url,
        stream,
      })
    }

    if (role === 'audience') {
      await sendAudienceInfo()
    }

    if (role === 'student') {
      if (seats.find((seat) => seat.user_id === user.id)) {
        setMembersRoomInfo((prev) => {
          return prev.map((seat) => {
            if (seat?.user_id === user.id) {
              return {
                ...seat,
                stream,
              }
            }

            return seat
          })
        })
        return
      }

      setMembersRoomInfo((prev: any) => {
        return prev.concat({
          id: 1,
          name: user.name,
          nickname: user.nickname,
          class_id: roomId,
          user_id: user.id,
          table_num: tableNum,
          is_mike: 1,
          is_camera: 1,
          profile_url: user.profile_url,
          stream,
        })
      })

      await api.post('/rtc/info/modify', {
        class_id: roomId,
        user_id: user.id,
        table_num: tableNum,
        is_mike: 1,
        is_camera: 1,
        kind: 'new',
      })
    }
  }

  const changeSeat: ChangeSeatType = async ({ roomId, userId, tableNum }) => {
    await api.post('/rtc/info/modify', {
      class_id: roomId,
      user_id: userId,
      table_num: tableNum,
      kind: 'seat',
    })
  }

  const exitUser: ExitUserType = async ({ roomId, userId }) => {
    await api.post('/rtc/info/modify', {
      class_id: roomId,
      user_id: userId,
      table_num: null,
      kind: 'exit',
    })
  }

  const exitTutor = async () => {
    await axios.post('https://api.debateon.fifteenh.io/api/rtc/handler', {
      class_id: roomId,
      type: 'exitTutor',
    })
  }

  const exitTutorHandler = () => {
    setTutorRoomInfo(undefined)
    tutorPcRef.current?.close()
    tutorPcRef.current = undefined
  }

  const exchangeSeatBefore: ExchageSeatBefore = ({
    userId,
    tableNum,
  }: {
    userId: number
    tableNum: number
  }) => {
    setExchagedSeat({
      userId,
      tableNum,
    })
  }

  const resetExchangeSeat = () => {
    setExchagedSeat(undefined)
  }

  const exchangeSeatAfter: ExchageSeatAfter = async ({ tableNum }) => {
    if (!exchangedSeat) return

    const { data } = await api.post('/rtc/info/modify', {
      class_id: roomId,
      user_id: exchangedSeat.userId,
      table_num: tableNum,
      old_table_num: exchangedSeat.tableNum,
      kind: 'exchange',
      team: 'empty',
    })

    data.success && resetExchangeSeat()
  }

  const modifyRoomInfo: ModifyRoomInfoType = async ({
    isCam,
    isMike,
    kind,
    tableNum,
    userId,
  }) => {
    if (role === 'tutor') {
      await api.post('/rtc/info/modify', {
        class_id: roomId,
        user_id: userId,
        is_mike: isMike ? 1 : 0,
        is_camera: isCam ? 1 : 0,
        is_tutor_mike: isMike ? 1 : 0,
        is_tutor_camera: isCam ? 1 : 0,
        table_num: tableNum,
        kind,
      })
      return
    }

    await api.post('/rtc/info/modify', {
      class_id: roomId,
      user_id: userId,
      is_mike: isMike ? 1 : 0,
      is_camera: isCam ? 1 : 0,
      table_num: tableNum,
      kind,
    })
  }

  const modifyTutorInfo: ModifyTutorInfoType = async ({ isCam, isMike }) => {
    await api.post(`/class/debate/tutor`, {
      class_id: roomId,
      tutor_camera: isCam ? 1 : 0,
      tutor_mike: isMike ? 1 : 0,
    })

    await axios.post('https://api.debateon.fifteenh.io/api/rtc/handler', {
      type: 'modifyTutor',
      id: user.id,
      is_camera: isCam ? 1 : 0,
      is_mike: isMike ? 1 : 0,
      class_id: roomId,
    })
  }

  const enterTeamRoom = async ({
    userId,
    team,
    tableNum,
  }: {
    userId: number
    team: 'A' | 'B'
    tableNum: number | null
  }) => {
    await api.post('/rtc/info/modify', {
      type: 'enterTeamRoom',
      user_id: userId,
      class_id: roomId,
      table_num: tableNum,
      team,
    })

    await axios.post('https://api.debateon.fifteenh.io/api/rtc/handler', {
      type: 'enterTeamRoom',
      user_id: userId,
      class_id: roomId,
      table_num: tableNum,
      team,
    })
  }

  const exitTeamRoom: ExitTeamRoom = async ({ userId, tableNum }) => {
    await api.post('/rtc/info/modify', {
      type: 'exitTeamRoom',
      user_id: userId,
      class_id: roomId,
      table_num: tableNum,
      team: 'empty',
    })

    await axios.post('https://api.debateon.fifteenh.io/api/rtc/handler', {
      type: 'exitTeamRoom',
      user_id: userId,
      class_id: roomId,
      table_num: tableNum,
      team: undefined,
    })
  }

  const enterTeamRoomTutor: EnterTeamRoomTutor = async ({ team, isHide }) => {
    if (isHide) {
      if (localStream && localStream !== 'empty') {
        localStream?.getTracks().forEach((track) => {
          track.enabled = false
        })
      }
      setTutorRoomInfo((prev) => {
        if (!prev) return
        return {
          ...prev,
          team,
          is_camera: 0,
          is_mike: 0,
          isSecretEnter: true,
        }
      })
    } else {
      await axios.post('https://api.debateon.fifteenh.io/api/rtc/handler', {
        type: 'enterTeamRoomTutor',
        class_id: roomId,
        team,
      })
    }
  }

  const exitTeamRoomTutor: ExitTeamRoomTutor = async () => {
    if (localStream && localStream !== 'empty') {
      localStream?.getTracks().forEach((track) => {
        track.enabled = true
      })
    }
    setTutorRoomInfo((prev) => {
      if (!prev) return
      return {
        ...prev,
        isSecretEnter: false,
      }
    })
    await axios.post('https://api.debateon.fifteenh.io/api/rtc/handler', {
      type: 'exitTeamRoomTutor',
      team: 'empty',
      class_id: roomId,
    })
  }

  const requestTeamDebating: RequestTeamDebatingType = async (team) => {
    await axios.post('https://api.debateon.fifteenh.io/api/rtc/handler', {
      class_id: roomId,
      type: 'requestTeamDebating',
      team,
    })
  }
  const excuteVote = async (team: 'A' | 'B') => {
    await api.post('/class/vote', {
      class_id: roomId,
      is_agree: team === 'A' ? 1 : 0,
    })
    setVoteModal(false)
  }

  const focusUserTrigger = async (userId: number) => {
    await axios.post('https://api.debateon.fifteenh.io/api/rtc/handler', {
      class_id: roomId,
      type: 'focusUser',
      userId,
    })
  }

  const focusUser = async (userId: number) => {
    setFocusUserId(userId)
  }

  const blurFocusUserTrigger = async () => {
    await axios.post('https://api.debateon.fifteenh.io/api/rtc/handler', {
      class_id: roomId,
      type: 'blurFocusUser',
    })
  }

  const blurFocusUser = () => {
    setFocusUserId(undefined)
  }

  const disconnectPeerConnection = (userId: number) => {
    setTutorRoomInfo((prev) => {
      if (prev?.id === userId) return undefined
      return prev
    })

    setMembersRoomInfo((prev) => {
      return prev.filter((info) => info.user_id !== userId)
    })

    memberPcsRef.current[userId]?.close()
    delete memberPcsRef.current?.[userId]
  }

  // 화상연결
  const createPeerConnection = ({
    userId,
    kind,
  }: {
    userId: number
    kind: RoomRoleType
  }) => {
    const pc = new RTCPeerConnection(pcConfig)

    if (role !== 'audience' && localStream && localStream !== 'empty') {
      localStream?.getTracks().forEach((track: any) => {
        pc.addTrack(track, localStream)
      })
    }

    if (kind === 'tutor') {
      tutorPcRef.current = pc
    }

    if (kind === 'student' || kind === 'audience') {
      memberPcsRef.current = {
        ...memberPcsRef.current,
        [userId]: pc,
      }
    }

    // if (role === 'audience') {
    // }

    pc.onicecandidate = async (e) => {
      if (e.candidate) {
        setTimeout(async () => {
          await api.post('/rtc/candidate', {
            candidate: e.candidate,
            class_id: roomId,
            user_id: user.id,
            receiver_id: userId,
            kind: role,
          })
        }, 150)
      }
    }
    pc.ontrack = (e) => {
      if (kind === 'tutor') {
        setTutorRoomInfo((prev) => {
          if (prev?.id === userId) {
            return {
              ...prev,
              stream: e.streams[0],
            }
          }
          return prev
        })

        return
      }

      if (kind === 'student') {
        setMembersRoomInfo((prev) => {
          return prev.map((info) => {
            if (info?.user_id === userId) {
              return {
                ...info,
                stream: e.streams[0],
              }
            }
            return info
          })
        })
      }
    }

    pc.onconnectionstatechange = (e: any) => {
      if (
        e.target?.iceConnectionState === 'closed'
        // || e.target?.iceConnectionState === 'disconnected'
      ) {
        disconnectPeerConnection(userId)
        if (role === 'tutor') {
          exitUser({ roomId: parseInt(roomId), userId })
        }
      }
    }

    return pc
  }

  const createOffer = async ({
    userId,
    kind,
  }: {
    userId: number
    kind: RoomRoleType
  }) => {
    if (role === 'tutor') {
      await sendTutorInfo()
    }

    const pc = createPeerConnection({
      userId,
      kind,
    })

    const offer = await pc.createOffer({
      offerToReceiveAudio: kind !== 'audience',
      offerToReceiveVideo: kind !== 'audience',
    })

    pc.setLocalDescription(new RTCSessionDescription(offer))

    await api.post(`/rtc/offer`, {
      user_id: user.id,
      class_id: roomId,
      offer,
      kind: role,
      receiver_id: userId,
    })
  }

  const createAnswer = async ({
    userId,
    kind,
    offer,
  }: {
    userId: number
    kind: RoomRoleType
    offer: RTCSessionDescriptionInit
  }) => {
    const pc = createPeerConnection({
      userId,
      kind,
    })

    await pc.setRemoteDescription(new RTCSessionDescription(offer))

    const answer = await pc.createAnswer({
      offerToReceiveVideo: kind !== 'audience',
      offerToReceiveAudio: kind !== 'audience',
    })

    pc.setLocalDescription(new RTCSessionDescription(answer))

    await api.post('/rtc/answer', {
      class_id: roomId,
      user_id: user.id,
      answer,
      kind: role,
      receiver_id: userId,
    })
  }

  const receiveAnswer = async ({
    userId,
    kind,
    answer,
  }: {
    userId: number
    kind: RoomRoleType
    answer: RTCSessionDescriptionInit
  }) => {
    let pc

    if (kind === 'tutor') {
      pc = tutorPcRef.current
    }

    if (kind === 'student' || kind === 'audience') {
      pc = memberPcsRef.current[userId]
    }

    if (pc?.signalingState !== 'stable') {
      await pc?.setRemoteDescription(new RTCSessionDescription(answer))
    }
  }

  const exitMe = async () => {
    if (localStream && localStream !== 'empty') {
      localStream?.getTracks().forEach((track) => track.stop())
    }

    if (role === 'tutor') {
      await exitTutor()
      return
    }
    exitUser({ roomId: parseInt(roomId), userId: user.id })
  }

  const maxCount = useMemo(
    () => data?.room.room_qty ?? 0,
    [data?.room.room_qty],
  )

  const myRoomInfo = useMemo(() => {
    const myInfo = membersRoomInfo.find((info) => info.user_id === user.id)
    if (!myInfo) return
    let currentTeam

    if (myInfo.table_num <= maxCount / 2) currentTeam = 'A' as TeamType
    if (myInfo.table_num > maxCount / 2) currentTeam = 'B' as TeamType

    return { ...myInfo, currentTeam }
  }, [membersRoomInfo, user, maxCount])

  const timer = useMemo(() => {
    let A
    let B

    if (!data?.room?.team_a_date) A = null
    if (!data?.room.team_b_date) B = null

    const currentDate = new Date()

    if (data?.room?.team_a_date) {
      const closedAt = new Date(
        (data?.room?.team_a_date as unknown as string).replace(/-/g, '/'),
      )
      const diff = fns.differenceInSeconds(closedAt, currentDate)

      A = diff <= 0 ? null : diff
    }

    if (data?.room?.team_b_date) {
      const closedAt = new Date(
        (data?.room?.team_b_date as unknown as string).replace(/-/g, '/'),
      )
      const diff = fns.differenceInSeconds(closedAt, currentDate)

      B = diff <= 0 ? null : diff
    }

    return { A, B }
  }, [data?.room?.team_a_date, data?.room?.team_b_date])

  const endDate = useMemo(() => {
    let A
    let B
    let room
    let vote
    if (!data?.room?.team_a_date) {
      A = null
    }

    if (!data?.room?.team_b_date) {
      B = null
    }

    const current = new Date()

    if (data?.room?.team_a_date) {
      const closedAt = new Date(
        (data?.room?.team_a_date as unknown as string).replace(/-/g, '/'),
      )
      const diff = fns.differenceInSeconds(closedAt, current)
      A = diff <= 0 ? null : closedAt
    }

    if (data?.room?.team_b_date) {
      const closedAt = new Date(
        (data?.room?.team_b_date as unknown as string).replace(/-/g, '/'),
      )
      const diff = fns.differenceInSeconds(closedAt, current)
      B = diff <= 0 ? null : closedAt
    }

    if (data?.room?.debate_date) {
      const closedAt = new Date(
        (data?.room?.debate_date as unknown as string).replace(/-/g, '/'),
      )
      const diff = fns.differenceInSeconds(closedAt, current)
      room = diff <= 0 ? null : closedAt
    }

    if (data?.room?.vote_date) {
      const closedAt = new Date(
        (data?.room?.vote_date as unknown as string).replace(/-/g, '/'),
      )

      const diff = fns.differenceInSeconds(closedAt, current)

      vote = diff <= 0 ? null : closedAt
    }
    return { A, B, room, vote }
  }, [
    data?.room?.team_a_date,
    data?.room?.team_b_date,
    data?.room?.debate_date,
    data?.room?.vote_date,
  ]) as EndDateType

  const teamCount: TeamCountType = useMemo(() => {
    return membersRoomInfo.reduce(
      (prev, current) => {
        if (current.team === 'A') {
          prev.A += 1
        }

        if (current.team === 'B') {
          prev.B += 1
        }

        return prev
      },
      { A: 0, B: 0 },
    )
  }, [membersRoomInfo])

  const isDebating = useMemo(() => {
    return data?.room.debate_status !== 6 && data?.room.debate_status !== 8
  }, [data?.room.debate_status])

  const focusUserRoomInfo = useMemo(() => {
    const focusMember = membersRoomInfo.find(
      (info) => info.user_id === focusUserId,
    )

    if (!focusMember) return

    let currentTeam

    if (focusMember.table_num <= maxCount / 2) currentTeam = 'A' as TeamType
    if (focusMember.table_num > maxCount / 2) currentTeam = 'B' as TeamType

    return {
      ...focusMember,
      currentTeam,
    }
  }, [focusUserId, membersRoomInfo])

  const value = useMemo(() => {
    return {
      membersRoomInfo,
      tutorRoomInfo,
      focusUserRoomInfo,
      role,
      maxCount: data?.room.room_qty ?? 0,
      exchangedSeat,
      myRoomInfo,
      timer,
      teamCount,
      endDate,
      isRequestTeamDebating,
      isDebating,
      audiences: audiencesInfo,
      voteCount,
    }
  }, [
    membersRoomInfo,
    tutorRoomInfo,
    focusUserRoomInfo,
    role,
    data?.room.room_qty,
    exchangedSeat,
    myRoomInfo,
    timer,
    teamCount,
    endDate,
    isRequestTeamDebating,
    isDebating,
    audiencesInfo,
    voteCount,
  ])

  useEffect(() => {
    getMyStream()
  }, [])

  useEffect(() => {
    if (!localStream) return

    setTimeout(() => {
      initialize()
    }, 1000)
  }, [localStream])

  useEffect(() => {
    if (role !== 'audience') return

    if (!endDate?.vote) {
      return setVoteModal(false)
    }

    setVoteModal(true)
  }, [endDate?.vote, role])

  useEffect(() => {
    if (!localStream) return
    return () => {
      exitMe()
    }
  }, [localStream])

  useEffect(() => {
    if (!localStream) return

    const echo = new Echo({
      host: 'https://api.debateon.fifteenh.io:6003',
      broadcaster: 'socket.io',
      client: io,
    })

    echo
      .channel(`laravel_database_chat${roomId}`)
      .listen('RtcHandler', async (e: any) => {
        if (e.data.type === 'tutor') {
          if (role === 'tutor') return
          return setTutorRoomInfo((prev) => {
            if (prev) return prev
            return e.data
          })
        }

        if (e.data.type === 'audience') {
          return setAudiencesInfo((prev) => prev.concat(e.data))
        }

        if (e.data.type === 'modify' && e.data.kind === 'new') {
          if (user.id === e.data.user_id) return
          return setMembersRoomInfo((prev) => prev.concat(e.data))
        }

        if (e.data.type === 'modify' && e.data.kind === 'seat') {
          return setMembersRoomInfo((prev) => {
            return prev?.map((info) => {
              if (info.user_id !== e.data.user_id) return info
              info.table_num = e.data.table_num
              return info
            })
          })
        }

        if (e.data.type === 'modify' && e.data.kind === 'exit') {
          if (e.data.user_id === user.id) {
            history.push('/')
            return
          }

          disconnectPeerConnection(e.data.user_id)
        }

        if (e.data.type === 'modify' && e.data.kind === 'exchange') {
          setMembersRoomInfo((prev) => {
            return prev.map((info) => {
              if (info.user_id === e.data.user_id) {
                return {
                  ...info,
                  table_num: e.data.table_num,
                }
              }

              if (info.table_num === e.data.table_num) {
                return {
                  ...info,
                  table_num: e.data.old_table_num,
                }
              }

              return info
            })
          })
        }

        if (e.data.type === 'modify') {
          return setMembersRoomInfo((prev) => {
            return prev.map((info) => {
              if (info.user_id === e.data.user_id) {
                return {
                  ...info,
                  is_mike: e.data.is_mike,
                  is_camera: e.data.is_camera,
                  is_tutor_camera: e.data.is_tutor_camera,
                  is_tutor_mike: e.data.is_tutor_mike,
                }
              }

              return info
            })
          })
        }

        if (e.data.type === 'modifyTutor') {
          return setTutorRoomInfo((prev) => {
            if (prev) {
              return {
                ...prev,
                is_camera: e.data.is_camera,
                is_mike: e.data.is_mike,
              }
            }
            return prev
          })
        }

        if (e.data.type === 'exitRoom') {
          exitRoom(role)
        }

        if (e.data.type === 'teamRoom') {
          modifyTeamRoom({
            closedAt: e.data.closed_at,
            team: e.data.team,
          })
          setIsRequestTeamDebating((prev) => ({
            ...prev,
            [e.data.team]: false,
          }))
        }

        if (e.data.type === 'enterTeamRoom') {
          setMembersRoomInfo((prev) => {
            return prev.map((member) => {
              if (member.user_id === e.data.user_id) {
                return {
                  ...member,
                  team: e.data.team,
                }
              }

              return member
            })
          })
        }

        if (e.data.type === 'exitTeamRoom') {
          setMembersRoomInfo((prev) => {
            return prev.map((member) => {
              if (member.user_id === e.data.user_id) {
                return {
                  ...member,
                  team: undefined,
                }
              }
              return member
            })
          })
        }

        if (e.data.type === 'enterTeamRoomTutor') {
          setTutorRoomInfo((prev) => {
            if (!prev) return
            return {
              ...prev,
              team: e.data.team,
            }
          })
        }

        if (e.data.type === 'exitTeamRoomTutor') {
          setTutorRoomInfo((prev) => {
            if (!prev) return
            return {
              ...prev,
              team: undefined,
            }
          })
        }

        if (e.data.type === 'closeTeamRoom') {
          closeTeamRoom(e.data.team)
        }

        if (e.data.type === 'modifyDebateStatus') {
          modifyDebateStatus(e.data.debate_status)
          setFocusUserId(undefined)
        }

        if (e.data.type === 'requestTeamDebating') {
          setIsRequestTeamDebating((prev) => ({ ...prev, [e.data.team]: true }))
          setTimeout(() => {
            setIsRequestTeamDebating((prev) => ({
              ...prev,
              [e.data.team]: false,
            }))
          }, 1000 * 60 * 30)
        }

        if (e.data.type === 'settingTimer') {
          setDebatingTimer(e.data.date)
        }

        if (e.data.type === 'clearTimer') {
          clearDebatingTimer()
        }

        if (e.data.type === 'setVoteTimer') {
          setVoteTimer(e.data.date)
        }

        if (e.data.type === 'clearVoteTimer') {
          clearVoteTimer()
          setVoteCount(e.data.vote)
        }

        if (e.data.type === 'focusUser') {
          focusUser(e.data.userId)
        }

        if (e.data.type === 'blurFocusUser') {
          blurFocusUser()
        }

        if (e.data.type === 'uploadReference') {
          uploadReferenceHandler()
        }

        if (e.data.type === 'deleteReference') {
          deleteReferenceHandler()
        }

        if (e.data.type === 'exitTutor') {
          exitTutorHandler()
        }

        // 화상연결
        if (e.data.type === 'joinRoom') {
          if (Number(e.data.user_id) !== Number(user.id)) return
          await api.post('/rtc/checkRoom', {
            class_id: roomId,
            user_id: user.id,
            kind: role,
          })
        }

        if (e.data.type === 'checkRoom') {
          if (Number(e.data.user_id) === Number(user.id)) return
          if (e.data.kind === 'audience' && role === 'audience') return

          createOffer({
            userId: e.data.user_id,
            kind: e.data.kind,
          })
        }

        if (e.data.type === 'offer') {
          if (e.data.receiver_id !== user.id) return

          e.data.offer.sdp += '\r\n'

          createAnswer({
            offer: e.data.offer,
            userId: e.data.user_id,
            kind: e.data.kind,
          })
        }

        if (e.data.type === 'answer') {
          if (e.data.user_id === user.id) return
          if (e.data.receiver_id !== user.id) return

          e.data.answer.sdp += '\r\n'

          receiveAnswer({
            userId: e.data.user_id,
            kind: e.data.kind,
            answer: e.data.answer,
          })
        }

        if (e.data.type === 'candidate') {
          if (e.data.user_id === user.id) return
          if (e.data.receiver_id !== user.id) return

          if (e.data.kind === 'tutor') {
            await tutorPcRef?.current?.addIceCandidate(
              new RTCIceCandidate(e.data.candidate),
            )

            return
          }

          if (e.data.kind === 'student' || e.data.kind === 'audience') {
            await memberPcsRef.current[e.data.user_id].addIceCandidate(
              new RTCIceCandidate(e.data.candidate),
            )
          }
        }
      })

    return () => {
      echo.leaveChannel(`laravel_database_chat${roomId}`)
      echo.disconnect()
    }
  }, [localStream])

  return (
    <RoomInfoActionContext.Provider
      value={{
        changeSeat,
        exitUser,
        exchangeSeatBefore,
        exchangeSeatAfter,
        resetExchangeSeat,
        modifyRoomInfo,
        modifyTutorInfo,
        enterTeamRoom,
        exitTeamRoom,
        enterTeamRoomTutor,
        exitTeamRoomTutor,
        requestTeamDebating,
        excuteVote,
        focusUserTrigger,
        blurFocusUserTrigger,
      }}
    >
      <RoomInfoValueContext.Provider value={value}>
        {children}
      </RoomInfoValueContext.Provider>
    </RoomInfoActionContext.Provider>
  )
}

export default RoomInfoProvider
