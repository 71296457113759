import { Editor } from '@toast-ui/react-editor'
import React, { useRef, useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import '@toast-ui/editor/dist/toastui-editor.css'
import { ReactComponent as MenuHoverCloseLogo } from '../../assets/icons/header_menu_close_hover_icon.svg'
import { getJSDocReturnTag } from 'typescript'
import type { DetailInfoType } from 'atoms/join'

interface Props {
  handleClose?: () => void
  close: boolean
  handleChange: (value: any) => void
  handleTitleChange: (value: any) => void
  defaultValue?: DetailInfoType
  menu?: string
  blur?: boolean
}
function EditorContent({
  handleClose,
  close,
  handleChange,
  handleTitleChange,
  defaultValue,
  menu,
  blur,
}: Props) {
  const inputRef = useRef<HTMLInputElement>(null)
  const [title, setTitle] = useState('')
  const editorRef = useRef<any>(null)
  const toolbarItems = [
    ['heading', 'bold', 'italic', 'strike'],
    ['hr'],
    ['ul', 'ol', 'task'],
    ['table', 'link'],
    ['image'],
    ['code'],
    ['scrollSync'],
  ]
  useEffect(() => {
    if (defaultValue?.contents) {
      editorRef.current.getInstance().setHTML(defaultValue.contents)
    }
  }, [defaultValue?.contents])

  useEffect(() => {
    if (blur && inputRef.current !== null) {
      handleTitleChange(title)
    }
  }, [blur])

  useEffect(() => {
    if (defaultValue?.contents === '') {
      editorRef.current.getInstance().setHTML()
    }
  }, [menu])

  const onChange = () => {
    if (!editorRef.current) getJSDocReturnTag
    const data = editorRef.current.getInstance().getHTML()
    handleChange(data)
    if (data.includes('<p><br></p>')) {
      handleChange(data.replace('<p><br></p>', ''))
    } else {
      handleChange(data)
    }
  }

  const intiialize = useCallback(() => {
    if (defaultValue?.title) {
      setTitle(defaultValue?.title)
    }

    if (defaultValue?.contents) {
      editorRef.current.getInstance().setHTML(defaultValue.contents)
    }
  }, [defaultValue?.contents, defaultValue?.title])

  useEffect(() => {
    intiialize()
  }, [])

  return (
    <Wrapper>
      {/* 다회차 및 여러항목인 경우에만 제목 필드 노출 */}
      {menu === 'multi' && (
        <div className="input_box">
          <input
            ref={inputRef}
            type="text"
            placeholder="제목을 입력해주세요"
            value={title}
            onChange={(e: any) => {
              setTitle(e.target.value)
              handleTitleChange(title)
            }}
            onBlur={() => handleTitleChange(title)}
            onKeyDown={() => handleTitleChange(title)}
          />
          {close && <MenuHoverCloseLogo onClick={handleClose} />}
        </div>
      )}
      <Editor
        ref={editorRef}
        // initialEditType="wysiwyg"
        initialEditType="markdown"
        hideModeSwitch={true}
        height="400px"
        theme={''}
        usageStatistics={false}
        toolbarItems={toolbarItems}
        useCommandShortcut={true}
        onChange={onChange}
      />
    </Wrapper>
  )
}

export default EditorContent
const Wrapper = styled.div`
  margin-bottom: 40px;
  & div.input_box {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    & > svg {
      margin-left: 10px;
    }
  }
  & input {
    width: 100%;
    outline: none;
    background: #ffffff;
    border: 1px solid #e1e1e2;
    border-radius: 8px;
    padding: 11px 14px;
    ::placeholder {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: #b5b5b9;
    }
  }
`
