import Footer from 'components/common/Footer'
import Header from 'components/common/header/Header'
import MobileTitleHeader from 'components/common/header/MobileTitleHeader'
import SearchComponent from 'components/common/search/SearchComponent'
import useUser from 'lib/hooks/account/useUser'
import useSearch from 'lib/hooks/useSearch'
import media from 'lib/styles/media'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

function SearchPage() {
  const { user } = useUser()
  const params = useParams<{ search: string }>()
  const searchData = params.search
  const [checked, setChecked] = useState(false)
  const [available, setAvailable] = useState('0')
  const studentUser = user && user.type === 'user'
  const {
    data: originData,
    mutate,
    target,
  } = useSearch({ searchData, available, studentUser })

  const handleChange = (checked: boolean) => {
    if (!checked) {
      setChecked(false)
      setAvailable('0')
    } else {
      setChecked(true)
      setAvailable('1')
    }
  }

  if (!originData) return null
  if (!originData.data) return null
  if (!originData.data.classes) return null

  return (
    <Wrapper>
      <Header />
      <MobileTitleHeader type="search" />
      <Contents>
        <SearchComponent
          data={originData.data.classes.data}
          target={target}
          handleChange={handleChange}
          checked={checked}
          available={available}
        />
      </Contents>
      <Footer />
    </Wrapper>
  )
}

export default SearchPage
const Wrapper = styled.div``
const Contents = styled.div`
  margin-top: 140px;
  ${media.medium} {
    margin-top: 0;
  }
`
