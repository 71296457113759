import React from 'react'
import styled, { css } from 'styled-components'

interface RoomButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children?: React.ReactNode
  size?: 'sm' | 'md' | 'lg' | 'xl'
  background?: string
}

function RoomButton({
  children,
  size = 'md',
  background,
  ...rest
}: RoomButtonProps) {
  return (
    <Wrapper background={background} size={size} {...rest}>
      {children}
    </Wrapper>
  )
}

const Wrapper = styled.button<{
  background?: string
  size: 'sm' | 'md' | 'lg' | 'xl'
  disabled?: boolean
}>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;

  background: ${(props) => (props.background ? props.background : '#39393c')};
  /* gray 3 */
  border: 1px solid #7c7c80;
  border-radius: 4px;
  color: #f9f9fb;
  cursor: pointer;
  outline: none;

  ${(props) =>
    props.size === 'md' &&
    css`
      height: 42px;
    `}

  ${(props) =>
    props.size === 'lg' &&
    css`
      height: 50px;
      font-weight: 700;
      font-size: 16px;
    `}

  &:hover {
    opacity: 0.9;
  }

  &:active {
    opacity: 0.8;
  }

  &:disabled {
    background: rgba(181, 181, 185, 0.7);
    color: rgba(59, 59, 64, 0.8);
    cursor: not-allowed;

    &:hover {
      opacity: 1;
    }

    &:active {
      opacity: 1;
    }
  }
`

export default RoomButton
