import PageLoadingComponent from 'components/common/PageLoadingComponent'
import RoomInfoProvider from 'components/room/provider/RoomInfoProvider'
import RoomSocketProvider from 'components/room/provider/RoomSocketProvider'
import RoomCore from 'containers/base/RoomCore'
import useRoom from 'lib/hooks/room/useRoom'
import { type UserInfo } from 'lib/types/account'
import { type RoomRoleType } from 'lib/types/room'
import React, { useEffect, useMemo } from 'react'
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams,
} from 'react-router-dom'
import AudienceRoomPage from './AudienceRoomPage'
import RoomChatList from './mobile/RoomChatList'
import RoomParticipantList from './mobile/RoomParticipantList'
import RoomReference from './mobile/RoomReference'
import StudentRoomPage from './StudentRoomPage'
import TeamRoomPage from './TeamRoomPage'
import TutorRoomPage from './TutorRoomPage'

interface RoomPageProps {
  user?: UserInfo
}

function RoomPage({ user }: RoomPageProps) {
  const { roomId }: { roomId: string } = useParams()
  const { data, isLoading } = useRoom(roomId)

  const role: RoomRoleType = useMemo(() => {
    if (user?.type === 'tutor' && data?.room.tutor_id === user.id) {
      return 'tutor'
    }
    if (user?.type === 'user' && data?.room.is_my_join_class === 'Y') {
      return 'student'
    }
    return 'audience'
  }, [data, user])

  useEffect(() => {
    document.documentElement.style.overflow = 'hidden'

    const channelTalkElement = document.getElementById('ch-plugin')
    channelTalkElement?.setAttribute('id', 'none')

    return () => {
      document.documentElement.style.overflow = 'auto'
      channelTalkElement?.setAttribute('id', 'ch-plugin')
    }
  }, [])

  if (isLoading) return <PageLoadingComponent />
  if (role === 'audience' && data?.room.available_attend === 0)
    return <Redirect to="/" />
  // 에러페이지 노출 or 종료된 강의라면 해당 UI 노출
  if (!data || !user) return <div></div>

  return (
    <RoomSocketProvider roomId={roomId}>
      <RoomInfoProvider role={role} user={user} roomId={roomId}>
        <Switch>
          <Route
            path="/room/:roomId/teamroom/:team"
            component={TeamRoomPage}
            exact
          />
          <Route
            path="/room/:roomId/participants"
            component={RoomParticipantList}
            exact
          />
          <Route path="/room/:roomId/chat" component={RoomChatList} exact />
          <Route
            path="/room/:roomId/teamchat"
            component={RoomParticipantList}
            exact
          />
          <Route
            path="/room/:roomId/reference"
            component={RoomReference}
            exact
          />

          <Route path="">
            {role === 'student' && <StudentRoomPage />}
            {role === 'tutor' && <TutorRoomPage />}
            {role === 'audience' && <AudienceRoomPage />}
          </Route>
        </Switch>
        <RoomCore />
      </RoomInfoProvider>
    </RoomSocketProvider>
  )
}

export default RoomPage
