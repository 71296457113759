import api from './api'

export const fetcher = async (url: string) =>
  await api(url).then((res) => res.data)

export const swrConfig = {
  fetcher,
  revalidateOnFocus: false,
  errorRetryCount: 0,
}

export const pcConfig = {
  iceServers: [{ urls: 'stun:stun.l.google.com:19302' }],
}
