import React, { useState } from 'react'
import styled from 'styled-components'
import { ReactComponent as HomeIcon } from 'assets/icons/home_icon.svg'
import { ReactComponent as CopyIcon } from 'assets/icons/copy_icon.svg'
import { type TutorDetailType } from 'lib/api/tutor'
import { handleCopy } from 'lib/utils/CommonFuncttion'
import CheckPopup from 'components/common/CheckPopup'
import media from 'lib/styles/media'

interface TutorIntroProps {
  info: Omit<TutorDetailType, 'detail_introduces'>
}

function TutorIntro({ info }: TutorIntroProps) {
  const [popup, setPopup] = useState(false)
  const routeHomeLink = () => {
    window.open(info.career_url)
  }

  return (
    <Wrapper>
      <div className="header">
        <h3 className="title">튜터 소개</h3>
        <div className="buttons">
          {info.career_url && (
            <div className="button" onClick={routeHomeLink}>
              <HomeIcon />
              홈페이지
            </div>
          )}
          <div
            className="button"
            onClick={() => {
              handleCopy(window.location.href)
              setPopup(true)
            }}
          >
            <CopyIcon />
            링크복사
          </div>
        </div>
      </div>
      <div className="content">
        <div className="profile">
          <div className="profile-image">
            <img src={info.profile_url} alt="profile-image" />
          </div>
          <div className="profile-textbox">
            <div className="profile-nickname">{info.nickname.slice(0, 10)}</div>
            <div className="profile-name">{info.name.slice(0, 10)}</div>
          </div>
        </div>
        <div className="intro">
          <div className="intro-title">{info.nickname} 님을 소개합니다.</div>
          <div className="intro-content">{info.introduce}</div>
        </div>
      </div>
      {popup && (
        <CheckPopup
          onClick={() => setPopup(false)}
          title="링크 복사"
          content="튜터의 링크가 복사되었습니다."
        />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding-top: 40px;

  & > .header {
    display: flex;

    justify-content: space-between;

    & > .title {
      font-weight: 700;
      font-size: 22px;
      line-height: 34px;
      color: #0d0d0e;
    }

    & > .buttons {
      display: flex;
      gap: 13px;

      & > .button {
        border: 1px solid #e1e1e2;
        border-radius: 4px;
        padding: 10px 20px;

        display: flex;
        gap: 8px;

        cursor: pointer;

        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #3b3b40;

        align-items: center;
      }
    }
  }

  & > .content {
    margin-top: 20px;
    padding: 20px;
    border: 1px solid #e1e1e2;
    border-radius: 14px;
    box-sizing: border-box;

    display: flex;
    /* justify-content: center; */
    gap: 29px;
    /* align-items: center; */

    & > .profile {
      width: 148px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      & > .profile-image {
        width: 78px;
        height: 78px;
        border-radius: 50%;

        & > img {
          width: 100%;
          height: 100%;

          border-radius: inherit;
        }
      }

      & > .profile-textbox {
        text-align: center;

        & > .profile-nickname {
          margin-top: 8px;
          font-weight: 500;
          font-size: 16px;
          line-height: 26px;
          color: #0d0d0e;
        }

        & > .profile-name {
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          color: #b5b5b9;
        }
      }
    }

    & > .intro {
      flex: 1;
      padding: 20px 24px;
      background: #f9f9fb;
      border-radius: 20px 20px 20px 0px;

      & > .intro-title {
        font-weight: 700;
        font-size: 18px;
        line-height: 28px;
        color: #0d0d0e;
      }

      & > .intro-content {
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        color: #707070;
      }
    }
  }

  ${media.medium} {
    padding-top: 20px;

    & > .header {
      display: block;

      & > .title {
        font-size: 18px;
        line-height: 1.56;
        margin-bottom: 18px;
      }

      & > .buttons {
        width: 100%;
        gap: 11px;

        & > .button {
          width: 100%;
          padding: 8px 10px;
          justify-content: center;
        }
      }
    }

    & > .content {
      margin-top: 10px;
      display: block;
      gap: 0;

      & > .profile {
        width: 100%;
        flex-direction: row;
        justify-content: flex-start;

        & > .profile-image {
          width: 54px;
          height: 54px;
          margin-right: 10px;
        }

        & > .profile-textbox {
          text-align: left;

          & > .profile-nickname {
            margin-top: 0;
          }
        }
      }

      & > .intro {
        margin-top: 20px;
        padding: 20px;
        border-radius: 20px;

        & > .intro-title {
          font-size: 14px;
          line-height: 24px;
          margin-bottom: 6px;
        }

        & > .intro-content {
          font-size: 13px;
          line-height: 24px;
        }
      }
    }
  }
`

export default TutorIntro
