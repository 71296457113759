import media from 'lib/styles/media'
import React from 'react'
import styled from 'styled-components'

interface Props {
  title: string
  handlePersonClick: (id: number) => void
  data: number | undefined
  room?: number
  handlePopup: () => void
}
function MaxPerson({
  title,
  handlePersonClick,
  data,
  room,
  handlePopup,
}: Props) {
  const person = [
    { id: 2, title: '2명(1:1)' },
    { id: 4, title: '4명(2:2)' },
    { id: 6, title: '6명(3:3)' },
    { id: 8, title: '8명(4:4)' },
    { id: 10, title: '10명(5:5)' },
  ]

  return (
    <Wrapper>
      <Title>{title}</Title>
      <Content>
        {person.map((item, i) => (
          <div
            className={data === item.id ? 'active' : ''}
            key={`tutor_register_class_add_${i}`}
            onClick={() => {
              if (room && room > item.id) {
                handlePopup()
              } else {
                handlePersonClick(item.id)
              }
            }}
          >
            <p>{item.title}</p>
          </div>
        ))}
      </Content>
    </Wrapper>
  )
}

export default MaxPerson
const Wrapper = styled.div`
  margin-top: 40px;
`
const Title = styled.p`
  font-weight: 500;
  font-size: 17px;
  line-height: 26px;
  color: #0d0d0e;
  ${media.medium} {
    font-size: 16px;
  }
`
const Content = styled.div`
  margin-top: 10px;
  width: 100%;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  ${media.medium} {
    flex-wrap: wrap;
    & div:nth-of-type(2n) {
      margin-right: 0;
    }
    & div:nth-of-type(5) {
      margin-bottom: 0;
    }
  }
  & div {
    width: calc(100% / 5 - 5px);
    background: #ffffff;
    border: 1px solid #e1e1e2;
    border-radius: 4px;
    padding: 13px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    &.active {
      border: 1px solid #7c7c80;
    }
    ${media.medium} {
      min-width: 155px;
      width: calc(50% - 5px);
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }
  & p {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #0d0d0e;
    cursor: pointer;
  }
`
