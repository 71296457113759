import Footer from 'components/common/Footer'
import MobileTitleHeader from 'components/common/header/MobileTitleHeader'
import TutorHeader from 'components/common/header/TutorHeader'
import ReferenceRegisterComponent from 'components/common/reference/ReferenceRegisterComponent'
import media from 'lib/styles/media'
import React from 'react'
import styled from 'styled-components'

const ReferenceRegisterPage = () => {
  return (
    <Wrapper>
      <TutorHeader />
      <MobileTitleHeader type="arrow" title="게시글 등록" />
      <Content>
        <ReferenceRegisterComponent />
      </Content>
      <Footer />
    </Wrapper>
  )
}

export default ReferenceRegisterPage

const Wrapper = styled.div``
const Content = styled.div`
  margin: 100px auto 120px;
  ${media.medium} {
    width: 100%;
    margin: 0 auto 80px;
  }
`
