import media from 'lib/styles/media'
import {
  PriceNum,
  dateBasicHyphenFormet,
  paymentMethod,
} from 'lib/utils/CommonFuncttion'
import React from 'react'
import styled from 'styled-components'

interface Props {
  cancelDate?: string
  content: string
  status: number
  price?: number | string
  payment?: string
  pageType: string
}
function PaymentCancelComponent({
  cancelDate,
  content,
  status,
  price,
  payment,
  pageType,
}: Props) {
  return (
    <Wrapper>
      <p className="info_title">취소신청 정보</p>

      {status === 9 && (
        <div className="content_box">
          <div className="content">
            <p className="title">취소신청 일시</p>
            <p>{cancelDate ? dateBasicHyphenFormet(cancelDate) : '-'}</p>
          </div>
          <div className="content">
            <p className="title">취소 사유</p>
            <p>{content ? content : '-'}</p>
          </div>
        </div>
      )}
      {status === 10 && (
        <div className="content_box">
          <div className="content">
            <p className="title">
              {pageType === 'tutor' ? '취소 일시' : '취소신청 일시'}
            </p>
            <p>{cancelDate ? dateBasicHyphenFormet(cancelDate) : '-'}</p>
          </div>
          <div className="content">
            <p className="title">취소 사유</p>
            <p>{content ? content : '-'}</p>
          </div>
          <div className="content">
            <p className="title">취소 금액</p>
            <p>{PriceNum(Number(price))}원</p>
          </div>
        </div>
      )}
    </Wrapper>
  )
}

export default PaymentCancelComponent
const Wrapper = styled.div`
  & p.info_title {
    font-weight: 500;
    font-size: 17px;
    line-height: 26px;
    color: #0d0d0e;
    padding-bottom: 10px;
    border-bottom: 1px solid #0d0d0e;
    ${media.medium} {
      font-size: 16px;
    }
  }
  & div.content_box {
    margin-top: 20px;
    ${media.medium} {
      margin-top: 14px;
    }
  }
  & div.content {
    display: flex;
    & > p {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: #3b3b40;
      margin-bottom: 12px;
      ${media.medium} {
        font-size: 13px;
        line-height: 22px;
        margin-bottom: 10px;
      }
      &.title {
        min-width: 160px;
        ${media.medium} {
          min-width: 110px;
        }
      }
    }
  }
`
