import { AppRootWrapper } from 'components/layout/CommonStyle'
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import SmsInput from '../SmsInput'
import Button from '../Button'
import api from 'lib/api'
import CheckPopup from '../CheckPopup'
import {
  Error0001,
  Error0002,
  Error0003,
  Error0004,
} from 'lib/utils/CommonFuncttion'
import { useHistory } from 'react-router-dom'
import media from 'lib/styles/media'
import useUser from 'lib/hooks/account/useUser'

interface Props {
  data: number | string
}
function EditInfoPhoneComponent({ data }: Props) {
  const history = useHistory()
  const [formdata, setFormdata] = useState({
    phone: data,
    code: '',
  })
  const [btnText, setBtnText] = useState('수정')
  const [isSendCode, setIsSendCode] = useState(false)
  const [isCodeCheck, setIsCodeCheck] = useState(false)
  const [smsDisabled, setSmsDisabled] = useState(true)
  const [popup, setPopup] = useState(false)
  const [title, setTitle] = useState('')
  const [contents, setContents] = useState('')
  const { user } = useUser()
  const typeUser = user && user.type

  useEffect(() => {
    if (popup) {
      document.documentElement.style.overflow = 'hidden'
    } else {
      document.documentElement.style.overflow = 'auto'
    }

    return () => {
      document.documentElement.style.overflow = 'auto'
    }
  }, [popup])

  const handleCodeError = (code?: string) => {
    if (code) {
      if (code === 'DB0001') {
        setPopup(true)
        setTitle('이미 가입된 휴대폰 번호')
        setContents(Error0001)
      } else if (code === 'DB0002') {
        setPopup(true)
        setTitle('탈퇴 회원')
        setContents(Error0002)
      } else if (code === 'DB0002') {
        setPopup(true)
        setTitle('강제 탈퇴 회원')
        setContents(Error0003)
      } else {
        setPopup(true)
        setTitle('인증번호 발송 횟수 초과')
        setContents(Error0004)
      }
    } else {
      setIsCodeCheck(true)
    }
  }

  const handlePhoneChange = (type: string) => (e: any) => {
    setFormdata({
      ...formdata,
      [type]: e.target.value.replace(/[^0-9]/g, ''),
    })
  }

  const handleEditPhone = () => {
    setBtnText('인증')
    setSmsDisabled(false)
    setFormdata({
      phone: '',
      code: '',
    })
  }

  const handleCheckInfo = async () => {
    if (formdata.phone && String(formdata.phone).length >= 10) {
      const formData = new FormData()
      formData.append('type', typeUser === 'tutor' ? 'tutor' : 'user')
      formData.append('phone', String(formdata.phone))
      const {
        data: { success, alert, code },
      } = await api.post('/sms/certification', formData)
      if (success) {
        setBtnText('재발송')
        setIsSendCode(true)
      } else {
        handleCodeError(code)
      }
    }
  }
  const handleSubmit = async () => {
    const fd = new FormData()
    fd.append('type', typeUser === 'tutor' ? 'tutor' : 'user')
    fd.append('phone', String(formdata?.phone))
    fd.append('sms_verify_code', String(formdata?.code))

    const {
      data: { success, alert, code },
    } = await api.post('/mypage/phone/modify', fd)

    if (success) {
      setPopup(true)
      setTitle('휴대폰 번호 변경 완료')
      setContents('휴대폰 번호 변경이 완료되었습니다.')
    } else {
      handleCodeError(code)
    }
  }

  const handleCheck = () => {
    setPopup(false)
    if (title === '휴대폰 번호 변경 완료') {
      window.location.reload()
    }
  }
  return (
    <Wrapper>
      <Content>
        <div className="content_box">
          <SmsInput
            title="휴대폰 번호"
            placeholder="‘-’ 제외 휴대폰 번호 입력"
            phone={formdata?.phone}
            onPhoneChange={handlePhoneChange('phone')}
            btnText={btnText}
            handleEditSendSms={handleEditPhone}
            handleSendSms={handleCheckInfo}
            codeInput={isSendCode}
            code={formdata?.code}
            onCodeChange={handlePhoneChange('code')}
            isCodeCheck={isCodeCheck}
            disabled={smsDisabled}
            type="edit"
          />
        </div>
        <div className="button_box">
          <Button
            title="저장"
            disabled={!formdata.code}
            onClick={handleSubmit}
          />
        </div>
      </Content>
      {popup && (
        <CheckPopup onClick={handleCheck} title={title} content={contents} />
      )}
    </Wrapper>
  )
}

export default EditInfoPhoneComponent
const Wrapper = styled.div`
  width: 100%;
`

const Content = styled.div`
  & div.content_box {
    width: 380px;
    margin: 0 auto;
    ${media.medium} {
      width: 100%;
      margin: 30px auto;
    }
  }
  & div.button_box {
    width: 380px;
    margin: 40px auto 0;

    ${media.medium} {
      width: 100%;
    }
  }
`
