import { RoomContainerWrapper } from 'components/layout/CommonStyle'
import DebateMembers from 'components/room/content/DebateMembers'
import FocusMember from 'components/room/common/FocusMember'
import RoomTitle from 'components/room/common/RoomTitle'
import media from 'lib/styles/media'
import React from 'react'
import styled from 'styled-components'
import { useRoomInfoValue } from 'components/room/provider/RoomInfoProvider'
import { type RoomSeatInfoType } from 'lib/types/room'

function AudienceRoomContainer() {
  const { focusUserRoomInfo, tutorRoomInfo } = useRoomInfoValue()
  return (
    <Wrapper>
      <RoomTitle />
      <FocusMember
        info={focusUserRoomInfo ?? (tutorRoomInfo as RoomSeatInfoType)}
        isCamera={
          focusUserRoomInfo
            ? focusUserRoomInfo?.is_camera === 1
            : tutorRoomInfo?.is_camera === 1
        }
        isMike={focusUserRoomInfo ? false : tutorRoomInfo?.is_mike === 1}
      />
      <DebateMembers />
    </Wrapper>
  )
}

const Wrapper = styled(RoomContainerWrapper)`
  position: relative;

  ${media.pc} {
    padding: 0 30px;
  }
`

export default AudienceRoomContainer
