import Button from 'components/common/Button'
import ClassInfo from 'components/common/class/ClassInfo'
import MyClassReviewContent from 'components/common/myclass/MyClassReviewContent'
import { AppRootWrapper } from 'components/layout/CommonStyle'
import api from 'lib/api'
import media from 'lib/styles/media'
import { type ClassDetailType } from 'lib/types/class'
import { classDateFormat, differenceTime } from 'lib/utils'
import React, { useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { MyClassContentWrapper } from '../../components/common/myclass/MyClassReviewComponent'
import MyClassScoreContent from '../../components/common/myclass/MyClassScoreContent'
import MyClassTitle from '../../components/common/myclass/MyClassTitle'

interface MyClassEndContainerProps {
  data: ClassDetailType
  step: string
  classId: string
}

function MyClassEndContainer({
  data,
  step,
  classId,
}: MyClassEndContainerProps) {
  const history = useHistory()

  const [formdata, setFormdata] = useState({
    score: 0,
    contents: '',
  })

  const handleChangeScore = (score: number) => {
    setFormdata({
      ...formdata,
      score,
    })
  }
  const handleChangeContent = (e: any) => {
    if (e.target.value.length > 500) return

    setFormdata({
      ...formdata,
      contents: e.target.value,
    })
  }

  const handleSubmit = async () => {
    const { data } = await api.post('/class/review/register', {
      class_user_id: classId,
      score: formdata.score,
      contents: formdata.contents,
    })

    if (!data.success) {
      window.alert(data.alert)
      return
    }

    history.push('/myclass')
  }

  const handleLater = () => {
    history.push('/myclass')
  }

  const isValid = useMemo(() => {
    return formdata.score && formdata.contents
  }, [formdata])

  const date = classDateFormat(data)

  return (
    <Wrapper>
      <MyClassTitle
        mainTitle="강의 종료"
        subTitle="강의가 종료되었습니다. 강의에 대한 리뷰를 남겨주세요!"
      />
      <MyClassContentWrapper>
        <ClassInfo
          title={data.title}
          image={data.images[0].image_url}
          name={data?.nickname ?? data.name}
          date={date}
        />
        <MyClassScoreContent
          value={formdata.score}
          handleChange={handleChangeScore}
        />
        <MyClassReviewContent
          value={formdata.contents}
          onChange={handleChangeContent}
        />
        <MyClassButtonBox>
          <div className="button-wrap">
            <Button
              title="나중에 입력"
              variant="outlined"
              onClick={handleLater}
            />
          </div>
          <div className="button-wrap">
            <Button title="완료" disabled={!isValid} onClick={handleSubmit} />
          </div>
        </MyClassButtonBox>
      </MyClassContentWrapper>
    </Wrapper>
  )
}

const Wrapper = styled(AppRootWrapper)``

export const MyClassButtonBox = styled.div`
  margin-top: 20px;
  padding-top: 20px;

  display: flex;
  justify-content: center;
  gap: 10px;

  & .button-wrap {
    width: 380px;
  }

  ${media.medium} {
    width: 100%;
    padding: 20px;
    background: #fff;
  }
`

export default MyClassEndContainer
