import React, { useState } from 'react'
import styled, { css } from 'styled-components'

interface Props {
  type: string
  title?: string
  placeholder: string
  value: string | undefined
  onChange: (e: any) => void
  error?: boolean
  errorMessage?: string
  maxLength?: number
  chekId?: boolean
  handleIdCheckClick?: () => void
  success?: boolean
  successMessage?: string
  cancel?: boolean
  handleCancelClick?: () => void
  edit?: boolean
  editTitle?: string
  handleEditClick?: () => void
  chekNickname?: boolean
  handlenickNameCheck?: () => void
  className?: string
}
function Input({
  type,
  title,
  placeholder,
  value,
  onChange,
  error,
  errorMessage,
  maxLength,
  chekId,
  handleIdCheckClick,
  success,
  successMessage,
  cancel,
  handleCancelClick,
  edit,
  editTitle,
  handleEditClick,
  chekNickname,
  handlenickNameCheck,
  className,
}: Props) {
  const [showPw, setShowPw] = useState(false)
  const [focus, setFocus] = useState(false)

  const onFocus = () => {
    setFocus(true)
  }
  const onBlur = () => {
    setFocus(false)
  }
  return (
    <Wrapper className={className}>
      <InputContent className={editTitle === '수정' ? 'disabled' : ''}>
        {title && <p className="input_title">{title}</p>}

        <input
          className={
            focus ? 'focus' : error ? 'error' : success ? 'success' : ''
          }
          type={showPw ? 'text' : type}
          placeholder={placeholder}
          autoComplete="off"
          onFocus={onFocus}
          onBlur={onBlur}
          value={value ?? ''}
          onChange={onChange}
          maxLength={maxLength}
          disabled={editTitle === '수정'}
        />
        {cancel && (
          <div className="cancel_button" onClick={handleCancelClick}>
            <p>취소</p>
          </div>
        )}
        {edit ? (
          <button className="cross_check_content" onClick={handleEditClick}>
            {editTitle}
          </button>
        ) : (
          chekId && (
            <button
              className="cross_check_content"
              onClick={handleIdCheckClick}
              disabled={String(value).length < 8}
            >
              중복 확인
            </button>
          )
        )}
        {chekNickname && (
          <button
            className="cross_check_content"
            onClick={handlenickNameCheck}
            disabled={String(value).length < 2}
          >
            중복 확인
          </button>
        )}
      </InputContent>
      {error && errorMessage && <p className="error_message">{errorMessage}</p>}
      {success && successMessage && (
        <p className="success_message">{successMessage}</p>
      )}
    </Wrapper>
  )
}

export default Input

const Wrapper = styled.div`
  & p.error_message {
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    color: #ff2c2c;
    margin-top: 4px;
  }
  & p.success_message {
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    color: #2c80ff;
    margin-top: 4px;
  }
`
const InputContent = styled.div`
  position: relative;
  &.disabled {
    & p.input_title {
      color: #b5b5b9;
    }
  }
  & div.cancel_button {
    position: absolute;
    right: 80px;
    bottom: 8px;
    & > p {
      font-weight: 400;
      font-size: 13px;
      line-height: 22px;
      text-align: right;
      text-decoration-line: underline;
      color: #7c7c80;
      cursor: pointer;
    }
  }
  & p.input_title {
    font-weight: 700;
    font-size: 13px;
    line-height: 22px;
    color: #0d0d0e;
    margin-bottom: 4px;
  }
  & input {
    width: 100%;
    border: none;
    border-bottom: 1px solid #e1e1e2;
    outline: none;
    padding: 7px 30px 7px 0;

    &::placeholder {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #b5b5b9;
    }

    &.focus {
      border-bottom: 1px solid #3b3b40;
    }
    &.error {
      border-bottom: 1px solid #ff2c2c;
    }
    &.success {
      border-bottom: 1px solid #2c80ff;
    }
    &:disabled {
      background: #fff;
      color: #b5b5b9;
    }
  }
  & input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px #fff inset;
  }

  & button.cross_check_content {
    width: 70px;
    height: 30px;
    background: #ffffff;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    bottom: 4px;
    font-weight: 400;
    font-size: 13px;
    line-height: 22px;
    border: 1px solid #0d0d0e;
    color: #0d0d0e;

    &:disabled {
      border: 1px solid #e1e1e2;
      color: #b5b5b9;
    }
  }
`
