import { customMedia } from 'lib/styles/global-styles'
import media from 'lib/styles/media'
import styled, { css } from 'styled-components'

export const AppRootWrapper = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  /* 모바일 */
  ${media.medium} {
    width: calc(100% - 40px);
  }
`

export const RoomPageWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
`

export const RoomContainerWrapper = styled.div`
  flex: 1;
  height: 100%;

  overflow: hidden;

  display: flex;
  flex-direction: column;
`
export const RoomContentWrapper = styled.div``

export const ModalContentWrapper = styled.div<{ width?: 'sm' | 'md' | 'lg' }>`
  padding: 26px;

  border-radius: 14px;
  background: #fff;

  ${(props) =>
    props.width === 'sm' &&
    css`
      width: 320px;
    `}

  ${(props) =>
    props.width === 'md' &&
    css`
      width: 380px;
    `}

  ${(props) =>
    props.width === 'lg' &&
    css`
      width: 380px;
    `}

  & .header {
    display: flex;
    justify-content: space-between;

    padding-bottom: 10px;

    & .title {
      font-weight: 700;
      font-size: 18px;
      color: #000000;
    }

    & .close-icon {
      cursor: pointer;
    }
  }

  & .description {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #3b3b40;
  }

  & .footer {
    padding-top: 10px;
    display: flex;
    gap: 10px;
  }
`

export const CommonContentWrapper = styled.div`
  margin-top: 100px;
  padding-bottom: 120px;
  ${media.medium} {
    margin-top: 0;
    padding-bottom: 80px;
  }
`

export const CommonRoomProfileWrapper = styled.div`
  position: relative;
  ${media.pc} {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ${media.medium} {
    width: 100px;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    background: #3b3b40;
  }
`
