import React from 'react'
import styled from 'styled-components'
import { ReactComponent as ArrowIcon } from 'assets/icons/left_arrow_color.svg'
import media from 'lib/styles/media'

interface PageNationProps {
  totalPage: number
  page: number
  onChangePage: (page: number) => void
}

function PageNation({ page, onChangePage, totalPage }: PageNationProps) {
  const handleChangePage = (page: number) => () => {
    onChangePage(page)
  }
  return (
    <Wrapper>
      <ArrowIcon
        className={'arrow left' + (page === 1 ? ' disable' : '')}
        onClick={() => {
          if (page > 1) onChangePage(page - 1)
        }}
      />
      <div className="pages">
        {Array.from({ length: totalPage }).map((_, index) => (
          <span
            className={`page ${page === index + 1 && 'selected'}`}
            key={index}
            onClick={handleChangePage(index + 1)}
          >
            {index + 1}
          </span>
        ))}
      </div>
      <ArrowIcon
        className={'arrow right' + (page === totalPage ? ' disable' : '')}
        onClick={() => {
          if (page < totalPage) onChangePage(page + 1)
        }}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-top: 80px;

  display: flex;
  justify-content: center;
  align-items: center;

  & > div {
    display: flex;
    gap: 16px;
    justify-content: center;
    align-items: center;
  }

  & .arrow {
    cursor: pointer;
    padding: 0 13px;
    width: 32px;
    height: 32px;
  }

  & .arrow.left {
    margin-right: 16px;
  }

  & .arrow.right {
    transform: rotate(180deg);
    margin-left: 16px;
  }

  & .page {
    width: 32px;
    height: 32px;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #3b3b40;

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
  }

  & .selected {
    background: #7574d8;
    border-radius: 8px;
    color: #fff;
  }

  & .disable {
    & > path {
      fill: #b5b5b9;
    }

    cursor: default;
  }

  ${media.medium} {
    margin-top: 40px;

    & > div {
      gap: 4px;
    }
  }
`

export default PageNation
