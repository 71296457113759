import api from 'lib/api'
import { getLikeListRequest } from 'lib/api/list'
import React, { useCallback, useState } from 'react'
import useSWR from 'swr'
import useScroll from './useScroll'

interface Props {
  type: string
}
function useLike({ type }: Props) {
  const [loading, setLoding] = useState(false)
  const { data, mutate } = useSWR(
    type === 'like' && `/class/like?page=1`,
    async (url) => {
      setLoding(true)
      const responseData = await api.get(url).then((res: any) => res.data)
      setLoding(false)
      if (!responseData.success) return undefined
      return responseData
    },
  )

  const [fetchMoreLoading, setFetchMoreLoading] = useState(false)

  const fetchMore = useCallback(
    async (page: number) => {
      if (!data || !data.data || fetchMoreLoading || loading) return
      setFetchMoreLoading(true)

      const response = await getLikeListRequest(page)

      if (!response.success || !response.data) return

      mutate(
        {
          ...data,
          data: {
            classes: {
              last_page: response.data?.classes.last_page,
              current_page: response.data?.classes.current_page,
              data: data.data.classes.data.concat(response.data.classes.data),
            },
          },
        },
        false,
      )
      setFetchMoreLoading(false)
    },
    [data, mutate, fetchMoreLoading, loading],
  )
  const { target } = useScroll({
    fetchMore,
    page: data?.data?.classes.current_page,
    lastPage: data?.data?.classes.last_page,
  })
  return {
    data,
    target,
    mutate,
    fetchMoreLoading,
  }
}

export default useLike
