import media from 'lib/styles/media'
import React from 'react'
import styled from 'styled-components'
import { ReactComponent as ScopeIcon } from '../../assets/icons/class_info_scope_icon.svg'
import { ReactComponent as LiveIcon } from '../../assets/icons/class_live_icon.svg'
import { ReactComponent as NoneImage } from '../../assets/icons/none_profile_icon.svg'
import { useHistory } from 'react-router-dom'

interface Props {
  Image: string
  Profile: string
  Title: string
  Nickname: string
  Score: string
  Category: Array<{ id: number; category: string }>
  Id: number
  tutorId: number
}
function IngClassInfoComponent({
  Image,
  Profile,
  Title,
  Nickname,
  Score,
  Category,
  Id,
  tutorId,
}: Props) {
  const history = useHistory()

  return (
    <Wrapper>
      <div
        className="class_info_box"
        onClick={() => history.push('/class/' + Id)}
      >
        <img src={Image} alt="class_image" className="class_image" />
        <div className="live_button">
          <LiveIcon />
          <p>진행중</p>
        </div>
      </div>

      <div className="profille">
        <div
          className="left_profile"
          onClick={() => history.push(`/tutor/detail/${tutorId}`)}
        >
          {Profile ? <img src={Profile} alt="profile" /> : <NoneImage />}
        </div>
        <div
          className="right_profile"
          onClick={() => history.push('/class/' + Id)}
        >
          <p className="class_title">{Title}</p>
          <div className="nick_content">
            <p className="nick_name">{Nickname}</p>
            <ScopeIcon className="scope_icon" />
            <p className="scope">{Score}</p>
          </div>
          <div className="category_content">
            <p className="category">
              {Category.map((item, i) => (
                <span
                  className={
                    Category.length === 1
                      ? 'category_span none'
                      : 'category_span'
                  }
                  key={`main_ing_class_category_list_${i}`}
                >
                  {item.category} <span className="dot" />
                </span>
              ))}
            </p>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}
export default IngClassInfoComponent

const Wrapper = styled.div`
  & .class_info_box {
    position: relative;
  }
  & .class_image {
    width: 410px;
    min-width: 410px;
    height: 230px;
    min-height: 230px;
    border-radius: 14px;
    object-fit: cover;
    ${media.medium} {
      width: 270px;
      min-width: 270px;
      height: 151px;
      min-height: 151px;
      border-radius: 10px;
    }
  }
  & .live_button {
    position: absolute;
    left: 20px;
    bottom: 20px;
    display: flex;
    align-items: center;
    background: #ff2c2c;
    border-radius: 3px;
    padding: 6px;
    width: fit-content;
    ${media.medium} {
      left: 16px;
      bottom: 16px;
      padding: 0 6px;
    }
    & > p {
      font-weight: 700;
      font-size: 12px;
      line-height: 22px;
      color: #ffffff;
      margin-left: 4px;
    }
  }
  & .profille {
    display: flex;
    align-items: flex-start;
    margin-top: 18px;
  }
  & .right_profile {
    cursor: pointer;
  }
  & .left_profile {
    width: 46px;
    min-width: 46px;
    height: 46px;
    min-height: 46px;
    border-radius: 100%;
    overflow: hidden;
    margin-right: 14px;
    ${media.medium} {
      width: 32px;
      min-width: 32px;
      height: 32px;
      min-height: 32px;
      margin-right: 8px;
    }
    & > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    & > svg {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  & .class_title {
    font-weight: 700;
    font-size: 16px;
    line-height: 26px;
    color: #3b3b40;
    overflow: hidden;
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    ${media.medium} {
      font-size: 14px;
      line-height: 24px;
    }
  }
  & .nick_content {
    display: flex;
    align-items: center;
    margin: 6px 0;
    ${media.medium} {
      margin: 3px 0;
    }
  }
  & .nick_name {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #3b3b40;
    ${media.medium} {
      font-size: 13px;
      line-height: 22px;
    }
  }
  & .scope_icon {
    margin: 0 4px 0 10px;
    ${media.medium} {
      margin: 0 4px 0 8px;
    }
  }
  & .scope {
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    color: #7c7c80;
  }
  & .category_content {
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    color: #b5b5b9;
    display: flex;
    align-items: center;
    min-width: 100%;
    width: 100%;
    overflow: hidden;
    word-break: break-word;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  & .category_span {
    width: 100%;
    min-width: fit-content;
    position: relative;
    margin-right: 8px;
    &.none {
      & span.dot {
        display: none;
      }
    }
    & span.dot {
      position: absolute;
      right: -4px;
      top: 50%;
      transform: translateY(-50%);
      width: 2px;
      height: 2px;
      border-radius: 100%;
      background: #b5b5b9;
    }
  }
`
