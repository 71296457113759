import media from 'lib/styles/media'
import React from 'react'
import styled from 'styled-components'

interface PaymentSubTitleProps {
  title: string
}

function PaymentSubTitle({ title }: PaymentSubTitleProps) {
  return <Wrapper>{title}</Wrapper>
}

const Wrapper = styled.h4`
  ${media.pc} {
    font-weight: 500;
    font-size: 17px;
    line-height: 26px;
    padding-bottom: 10px;
    border-bottom: 1px solid #0d0d0e;
  }

  ${media.medium} {
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: #0d0d0e;
    padding-bottom: 10px;
    border-bottom: 1px solid #0d0d0e;
  }
`

export default PaymentSubTitle
