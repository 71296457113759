import media from 'lib/styles/media'
import { type PaymentMethodType } from 'lib/types/common'
import { PAYMENT_METHOD_VARIABLES } from 'lib/utils/variables'
import React from 'react'
import styled from 'styled-components'
import PaymentMethod, { type ChangeMethodType } from './PaymentMethod'
import PaymentSubTitle from './PaymentSubTitle'

interface ClassPaymentMethodProps {
  onChangeMethod: ChangeMethodType
  method?: PaymentMethodType
}

function ClassPaymentMethod({
  onChangeMethod,
  method,
}: ClassPaymentMethodProps) {
  return (
    <Wrapper>
      <PaymentSubTitle title="결제 수단 선택" />
      <div className="content">
        {Object.entries(PAYMENT_METHOD_VARIABLES).map(([value, label]) => (
          <PaymentMethod
            value={value as PaymentMethodType}
            label={label}
            onChangeMethod={onChangeMethod}
            isSelected={method === value}
            key={value}
          />
        ))}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-top: 50px;

  ${media.medium} {
    margin-top: 40px;
  }

  & > .content {
    margin-top: 20px;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 12px;
    grid-row-gap: 16px;

    ${media.medium} {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 10px;
      grid-row-gap: 12px;
    }
  }
`

export default ClassPaymentMethod
