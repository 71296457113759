import React from 'react'
import styled from 'styled-components'
import { ReactComponent as AlarmIcon } from '../../../assets/icons/alarm_icon.svg'
import { ReactComponent as AlarmHoverIcon } from '../../../assets/icons/alarm_hover_icon.svg'
import media from 'lib/styles/media'

interface Props {
  title: string
  date: string
  status: number
}
function AlarmListComponent({ title, date, status }: Props) {
  return (
    <Wrapper className={status === 1 ? '' : 'none'}>
      {status === 1 ? <AlarmIcon /> : <AlarmHoverIcon />}
      <div className="alarm_content">
        <p className="alarm_title">{title}</p>
        <p className="alarm_text">{date}</p>
      </div>
    </Wrapper>
  )
}

export default AlarmListComponent

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 18px 20px;
  background: #fff;
  border-bottom: 1px solid #eeeff1;
  ${media.medium} {
    padding: 14px 20px;
  }
  & > svg {
    width: 40px;
    height: 40px;
    min-width: 40px;
    ${media.medium} {
      width: 37px;
      height: 37px;
      min-width: 37px;
    }
  }
  &.none {
    background: rgba(117, 116, 216, 0.05);
  }

  & .alarm_content {
    margin-left: 18px;
    cursor: pointer;
    ${media.medium} {
      margin-left: 12px;
    }
  }
  & p.alarm_title {
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #0d0d0e;
    word-break: break-word;
    ${media.medium} {
      font-size: 13px;
      line-height: 22px;
    }
  }
  & p.alarm_text {
    font-weight: 400;
    font-size: 13px;
    line-height: 22px;
    color: #7c7c80;
    ${media.medium} {
      font-size: 12px;
    }
  }
`
