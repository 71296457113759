import { PageTypeState } from 'atoms/common'
import Footer from 'components/common/Footer'
import Header from 'components/common/header/Header'
import MobileTitleHeader from 'components/common/header/MobileTitleHeader'
import TutorHeader from 'components/common/header/TutorHeader'
import SmsComponent from 'components/common/sms/SmsComponent'
import media from 'lib/styles/media'
import React, { useEffect } from 'react'
import { useRecoilState } from 'recoil'
import styled from 'styled-components'

function RegisterSmsPage() {
  const [pageType, setPageType] = useRecoilState(PageTypeState)

  return (
    <Wrapper>
      {pageType === 'tutor' ? <TutorHeader /> : <Header />}

      <MobileTitleHeader type="arrow" title="본인 인증" />
      <Contents>
        <SmsComponent type="join" pageType={pageType} />
      </Contents>
      <Footer />
    </Wrapper>
  )
}

export default RegisterSmsPage
const Wrapper = styled.div``
const Contents = styled.div`
  margin-top: 100px;
  ${media.medium} {
    margin-top: 0;
  }
`
