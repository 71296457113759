import { confirmModalState } from 'atoms/modal'
import { RoomPageWrapper } from 'components/layout/CommonStyle'
import { useRoomInfoValue } from 'components/room/provider/RoomInfoProvider'
import RoomHeader from 'components/room/common/RoomHeader'
import AudienceRoomContainer from 'containers/room/AudienceRoomContainer'
import useRoom from 'lib/hooks/room/useRoom'
import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import styled from 'styled-components'
import api from 'lib/api'

interface AudienceRoomPageProps {}

function AudienceRoomPage({}: AudienceRoomPageProps) {
  const { maxCount } = useRoomInfoValue()
  const { roomId }: { roomId: string } = useParams()
  const { data } = useRoom(roomId)
  const setConfirmModal = useSetRecoilState(confirmModalState)
  const history = useHistory()
  const handleClickArrow = () => {
    setConfirmModal({
      title: '강의 나가기',
      description: '강의를 나가시겠습니까?',
      confirmText: '나가기',
      onConfirm: () => {
        history.goBack()
      },
    })
  }

  const enterRoomAudience = async () => {
    await api.post('/rtc/attend', {
      class_id: roomId,
    })
  }

  useEffect(() => {
    const interval = setInterval(() => {
      enterRoomAudience()
    }, 5700)

    return () => {
      clearInterval(interval)
    }
  }, [])

  return (
    <Wrapper>
      <RoomHeader
        title={data?.room?.title ?? ''}
        maxCount={maxCount}
        onClickArrow={handleClickArrow}
      />
      <AudienceRoomContainer />
    </Wrapper>
  )
}

const Wrapper = styled(RoomPageWrapper)`
  position: relative;
  flex-direction: column;
  background: #242427;
`

export default AudienceRoomPage
