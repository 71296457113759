import ParticipantList from 'components/room/side/ParticipantList'
import React from 'react'
import styled from 'styled-components'

function ParticipantContainer() {
  return (
    <Wrapper>
      <ParticipantList />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  flex: 1;
`

export default ParticipantContainer
