import media from 'lib/styles/media'
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import Button from '../Button'
import { useHistory } from 'react-router-dom'
import api from 'lib/api'

import { ReactComponent as OnAir } from '../../../assets/icons/class_live_icon.svg'
import { ReactComponent as Menu } from '../../../assets/icons/square_button_icon.svg'
import { ReactComponent as Star } from '../../../assets/icons/reiews_star_off.svg'
import { dateDiffHour } from 'lib/utils/CommonFuncttion'

interface Props {
  begin_date: string
  categories: any[]
  id: number
  image_url: string
  is_close: number
  is_multi: number
  is_public: number
  join_qty: number
  max_qty: number
  price: number
  status: number
  step: number
  step_title: string
  title: string
  review_score?: string
  review_count?: number
  real_begin_date?: string
  real_end_date?: string
}

const ManageList = ({
  type,
  data,
  last,
  handleDelete,
  handleClose,
  handleLink,
}: {
  type: '0' | '1'
  data: Props
  last?: boolean
  handleDelete: (id: string) => void
  handleClose: (type: string, id: string) => void
  handleLink: () => void
}) => {
  const history = useHistory()

  const menuRef = useRef(null)

  const onWindowClick = (e: any) => {
    e.stopPropagation()
    const parentId = e.target.parentNode.id

    if (
      parentId === undefined ||
      parentId === '' ||
      (parentId !== undefined &&
        parentId !== '' &&
        parentId !== 'manage_menu_btn_' + data.id &&
        parentId !== 'manage_menu_' + data.id)
    ) {
      try {
        const elements = document.querySelectorAll('.manage_list_menu_box')

        if (elements && elements.length > 0) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          for (const element of elements) {
            if (element.className.includes(String(data.id))) {
              element.style.opacity = '0'

              setTimeout(() => {
                element.style.display = 'none'
              }, 300)
            }
          }
        }
      } catch (error) {}
    }
  }

  useEffect(() => {
    window.addEventListener('click', onWindowClick)
  }, [])

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    if (menuRef.current !== null && !menuRef.current.style.display) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      menuRef.current.style.display = 'none'
    }
  }, [menuRef])

  const handleMenu = () => {
    if (menuRef.current !== null) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      if (menuRef.current.style.display === 'none') {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        menuRef.current.style.display = 'block'
        setTimeout(() => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error{
          menuRef.current.style.opacity = '1'
        }, 100)
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        menuRef.current.style.opacity = '0'
        setTimeout(() => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error{
          menuRef.current.style.display = 'none'
        }, 300)
      }
    }
  }

  const handleOpen = async () => {
    try {
      const formData = new FormData()
      formData.append('class_id', String(data.id))
      formData.append('step', String(data.step))

      const {
        data: { success, alert },
      } = await api.post('/class/room/create', formData)
      if (success) {
        history.push('/room/' + data.id)
      } else {
        window.alert(alert)
      }
    } catch (err) {}
  }

  const isBegin = moment().isAfter(
    moment(data.begin_date).subtract(30, 'minute'),
  )

  if (!data) return null

  return (
    <ListBox>
      <ListImage
        onClick={() => history.push('/class/' + data.id + '?role=tutor')}
      >
        <img src={data.image_url} />
        {type === '0' && (
          <ClassStart
            className={moment().isAfter(moment(data.begin_date)) ? 'start' : ''}
          >
            {!moment().isAfter(moment(data.begin_date)) ? (
              <>{moment(data.begin_date).format('HH:mm')} 시작</>
            ) : (
              <>
                <OnAir />
                {media.medium ? '진행중' : '수업진행중'}
              </>
            )}
          </ClassStart>
        )}
      </ListImage>
      <ListInfo className={type === '1' ? 'end' : ''}>
        {type === '0' && (
          <People
            onClick={() => history.push('/class/' + data.id + '?role=tutor')}
          >
            {data.is_public !== 1 && <div>비공개</div>}
            수강인원{' '}
            <span>
              {data.join_qty}/{data.max_qty}
            </span>
          </People>
        )}
        <h1
          className={data.is_multi === 1 ? 'one' : ''}
          onClick={() => history.push('/class/' + data.id + '?role=tutor')}
        >
          {data.title}
        </h1>
        {type === '1' && (
          <Info>
            {`${moment(data.real_begin_date).format('HH:mm')} ~ ${moment(
              data.real_end_date,
            ).format('HH:mm')} ${dateDiffHour(
              data.real_begin_date,
              data.real_end_date,
            )}`}
            <Review>
              <Star />
              {data.review_score}
              <span />
              리뷰({data.review_count})
            </Review>
          </Info>
        )}
        {data.is_multi === 1 && (
          <p onClick={() => history.push('/class/' + data.id + '?role=tutor')}>
            {data.step_title}
          </p>
        )}
        {data.categories?.length > 0 && (
          <Category
            onClick={() => history.push('/class/' + data.id + '?role=tutor')}
          >
            {data.categories.map((cate, i) => {
              if (i === 0) return cate?.category
              else
                return (
                  <>
                    <span />
                    {cate?.category}
                  </>
                )
            })}
          </Category>
        )}
        {type === '0' && (
          <ButtonBox>
            <MenuButton onClick={handleMenu}>
              <Menu id={'manage_menu_btn_' + data.id} />
            </MenuButton>
            <Button
              title={
                isBegin
                  ? data.status > 0
                    ? '강의 입장'
                    : '온라인 교실 생성'
                  : '강의 시간 30분 전부터 입장 가능'
              }
              className="small"
              variant={
                isBegin
                  ? data.status > 0
                    ? 'contained'
                    : 'outlined'
                  : 'contained'
              }
              disabled={!isBegin}
              onClick={() => {
                if (data.status > 0) history.push('/room/' + data.id)
                else handleOpen()
              }}
            />
            <MenuBox
              ref={menuRef}
              id={'manage_menu_' + data.id}
              className={
                (last ? 'last' : '') + ' manage_list_menu_box' + ` ${data.id}`
              }
            >
              {!moment().isAfter(moment(data.begin_date)) && (
                <button
                  onClick={() => history.push('/register/class/' + data.id)}
                >
                  강의 수정
                </button>
              )}
              <button
                onClick={() => {
                  handleMenu()
                  handleDelete(data.id.toString())
                }}
              >
                강의 삭제
              </button>
              {!moment().isAfter(moment(data.begin_date)) && (
                <button
                  onClick={() => {
                    handleMenu()
                    handleClose(
                      data.is_close === 0 ? 'close' : '',
                      String(data.id),
                    )
                  }}
                >
                  {data.is_close === 0 ? '모집 마감' : '모집 마감 해제'}
                </button>
              )}
              <button
                onClick={() => {
                  handleMenu()
                  handleLink()
                }}
              >
                링크 복사
              </button>
            </MenuBox>
          </ButtonBox>
        )}
      </ListInfo>
    </ListBox>
  )
}

export default ManageList

const Review = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
  font-weight: 500;
  font-size: 13px;
  line-height: 1.69;
  color: #7c7c80;

  & > svg {
    width: 13px;
    height: 12px;
    margin-right: 4px;
  }

  & > span {
    width: 2px;
    min-width: 2px;
    height: 2px;
    background: #b5b5b9;
    margin: 0 6px;
  }

  ${media.medium} {
    width: 100%;
    margin-left: 0;
  }
`

const Info = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.71;
  color: #3b3b40;
  margin-bottom: 10px;

  ${media.medium} {
    display: block;
    margin-bottom: 6px;
  }
`

const MenuBox = styled.div`
  position: absolute;
  top: calc(100% + 6px);
  padding: 11px 14px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  width: 200px;
  transition: all 0.3s;
  z-index: 1;

  opacity: 0;

  & > button {
    width: 172px;
    padding: 0;
    margin: 0;
    border: 0;
    outline: none;
    background: none;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.71;
    color: #3b3b40;
    text-align: left;

    &:nth-of-type(n + 2) {
      margin-top: 10px;
    }
  }

  &.last {
    top: auto;
    bottom: calc(100% + 6px);
  }

  ${media.medium} {
    & > button:nth-of-type(n + 2) {
      margin-top: 22px;
    }
  }
`

const MenuButton = styled.button`
  width: 36px;
  min-width: 36px;
  height: 42px;
  padding: 0px;
  border: 0;
  outline: none;
  background: none;
  cursor: pointer;

  & > svg {
    width: 36px;
    min-width: 36px;
    height: 42px;
  }
`

const ButtonBox = styled.div`
  margin-top: 30px;
  position: relative;
  display: flex;
  align-items: center;

  & > button:nth-of-type(n + 2) {
    margin-left: 10px;
  }

  ${media.medium} {
    margin-top: 15px;
  }
`

const Category = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  font-size: 13px;
  line-height: 1.69;
  color: #b5b5b9;
  cursor: pointer;

  & > span {
    display: inline-block;
    width: 2px;
    min-width: 2px;
    height: 2px;
    margin: 5px;
    background: #b5b5b9;
  }

  ${media.medium} {
    font-size: 12px;
    line-height: 1.83;
  }
`

const People = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 1.71;
  color: #3b3b40;
  margin-bottom: 8px;
  cursor: pointer;

  & > span {
    font-weight: 500;
  }

  & > div {
    padding: 2px 6px;
    margin-right: 6px;
    font-weight: 700;
    font-size: 12px;
    line-height: 1.83;
    color: #ffffff;
    background: #b5b5b9;
    border-radius: 3px;
  }

  ${media.medium} {
    font-size: 13px;
    line-height: 2.08;
    color: #0d0d0e;
  }
`

const ListInfo = styled.div`
  border: 1px solid #e1e1e2;
  border-radius: 0px 0px 14px 14px;
  padding: 18px 20px 20px;

  &.end {
    padding-bottom: 34px;

    & > h1 {
      margin-bottom: 10px;
    }
  }

  & > h1 {
    margin-bottom: 8px;
    font-weight: 700;
    font-size: 16px;
    line-height: 1.62;
    color: #3b3b40;
    cursor: pointer;

    &.one {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  & > p {
    width: 100%;
    margin-bottom: 8px;
    padding: 4px 10px;
    background: #eeeff1;
    border-radius: 3px;
    font-weight: 500;
    font-size: 12px;
    line-height: 1.83;
    text-align: center;
    color: #3b3b40;
    cursor: pointer;
  }

  ${media.medium} {
    border-radius: 0px 0px 10px 10px;
    padding: 14px;

    &.end {
      padding-bottom: 20px;
    }

    & > h1 {
      margin-bottom: 6px;
      font-size: 14px;
      line-height: 1.71;
    }

    & > p {
      margin-bottom: 6px;
    }
  }
`

const ClassStart = styled.div`
  position: absolute;
  bottom: 20px;
  left: 20px;
  padding: 0 6px;
  background: #7574d8;
  border-radius: 3px;
  font-weight: 700;
  font-size: 12px;
  line-height: 1.83;
  color: #fff;

  & > svg {
    margin-right: 4px;
  }

  &.start {
    background: #ff2c2c;
  }

  ${media.medium} {
    bottom: 16px;
    left: 16px;
  }
`

const ListImage = styled.div`
  width: 100%;
  border-radius: 14px 14px 0px 0px;
  padding-top: 56.21%;
  position: relative;
  overflow: hidden;
  cursor: pointer;

  & > img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
  }

  ${media.medium} {
    border-radius: 10px 10px 0px 0px;
    padding-top: 57.58%;
  }
`

const ListBox = styled.div`
  width: calc(33.33% - 14px);
  margin-right: 14px;
  border-radius: 14px;

  &:nth-of-type(3n) {
    margin-right: 0;
  }

  &:nth-of-type(n + 4) {
    margin-top: 16px;
  }

  ${media.medium} {
    width: 100%;
    margin-right: 0;
    border-radius: 10px;

    &:nth-of-type(n + 4) {
      margin-top: 0;
    }
  }
`
