import { AppRootWrapper } from 'components/layout/CommonStyle'
import api from 'lib/api'
import media from 'lib/styles/media'
import type { NewClassDataProps } from 'lib/types/account'
import React from 'react'
import styled from 'styled-components'
import NewClassInfoComponent from '../NewClassInfoComponent'

function NewClassComponent({ data }: NewClassDataProps) {
  return (
    <Wrapper>
      <p className="main_new_title">새로운 디베이팅 강의를 확인해 보세요!</p>
      <Content>
        {data.map((item, i) => (
          <NewClassInfoComponent
            key={`main_new_class_list_${i}`}
            Image={item.image_url}
            Profile={item.profile_url}
            Title={item.title}
            Nickname={item.nickname}
            Score={item.tutor_score}
            Category={item.categories}
            Price={item.price}
            like={item.is_my_like}
            date={item.begin_date}
            id={item.id}
            tutorId={item.tutor_id}
          />
        ))}
      </Content>
    </Wrapper>
  )
}
export default NewClassComponent

const Wrapper = styled(AppRootWrapper)`
  margin-top: 85px;
  ${media.medium} {
    margin-top: 65px;
  }
  & .main_new_title {
    font-weight: 700;
    font-size: 22px;
    line-height: 34px;
    color: #0d0d0e;
    ${media.medium} {
      font-size: 16px;
      line-height: 26px;
    }
  }
`
const Content = styled.div`
  margin-top: 32px;
  display: flex;
  overflow: hidden;
  flex-wrap: wrap;
  ${media.medium} {
    margin-top: 18px;
    flex-wrap: nowrap;
    overflow-x: scroll;
    width: calc(100% + 20px);
  }
  & > div {
    margin-bottom: 40px;
    ${media.medium} {
      margin-bottom: 0;
    }
  }
  & > div:nth-of-type(4n) {
    margin-right: 0;
    ${media.medium} {
      margin-right: 12px;
    }
  }
`
