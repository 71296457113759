import type { MyClassListDetailDataProps } from 'lib/types/account'
import React, { useEffect } from 'react'
import styled from 'styled-components'
import ClassInfoContent from './ClassInfoContent'
import ReviewInfoContent from './ReviewInfoContent'
import { MyClassListDataPayloadState } from 'atoms/join'
import { useRecoilState } from 'recoil'
import { useHistory } from 'react-router-dom'

interface Props {
  data: MyClassListDetailDataProps
}
function MyClassListComponent({ data }: Props) {
  const history = useHistory()
  const [payload, setPayload] = useRecoilState(MyClassListDataPayloadState)

  const handleReviewClick = () => {
    setPayload({
      ...payload,
      image: data.image_url,
      title: data.title,
      nickname: data.nickname,
      beginDate: data.step_info.step_real_begin_date ?? data.real_begin_date,
      endDate: data.step_info.step_real_end_date ?? data.real_end_date,
      time: data.step_info.step_estimated_time ?? '',
      profile: data.profile_url,
      stepInfo: data.step_info,
    })
    history.push(`/myclass/review/${data.id}`)
  }
  const handleFeedbackClick = () => {
    setPayload({
      ...payload,
      image: data.image_url,
      title: data.title,
      nickname: data.nickname,
      beginDate: data.step_info.step_real_begin_date ?? data.real_begin_date,
      endDate: data.step_info.step_real_end_date ?? data.real_end_date,
      time: data.step_info.step_estimated_time ?? '',
      profile: data.profile_url,
      stepInfo: data.step_info,
    })
    history.push(`/myclass/feedback/${data.id}`)
  }
  return (
    <Wrapper>
      <ClassInfoContent
        image={data.image_url}
        title={data.title}
        begin={data.step_info.step_real_begin_date ?? data.real_begin_date}
        end={data.step_info.step_real_end_date ?? data.real_end_date}
        category={data.categories}
        nickname={data.nickname}
        profile={data.profile_url}
        score={data.tutor_score}
        stepInfo={data.step_info}
      />
      <ReviewInfoContent
        review={data.review_contents}
        grade={data.review_grade}
        feedback={data.feedback_score}
        maxFeedback={data.max_feedback_score}
        handleReviewClick={handleReviewClick}
        handleFeedbackClick={handleFeedbackClick}
      />
    </Wrapper>
  )
}

export default MyClassListComponent
const Wrapper = styled.div`
  border-top: 1px solid #e1e1e2;
  border-bottom: 1px solid #e1e1e2;
`
