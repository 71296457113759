import axios from 'axios'
import api, { API_END_POINT } from '.'

export const getAlarmListRequest = async (page: number) => {
  return api
    .get('/message', {
      params: {
        page,
      },
    })
    .then((res) => res.data)
}

export const getSearchListRequest = async (
  page: number,
  searchData: string,
  availableData: string,
  studentUser?: boolean,
) => {
  const fetch = studentUser ? api : axios
  return fetch
    .get(API_END_POINT + `/class`, {
      params: {
        page,
        search: searchData,
        type: 'search',
        available_class: availableData,
      },
    })
    .then((res) => res.data)
}

export const getLikeListRequest = async (page: number) => {
  return api
    .get('/class/like', {
      params: {
        page,
      },
    })
    .then((res) => res.data)
}

export const getCategoryListRequest = async (
  page: number,
  categoryId: string,
  availableData: string,
  studentUser?: boolean,
) => {
  const fetch = studentUser ? api : axios

  return fetch
    .get(API_END_POINT + '/class', {
      params: {
        page,
        category_id: categoryId,
        type: 'search',
        available_class: availableData,
      },
    })
    .then((res) => res.data)
}

export const getPaymentListRequest = async (page: number, status: string) => {
  return api
    .get(`/payment`, {
      params: {
        page,
        payment_status: status,
      },
    })
    .then((res) => res.data)
}
