import { AppRootWrapper } from 'components/layout/CommonStyle'
import React from 'react'
import styled from 'styled-components'
import PaymentMenuTab from './PaymentMenuTab'
import TabPaymentComponent from './TabPaymentComponent'
import media from 'lib/styles/media'
import type { MyClassPaymentDataProps } from 'lib/types/account'

interface Props {
  pageType: 'user' | 'tutor'
  location: string
  data: MyClassPaymentDataProps
  type: string
  handleClick: (type: string) => void
}
function MypaymentComponent({
  pageType,
  location,
  data,
  type,
  handleClick,
}: Props) {
  return (
    <Wrapper>
      <Title>
        {pageType === 'tutor' ? <p>결제 관리</p> : <p>결제 내역</p>}
      </Title>
      <PaymentMenuTab
        handleClick={handleClick}
        location={location}
        pageType={pageType}
      />
      <Contents>
        <TabPaymentComponent data={data} type={type} pageType={pageType} />
      </Contents>
    </Wrapper>
  )
}

export default MypaymentComponent
const Wrapper = styled(AppRootWrapper)``
const Title = styled.div`
  padding: 40px 0 32px;
  ${media.medium} {
    padding: 0;
  }
  & > p {
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: #0d0d0e;
    ${media.medium} {
      display: none;
    }
  }
`
const Contents = styled.div`
  min-height: 600px;
  margin-bottom: 120px;
  ${media.medium} {
    min-height: 400px;
    margin-bottom: 80px;
  }
`
