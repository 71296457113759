import { AppRootWrapper } from 'components/layout/CommonStyle'
import media from 'lib/styles/media'
import type { RootDataProps, RootIngDataProps } from 'lib/types/account'
import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'
import Search from '../Search'
import HotClassComponent from './HotClassComponent'
import IngClassComponent from './IngClassComponent'
import MainBannerComponent from './MainBannerComponent'
import MainMyInfo from './MainMyInfo'
import NewClassComponent from './NewClassComponent'
import TutorClassComponent from './TutorClassComponent'

interface RootProps {
  data: RootDataProps
  ingData: RootIngDataProps
}

function RootComponent({ data, ingData }: RootProps) {
  const params = useParams<{ search: string }>()
  const value = params.search
  const history = useHistory()

  return (
    <Wrapper>
      <AppContents className="contents">
        <MainBannerComponent data={data.banners} />
        <div className="my_info">
          <MainMyInfo />
        </div>
      </AppContents>
      <MobileSearch className="mobile_search">
        <Search
          preValue={value}
          placeholder="강의 제목, 튜터 이름으로 검색하세요!"
          onClick={() => {
            history.push('/mobile/search')
          }}
          type="mobile"
        />
      </MobileSearch>
      <IngClassComponent data={ingData.ing_classes} />
      {data.hot_classes.length > 0 && (
        <HotClassComponent data={data.hot_classes} />
      )}
      <NewClassComponent data={data.new_classes} />
      <TutorClassComponent data={data.tutors} />
    </Wrapper>
  )
}
export default RootComponent

const Wrapper = styled.div``
const AppContents = styled(AppRootWrapper)`
  display: flex;
  ${media.medium} {
    display: block;
    min-height: 176px;
  }
  & .my_info {
    ${media.medium} {
      display: none;
    }
  }
`
const MobileSearch = styled(AppRootWrapper)`
  display: none;
  margin-top: 33px;
  ${media.medium} {
    display: flex;
  }
`
