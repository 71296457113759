import { type RoomRoleType, type RoomSeatInfoType } from 'lib/types/room'
import React, { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import styled, { css } from 'styled-components'
import EmptySeat from './EmptySeat'
import { ReactComponent as BasicIcon } from 'assets/icons/basic_icon.svg'
import media from 'lib/styles/media'
import {
  type ExchangedSeatType,
  useRoomInfoAction,
  useRoomInfoValue,
} from '../provider/RoomInfoProvider'
import useUser from 'lib/hooks/account/useUser'
import RoomMemberOption from './RoomMemberOption'
import Video from '../common/Video'
import useRoom from 'lib/hooks/room/useRoom'
import { SyncLoader } from 'react-spinners'
import toast from 'react-hot-toast'
import api from 'lib/api'
import { CommonRoomProfileWrapper } from 'components/layout/CommonStyle'
import RoomProfileImage from '../common/RoomProfileImage'
import Audio from '../common/Audio'

interface DebateMemberProps {
  seat?: RoomSeatInfoType
  exchangedSeat?: ExchangedSeatType
  seatNumber: number
  role: RoomRoleType
  isTeamRoom?: boolean
  isFocus?: boolean
}

function DebateMember({
  seat,
  seatNumber,
  role,
  exchangedSeat,
  isTeamRoom,
  isFocus,
}: DebateMemberProps) {
  const { roomId }: { roomId: string } = useParams()
  const {
    changeSeat,
    exitUser,
    exchangeSeatBefore,
    resetExchangeSeat,
    exchangeSeatAfter,
  } = useRoomInfoAction()
  const { myRoomInfo, tutorRoomInfo, maxCount } = useRoomInfoValue()
  const { debateStatus } = useRoom(roomId)

  const { user } = useUser()

  const userData = user

  const handleChangeSeat = () => {
    changeSeat &&
      changeSeat({
        userId: userData.id,
        roomId: parseInt(roomId),
        tableNum: seatNumber,
      })
  }

  const handleExitUser = () => {
    if (role !== 'tutor' || !exitUser || !seat) return

    exitUser({
      userId: seat.user_id,
      roomId: parseInt(roomId),
    })
  }

  const handleExchangeSeatBefore = () => {
    if (role !== 'tutor' || !exchangeSeatBefore || !seat) return

    toast('자리를 바꿀 학생을 선택해주세요.', {
      className: 'toast',
      duration: 3000,
      position: 'bottom-center',
    })

    exchangeSeatBefore({
      userId: seat.user_id,
      tableNum: seat.table_num,
    })
  }

  const handleExchangeSeatAfter = () => {
    if (role !== 'tutor' || !exchangeSeatAfter) return

    exchangeSeatAfter({
      tableNum: seatNumber,
    })
  }

  const handleResetExchangeSeat = () => {
    resetExchangeSeat && resetExchangeSeat()
  }

  const handleComplement = async () => {
    if (role !== 'tutor') return

    const tutorNick = `${tutorRoomInfo?.nickname} 튜터`
    const memberNick = seat?.nickname ?? seat?.name

    const message = `${tutorNick}님이 ${memberNick}님에게 좋아요를 남겼습니다.`

    await api.post('/rtc/chat/register', {
      message,
      class_id: roomId,
      step: 1,
    })
  }

  const isCamera = useMemo(() => {
    if (!isTeamRoom) {
      if (seat?.team) return false
      if (!seat?.stream) return false
      if (seat?.is_camera === 0) return false
      return true
    }

    if (role === 'tutor') {
      if (tutorRoomInfo?.team !== seat?.team) return false
    }
    if (role === 'student') {
      if (myRoomInfo?.team !== seat?.team) return false
    }
    if (!seat?.stream) return false
    if (seat?.is_camera === 0) return false
    return true
  }, [seat, myRoomInfo, tutorRoomInfo, role])

  const isMike = useMemo(() => {
    if (!isTeamRoom) {
      if (seat?.team) return false
      if (seat?.is_mike === 0) return false
      return true
    }

    if (role === 'tutor') {
      if (tutorRoomInfo?.team !== seat?.team) return false
    }
    if (role === 'student') {
      if (myRoomInfo?.team !== seat?.team) return false
    }

    if (seat?.is_mike === 0) return false
    return true
  }, [seat, myRoomInfo, tutorRoomInfo, role])

  const currentTeam = useMemo(() => {
    if (debateStatus === 6) return null
    if ((seat?.table_num ?? 0) <= maxCount / 2) return 'A'
    return 'B'
  }, [seat?.table_num, maxCount, debateStatus])

  if (!seat) {
    return (
      <EmptySeat
        role={role}
        onChangeSeat={handleChangeSeat}
        exchangedSeat={exchangedSeat}
        onExchangeSeatAfter={handleExchangeSeatAfter}
      />
    )
  }

  return (
    <Wrapper team={currentTeam}>
      {isCamera && seat.stream ? (
        <CommonRoomProfileWrapper>
          <Video stream={seat.stream} test={seat} />
        </CommonRoomProfileWrapper>
      ) : (
        <RoomProfileImage profileUrl={seat.profile_url} />
      )}

      {isMike && (
        <Audio muted={!isMike} userId={seat.user_id} stream={seat?.stream} />
      )}

      <div className="nickname">{seat.nickname ?? seat.name}</div>

      {role === 'tutor' && (
        <RoomMemberOption
          seat={seat}
          exchangedSeat={exchangedSeat}
          onExitUser={handleExitUser}
          onExchangeSeatBefore={handleExchangeSeatBefore}
          onExchangeSeatAfter={handleExchangeSeatAfter}
          onResetExchangeSeat={handleResetExchangeSeat}
          onComplement={handleComplement}
        />
      )}

      {isFocus && (
        <div className="loading">
          <SyncLoader className="loading-icon" color="#fff" size={5} />
        </div>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div<{ team: 'A' | 'B' | null }>`
  position: relative;

  ${media.pc} {
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.4);
    border-radius: 6px;
    width: 100%;
    aspect-ratio: 1000 / 594;
    background: #3b3b40;
  }

  ${media.medium} {
    height: 60px;
    border-bottom: 1px solid #7c7c80;
    display: flex;
  }

  & .profile-image {
    ${media.pc} {
      width: calc(100% / 3.02);
      aspect-ratio: 1 /1;
      border-radius: 50%;
    }

    ${media.medium} {
      width: 36px;
      height: 36px;
      border-radius: 50%;
    }
  }

  & .basic-icon {
    ${media.medium} {
      width: 36px;
      height: 36px;
    }
  }

  & .nickname {
    ${media.pc} {
      position: absolute;
      right: 10px;
      bottom: 10px;
      border-radius: 6px;
      padding: 10px 12px;

      background: rgba(13, 13, 14, 0.6);
      color: #fff;
      ${(props) =>
        props.team === 'A' &&
        css`
          background: #72dfd4;
          color: #0d0d0e;
        `}

      ${(props) =>
        props.team === 'B' &&
        css`
          background: #57569e;
          color: #fff;
        `}
    }

    ${media.medium} {
      font-weight: 500;
      font-size: 13px;
      line-height: 22px;

      display: flex;
      align-items: center;
      margin-left: 10px;
      color: #fff;

      ${(props) =>
        props.team === 'A' &&
        css`
          color: rgba(143, 225, 205, 1);
        `}

      ${(props) =>
        props.team === 'B' &&
        css`
          color: rgba(144, 143, 244, 1);
        `}
    }
  }

  & .loading {
    position: absolute;

    top: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    background: rgba(0, 0, 0, 0.7);
    ${media.pc} {
      width: 100%;
      height: 100%;
    }
    ${media.medium} {
      & .loading {
        width: 100px;
        height: 60px;
      }
    }
  }
`

export default DebateMember
