import React from 'react'
import type { UserInfo } from 'lib/types/account'
import StudentHeader from './StudentHeader'
import TutorHeader from './TutorHeader'

interface RootPageProps {
  user?: UserInfo
}

function Header({ user }: RootPageProps) {
  if (!user || user.type === 'user') return <StudentHeader />
  return <TutorHeader />
}

export default Header
