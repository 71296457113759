import { type RoomSeatInfoType } from 'lib/types/room'
import React from 'react'
import styled, { css } from 'styled-components'
import { ReactComponent as BasicIcon } from 'assets/icons/basic_icon.svg'
import Video from './Video'
import Audio from './Audio'

interface CommonDebateComponentProps {
  info?: RoomSeatInfoType
  disableName?: boolean
  isCamera?: boolean
  isMike?: boolean
}

function CommonDebateComponent({
  info,
  disableName,
  isCamera,
  isMike,
}: CommonDebateComponentProps) {
  if (!info)
    return (
      <Wrapper>
        <div className="profile">
          <BasicIcon className="basic-icon" />
        </div>
      </Wrapper>
    )

  return (
    <Wrapper team={info?.currentTeam}>
      {isCamera ? (
        <div className="content">
          <Video stream={info.stream} test={info} />
          {!disableName && (
            <div className="nickname">{info.nickname ?? info.name}</div>
          )}
        </div>
      ) : (
        <>
          <div className="profile">
            {info?.profile_url ? (
              <img
                src={info?.profile_url}
                alt="profile"
                className="profile-image"
              />
            ) : (
              <BasicIcon className="basic-icon" />
            )}
          </div>
          {!disableName && (
            <div className="nickname">{info.nickname ?? info.name}</div>
          )}
        </>
      )}
      {isMike && <Audio userId={info.user_id} stream={info.stream} />}
    </Wrapper>
  )
}

const Wrapper = styled.div<{ team?: 'A' | 'B' }>`
  position: relative;

  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.4);
  border-radius: inherit;
  width: 100%;
  aspect-ratio: 1000 / 594;
  background: #3b3b40;

  & .profile {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & .profile-image,
  .basic-icon {
    width: calc(100% / 3.02);
    aspect-ratio: 1 /1;
    border-radius: 50%;
  }

  & .nickname {
    position: absolute;
    right: 10px;
    bottom: 10px;
    border-radius: 6px;
    padding: 10px 12px;
    background: rgba(13, 13, 14, 0.6);
    color: #fff;

    ${(props) =>
      props.team === 'A' &&
      css`
        background: #72dfd4;
        color: #0d0d0e;
      `}

    ${(props) =>
      props.team === 'B' &&
      css`
        background: #57569e;
        color: #fff;
      `}
  }

  background: #3b3b40;
`

export default CommonDebateComponent
