import React from 'react'
import styled, { css } from 'styled-components'
import useSWR from 'swr'
import { ReactComponent as ViewIcon } from 'assets/icons/view_icon.svg'
import { type RoomRoleType } from 'lib/types/room'
import { useSetRecoilState } from 'recoil'
import {
  audiencesModalState,
  roomSettingViewModalState,
  timeSettingModalState,
} from 'atoms/modal'
import useRoom from 'lib/hooks/room/useRoom'
import { addDate } from 'lib/utils'

function AudienceCount({
  roomId,
  role,
}: {
  roomId: string
  role: RoomRoleType
}) {
  const { data } = useSWR(`rtc/attend/view?class_id=${roomId}`, {
    refreshInterval: 10000,
  })
  const setRoomSettingViewModal = useSetRecoilState(roomSettingViewModalState)
  const setAudiencesModal = useSetRecoilState(audiencesModalState)
  const setTimeSettingModal = useSetRecoilState(timeSettingModalState)
  const { setVoteTimerTrigger } = useRoom(roomId)

  const handleVote = (timer: number) => {
    setVoteTimerTrigger && setVoteTimerTrigger(addDate(timer))
  }

  const settingTimer = () => {
    role === 'tutor' &&
      setTimeSettingModal({
        title: '타이머 설정',
        text: '설정한 시간이 디베이팅룸에 카운트됩니다.',
        onSetting: handleVote,
      })
  }

  const handleViewAudienceList = () => {
    if (role !== 'tutor') return
    setRoomSettingViewModal(null)
    setAudiencesModal({
      onSubmit: settingTimer,
    })
  }

  return (
    <Wrapper onClick={handleViewAudienceList}>
      <div className="view-wrapper mobile-hidden">
        <ViewIcon />
        <span className="view-count">{data?.data?.cnt ?? 0}</span>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  & .view-wrapper {
    display: flex;
    align-items: center;

    ${(props) =>
      props.role === 'tutor'
        ? css`
            background: rgba(59, 59, 64, 0.8);
            border-radius: 8px;
            height: 44px;
            padding: 0 10px;
            cursor: pointer;
          `
        : css`
            padding: 0 10px;
          `}
  }

  & .view-count {
    margin-left: 6px;
  }
`

export default AudienceCount
