import { AppRootWrapper } from 'components/layout/CommonStyle'
import media from 'lib/styles/media'
import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import TutorProfileContent from './TutorProfileContent'
import { useRecoilState } from 'recoil'
import { TutorJoinStepPayloadState } from 'atoms/join'
import TutorTextAreaContent from './TutorTextAreaContent'
import TutorInputContent from './TutorInputContent'
import Button from '../Button'
import LineButton from '../LineButton'
import TutorDetailInfoContent from './TutorDetailInfoContent'
import CheckPopup from '../CheckPopup'
import api from 'lib/api'
import { useHistory } from 'react-router-dom'
import type Editor from '@toast-ui/editor'
import axios from 'axios'

function TutorInfoComponent() {
  const history = useHistory()
  const [payload, setPayload] = useRecoilState(TutorJoinStepPayloadState)
  const [preview, setPreview] = useState<File | undefined>()
  const [popup, setPopup] = useState(false)
  const [menu, setMenu] = useState('single')
  const detailContent = payload?.detailIntroduce?.map((item) => item.content)
  const payloadDisabled = detailContent?.includes('<p><br></p>')
  const TabBox = [
    { text: 'single', title: '단일 항목' },
    { text: 'multi', title: '여러 항목' },
  ]

  useEffect(() => {
    if (popup) {
      document.documentElement.style.overflow = 'hidden'
    } else {
      document.documentElement.style.overflow = 'auto'
    }

    return () => {
      document.documentElement.style.overflow = 'auto'
    }
  }, [popup])

  const handleEditProfile = (file: any, newFile: File) => {
    setPayload({
      ...payload,
      profile: file,
    })
    setPreview(newFile)
  }
  const handleChange = (type: string) => (e: any) => {
    setPayload({
      ...payload,
      [type]: e.target.value,
    })
  }
  const handleCheck = () => {
    setPopup(false)
    window.location.href = '/?role=tutor'
  }
  const handleLaterSubmit = async (type: string) => {
    const marketing = payload?.is_marketing
    const formData = new FormData()
    formData.append('is_marketing', String(marketing))
    formData.append('phone', String(payload?.phone))
    formData.append('email', String(payload?.id))
    formData.append('password', String(payload?.password))
    formData.append('name', String(payload?.name))
    formData.append('nickname', String(payload?.nickname))
    formData.append('bank_name', String(payload?.bankName))
    formData.append('bank_account', String(payload?.bankAccount))
    formData.append('bank_holder', String(payload?.bankHolder))

    const {
      data: { success, alert, data },
    } = await api.post('/tutor/join', formData)

    if (success) {
      if (!data) return
      if (type === 'later') {
        setPopup(true)
      } else {
        handleSubmit(data.token.access_token)
      }
    } else {
      window.alert(alert)
    }
  }
  const handleSubmit = async (token: string) => {
    if (!token) return

    const formData = new FormData()
    payload?.profile !== undefined &&
      formData.append('profile', payload?.profile)
    formData.append('introduce', String(payload?.introduce))
    formData.append('detail_introduce_type', menu)
    payload?.detailIntroduce?.map((item, i) =>
      formData.append(
        `detail_introduces[${item.title ? item.title : ''}]`,
        item.content,
      ),
    )
    formData.append('career_url', String(payload?.careerUrl))

    await axios
      .post('https://api.debateon.fifteenh.io/api/tutor/join/add', formData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(() => setPopup(true))
  }

  const handleTabClick = (text: string) => {
    if (text === '단일 항목') {
      setMenu('single')
    } else {
      setMenu('multi')
    }
  }
  const onhandleChange = (value: string, index: number) => {
    const fineded = payload?.detailIntroduce?.find(
      (detail) => detail.index === index,
    )

    let modified = []

    if (fineded) {
      modified = (payload?.detailIntroduce ?? []).map((detail) => {
        if (detail.index === index) {
          return {
            ...detail,
            content: value,
          }
        }
        return detail
      })
    } else {
      modified = [
        ...(payload?.detailIntroduce ?? []),
        {
          index,
          title: '',
          content: value,
        },
      ]
    }

    setPayload({
      ...payload,
      detailIntroduce: modified,
    })
  }
  const onTitlehandleChange = (value: string, index: number) => {
    const fineded = payload?.detailIntroduce?.find(
      (detail) => detail.index === index,
    )
    let modify = []

    if (fineded) {
      modify = (payload?.detailIntroduce ?? []).map((detail) => {
        if (detail.index === index) {
          return {
            ...detail,
            title: value,
          }
        }
        return detail
      })
    } else {
      modify = [
        ...(payload?.detailIntroduce ?? []),
        {
          index,
          title: value,
          content: '',
        },
      ]
    }

    setPayload({
      ...payload,
      detailIntroduce: modify,
    })
  }

  const handleDelete = (index: number) => {
    const filtered = payload?.detailIntroduce?.filter(
      (detail) => detail.index !== index,
    )

    setPayload({
      ...payload,
      detailIntroduce: filtered,
    })
  }
  return (
    <Wrapper>
      <Content>
        <div className="title_box">
          <p className="join_info_title">튜터 정보 설정</p>
          <p className="join_info_text">
            수강생들에게 노출할 튜터 정보를 설정하세요.
          </p>
        </div>
        <TutorProfileContent
          profile={payload?.profile}
          onChange={handleEditProfile}
          preview={preview}
        />
        <TutorTextAreaContent
          value={payload?.introduce}
          onChange={(e: any) => {
            setPayload({
              ...payload,
              introduce: e.target.value,
            })
          }}
        />
        <TutorDetailInfoContent
          Tab={TabBox}
          menu={menu}
          handleTabClick={handleTabClick}
          onhandleChange={onhandleChange}
          onDelete={handleDelete}
          onTitlehandleChange={onTitlehandleChange}
          mode="add"
          type="info"
        />
        <TutorInputContent
          title={'나의 경력을 확인할 수 있는 URL \n(SNS, 블로그 등)'}
          value={payload?.careerUrl}
          onChange={handleChange('careerUrl')}
          placeholder="https://example.com"
          maxLength={255}
        />
        <Notice>
          <p className="web">
            튜터 정보는 서비스 로그인 후 등록/수정하실 수 있습니다.
            <br />
            부정확하거나 미입력된 정보가 있을 경우, 승인되지 않을 수 있습니다.
          </p>
          <p className="mobile">
            튜터 정보는 서비스 로그인 후 등록/수정하실 수<br />
            있습니다.부정확하거나 미입력된 정보가 있을 경우,
            <br />
            승인되지 않을 수 있습니다.
          </p>
        </Notice>

        <ButtonBox>
          <LineButton
            title="나중에 입력"
            type="gray"
            onClick={() => handleLaterSubmit('later')}
          />
          <Button
            title="완료"
            onClick={() => handleLaterSubmit('now')}
            disabled={
              !payload?.profile &&
              !payload?.careerUrl &&
              (payloadDisabled === undefined || payloadDisabled)
            }
          />
        </ButtonBox>
      </Content>
      {popup && (
        <CheckPopup
          onClick={handleCheck}
          title={'회원가입 완료'}
          content={
            '튜터 회원가입이 완료되었습니다.\n관리자가 최종 승인 후\n강의를 등록하실 수 있습니다.'
          }
        />
      )}
    </Wrapper>
  )
}

export default TutorInfoComponent
const Wrapper = styled(AppRootWrapper)``
const Content = styled.div`
  width: 830px;
  min-height: 1820px;
  margin: 0 auto;
  padding: 80px 30px 160px;
  ${media.medium} {
    padding: 20px 0 80px;
    height: 100%;
    min-height: 1620px;
    width: 100%;
  }
  & div.title_box {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  & p.join_info_title {
    font-weight: 700;
    font-size: 22px;
    line-height: 34px;
    text-align: center;
    color: #0d0d0e;
    ${media.medium} {
      font-size: 18px;
      line-height: 28px;
    }
  }
  & p.join_info_text {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #3b3b40;
    margin-top: 6px;
  }
`
const Notice = styled.div`
  width: 100%;
  background: #f9f9fb;
  border-radius: 8px;
  padding: 12px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  ${media.medium} {
    margin-top: 30px;
    padding: 12px 10px;
  }
  & > p {
    font-weight: 400;
    font-size: 13px;
    line-height: 22px;
    color: #7c7c80;
    text-align: center;
    &.mobile {
      display: none;
    }
    ${media.medium} {
      &.mobile {
        display: flex;
      }
      &.web {
        display: none;
      }
    }
  }
`
const ButtonBox = styled.div`
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${media.medium} {
    margin-top: 30px;
  }
  & button {
    width: calc(50% - 5px);
    margin-top: 0;
    ${media.medium} {
      height: 50px;
    }
  }
`
