import Header from 'components/common/header/Header'
import PaymentDetailComponent from 'components/common/payment/PaymentDetailComponent'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import useSWR from 'swr'
import Footer from 'components/common/Footer'
import ClassCancelPopup from 'components/common/ClassCancelPopup'
import api from 'lib/api'
import MobileTitleHeader from 'components/common/header/MobileTitleHeader'
import media from 'lib/styles/media'
import TwoCheckPopup from 'components/common/TwoCheckPopup'
import { CancelContents, tutorPaymentCancel } from 'lib/utils/CommonFuncttion'
import CheckPopup from 'components/common/CheckPopup'
import BasicPopup from 'components/common/BasicPopup'
import TutorHeader from 'components/common/header/TutorHeader'
import useUser from 'lib/hooks/account/useUser'

function MyPaymentDetailpage() {
  const params = useParams<{ paymentId: string }>()
  const id = params.paymentId.includes('&role=tutor')
    ? params.paymentId.replaceAll('&role=tutor', '')
    : params.paymentId
  const { data: originData } = useSWR(
    `/payment/detail?payment_history_id=${id}`,
  )
  const requestId = originData?.data?.class_user_cancel_request?.id
  const [popup, setPopup] = useState(false)
  const [cancelPopup, setCancelPopup] = useState(false)
  const [detailPopup, setDetailPopup] = useState(false)
  const [checkPopup, setCheckPopup] = useState(false)
  const [reason, setReason] = useState('')
  const { user } = useUser()
  const typeUser = user && user.type
  const [detailData, setDetailData] = useState({
    date: '',
    price: '',
    name: '',
    bank: '',
    account: '',
  })
  const [popupData, setPopupData] = useState({
    popupType: '',
    title: '',
    content: '',
    btn: '',
  })
  useEffect(() => {
    if (popup || cancelPopup || detailPopup || checkPopup) {
      document.documentElement.style.overflow = 'hidden'
    } else {
      document.documentElement.style.overflow = 'auto'
    }

    return () => {
      document.documentElement.style.overflow = 'auto'
    }
  }, [popup, cancelPopup, detailPopup, checkPopup])

  const handleCancelClick = () => {
    if (typeUser === 'tutor') {
      if (originData?.data?.is_multi === 0) {
        setPopupData({
          popupType: 'cancel',
          title: '결제 취소',
          content: tutorPaymentCancel,
          btn: '결제 취소',
        })
      } else {
        setPopupData({
          popupType: 'cancel',
          title: '결제 취소',
          content: '남은 회차의 결제를 모두 취소하시겠습니까?',
          btn: '결제 취소',
        })
      }
      setCancelPopup(true)
    } else {
      setPopup(true)
    }
  }
  const handleClose = () => {
    setPopup(false)
  }
  const handleBackClick = (e: any) => {
    if (e.target.id === 'popup') {
      if (popup) setPopup(false)
    }
  }
  const onCancelClick = async () => {
    const id = originData.data.payment_history.class_user_id
    setCheckPopup(false)
    const formdata = new FormData()
    formdata.append('class_user_id', id)
    formdata.append('reason', reason)
    const {
      data: { success, alert },
    } = await api.post('/class/cancel/request', formdata)

    if (success) {
      setTimeout(() => {
        window.location.reload()
      }, 130)
    } else {
      window.alert(alert)
    }
  }
  const handleCancelSubmit = async () => {
    setCancelPopup(false)
    const classId = originData.data.payment_history.id

    if (!classId) return

    const formdata = new FormData()
    formdata.append('payment_history_id', classId)
    const {
      data: { success, alert },
    } = await api.post('/class/cancel/payment', formdata)

    if (success) {
      setTimeout(() => {
        window.location.reload()
      }, 130)
    } else {
      window.alert(alert)
    }
  }
  const handlePaymentClick = (data: any) => {
    if (!data) return

    setDetailData({
      ...detailData,
      date: data.setDetailData,
      price: data.calculate_price,
      name: data.calculate_bank_holder,
      bank: data.calculate_bank_name,
      account: data.calculate_bank_account,
    })
    setDetailPopup(true)
  }

  // 취소 신청 상세 > 취소신청 반려
  const handleCancelReject = () => {
    setPopupData({
      popupType: 'reject',
      title: '취소 신청 반려',
      content: '수강생의 취소 신청을 반려하시겠습니까?',
      btn: '반려',
    })
    setCancelPopup(true)
  }
  const handleCancelRejectSubmit = async () => {
    const formdata = new FormData()
    formdata.append('class_user_cancel_request_id', requestId)
    const {
      data: { success, alert },
    } = await api.post('/class/cancel/request/reject', formdata)

    if (success) {
      setTimeout(() => {
        window.location.href = '/payment?type=tutor_price_cancel&role=tutor'
      }, 130)
    } else {
      window.alert(alert)
    }
    setCancelPopup(false)
  }
  // 취소 신청 상세 > 취소신청 승인
  const handleCancelAccept = () => {
    setPopupData({
      popupType: 'accept',
      title: '취소 신청 승인',
      content:
        '승인 시 수강 취소 및 결제 금액이 환불됩니다.\n선택한 취소 신청을 승인하시겠습니까?',
      btn: '승인',
    })
    setCancelPopup(true)
  }
  const handleCancelAcceptSubmit = async () => {
    const formdata = new FormData()
    formdata.append('class_user_cancel_request_id', requestId)
    const {
      data: { success, alert },
    } = await api.post('/class/cancel/request/accept', formdata)

    if (success) {
      setTimeout(() => {
        window.location.reload()
      }, 130)
    } else {
      window.alert(alert)
    }
    setCancelPopup(false)
  }

  if (!originData) return null
  if (!originData.data) return null

  return (
    <Wrapper>
      {typeUser === 'tutor' ? <TutorHeader /> : <Header />}
      <MobileTitleHeader type="arrow" title="결제 내역 상세" />
      <Content>
        <PaymentDetailComponent
          data={originData.data}
          handleCancelClick={handleCancelClick}
          pageType={typeUser}
          handlePaymentClick={handlePaymentClick}
          handleCancelReject={handleCancelReject}
          handleCancelAccept={handleCancelAccept}
        />
      </Content>
      {popup && (
        <ClassCancelPopup
          value={reason}
          onChange={(e) => {
            setReason(e.target.value)
          }}
          handleClose={handleClose}
          handleBackClick={handleBackClick}
          onCancelClick={() => {
            setPopup(false)
            setCheckPopup(true)
          }}
        />
      )}
      {cancelPopup && (
        <TwoCheckPopup
          title={popupData.title}
          content={popupData.content}
          oncancelClick={() => {
            setCancelPopup(false)
          }}
          onClick={() => {
            if (popupData.popupType === 'reject') {
              handleCancelRejectSubmit()
            } else if (popupData.popupType === 'accept') {
              handleCancelAcceptSubmit()
            } else {
              handleCancelSubmit()
            }
          }}
          btn={popupData.btn}
        />
      )}
      {detailPopup && (
        <BasicPopup
          title="정산 정보"
          onClick={() => {
            setDetailPopup(false)
          }}
          data={detailData}
        />
      )}
      {checkPopup && (
        <CheckPopup
          onClick={onCancelClick}
          title="수강취소 신청 완료"
          content={CancelContents}
        />
      )}
      <Footer />
    </Wrapper>
  )
}

export default MyPaymentDetailpage
const Wrapper = styled.div``
const Content = styled.div`
  margin-top: 100px;
  margin-bottom: 120px;
  ${media.medium} {
    margin-top: 0;
    margin-bottom: 80px;
  }
`
