import api from '.'

export interface DetailIntroduceType {
  title: string
  contents: string
}

export interface TutorDetailType {
  id: number
  nickname: string
  name: string
  profile_url: string
  introduce: string
  career_url: string
  detail_introduces: DetailIntroduceType[]
}

interface GetTutorDetailRequest {
  success: boolean
  alert: string
  data: {
    tutor: TutorDetailType
  }
}

export async function getTutorDetailRequest(tutorId: string) {
  const { data } = await api.get<GetTutorDetailRequest>('/tutor/detail', {
    params: {
      id: tutorId,
    },
  })

  return data
}

export interface CategoryType {
  category: string
}

export interface TutorClassType {
  id: number
  title: string
  image_url: string
  begin_date: string
  price: number
  is_my_like: 'Y' | 'N'
  categories: CategoryType[]
  tutor_score: string | number
  status: number
}

export interface GetTutorClassListRequest {
  success: boolean
  alert: string
  data: {
    classes: {
      current_page: number
      last_page: number
      per_page: number
      data: TutorClassType[]
    }
  }
}

export async function getTutorClassListRequest({
  tutorId,
  page,
}: {
  tutorId: string
  page: number
}) {
  const { data } = await api.get<GetTutorClassListRequest>('/tutor/class', {
    params: {
      id: tutorId,
      page,
    },
  })

  return data
}

export interface ReviewType {
  id: number
  grade: number
  contents: string
  profile_url: string | null
  name: string
  created_at: string
  title: string
  image_url: string
}

interface GetTutorReviewListRequest {
  success: boolean
  alert: string
  data: {
    count: number
    tutor_score: string
    reviews: {
      per_page: number
      current_page: number
      last_page: number
      data: ReviewType[]
    }
  }
}

export async function getTutorReviewListRequest({
  tutorId,
  page,
}: {
  tutorId: string
  page: number
}) {
  const { data } = await api.get<GetTutorReviewListRequest>('/tutor/review', {
    params: {
      id: tutorId,
      page,
    },
  })

  return data
}
