import media from 'lib/styles/media'
import React from 'react'
import styled from 'styled-components'

interface MyClassTitleProps {
  mainTitle: string
  subTitle: string
}

function MyClassTitle({ mainTitle, subTitle }: MyClassTitleProps) {
  return (
    <Wrapper>
      <p className="main_title">{mainTitle}</p>
      <p className="sub_title">{subTitle}</p>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding-top: 50px;
  ${media.medium} {
    padding-top: 0;
  }
  & p.main_title {
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: #0d0d0e;
    ${media.medium} {
      font-size: 18px;
      line-height: 28px;
    }
  }
  & p.sub_title {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #3b3b40;
  }
`

export default MyClassTitle
