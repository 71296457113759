import api from 'lib/api'
import useCategory from 'lib/hooks/useCategory'
import useLike from 'lib/hooks/useLike'
import useMain from 'lib/hooks/useMain'
import useSearch from 'lib/hooks/useSearch'
import media from 'lib/styles/media'
import { PriceNum } from 'lib/utils/CommonFuncttion'
import moment from 'moment'
import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { ReactComponent as ScopeIcon } from '../../assets/icons/class_info_scope_icon.svg'
import { ReactComponent as LikeIcon } from '../../assets/icons/like_icon.svg'
import { ReactComponent as LikePullIcon } from '../../assets/icons/like_pull_icon.svg'
import { ReactComponent as NewClassIcon } from '../../assets/icons/new_class_icon.svg'
import { ReactComponent as NoneImage } from '../../assets/icons/none_profile_icon.svg'
import ToastPopup from './ToastPopup'
import useUser from 'lib/hooks/account/useUser'

interface Props {
  Image: string
  Profile: string
  Title: string
  Nickname: string
  Score: string
  Price: number
  like: string
  date: string
  Category: Array<{ id: number; category: string }>
  id: number
  type: string
  available?: string
  tutorId: number
  status?: number
}
function SmallClassInfoComponent({
  Image,
  Profile,
  Title,
  Nickname,
  Score,
  Category,
  Price,
  like,
  date,
  id,
  type,
  available,
  tutorId,
  status,
}: Props) {
  const { user } = useUser()
  const params = useParams<{ search: string }>()
  const searchData = params.search
  const studentUser = user && user.type === 'user'
  const categoryparams = useParams<{ categoryId: string }>()
  const categoryId = categoryparams.categoryId
  const [massage, setMassage] = useState(false)
  const [noneMassage, setNoneMassage] = useState(false)
  const { data: likeData, mutate: LikeMutate } = useLike({ type })
  const { data: search, mutate: searchMutate } = useSearch({
    searchData,
    available,
  })
  const { data: category, mutate: categoryMutate } = useCategory({
    categoryId,
    available,
    studentUser,
  })
  const diff = Math.floor(moment.duration(moment().diff(moment(date))).asDays())
  const diffDate = String(diff).replaceAll('-', '')
  const history = useHistory()

  const Like = (like: string) => {
    if (like === 'N') {
      setMassage(true)
    } else {
      setMassage(false)
    }
  }

  const NoneLike = (like: string) => {
    if (like === 'Y') {
      setNoneMassage(true)
    } else {
      setNoneMassage(false)
    }
  }

  const handleImageClick = (e: any) => {
    e.stopPropagation()
    history.push('/class/' + id)
  }
  const handleLikeClick = async (e: any) => {
    e.stopPropagation()
    if (!studentUser) return history.push('/login')

    try {
      const formData = new FormData()
      formData.append('class_id', String(id))
      const {
        data: { success, alert },
      } = await api.post('/class/like', formData)
      if (success) {
        if (type === 'search') {
          searchMutate()
          Like(like)
        } else if (type === 'category') {
          categoryMutate()
          Like(like)
        } else {
          LikeMutate()
          NoneLike(like)
        }
      } else {
        window.alert(alert)
      }
    } catch (err) {}
  }

  return (
    <Wrapper>
      <div
        className="class_info_box"
        id="image"
        onClick={(e) => {
          handleImageClick(e)
        }}
      >
        {status !== 2 && (
          <div className="dday_icon">
            <p>D{diff > 0 ? `+${diffDate}` : `-${diffDate}`}</p>
            <NewClassIcon className="dday_image" />
          </div>
        )}
        {status === 2 && (
          <EndClassContent>
            <p>종료된 강의입니다.</p>
          </EndClassContent>
        )}
        {Image === '' ? (
          <Div className="none_image_Box" />
        ) : (
          <img
            src={Image}
            alt="class_image"
            className="class_image"
            id="class"
          />
        )}

        {like === 'N' ? (
          <LikeIcon
            className="like_icon"
            onClick={(e) => {
              handleLikeClick(e)
            }}
          />
        ) : (
          <LikePullIcon
            className="like_icon"
            onClick={(e) => {
              handleLikeClick(e)
            }}
          />
        )}
      </div>
      <WebClssList>
        <div className="profille">
          <div
            className="left_profile"
            onClick={() => history.push(`/tutor/detail/${tutorId}`)}
          >
            {Profile ? <img src={Profile} alt="profile" /> : <NoneImage />}
          </div>
          <div
            className="right_profile"
            onClick={() => history.push('/class/' + id)}
          >
            <p className="class_title">{Title}</p>
            <p className="class_price">
              {Price === 0 ? '무료 강의' : `${PriceNum(Price)}원`}
            </p>
            <div className="nick_content">
              <p className="nick_name">{Nickname}</p>
              <ScopeIcon className="scope_icon" />
              <p className="scope">{Score}</p>
            </div>
            <div className="category_content">
              <p className="category">
                {Category.map((item, i) => (
                  <span
                    className={
                      Category.length === 1
                        ? 'category_span none'
                        : 'category_span'
                    }
                    key={`main_ing_class_category_list_web_${i}`}
                  >
                    {item.category} <span className="dot" />
                  </span>
                ))}
              </p>
            </div>
          </div>
        </div>
      </WebClssList>
      <MobileClassList>
        <div className="profille">
          <div
            className="right_profile"
            onClick={() => history.push('/class/' + id)}
          >
            <p className="class_title">{Title}</p>
            <p className="class_price">{PriceNum(Price)}원</p>
            <div className="nick_content">
              <img src={Profile} className="mobile_profile" />
              <p className="nick_name">{Nickname}</p>
              <ScopeIcon className="scope_icon" />
              <p className="scope">{Score}</p>
            </div>
            <div className="category_content">
              <p className="category">
                {Category.map((item, i) => (
                  <span
                    className={
                      Category.length === 1
                        ? 'category_span none'
                        : 'category_span'
                    }
                    key={`main_ing_class_category_list_mobile_${i}`}
                  >
                    {item.category} <span className="dot" />
                  </span>
                ))}
              </p>
            </div>
          </div>
        </div>
      </MobileClassList>

      {massage && <ToastPopup title="찜한 강의에 추가되었어요!" />}
      {noneMassage && <ToastPopup title="찜한 강의에서 제외되었어요." />}
    </Wrapper>
  )
}
export default SmallClassInfoComponent

const Wrapper = styled.div`
  margin-right: 20px;
  width: 285px;
  ${media.medium} {
    width: 160px;
    min-width: 160px;
    height: 220px;
    margin-right: 12px;
  }
  cursor: pointer;
  & .class_info_box {
    position: relative;
  }
  & .dday_icon {
    width: 43px;
    height: 45px;
    position: absolute;
    left: 16px;
    ${media.medium} {
      width: 32px;
      height: 345px;
      left: 12px;
    }
    & > p {
      width: 43px;
      position: absolute;
      left: 0;
      top: 8px;
      font-weight: 700;
      font-size: 13px;
      line-height: 22px;
      text-align: center;
      color: #ffffff;
      z-index: 1;
      ${media.medium} {
        width: 32px;
        font-size: 11px;
        line-height: 18px;
        left: 0;
        top: 5px;
      }
    }
  }
  & .dday_image {
    position: absolute;
    left: 0;
    top: 0;
    ${media.medium} {
      width: 32px;
      height: 35px;
    }
  }
  & .block {
    width: 285px;
    min-width: 285px;
    height: 230px;
    min-height: 160px;
    border-radius: 14px;
    background: gray;
  }
  & .class_image {
    width: 285px;
    min-width: 285px;
    height: 160px;
    min-height: 160px;
    border-radius: 14px;
    ${media.medium} {
      width: 160px;
      min-width: 160px;
      height: 90px;
      min-height: 90px;
      border-radius: 6px;
    }
  }
  & .like_icon {
    position: absolute;
    right: 15px;
    bottom: 15px;
    ${media.medium} {
      right: 12px;
      bottom: 12px;
      width: 18px;
      height: 16px;
    }
  }
  & .profille {
    display: flex;
    align-items: flex-start;
    margin-top: 12px;
    ${media.medium} {
      margin-top: 8px;
    }
  }
  & .left_profile {
    width: 32px;
    min-width: 32px;
    height: 32px;
    min-height: 32px;
    border-radius: 100%;
    overflow: hidden;
    margin-right: 10px;
    ${media.medium} {
      width: 20px;
      min-width: 20px;
      height: 20px;
      min-height: 20px;
      margin-right: 6px;
    }
    & > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    & > svg {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  & .class_title {
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: #3b3b40;
    overflow: hidden;
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    ${media.medium} {
      font-size: 13px;
      line-height: 22px;
    }
  }
  & .class_price {
    font-weight: 900;
    font-size: 16px;
    line-height: 26px;
    color: #0d0d0e;
    margin: 3px 0;
    ${media.medium} {
      font-size: 14px;
      line-height: 24px;
      margin: 0;
      margin-right: 2px;
    }
  }
  & .nick_content {
    display: flex;
    align-items: center;
    margin: 3px 0;
    ${media.medium} {
      margin: 0;
    }
  }
  & .mobile_profile {
    width: 32px;
    min-width: 32px;
    height: 32px;
    min-height: 32px;
    border-radius: 100%;
    overflow: hidden;
    margin-right: 10px;
    ${media.medium} {
      width: 18px;
      min-width: 18px;
      height: 18px;
      min-height: 18px;
      border-radius: 100%;
      margin-right: 6px;
    }
  }
  & .nick_name {
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    color: #3b3b40;
    ${media.medium} {
      font-size: 12px;
    }
  }
  & .scope_icon {
    margin: 0 4px 0 6px;
  }
  & .scope {
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    color: #7c7c80;
  }
  & .category_content {
    display: flex;
    align-items: center;
  }
  & .category {
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    color: #b5b5b9;
    display: flex;
    align-items: center;
    min-width: 100%;
    width: 100%;
    overflow: hidden;
    word-break: break-word;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  & .category_span {
    width: 100%;
    min-width: fit-content;
    position: relative;
    margin-right: 8px;
    &.none {
      & span.dot {
        display: none;
      }
    }
    & span.dot {
      position: absolute;
      right: -4px;
      top: 50%;
      transform: translateY(-50%);
      width: 2px;
      height: 2px;
      border-radius: 100%;
      background: #b5b5b9;
    }
  }
`
const EndClassContent = styled.div`
  position: absolute;
  left: 0;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(5px);
  border-radius: 8px;
  width: 100%;
  height: 100%;
  & > p {
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    color: rgba(255, 255, 255, 0.8);
  }
`
const Div = styled.div`
  width: 285px;
  min-width: 285px;
  height: 160px;
  min-height: 160px;
  border-radius: 14px;
  background: rgb(238, 239, 241);

  ${media.medium} {
    width: 160px;
    min-width: 160px;
    height: 90px;
    min-height: 90px;
    border-radius: 6px;
  }
`
const WebClssList = styled.div`
  ${media.medium} {
    display: none;
  }
`
const MobileClassList = styled.div`
  display: none;
  ${media.medium} {
    display: flex;
  }
`
