import { AppRootWrapper } from 'components/layout/CommonStyle'
import api from 'lib/api'
import useTutorClass from 'lib/hooks/useTutorClass'
import media from 'lib/styles/media'
import type {
  ToturClassDataProps,
  ToturHotClassDataProps,
} from 'lib/types/account'
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import useSWR from 'swr'
import HotClassInfoComponent from '../HotClassInfoComponent'

function TutorClassComponent({ data }: ToturClassDataProps) {
  const [tutorId, setTutorId] = useState('')
  const { data: tutorData } = useTutorClass({ tutorId })
  const tutor = tutorData && tutorData.data

  useEffect(() => {
    if (!data) return
    const id = data?.[0]?.id
    setTutorId(String(id))
  }, [data])

  return (
    <Wrapper>
      <p className="main_new_title">
        지금 제일 인기 있는
        <br />
        튜터의 강의를 만나보세요!
      </p>

      <Content>
        <LeftContent>
          <div className="left_tutor">
            {data.map((item, i) => (
              <div
                key={`main_tutor_class_list_${i}`}
                className={
                  tutorId === String(item.id)
                    ? 'left_tutor_content active'
                    : 'left_tutor_content'
                }
                onClick={() => {
                  setTutorId(String(item.id))
                }}
              >
                {item.profile_url ? (
                  <img src={item.profile_url} alt="tutor_image" />
                ) : (
                  <Div />
                )}

                <p
                  className={
                    tutorId === String(item.id)
                      ? 'left_tutor_content_nickname active'
                      : 'left_tutor_content_nickname'
                  }
                >
                  {item.nickname}
                </p>
              </div>
            ))}
          </div>
        </LeftContent>
        <RightContent>
          {tutor &&
            tutor.classes.map((item: ToturHotClassDataProps, i: number) => (
              <HotClassInfoComponent
                key={`main_hot_tutor_class_list_${i}`}
                Image={item.image_url}
                Title={item.title}
                Category={item.categories}
                Price={item.price}
                like={item.is_my_like}
                date={item.begin_date}
                id={item.id}
                tutorId={tutorId}
              />
            ))}
        </RightContent>
      </Content>
    </Wrapper>
  )
}
export default TutorClassComponent

const Wrapper = styled(AppRootWrapper)`
  margin: 85px auto 120px;
  ${media.medium} {
    margin: 65px auto 80px;
  }
  & .main_new_title {
    font-weight: 700;
    font-size: 22px;
    line-height: 34px;
    color: #0d0d0e;
    ${media.medium} {
      font-size: 16px;
      line-height: 26px;
    }
  }
`

const Content = styled.div`
  margin-top: 32px;
  display: flex;
  align-items: flex-start;
  ${media.medium} {
    flex-direction: column;
  }
`
const LeftContent = styled.div`
  width: 285px;
  height: 250px;
  margin-right: 20px;
  ${media.medium} {
    width: 100%;
    height: 40px;
    margin-right: 8px;
  }
  & .left_tutor {
    ${media.medium} {
      display: flex;
      width: 100%;
      overflow: auto;
    }
    & > div {
      margin-bottom: 10px;
      ${media.medium} {
        margin-bottom: 0;
        margin-right: 8px;
      }
    }
    & > div:last-of-type {
      margin-bottom: 0;
      ${media.medium} {
        margin-right: 0;
      }
    }
  }
  & .left_tutor_content {
    width: fit-content;
    min-width: fit-content;
    height: 50px;
    border-radius: 100px;
    padding: 8px 24px 8px 12px;
    display: flex;
    align-items: center;
    background: #ffffff;
    border: 1px solid #e1e1e2;
    ${media.medium} {
      width: fit-content;
      padding: 10px 8px;
      height: 40px;
    }

    &.active {
      background: #57569e;
    }
    & > img {
      width: 34px;
      height: 34px;
      border-radius: 100%;
      ${media.medium} {
        width: 24px;
        height: 24px;
        min-width: 24px;
        min-height: 24px;
      }
    }
  }
  & .left_tutor_content_nickname {
    margin-left: 14px;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: #3b3b40;
    cursor: pointer;
    &.active {
      font-weight: 700;
      color: #ffffff;
    }
    ${media.medium} {
      font-size: 14px;
      line-height: 24px;
      margin-left: 8px;
    }
  }
`
const RightContent = styled.div`
  flex: 1;
  display: flex;
  overflow: hidden;
  flex-wrap: wrap;
  & > div:nth-of-type(3) {
    margin-right: 0;
    ${media.medium} {
      margin-right: 12px;
    }
  }
  ${media.medium} {
    margin-top: 18px;
    flex-wrap: nowrap;
    overflow-x: scroll;
    width: calc(100% + 20px);
  }
`
const Div = styled.div`
  width: 34px;
  height: 34px;
  border-radius: 100%;
  background: rgb(238, 239, 241);
  ${media.medium} {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
  }
`
