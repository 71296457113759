import { Collapse } from 'antd'
import React from 'react'
import styled from 'styled-components'
import { ReactComponent as PlusIcon } from '../../../assets/icons/feedback_review_plus_icon.svg'
import { ReactComponent as MinusIcon } from '../../../assets/icons/feedback_review_minus_icon.svg'
import media from 'lib/styles/media'

function TutorFifthContent() {
  const { Panel } = Collapse
  const data = [
    {
      title: 'FAQ 제목을 노출합니다.',
      content:
        '내용을 해당 위치에 노출합니다.\n영역은 자동으로 늘어납니다. 멀듯이, 소학교 청춘이 딴은 시인의 헤일 이름자 하나에 잠, 듯합니다. 아침이 이름을 가난한 이름과, 강아지, 잠, 많은 벌써 속의 계십니다. 새워 추억과 벌써 때 멀듯이, 하나에 북간도에 별이 별 봅니다. 오는 너무나 청춘이 북간도에 때 별 이름을 어머님, 까닭입니다. 별 내 라이너 청춘이 쉬이 한 못 별 나의 계십니다.',
    },
    {
      title: 'FAQ 제목을 노출합니다.',
      content:
        '내용을 해당 위치에 노출합니다.\n영역은 자동으로 늘어납니다. 멀듯이, 소학교 청춘이 딴은 시인의 헤일 이름자 하나에 잠, 듯합니다. 아침이 이름을 가난한 이름과, 강아지, 잠, 많은 벌써 속의 계십니다. 새워 추억과 벌써 때 멀듯이, 하나에 북간도에 별이 별 봅니다. 오는 너무나 청춘이 북간도에 때 별 이름을 어머님, 까닭입니다. 별 내 라이너 청춘이 쉬이 한 못 별 나의 계십니다.',
    },
    {
      title: 'FAQ 제목을 노출합니다.',
      content:
        '내용을 해당 위치에 노출합니다.\n영역은 자동으로 늘어납니다. 멀듯이, 소학교 청춘이 딴은 시인의 헤일 이름자 하나에 잠, 듯합니다. 아침이 이름을 가난한 이름과, 강아지, 잠, 많은 벌써 속의 계십니다. 새워 추억과 벌써 때 멀듯이, 하나에 북간도에 별이 별 봅니다. 오는 너무나 청춘이 북간도에 때 별 이름을 어머님, 까닭입니다. 별 내 라이너 청춘이 쉬이 한 못 별 나의 계십니다.',
    },
    {
      title: 'FAQ 제목을 노출합니다.',
      content:
        '내용을 해당 위치에 노출합니다.\n영역은 자동으로 늘어납니다. 멀듯이, 소학교 청춘이 딴은 시인의 헤일 이름자 하나에 잠, 듯합니다. 아침이 이름을 가난한 이름과, 강아지, 잠, 많은 벌써 속의 계십니다. 새워 추억과 벌써 때 멀듯이, 하나에 북간도에 별이 별 봅니다. 오는 너무나 청춘이 북간도에 때 별 이름을 어머님, 까닭입니다. 별 내 라이너 청춘이 쉬이 한 못 별 나의 계십니다.',
    },
    {
      title: 'FAQ 제목을 노출합니다.',
      content:
        '내용을 해당 위치에 노출합니다.\n영역은 자동으로 늘어납니다. 멀듯이, 소학교 청춘이 딴은 시인의 헤일 이름자 하나에 잠, 듯합니다. 아침이 이름을 가난한 이름과, 강아지, 잠, 많은 벌써 속의 계십니다. 새워 추억과 벌써 때 멀듯이, 하나에 북간도에 별이 별 봅니다. 오는 너무나 청춘이 북간도에 때 별 이름을 어머님, 까닭입니다. 별 내 라이너 청춘이 쉬이 한 못 별 나의 계십니다.',
    },
  ]

  const handleHeader = (item: any) => {
    return (
      <div className="fifth_text_box">
        <p className="fifth_title">{item.title}</p>
      </div>
    )
  }

  return (
    <Wrapper>
      <ContentBox>
        <Title>디베이트온 튜터 등록 관련 FAQ</Title>
        <MobileTitle>
          디베이트온
          <br />
          튜터 등록 관련 FAQ
        </MobileTitle>
        <Content>
          <Collapse
            accordion
            expandIcon={({ isActive }) =>
              isActive ? <MinusIcon /> : <PlusIcon />
            }
            className="fifth_content"
          >
            {data.map((item, i) => (
              <Panel
                key={String(i) + 1}
                header={handleHeader(item)}
                className="fifth_content_box"
              >
                <div className="fifth_text_box">
                  <p className="fifth_comment">{item.content}</p>
                </div>
              </Panel>
            ))}
          </Collapse>
        </Content>
        <NoticeContent>
          <p>이 외에 궁금한 내용은 고객센터를 통해 문의 바랍니다.</p>
        </NoticeContent>
      </ContentBox>
    </Wrapper>
  )
}

export default TutorFifthContent
const Wrapper = styled.div`
  width: 100%;
  height: 1005px;
  background: #ffffff;
  padding: 120px 0 130px;
  ${media.medium} {
    height: 100%;
    padding: 52px 20px 80px;
  }
`
const ContentBox = styled.div`
  width: 1120px;
  margin: 0 auto;
  ${media.medium} {
    width: 100%;
  }
`
const MobileTitle = styled.p`
  display: none;
  ${media.medium} {
    display: block;
    font-size: 18px;
    line-height: 28px;
    font-weight: 700;
    text-align: center;
    color: #0d0d0e;
  }
`
const Title = styled.p`
  font-weight: 700;
  font-size: 30px;
  line-height: 50px;
  text-align: center;
  color: #0d0d0e;
  ${media.medium} {
    display: none;
  }
`
const Content = styled.div`
  margin: 60px 0;
  ${media.medium} {
    margin: 36px 0;
  }
  & div.fifth_content {
    width: 100%;
    min-height: 74px;
    background: #ffffff;
    border: none;
    ${media.medium} {
      min-height: 100%;
    }
    & > div {
      margin-bottom: 20px;
      ${media.medium} {
        margin-bottom: 0;
      }
    }
    & > div:last-of-type {
      border-radius: 15px;
      ${media.medium} {
        border-radius: 0;
      }
    }
  }
  & div.fifth_content_box {
    width: 100%;
    min-height: 74px;
    border: none;
    padding: 24px 20px 0;
    border: 1px solid #e1e1e2;
    border-radius: 15px;
    ${media.medium} {
      min-height: 56px;
      border: none;
      border-radius: 0;
      border-bottom: 1px solid #e1e1e2;
      padding: 16px 0 0;
    }
  }
  & p.fifth_title {
    font-weight: 700;
    font-size: 16px;
    line-height: 26px;
    color: #0d0d0e;
    ${media.medium} {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
    }
  }
  & p.fifth_comment {
    padding: 24px 0;
    white-space: break-spaces;
    ${media.medium} {
      padding: 16px 0;
    }
  }
  & .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
  }
  & .ant-collapse-item > .ant-collapse-header {
    align-items: center;
    flex-direction: row-reverse;
    padding: 0;
    padding-bottom: 24px;
    ${media.medium} {
      padding: 0;
      padding-bottom: 16px;
    }
  }
`
const NoticeContent = styled.div`
  width: 100%;
  height: 46px;
  background: #f9f9fb;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  & > p {
    font-weight: 400;
    font-size: 13px;
    line-height: 22px;
    color: #7c7c80;
  }
`
