import media from 'lib/styles/media'
import {
  dateDiff,
  dateHyphenFormet,
  timeFormet,
} from 'lib/utils/CommonFuncttion'
import React from 'react'
import styled from 'styled-components'

interface Props {
  image: string
  title: string
  nickname: string
  beginDate: string
  endDate: string
  pageType: string
  studentName: string
  time: string | number
}
function PaymenClasstInfoComponent({
  image,
  title,
  nickname,
  beginDate,
  endDate,
  pageType,
  studentName,
  time,
}: Props) {
  return (
    <Wrapper>
      <p className="content_title">강의 정보</p>
      <div className="info_content_box">
        {image ? (
          <img src={image} className="info_image" alt="image" />
        ) : (
          <Div />
        )}
        <div className="info_text_box">
          <p className="class_title">{title}</p>
          {pageType === 'tutor' ? (
            <p className="class_nickname">{studentName}</p>
          ) : (
            <p className="class_nickname">{nickname}</p>
          )}

          {!endDate ? (
            <p className="class_date">
              {dateHyphenFormet(beginDate)} {time ? `~ (약 ${time}분)` : ''}
            </p>
          ) : (
            <p className="class_date">
              {dateHyphenFormet(beginDate)} ~ {timeFormet(endDate)}{' '}
              {dateDiff(beginDate, endDate)}
            </p>
          )}
        </div>
      </div>
    </Wrapper>
  )
}

export default PaymenClasstInfoComponent
const Wrapper = styled.div`
  margin-bottom: 20px;
  ${media.medium} {
    margin-bottom: 10px;
  }
  & p.content_title {
    font-weight: 500;
    font-size: 17px;
    line-height: 26px;
    color: #0d0d0e;
    ${media.medium} {
      font-size: 16px;
    }
  }
  & div.info_content_box {
    width: 100%;
    min-height: 158px;
    margin-top: 10px;
    background: #ffffff;
    border: 1px solid #e1e1e2;
    border-radius: 14px;
    padding: 20px;
    display: flex;
    align-items: center;
    ${media.medium} {
      flex-direction: column;
      align-items: baseline;
    }
  }
  & img.info_image {
    width: 210px;
    min-width: 210px;
    height: 118px;
    border-radius: 8px;
    ${media.medium} {
      width: 90px;
      min-width: 90px;
      height: 51px;
    }
  }
  & div.info_text_box {
    margin-left: 20px;
    ${media.medium} {
      margin-left: 0;
      margin-top: 12px;
    }
  }
  & p.class_title {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    color: #3b3b40;
    ${media.medium} {
      font-size: 14px;
      line-height: 24px;
    }
  }
  & p.class_nickname {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #7c7c80;
    margin: 6px 0 10px;
    ${media.medium} {
      font-size: 13px;
      line-height: 22px;
      margin: 1px 0 4px;
    }
  }
  & p.class_date {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #0d0d0e;
    ${media.medium} {
      font-size: 13px;
      line-height: 22px;
    }
  }
`
const Div = styled.div`
  width: 210px;
  min-width: 210px;
  height: 118px;
  border-radius: 8px;
  background: rgb(249, 249, 251);
  ${media.medium} {
    width: 90px;
    min-width: 90px;
    height: 51px;
  }
`
