import RoomSideTab from 'components/room/side/RoomSideTab'
import StudentRoomSideFooter from 'components/room/side/StudentRoomSideFooter'
import TutorRoomSideFooter from 'components/room/side/TutorRoomSideFooter'
import { type RoomRoleType } from 'lib/types/room'
import React from 'react'
import { RoomSideContainerWrapper } from './RoomTutorSideContainer'
import ReferenceContainer from './side/ReferenceContainer'

function TeamRoomSideContainer({ role }: { role: RoomRoleType }) {
  return (
    <RoomSideContainerWrapper>
      <RoomSideTab isTeamRoom selectedTab="reference" />
      <ReferenceContainer />
      {role === 'tutor' && <TutorRoomSideFooter isTeamRoom />}
      {role === 'student' && <StudentRoomSideFooter />}
    </RoomSideContainerWrapper>
  )
}

export default TeamRoomSideContainer
