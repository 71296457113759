import { AppRootWrapper } from 'components/layout/CommonStyle'
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Input from '../Input'
import Button from '../Button'
import api from 'lib/api'
import {
  ErrorPwCheckMessage,
  ErrorPwMessage,
  resId,
  resPw,
} from 'lib/utils/CommonFuncttion'
import media from 'lib/styles/media'
import { useRecoilState } from 'recoil'
import { JoinStepPayloadState } from 'atoms/join'
import { useHistory } from 'react-router-dom'
import PasswordInput from '../PasswordInput'

function JoinInfoComponent() {
  const [payload, setPayload] = useRecoilState(JoinStepPayloadState)
  const [error, setError] = useState(false)
  const [errorPw, setErrorPw] = useState(false)
  const [success, setSuccess] = useState(false)
  const [message, setMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [errorPwMessage, setErrorPwMessage] = useState('')
  const history = useHistory()

  const handleCheckPassword = () => {
    if (!payload?.password) {
      return setError(false)
    }

    if (!payload?.checkPassword) {
      if (!resPw.test(String(payload?.password))) {
        setErrorPw(true)
        setErrorPwMessage(ErrorPwMessage)
      } else {
        setErrorPw(false)
      }
    } else {
      if (payload?.password === payload?.checkPassword) {
        if (!resPw.test(String(payload?.password))) {
          setErrorPw(true)
          setErrorPwMessage(ErrorPwMessage)
        } else {
          setErrorPw(false)
        }
      } else {
        if (!resPw.test(String(payload?.password))) {
          setErrorPw(true)
          setErrorPwMessage(ErrorPwCheckMessage)
        } else {
          setErrorPw(true)
          setErrorPwMessage('비밀번호가 일치하지 않습니다.')
        }
      }
    }

    if (payload?.password === payload?.checkPassword) {
      if (!resPw.test(String(payload?.password))) {
        setErrorPw(true)
        setErrorPwMessage(
          `영문, 숫자, 특수문자 조합 8-20자로 설정하실 수 있습니다.`,
        )
      } else {
        setErrorPw(false)
      }
    } else {
      if (!resPw.test(String(payload?.password))) {
        setErrorPw(true)
        setErrorPwMessage(
          `영문, 숫자, 특수문자 조합 8-20자로 설정하실 수 있습니다.\n비밀번호가 일치하지 않습니다.`,
        )
      } else {
        setErrorPw(true)
        setErrorPwMessage('비밀번호가 일치하지 않습니다.')
      }
    }
  }

  const handleChange = (type: string) => (e: any) => {
    setPayload({
      ...payload,
      [type]: e.target.value,
    })
    if (type !== 'id') {
      handleCheckPassword()
    }
  }
  const handleIdCheckClick = async () => {
    if (String(payload?.id).length >= 8) {
      if (resId.test(String(payload?.id))) {
        const formData = new FormData()
        formData.append('type', 'user')
        formData.append('email', String(payload?.id))
        const {
          data: { success, alert },
        } = await api.post('/member/email/check', formData)
        if (success) {
          setError(false)
          setSuccess(true)
          setMessage('사용 가능한 아이디입니다.')
        } else {
          setSuccess(false)
          if (alert === '이미 사용중인 아이디입니다.') {
            setError(true)
            setErrorMessage('중복 아이디입니다.')
          } else {
            window.alert(alert)
          }
        }
      } else {
        setSuccess(false)
        setError(true)
        return setErrorMessage('영문, 숫자 조합으로 설정하실 수 있습니다.')
      }
    }
  }

  return (
    <Wrapper>
      <Content>
        <div className="title_box">
          <p className="join_info_title">로그인 정보 설정</p>
          <p className="join_info_text">
            로그인에 사용할 아이디와 비밀번호를 설정해주세요.
          </p>
        </div>
        <div className="info_content_box">
          <Input
            type="text"
            title="아이디"
            placeholder="영문, 숫자 포함 8-20자"
            value={payload?.id}
            onChange={handleChange('id')}
            chekId
            handleIdCheckClick={handleIdCheckClick}
            maxLength={20}
            error={error}
            errorMessage={errorMessage}
            success={success}
            successMessage={message}
          />
          <PasswordInput
            title="비밀번호"
            placeholder="영문, 숫자, 특수문자 포함 8~20자"
            value={payload?.password}
            onChange={handleChange('password')}
            onKeyUp={handleCheckPassword}
            error={errorPw}
            maxLength={20}
          />
          <PasswordInput
            title="비밀번호 확인"
            placeholder="비밀번호 재입력"
            value={payload?.checkPassword}
            onChange={handleChange('checkPassword')}
            onKeyUp={handleCheckPassword}
            error={errorPw}
            errorPwMessage={errorPwMessage}
            maxLength={20}
          />
        </div>
        <div className="info_button_box">
          <Button
            title="다음"
            disabled={
              error ||
              errorPw ||
              !String(payload?.id) ||
              !String(payload?.password) ||
              !String(payload?.checkPassword)
            }
            onClick={() => history.push('/join/info/basic')}
          />
        </div>
      </Content>
    </Wrapper>
  )
}

export default JoinInfoComponent

const Wrapper = styled(AppRootWrapper)``
const Content = styled.div`
  width: 440px;
  height: 680px;
  margin: 0 auto;
  padding: 80px 0 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${media.medium} {
    padding: 20px 0 80px;
    height: 100%;
    width: 100%;
  }
  & p.join_info_title {
    font-weight: 700;
    font-size: 22px;
    line-height: 34px;
    text-align: center;
    color: #0d0d0e;
    ${media.medium} {
      font-size: 18px;
      line-height: 28px;
    }
  }
  & p.join_info_text {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #3b3b40;
    margin-top: 6px;
  }
  & div.info_content_box {
    margin-top: 50px;
    width: 380px;
    ${media.medium} {
      width: 100%;
    }
    & > div {
      margin-bottom: 20px;
    }
    & > div:last-of-type {
      margin-bottom: 0;
    }
  }
  & div.info_button_box {
    margin-top: 40px;
    width: 380px;
    ${media.medium} {
      width: 100%;
    }
  }
`
