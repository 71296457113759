import media from 'lib/styles/media'
import React from 'react'
import styled from 'styled-components'
import Participant from './Participant'
import {
  useRoomInfoAction,
  useRoomInfoValue,
} from '../provider/RoomInfoProvider'

function ParticipantList() {
  const { membersRoomInfo, focusUserRoomInfo, isDebating } = useRoomInfoValue()
  const { modifyRoomInfo, focusUserTrigger, blurFocusUserTrigger } =
    useRoomInfoAction()
  return (
    <Wrapper>
      {membersRoomInfo?.map((info) => (
        <Participant
          key={info.id}
          info={info}
          modifyRoomInfo={modifyRoomInfo}
          onFocusUser={focusUserTrigger}
          isFocus={info.user_id === focusUserRoomInfo?.user_id}
          onBlurFocusUser={blurFocusUserTrigger}
          isDebating={isDebating}
        />
      ))}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  ${media.pc} {
    padding-top: 10px;
  }

  padding-left: 20px;
  padding-right: 20px;
`

export default ParticipantList
