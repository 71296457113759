import { AppRootWrapper } from 'components/layout/CommonStyle'
import React from 'react'
import styled from 'styled-components'
import StudentClassDateComponent from './StudentClassDateComponent'
import useClass from 'lib/hooks/useClass'
import media from 'lib/styles/media'

function StudentClassComponent() {
  const { data: originData } = useClass()

  if (!originData) return null
  if (!originData.data) return null
  if (!originData.data.classes) return null

  return (
    <Wrapper>
      <Title>
        <p>예정된 강의</p>
      </Title>
      <Content>
        <StudentClassDateComponent data={originData.data.classes} />
      </Content>
    </Wrapper>
  )
}

export default StudentClassComponent

const Wrapper = styled(AppRootWrapper)`
  margin-top: 40px;
  position: relative;
  ${media.medium} {
    margin-top: 0;
    width: calc(100% + 20px);
    margin-left: 20px;
  }
`
const Title = styled.div`
  ${media.medium} {
    display: none;
  }
  & > p {
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: #0d0d0e;
  }
`
const Content = styled.div`
  margin-top: 32px;
  ${media.medium} {
    margin-top: 0;
  }
`
