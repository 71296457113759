import { AppRootWrapper } from 'components/layout/CommonStyle'
import media from 'lib/styles/media'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import useSWR from 'swr'
import Button from '../Button'
import { Select } from 'antd'
import EditorContent from '../EditorContent'

import { ReactComponent as Close } from 'assets/icons/popup_cancel_icon.svg'
import CheckPopup from '../CheckPopup'
import api from 'lib/api'

const ReferenceRegisterComponent = () => {
  const history = useHistory()

  const [id, setId] = useState('')
  const [type, setType] = useState('')
  const [title, setTitle] = useState('')
  const [contents, setContents] = useState('')
  const [file, setFile] = useState<any[]>([])
  const [remove, setRemove] = useState<any[]>([])
  const [popup, setPopup] = useState('')
  const [formData, setFormData] = useState({
    id: '',
    index: 0,
    title: '',
    contents: '',
  })

  const { data, mutate } = useSWR(id !== '' && `/reference/detail?id=${id}`)
  const originData = data?.data?.reference_room

  useEffect(() => {
    if (window.location.href.includes('?id='))
      setId(window.location.href.split('?id=')[1])
  }, [])

  useEffect(() => {
    if (originData && id !== '' && title === '') {
      if (originData?.type) {
        const preType = originData?.type
        setType(preType)
      }
      if (originData?.contents) {
        setFormData({
          ...formData,
          contents: originData.contents,
        })
      }

      setTitle(originData.title)

      const arr = []

      for (const file of originData.files) {
        const name =
          file.file_url.split('/')[file.file_url.split('/').length - 1]

        arr.push({ id: file.id, file: '', name: name })
      }

      setFile(arr)
    }
  }, [id, originData])

  const onChange = (value: string) => {
    setType(value)
  }

  const uploadFile: React.ChangeEventHandler<HTMLInputElement> = async (e) => {
    const maxSize = 50 * 1024 * 1024
    const files = e.target.files

    const arr = file.length > 0 ? [...file] : []

    if (files && files?.length > 0) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      for (const file of files) {
        if (file.size <= maxSize) {
          if (arr.length < 10) {
            arr.push({ id: '', file: file, name: file.name })
          } else {
            setPopup('파일은 최대 10건만 첨부할 수 있습니다.')
          }
        } else {
          setPopup('50MB 이하의 파일을 업로드해주세요.')
          return false
        }
      }

      setFile(arr)
    }
  }

  const handleSubmit = async () => {
    const formData = new FormData()
    formData.append('type', type)
    formData.append('title', title)
    formData.append('contents', contents)

    for (const f of file) {
      if (f.id === '') formData.append('file[]', f.file)
    }

    if (id !== '') {
      formData.append('id', id)
      for (const f of remove) {
        formData.append('remove_files[]', f)
      }
    }

    const {
      data: { success, alert },
    } = await api.post(
      id !== '' ? '/reference/modify' : '/reference/register',
      formData,
    )

    if (success) {
      history.goBack()
    } else {
      window.alert(alert)
    }
  }

  return (
    <Wrapper>
      <h1>레퍼런스 룸</h1>
      <InputBox className={type === '' ? 'placeholder' : ''}>
        <h1>게시판</h1>
        <Select
          placeholder="게시판 선택"
          onChange={onChange}
          options={[
            {
              label: '수강생용',
              value: 'user',
            },
            {
              label: '튜터용',
              value: 'tutor',
            },
          ]}
          value={originData && type}
          defaultValue={type === '' ? '게시판 선택' : type}
        />
      </InputBox>
      <InputBox>
        <h1>게시글 작성</h1>
        <Input
          type="text"
          placeholder="제목을 입력해주세요"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          maxLength={50}
        />
        {(id === '' || (id !== '' && originData)) && (
          <EditorContent
            close={false}
            handleClose={() => {}}
            handleChange={setContents}
            handleTitleChange={() => {}}
            defaultValue={formData}
          />
        )}
      </InputBox>
      <InputBox>
        <h1>파일 첨부</h1>
        {file.length > 0 && (
          <Files>
            {file.map((f: any, i: number) => (
              <div key={'file_list_' + i}>
                {f.name}
                <Close
                  onClick={() => {
                    if (f.id !== '') setRemove((prev) => [...prev, f.id])
                    const arr = [...file]
                    arr.splice(i, 1)
                    setFile(arr)
                  }}
                />
              </div>
            ))}
          </Files>
        )}
        <Label>
          파일 첨부
          <input type="file" multiple onChange={uploadFile} />
        </Label>
        <Notice>50MB 이하의 파일을 10개까지 업로드 가능합니다.</Notice>
      </InputBox>
      <Button
        title={id !== '' ? '수정' : '등록'}
        disabled={type === '' || title === '' || contents === ''}
        onClick={handleSubmit}
      />
      {popup !== '' && (
        <CheckPopup
          onClick={() => setPopup('')}
          title="등록 불가"
          content={popup}
        />
      )}
    </Wrapper>
  )
}

export default ReferenceRegisterComponent

const Files = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;

  & > div {
    background: rgba(181, 181, 185, 0.4);
    border-radius: 3px;
    padding: 4px 8px;
    margin-right: 10px;
    white-space: nowrap;
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 1.71;
    color: #7c7c80;
    margin-bottom: 10px;

    & > svg {
      width: 12px;
      height: 12px;
      margin-left: 10px;
      cursor: pointer;
    }
  }

  ${media.medium} {
    margin-bottom: 0;
  }
`

const Notice = styled.div`
  background: #f9f9fb;
  border-radius: 8px;
  font-weight: 400;
  font-size: 13px;
  line-height: 1.69;
  color: #7c7c80;
  text-align: center;
  padding: 12px 0;
  margin: 20px 0 40px;

  ${media.medium} {
    margin: 10px 0 40px;
  }
`

const Label = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 42px;
  border: 1px solid #e1e1e2;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.71;
  color: #0d0d0e;
  cursor: pointer;

  & > input {
    display: none;
  }
`

const Input = styled.input`
  width: 100%;
  padding: 11px 14px;
  border: 1px solid #e1e1e2;
  border-radius: 8px;
  font-weight: 700;
  font-size: 14px;
  line-height: 1.71;
  color: #0d0d0e;
  margin-bottom: 20px;

  &::placeholder {
    color: #b5b5b9;
  }

  ${media.medium} {
    font-weight: 500;
  }
`

const InputBox = styled.div`
  &:nth-of-type(n + 2) {
    margin-top: 40px;
  }

  & > h1 {
    font-weight: 500;
    font-size: 17px;
    line-height: 1.53;
    color: #0d0d0e;
    margin-bottom: 10px;
  }

  & .ant-select {
    width: 100%;
  }

  & .ant-select-selector {
    height: 46px !important;

    & .ant-select-selection-item {
      font-family: 'Noto Sans KR', sans-serif;
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 14px;
      line-height: 1.71;
      color: #0d0d0e;
    }
  }
  & .ant-select-selection-placeholder {
    font-weight: 700;
    font-size: 14px;
    line-height: 2;
    color: #b5b5b9;
    padding-top: 7px;
  }
  &.placeholder {
    & .ant-select-selection-item {
      color: #b5b5b9;
    }
  }

  ${media.medium} {
    & > h1 {
      font-size: 16px;
      line-height: 1.6;
    }
  }
`

const Wrapper = styled(AppRootWrapper)`
  max-width: 770px;

  & > button {
    max-width: 380px;
    margin: auto;
  }

  & > h1 {
    font-weight: 700;
    font-size: 24px;
    line-height: 1.5;
    color: #0d0d0e;
    padding: 40px 0 50px;
  }

  ${media.medium} {
    & > h1 {
      display: none;
    }
  }
`
