import React from 'react'
import styled from 'styled-components'
import { ReactComponent as HomeIcon } from '../../assets/icons/mobile_home_icon.svg'
import { ReactComponent as HomeHoverIcon } from '../../assets/icons/mobile_home_hover_icon.svg'
import { ReactComponent as SearchIcon } from '../../assets/icons/mobile_search_icon.svg'
import { ReactComponent as SearchHoverIcon } from '../../assets/icons/mobile_search_hover_icon.svg'
import { ReactComponent as ClassIcon } from '../../assets/icons/mobile_class_icon.svg'
import { ReactComponent as ClassHoverIcon } from '../../assets/icons/mobile_class_hover_icon.svg'
import { ReactComponent as MenuIcon } from '../../assets/icons/mobile_menu_icon.svg'
import { ReactComponent as MenuHoverIcon } from '../../assets/icons/mobile_menu_hover_icon.svg'
import { useHistory } from 'react-router-dom'
import media from 'lib/styles/media'
import useUser from 'lib/hooks/account/useUser'

function Gnb() {
  const { user } = useUser()
  const history = useHistory()
  const location = window.location.pathname
  const studentUser = user && user.type === 'user'
  const icon = [
    {
      id: 1,
      title: '홈',
      history: '/',
      icon: <HomeIcon />,
      hover: <HomeHoverIcon />,
    },
    {
      id: 2,
      title: '수업 찾기',
      history: '/mobile/search',
      icon: <SearchIcon />,
      hover: <SearchHoverIcon />,
    },
    {
      id: 3,
      title: '예정된 강의',
      history: !studentUser ? '/login' : '/class',
      icon: <ClassIcon />,
      hover: <ClassHoverIcon />,
    },
    {
      id: 4,
      title: '더보기',
      history: '/menu',
      icon: <MenuIcon />,
      hover: <MenuHoverIcon />,
    },
  ]
  return (
    <Wrapper>
      {icon.map((item, i) => (
        <div
          key={`mobile_gnb_list_${i}`}
          className={location === item.history ? 'content active' : 'content'}
          onClick={() => {
            history.push(item.history)
          }}
        >
          {location === item.history ? item.hover : item.icon}
          <p className="gnb_title">{item.title}</p>
        </div>
      ))}
    </Wrapper>
  )
}

export default Gnb

const Wrapper = styled.div`
  position: fixed;
  left: 0;
  bottom: -1px;
  z-index: 6;
  width: 100%;
  height: 60px;
  background: #fff;
  border-top: 1px solid #eeeff1;
  padding: 0 26px;
  align-items: center;
  justify-content: space-between;
  display: none;
  ${media.medium} {
    display: flex;
  }
  & div.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 65px;
    height: 60px;
  }
  & p.gnb_title {
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    text-align: center;
    color: #7c7c80;
    margin-top: 3px;
  }
  & .active {
    & > p {
      color: #0d0d0e;
    }
  }
`
