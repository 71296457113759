import Footer from 'components/common/Footer'
import TutorGnb from 'components/common/TutorGnb'
import MobileTitleHeader from 'components/common/header/MobileTitleHeader'
import TutorHeader from 'components/common/header/TutorHeader'
import RegisterComponent from 'components/common/register/RegisterComponent'
import media from 'lib/styles/media'
import React from 'react'
import styled from 'styled-components'

function RegisterClass() {
  return (
    <Wrapper>
      <TutorHeader />
      <MobileTitleHeader title="강의 개설" />
      <Content>
        <RegisterComponent type="add" />
      </Content>
      <Footer type="bottom" />
      <TutorGnb />
    </Wrapper>
  )
}

export default RegisterClass
const Wrapper = styled.div``
const Content = styled.div`
  width: 770px;
  margin: 100px auto 120px;
  ${media.medium} {
    width: 100%;
    margin: 0 auto 80px;
  }
`
