import media from 'lib/styles/media'
import { type RoomSeatInfoType } from 'lib/types/room'
import React from 'react'
import styled, { css } from 'styled-components'
import DebateMember from './DebateMember'
import { useRoomInfoValue } from '../provider/RoomInfoProvider'

interface TeamRoomMembersProps {
  members: RoomSeatInfoType[]
}

function TeamRoomMembers({ members }: TeamRoomMembersProps) {
  const { role } = useRoomInfoValue()
  return (
    <Wrapper memberCount={members.length}>
      {members.map((member) => (
        <div className="room-wrapper" key={member.user_id}>
          <DebateMember
            seat={member}
            role={role}
            seatNumber={member.table_num}
            isTeamRoom
          />
        </div>
      ))}
    </Wrapper>
  )
}

const Wrapper = styled.div<{ memberCount: number }>`
  display: flex;
  overflow: scroll;
  flex-wrap: wrap;

  ${media.pc} {
    gap: 20px;
    justify-content: center;
  }

  width: 100%;

  ${(props) =>
    props.memberCount === 1 &&
    css`
      & .room-wrapper {
        margin-top: 4%;
        width: 44.58%;
      }
    `}

  ${(props) =>
    props.memberCount === 2 &&
    css`
      & .room-wrapper {
        ${media.pc} {
          margin-top: 4%;
          width: calc((100% - 156px) / 2);
        }
      }
    `}

  ${(props) =>
    (props.memberCount === 3 || props.memberCount === 4) &&
    css`
      ${media.pc} {
        ${media.max} {
          padding-left: 150px;
          padding-right: 150px;
        }

        & .room-wrapper {
          width: 34.15%;
          height: fit-content;
        }
      }
    `}

  ${(props) =>
    props.memberCount === 5 &&
    css`
      ${media.pc} {
        & .room-wrapper {
          width: 30.01%;
          height: fit-content;
        }
      }
    `}

  ${media.medium} {
    width: 100%;
    & .room-wrapper {
      width: 100%;
    }
  }
`

export default TeamRoomMembers
