import { roomSettingState } from 'atoms/modal'
import React, { useState } from 'react'
import { useRecoilState } from 'recoil'
import styled from 'styled-components'
import ModalWrapper from './ModalWrapper'
import { ReactComponent as CloseIcon } from 'assets/icons/close_icon.svg'
import Button from 'components/common/Button'
import { MEMBER_COUNT } from 'lib/utils/variables'
import {
  useRoomInfoAction,
  useRoomInfoValue,
} from 'components/room/provider/RoomInfoProvider'
import toast from 'react-hot-toast'

function RoomSettingModal() {
  const [modal, setModal] = useRecoilState(roomSettingState)

  const [max, setMax] = useState<number>(modal?.maxCount ?? 0)

  const { membersRoomInfo } = useRoomInfoValue()
  const { changeSeat } = useRoomInfoAction()
  const handleChangeMaxCount = (count: number) => () => {
    setMax(count)
  }

  const handleConfirm = () => {
    const currentLength = (membersRoomInfo ?? []).length
    if (currentLength > max) {
      toast('설정하려는 인원이 현재 참여 인원보다 적습니다.', {
        duration: 3000,
        className: 'toast',
        position: 'bottom-center',
      })
      return
    }

    membersRoomInfo
      ?.sort((a, b) => a.table_num - b.table_num)
      ?.forEach(async (info, index) => {
        await changeSeat({
          userId: info.user_id,
          roomId: info.class_id,
          tableNum: index + 1,
        })
      })

    modal?.onConfirm && modal?.onConfirm(max)
    handleClose()
  }

  const handleExit = () => {
    modal?.onExit()

    handleClose()
  }

  const handleClose = () => {
    setModal(null)
  }

  return (
    <ModalWrapper onClose={handleClose}>
      <Wrapper>
        <div className="header">
          <h3>설정</h3>
          <CloseIcon className="close-icon" onClick={handleClose} />
        </div>
        <div className="content">
          <h4>참가자 인원 설정</h4>

          <div className="select-buttons">
            {MEMBER_COUNT.map((member) => (
              <div
                className={`select-button ${
                  Number(max) === Number(member.value) ? 'selected' : ''
                }`}
                key={member.value}
                onClick={handleChangeMaxCount(member.value)}
              >
                {member.label}
              </div>
            ))}
          </div>
        </div>
        <div className="footer">
          <Button title="적용" onClick={handleConfirm} />
          <div className="divide" />
          <Button title="강의 종료" variant="outlined" onClick={handleExit} />
        </div>
      </Wrapper>
    </ModalWrapper>
  )
}

const Wrapper = styled.div`
  width: 380px;
  background: #fff;

  border-radius: 14px;
  padding: 26px;

  & .header {
    position: relative;
    padding-bottom: 10px;

    & h3 {
      font-weight: 700;
      font-size: 18px;
      line-height: 28px;
      color: #0d0d0e;
    }

    & .close-icon {
      position: absolute;
      bottom: 15.5px;
      right: 0;

      cursor: pointer;
    }
  }

  & .content {
    padding: 20px 0px;

    & h4 {
      height: 22px;

      font-family: 'Noto Sans KR';
      font-style: normal;
      font-weight: 700;
      font-size: 13px;
      line-height: 22px;
      color: #0d0d0e;
    }

    & .select-buttons {
      margin-top: 4px;

      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 10px;

      & .select-button {
        height: 50px;

        border: 1px solid #e1e1e2;
        border-radius: 4px;

        display: flex;
        align-items: center;
        justify-content: center;

        font-weight: 500;
        font-size: 14px;
        color: #0d0d0e;

        cursor: pointer;
      }

      & .select-button.selected {
        border: 1px solid #7c7c80;
      }
    }
  }

  & .footer {
    padding-top: 10px;
  }

  & .divide {
    margin: 20px 0;
    height: 1px;
    background: #e1e1e2;
  }
`

export default RoomSettingModal
