import React from 'react'
import styled from 'styled-components'
import Textarea from '../Textarea'
import media from 'lib/styles/media'

interface Props {
  value: string | undefined
  onChange: (e: any) => void
}
function TutorTextAreaContent({ value, onChange }: Props) {
  return (
    <Wrapper>
      <Title>간단 소개</Title>
      <Textarea
        placeholder="튜터님에 대한 간단한 소개나 인사말을 적어주세요."
        value={value ?? ''}
        onChange={onChange}
        maxLength={200}
      />
    </Wrapper>
  )
}

export default TutorTextAreaContent
const Wrapper = styled.div`
  margin-top: 40px;
  ${media.medium} {
    margin-top: 30px;
  }
  & > div {
    width: 100%;
    margin-top: 10px;
  }
`
const Title = styled.p`
  font-weight: 500;
  font-size: 17px;
  line-height: 26px;
  color: #0d0d0e;
  ${media.medium} {
    font-size: 16px;
  }
`
