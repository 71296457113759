import { inviteModalState } from 'atoms/modal'
import React, { useState } from 'react'
import { useRecoilState } from 'recoil'
import styled from 'styled-components'
import ModalWrapper from './ModalWrapper'
import useSWR from 'swr'
import { phoneFormet } from 'lib/utils/CommonFuncttion'
import Button from 'components/common/Button'
import api from 'lib/api'

import { ReactComponent as CloseIcon } from 'assets/icons/close_icon.svg'
import { ReactComponent as EmptyIcon } from 'assets/icons/no_data_icon.svg'
import { ReactComponent as EditPurpleCheck } from 'assets/icons/withdrawal_check_box_checked.svg'
import { ReactComponent as EditGrayCheck } from 'assets/icons/withdrawal_check_box_default.svg'
import { ReactComponent as BasicIcon } from 'assets/icons/basic_icon.svg'

interface Props {
  id: number
  profile_url: string
  name: string
  phone: string
}

function InviteModal() {
  const [modal, setModal] = useRecoilState(inviteModalState)
  const [checked, setChecked] = useState<number[]>([])

  const { data, mutate } = useSWR(
    modal && `/class/available/invitation/user?class_id=${modal.id}`,
  )

  const originData = data?.data?.users

  const handleClose = () => {
    setModal(null)
  }

  const handleInvite = async () => {
    try {
      const formData = new FormData()
      formData.append('class_id', String(modal?.id))

      for (const check of checked) {
        formData.append('user_id[]', String(check))
      }

      const {
        data: { success, alert },
      } = await api.post('/class/invitation', formData)
      if (success) {
        modal?.mutate()
        setChecked([])
        handleClose()
      } else {
        modal?.onClose()
        handleClose()
      }
    } catch (err) {
      modal?.onClose()
      handleClose()
    }
  }

  if (!modal) return null

  return (
    <ModalWrapper onClose={handleClose}>
      <Wrapper>
        <Header>
          <h4 className="title">수강생 초대</h4>
          <CloseIcon className="close-icon" onClick={handleClose} />
        </Header>
        <Content>
          {originData && originData?.length > 0 ? (
            originData?.map((item: Props, i: number) => (
              <List
                key={'invite_user_list_' + i}
                onClick={() => {
                  const arr: any = [...checked]

                  if (arr.includes(item.id)) {
                    arr.splice(arr.indexOf(item.id), 1)
                  } else {
                    if (arr.length < modal?.count) arr.push(item.id)
                  }

                  setChecked(arr)
                }}
              >
                <ProfileBox>
                  {item.profile_url ? (
                    <img src={item.profile_url} />
                  ) : (
                    <BasicIcon />
                  )}
                </ProfileBox>
                <TextBox>
                  <h1>{item.name}</h1>
                  <p>{phoneFormet(item.phone)}</p>
                </TextBox>
                {checked.includes(item.id) ? (
                  <EditPurpleCheck />
                ) : (
                  <EditGrayCheck />
                )}
              </List>
            ))
          ) : (
            <EmptyBox>
              <EmptyIcon />
              초대 가능한 학생이 없습니다.
            </EmptyBox>
          )}
        </Content>
        <ButtonArea>
          <Button
            title="수강생 초대"
            disabled={checked.length <= 0}
            onClick={handleInvite}
          />
        </ButtonArea>
      </Wrapper>
    </ModalWrapper>
  )
}

export default InviteModal

const ButtonArea = styled.div`
  padding: 10px 26px 26px;
`

const TextBox = styled.div`
  width: 100%;
  margin: 0 10px;

  & > h1 {
    font-weight: 500;
    font-size: 14px;
    line-height: 1;
    color: #3b3b40;
  }

  & > p {
    font-weight: 500;
    font-size: 12px;
    line-height: 1;
    color: #b5b5b9;
    margin-top: 6px;
  }
`

const ProfileBox = styled.div`
  & > img,
  & > svg {
    width: 42px;
    min-width: 42px;
    height: 42px;
    border-radius: 100px;
    object-fit: cover;
  }
`

const EmptyBox = styled.div`
  padding: 105px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.62;
  color: #b5b5b9;

  & > svg {
    width: 38px;
    height: 38px;
    margin-bottom: 16px;
  }
`

const List = styled.div`
  padding: 12px 26px;
  display: flex;
  align-items: center;
  cursor: pointer;

  & > svg {
    width: 24px;
    min-width: 24px;
    height: 24px;
  }
`

const Content = styled.div`
  height: 290px;
  overflow: auto;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 26px 26px 10px;

  & > h4 {
    font-weight: 700;
    font-size: 18px;
    line-height: 1.56;
    color: #0d0d0e;
  }

  & > svg {
    width: 17px;
    height: 17px;
  }
`

const Wrapper = styled.div`
  background: #fff;
  border-radius: 14px;
  width: 100%;
  max-width: 380px;
  margin: 0 20px;
`
