import { AppRootWrapper } from 'components/layout/CommonStyle'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import IconButton from '../IconButton'
import CategoryTab from '../CategoryTab'
import MainMyInfo from '../rootpage/MainMyInfo'
import useSWR from 'swr'
import useIndex from 'lib/hooks/useIndex'
import { useHistory } from 'react-router-dom'
import media from 'lib/styles/media'
import auth from 'lib/utils/auth'
import useUser from 'lib/hooks/account/useUser'

interface CategoryProps {
  id: number
  category: string
}

function StudentMenuComponent() {
  const { data: originData } = useSWR('/class/category')
  const { data } = useIndex()
  const { user } = useUser()
  const userData = data && data?.data?.user
  const [alarm, setAlarm] = useState('N')
  const history = useHistory()
  const studentUser = user && user.type === 'user'

  useEffect(() => {
    if (!userData) return
    if (userData?.is_new_message) {
      setAlarm(userData?.is_new_message)
    }
  }, [userData])

  const handleLogout = () => {
    if (user.type === 'tutor') return
    auth.clearToken()
    localStorage.removeItem('save_refresh_token')
    window.location.href = '/'
  }

  return (
    <Wrapper>
      <Content>
        <LeftContent>
          <div>
            <MainMyInfo type="none" />
            <IconButton
              title="알림"
              type="alarm"
              className="menu"
              onClick={() => {
                if (studentUser) {
                  history.push('/alarm')
                } else {
                  history.push('/login')
                }
              }}
              alarm={alarm}
            />
            <IconButton
              title="공지사항"
              type="notice"
              className="menu"
              onClick={() => {
                if (studentUser) {
                  history.push('/notice')
                } else {
                  history.push('/login')
                }
              }}
            />
            {studentUser && (
              <IconButton
                title="로그아웃"
                type="none"
                className="menu"
                onClick={handleLogout}
              />
            )}
          </div>
        </LeftContent>
        <Span />
        <RightContent>
          <Title>카테고리별 강의 보기</Title>
          <div className="category_content">
            {originData &&
              originData?.data?.categories.map(
                (item: CategoryProps, i: number) => (
                  <div key={`menu_category_list_${i}`}>
                    <CategoryTab
                      category={item.category}
                      onClick={() => {
                        history.push(
                          `/category/${item.id}?category=${item.category}`,
                        )
                      }}
                    />
                  </div>
                ),
              )}
          </div>
        </RightContent>
      </Content>
    </Wrapper>
  )
}

export default StudentMenuComponent

const Wrapper = styled(AppRootWrapper)``
const Content = styled.div`
  display: flex;
  ${media.medium} {
    flex-direction: column;
  }
`
const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 890px;
  ${media.medium} {
    width: 100%;
  }
`
const Span = styled.div`
  height: 8px;
  background: #f9f9fb;
  margin-top: 29px;
  width: calc(100% + 40px);
  transform: translateX(-20px);
  display: none;
  ${media.medium} {
    display: flex;
  }
`
const RightContent = styled.div`
  margin-left: 22px;
  width: 100%;
  ${media.medium} {
    margin-left: 0;
    width: calc(100% + 40px);
    transform: translateX(-20px);
    margin-bottom: 60px;
  }
  & div.category_content {
    margin-top: 19px;
    ${media.medium} {
      margin-top: 0;
    }
  }
`
const Title = styled.p`
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #0d0d0e;
  padding-left: 18px;
  ${media.medium} {
    font-size: 16px;
    line-height: 26px;
    padding: 26px 20px 16px;
  }
`
