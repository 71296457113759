import React from 'react'
import styled from 'styled-components'
import { ReactComponent as MainLogo } from '../../../assets/icons/main_logo.svg'
import { AppRootWrapper } from 'components/layout/CommonStyle'
import Button from '../Button'
import LineButton from '../LineButton'
import IconButton from '../IconButton'
import { useHistory } from 'react-router-dom'
import media from 'lib/styles/media'
import { isAndroid } from 'lib/utils'

function WelcomeComponent() {
  const history = useHistory()

  const handleKakao = () => {
    if (isAndroid()) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      window?.debateon?.postMessage(JSON.stringify({ type: 'kakaoLoginCheck' }))
      return
    }
    window.location.href = `https://kauth.kakao.com/oauth/authorize?client_id=${process.env.REACT_APP_KAKAO_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_KAKAO_REDIRECT_URL}&response_type=code&state=kakao`
  }
  return (
    <Wrapper>
      <Content>
        <MainLogo className="logo" />
        <div className="button_box">
          <Button
            title="디베이트온 계정으로 로그인"
            onClick={() => {
              history.push('/login/id')
            }}
            className="login"
          />
          <IconButton
            title="카카오 계정으로 로그인"
            type="kakao"
            onClick={handleKakao}
          />
        </div>
        <div className="title_box">
          <span className="bar" />
          <p>아직 디베이트온 회원이 아니신가요?</p>
          <span className="bar" />
        </div>
        <div className="login_box">
          <LineButton
            title="일반 회원가입"
            type="gray"
            onClick={() => {
              history.push('/join')
            }}
          />
        </div>
        <p
          className="look_text"
          onClick={() => {
            history.push('/')
          }}
        >
          둘러보기
        </p>
      </Content>
    </Wrapper>
  )
}

export default WelcomeComponent

const Wrapper = styled(AppRootWrapper)``
const Content = styled.div`
  width: 440px;
  height: 680px;
  margin: 0 auto;
  padding: 80px 0 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${media.medium} {
    width: 100%;
    height: 100%;
    padding: 40px 0 80px;
  }
  & svg.logo {
    min-width: 146px;
    min-height: 50px;
    ${media.medium} {
      min-width: 116px;
      min-height: 40px;
    }
  }
  & div.button_box {
    width: 380px;
    margin-top: 40px;
    ${media.medium} {
      width: 300px;
      margin-top: 45px;
    }
    & > button:first-of-type {
      margin-bottom: 12px;
    }
  }
  & div.title_box {
    width: 380px;
    margin-top: 80px;
    display: flex;
    align-items: center;
    ${media.medium} {
      width: 300px;
    }
    & span.bar {
      width: 72px;
      height: 1px;
      background: #eeeff1;
      ${media.medium} {
        width: 42px;
      }
    }
    & > p {
      font-size: 13px;
      line-height: 22px;
      text-align: center;
      color: #7c7c80;
      margin: 0 20px;
      ${media.medium} {
        margin: 0 10px;
      }
    }
  }
  & div.login_box {
    width: 380px;
    margin-top: 22px;
    ${media.medium} {
      width: 300px;
    }
  }
  & p.look_text {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    text-decoration-line: underline;
    color: #3b3b40;
    margin-top: 40px;
    cursor: pointer;
  }
`
