import media from 'lib/styles/media'
import React from 'react'
import styled from 'styled-components'
import { ReactComponent as NoneIcon } from '../../assets/icons/no_data_icon.svg'

interface Props {
  title: string
}
function NoneData({ title }: Props) {
  return (
    <Wrapper>
      <NoneIcon />
      <p>{title} 없습니다.</p>
    </Wrapper>
  )
}

export default NoneData

const Wrapper = styled.div`
  width: 100%;
  min-height: 400px;
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 120px;
  ${media.medium} {
    margin-bottom: 80px;
  }
  & > p {
    font-weight: 700;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    color: #b5b5b9;
    margin-top: 16px;
  }
`
