import { timeFormet } from 'lib/utils/CommonFuncttion'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ReactComponent as ScopeIcon } from '../../../assets/icons/class_info_scope_icon.svg'
import { ReactComponent as LiveIcon } from '../../../assets/icons/class_live_icon.svg'
import { ReactComponent as NoneImage } from '../../../assets/icons/none_profile_icon.svg'
import Button from '../Button'
import ClassNoticeButton from '../ClassNoticeButton'
import media from 'lib/styles/media'
import { useHistory } from 'react-router-dom'

interface Props {
  title: string
  nickname: string
  score: string
  image: string
  profile: string
  status: number
  date: string
  category: Array<{ id: number; category: string }>
  handleCancel: () => void
  multi: number
  stepTitle?: string
  classCancel: any
  id: number
  tutorId: number
}
function ClassComponent({
  title,
  nickname,
  score,
  image,
  profile,
  status,
  date,
  category,
  handleCancel,
  multi,
  stepTitle,
  classCancel,
  id,
  tutorId,
}: Props) {
  const history = useHistory()

  const [popup, setPopup] = useState(false)
  const handleCancelClick = () => {
    setPopup(false)
    handleCancel()
  }

  useEffect(() => {
    if (popup) {
      document.documentElement.style.overflow = 'hidden'
    } else {
      document.documentElement.style.overflow = 'auto'
    }

    return () => {
      document.documentElement.style.overflow = 'auto'
    }
  }, [popup])

  return (
    <Wrapper>
      <ImageContent onClick={() => history.push('/class/' + id)}>
        {image ? <img src={image} alt="class_image" /> : <Div />}
        {status === 1 && (
          <StatusTab className="live">
            <LiveIcon />
            <p>수업진행중</p>
          </StatusTab>
        )}
        {status === 0 && (
          <StatusTab className="none">
            <p>{timeFormet(date)} 시작</p>
          </StatusTab>
        )}
      </ImageContent>
      <Contents>
        <p className="class_title" onClick={() => history.push('/class/' + id)}>
          {title}
        </p>
        {multi === 1 && <p className="class_multi">{stepTitle}</p>}
        <div
          className="nick_content"
          onClick={() => history.push(`/tutor/detail/${tutorId}`)}
        >
          {profile ? <img src={profile} alt="profile_image" /> : <NoneImage />}
          <p className="nick_name">{nickname}</p>
          <ScopeIcon className="scope_icon" />
          <p className="scope">{score}</p>
        </div>
        <div
          className="category_content"
          onClick={() => history.push('/class/' + id)}
        >
          <p className="category">
            {category.map((item, i) => (
              <span
                className={
                  category.length === 1 ? 'category_span none' : 'category_span'
                }
                key={`main_student_class_category_list_${i}`}
              >
                {item.category} <span className="dot" />
              </span>
            ))}
          </p>
        </div>
      </Contents>
      <ButtonBox>
        {classCancel === null && (
          <ClassNoticeButton
            onBlur={() => {
              if (popup) {
                setTimeout(() => {
                  setPopup(false)
                }, 300)
              }
            }}
            onClick={() => {
              if (!popup) {
                setPopup(true)
              } else {
                setPopup(false)
              }
            }}
          />
        )}

        {status === 1 && (
          <Button
            title="강의 입장"
            onClick={() => history.push('/room/' + id)}
            className="small"
          />
        )}
        {status === 0 && (
          <Button
            title="강의 시간 30분 전부터 입장 가능"
            className="small"
            disabled
          />
        )}
      </ButtonBox>
      {popup && (
        <PopupContent onClick={handleCancelClick}>
          <p className="popup_title"> 수강 취소 신청</p>
        </PopupContent>
      )}
    </Wrapper>
  )
}

export default ClassComponent
const Wrapper = styled.div`
  width: 386px;
  height: 445px;
  background: #ffffff;
  border: 1px solid #e1e1e2;
  border-radius: 14px;
  position: relative;
  display: flex;
  flex-direction: column;
  ${media.medium} {
    width: 290px;
    height: 350px;
    border-radius: 10px;
  }
`
const ImageContent = styled.div`
  width: 100%;
  max-width: 386px;
  height: 217px;
  border-radius: 14px 14px 0px 0px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  ${media.medium} {
    max-width: 290px;
    height: 167px;
    border-radius: 10px 10px 0px 0px;
  }
  & > img {
    width: 100%;
    object-fit: contain;
  }
`
const StatusTab = styled.div`
  position: absolute;
  left: 20px;
  bottom: 20px;
  height: 23px;
  display: flex;
  align-items: center;
  border-radius: 3px;
  padding: 6px 8px;
  width: fit-content;
  ${media.medium} {
    left: 17px;
    bottom: 17px;
  }
  & > p {
    font-weight: 700;
    font-size: 12px;
    line-height: 22px;
    color: #ffffff;
  }
  &.none {
    background: #7574d8;
  }
  &.live {
    background: #ff2c2c;
    & > p {
      margin-left: 4px;
    }
  }
`
const Contents = styled.div`
  padding: 18px 20px 0;
  min-height: 118px;
  flex: 1;
  ${media.medium} {
    padding: 14px;
    min-height: 98px;
  }
  cursor: pointer;
  & p.class_title {
    font-weight: 700;
    font-size: 16px;
    line-height: 26px;
    color: #3b3b40;
    overflow: hidden;
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    ${media.medium} {
      font-size: 14px;
      line-height: 24px;
    }
  }
  & p.class_multi {
    background: #eeeff1;
    border-radius: 3px;
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    text-align: center;
    color: #3b3b40;
    padding: 4px 0;
    margin-top: 8px;
  }
  & div.nick_content {
    margin: 8px 0;
    display: flex;
    align-items: center;
    ${media.medium} {
      margin: 5px 0;
    }
    & > img {
      width: 28px;
      height: 28px;
      border-radius: 100%;
      ${media.medium} {
        width: 18px;
        height: 18px;
      }
    }
    & > svg {
      width: 28px;
      height: 28px;
      border-radius: 100%;
      ${media.medium} {
        width: 18px;
        height: 18px;
      }
    }
  }
  & .nick_name {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #3b3b40;
    margin: 0 10px;
    ${media.medium} {
      font-size: 12px;
      line-height: 22px;
    }
  }
  & .scope {
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    color: #7c7c80;
    margin-left: 4px;
    ${media.medium} {
      font-size: 12px;
    }
  }
  & .category_content {
    min-height: 22px;
    display: flex;
    align-items: center;
  }
  & .category {
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    color: #b5b5b9;
    display: flex;
    align-items: center;
    min-width: 100%;
    width: 100%;
    overflow: hidden;
    word-break: break-word;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  & .category_span {
    width: 100%;
    min-width: fit-content;
    position: relative;
    margin-right: 8px;
    &.none {
      & span.dot {
        display: none;
      }
    }
    & span.dot {
      position: absolute;
      right: -4px;
      top: 50%;
      transform: translateY(-50%);
      width: 2px;
      height: 2px;
      border-radius: 100%;
      background: #b5b5b9;
    }
  }
`
const ButtonBox = styled.div`
  padding: 18px 20px;
  display: flex;
  ${media.medium} {
    padding: 0 20px;
  }
  & > button:first-of-type {
    margin-right: 10px;
  }
`
const Div = styled.div`
  width: 100%;
  max-width: 386px;
  height: 217px;
  border-radius: 14px 14px 0px 0px;
  background: #e1e1e2;
  ${media.medium} {
    max-width: 290px;
    height: 167px;
    border-radius: 10px 10px 0px 0px;
  }
`
const PopupContent = styled.div`
  position: absolute;
  left: 20px;
  bottom: -20px;
  z-index: 5;
  width: 200px;
  height: 46px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 11px 14px;
  & p.popup_title {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #3b3b40;
    cursor: pointer;
  }
`
