import { getTutorClassListRequest } from 'lib/api/tutor'
import useSWR from 'swr'

function useTutorClassList({
  tutorId,
  page,
}: {
  tutorId: string
  page: number
}) {
  return useSWR(['tutorClassList', page], async () => {
    const response = await getTutorClassListRequest({
      tutorId,
      page,
    })

    if (!response.success) return
    return response.data.classes
  })
}

export default useTutorClassList
