import type { UserInfo } from 'lib/types/account'
import React, { useEffect } from 'react'
import StudentJoin from './StudentJoin'
import TutorJoin from './TutorJoin'
import { useRecoilState } from 'recoil'
import { PageTypeState } from 'atoms/common'

interface JoinPageProps {
  user?: UserInfo
}

function JoinPage({ user }: JoinPageProps) {
  const [pageType, setPageType] = useRecoilState(PageTypeState)

  if (pageType === 'tutor') return <TutorJoin />
  return <StudentJoin />
}

export default JoinPage
