import Search from 'components/common/Search'
import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { ReactComponent as SearchIcon } from '../../components/../assets/icons/input_search_icon.svg'
import { ReactComponent as SearchCloseIcon } from '../../components/../assets/icons/search_close_icon.svg'

import Gnb from 'components/common/Gnb'
import useSWR from 'swr'
import api from 'lib/api'
import MobileTitleHeader from 'components/common/header/MobileTitleHeader'
import useUser from 'lib/hooks/account/useUser'

interface HistoryProps {
  id: number
  keyword: string
}

function MobileSearchPage() {
  const history = useHistory()
  const { user } = useUser()
  const studentUser = user && user.type === 'user'

  const { data: search, mutate } = useSWR(studentUser && `/search/history`)
  const searchData = search && search.data

  const onCloseClick = async (id: number) => {
    const {
      data: { success, alert },
    } = await api.delete(`/search/delete?id=${id}`)

    if (success) {
      mutate()
    } else {
      window.alert(alert)
    }
  }

  return (
    <Wrapper>
      <MobileTitleHeader type="search" />
      <ContentBox>
        {searchData && (
          <FocusSearch>
            <p className="search_title">최근 검색어</p>
            <div className="search_content">
              {searchData.search_histories.length > 0 ? (
                <>
                  {searchData.search_histories.map(
                    (item: HistoryProps, i: number) => (
                      <div
                        className="search_history_content"
                        key={`mobile_search_history_list_${i}`}
                      >
                        <SearchIcon className="small_icon" />
                        <p
                          className="search_history_text"
                          onClick={() => {
                            history.push(`/search/${item.keyword}`)
                          }}
                        >
                          {item.keyword}
                        </p>
                        <SearchCloseIcon
                          onClick={() => {
                            onCloseClick(item.id)
                          }}
                        />
                      </div>
                    ),
                  )}
                </>
              ) : (
                <div className="none_history">
                  <p>최근 검색어 내역이 없습니다.</p>
                </div>
              )}
            </div>
          </FocusSearch>
        )}
      </ContentBox>
      <Gnb />
    </Wrapper>
  )
}

export default MobileSearchPage

const Wrapper = styled.div``
const ContentBox = styled.div``
const FocusSearch = styled.div`
  margin-top: 2px;
  width: 100%;
  background: #ffffff;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  & .search_title {
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    color: #7c7c80;
    margin-bottom: 6px;
    padding: 11px 14px;
  }
  & .search_history_content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 14px;
  }
  & .search_history_content:hover {
    background: #f9f9fb;
  }
  & .small_icon {
    width: 14px;
    height: 14px;
  }
  & .search_history_text {
    flex: 1;
    margin: 0 8px;
    font-weight: 400;
    font-size: 13px;
    line-height: 22px;
    color: #3b3b40;
    cursor: pointer;
  }
  & .none_history {
    padding: 49px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    & > p {
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      color: #b5b5b9;
    }
  }
`
