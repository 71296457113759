import media from 'lib/styles/media'
import React, { useState } from 'react'
import styled from 'styled-components'

interface Props {
  title: string
  handleChange: (e: any) => void
  disabled?: boolean
}
function TutorStepTitleContent({ title, handleChange, disabled }: Props) {
  return (
    <Wrapper>
      <Title>회차명</Title>
      <Content>
        <input
          className={disabled ? 'disabled' : ''}
          type="text"
          placeholder="회차명은 여기에 입력해주세요"
          value={title}
          onChange={handleChange}
          maxLength={25}
          disabled={disabled}
        />
      </Content>
    </Wrapper>
  )
}

export default TutorStepTitleContent
const Wrapper = styled.div`
  margin-top: 40px;
  width: 100%;
`
const Title = styled.p`
  font-weight: 500;
  font-size: 17px;
  line-height: 26px;
  color: #0d0d0e;
  ${media.medium} {
    font-size: 16px;
  }
`
const Content = styled.div`
  margin-top: 10px;
  & input {
    width: 100%;
    background: #ffffff;
    border: 1px solid #e1e1e2;
    border-radius: 8px;
    padding: 11px 14px;
    outline: none;
    &::placeholder {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: #b5b5b9;
    }
    &.disabled {
      background: rgba(0, 0, 0, 0.04);
      color: rgba(0, 0, 0, 0.25);
    }
  }
`
