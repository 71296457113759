import { PageTypeState } from 'atoms/common'
import Footer from 'components/common/Footer'
import Header from 'components/common/header/Header'
import MobileTitleHeader from 'components/common/header/MobileTitleHeader'
import TutorHeader from 'components/common/header/TutorHeader'
import NoticeDetailComponent from 'components/common/noticeDetail/NoticeDetailComponent'
import useUser from 'lib/hooks/account/useUser'
import useNoticeDetail from 'lib/hooks/useNoticeDetail'
import media from 'lib/styles/media'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import styled from 'styled-components'

function NoticeDetailPage() {
  const params = useParams<{ noticeId: string }>()
  const noticeId = params.noticeId
  const { data: originData } = useNoticeDetail({ noticeId })
  const [pageType, setPageType] = useRecoilState(PageTypeState)
  const { user } = useUser()
  const typeUser = user && user.type

  if (!originData) return null
  if (!originData.data) return null
  return (
    <Wrapper>
      {typeUser === 'tutor' ? <TutorHeader /> : <Header />}
      <MobileTitleHeader type="arrow" title="공지사항" />
      <ContentWra>
        <NoticeDetailComponent data={originData.data.notice} />
      </ContentWra>
      <Footer />
    </Wrapper>
  )
}

export default NoticeDetailPage

const Wrapper = styled.div``
const ContentWra = styled.div`
  margin-top: 140px;
  min-height: 600px;
  ${media.medium} {
    margin-top: 10px;
    margin-bottom: 80px;
  }
`
