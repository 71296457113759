import type { UserInfo } from 'lib/types/account'
import React, { useEffect } from 'react'
import StudentLogin from './StudentLogin'
import TutorLogin from './TutorLogin'
import { useRecoilState } from 'recoil'
import { PageTypeState } from 'atoms/common'

interface LoginPageProps {
  user?: UserInfo
}

function LoginPage({ user }: LoginPageProps) {
  const [pageType, setPageType] = useRecoilState(PageTypeState)

  if (pageType === 'tutor') return <TutorLogin />
  return <StudentLogin />
}

export default LoginPage
