import React, { useState } from 'react'
import styled from 'styled-components'
import { ReactComponent as Staroff } from '../../../assets/icons/class_review_icon_off.svg'
import { ReactComponent as StarOn } from '../../../assets/icons/class_review_icon_on.svg'
import media from 'lib/styles/media'

interface Props {
  handleChange: (score: number) => void
  value: number
}
function MyClassScoreContent({ handleChange, value }: Props) {
  const review = [
    { icon: <Staroff />, activeIcon: <StarOn />, score: 1 },
    { icon: <Staroff />, activeIcon: <StarOn />, score: 2 },
    { icon: <Staroff />, activeIcon: <StarOn />, score: 3 },
    { icon: <Staroff />, activeIcon: <StarOn />, score: 4 },
    { icon: <Staroff />, activeIcon: <StarOn />, score: 5 },
  ]

  return (
    <Wrapper>
      <p className="content_title">강의 평점</p>
      <div className="score_content_box">
        <p>{value}.0</p>
        <div className="score_box">
          {review.map((item, i) => (
            <div
              key={`myclass_review_star_${i}`}
              onClick={() => {
                handleChange(item.score)
              }}
            >
              {value >= item.score ? item.activeIcon : item.icon}
            </div>
          ))}
        </div>
      </div>
    </Wrapper>
  )
}

export default MyClassScoreContent

const Wrapper = styled.div`
  margin-bottom: 40px;
  & p.content_title {
    font-weight: 500;
    font-size: 17px;
    line-height: 26px;
    color: #0d0d0e;
    ${media.medium} {
      font-size: 16px;
    }
  }
  & div.score_content_box {
    width: 100%;
    min-height: 145px;
    margin-top: 10px;
    background: #f9f9fb;
    border-radius: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  & p {
    font-weight: 500;
    font-size: 36px;
    line-height: 36px;
    color: #0d0d0e;
    ${media.medium} {
      font-size: 22px;
      line-height: 34px;
    }
  }
  & div.score_box {
    display: flex;
    margin-top: 16px;
    & > div {
      margin-right: 10px;
    }
    & > div:last-of-type {
      margin-right: 0;
    }
  }
`
