import React, { useEffect } from 'react'
import styled from 'styled-components'

interface ModalWrapperProps {
  children: React.ReactNode
  onClose?: () => void
}

function ModalWrapper({ children, onClose }: ModalWrapperProps) {
  const handleClose: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation()

    if (e.currentTarget !== e.target) return

    onClose?.()
  }

  useEffect(() => {
    document.documentElement.style.overflow = `hidden`
    return () => {
      document.documentElement.style.overflow = `auto`
    }
  }, [])
  return <Wrapper onClick={handleClose}>{children}</Wrapper>
}

const Wrapper = styled.div`
  z-index: 1000;
  position: fixed;
  left: 0;
  top: 0;

  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  background: rgba(0, 0, 0, 0.7);

  display: flex;
  justify-content: center;
  align-items: center;
`

export default ModalWrapper
