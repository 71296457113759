import { AppRootWrapper } from 'components/layout/CommonStyle'
import React, { useState } from 'react'
import styled from 'styled-components'
import MyClassInfoContent from './MyClassInfoContent'
import MyClassScoreContent from './MyClassScoreContent'
import MyClassReviewContent from './MyClassReviewContent'
import Button from '../Button'
import { useHistory, useParams } from 'react-router-dom'
import api from 'lib/api'
import media from 'lib/styles/media'
import MyClassTitle from './MyClassTitle'
import { useRecoilValue } from 'recoil'
import { MyClassListDataPayloadState } from 'atoms/join'

function MyClassReviewComponent() {
  const history = useHistory()
  const params = useParams<{ classId: string }>()
  const classId = params.classId
  const [formdata, setFormdata] = useState({
    score: 0,
    contents: '',
  })
  const payload = useRecoilValue(MyClassListDataPayloadState)
  const onChange = (e: any) => {
    setFormdata({
      ...formdata,
      contents: e.target.value,
    })
  }
  const handleChange = (type: number) => {
    setFormdata({
      ...formdata,
      score: type,
    })
  }
  const handleSubmit = async () => {
    const formData = new FormData()
    formData.append('class_user_id', classId)
    formData.append('score', String(formdata.score))
    formData.append('contents', formdata.contents)

    const {
      data: { success, alert },
    } = await api.post('/class/review/register', formData)

    if (success) {
      history.push('/myclass')
    } else {
      window.alert(alert)
    }
  }
  return (
    <Wrapper>
      <MyClassTitle
        mainTitle="강의 리뷰쓰기"
        subTitle="강의에 대한 리뷰를 남겨주세요!"
      />
      <MyClassContentWrapper>
        <MyClassInfoContent info={payload} />
        <MyClassScoreContent
          value={formdata.score}
          handleChange={handleChange}
        />
        <MyClassReviewContent value={formdata.contents} onChange={onChange} />
        <div className="button_box">
          <Button
            title="완료"
            onClick={handleSubmit}
            disabled={formdata.score === 0 || formdata.contents.length < 1}
          />
        </div>
      </MyClassContentWrapper>
    </Wrapper>
  )
}

export default MyClassReviewComponent
const Wrapper = styled(AppRootWrapper)``
export const MyClassContentWrapper = styled.div`
  padding-top: 40px;
  & div.button_box {
    margin-top: 20px;
    max-width: 380px;
    padding-top: 20px;
    margin: 0 auto;
    ${media.medium} {
      position: fixed;
      left: 0;
      bottom: 0;
      width: 100%;
      padding: 20px;
      background: #fff;
    }
  }
`
