import media from 'lib/styles/media'
import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'

interface Props {
  title: string
}
function ToastPopup({ title }: Props) {
  const massage = useRef<any>(null)

  useEffect(() => {
    if (massage.current !== null) {
      setTimeout(() => {
        try {
          massage.current.style.opacity = 0
        } catch (error) {}
      }, 2000)
      setTimeout(() => {
        try {
          massage.current.style.opacity = 'none'
        } catch (error) {}
      }, 2300)
    }
  }, [massage])

  return (
    <Wrapper ref={massage}>
      <p>{title}</p>
    </Wrapper>
  )
}

export default ToastPopup
const Wrapper = styled.div`
  width: 420px;
  height: 52px;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(4px);
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 50%;
  top: 120px;
  transform: translateX(-50%);
  z-index: 5;
  transition: all 0.3s;
  ${media.medium} {
    width: 320px;
    height: 42px;
    top: 70px;
  }
  & > p {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
    ${media.medium} {
      font-size: 13px;
      line-height: 22px;
    }
  }
`
