import { PageTypeState } from 'atoms/common'
import AlarmComponent from 'components/common/alarm/AlarmComponent'
import Footer from 'components/common/Footer'
import Header from 'components/common/header/Header'
import MobileTitleHeader from 'components/common/header/MobileTitleHeader'
import TutorHeader from 'components/common/header/TutorHeader'
import api from 'lib/api'
import useUser from 'lib/hooks/account/useUser'
import useAlarm from 'lib/hooks/useAlarm'
import media from 'lib/styles/media'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import styled from 'styled-components'

function AlarmPage() {
  const { data: originData, target } = useAlarm()
  const { user } = useUser()
  const typeUser = user && user.type

  const history = useHistory()

  useEffect(() => {
    const unhistory = history.listen((location) => {
      if (history.action === 'PUSH') {
        handelRead()
      }
      if (history.action === 'POP') {
        handelRead()
      }
    })

    return () => {
      unhistory()
    }
  }, [history])

  const handelRead = async () => {
    const {
      data: { success, alert },
    } = await api.post('/message/read')
  }

  if (!originData) return null
  if (!originData.data) return null

  return (
    <Wrapper>
      {typeUser === 'tutor' ? <TutorHeader /> : <Header />}
      <MobileTitleHeader type="arrow" title="알림" />
      <ContentWra>
        <AlarmComponent
          data={originData.data.user_messages.data}
          target={target}
        />
      </ContentWra>
      <Footer />
    </Wrapper>
  )
}

export default AlarmPage

const Wrapper = styled.div``
const ContentWra = styled.div`
  margin-top: 140px;
  ${media.medium} {
    margin-top: 10px;
  }
`
