import { confirmModalState } from 'atoms/modal'
import RoomContent from 'components/room/content/RoomContent'
import RoomHeader from 'components/room/common/RoomHeader'
import useRoom from 'lib/hooks/room/useRoom'
import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import styled from 'styled-components'

function TutorRoomContainer() {
  const { roomId }: { roomId: string } = useParams()
  const setConfirmModal = useSetRecoilState(confirmModalState)
  const { data, changeMemberCount } = useRoom(roomId)
  const history = useHistory()
  const handleClickArrow = () => {
    setConfirmModal({
      title: '강의 나가기',
      description: '강의를 나가시겠습니까?',
      confirmText: '나가기',
      onConfirm: () => {
        history.goBack()
      },
    })
  }

  if (!data) return null

  return (
    <Wrapper>
      <RoomHeader
        title={data.room.title}
        maxCount={data.room.room_qty}
        changeMemberCount={changeMemberCount}
        onClickArrow={handleClickArrow}
      />
      <RoomContent />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  flex: 1;
  height: 100%;

  overflow: hidden;

  display: flex;
  flex-direction: column;
`

export default TutorRoomContainer
