import TutorGnb from 'components/common/TutorGnb'
import MobileTitleHeader from 'components/common/header/MobileTitleHeader'
import TutorMenuHeader from 'components/common/header/TutorMenuHeader'
import TutorMenuComponent from 'components/common/menu/TutorMenuComponent'
import media from 'lib/styles/media'
import React from 'react'
import styled from 'styled-components'

function TutorMenu() {
  return (
    <Wrapper>
      <TutorMenuHeader />
      <MobileTitleHeader title="마이페이지" />
      <ContentWra>
        <TutorMenuComponent />
      </ContentWra>
      <TutorGnb />
    </Wrapper>
  )
}

export default TutorMenu
const Wrapper = styled.div``
const ContentWra = styled.div`
  margin-top: 140px;
  ${media.medium} {
    margin-top: 5px;
    margin-bottom: 60px;
  }
`
