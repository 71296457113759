import api from 'lib/api'
import useSWR from 'swr'

function useAuduence(roomId: string) {
  const { data, isLoading } = useSWR(
    ['/rtc/attend/list', roomId],
    async ([url, roomId]) => {
      const { data } = await api.get(url, { params: { class_id: roomId } })
      if (!data.success) return

      return {
        last_page: data.data.attends.last_page,
        current_page: data.data.attends.current_page,
        data: data.data.attends.data,
        total: data.data.attends.total,
      }
    },
  )

  return { data, isLoading }
}

export default useAuduence
