import PageLoadingComponent from 'components/common/PageLoadingComponent'
import PageNation from 'components/common/PageNation'
import { AppRootWrapper } from 'components/layout/CommonStyle'
import TutorReviewComponent from 'components/tutor/TutorReviewComponent'
import React, { useState } from 'react'
import styled from 'styled-components'
import useTutorReviewList from './hooks/useTutorReviewList'
import { ReactComponent as EmptyIcon } from 'assets/icons/no_data_icon.svg'
import media from 'lib/styles/media'

function TutorReviewListContainer({
  name,
  tutorId,
}: {
  name: string
  tutorId: string
}) {
  const [page, setPage] = useState(1)
  const { data, isLoading } = useTutorReviewList({ tutorId, page })

  const handleChangePage = (page: number) => {
    setPage(page)
  }

  if (isLoading) return <PageLoadingComponent />

  return (
    <Wrapper id="tutor_review_list_area">
      <h3 className="title">{name} 튜터님의 강의 리뷰</h3>

      {!data ? (
        <EmptyBox>
          <EmptyIcon />
          등록된 리뷰가 없습니다.
        </EmptyBox>
      ) : (
        <>
          <div className="content">
            {data?.data.map((review) => (
              <TutorReviewComponent key={review.id} review={review} />
            ))}
          </div>
          <PageNation
            totalPage={Math.ceil(data.data.length / data.per_page)}
            page={page}
            onChangePage={handleChangePage}
          />
        </>
      )}
    </Wrapper>
  )
}

const EmptyBox = styled.div`
  padding: 160px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.62;
  color: #b5b5b9;

  & > svg {
    width: 38px;
    height: 38px;
    margin-bottom: 16px;
  }
`

const Wrapper = styled(AppRootWrapper)`
  margin-top: 80px;

  & > .title {
    font-weight: 700;
    font-size: 22px;
    line-height: 34px;
    color: #0d0d0e;
  }

  & > .content {
    margin-top: 30px;
    border-top: 1px solid #eeeff1;
  }

  ${media.medium} {
    margin-top: 65px;

    & > .title {
      font-size: 18px;
      line-height: 28px;
    }

    & > .content {
      margin-top: 20px;
    }
  }
`

export default TutorReviewListContainer
