import React, { createContext, useContext, useEffect, useMemo } from 'react'
import { useRoomSocket } from './RoomSocketProvider'
import useSWR from 'swr'
import {
  type ChatType,
  getChatListRequest,
  getTeamChatListRequest,
} from 'lib/api/chat'
import PageLoadingComponent from 'components/common/PageLoadingComponent'
import api from 'lib/api'
import * as io from 'socket.io-client'
import Echo from 'laravel-echo/dist/echo'
import { format } from 'date-fns'
import { dateFormat } from 'lib/utils'

type SendMessageTriggerType = ({
  message,
  team,
}: {
  message: string
  team?: 'A' | 'B'
}) => void

type ChatActionContextType =
  | {
      sendMessageTrigger: SendMessageTriggerType
    }
  | undefined

const ChatValueContext = createContext<
  | {
      data: {
        is_unread_normal: boolean
        chats: ChatType[]
      }
    }
  | undefined
>(undefined)

const ChatActionContext = createContext<ChatActionContextType>(undefined)

export function useChatValues() {
  const values = useContext(ChatValueContext)

  if (!values) throw new Error('not wrapped with ChatProvider')
  return values
}

export function useChatActions() {
  const actions = useContext(ChatActionContext)
  if (!actions) throw new Error('not wrapped with ChatProvider')
  return actions
}

interface RoomChatProviderProps {
  children: React.ReactNode
  roomId: string
  team?: 'A' | 'B'
}

const RoomChatProvider = ({
  children,
  roomId,
  team,
}: RoomChatProviderProps) => {
  const { data, isLoading, mutate } = useSWR(['chat', team], async (url) => {
    let response
    if (team) {
      response = await getTeamChatListRequest({
        roomId,
        team,
      })
    } else {
      response = await getChatListRequest({ roomId })
    }

    if (!response.success) return undefined
    return response.data
  })

  const sendMessageTrigger: SendMessageTriggerType = async ({
    message,
    team,
  }) => {
    await api.post('/rtc/chat/register', {
      message,
      class_id: roomId,
      step: 1,
      ...(team && { team }),
    })
  }

  const sendMessage = (data: ChatType) => {
    mutate((prev) => {
      if (!prev) return
      return {
        ...prev,
        chats: prev?.chats.concat(data),
      }
    })
  }

  const actions = useMemo(() => {
    return {
      sendMessageTrigger,
    }
  }, [])

  useEffect(() => {
    const echo = new Echo({
      host: 'https://api.debateon.fifteenh.io:6003',
      broadcaster: 'socket.io',
      client: io,
    })

    echo
      .channel(`laravel_database_chat${roomId}`)
      .listen('RtcHandler', (e: any) => {
        if (e.data.type === 'chat') {
          sendMessage({
            ...e.data,
            created_at: dateFormat(e.data.created_at, 'yyyy/MM/dd HH:mm:ss'),
          })
        }
      })

    return () => {
      echo.leaveChannel(`laravel_database_chat${roomId}`)
      echo.disconnect()
    }
  }, [])

  if (isLoading) return <PageLoadingComponent />
  if (!data) return <div></div>
  return (
    <ChatActionContext.Provider value={actions}>
      <ChatValueContext.Provider
        value={{
          data,
        }}
      >
        {children}
      </ChatValueContext.Provider>
    </ChatActionContext.Provider>
  )
}

export default RoomChatProvider
