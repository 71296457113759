import Footer from 'components/common/Footer'
import Header from 'components/common/header/Header'
import MobileTitleHeader from 'components/common/header/MobileTitleHeader'
import FeedBackComponent from 'components/common/myclass/FeedBackComponent'
import media from 'lib/styles/media'
import React from 'react'
import styled from 'styled-components'

function MyClassFeedbackPage() {
  return (
    <Wrapper>
      <Header />
      <MobileTitleHeader type="arrow" title="튜터 피드백" />
      <Content>
        <FeedBackComponent />
      </Content>
      <Footer />
    </Wrapper>
  )
}

export default MyClassFeedbackPage
const Wrapper = styled.div``
const Content = styled.div`
  margin-top: 100px;
  margin-bottom: 120px;
  ${media.medium} {
    margin-top: 0;
    margin-bottom: 80px;
  }
`
