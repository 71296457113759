import { Pagination } from 'antd'
import React from 'react'
import styled from 'styled-components'

interface Props {
  page: number
  total: number
  onChange: (page: number) => void
}
function PagiNationComponent({ page, total, onChange }: Props) {
  return (
    <Wrapper>
      <Pagination
        defaultCurrent={page}
        current={page}
        total={total}
        onChange={onChange}
      />
    </Wrapper>
  )
}

export default PagiNationComponent
const Wrapper = styled.div`
  & .ant-pagination {
    display: flex;
  }
  & .ant-pagination-item {
    margin-bottom: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    background: #fff;
    & > a {
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      color: #3b3b40;
      margin-bottom: 2px;
    }
  }
  & .ant-pagination-item-active {
    background: #7574d8;
    border: none;
    border-radius: 8px;
    & > a {
      color: #fff;
      margin-bottom: 2px;
    }
  }
  & .ant-pagination-item-active:hover {
    background: #7574d8;
    border: none;
    border-radius: 8px;
    & > a {
      color: #fff;
      margin-bottom: 2px;
    }
  }
`
