import { timeSettingModalState } from 'atoms/modal'
import RoomTimer from 'components/room/content/RoomTimer'
import TeamRoomMembers from 'components/room/content/TeamRoomMembers'
import TeamRoomTutor from 'components/room/content/TeamRoomTutor'
import { useRoomInfoValue } from 'components/room/provider/RoomInfoProvider'
import { add, format } from 'date-fns'
import { ko } from 'date-fns/locale'
import useRoom from 'lib/hooks/room/useRoom'
import media from 'lib/styles/media'
import React from 'react'
import { useParams } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import styled from 'styled-components'

interface TeamRoomContainerProps {}

function TeamRoomContainer({}: TeamRoomContainerProps) {
  const { team, roomId }: { team: 'A' | 'B'; roomId: string } = useParams()

  const { timer, role, membersRoomInfo, tutorRoomInfo, endDate } =
    useRoomInfoValue()

  const { closeTeamRoomTrigger, modifyTeamRoomTrigger } = useRoom(roomId)
  const setTimeSettingModal = useSetRecoilState(timeSettingModalState)

  const teamRoomMembers = (membersRoomInfo ?? []).filter(
    (member) => member.team === team,
  )

  const openTeamRoom = () => {
    const handleSettingTimer = async (time: number) => {
      const currentAt = new Date()

      const closedAt = add(currentAt, {
        seconds: time,
      })

      const formatDate = format(closedAt, 'yyyy/MM/dd HH:mm:ss', { locale: ko })

      await modifyTeamRoomTrigger({
        closedAt: formatDate,
        team,
      })
    }

    setTimeSettingModal({
      onSetting: handleSettingTimer,
      title: '회의 시간 설정',
      text: '설정한 회의시간이 소회의방에 카운트됩니다.',
      width: 'sm',
    })
  }

  const handleCloseTeamRoom = () => {
    closeTeamRoomTrigger(team, true)
  }

  return (
    <Wrapper>
      <div className="timer-wrapper">
        <RoomTimer
          date={endDate[team]}
          timer={timer[team]}
          role={role}
          onCancel={handleCloseTeamRoom}
          onSetting={openTeamRoom}
        />
      </div>
      <div className="members-wrapper">
        <TeamRoomMembers members={teamRoomMembers} />
      </div>
      <div className="tutor-wrapper">
        <div className="tutor-room-wrapper">
          {tutorRoomInfo?.team === team && (
            <TeamRoomTutor tutor={tutorRoomInfo} />
          )}
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  overflow-y: scroll;
  background: rgb(36, 36, 39);
  flex: 1;

  display: flex;
  flex-direction: column;

  ${media.pc} {
    padding: 30px 0;
    gap: 30px;
  }

  & .timer-wrapper {
    display: flex;
    justify-content: center;

    ${media.medium} {
      padding: 10px 0;
      order: 1;
    }
  }

  & .members-wrapper {
    flex: 1;

    ${media.pc} {
    }

    ${media.medium} {
      order: 1;
    }
  }

  & .tutor-wrapper {
    display: flex;
    justify-content: center;

    ${media.medium} {
      order: 0;
    }
  }

  & .tutor-room-wrapper {
    width: 278px;

    ${media.medium} {
      width: 100%;
    }
  }
`

export default TeamRoomContainer
