import { AppRootWrapper } from 'components/layout/CommonStyle'
import media from 'lib/styles/media'
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { ReactComponent as FooterLogo } from '../../assets/icons/footer_logo.svg'
import { ReactComponent as MobileFooterLogo } from '../../assets/icons/mobile_footer_logo_icon.svg'
import TermsPopup from './join/TermsPopup'
import { useRecoilState } from 'recoil'
import { PageTypeState } from 'atoms/common'
import { useHistory } from 'react-router-dom'
import useSWR from 'swr'

interface Props {
  type?: string
}
function Footer({ type }: Props) {
  const history = useHistory()
  const [popup, setPopup] = useState(false)
  const [isType, setIsType] = useState('')
  const [pageType, setPageType] = useRecoilState(PageTypeState)

  const data = useSWR(
    pageType && `/footer?type=${pageType === 'tutor' ? 'tutor' : 'user'}`,
  )

  useEffect(() => {
    if (popup) {
      document.documentElement.style.overflow = 'hidden'
    } else {
      document.documentElement.style.overflow = 'auto'
    }

    return () => {
      document.documentElement.style.overflow = 'auto'
    }
  }, [popup])

  const pageTypeChange = (type: string) => {
    if (type === 'tutor') {
      setPageType('tutor')
      history.push('/?role=tutor')
    } else {
      setPageType('user')
      history.push('/')
    }
  }

  const handlePopup = (type: string) => {
    const list =
      type === '서비스 이용약관'
        ? '서비스 이용 약관'
        : '개인정보 수집 및 처리방침'
    setIsType(list)
    setPopup(true)
  }
  const handleClose = () => {
    setPopup(false)
  }
  const handleBackClick = (e: any) => {
    if (e.target.id === 'popup') {
      if (popup) setPopup(false)
    }
  }
  if (!data) return null
  if (!data.data) return null

  return (
    <Wrapper className={!type ? '' : type}>
      <FooterTop>
        <FooterLogo className="web_icon" />
        <MobileFooterLogo className="mobile_icon" />
        <div className="service_contnet">
          <p
            onClick={() => {
              handlePopup('서비스 이용약관')
            }}
          >
            서비스 이용약관
          </p>
          <p
            onClick={() => {
              handlePopup('개인정보처리방침')
            }}
          >
            개인정보처리방침
          </p>
          {pageType === 'user' ? (
            <p
              className="page_move"
              onClick={() => {
                pageTypeChange('tutor')
              }}
            >
              튜터페이지 이동
            </p>
          ) : (
            <p
              className="page_move"
              onClick={() => {
                pageTypeChange('user')
              }}
            >
              학생페이지 이동
            </p>
          )}
        </div>
      </FooterTop>
      <Span />
      <FooterBottom>
        <div className="bottom_content">
          <p>상호명 : {data?.data?.data.footers[0].value}</p>
          <span />
          <p>대표이사 : {data?.data?.data.footers[1].value}</p>
        </div>
        <div className="bottom_content">
          <p>주소 : {data?.data?.data.footers[2].value}</p>
        </div>
        <div className="bottom_content mobile">
          <p className="mobile_number">
            사업자등록번호 : {data?.data?.data.footers[3].value}
          </p>
          <span className="mobile_span" />
          <p>통신판매업신고 : {data?.data?.data.footers[4].value}</p>
        </div>
        <div className="bottom_content">
          <p>고객센터 : {data?.data?.data.footers[5].value}</p>
        </div>
        <div className="bottom_content">
          <p>E-Mail : {data?.data?.data.footers[6].value}</p>
        </div>

        <div className="contents">
          <p>
            COPYRIGHT © {data?.data?.data.footers[7].value}. All right reserved
          </p>
        </div>
      </FooterBottom>
      {popup && (
        <TermsPopup
          title={isType}
          handleClose={handleClose}
          handleBackClick={handleBackClick}
          type="footer"
          pageType={pageType === 'tutor' ? 'tutor' : 'user'}
        />
      )}
    </Wrapper>
  )
}

export default Footer

const Wrapper = styled.div`
  width: 100%;
  height: 377px;
  background: #3b3b40;
  padding: 44px 0;
  ${media.medium} {
    height: 347px;
    background: #fff;
    padding: 22px 0;
  }
  &.bottom {
    ${media.medium} {
      margin-bottom: 60px;
    }
  }
  &.none {
    ${media.medium} {
      display: none;
    }
  }
`
const FooterTop = styled(AppRootWrapper)`
  & .web_icon {
    ${media.medium} {
      display: none;
    }
  }
  & .mobile_icon {
    display: none;
    ${media.medium} {
      display: flex;
    }
  }
  & .service_contnet {
    display: flex;
    align-items: center;
    margin: 16px 0 22px;
    & > p {
      font-weight: 700;
      font-size: 13px;
      line-height: 22px;
      color: #e1e1e2;
      cursor: pointer;
      ${media.medium} {
        color: #3b3b40;
        font-size: 12px;
      }
    }
    & > p:nth-of-type(n + 1) {
      margin-right: 17px;
    }
  }
`
const Span = styled.div`
  width: 100%;
  height: 1px;
  display: block;
  background: rgba(255, 255, 255, 0.1);
  ${media.medium} {
    background: #eeeff1;
  }
`
const FooterBottom = styled(AppRootWrapper)`
  padding-top: 22px;
  & div.bottom_content {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    ${media.medium} {
      margin-bottom: 2px;
      &.mobile {
        flex-direction: column;
        align-items: self-start;
      }
    }
    & p {
      font-weight: 400;
      font-size: 13px;
      line-height: 22px;
      color: #b5b5b9;
      ${media.medium} {
        font-size: 12px;
        line-height: 22px;
        &.mobile_number {
          margin-bottom: 2px;
        }
      }
    }
    & span {
      margin: 0 10px;
      width: 1px;
      height: 10px;
      border-right: 1px solid rgba(255, 255, 255, 0.2);
      ${media.medium} {
        &.mobile_span {
          display: none;
        }
      }
    }
  }
  & div.contents {
    margin-top: 22px;
    & > p {
      font-weight: 400;
      font-size: 12px;
      line-height: 22px;
      color: #7c7c80;
      ${media.medium} {
        font-size: 11px;
        line-height: 16px;
      }
    }
  }
`
