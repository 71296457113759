import { AppRootWrapper } from 'components/layout/CommonStyle'
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { ReactComponent as MainLogo } from '../../../assets/icons/tutor_main_logo.svg'
import { ReactComponent as MenuCloseLogo } from '../../../assets/icons/header_menu_close_icon.svg'
import { ReactComponent as MenuHoverCloseLogo } from '../../../assets/icons/header_menu_close_hover_icon.svg'
import { useHistory } from 'react-router-dom'
import media from 'lib/styles/media'

function TutorMenuHeader() {
  const history = useHistory()
  const [isMenu, SetIsMenu] = useState(false)
  const [position, SetPosition] = useState(window.pageYOffset)
  const [isVisiable, SetIsVisiable] = useState(true)

  useEffect(() => {
    const handleScroll = () => {
      const move = window.pageYOffset
      SetIsVisiable(position > move)
      SetPosition(move)
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [position])

  const handleMouseOver = () => {
    if (!isMenu) {
      SetIsMenu(true)
    }
  }
  const handleMouseOut = () => {
    if (isMenu) {
      SetIsMenu(false)
    }
  }

  return (
    <Wrapper>
      <HeaderContent>
        <LeftContent>
          <MainLogo
            onClick={() => {
              history.push('/')
            }}
          />
        </LeftContent>
        <RightContent>
          {isMenu ? (
            <MenuHoverCloseLogo
              onMouseOut={handleMouseOut}
              onClick={() => {
                history.push('/')
              }}
            />
          ) : (
            <MenuCloseLogo
              onMouseOver={handleMouseOver}
              onClick={() => {
                history.push('/')
              }}
            />
          )}
        </RightContent>
      </HeaderContent>
    </Wrapper>
  )
}

export default TutorMenuHeader
const Wrapper = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  position: fixed;
  left: 0;
  top: 0;
  background: #fff;
  border-bottom: 1px solid #eeeff1;
  z-index: 5;
  &.active {
    display: none;
  }
  ${media.medium} {
    display: none;
  }
`
const HeaderContent = styled(AppRootWrapper)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const LeftContent = styled.div`
  display: flex;
  align-items: center;
  & > svg {
    cursor: pointer;
  }
`
const RightContent = styled.div`
  display: flex;
  align-items: center;
`
