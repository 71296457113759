import { DatePicker, TimePicker } from 'antd'
import media from 'lib/styles/media'
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { ReactComponent as Arrow } from '../../assets/icons/join_select_button_arrow.svg'
import moment from 'moment'
import TutorStepTitleContent from './joinInfo/TutorStepTitleContent'
import dayjs from 'dayjs'

interface DateProps {
  day: string
  time: string
  estimated: any
  title: string
  step: number
  modify: string
}
interface Props {
  multi: boolean
  handleStepChange: (data: DateProps) => void
  step: number
  mode: 'add' | 'edit'
  handledateDisabled: (data: boolean) => void
  handleTitleDisabled: (data: boolean) => void
  day: string
  time: string
  title: string
  estimated: any
  modify: 'Y' | 'N'
}
function DatePickerContent({
  multi,
  handleStepChange,
  step,
  mode,
  handledateDisabled,
  handleTitleDisabled,
  day,
  time,
  title,
  estimated,
  modify,
}: Props) {
  const dateFormat = 'YYYY-MM-DD'
  const format = 'HH:mm'
  const timeDropIcon = <Arrow />
  const [today, setToday] = useState(false)
  const [formdata, setFormdata] = useState({
    day: '',
    time: '',
    estimated: '',
    title: '',
    step: step,
    modify: 'Y',
  })

  useEffect(() => {
    if (mode === 'add') return

    setFormdata({
      ...formdata,
      day: day,
      time: time,
      estimated: estimated ?? '',
      title: title,
      modify: modify ?? 'Y',
    })
    handleStepChange(formdata)
  }, [mode, day, time, estimated, title, modify])

  useEffect(() => {
    const datedisabled =
      formdata.day === '' || formdata.time === '' || formdata.estimated === ''
    const titledisabled =
      formdata.day === '' ||
      formdata.time === '' ||
      formdata.estimated === '' ||
      formdata.title === ''

    handledateDisabled(datedisabled)
    handleTitleDisabled(titledisabled)
    handleStepChange(formdata)
  }, [formdata])

  useEffect(() => {
    if (formdata.day) {
      const todayBoolean = !moment(formdata.day).isAfter(
        moment().format(dateFormat),
      )
      return setToday(todayBoolean)
    }
  }, [formdata.day])

  const handleChangeDate = (date: any) => {
    setFormdata({
      ...formdata,
      day: date,
    })
  }
  const handleTimeChange = (time: any) => {
    setFormdata({
      ...formdata,
      time: time !== '' ? time : '',
    })
  }
  const handleEstimated = (value: any) => {
    setFormdata({
      ...formdata,
      estimated: value,
    })
  }
  const handleTitle = (e: any) => {
    setFormdata({
      ...formdata,
      title: e.target.value,
    })
  }
  const disabledDate = (current: any) => {
    return current && current < moment().subtract(1, 'day').endOf('day')
  }
  const disabledHours = () => {
    const hours = []
    for (let i = 0; i < moment().hour(); i += 1) {
      if (today) hours.push(i)
    }
    return hours
  }

  const disabledMinutes = (selectedHour: any) => {
    const minutes = []
    if (selectedHour === moment().hour()) {
      for (let i = 0; i < moment().minute(); i += 1) if (today) minutes.push(i)
    }
    return minutes
  }
  return (
    <Wrapper>
      <DateContent>
        {mode === 'add' ? (
          <>
            <DatePicker
              onChange={(date, dateString) => {
                handleChangeDate(dateString)
              }}
              placeholder="날짜 선택"
              disabledDate={disabledDate}
            />
            <TimePicker
              minuteStep={30}
              format={format}
              suffixIcon={timeDropIcon}
              onChange={(date, dateString) => {
                handleTimeChange(dateString)
              }}
              placeholder="시간 선택"
              disabledHours={disabledHours}
              disabledMinutes={disabledMinutes}
            />
          </>
        ) : (
          <>
            <DatePicker
              format={dateFormat}
              onChange={(date, dateString) => {
                handleChangeDate(dateString)
              }}
              placeholder="날짜 선택"
              disabledDate={disabledDate}
              value={formdata.day ? dayjs(formdata.day, dateFormat) : null}
              disabled={modify === 'N'}
            />
            <TimePicker
              minuteStep={30}
              format={format}
              suffixIcon={timeDropIcon}
              onChange={(date, dateString) => {
                handleTimeChange(dateString)
              }}
              placeholder="시간 선택"
              value={formdata.time ? dayjs(formdata.time, format) : null}
              disabled={modify === 'N'}
              disabledHours={disabledHours}
              disabledMinutes={disabledMinutes}
            />
          </>
        )}

        <div className="input_box">
          <input
            className={modify === 'N' ? 'disabled' : ''}
            type="number"
            value={formdata.estimated ?? ''}
            onChange={(e) => {
              if (e.target.value.length > 3) {
                return
              }
              handleEstimated(e.target.value)
            }}
            disabled={modify === 'N'}
          />
          <p className="input_min">분</p>
        </div>
      </DateContent>
      {multi && (
        <TutorStepTitleContent
          title={formdata.title}
          handleChange={handleTitle}
          disabled={modify === 'N'}
        />
      )}
    </Wrapper>
  )
}

export default DatePickerContent
const Wrapper = styled.div`
  margin-top: 10px;
`
const DateContent = styled.div`
  display: flex;
  align-items: baseline;
  ${media.medium} {
    flex-flow: wrap;
    justify-content: space-between;
  }

  & > div:first-of-type {
    width: 380px;
    padding: 11px 14px;
    ${media.medium} {
      width: 100%;
    }
  }
  & > div:nth-of-type(2) {
    width: 185px;
    padding: 11px 14px;
    margin-left: 10px;
    ${media.medium} {
      display: inline-block;
      margin-left: 0;
      margin-top: 10px;
      width: calc(50% - 5px);
    }
  }
  & > div:nth-of-type(3) {
    ${media.medium} {
      display: inline-block;
      width: calc(50% - 5px);
      margin-left: 0px;
    }
  }
  & div.input_box {
    width: 185px;
    margin-left: 10px;
    position: relative;
    & input {
      width: 100%;
      background: #ffffff;
      border: 1px solid #e1e1e2;
      border-radius: 8px;
      padding: 11px 14px;
      outline: none;
      ${media.medium} {
        height: 45px;
        border-radius: 6px;
      }
      &::placeholder {
        font-weight: 700;
        font-size: 14px;
        line-height: 24px;
        color: #b5b5b9;
      }
      &.disabled {
        background: rgba(0, 0, 0, 0.04);
        color: rgba(0, 0, 0, 0.25);
      }
    }
    & p.input_min {
      position: absolute;
      right: 14px;
      top: 50%;
      z-index: 2;
      transform: translateY(-50%);
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      text-align: right;
      color: #b5b5b9;
    }
  }
`
