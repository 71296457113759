import React from 'react'
import styled from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Autoplay, Pagination } from 'swiper'
import media from 'lib/styles/media'
SwiperCore.use([Autoplay])

interface BannerProps {
  data: Array<{
    id: number
    image_url: string
    link: string
  }>
}
function MainBannerComponent({ data }: BannerProps) {
  return (
    <Wrapper>
      <BannerWra
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        className="mySwiper"
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        loop={true}
      >
        {data.map((item, i) => (
          <SwiperSlide
            key={`root_main_banner_list_${i}`}
            onClick={() => {
              window.open(item.link)
            }}
          >
            <img src={item.image_url} alt="banner" />
          </SwiperSlide>
        ))}
      </BannerWra>
    </Wrapper>
  )
}
export default MainBannerComponent

const Wrapper = styled.div`
  width: 800px;
  height: 440px;
  border-radius: 14px;
  display: flex;
  overflow: hidden;
  ${media.medium} {
    width: 100%;
    height: 176px;
  }
`
const BannerWra = styled(Swiper)`
  display: flex;
  width: 800px;
  max-width: 800px;
  min-width: 800px;
  height: 440px;
  position: relative;
  ${media.medium} {
    width: 100%;
    max-width: 100%;
    min-width: 320px;
    height: 176px;
  }
  & .swiper-wrapper {
    display: flex;
    align-items: flex-start;
  }
  & .swiper-slide {
    border-radius: 14px;
    overflow: hidden;
    & img {
      width: 100%;
      object-fit: contain;
    }
  }
  & .swiper-pagination {
    position: absolute;
    left: 60px;
    bottom: 50px;
    z-index: 1;
    display: flex;
    align-items: center;
    ${media.medium} {
      left: 22px;
      bottom: 22px;
    }
  }
  & .swiper-pagination-bullet {
    width: 7px;
    height: 7px;
    border: 1px solid #ffffff;
    border-radius: 100px;
    margin-right: 6px;
  }
  & .swiper-pagination-bullet-active {
    width: 18px;
    height: 7px;
    background: #ffffff;
    border-radius: 100px;
  }
`
