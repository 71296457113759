import ChatInput from 'components/room/chat/ChatInput'
import ChatList from 'components/room/chat/ChatList'
import RoomChatProvider from 'components/room/provider/RoomChatProvider'
import { useRoomInfoValue } from 'components/room/provider/RoomInfoProvider'
import React from 'react'
import { useParams } from 'react-router-dom'
import { ChatContainerWrapper } from './ChatContainer'

function TeamChatContainer() {
  const { roomId }: { roomId: string } = useParams()
  const { myRoomInfo } = useRoomInfoValue()

  return (
    <RoomChatProvider roomId={roomId} team={myRoomInfo?.currentTeam}>
      <ChatContainerWrapper>
        <ChatList />
        <ChatInput team={myRoomInfo?.currentTeam} />
      </ChatContainerWrapper>
    </RoomChatProvider>
  )
}

export default TeamChatContainer
