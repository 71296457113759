import { AppRootWrapper } from 'components/layout/CommonStyle'
import type { MyClassDetailDataProps } from 'lib/types/account'
import React from 'react'
import styled from 'styled-components'
import NoneData from '../NoneData'
import MyClassList from './MyClassList'
import useSWR from 'swr'
import media from 'lib/styles/media'

function MyClassComponent() {
  const { data: originData } = useSWR('/class/my')
  if (!originData) return null
  if (!originData.data) return null

  return (
    <Wrapper>
      <Title>
        <p>강의 이력</p>
      </Title>
      <Content>
        {originData.data.class_users.length > 0 ? (
          originData.data.class_users.map(
            (item: MyClassDetailDataProps, i: number) => (
              <MyClassList key={`myclass_data_list_${i}`} data={item} />
            ),
          )
        ) : (
          <NoneData title="강의 이력이" />
        )}
      </Content>
    </Wrapper>
  )
}

export default MyClassComponent

const Wrapper = styled(AppRootWrapper)`
  ${media.medium} {
    width: 100%;
  }
`
const Title = styled.div`
  padding-top: 40px;
  & > p {
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: #0d0d0e;
  }
  ${media.medium} {
    display: none;
  }
`
const Content = styled.div`
  padding-top: 32px;
  margin-bottom: 120px;
  ${media.medium} {
    padding-top: 0;
    margin-bottom: 80px;
  }
`
