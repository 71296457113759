import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { ReactComponent as Icon } from '../../../assets/icons/payment_class_setting_icon.svg'
import { PriceNum, paymentStaus } from 'lib/utils/CommonFuncttion'
import { useHistory } from 'react-router-dom'
import media from 'lib/styles/media'

interface Props {
  handleCancelClick: (id: number) => void
  title: string
  nickname?: string
  price: number
  orderNum: string
  id: number
  type: string
  popup: boolean
  userId: number
  image: string
  userProfile?: string
  userNickname?: string
  pageType: string
  multi: number | undefined
  step?: string | number
  stepTitle?: string | number
  handleTutorCancel: (id: number, multi: number | undefined) => void
  handleCancelReject: (id: number) => void
  handleCancelAccept: (id: number) => void
  status: number
  cancelId: number
}
function PaymentClassComponent({
  handleCancelClick,
  title,
  nickname,
  price,
  orderNum,
  id,
  type,
  popup,
  userId,
  image,
  userProfile,
  userNickname,
  pageType,
  multi,
  step,
  stepTitle,
  handleTutorCancel,
  handleCancelReject,
  handleCancelAccept,
  status,
  cancelId,
}: Props) {
  const history = useHistory()
  const [ispopup, setIsPopup] = useState(false)

  useEffect(() => {
    if (popup) {
      setIsPopup(false)
    }
  }, [popup])

  const handleClick = (e: any) => {
    e.stopPropagation()
    if (ispopup) {
      setIsPopup(false)
    } else {
      setIsPopup(true)
    }
  }

  const handleClassClick = (e: any) => {
    e.stopPropagation()
    pageType === 'tutor'
      ? history.push(`/payment/detail/${id}&role=tutor`)
      : history.push(`/payment/detail/${id}`)
  }

  return (
    <Wrapper onClick={handleClassClick} id="content">
      <div className="class_info_box">
        <p className="class_number">
          {id} {type === 'cancel' && <span>{paymentStaus(status)}</span>}
        </p>
        {(type.includes('payment') || type.includes('tutor_cancel')) && (
          <Icon onClick={handleClick} />
        )}
      </div>
      <div className="class_content_box">
        {image ? <img src={image} className="image_url" /> : <Div />}
        <div className="class_text_box">
          <p className="class_title">{title}</p>
          {pageType === 'tutor' ? (
            <>
              {stepTitle && <p className="class_step_title">{stepTitle}</p>}
              <p className="class_nickname">{userNickname}</p>
            </>
          ) : (
            <>
              {stepTitle && <p className="class_step_title">{stepTitle}</p>}
              <p className="class_nickname">{nickname}</p>
            </>
          )}

          <p className="class_price">{PriceNum(price)}원</p>
        </div>
      </div>
      {ispopup &&
        (type.includes('tutor_cancel') ? (
          <Content id="cancel">
            <p
              onClick={(e) => {
                e.stopPropagation()
                handleCancelReject(cancelId)
                setIsPopup(false)
              }}
            >
              취소 신청 반려
            </p>
            <p
              onClick={(e) => {
                e.stopPropagation()
                handleCancelAccept(cancelId)
                setIsPopup(false)
              }}
            >
              취소 신청 승인
            </p>
          </Content>
        ) : (
          <Content id="cancel">
            <p
              onClick={(e) => {
                e.stopPropagation()
                if (pageType === 'tutor') {
                  handleTutorCancel(id, multi)
                  setIsPopup(false)
                } else {
                  handleCancelClick(userId)
                }
              }}
            >
              결제 취소
            </p>
          </Content>
        ))}
    </Wrapper>
  )
}

export default PaymentClassComponent
const Wrapper = styled.div`
  width: 590px;
  min-height: 180px;
  background: #ffffff;
  border: 1px solid #e1e1e2;
  border-radius: 14px;
  padding: 16px;
  position: relative;
  cursor: pointer;
  ${media.medium} {
    width: 100%;
    min-height: 145px;
    padding: 14px 16px;
  }
  & div.class_info_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
  }
  & p.class_number {
    font-weight: 900;
    font-size: 16px;
    line-height: 26px;
    color: #3b3b40;
    & > span {
      font-weight: 700;
      font-size: 15px;
      line-height: 24px;
      color: #b5b5b9;
      margin-left: 6px;
    }
  }
  & div.class_content_box {
    margin-top: 12px;
    display: flex;
    align-items: center;
    ${media.medium} {
      align-items: flex-start;
    }
  }
  & img.image_url {
    width: 210px;
    min-width: 210px;
    height: 118px;
    border-radius: 8px;
    ${media.medium} {
      width: 90px;
      min-width: 90px;
      height: 50px;
    }
  }
  & div.class_text_box {
    margin-left: 16px;
  }
  & p.class_title {
    font-weight: 700;
    font-size: 16px;
    line-height: 26px;
    color: #3b3b40;
    overflow: hidden;
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    ${media.medium} {
      font-size: 14px;
      line-height: 24px;
    }
  }
  & p.class_step_title {
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    color: #3b3b40;
    margin-top: 3px;
    ${media.medium} {
      font-size: 13px;
      line-height: 22px;
    }
  }
  & p.class_nickname {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #7c7c80;
    margin: 6px 0 10px;
    ${media.medium} {
      font-size: 13px;
      line-height: 22px;
      margin: 1px 0 4px;
    }
  }
  & p.class_price {
    font-weight: 900;
    font-size: 16px;
    line-height: 26px;
    color: #0d0d0e;
  }
`
const Content = styled.div`
  width: 200px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 11px 14px;
  position: absolute;
  right: 16px;
  top: 50px;
  & p {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #3b3b40;
  }
  & p:nth-of-type(2) {
    margin-top: 22px;
  }
`
const Div = styled.div`
  width: 210px;
  min-width: 210px;
  height: 118px;
  border-radius: 8px;
  background: #e1e1e2;
  ${media.medium} {
    width: 90px;
    min-width: 90px;
    height: 50px;
  }
`
