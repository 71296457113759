import React, { useEffect } from 'react'
import Footer from 'components/common/Footer'
import TutorGnb from 'components/common/TutorGnb'
import MobileTitleHeader from 'components/common/header/MobileTitleHeader'
import TutorHeader from 'components/common/header/TutorHeader'
import ManageListComponent from 'components/common/manage/ManageListComponent'
import media from 'lib/styles/media'
import styled from 'styled-components'
import useUser from 'lib/hooks/account/useUser'
import { useHistory } from 'react-router-dom'

const ManageListPage = () => {
  const history = useHistory()
  const { user } = useUser()
  const tutorUser = user && user.type === 'tutor'

  useEffect(() => {
    if (!tutorUser) return history.push('/login?role=tutor')
  }, [])

  return (
    <Wrapper>
      <TutorHeader />
      <MobileTitleHeader title="강의 관리" />
      <Content>
        <ManageListComponent />
      </Content>
      <Footer type="none" />
      <TutorGnb />
    </Wrapper>
  )
}

export default ManageListPage

const Wrapper = styled.div``
const Content = styled.div`
  margin: 100px auto 120px;
  ${media.medium} {
    width: 100%;
    margin: 0 auto 80px;
  }
`
