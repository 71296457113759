import { PageTypeState } from 'atoms/common'
import Footer from 'components/common/Footer'
import MobileTitleHeader from 'components/common/header/MobileTitleHeader'
import StudentHeader from 'components/common/header/StudentHeader'
import TutorHeader from 'components/common/header/TutorHeader'
import ReferenceComponent from 'components/common/reference/ReferenceComponent'
import useUser from 'lib/hooks/account/useUser'
import media from 'lib/styles/media'
import React from 'react'
import { useRecoilState } from 'recoil'
import styled from 'styled-components'

const ReferencePage = () => {
  const { user } = useUser()
  const typeUser = user && user.type

  return (
    <Wrapper className={typeUser}>
      {typeUser === 'user' ? <StudentHeader /> : <TutorHeader />}
      <MobileTitleHeader type="arrow" title="레퍼런스룸" />
      <Content>
        <ReferenceComponent type={typeUser} />
      </Content>
      <Footer />
    </Wrapper>
  )
}

export default ReferencePage

const Wrapper = styled.div`
  ${media.medium} {
    &.tutor {
      padding-bottom: 90px;
    }
  }
`
const Content = styled.div`
  margin: 100px auto 120px;
  ${media.medium} {
    width: 100%;
    margin: 0 auto 80px;
  }
`
