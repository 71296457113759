import { AppRootWrapper } from 'components/layout/CommonStyle'
import React from 'react'
import styled from 'styled-components'
import { PriceNum } from 'lib/utils/CommonFuncttion'
import NoticeListComponent from './NoticeListComponent'
import type { NoticeDataProps } from 'lib/types/account'
import { useHistory } from 'react-router-dom'
import media from 'lib/styles/media'

function NoticeComponent({ data, total }: NoticeDataProps) {
  const history = useHistory()
  const onClick = (id: number) => {
    history.push(`/notice/${id}?role=tutor`)
  }
  return (
    <Wrapper>
      <NoticeTitle>
        <p className="notice_title">공지사항</p>
        <p className="notice_text">
          <span>{PriceNum(total)}</span>건
        </p>
      </NoticeTitle>
      <Contnet>
        {data.map((item, i) => (
          <NoticeListComponent
            key={`notice_list_${i}`}
            title={item.title}
            date={item.created_at}
            onClick={() => onClick(item.id)}
          />
        ))}
      </Contnet>
    </Wrapper>
  )
}

export default NoticeComponent

const Wrapper = styled.div`
  min-height: 600px;
  ${media.medium} {
    min-height: 400px;
  }
`
const NoticeTitle = styled(AppRootWrapper)`
  & .notice_title {
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: #0d0d0e;
    ${media.medium} {
      display: none;
    }
  }
  & .notice_text {
    font-weight: 400;
    font-size: 13px;
    line-height: 22px;
    color: #0d0d0e;
    margin-top: 32px;
    ${media.medium} {
      margin-top: 0;
      font-size: 12px;
    }
    & > span {
      font-weight: 700;
      font-size: 13px;
      line-height: 22px;
      color: #7574d8;
      ${media.medium} {
        font-size: 12px;
      }
    }
  }
`
const Contnet = styled(AppRootWrapper)`
  margin-top: 10px;
  max-width: 1240px;
  ${media.medium} {
    margin-top: 10px;
    width: 100%;
  }
`
