import Footer from 'components/common/Footer'
import TutorHeader from 'components/common/header/TutorHeader'
import TutorIdLoginComponent from 'components/common/iid/TutorIdLoginComponent'
import media from 'lib/styles/media'
import React from 'react'
import styled from 'styled-components'

function TutorLogin() {
  return (
    <Wrapper>
      <TutorHeader />
      <Content>
        <TutorIdLoginComponent />
      </Content>
      <Footer />
    </Wrapper>
  )
}

export default TutorLogin
const Wrapper = styled.div``
const Content = styled.div`
  margin-top: 100px;
  ${media.medium} {
    margin-top: 0;
  }
`
