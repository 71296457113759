import type { UserInfo } from 'lib/types/account'
import React, { useEffect } from 'react'
import StudentJoininfo from './StudentJoininfo'
import TutorJoininfo from './TutorJoininfo'
import { useRecoilState } from 'recoil'
import { PageTypeState } from 'atoms/common'

interface JoinPageProps {
  user?: UserInfo
}

function JoininfoPage({ user }: JoinPageProps) {
  const [pageType, setPageType] = useRecoilState(PageTypeState)

  if (pageType === 'tutor') return <TutorJoininfo />
  return <StudentJoininfo />
}

export default JoininfoPage
