import RoomMobileHeader from 'components/room/common/RoomMobileHeader'
import ReferenceContainer from 'containers/room/side/ReferenceContainer'
import React from 'react'
import { MobileRoomWrapper } from './RoomParticipantList'

function RoomReference() {
  return (
    <MobileRoomWrapper>
      <RoomMobileHeader title="자료" />
      <ReferenceContainer />
    </MobileRoomWrapper>
  )
}

export default RoomReference
