import RoomMobileHeader from 'components/room/common/RoomMobileHeader'
import ParticipantList from 'components/room/side/ParticipantList'
import media from 'lib/styles/media'
import React from 'react'
import styled from 'styled-components'

function RoomParticipantList() {
  return (
    <MobileRoomWrapper>
      <RoomMobileHeader title="참가자" />
      <ParticipantList />
    </MobileRoomWrapper>
  )
}

export const MobileRoomWrapper = styled.div`
  z-index: 100;
  ${media[1400]} {
    display: none;
  }

  display: flex;
  flex-direction: column;

  position: fixed;
  left: 0;
  top: 0;

  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);

  background: rgba(13, 13, 14, 1);
`

export default RoomParticipantList
