import StudentClassComponent from 'components/common/class/StudentClassComponent'
import Footer from 'components/common/Footer'
import Gnb from 'components/common/Gnb'
import Header from 'components/common/header/Header'
import MobileTitleHeader from 'components/common/header/MobileTitleHeader'
import media from 'lib/styles/media'
import React from 'react'
import styled from 'styled-components'

function IntendedClass() {
  return (
    <Wrapper>
      <Header />
      <MobileTitleHeader title="예정된 강의" />
      <Contents>
        <StudentClassComponent />
      </Contents>
      <Footer />
      <Gnb />
    </Wrapper>
  )
}

export default IntendedClass
const Wrapper = styled.div`
  ${media.medium} {
    padding-bottom: 60px;
  }
`
const Contents = styled.div`
  margin: 140px auto 120px;
  ${media.medium} {
    margin: 10px auto 80px;
  }
`
