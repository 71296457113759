import media from 'lib/styles/media'
import React from 'react'
import styled from 'styled-components'

interface Porps {
  handleClick: any
  location: string
  pageType: 'user' | 'tutor'
}
function PaymentMenuTab({ handleClick, location, pageType }: Porps) {
  const tab = [
    {
      name: '결제 완료',
      link: '?type=payment',
    },
    {
      name: '수강 완료',
      link: '?type=success',
    },
    {
      name: '수강 취소',
      link: '?type=cancel',
    },
  ]
  const TutorTab = [
    {
      name: '결제 완료',
      link: '?type=tutor_payment',
    },
    {
      name: '수강 완료',
      link: '?type=tutor_success',
    },
    {
      name: '정산 완료',
      link: '?type=tutor_price_success',
    },
    {
      name: '취소 신청',
      link: '?type=tutor_cancel',
    },
    {
      name: '결제 취소',
      link: '?type=tutor_price_cancel',
    },
  ]
  return (
    <Wrapper>
      {pageType === 'tutor' ? (
        <>
          {TutorTab.map((item, i) => (
            <div
              key={`my_class_payment_menu_tab_${i}`}
              onClick={() => {
                handleClick(`${item.link}&role=tutor`)
              }}
              className={
                location.includes(item.link)
                  ? 'menu_tab_content tutor active'
                  : 'menu_tab_content tutor'
              }
            >
              <p className="menu_tab_text">{item.name}</p>
            </div>
          ))}
        </>
      ) : (
        <>
          {tab.map((item, i) => (
            <div
              key={`my_class_payment_menu_tab_${i}`}
              onClick={() => {
                handleClick(item.link)
              }}
              className={
                location === item.link
                  ? 'menu_tab_content active'
                  : 'menu_tab_content'
              }
            >
              <p className="menu_tab_text">{item.name}</p>
            </div>
          ))}
        </>
      )}
    </Wrapper>
  )
}

export default PaymentMenuTab
const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e1e1e2;
  ${media.medium} {
    width: calc(100% + 40px);
    transform: translateX(-20px);
  }
  & div.menu_tab_content {
    width: calc(100% / 3);
    &.tutor {
      width: calc(100% / 5);
    }
    &.active {
      border-bottom: 2px solid #3b3b40;
      & > p {
        color: #0d0d0e;
        font-weight: 700;
      }
    }
  }
  & p.menu_tab_text {
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #b5b5b9;
    padding-bottom: 16px;
    cursor: pointer;
    ${media.medium} {
      font-size: 14px;
      line-height: 24px;
      padding-bottom: 14px;
    }
  }
`
