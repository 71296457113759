import { AppRootWrapper } from 'components/layout/CommonStyle'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ReactComponent as UserIcon } from '../../../assets/icons/myinfo_use_icon.svg'
import { ReactComponent as PaymentIcon } from '../../../assets/icons/tutor_main_payment_icon.svg'
import { ReactComponent as AlarmIcon } from '../../../assets/icons/tutor_main_alarm_icon.svg'
import { ReactComponent as NoticeIcon } from '../../../assets/icons/main_notice_icon.svg'
import { ReactComponent as ReferenceIcon } from '../../../assets/icons/mypage_main_reference_icon.svg'
import Button from '../Button'
import { useHistory } from 'react-router-dom'
import useUser from 'lib/hooks/account/useUser'
import { Switch } from 'antd'
import TermsPopup from '../join/TermsPopup'
import media from 'lib/styles/media'
import auth from 'lib/utils/auth'
import { useRecoilState } from 'recoil'
import { PageTypeState } from 'atoms/common'
import useIndex from 'lib/hooks/useIndex'
import api from 'lib/api'
import { PriceNum } from 'lib/utils/CommonFuncttion'

function TutorMenuComponent() {
  const history = useHistory()
  const { data, mutate } = useIndex()
  const { user } = useUser()
  const tutorUser = user && user.type === 'tutor'
  const [checked, setChecked] = useState(false)
  const [terms, setTerms] = useState('')
  const [popup, setPopup] = useState(false)

  const [pageType, setPageType] = useRecoilState(PageTypeState)

  const Icon = [
    {
      key: 1,
      title: '정산 완료',
      icon: PaymentIcon,
      history: '/payment?type=tutor_price_success',
    },
    {
      key: 2,
      title: '알림',
      icon: AlarmIcon,
      history: '/alarm',
    },
    {
      key: 3,
      title: '레퍼런스룸',
      icon: ReferenceIcon,
      history: '/reference',
    },
    {
      key: 4,
      title: '공지사항',
      icon: NoticeIcon,
      history: '/notice',
    },
  ]

  useEffect(() => {
    if (!data?.data.user) return
    if (data?.data.user.use_marketing_alarm === 1) {
      setChecked(true)
    }
  }, [data])

  useEffect(() => {
    if (popup) {
      document.documentElement.style.overflow = 'hidden'
    } else {
      document.documentElement.style.overflow = 'auto'
    }

    return () => {
      document.documentElement.style.overflow = 'auto'
    }
  }, [popup])

  const handleChange = async (checked: boolean) => {
    if (!checked) {
      setChecked(false)
    } else {
      setChecked(true)
    }

    const checkNum = checked ? 1 : 0
    const formData = new FormData()
    formData.append('is_marketing', String(checkNum))

    const {
      data: { success, alert },
    } = await api.post('/mypage/marketing', formData)

    if (success) {
      mutate()
    } else {
      window.alert(alert)
    }
  }
  const handlePopup = (type: string) => {
    setTerms(type)
    setPopup(true)
  }

  const handleClose = () => {
    setPopup(false)
  }
  const handleBackClick = (e: any) => {
    if (e.target.id === 'popup') {
      if (popup) setPopup(false)
    }
  }

  const handleLogout = () => {
    if (user.type === 'user') return
    auth.clearToken()
    localStorage.removeItem('save_refresh_token')
    window.location.href = '/?role=tutor'
  }

  return (
    <Wrapper>
      {tutorUser ? (
        <InfoContent>
          <div className="info_content">
            {user.profile_url ? (
              <img src={user.profile_url} className="user_profile_image" />
            ) : (
              <UserIcon />
            )}

            <p className="tutor_user_info_title">
              <span>{user.nickname}</span>
              <br />
              튜터님 안녕하세요!
            </p>
          </div>
          <div className="tutor_info_login_button">
            <Button
              title="프로필 수정"
              onClick={() => {
                history.push('/mypage/edit/info?role=tutor')
              }}
              className="border"
            />
          </div>
        </InfoContent>
      ) : (
        <InfoContent>
          <div className="info_content">
            <UserIcon className="info_image" />
            <p className="tutor_info_title">
              튜터 회원가입 후<br />
              디베이팅 강의를 등록해보세요!
            </p>
          </div>
          <div className="tutor_info_login_button">
            <Button
              title="로그인"
              onClick={() => {
                history.push('/login?role=tutor')
              }}
            />
          </div>
        </InfoContent>
      )}

      <IconContent>
        {Icon.map((item, i) => (
          <div
            key={`tutor_main_myinfo_icon_list_${i}`}
            className="tutor_icon_content"
            onClick={() => {
              if (tutorUser) {
                history.push(`${item.history}?role=tutor`)
              } else {
                history.push('/login?role=tutor')
              }
            }}
          >
            <item.icon className="tutor_icon" />
            <p className="tutor_icon_title">{item.title}</p>
            {i === 0 && data?.data?.user?.calculate_price && (
              <Price>{PriceNum(data?.data?.user?.calculate_price)}원</Price>
            )}
            {i === 1 && data?.data?.user?.is_new_message === 'Y' && <Dot />}
          </div>
        ))}
      </IconContent>
      <Line />
      <CategoryContent>
        <div
          className="category_box"
          onClick={() => handlePopup('서비스 이용 약관')}
        >
          <p>서비스 이용약관</p>
        </div>
        <div
          className="category_box"
          onClick={() => handlePopup('개인정보 수집 및 처리방침')}
        >
          <p>개인정보 처리방침</p>
        </div>
        {tutorUser && (
          <>
            <div className="marketing_box">
              <p>마케팅 정보 수신 동의</p>
              <Switch checked={checked} onChange={handleChange} />
            </div>
            <div className="logout_box" onClick={handleLogout}>
              <p>로그아웃</p>
            </div>
          </>
        )}
      </CategoryContent>
      {popup && (
        <TermsPopup
          title={terms}
          handleClose={handleClose}
          handleBackClick={handleBackClick}
          type="footer"
          pageType={pageType}
        />
      )}
    </Wrapper>
  )
}

export default TutorMenuComponent
const Wrapper = styled(AppRootWrapper)``
const InfoContent = styled.div`
  width: 100%;
  height: 150px;
  background: #ffffff;
  border: 1px solid #e1e1e2;
  border-radius: 14px;
  padding: 35px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${media.medium} {
    height: 168px;
    padding: 25px;
    flex-direction: column;
    align-items: baseline;
  }

  & div.info_content {
    display: flex;
    align-items: center;
  }
  & svg.info_image {
    ${media.medium} {
      width: 60px;
      height: 60px;
    }
  }
  & p.tutor_info_title {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: #3b3b40;
    margin-left: 20px;
    flex: 1;
    ${media.medium} {
      font-size: 14px;
      line-height: 24px;
      margin-left: 16px;
    }
  }
  & img.user_profile_image {
    width: 80px;
    height: 80px;
    border-radius: 100%;
    object-fit: cover;
    ${media.medium} {
      width: 60px;
      height: 60px;
    }
  }
  & p.tutor_user_info_title {
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #3b3b40;
    margin-left: 20px;
    flex: 1;
    ${media.medium} {
      font-size: 14px;
      line-height: 24px;
      margin-left: 16px;
    }
    & > span {
      font-weight: 700;
    }
  }
  & div.tutor_info_login_button {
    width: 200px;
    ${media.medium} {
      margin-top: 22px;
      width: 100%;
    }
  }
`
const IconContent = styled.div`
  margin-top: 60px;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  ${media.medium} {
    margin-top: 26px;
  }
  & div.tutor_icon_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(100% / 4);
    position: relative;
  }
  & div.tutor_icon_content::after {
    content: '';
    width: 1px;
    height: 70px;
    position: absolute;
    right: 0;
    background: rgb(238, 239, 241);
    ${media.medium} {
      display: none;
    }
  }
  & div.tutor_icon_content:last-of-type::after {
    content: '';
    display: none;
  }
  & p.tutor_icon_title {
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    text-align: center;
    color: #3b3b40;
    margin-top: 8px;
    cursor: pointer;
    ${media.medium} {
      font-size: 12px;
    }
  }
  & svg.tutor_icon {
    width: 46px;
    height: 46px;
    ${media.medium} {
      width: 34px;
      height: 34px;
    }
  }
`
const Line = styled.div`
  width: 100%;
  height: 1px;
  background: #e1e1e2;
  margin-top: 60px;
  ${media.medium} {
    height: 8px;
    width: calc(100% + 40px);
    transform: translateX(-20px);
    margin-top: 24px;
    background: #f9f9fb;
  }
`
const CategoryContent = styled.div`
  margin-top: 42px;
  ${media.medium} {
    margin-top: 0;
    width: calc(100% + 40px);
    transform: translateX(-20px);
  }
  & div.category_box {
    width: 100%;
    padding: 20px 18px;
    border-radius: 8px;
    background: #fff;
    display: flex;
    align-items: center;
    ${media.medium} {
      padding: 18px 20px;
      border-radius: 0;
    }
    & > p {
      font-weight: 500;
      font-size: 16px;
      line-height: 26px;
      color: #0d0d0e;
      cursor: pointer;
      ${media.medium} {
        font-size: 14px;
        line-height: 24px;
      }
    }
    &:hover {
      background: #f9f9fb;
    }
  }
  & div.marketing_box {
    width: 100%;
    padding: 20px 18px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    ${media.medium} {
      padding: 18px 20px;
    }
    & > p {
      font-weight: 500;
      font-size: 16px;
      line-height: 26px;
      color: #0d0d0e;
      cursor: pointer;
      ${media.medium} {
        font-size: 14px;
        line-height: 24px;
      }
    }
    & .ant-switch.ant-switch-checked {
      background: #7574d8;
    }
    & .ant-switch.ant-switch-checked:hover {
      background: #7574d8;
    }
    & .ant-switch {
      background: #e1e1e2;
    }
    & .ant-switch:hover {
      background: #e1e1e2;
    }
  }
  & div.logout_box {
    width: 100%;
    padding: 20px 18px;
    background: #fff;
    display: flex;
    align-items: center;
    ${media.medium} {
      padding: 18px 20px;
    }
    & > p {
      font-weight: 500;
      font-size: 16px;
      line-height: 26px;
      color: #7c7c80;
      cursor: pointer;
      ${media.medium} {
        font-size: 14px;
        line-height: 24px;
      }
    }
  }
`
const Dot = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background: #ff2c2c;
  position: absolute;
  right: 42%;
`
const Price = styled.p`
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: #7574d8;
  cursor: pointer;
`
