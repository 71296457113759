import Button from 'components/common/Button'
import ClassInfo from 'components/common/class/ClassInfo'
import { MyClassContentWrapper } from 'components/common/myclass/MyClassReviewComponent'
import MyClassTitle from 'components/common/myclass/MyClassTitle'
import { AppRootWrapper } from 'components/layout/CommonStyle'
import { type ClassDetailType } from 'lib/types/class'
import { classDateFormat } from 'lib/utils'
import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { MyClassButtonBox } from './MyClassEndStudentContainer'

interface Props {
  data: ClassDetailType
  step: string
  classId: string
}

function MyClassEndTutorContainer({ data, step, classId }: Props) {
  const history = useHistory()

  const handleSubmit = () => {
    history.push(`/class/${classId}`)
  }
  const handleLater = () => {
    history.push('/')
  }

  const stepData = useMemo(() => {
    return data.steps?.find((stepData) => {
      return Number(stepData.step) === Number(step)
    })
  }, [data?.steps, step])

  const date = classDateFormat(data)

  return (
    <Wrapper>
      <MyClassTitle
        mainTitle="강의 종료"
        subTitle="강의가 종료되었습니다. 수강생에 대한 리뷰를 남겨주세요!"
      />
      <MyClassContentWrapper>
        <ClassInfo
          title={data.title}
          image={data.images[0].image_url}
          tutorInfo={{
            isPublic: data.is_public === 1,
            joinCount: data.join_qty,
            maxCount: data.max_qty,
          }}
          step={{
            title: stepData?.step_title,
            round: stepData?.step,
          }}
          date={date}
        />
        <MyClassButtonBox>
          <div className="button-wrap">
            <Button
              title="나중에 입력"
              variant="outlined"
              onClick={handleLater}
            />
          </div>
          <div className="button-wrap">
            <Button title="피드백 작성" onClick={handleSubmit} />
          </div>
        </MyClassButtonBox>
      </MyClassContentWrapper>
    </Wrapper>
  )
}

const Wrapper = styled(AppRootWrapper)``

export default MyClassEndTutorContainer
