import { TutorRegisterClassPayloadState2 } from 'atoms/join'
import Footer from 'components/common/Footer'
import TutorGnb from 'components/common/TutorGnb'
import MobileTitleHeader from 'components/common/header/MobileTitleHeader'
import TutorHeader from 'components/common/header/TutorHeader'
import RegisterComponent from 'components/common/register/RegisterComponent'
import media from 'lib/styles/media'
import {
  refatoringDetaiStepInfo,
  refatoringDetailFile,
  refatoringDetailInfo,
} from 'lib/utils'
import moment from 'moment'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import styled from 'styled-components'
import useSWR from 'swr'

function EditRegisterClass() {
  const params = useParams<{ classId: string }>()
  const classId = params.classId
  const { data } = useSWR(`/class/detail?class_id=${classId}`)
  const setPayload = useSetRecoilState(TutorRegisterClassPayloadState2('edit'))
  useEffect(() => {
    if (!data) return
    const preData = data.data.class
    const preDate = preData.begin_date && [
      {
        step: 1,
        date: moment(preData.begin_date).format('YYYY-MM-DD') ?? '',
        time: moment(preData.begin_date).format('HH:mm') ?? '',
        estimated: preData.estimated_time ?? '',
        title: '',
      },
    ]
    setPayload((pre) => ({
      ...pre,
      images: refatoringDetailFile(preData.images) ?? [],
      files: refatoringDetailFile(preData.images) ?? [],
      classTitle: preData.title ?? '',
      category:
        preData.categories?.map((category: any) => category.category_id) ?? [],
      person: preData.max_qty ?? 2,
      detailType: preData.detail_introduce_type ?? 'single',
      detailIntroduce: refatoringDetailInfo(preData.detail_introduces ?? []),
      multi: preData.is_multi ?? 0,
      public: preData.is_public ?? 1,
      password: preData.password ?? '',
      free: preData.is_free ?? 0,
      attend: preData.available_attend ?? 1,
      stepClass: preData.steps
        ? refatoringDetaiStepInfo(preData.steps ?? [])
        : preDate,
      price: preData.price ?? '',
      room: preData.room_qty ?? preData.max_qty,
    }))
  }, [data])

  if (!data) return null
  if (!data.data) return null
  if (!data.data.class) return null

  return (
    <Wrapper>
      <TutorHeader />
      <MobileTitleHeader title="강의 수정" />
      <Content>
        <RegisterComponent type="edit" classId={classId} />
      </Content>
      <Footer type="bottom" />
      <TutorGnb />
    </Wrapper>
  )
}

export default EditRegisterClass
const Wrapper = styled.div``
const Content = styled.div`
  width: 770px;
  margin: 100px auto 120px;
  ${media.medium} {
    width: 100%;
    margin: 0 auto 80px;
  }
`
