import Footer from 'components/common/Footer'
import Header from 'components/common/header/Header'
import MobileTitleHeader from 'components/common/header/MobileTitleHeader'
import IdLoginComponent from 'components/common/iid/IdLoginComponent'
import media from 'lib/styles/media'
import React from 'react'
import styled from 'styled-components'

function StudentIdLogin() {
  return (
    <Wrapper>
      <Header />
      <MobileTitleHeader title="로그인" type="arrow" />
      <Content>
        <IdLoginComponent />
      </Content>
      <Footer />
    </Wrapper>
  )
}

export default StudentIdLogin
const Wrapper = styled.div``
const Content = styled.div`
  margin-top: 100px;
  ${media.medium} {
    margin-top: 0;
    margin-bottom: 80px;
  }
`
