import media from 'lib/styles/media'
import React, { useState } from 'react'
import styled from 'styled-components'
import { ReactComponent as CloseIcon } from '../../assets/icons/popup_cancel_icon.svg'
import Button from './Button'
import Input from './Input'
import api from 'lib/api'
import PasswordInput from './PasswordInput'

interface Props {
  handleClose?: () => void
  id: string
  onSuccess: () => void
}
function SecretPopup({ handleClose, id, onSuccess }: Props) {
  const [password, setPassword] = useState('')
  const [isError, setIsError] = useState(false)

  const handleSubmit = async () => {
    const formData = new FormData()
    formData.append('class_id', String(id))
    formData.append('password', password)

    const {
      data: { success, alert },
    } = await api.post('/class/password', formData)

    if (success) {
      onSuccess()
    } else {
      setIsError(true)
    }
  }

  return (
    <Wrapper>
      <Content>
        <TitleBox>
          <p className="secret_popup_title">비공개 강의</p>
          <CloseIcon onClick={handleClose} />
        </TitleBox>
        <InputBox>
          <p className="secret_notice_title">
            튜터에게 전달받은 비밀번호를 입력해 주세요.
          </p>
          <div className="input_box">
            <PasswordInput
              title="강의 비밀번호"
              placeholder="강의 비밀번호 입력"
              value={password}
              onChange={(e: any) => {
                setPassword(e.target.value)
              }}
              error={isError}
              errorPwMessage="비밀번호가 올바르지 않습니다."
              maxLength={10}
            />
          </div>
        </InputBox>
        <ButtonBox>
          <Button title="입장" disabled={!password} onClick={handleSubmit} />
        </ButtonBox>
      </Content>
    </Wrapper>
  )
}

export default SecretPopup

const Wrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  z-index: 110;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 1);
`
const Content = styled.div`
  width: 380px;
  min-height: 320px;
  background: #fff;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 14px;
  padding: 26px;
  ${media.medium} {
    width: 320px;
    min-height: 320px;
  }
`
const TitleBox = styled.div`
  padding-bottom: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  & p.secret_popup_title {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: #0d0d0e;
  }
`
const InputBox = styled.div`
  min-height: 165px;
  & p.secret_notice_title {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #3b3b40;
  }
  & div.input_box {
    padding: 30px 0 50px;
  }
`
const ButtonBox = styled.div`
  padding-top: 10px;
`
