import type { DetailInfoType, DetailStepInfoType } from 'atoms/join'
import {
  add,
  differenceInDays,
  differenceInMinutes,
  differenceInSeconds,
  format,
} from 'date-fns'
import { ko } from 'date-fns/locale'
import { type RoomSeatInfoType } from 'lib/types/room'
import imageCompression from 'browser-image-compression'
import { type ClassDetailType } from 'lib/types/class'

export function isAndroid() {
  return window.navigator.userAgent.match('chadugo/Android') !== null
}

export function initialSeatInfo(seats: RoomSeatInfoType[], max: number) {
  const arr = Array.from({ length: max })
  let tableNum = null

  seats.forEach((seat) => {
    const index = seat.table_num - 1
    arr[index] = seat
  })

  arr.some((value, index) => {
    tableNum = index + 1
    return value === undefined
  })

  return {
    seats,
    tableNum,
  }
}

export function remainTableNumber() {}

export function convertTimer(timer?: number | null) {
  if (!timer) return '00:00'

  const minutes = Math.floor(timer / 60)

  const senconds = timer % 60

  return `${minutes < 10 ? '0' + minutes : minutes}:${
    senconds < 10 ? '0' + senconds : senconds
  }`
}

export function initialTimer(date?: string | null) {
  if (!date) return 0

  const current = new Date()

  let resultDate

  if (date.replace) {
    resultDate = new Date(date.replace(/-/g, '/'))
  } else {
    resultDate = new Date(date)
  }

  const diff = differenceInSeconds(resultDate, current)

  return diff
}

export function addDate(time: number) {
  const current = new Date()
  const addDate = add(current, { seconds: time })
  const formatDate = format(addDate, 'yyyy/MM/dd HH:mm:ss', { locale: ko })

  return formatDate
}

export const refatoringDetailInfo = (detailInfoList: DetailInfoType[]) => {
  return detailInfoList.map((info, index) => ({
    ...info,
    index: index + 1,
  }))
}
export const refatoringDetailFile = (fileList: any[]) => {
  return fileList.map((info, index) => ({
    ...info,
    index: index + 1,
  }))
}
export const refatoringDetaiStepInfo = (stepInfoList: DetailStepInfoType[]) => {
  return stepInfoList.map((info, index) => ({
    ...info,
    index: index + 1,
  }))
}
export function getThousandCommaPrice(price: string | number): string {
  if (price === '0' || !price) return '0'
  return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const compressionImage = async (
  file: File,
  imageSize?: 'sm' | 'md' | 'lg',
) => {
  const option = {
    sm: {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 512,
      useWebWorker: true,
    },
    md: {
      maxSizeMB: 1,
      maxWidthOrHeight: 1024,
      useWebWorker: true,
    },
    lg: {
      maxSizeMB: 1.5,
      maxWidthOrHeight: 1024,
      useWebWorker: true,
    },
  }

  return imageCompression(file, option[imageSize ?? 'sm'])
}

export function readFile(file: File) {
  return new Promise((resolve, reject) => {
    const resultFile = file
    const reader = new FileReader()

    reader.onload = (e) => {
      return resolve({
        id: String(resultFile.name + '_' + resultFile.lastModified),
        preview: e.target?.result ?? '',
        file: resultFile,
      })
    }
    reader.readAsDataURL(file)

    reader.onerror = reject
  })
}

export function formatChatDate(date: string) {
  let resultDate

  if (date.replace) {
    resultDate = new Date(date.replace(/-/g, '/'))
  } else {
    resultDate = new Date(date)
  }

  const result = format(resultDate, 'aaa hh:mm', {
    locale: ko,
  })

  return result
}

export function formatPaymentDate(date: string) {
  let resultDate

  if (date.replace) {
    resultDate = new Date(date.replace(/-/g, '/'))
  } else {
    resultDate = new Date(date)
  }

  const result = format(new Date(resultDate), 'yyyy-MM-dd(ccc) HH:mm', {
    locale: ko,
  })
  return result
}

export function formatTime(date: string) {
  let resultDate

  if (date.replace) {
    resultDate = new Date(date.replace(/-/g, '/'))
  } else {
    resultDate = new Date(date)
  }

  const result = format(resultDate, 'HH:mm', {
    locale: ko,
  })
  return result
}

export function differenceTime(start: string, end: string) {
  let startDate

  if (start.replace) {
    startDate = new Date(start.replace(/-/g, '/'))
  } else {
    startDate = new Date(start)
  }

  let endDate

  if (end.replace) {
    endDate = new Date(end.replace(/-/g, '/'))
  } else {
    endDate = new Date(end)
  }

  const diff = differenceInMinutes(endDate, startDate)
  return diff
}

export function renderTime(minutes: number) {
  if (!minutes) return ''

  const renderHours = Math.floor(minutes / 60)
  const renderMinutes = Math.floor(minutes % 60)

  if (renderHours === 0) return renderMinutes + '분'
  return renderHours + '시간' + renderMinutes + '분'
}

export function classDateFormat(data: ClassDetailType) {
  if (data.is_multi === 1) {
    const step = data.steps.find((step) => Number(step.id) === Number(step))
    let learningTime

    if (step?.step_real_begin_date && step.step_real_end_date) {
      learningTime = differenceTime(
        step?.step_real_begin_date,
        step.step_real_end_date,
      )
    } else {
      learningTime = data.estimated_time
    }

    return {
      start:
        step?.step_real_begin_date ?? step?.step_begin_date ?? data.begin_date,
      end: step?.step_real_end_date,
      learningTime,
    }
  }

  const start = data?.real_begin_date ?? data.begin_date
  let learningTime

  if (data.real_end_date) {
    learningTime = differenceTime(start, data.real_end_date)
  } else {
    learningTime = data.estimated_time
  }

  return {
    start: data?.real_begin_date ?? data.begin_date,
    end: data.real_end_date,
    learningTime,
  }
}

export const refactoringSelectData = (data: any) => {
  return data?.reduce((prev: any, curr: any) => {
    prev[String(curr.id)] = curr.category
    return prev
  }, {}) as { [key in string]: string }
}

export function getDday(date: string) {
  const today = new Date()

  let resultDate

  if (date.replace) {
    resultDate = new Date(date.replace(/-/g, '/'))
  } else {
    resultDate = new Date(date)
  }

  const diff = differenceInDays(resultDate, today)
  return diff
}

export function dateFormat(date: string, dateFormat: string) {
  // let resultDate

  // if (date.replace) {
  //   resultDate = new Date(date.replace(/-/g, '/'))
  // } else {
  //   resultDate = new Date(date)
  // }

  return format(new Date(date), dateFormat, {
    locale: ko,
  })
}
