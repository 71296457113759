import media from 'lib/styles/media'
import React from 'react'
import styled from 'styled-components'

const ToogleButton = ({
  title,
  value,
  onChange,
}: {
  title: string
  value: boolean
  onChange: () => void
}) => {
  return (
    <Warpper>
      <Toggle onClick={onChange} className={value ? 'active' : ''}>
        <div />
      </Toggle>
      {title}
    </Warpper>
  )
}

export default ToogleButton

const Toggle = styled.div`
  background: #e1e1e2;
  border-radius: 100px;
  width: 34px;
  height: 20px;
  padding: 3px;
  position: relative;
  transition: all 0.3s;
  margin-right: 10px;
  cursor: pointer;

  & > div {
    background: #ffffff;
    border-radius: 100px;
    width: 14px;
    height: 14px;
  }

  &.active {
    background: #7574d8;

    & > div {
      margin-left: auto;
    }
  }
`

const Warpper = styled.label`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.71;
  color: #3b3b40;
`
