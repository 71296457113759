import { AppRootWrapper } from 'components/layout/CommonStyle'
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Input from '../Input'
import PasswordInput from '../PasswordInput'
import {
  ErrorPwCheckMessage,
  ErrorPwMessage,
  resId,
  resPw,
  tutorWithdrawalAlert,
  tutorWithdrawalSuccess,
  withdrawalAlert,
  withdrawalSuccess,
} from 'lib/utils/CommonFuncttion'
import Button from '../Button'
import WithdrawalPopup from './WithdrawalPopup'
import api from 'lib/api'
import CheckPopup from '../CheckPopup'
import { useHistory } from 'react-router-dom'
import media from 'lib/styles/media'
import { PageTypeState } from 'atoms/common'
import { useRecoilState } from 'recoil'
import auth from 'lib/utils/auth'
import useUser from 'lib/hooks/account/useUser'

interface Props {
  data: { email: string; provider: string }
}
function EditInfoLoginComponent({ data }: Props) {
  const history = useHistory()
  const [formdata, setFormdata] = useState({
    email: data.email,
    password: '',
    checkPassword: '',
  })
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [withdrawalPopup, setWithdrawalPopup] = useState(false)
  const [popup, setPopup] = useState(false)
  const [title, setTitle] = useState('')
  const [contents, setContents] = useState('')
  const [isCancel, setIsCancel] = useState(false)
  const [isEdit, setIsEdit] = useState(true)
  const [editTitle, setEditTitle] = useState('수정')
  const [isCheckId, setIsCheckId] = useState(true)
  const [success, setSuccess] = useState(false)
  const [emailError, setEmailError] = useState(false)
  const [message, setMessage] = useState('')
  const [emailErrorMessage, setEmailErrorMessage] = useState('')
  const disabledId = (!isEdit && success) || (isEdit && !success)
  const { user } = useUser()
  const typeUser = user && user.type

  useEffect(() => {
    if (popup || withdrawalPopup) {
      document.documentElement.style.overflow = 'hidden'
    } else {
      document.documentElement.style.overflow = 'auto'
    }

    return () => {
      document.documentElement.style.overflow = 'auto'
    }
  }, [popup, withdrawalPopup])

  const handleChange = (type: string) => (e: any) => {
    setFormdata({
      ...formdata,
      [type]: e.target.value,
    })
    if (type !== 'email') {
      handleCheckPassword()
    }
  }

  const handleCheckPassword = () => {
    if (!formdata.password) {
      return setError(false)
    }

    if (!formdata.checkPassword) {
      if (!resPw.test(String(formdata.password))) {
        setError(true)
        setErrorMessage(ErrorPwMessage)
      } else {
        setError(false)
      }
    } else {
      if (formdata.password === formdata.checkPassword) {
        if (!resPw.test(String(formdata.password))) {
          setError(true)
          setErrorMessage(ErrorPwMessage)
        } else {
          setError(false)
        }
      } else {
        if (!resPw.test(String(formdata.password))) {
          setError(true)
          setErrorMessage(ErrorPwCheckMessage)
        } else {
          setError(true)
          setErrorMessage('비밀번호가 일치하지 않습니다.')
        }
      }
    }
  }
  const handleSubmit = async () => {
    const formData = new FormData()
    formData.append('email', formdata.email)
    formData.append('password', formdata.password)

    const {
      data: { success, alert },
    } = await api.post('/mypage/email/password/modify', formData)

    if (success) {
      setPopup(true)
      setTitle('로그인 정보 변경 완료')
      setContents('로그인 정보 변경이 완료되었습니다.')
    } else {
      window.alert(alert)
    }
  }
  const handleWithdrawal = async () => {
    setWithdrawalPopup(false)
    const {
      data: { success, alert },
    } = await api.post('/withdrawal')

    if (success) {
      setPopup(true)
      typeUser === 'user'
        ? setTitle('회원 탈퇴 완료')
        : setTitle('튜터 탈퇴 완료')
      typeUser === 'user'
        ? setContents(withdrawalSuccess)
        : setContents(tutorWithdrawalSuccess)
    } else {
      setPopup(true)
      typeUser === 'user'
        ? setTitle('회원 탈퇴 불가')
        : setTitle('튜터 탈퇴 불가')
      typeUser === 'user'
        ? setContents(withdrawalAlert)
        : setContents(tutorWithdrawalAlert)
    }
  }

  const handleCheck = () => {
    if (title.includes('탈퇴 완료')) {
      window.location.href = typeUser === 'tutor' ? '/?role=tutor' : '/'
      auth.clearAppStorage()
    }
    if (title === '로그인 정보 변경 완료') {
      window.location.reload()
    }
  }

  const handleCancelClick = () => {
    setIsEdit(true)
    setEditTitle('수정')
    setFormdata({
      ...formdata,
      email: data.email,
    })
    setEmailError(false)
    setIsCancel(false)
    setSuccess(false)
  }

  const handleEditClick = () => {
    setEditTitle('중복 확인')
    setIsCancel(true)
    setIsEdit(false)
    setIsCheckId(true)
  }
  const handleIdCheckClick = async () => {
    if (String(formdata.email).length >= 8) {
      if (resId.test(String(formdata.email))) {
        const formData = new FormData()
        formData.append('type', typeUser === 'tutor' ? 'tutor' : 'user')
        formData.append('email', String(formdata.email))
        const {
          data: { success, alert },
        } = await api.post('/member/email/check', formData)
        if (success) {
          setEmailError(false)
          setSuccess(true)
          setMessage('사용 가능한 아이디입니다.')
        } else {
          setSuccess(false)
          if (alert === '이미 사용중인 아이디입니다.') {
            setEmailError(true)
            setEmailErrorMessage('중복 아이디입니다.')
          } else {
            window.alert(alert)
          }
        }
      } else {
        setSuccess(false)
        setEmailError(true)
        return setEmailErrorMessage('영문, 숫자 조합으로 설정하실 수 있습니다.')
      }
    }
  }
  return (
    <Wrapper>
      <Content>
        <div className="content_box">
          <Input
            type="text"
            title="아이디"
            placeholder="영문, 숫자 포함 8-20자"
            value={formdata.email}
            onChange={handleChange('email')}
            cancel={isCancel}
            handleCancelClick={handleCancelClick}
            edit={isEdit}
            editTitle={editTitle}
            handleEditClick={handleEditClick}
            chekId={isCheckId}
            handleIdCheckClick={handleIdCheckClick}
            error={emailError}
            errorMessage={emailErrorMessage}
            success={success}
            successMessage={message}
            maxLength={20}
          />
          {data.provider === null && (
            <>
              <PasswordInput
                title="비밀번호"
                placeholder="영문, 숫자, 특수문자 포함 8~20자"
                value={formdata.password}
                onChange={handleChange('password')}
                onKeyUp={handleCheckPassword}
                error={error}
                maxLength={20}
              />
              <PasswordInput
                title="새 비밀번호 확인"
                placeholder="비밀번호 재입력"
                value={formdata.checkPassword}
                onChange={handleChange('checkPassword')}
                onKeyUp={handleCheckPassword}
                error={error}
                errorPwMessage={errorMessage}
                maxLength={20}
              />
            </>
          )}
        </div>
        {data.provider === null && (
          <div className="button_box">
            <Button
              title="저장"
              onClick={handleSubmit}
              disabled={
                !disabledId ||
                error ||
                !String(formdata.email) ||
                String(formdata.password).length < 8 ||
                String(formdata.checkPassword).length < 8
              }
            />
          </div>
        )}
        <div className="withdrawal_box">
          <p
            onClick={() => {
              setWithdrawalPopup(true)
            }}
          >
            회원탈퇴
          </p>
        </div>
      </Content>
      {popup && (
        <CheckPopup onClick={handleCheck} title={title} content={contents} />
      )}
      {withdrawalPopup && (
        <WithdrawalPopup
          handleClose={() => {
            setWithdrawalPopup(false)
          }}
          onClick={handleWithdrawal}
        />
      )}
    </Wrapper>
  )
}

export default EditInfoLoginComponent
const Wrapper = styled.div`
  width: 100%;
`
const Content = styled.div`
  & div.content_box {
    width: 380px;
    margin: 0 auto;
    ${media.medium} {
      width: 100%;
      margin: 30px auto;
    }
    & > div {
      margin-bottom: 20px;
    }
    & > div:last-of-type {
      margin-bottom: 0;
    }
  }
  & div.button_box {
    width: 380px;
    margin: 40px auto 0;
    ${media.medium} {
      width: 100%;
    }
  }
  & div.withdrawal_box {
    width: 380px;
    margin: 80px auto 0;
    display: flex;
    align-items: center;
    justify-content: center;
    ${media.medium} {
      width: 100%;
    }
    & > p {
      font-weight: 500;
      font-size: 13px;
      line-height: 22px;
      text-align: center;
      text-decoration-line: underline;
      color: #7c7c80;
      cursor: pointer;
    }
  }
`
