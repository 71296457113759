import { AppRootWrapper } from 'components/layout/CommonStyle'
import media from 'lib/styles/media'
import type { LikeDataProps } from 'lib/types/account'
import React from 'react'
import styled from 'styled-components'
import NoneData from '../NoneData'
import SmallClassInfoComponent from '../SmallClassInfoComponent'

function LikeClassComponent({ data, target }: LikeDataProps) {
  return (
    <Wrapper>
      <LikeTitle>
        <p className="like_title">찜한 강의</p>
      </LikeTitle>
      <Contents>
        {data.length > 0 ? (
          <LikeContents>
            {data.map((item, i) => (
              <SmallClassInfoComponent
                key={`category_class_list_${i}`}
                Image={item.image_url}
                Profile={item.profile_url}
                Title={item.title}
                Nickname={item.nickname}
                Score={item.tutor_score}
                Category={item.categories}
                Price={item.price}
                like="Y"
                date={item.begin_date}
                id={item.id}
                type="like"
                tutorId={item.tutor_id}
              />
            ))}
            <div className="target" ref={target} />
          </LikeContents>
        ) : (
          <NoneData title="찜한 강의가" />
        )}
      </Contents>
    </Wrapper>
  )
}

export default LikeClassComponent
const Wrapper = styled(AppRootWrapper)``
const LikeTitle = styled.div`
  ${media.medium} {
    display: none;
  }
  & .like_title {
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: #0d0d0e;
  }
`
const Contents = styled.div`
  margin-top: 32px;
  ${media.medium} {
    margin-top: 0;
  }
`
const LikeContents = styled.div`
  display: flex;
  overflow: hidden;
  flex-wrap: wrap;
  margin-bottom: 120px;
  ${media.medium} {
    margin-bottom: 50px;
  }
  & > div {
    margin-bottom: 40px;
    ${media.medium} {
      margin-bottom: 30px;
    }
  }
  & > div:nth-of-type(4n) {
    margin-right: 0;
  }
  & > div:nth-of-type(2n) {
    ${media.medium} {
      margin-right: 0;
    }
  }
`
