import { PageTypeState } from 'atoms/common'
import api from 'lib/api'
import auth from 'lib/utils/auth'
import QueryString from 'qs'
import React, { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useRecoilState } from 'recoil'

function KakaoLoginPage() {
  const location = useLocation()
  const history = useHistory()
  const { code }: any = QueryString.parse(location.search.slice(1))
  const [pageType, setPageType] = useRecoilState(PageTypeState)

  useEffect(() => {
    KakaoToken()
  }, [])

  const KakaoToken = async () => {
    fetch(`https://kauth.kakao.com/oauth/token`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: `grant_type=authorization_code&client_id=${process.env.REACT_APP_KAKAO_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_KAKAO_REDIRECT_URL}&code=${code}`,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.access_token) {
          handleKakao(data.access_token)
        } else {
          history.push('/login')
        }
      })
  }
  const handleKakao = async (token: any) => {
    const formData = new FormData()
    formData.append('token', token)

    const {
      data: { success, alert, data },
    } = await api.post('/kakao/login', formData)

    if (success) {
      auth.setToken({
        token: data?.token?.access_token,
        refreshToken: data?.token?.refresh_token ?? '',
      })
      auth.setSaveRefreshToken(data?.token?.refresh_token)
      setTimeout(() => {
        setPageType('user')
        localStorage.setItem('social', 'kakao')
        window.location.href = '/join'
      }, 200)
    } else {
      history.push('/login')
    }
  }
  return null
}

export default KakaoLoginPage
