import Footer from 'components/common/Footer'
import Header from 'components/common/header/Header'
import MobileTitleHeader from 'components/common/header/MobileTitleHeader'
import MyClassComponent from 'components/common/myclass/MyClassComponent'
import useUser from 'lib/hooks/account/useUser'
import media from 'lib/styles/media'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

function MyClassPage() {
  const { user } = useUser()
  const studentUser = user && user.type === 'user'
  const history = useHistory()

  useEffect(() => {
    if (!studentUser) return history.push('/login')
  }, [])

  return (
    <Wrapper>
      <Header />
      <MobileTitleHeader type="arrow" title="강의 이력" />
      <Content>
        <MyClassComponent />
      </Content>
      <Footer />
    </Wrapper>
  )
}

export default MyClassPage
const Wrapper = styled.div``
const Content = styled.div`
  margin-top: 100px;
  ${media.medium} {
    margin-top: 0;
  }
`
