import api, { API_END_POINT } from 'lib/api'
import React, { useCallback, useState } from 'react'
import useSWR from 'swr'
import useScroll from './useScroll'
import { getSearchListRequest } from 'lib/api/list'
import axios from 'axios'

interface Props {
  searchData: string
  available?: string
  studentUser?: boolean
}
function useSearch({ searchData, available, studentUser }: Props) {
  const [loading, setLoding] = useState(false)
  const availableData = available !== undefined ? available : '0'

  const { data, mutate } = useSWR(
    searchData &&
      `/class?page=1&search=${searchData}&type=search&available_class=${availableData}`,
    async (url: any) => {
      setLoding(true)

      let responseData

      if (studentUser) {
        responseData = await api.get(url).then((res) => res.data)
      } else {
        responseData = await axios
          .get(API_END_POINT + url)
          .then((res) => res.data)
      }
      setLoding(false)
      if (!responseData.success) return undefined
      return responseData
    },
  )
  const [fetchMoreLoading, setFetchMoreLoading] = useState(false)

  const fetchMore = useCallback(
    async (page: number) => {
      if (!data || !data.data || fetchMoreLoading || loading) return
      setFetchMoreLoading(true)

      const response = await getSearchListRequest(
        page,
        searchData,
        availableData,
        studentUser,
      )

      if (!response.success || !response.data) return

      mutate(
        {
          ...data,
          data: {
            classes: {
              last_page: response.data?.classes.last_page,
              current_page: response.data?.classes.current_page,
              data: data.data.classes.data.concat(response.data.classes.data),
            },
          },
        },
        false,
      )
      setFetchMoreLoading(false)
    },
    [data, mutate, fetchMoreLoading, loading],
  )
  const { target } = useScroll({
    fetchMore,
    page: data?.data?.classes.current_page,
    lastPage: data?.data?.classes.last_page,
  })
  return {
    data,
    target,
    mutate,
    fetchMoreLoading,
  }
}

export default useSearch
