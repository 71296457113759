import media from 'lib/styles/media'
import {
  PriceNum,
  dateHyphenFormet,
  paymentMethod,
} from 'lib/utils/CommonFuncttion'
import React from 'react'
import styled from 'styled-components'

interface Props {
  payment: string
  date: string
  price: number
}
function PaymentInfoComponent({ payment, date, price }: Props) {
  return (
    <Wrapper>
      <p className="info_title">결제 정보</p>
      <div className="content_box">
        <div className="content">
          <p className="title">결제 수단</p>
          <p>{paymentMethod(payment)}</p>
        </div>
        <div className="content">
          <p className="title">결제 일시</p>
          <p>{dateHyphenFormet(date)}</p>
        </div>
        <div className="content">
          <p className="title">결제 금액</p>
          <p className="price">{PriceNum(price)}원</p>
        </div>
      </div>
    </Wrapper>
  )
}

export default PaymentInfoComponent
const Wrapper = styled.div`
  & p.info_title {
    font-weight: 500;
    font-size: 17px;
    line-height: 26px;
    color: #0d0d0e;
    padding-bottom: 10px;
    border-bottom: 1px solid #0d0d0e;
    ${media.medium} {
      font-size: 16px;
    }
  }
  & div.content_box {
    margin-top: 20px;
    ${media.medium} {
      margin-top: 14px;
    }
  }
  & div.content {
    display: flex;
    & > p {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: #3b3b40;
      margin-bottom: 12px;
      ${media.medium} {
        font-size: 13px;
        line-height: 22px;
        margin-bottom: 10px;
      }
      &.title {
        min-width: 160px;
        ${media.medium} {
          min-width: 110px;
        }
      }
      &.price {
        font-weight: 700;
        color: #0d0d0e;
      }
    }
  }
`
