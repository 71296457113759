import React from 'react'
import styled from 'styled-components'
import type { MyClassPaymentDataProps } from 'lib/types/account'
import NoneData from '../NoneData'
import PaymentClssList from './PaymentClssList'
import media from 'lib/styles/media'

interface Props {
  data: MyClassPaymentDataProps
  type: string
  pageType: string
}
function TabPaymentComponent({ data, type, pageType }: Props) {
  return (
    <Wrapper>
      {data.payment_histories.length > 0 ? (
        data.payment_histories.map((item, i) => (
          <PaymentClssList
            key={`my_class_payment_list_component_${i}`}
            data={item}
            type={type}
            pageType={pageType}
          />
        ))
      ) : (
        <NoneData title="결제 목록이" />
      )}
    </Wrapper>
  )
}

export default TabPaymentComponent
const Wrapper = styled.div`
  margin-top: 40px;
  ${media.medium} {
    margin-top: 20px;
  }
`
